import Modal from "./Modal";
import { useModal } from "../helpers/useModal";

import "../estilos/TX_Textos.css";


const Confirmar = ({ titulo, mensaje, fnAceptar, fnCerrar, txtCancelar="CANCELAR", txtAceptar="ACEPTAR"}) => {
  const [isOpenMensaje, openMensaje, closeMensaje] = useModal (true);


  const clickCerrar = () => {
    if(fnCerrar===null) closeMensaje();
    else fnCerrar();    
  } 

  const clickAceptar = () => {
    if(fnAceptar===null) closeMensaje();
    else fnAceptar();    
  }
  

  return (
      <Modal isOpen={isOpenMensaje} closeModal={clickCerrar}  titulo={titulo}  clickAceptar={clickAceptar} txtCancelar={txtCancelar} txtAceptar={txtAceptar} >
                <div style={{margin:"30px"}}>
                    <span className="TX_neutro" style={{whiteSpace:"pre-line"}}>{mensaje}</span>                  
                </div>         
                
      </Modal>
  );
};

export default Confirmar;