import { ID_MODULO_DETALLE_TORNEO, SERVIDOR_PRINCIPAL, SERVIDOR_WEB } from "../constantes/generales";
import { convertirTextoHorario, formatearFecha, getParamsLink, getStringLink, recuperarDatoLocal, registrarLogEventos } from "../helpers/funciones";
import TemplateLista2Items from "./TemplateLista2Items";

//const nomClase="InfoTorneo";
const idModulo=ID_MODULO_DETALLE_TORNEO;

const ruta=recuperarDatoLocal("rutaRecursos");


const InfoTorneo = ({ datos, idTorneo }) => {


    if(!datos) return null;

    
    registrarLogEventos(idModulo,"3",idTorneo,"");


    let params= datos.parametros.split("-");
    if(params.length<3) params=["","",""];

    let reg_final=datos.reglamento;
    reg_final=reg_final.replaceAll(ruta,"");
    reg_final=ruta + reg_final;

    let link=SERVIDOR_WEB + "#/" + getStringLink("T", idTorneo);


  
    return (
      <>
        <div style={{margin: "0.5em", maxWidth:"900px"}}>
            {datos.reglamento && 
            (
                <div><a className="BF_botonBordeAccent" href={reg_final} target="_blank" rel="noreferrer noopener">Ver Reglamento</a></div>
            )}
            <table width="100%"> 
                <tbody>
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Empresa"} dato2={datos.empresa} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Sede"} dato2={datos.sede} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Nombre Torneo"} dato2={datos.nombre} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Descripción"} dato2={datos.descripcion} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Horario"} dato2={convertirTextoHorario(datos.horario)} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Jornadas Registradas"} dato2={datos.cantJornadas} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Fecha de Inicio"} dato2={formatearFecha(datos.fechaInicio,9)} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Jugadores Activos"} dato2={datos.cantJugadores} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Jugadores x Equipo"} dato2={params[0]} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Minutos x Tiempo"} dato2={params[1]} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Minutos Descanso"} dato2={params[2]} />
                    <TemplateLista2Items llevaImagen={false} imagen="" dato1={"Link de Acceso Directo"} dato2={link} />
                </tbody>
            </table>
        </div>
        
      </>
    );
  };
  
  export default InfoTorneo;