import { useEffect, useState } from "react";
import { reportarError, showMensajePantalla } from "../helpers/funciones";

const nomClase="ShowError_WB";

let titulo="";
let mensaje="";
let ventanaMensaje;

const ShowError = ({tipoError, funcionCerrar=null}) => {
  const [showMensaje, setShowMensaje] = useState(false);

  

  useEffect(() => {
    const nomFuncion="useEffect";

    try{        
      if(tipoError===1){
        titulo="ERROR EN LOS DATOS";
        mensaje="Se presento un error inesperado procesando la información. Por favor Intenta nuevamente o reporta tu problema a nuestra linea de soporte. ";
      }
      else if(tipoError===2){
        titulo="PROBLEMA DE CONEXIÓN";
        mensaje="Parece que NO ESTAS conectado a Internet o existen intermitencias en la conexión. Por favor Intenta nuevamente o reporta tu problema a nuestra linea de soporte.";
      }
      
      if(titulo!=="") ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,funcionCerrar,"ERROR","0");
        

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }     
    

}, [tipoError]);
    
  

    return (
      <>
        {showMensaje && (ventanaMensaje)}      
      </>
    );
  };
  
  export default ShowError;