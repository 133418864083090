import { Route, BrowserRouter, Routes, HashRouter } from 'react-router-dom';
import './App.css';
import Inicial from './componentes/Inicial';


function App() {


  return (

    <div className="App">      
      <div align="center">  
        <HashRouter>
          <Routes>
            <Route exact path='/:dato' Component={Inicial} />
            <Route exact path='/' Component={Inicial} />   
            <Route exact path='*' Component={Inicial} />  
          </Routes> 
        </HashRouter>    
        
      </div>
    </div>
    
  );
}

export default App;
