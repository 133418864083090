import { C_colorAccent, C_fondoPaginas } from "../constantes/generales";
import "../estilos/IP_Inputs.css";
import { useEffect, useState } from "react";

const DEFAULT_COUNT = 5;
const DEFAULT_ICON = "★";
const DEFAULT_UNSELECTED_COLOR = C_fondoPaginas;
const DEFAULT_COLOR = C_colorAccent;

const Stars = ({ valorInicial, habilitado, icon, color, iconSize, identificador, clickGenerico}) => {
  const [valActual, setValActual] = useState(valorInicial);
  const [temporaryRating, setTemporaryRating] = useState(0);

  useEffect(() => {
    setValActual(valorInicial);
  }, [valorInicial]);


  let stars = Array(DEFAULT_COUNT).fill(icon || DEFAULT_ICON);

  let clase="star";
  if(habilitado===true) clase="star habilitado";

  const manejarMouse = (tipo, index) => {
    if(habilitado===true){
        if(tipo==="ENTRA") setTemporaryRating(index + 1);
        else if(tipo==="SALE") setTemporaryRating(0);
    }
  };

  const handleClick = (calificacion) => {
    if(habilitado===true){
      setValActual(calificacion);
      clickGenerico("CLICK_STAR",[identificador,calificacion])
    }    
  };

  return (
    <div className="starsContainer">
      {stars.map((item, index) => {
        const isActiveColor =
          (valActual || temporaryRating) &&
          (index < valActual || index < temporaryRating);

        let elementColor = "";

        if (isActiveColor) {
          elementColor = color || DEFAULT_COLOR;
        } else {
          elementColor = DEFAULT_UNSELECTED_COLOR;
        }

        return (
          <div
            className={clase}
            key={index}
            style={{
              fontSize: iconSize ? `${iconSize}px` : "18px",
              color: elementColor,
              filter: `${isActiveColor ? "grayscale(0%)" : "grayscale(100%)"}`,
            }}       
            onMouseEnter={() => manejarMouse("ENTRA", index)}
            onMouseLeave={() => manejarMouse("SALE", index)}
            onClick={() => handleClick(index + 1)}
          >
            {icon ? icon : DEFAULT_ICON}
          </div>
        );
      })}
    </div>
  );
}

export default Stars;