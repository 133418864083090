import { useState, useEffect } from 'react';
import { registrarLogEventos } from '../helpers/funciones';

import ic_escudo_base2 from '../recGraficos/ic_escudo_base2_w.png';
import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_disparo_arco from '../recGraficos/ic_disparo_arco.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_fairplay from '../recGraficos/ic_fairplay.png';
import ic_porcentajetiempo from '../recGraficos/ic_porcentajetiempo.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_falta_cometida from '../recGraficos/ic_falta_cometida.png';

import DinamicoRanking from './DinamicoRanking';
import { C_uniformeGrisClaro, ID_MODULO_DETALLE_TORNEO } from '../constantes/generales';

const nomClase="DT_InfoRankingEquipos_WB";
const idModulo=ID_MODULO_DETALLE_TORNEO;

//let indicadoresPos =[1, 2, 10, 11, 22, 9, 23, 8];
//let indicadoresNeg =[2, 12, 5, 6, 46, 7, 8];

let colPartidosJugados=6;
let idTorneo="";


const DT_InfoRankingEquipos = ({infoClasificacion, infoOrdenadaEquipos, tipo_registroinfo, columnasASumar, modOrigen, paramOrigen, definirModulo, tieneTarjetaAzul, hayPartidosFE}) => {
    const [positivo, setPositivo] = useState(true);
    const [numTodos, setNumTodos] = useState(-1);    
    const [tipoLista, setTipoLista] = useState("total");
    const [indicadoresPos, setIndicadoresPos] = useState([1, 2, 10, 11, 22, 9, 23, 8]);
    const [indicadoresNeg, setIndicadoresNeg] = useState([2, 12, 5, 6, 46, 7, 8]);


    useEffect(() => {
        if(infoClasificacion!==null && infoClasificacion.length>0) idTorneo=infoClasificacion[0][0];

        if(idTorneo!=="") registrarLogEventos(idModulo,"8",idTorneo,"1");
    }, []);

    useEffect(() => {
        let indicador="1";
        if(!positivo) indicador="0";

        if(idTorneo!=="") registrarLogEventos(idModulo,"8",idTorneo,indicador);
    }, [positivo]);


    useEffect(() => {
        actualizarListas(tipoLista);        
    }, [tipoLista]);

    useEffect(() => {
        if(idTorneo!=="") registrarLogEventos(idModulo,"8",idTorneo,"1");
    }, []);

    const incluirIndicador = (indicador, todos, titulo, subtitulo, imagen, bgnd, ascendente, esporcentaje) => {
        if(indicador>0){
            let indice=indicador;
            if(todos) indice=-1;
            
            return(
                <DinamicoRanking indicador={indicador} todos={todos} titulo={titulo} subtitulo={subtitulo} 
                            imagen={imagen} bgnd={bgnd} ascendente={ascendente} esporcentaje={esporcentaje} idxCant={columnasASumar[indicador]}
                            infoOrdenadaEquipos={infoOrdenadaEquipos} infoClasificacion={infoClasificacion} clickVerTodos={() => {setNumTodos(indice)}}  modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} colPartidosJugados={colPartidosJugados} /> 
            );
        }
        else{
            return(<></>);
        }
        
    }

    const cambiarTipoLista  = (selectedOption) => {          

        actualizarListas(selectedOption.target.value);
        setTipoLista(selectedOption.target.value);
    };

    const actualizarListas  = (valor) => {  
        
        let indicadoresPos1=[1, 2, 10, 11, 22, 9, 23, 8];
        let indicadoresNeg1=[2, 12, 5, 6, 46, 7, 8];

        if(valor==="total"){
            colPartidosJugados=6;
            indicadoresPos1 =[1, 2, 10, 11, 22, 9, 23, 8];
            indicadoresNeg1 =[2, 12, 5, 6, 46, 7, 8];
        }
        else if(valor==="grupos"){
            colPartidosJugados=32;
            indicadoresPos1 =[24, 25, 31, 32, 0, 30, 0, 29];
            indicadoresNeg1 =[25, 33, 26, 27, 47, 28, 29];
        }
        else if(valor==="eliminacion"){
            colPartidosJugados=58;
            indicadoresPos1 =[34, 35, 41, 42, 0, 40, 0, 39];
            indicadoresNeg1 =[35, 43, 36, 37, 45, 38, 39];
        }

        setIndicadoresPos(indicadoresPos1);
        setIndicadoresNeg(indicadoresNeg1);

    };


    return (

        <div>
            <div style={{marginLeft: "10px", marginRight: "10px"}}>
                <div className="CT_contenedorTitulo">
                    <img className='IM_imgIcono36' height="36px" width="36px" src={ic_escudo_base2} alt='' />   
                    <p style={{marginLeft: "10px", fontSize:"18px"}}>Ranking de Equipos</p>             
                </div>  
                {numTodos===-1 && (
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <div style={{flex:"1"}}>
                            <div style={{width:"100px", alignItems:"center"}} className={positivo ? "BF_botonPrimaryFull" : "BF_botonPrimary"} onClick={() => {setPositivo(!positivo)}}>
                                <span>+ Positivos</span>
                            </div>
                        </div>
                        <div style={{flex:"1"}}>
                            <div style={{width:"100px", alignItems:"center"}} className={positivo ? "BF_botonBordeAccent" : "BF_botonBordeAccentFull"} onClick={() => {setPositivo(!positivo)}}>
                                <span>Negativos -</span>
                            </div>
                        </div>
                    </div> 
                )}   

                {numTodos===-1 && hayPartidosFE && (
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-around", marginBottom: "10px" }}>
                        <label><input type="radio" value="total" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="total"} onChange={cambiarTipoLista}/>Total</label>
                        <label><input type="radio" value="grupos" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="grupos"} onChange={cambiarTipoLista}/>Grupos</label>
                        <label><input type="radio" value="eliminacion" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="eliminacion"} onChange={cambiarTipoLista}/>Eliminación</label>
                    </div>
                ) }  
                
                
            </div>

           
            <div style={{background:C_uniformeGrisClaro, padding:"4px", minHeight: "70vh"}}>

                {positivo && numTodos===-1 && (<div  className='CT_contenedorFlex'>

                    {incluirIndicador(indicadoresPos[0],false,"Goleadores","Mayor Cantidad de Goles Realizados",ic_balon_gol,1,true,false)}
                    {incluirIndicador(indicadoresPos[1],false,"Valla Menos Vencida","Menor Cantidad de Goles Recibidos",ic_disparo,1,false,false)}                    

                    {tipo_registroinfo==="2" && (<>
                        {incluirIndicador(indicadoresPos[2],false,"Disparos Totales","Disparos totales realizados",ic_disparo_arco,1,true,false)}
                        {incluirIndicador(indicadoresPos[3],false,"Disparos al Arco","Disparos al Arco realizados",ic_disparo,1,true,false)}
                        {incluirIndicador(indicadoresPos[4],false,"Efectividad de Disparo","Goles por cada 100 Disparos al Arco",ic_porcentajetiempo,1,true,true)}
                        {incluirIndicador(indicadoresPos[5],false,"Atajadas","Total Atajadas realizadas",ic_atajada,1,true,false)}
                        {incluirIndicador(indicadoresPos[6],false,"Efectividad Porteria","Atajadas por cada 100 Disparos Recibidos",ic_porcentajetiempo,1,true,true)}
                    </>)}

                    {incluirIndicador(indicadoresPos[7],false,"Juego Limpio","Indicador de Faltas(1), T Amarillas(3) y T Rojas(5)",ic_fairplay,1,false,false)}      

                </div>)}
                
                {!positivo && numTodos===-1 && (<div  className='CT_contenedorFlex'>
                    {incluirIndicador(indicadoresNeg[0],false,"Mas Goleado","Mayor Cantidad de Goles Recibidos",ic_balon_gol,2,true,false)}
                    {incluirIndicador(indicadoresNeg[1],false,"Autogoles","Mas autogoles Realizados",ic_balon_autogol,2,true,false)}   

                    {tipo_registroinfo==="2" && (<>
                        {incluirIndicador(indicadoresNeg[2],false,"Faltas Cometidas","Mas faltas Genera",ic_falta_cometida,2,true,false)} 
                    </>)}  
    
                    {incluirIndicador(indicadoresNeg[3],false,"Tarjetas Amarillas","Mas Tarjetas Amarillas Recibe",ic_amarilla,2,true,false)} 

                    {tieneTarjetaAzul===true && incluirIndicador(indicadoresNeg[4],false,"Tarjetas Azules","Mas Tarjetas Azules Recibe",ic_tarjeta_azul,2,true,false)} 

                    {incluirIndicador(indicadoresNeg[5],false,"Tarjetas Rojas","Mas Tarjetas Rojas Recibe",ic_tarjeta_roja,2,true,false)} 
                    {incluirIndicador(indicadoresNeg[6],false,"Juego Sucio","Indicador de Faltas(1), T Amarillas(3) y T Rojas(5)",ic_fairplay,2,true,false)}     
                </div>)}

                {numTodos>=0 && positivo && (<div  className='CT_contenedorFlex'>

                    {numTodos===indicadoresPos[0] && (incluirIndicador(indicadoresPos[0],true,"Goleadores","Mayor Cantidad de Goles Realizados",ic_balon_gol,1,true,false))}
                    {numTodos===indicadoresPos[1] && (incluirIndicador(indicadoresPos[1],true,"Valla Menos Vencida","Menor Cantidad de Goles Recibidos",ic_disparo,1,false,false))}                    
       
                    {numTodos===indicadoresPos[2] && (incluirIndicador(indicadoresPos[2],true,"Disparos Totales","Disparos totales realizados",ic_disparo_arco,1,true,false))}
                    {numTodos===indicadoresPos[3] && (incluirIndicador(indicadoresPos[3],true,"Disparos al Arco","Disparos al Arco realizados",ic_disparo,1,true,false))}
                    {numTodos===indicadoresPos[4] && (incluirIndicador(indicadoresPos[4],true,"Efectividad de Disparo","Goles por cada 100 Disparos al Arco",ic_porcentajetiempo,1,true,true))}
                    {numTodos===indicadoresPos[5] && (incluirIndicador(indicadoresPos[5],true,"Atajadas","Total Atajadas realizadas",ic_atajada,1,true,false))}
                    {numTodos===indicadoresPos[6] && (incluirIndicador(indicadoresPos[6],true,"Efectividad Porteria","Atajadas por cada 100 Disparos Recibidos",ic_porcentajetiempo,1,true,true))}
      
                    {numTodos===indicadoresPos[7] && (incluirIndicador(indicadoresPos[7],true,"Juego Limpio","Indicador de Faltas(1), T Amarillas(3) y T Rojas(5)",ic_fairplay,1,false,false))}

                </div>)}

                {numTodos>=0 && !positivo && (<div  className='CT_contenedorFlex'>

                    {numTodos===indicadoresNeg[0] && (incluirIndicador(indicadoresNeg[0],true,"Mas Goleado","Mayor Cantidad de Goles Recibidos",ic_balon_gol,2,true,false))}
                    {numTodos===indicadoresNeg[1] && (incluirIndicador(indicadoresNeg[1],true,"Autogoles","Mas autogoles Realizados",ic_balon_autogol,2,true,false))}   

                    {numTodos===indicadoresNeg[2] && (incluirIndicador(indicadoresNeg[2],true,"Faltas Cometidas","Mas faltas Genera",ic_falta_cometida,2,true,false))}  
    
                    {numTodos===indicadoresNeg[3] && (incluirIndicador(indicadoresNeg[3],true,"Tarjetas Amarillas","Mas Tarjetas Amarillas Recibe",ic_amarilla,2,true,false))} 

                    {tieneTarjetaAzul===true && numTodos===indicadoresNeg[4] && (incluirIndicador(indicadoresNeg[4],true,"Tarjetas Azules","Mas Tarjetas Azules Recibe",ic_tarjeta_azul,2,true,false))} 

                    {numTodos===indicadoresNeg[5] && (incluirIndicador(indicadoresNeg[5],true,"Tarjetas Rojas","Mas Tarjetas Rojas Recibe",ic_tarjeta_roja,2,true,false))} 
                    {numTodos===indicadoresNeg[6] && (incluirIndicador(indicadoresNeg[6],true,"Juego Sucio","Indicador de Faltas(1), T Amarillas(3) y T Rojas(5)",ic_fairplay,2,true,false))}  

                </div>)}

            </div>

         

        
        </div>

    );
};


export default DT_InfoRankingEquipos;