import { useEffect, useState } from 'react';

import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_asistencia from '../recGraficos/ic_asistencia.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_disparo_arco from '../recGraficos/ic_disparo_arco.png';
import ic_falta_recibida from '../recGraficos/ic_falta_recibida.png';
import ic_falta_cometida from '../recGraficos/ic_falta_cometida.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_play_arrow from '../recGraficos/ic_play_arrow.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';
import ic_puntos from '../recGraficos/ic_puntos.png';
import ic_fairplay from '../recGraficos/ic_fairplay.png';


import { formatearPorcentaje, formatearDecimal, valorNumero, reportarError, registrarLogEventos, dividirLista } from '../helpers/funciones';
import DinamicoEstadisticasJugador from './DinamicoEstadisticasJugador';
import DinamicoResumen2Cols from './DinamicoResumen2Cols';
import DinamicoResumenGoles from './DinamicoResumenGoles';
import DinamicoResumenDisparos from './DinamicoResumenDisparos';
import DinamicoResumenTiempo from './DinamicoResumenTiempo';
import DinamicoResumenEfectividad from './DinamicoResumenEfectividad';
import DinamicoResumenJugadores from './DinamicoResumenJugadores';
import { ID_MODULO_DETALLE_EQUIPO } from '../constantes/generales';

const nomClase="DE_InfoEstadisticasEquipo_WB";
const idModulo=ID_MODULO_DETALLE_EQUIPO;

const DE_InfoEstadisticasEquipo = ({idEquipo, infoClasificacion, tipo_registroinfo, puntosGanador, jugadoresActivos, tieneTarjetaAzul}) => {    
    const [iEstadisticas, setIEstadisticas] = useState(["","","","","","","","",""]);
    const [iPartidos, setIPartidos] = useState("0");
    const [iPuntos, setIPuntos] = useState(["",""]);
    const [iJugadores, setIJugadores] = useState(["0","0","0","0"]);
    const [iGoles, setIGoles] = useState(["0","0","0","0","0","0","0"]);
    const [disparosTotales, setDisparosTotales] = useState("");
    const [iDisparos, setIDisparos] = useState(["","","","","","","","","","",""]);
    const [iEfectividad, setIEfectividad] = useState(["","","","","","","",""]);   
    const [iPorteria, setIPorteria] = useState(["",""]);   
    const [iAutogoles, setIAutogoles] = useState("0");
    const [iFairPlay, setIFairPlay] = useState("0");
    const [iTiempo, setITiempo] = useState(["","","","","","",""]);
    
    let icono=ic_atajada;
    let texto="Atajadas";
    if(tieneTarjetaAzul){
        icono=ic_tarjeta_azul;
        texto="T Azules";
    }


    let titulos=["Puntos","Goles","En Contra","Disparos al Arco","Faltas Recibidas","Faltas Cometidas","T Amarillas",texto,"T Rojas"];
    let iconos=["",ic_balon_gol,ic_balon_autogol,ic_disparo,ic_falta_recibida,ic_falta_cometida,ic_amarilla,icono,ic_tarjeta_roja];

  
    useEffect(() => {        
        asignarDatosFinales();      
        
        registrarLogEventos(idModulo, "5", idEquipo,"" );

    }, [idEquipo]);


    

    const asignarDatosFinales = () => {
        const nomFuncion="asignarDatosFinales";

        try{
            let datosFinales=dividirLista("","|",34);
            let datosEstadisticas=dividirLista("","|",10);
            let datosPJ="0";
            let datosPuntos=["",""];
            let datosJugadores=["0","0","0","0"];
            let datosGoles=["0","0","0","0","0","0","0"];
            let disparosTotales="0";
            let datosDisparos=["","","","","","","","","","",""];
            let datosEfectividad=["Efectividad de Disparo","","Goles/Total Disparos","Goles/Disparos al Arco","","","",""];
            let datosPorteria=["",""];
            let autogoles="0";
            let fairPlay="0";
            let datosTiempo=["","","","","","",""];
            
            
            let i;
            let indice=0;
            let sinDatos=true;

            if(infoClasificacion.length>0){
                for(i=0;i<infoClasificacion.length;i++){
                    if(infoClasificacion[i][2]===idEquipo){
                        sinDatos=false;
                        indice=i;
                        i=infoClasificacion.length;
                    }
                }

                if(!sinDatos) { 

                    let tempInt1=0;
                    let tempInt2=0;
                    let tempFloat=0;
                    let puntos=3;
                    if(valorNumero(puntosGanador)>0) puntos=valorNumero(puntosGanador);

                    tempInt1=valorNumero(infoClasificacion[indice][6])*puntos;

                    datosFinales[0]=infoClasificacion[indice][10] + "/" + tempInt1; //Puntos/puntos Totales
                    datosFinales[1]=infoClasificacion[indice][7]; //goles
                    datosFinales[2]=infoClasificacion[indice][8]; //goles en contra

                    if(tipo_registroinfo===("2")){
                        datosFinales[3]=infoClasificacion[indice][18]; //Disparos al arco
                        datosFinales[4]=infoClasificacion[indice][11]; //faltas recibidas
                        datosFinales[5]=infoClasificacion[indice][12]; //faltas cometidas
                        datosFinales[7]=infoClasificacion[indice][16]; //atajadas
                    }else{
                        datosFinales[3]="-"; //Disparos al arco
                        datosFinales[4]="-"; //faltas recibidas
                        datosFinales[5]="-"; //faltas cometidas
                        datosFinales[7]="-"; //atajadas
                    }

                    datosFinales[6]=infoClasificacion[indice][13]; //tarjetas amarillas
                    datosFinales[8]=infoClasificacion[indice][14]; //tarjetas rojas
                    datosFinales[9]=infoClasificacion[indice][6]; //partidos jugados

                    if(tieneTarjetaAzul) datosFinales[7]=infoClasificacion[indice][38]; //tarjetas azules

                    datosFinales[10]=infoClasificacion[indice][10]; //puntos
                    tempInt2=valorNumero(infoClasificacion[indice][10]);
                    if(tempInt1>0) tempFloat=(tempInt2/tempInt1)*100; //% puntos obtenidos del total
                    datosFinales[11]=formatearPorcentaje(tempFloat); //% puntos obtenidos del total

                    datosFinales[12]=jugadoresActivos; //Jugadores activos del equipo

                    datosFinales[13]=""; //Prom x Equipo
                    tempFloat=valorNumero(infoClasificacion[indice][24])/100;
                    datosFinales[14]=formatearDecimal(tempFloat); //Prom x Equipo x Partido
                    tempFloat=valorNumero(infoClasificacion[indice][25])/100;
                    datosFinales[15]=formatearDecimal(tempFloat); //Prom cambios x partido

                    tempInt2=valorNumero(infoClasificacion[indice][7]);
                    tempInt1=valorNumero(infoClasificacion[indice][21]);
                    datosFinales[16]=infoClasificacion[indice][21]; //En Jugada
                    if(tempInt2>0){
                        tempFloat=(tempInt1/tempInt2)*100;
                        datosFinales[17]=formatearPorcentaje(tempFloat); //% goles de jugada
                    }
                    else datosFinales[17]="0%";

                    tempInt1=valorNumero(infoClasificacion[indice][22]);
                    datosFinales[18]=infoClasificacion[indice][22]; //Tiro Libre
                    if(tempInt2>0){
                        tempFloat=(tempInt1/tempInt2)*100;
                        datosFinales[19]=formatearPorcentaje(tempFloat); //% goles de tiro libre
                    }
                    else datosFinales[19]="0%";

                    tempInt1=valorNumero(infoClasificacion[indice][23]);
                    datosFinales[20]=infoClasificacion[indice][23]; //Penalti
                    if(tempInt2>0){
                        tempFloat=(tempInt1/tempInt2)*100;
                        datosFinales[21]=formatearPorcentaje(tempFloat); //% goles de penalti
                    }
                    else datosFinales[21]="0%";

                    tempInt1=valorNumero(infoClasificacion[indice][17]);
                    tempInt2=valorNumero(infoClasificacion[indice][18]);
                    datosFinales[22]=infoClasificacion[indice][17]; //Total Disparos
                    datosFinales[23]=infoClasificacion[indice][18]; //Al Arco
                    datosFinales[24]="" + (tempInt1-tempInt2); //Desviados

                    tempInt2=valorNumero(infoClasificacion[indice][7]);
                    tempInt1=valorNumero(infoClasificacion[indice][17]);
                    tempFloat=0;
                    if(tempInt1>0) tempFloat=(tempInt2/tempInt1)*100;
                    datosFinales[25]=formatearPorcentaje(tempFloat);// efectividdad goles/disp totales
                    tempInt1=valorNumero(infoClasificacion[indice][18]);
                    tempFloat=0;
                    if(tempInt1>0) tempFloat=(tempInt2/tempInt1)*100;
                    datosFinales[26]=formatearPorcentaje(tempFloat);// efectividdad goles/disp arco

                    datosFinales[27]=infoClasificacion[indice][19]; //autogoles
                    datosFinales[28]=infoClasificacion[indice][15]; //fairplay


                    let tempTiempo=0;
                    let horas=0,minutos=0,segundos=0;

                    tempTiempo=valorNumero(infoClasificacion[indice][28]);
                    let valorTiempo="";
                    horas=valorNumero(tempTiempo/3600);
                    minutos=valorNumero((tempTiempo-(horas*3600))/60);
                    segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                    if(horas>0) valorTiempo=horas + " h ";
                    if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                    if(horas==0 && segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                    datosFinales[29]=valorTiempo; //Tiempo Jugado Total

                    tempTiempo=valorNumero(infoClasificacion[indice][26]);
                    valorTiempo="";
                    horas=valorNumero(tempTiempo/3600);
                    minutos=valorNumero((tempTiempo-(horas*3600))/60);
                    segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                    if(horas>0) valorTiempo=horas + " h ";
                    if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                    if(horas==0 && segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                    datosFinales[30]=valorTiempo; //Tiempo Jugado 1er tiempo

                    tempTiempo=valorNumero(infoClasificacion[indice][27]);
                    valorTiempo="";
                    horas=valorNumero(tempTiempo/3600);
                    minutos=valorNumero((tempTiempo-(horas*3600))/60);
                    segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                    if(horas>0) valorTiempo=horas + " h ";
                    if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                    if(horas==0 && segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                    datosFinales[31]=valorTiempo; //Tiempo Jugado 1er tiempo


                    tempInt2=valorNumero(infoClasificacion[indice][8]);
                    tempInt1=valorNumero(infoClasificacion[indice][16]);
                    tempFloat=0;
                    if((tempInt1+tempInt2)>0) tempFloat=(tempInt1/(tempInt1+tempInt2))*100;
                    datosFinales[32]=formatearPorcentaje(tempFloat); // Efectividad Atajadas
                    datosFinales[33]=tempInt1 + "/" + (tempInt1+tempInt2);


                    /////ASDIGNAR INFO
                    
                    datosEstadisticas=asignarValoresArray(datosFinales,0,9);
                    datosPJ=datosFinales[9];
                    datosPuntos=asignarValoresArray(datosFinales,10,11);
                    datosJugadores=asignarValoresArray(datosFinales,12,15);
                    datosGoles=asignarValoresArray(datosFinales,15,21);
                    datosGoles[0]=datosFinales[1];

                    if(tipo_registroinfo===("2")){
                        disparosTotales=datosFinales[22];
                        datosDisparos[0]=datosFinales[24]; //disparos desviados
                        datosDisparos[3]=datosFinales[23]; //disparos al arco

                        datosEfectividad[4]=datosFinales[25];
                        datosEfectividad[5]=datosFinales[26];
                        datosEfectividad[6]=datosFinales[1] + "/" + datosFinales[22];
                        datosEfectividad[7]=datosFinales[1] + "/" + datosFinales[23];   
                        datosPorteria[0]=datosFinales[33];
                        datosPorteria[1]=datosFinales[32];                     
                    }

                    

                    autogoles=datosFinales[27];
                    fairPlay=datosFinales[28];
                    
                    datosTiempo[0]=datosFinales[29]; //Tiempo Jugado Total
                    datosTiempo[2]=datosFinales[30]; //Tiempo 1er tiempo
                    datosTiempo[4]=datosFinales[31]; //Tiempo 2do tiempo

                }
            }                  

            
            setIEstadisticas(datosEstadisticas);
            setIPartidos(datosPJ);
            setIPuntos(datosPuntos);
            setIJugadores(datosJugadores);
            setIGoles(datosGoles);
            setDisparosTotales(disparosTotales);
            setIDisparos(datosDisparos);
            setIEfectividad(datosEfectividad);
            setIPorteria(datosPorteria);
            setIAutogoles(autogoles);
            setIFairPlay(fairPlay);
            setITiempo(datosTiempo);
            

            
        }
        catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const asignarValoresArray = (datos, inicio, fin) => {
        const nomFuncion="asignarValoresArray";
        let arreglo=[];
        try{
            arreglo=dividirLista("","|",((fin+1)-inicio));
            let i;
    
            for(i=0;i<arreglo.length;i++){
                arreglo[i]=datos[inicio+i];
            }
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }        

        return arreglo;
    }


    return (
        <>
            {(iEstadisticas.length>0) && (iEstadisticas[0]!=="") && 
            (<><div className='CT_contenedorFlex' style={{marginTop: "15px", marginBottom:"15px"}}>
                <DinamicoEstadisticasJugador titulos={titulos} iconos={iconos} datos={iEstadisticas} />   

                <div className='CT_regSancion' style={{minWidth:"280px"}}>
                    <div className='BF_cajaGris' style={{margin:"0px"}}>
                        <p className='TX_tituloCaja'>Información Adicional</p>
                        <div className='BF_cajaBlanca'>
                            <DinamicoResumen2Cols imagen={ic_play_arrow} titulo={"Partidos Jugados"} texto1={iPartidos} texto2={" "} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumen2Cols imagen={ic_puntos} titulo={"Puntos Obtenidos"} texto1={iPuntos[0]} texto2={iPuntos[1]} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumenJugadores datos={iJugadores} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumenGoles tipo={1} datos={iGoles} />
                            <div className='BF_lineaInferior'></div>
                           {(tipo_registroinfo==="2")  && 
                                (<>
                                <DinamicoResumen2Cols imagen={ic_disparo_arco} titulo={"Disparos Totales"} texto1={disparosTotales} texto2={" "} />
                                <DinamicoResumenDisparos tipo={1} datos={iDisparos}/>   
                                <DinamicoResumenEfectividad datos={iEfectividad} />
                                <div className='BF_lineaInferior'></div>

                                <DinamicoResumen2Cols imagen={ic_atajada} titulo={"Efectividad de Porteria"} texto1={iPorteria[0]} texto2={iPorteria[1]} />
                                <div className='BF_lineaInferior'></div>
                            </>)}

                            
                            <DinamicoResumen2Cols imagen={ic_balon_autogol} titulo={"Autogoles"} texto1={iAutogoles} texto2={" "} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumen2Cols imagen={ic_fairplay} titulo={"Puntos FairPlay"} texto1={iFairPlay} texto2={" "} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumenTiempo datos={iTiempo}/>
                        </div>
                    </div>     
                       
                </div>
            </div>
            
            </>)
            }
        </>
    );
};


export default DE_InfoEstadisticasEquipo;