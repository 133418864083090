import { C_uniformeGrisOscuro, C_uniformeNegro } from "../constantes/generales";
import "../estilos/IM_Imagenes.css";

export const listaDosItems = ({ value, label, texto2 }) => (
    <div style={{display: "block", textAlign: "left", margin: "0", padding:"0"}}>        
        <div style={{ color: C_uniformeNegro, fontSize:"16px"}}>{label}</div>
        {texto2!=="" && (<div style={{  color: C_uniformeGrisOscuro, fontSize:"14px", whiteSpace: "pre" }}> {texto2} </div>)}        
    </div>     
  );


  export const listaIconoDosItems = ({ value, label, texto2, imagen }) => (
    <div style={{display: "flex", flexWrap: "nowrap", alignItems: "center"}}>
      {imagen!=="" && (<div><img className="IM_imgRedondeada36" height="36px" width="36px" src={imagen} alt="" style={{padding:"4px"}}/></div>)}      
      <div style={{display: "block", textAlign: "left", marginLeft: "5px"}}>        
        <div style={{ color: C_uniformeNegro, fontSize:"16px" }}>{label}</div>
        <div style={{ color: C_uniformeGrisOscuro, fontSize:"14px", whiteSpace: "pre" }}>
          {texto2}
        </div>
      </div>
    </div>      
  );