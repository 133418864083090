import Modal from "./Modal";
import Select from "react-select";

import {estilosSelect} from '../estilos/estilosSelect'
import { listaDosItems } from "./listaDesplegable";
import { useEffect, useState } from "react";
import { esformatoEmail, esformatoNumero, formatearTexto, openInNewTab, quitarCharEspeciales, recuperarDatoLocal, reportarError, showMensajePantalla, valorNumero } from "../helpers/funciones";
import { useModal } from "../helpers/useModal";
import { C_uniformeAzulClaro, C_uniformeVerdeClaro, SEPARADOR_PARAMETROS_BD } from "../constantes/generales";

import ic_llamada from '../recGraficos/ic_llamada.png';
import ic_whatsapp from '../recGraficos/ic_whatsapp.png';
import ic_email from '../recGraficos/ic_email.png';


const nomClase="ContactoMG_WB";

let ventanaMensaje;


let matrizCampos=[
    {id: "NOMBRE",  idx: 0, valor: "", ultValor:"", error: "Ingrese su nombre"},
    {id: "CORREO",  idx: 1, valor: "", ultValor:"", error: "Ingrese un correo válido"},
    {id: "PAIS",  idx: 2, valor: "", ultValor:"", error: "Seleccione el país de contacto"},
    {id: "TELEFONO",  idx: 3, valor: "", ultValor:"", error: "Ingrese un teléfono válido"},
    {id: "ASUNTO",  idx: 4, valor: "", ultValor:"", error: "Seleccione el asunto del contacto"},
    {id: "MENSAJE",  idx: 5, valor: "", ultValor:"", error: "Ingrese su mensaje"}
];

let paises=[
    {value:"0", label:"Selecciona tú País", texto2:""},
    {value:"57", label:"Colombia", texto2:""},
    {value:"54", label:"Argentina", texto2:""},
    {value:"56", label:"Chile", texto2:""},
    {value:"593", label:"Ecuador", texto2:""},
    {value:"1", label:"Estados Unidos", texto2:""},
    {value:"52", label:"México", texto2:""},
    {value:"51", label:"Perú", texto2:""},
    {value:"-1", label:"Otro", texto2:""}
];

let asuntos=[
    {value:"0", label:"Selecciona tú asunto", texto2:""},
    {value:"1", label:"Pregunta sobre la funcionalidad", texto2:""},
    {value:"2", label:"Soporte Técnico", texto2:""},
    {value:"3", label:"Reportar un problema", texto2:""},
    {value:"4", label:"Sugerencia, comentario, felicitación", texto2:""},
    {value:"5", label:"Otro asunto", texto2:""}
];


const ContactoMG = ({datos, funcionExterna, cerrar, idSede=0, idUser=0, tipoUser="invitado" }) => {
    const [arrCampos, setArrCampos] = useState(matrizCampos);  
    const [isOpenContactoMG, openContactoMG, closeContactoMG] = useModal(true);
    const [showMensaje, setShowMensaje] = useState(false);

    const [datosMDContacto, setDatosMDContacto] = useState([]);
    const [acepto, setAcepto] = useState(false);


    const [paisesUS, setPaisesUS] = useState(paises);
    const [asuntosUS, setAsuntosUS] = useState(asuntos);
    const [posPaisSel, setPosPaisSel] = useState(0);
    const [posAsuntoSel, setPosAsuntoSel] = useState(0);
    const [txtIndicativo, setTxtIndicativo] = useState("");


    useEffect(() => {
        let nomFuncion="useEffect"; 

        try{

            let tempDatos=JSON.parse(JSON.stringify(matrizCampos));
            if(datos!==null) tempDatos=JSON.parse(JSON.stringify(datos));

            setAcepto(false);
            setPosPaisSel(0);
            setPosAsuntoSel(0);

            let tempArreglo=JSON.parse(JSON.stringify(tempDatos));
            tempArreglo[3].valor="";
            tempArreglo[5].valor="";
            tempArreglo[2].error=matrizCampos[2].error;
            tempArreglo[3].error=matrizCampos[3].error;
            tempArreglo[4].error=matrizCampos[4].error;
            tempArreglo[5].error=matrizCampos[5].error;

            setArrCampos(tempArreglo);

            if(datos!==null){
                let indicativo=recuperarDatoLocal("indicativo");

                if(indicativo==="57") setPosPaisSel(1);
                else if(indicativo==="54") setPosPaisSel(2);
                else if(indicativo==="56") setPosPaisSel(3);
                else if(indicativo==="593") setPosPaisSel(4);
                else if(indicativo==="1") setPosPaisSel(5);
                else if(indicativo==="52") setPosPaisSel(6);
                else if(indicativo==="51") setPosPaisSel(7);
                else setPosPaisSel(0);
        
                if(valorNumero(indicativo)>0) setTxtIndicativo("+" + indicativo);
                else setTxtIndicativo(indicativo);
            }
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }


    }, []);




    const clickGenerico = (donde, params=null) => {
        let nomFuncion="clickGenerico"; 

        try{
            let titulo="";
            let mensaje="";
            //let params="";

            let tempArreglo;
            
            switch(donde) {
                case "CAMBIAR_PAIS":
                    let codigo=params.value;
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    tempArreglo[2].error="";

                    if(codigo==="57") setPosPaisSel(1);
                    else if(codigo==="54") setPosPaisSel(2);
                    else if(codigo==="56") setPosPaisSel(3);
                    else if(codigo==="593") setPosPaisSel(4);
                    else if(codigo==="1") setPosPaisSel(5);
                    else if(codigo==="52") setPosPaisSel(6);
                    else if(codigo==="51") setPosPaisSel(7);
                    else if(codigo==="-1") setPosPaisSel(8);
                    else{
                        setPosPaisSel(0);
                        tempArreglo[2].error=matrizCampos[2].error;
                    } 

                    if(codigo==="0") setTxtIndicativo("");
                    else if(codigo==="-1") setTxtIndicativo("+");
                    else setTxtIndicativo("+" + codigo);

                    setArrCampos(tempArreglo);

                    break;
                case "CAMBIAR_ASUNTO":
                    let valor=params.value;
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));

                    if(valorNumero(valor)>0) tempArreglo[4].error="";
                    else tempArreglo[4].error=matrizCampos[4].error;

                    setArrCampos(tempArreglo);
                    setPosAsuntoSel(valor);

                    break;
                case "ENVIAR_MENSAJE_CONTACTO":
                    setAcepto(true);
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));

                    if(posPaisSel>0) tempArreglo[2].error="";
                    if(posAsuntoSel>0) tempArreglo[4].error="";

                    setArrCampos(tempArreglo);

                    if(tempArreglo[0].error!=="" || tempArreglo[1].error!=="" || tempArreglo[2].error!=="" || tempArreglo[3].error!=="" ||
                        tempArreglo[4].error!=="" || tempArreglo[5].error!==""){
                            titulo="CON ERRORES";
                            mensaje="Los campos presentan errores, corrija la información y continue el proceso.";
                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","BAJA");                        
                    }
                    else{
                        let SEP=SEPARADOR_PARAMETROS_BD;
                        
                        let nombre= formatearTexto(quitarCharEspeciales(arrCampos[0].valor),2);
                        let correo=arrCampos[1].valor.toLowerCase();
                        let txtPais=paises[posPaisSel].label;
                        let indicativo=paises[posPaisSel].value;
                        let telefono=arrCampos[3].valor;
                        let txtAsunto=asuntos[posAsuntoSel].label;
                        let mensaje=quitarCharEspeciales(arrCampos[5].valor);

                        let paramQuery=  idSede + SEP + idUser + SEP + tipoUser + SEP + nombre + SEP + correo + SEP + txtPais + SEP + indicativo + SEP + telefono + SEP + txtAsunto + SEP + mensaje;
                        

                        funcionExterna("ENVIAR_MENSAJE_CONTACTO", paramQuery);

                        cerrar();
                    }



                    break;
                default:
                    break;
              }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 


    const validarCampo = (e, campo, maxlen) => {
        let nomFuncion="validarCampo"; 

        try{
            let newValor = e.target.value;
            if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);

            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

            if(campo==="MD_NOMBRE"){  
                tempArreglo[0].error=matrizCampos[0].error;
                
                tempArreglo[0].valor=newValor;  
                if(newValor.length>=5) {
                    tempArreglo[0].error="";     
                }
                setArrCampos(tempArreglo);  
            } else if(campo==="MD_CORREO"){  
                tempArreglo[1].error=matrizCampos[1].error;
                
                tempArreglo[1].valor=newValor; 
                if(esformatoEmail(newValor)) {
                    tempArreglo[1].error="";   
                }               
                
                setArrCampos(tempArreglo);  
            } else if(campo==="MD_TELEFONO"){  
                tempArreglo[3].error=matrizCampos[3].error;
                
                tempArreglo[3].valor=newValor; 
                if(esformatoNumero(newValor) && newValor.length>=10) {
                    tempArreglo[3].error="";   
                }               
                
                setArrCampos(tempArreglo);  
            }  else if(campo==="MD_MENSAJE"){  
                tempArreglo[5].error=matrizCampos[5].error;
                
                tempArreglo[5].valor=newValor; 
                if(newValor.length>=5) {
                    tempArreglo[5].error="";   
                }               
                
                setArrCampos(tempArreglo);  
            }          
                    
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
        
    }




    return(
        <>

            <Modal isOpen={isOpenContactoMG} closeModal={cerrar}  titulo={"Contacto MarcaGol"} txtAceptar='' txtCancelar=''>
                <div style={{marginTop:"20px", marginBottom:"30px", marginInline:"20px", maxWidth:"500px", textAlign:"left"}}>

                    
                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px", textAlign:"left"}}>
                    <div style={{width:"100px"}}>Nombre*</div>
                    <input type="text" value={arrCampos[0].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "400px"}} placeholder="" onChange={(e) => validarCampo(e, "MD_NOMBRE", 40)}/>   
                </div>
                {acepto && arrCampos[0].error!==""  && ( <div><span className="TX_error">{arrCampos[0].error}</span></div> )}

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px", textAlign:"left"}}>
                    <div style={{width:"100px"}}>Correo*</div>
                    <input type="email" value={arrCampos[1].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "400px"}} placeholder="" onChange={(e) => validarCampo(e, "MD_CORREO", 40)}/>   
                </div>
                {acepto && arrCampos[1].error!==""  && ( <div><span className="TX_error">{arrCampos[1].error}</span></div> )}

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px", textAlign:"left"}}>
                    <div style={{width:"100px"}}>País*</div>                    
                    <div style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "400px"}}>
                        <Select     
                            styles={estilosSelect}
                            formatOptionLabel={listaDosItems}
                            value={paisesUS[posPaisSel]}
                            options={paisesUS}
                            isSearchable={false}   
                            onChange={(e,index) => {clickGenerico("CAMBIAR_PAIS",e)}}            
                        />      
                    </div>   
                </div>
                {acepto && arrCampos[2].error!==""  && ( <div><span className="TX_error">{arrCampos[2].error}</span></div> )}

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px", textAlign:"left"}}>
                    <div style={{width:"100px"}}>Teléfono*</div>
                    <div style={{fontSize:"16px", fontWeight:"bold", paddingInline:"4px", color: C_uniformeVerdeClaro}}>{txtIndicativo}</div>
                    <input type="number" value={arrCampos[3].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "400px"}} placeholder="" onChange={(e) => validarCampo(e, "MD_TELEFONO", 14)}/>   
                </div>
                {acepto && arrCampos[3].error!==""  && ( <div><span className="TX_error">{arrCampos[3].error}</span></div> )}

                <div style={{marginTop:"10px"}}>Asunto*</div>
                <div >
                        <Select     
                            styles={estilosSelect}
                            defaultValue={asuntosUS[0]}
                            formatOptionLabel={listaDosItems}
                            options={asuntosUS}
                            isSearchable={false}   
                            onChange={(e,index) => {clickGenerico("CAMBIAR_ASUNTO",e)}}            
                        />      
                </div> 
                {acepto && arrCampos[4].error!==""  && ( <div><span className="TX_error">{arrCampos[4].error}</span></div> )}

                <div style={{marginTop:"10px"}}>Mensaje*</div>
                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", textAlign:"left"}}>
                    <textarea maxLength={1000} value={arrCampos[5].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "400px", height:"60px"}} placeholder=""  onChange={(e) => validarCampo(e, "MD_MENSAJE", 1000)}/> 
                </div>
                {acepto && arrCampos[5].error!==""  && ( <div><span className="TX_error">{arrCampos[5].error}</span></div> )}


                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", textAlign:"left", marginTop:"10px"}}>
                    <div style={{flex:"1"}}></div>
                    <div className='BF_botonPrimaryFull' style={{paddingInline:"20px", marginBottom:"20px"}} onClick={() => clickGenerico("ENVIAR_MENSAJE_CONTACTO")}>ENVIAR MENSAJE</div>
                </div>
                
                <div>Otros medios de contacto</div>

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", margin:"10px", cursor:"pointer"}} onClick={() => openInNewTab("https://wa.me/573508111502")} >
                        <img src={ic_whatsapp} className="IM_imgIcono24" alt=""/>
                        <div className='TX_unaSolaLinea2' style={{marginLeft:"20px", color:C_uniformeVerdeClaro, textAlign:"left", fontWeight:"bold"}}>{"WhatsApp\n(+57 3508111502)"}</div>
                </div>

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", margin:"10px", cursor:"pointer"}} onClick={() => openInNewTab("mailto:contacto@marcagol.net")} >
                        <img src={ic_email} className="IM_imgIcono24" alt=""/>
                        <div className='TX_unaSolaLinea2' style={{marginLeft:"20px", color:C_uniformeAzulClaro, textAlign:"left", fontWeight:"bold"}}>{"Correo\n(contacto@marcagol.net)"}</div>
                </div>


                </div>  
            </Modal>

            {showMensaje && (ventanaMensaje)}

        </>

        



    );

};


export default ContactoMG;