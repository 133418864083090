import { useState, useEffect } from 'react';


import { valorFloat, valorNumero, formatearDecimal, reportarError } from '../helpers/funciones';


import ic_transparente from '../recGraficos/ic_transparente.png';
import { C_uniformeNegro } from '../constantes/generales';

const DinamicoEstadisticasJugador = ({ titulos, iconos, datos }) => {
    const nomClase="DinamicoEstadisticasJugador_WB";
    const nomFuncion="DinamicoEstadisticasJugador";

    const [sacarMedia, setSacarMedia] = useState(false);
    //const [divisor, setDivisor] = useState(0);
   // const [datosF, setDatosF] = useState(["","","","","","","",""]);

   try{


        let dato1, dato2, dato3, dato4, dato5, dato6, dato7, dato8;

        
            if(datos=== null || datos[0]==="") return null;
                
            let res=0;
            let tempDato=datos[0].replace("%","");
            let temp=tempDato.split("/");
            if(temp.length===1){
                let dat1= valorFloat(temp[0]);
                if(dat1<0) res=0;
                else if(dat1>100) res=100;
                else res= dat1;
                res=res/100;
            }
            else if(temp.length===2){
                let dat1=valorFloat(temp[0]);
                let dat2=valorFloat(temp[1]);
        
                if(dat2>0) res=(dat1/dat2);
                else res=0;
            }
        
            document.documentElement.style.setProperty('--ratio', res);
        
            
        
            dato1=datos[1];
            dato2=datos[2];
            dato3=datos[3];
            dato4=datos[4];
            dato5=datos[5];
            dato6=datos[6];
            dato7=datos[7];
            dato8=datos[8];
        
            let divisor=(valorNumero(datos[9]));
        
        
            if(sacarMedia && divisor>0){
                if(dato1!=="-") dato1=formatearDecimal(valorFloat(datos[1])/divisor);
                if(dato2!=="-") dato2=formatearDecimal(valorFloat(datos[2])/divisor);
                if(dato3!=="-") dato3=formatearDecimal(valorFloat(datos[3])/divisor);
                if(dato4!=="-") dato4=formatearDecimal(valorFloat(datos[4])/divisor);
                if(dato5!=="-") dato5=formatearDecimal(valorFloat(datos[5])/divisor);
                if(dato6!=="-") dato6=formatearDecimal(valorFloat(datos[6])/divisor);
                if(dato7!=="-") dato7=formatearDecimal(valorFloat(datos[7])/divisor);
                if(dato8!=="-") dato8=formatearDecimal(valorFloat(datos[8])/divisor);                           
            }
        
            //setDatosF([dato1, dato2, dato3, dato4, dato5, dato6, dato7, dato8]);



        return(
            
            <div className="CT_regSancion TX_letraPeq" style={{minWidth:"280px"}}>

                
                    {divisor>0 && (
                        <div style={{display:"flex", justifyContent:"right", alignItems:"center", height:"20px", marginRight:"10px", marginBottom:"10px"}}>
                        <p>Totales</p>
                        <label className="switch">
                            <input type="checkbox" onChange={() => {setSacarMedia(!sacarMedia)}}/><span className="slider round"></span>
                        </label>
                        <p>Media PP</p>
                        </div>
                    )}
                    
                
                
            
                <div className="BF_bordeTotal3" style={{position: "relative", height:"320px", margin:"0px", minWidth:"300px"}}>
                    

                    <div style={{position: "absolute", left: "0px", top: "0px", height:"100%", width:"100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div className="ratio"></div>
                    </div>

                    <table height="100%" width="100%" border="0" style={{position: "absolute", left: "0px", top: "0px", tableLayout:"fixed"}}>
                        <tbody>
                            <tr height="33%">
                                <td>
                                    <table width="100%" height="100%"><tbody>
                                        <tr><td><img className="IM_imgIcono24" src={iconos[1]} alt="" /></td><td width="100%" className="TX_textoPeq">{titulos[1]}</td></tr>
                                        <tr height="100%"><td colSpan="2" style={{align:"center", fontSize: "26px", textAlign: "center", color:C_uniformeNegro}}>{dato1}</td></tr>
                                    </tbody></table>                               
                                </td>
                                <td>
                                    <table height="100%" align="center"><tbody>
                                        <tr><td><img className="IM_imgIcono24" src={iconos[2]} alt="" /></td><td className="TX_textoPeq">{titulos[2]}</td></tr>
                                        <tr height="100%"><td colSpan="2" style={{align:"center", fontSize: "26px", textAlign: "center", color:C_uniformeNegro}}>{dato2}</td></tr>
                                    </tbody></table>  
                                </td>
                                <td>
                                    <table width="100%" height="100%"><tbody>
                                        <tr><td width="100%" className="TX_textoPeq" style={{textAlign: "right"}}>{titulos[3]}</td><td><img className="IM_imgIcono24" src={iconos[3]} alt="" /></td></tr>
                                        <tr height="100%"><td colSpan="2" style={{align:"center", fontSize: "26px", textAlign: "center", color:C_uniformeNegro}}>{dato3}</td></tr>
                                    </tbody></table>
                                </td>
                            </tr>
                            <tr height="34%">
                                <td>
                                    <table width="100%" height="100%" ><tbody>
                                        <tr><td colSpan={3} height="25%"></td></tr>
                                        <tr height="100%" width="100%">
                                            <td><img className="IM_imgIcono24" src={iconos[4]} alt="" /></td>
                                            <td width="100%" style={{align:"center", fontSize: "26px", textAlign: "center", color:C_uniformeNegro}}>{dato4}</td>
                                            <td><img className="IM_imgIcono24" src={ic_transparente} alt="" /></td></tr>
                                        <tr height="25%"><td colSpan={3} width="100%" className="TX_textoPeq" style={{textAlign:"center"}}>{titulos[4]}</td></tr>                                    
                                    </tbody></table>                               
                                </td>
                                <td align='center' valign='center'>
                                    <div style={{display:"flex", flexDirection:"column", width:"80px", height:"80px", alignItems:"center", justifyContent:"center"}}>

                                        <div className="TX_textoPeq" style={{textAlign:"center"}}>{titulos[0]}</div>
                                        <div style={{fontSize:"18px", color:C_uniformeNegro, textAlign:"center"}}>{datos[0]}</div>
                                    </div>
                                    
                                </td>
                                <td>
                                    <table width="100%" height="100%" ><tbody>
                                        <tr><td colSpan={3} height="25%"></td></tr>
                                        <tr height="100%" width="100%">
                                            <td><img className="IM_imgIcono24" src={ic_transparente} alt="" /></td>
                                            <td width="100%" style={{align:"center", fontSize: "26px", textAlign: "center", color:C_uniformeNegro}}>{dato5}</td>
                                            <td><img className="IM_imgIcono24" src={iconos[5]} alt="" /></td>
                                            </tr>
                                        <tr height="25%"><td colSpan={3} width="100%" className="TX_textoPeq" style={{textAlign:"center"}}>{titulos[5]}</td></tr>                                    
                                    </tbody></table> 
                                </td>
                            </tr>
                            <tr height="33%">
                                <td>
                                    <table width="100%" height="100%"><tbody>
                                    <tr height="100%"><td colSpan="2" style={{align:"center", fontSize: "26px", textAlign: "center", color:C_uniformeNegro}}>{dato6}</td></tr>
                                        <tr><td><img className="IM_imgIcono24" src={iconos[6]} alt="" /></td><td width="100%" className="TX_textoPeq">{titulos[6]}</td></tr>                                    
                                    </tbody></table>                               
                                </td>
                                <td>
                                    <table height="100%" align="center"><tbody>                                    
                                        <tr height="100%"><td colSpan="2" style={{align:"center", fontSize: "26px", textAlign: "center", color:C_uniformeNegro}}>{dato7}</td></tr>
                                        <tr><td><img className="IM_imgIcono24" src={iconos[7]} alt="" /></td><td className="TX_textoPeq">{titulos[7]}</td></tr>
                                    </tbody></table>  
                                </td>
                                <td>
                                    <table width="100%" height="100%"><tbody>                                    
                                        <tr height="100%"><td colSpan="2" style={{align:"center", fontSize: "26px", textAlign: "center", color:C_uniformeNegro}}>{dato8}</td></tr>
                                        <tr><td width="100%" className="TX_textoPeq" style={{textAlign: "right"}}>{titulos[8]}</td><td><img className="IM_imgIcono24" src={iconos[8]} alt="" /></td></tr>
                                    </tbody></table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                

                </div>
            
            </div>

        );


    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

};


export default DinamicoEstadisticasJugador;