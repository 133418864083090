import { formatearFecha, esformatoHoraDia, valorNumero, operacionFechas,sumarMinToHoras, recuperarDatoLocal, reportarError, formatearTexto,  } from "../helpers/funciones";
import {  operacionHorasDia, difHoras, difFechas } from "../helpers/funciones";


import ic_escudo_base2 from '../recGraficos/ic_escudo_base2_w.png';
import ic_escudo_base from '../recGraficos/ic_escudo_base.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_aceptar from '../recGraficos/ic_aceptar.png';
import ic_aceptar_gris from '../recGraficos/ic_aceptar_gris.png';
import ic_cancelar from '../recGraficos/ic_cancelar.png';
import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png';
import ic_ubicacion_verde from '../recGraficos/ic_ubicacion_verde.png';

import Stars from "../recVisual/Stars";
import { useEffect, useState } from "react";
import { C_uniformeRojo, C_uniformeBlanco, C_uniformeGrisClaro, C_uniformeGrisOscuro, ID_MODULO_DETALLE_PARTIDO, C_uniformeNegro, C_uniformeVerdeClaro, C_tarjetaAmarilla } from "../constantes/generales";


const nomClase="DinamicoPartidosProgramados_WB";
//const idModulo=ID_MODULO_DETALLE_TORNEO;


let imgAcepto=ic_aceptar_gris;
let imgRechazo=ic_cancelar_gris;

const ruta=recuperarDatoLocal("rutaRecursos");


const DinamicoPartidosProgramados = ({ registro=0, partido, linea, tipoPartido="dpp", modOrigen, paramOrigen, definirModulo, idJugador="", clickGenerico, index=-1 }) => {
    const nomFuncion="DinamicoPartidosProgramados";

    const [asistira, setAsistira] = useState("2");

    useEffect(() => {
        const nomFuncion="useEffect";

        try{     
            if(tipoPartido==="ppj"){ 

                if(partido){
                    if(partido.confirmoAsistencia==="1"){
                        imgAcepto=ic_aceptar;
                        imgRechazo=ic_cancelar_gris;
                        setAsistira("1");
                    }else{
                        if(partido.fechaConfirmo===""){
                            imgAcepto=ic_aceptar_gris;
                            imgRechazo=ic_cancelar_gris;
                            setAsistira("2");
                        }
                        else{
                            imgAcepto=ic_aceptar_gris;
                            imgRechazo=ic_cancelar;
                            setAsistira("3");
                        }
                    }
                } 
            } 

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    }, [partido]);

    try{        

        if(!partido) return null;

        let cssRegistro={
            cursor:"pointer"
        };

        

        let datos=partido;
        if(datos.idPartido==="") cssRegistro.cursor="";

        let txtFecha=formatearFecha(datos.fechaPartido,13);
        let txtHora=esformatoHoraDia(datos.horaPartido);
        let txtMarcador=datos.golesEq1 + " : " + datos.golesEq2;
        let txtPenaltis="(" + datos.penaltisEq1 + " - " + datos.penaltisEq2 + ")";
        let estiloFinal;
        let txtFechaFinal=txtFecha;
        let txtFinal="";
        let txtFinalPenaltis="";
        let porcentajeAvance=0;
        let txtJornada="";

        let comp1, comp2, hora1, hora2;

        let cssResultado={
            background: C_uniformeVerdeClaro,
            color:C_uniformeBlanco,
            width:"22px",
            height:"22px",
            fontSize:"16px",
            display:"flex",
            textAlign:"center",
            justifyContent:"center",
            alignItems:"center", 
            margin:"4px"
        }
        let txtResultado="-";

        if(valorNumero(datos.penaltisEq1)===0 && valorNumero(datos.penaltisEq2)===0) txtPenaltis="";

        let estiloHoraPartido={
            height: "20px",
            width: "50px",
            color:C_uniformeNegro,
            align: "center",
            alignItems: "center",
            fontSize: "medium",
            fontWeight: "bold"
        };

        let estiloMarcador={
            height: "20px",
            width: "50px",
            background: C_uniformeGrisClaro,
            color:C_uniformeNegro,
            align: "center",
            fontWeight: "bold",
            alignItems: "center",
            fontSize: "medium"
        };

        let estiloPenaltis={
            height: "12px",
            width: "50px",
            color:C_uniformeVerdeClaro,
            align: "center",
            alignItems: "center",
            fontSize: "smaller"
        };

        let estiloAvancePartido={
            height: "3px",
            background:C_uniformeVerdeClaro,
            align: "left",
            alignItems: "left",
        };


        let estado=datos.nomEstado;

        if(estado==="PROGRAMADO" || estado==="REGISTRADO"){
            estiloFinal=estiloHoraPartido;
            txtFinal=txtHora;

            comp1=operacionFechas(3,datos.fechaPartido,datos.fechaActual);
            if(comp1==="3"){
                hora1=sumarMinToHoras(datos.horaPartido,-60);
                hora2=sumarMinToHoras(datos.horaPartido,process.env.REACT_APP_TIEMPO_REGISTRO_RESULTADOS);
                comp1=operacionHorasDia(5,datos.horaActual,hora1);
                comp2=operacionHorasDia(5,datos.horaActual,hora2);

                if((comp2==="2" || comp2==="3")){
                    estiloFinal.color=C_uniformeRojo;
                }
            }
            else if(comp1==="1"){
                estiloFinal.color=C_uniformeRojo;
            }   

        }
        else if(estado==="JUGANDO" || estado==="DESCANSO"){
            estiloFinal=estiloMarcador;
            txtFinal=txtMarcador;
            txtFechaFinal= txtFecha + " " + txtHora;
            
            let avance=0;

            if(estado==="JUGANDO"){
                if(datos.parte==="2") avance=50;

                let difhora1=difHoras(datos.tiempoTotal, datos.tiempoActual);
        
                if(datos.estadoTiempo==="1"){ //el tiempo esta corriendo o esta detenido? 1. corriendo
                    let difhora2=difFechas(datos.fechaActual + " " + datos.horaActual, datos.fechaInicioTiempo); //cuanto tiempo ha pasado desde q esta corriendo el tiempo
                    let tiempo=difHoras(difhora1,difhora2);
        
                if(tiempo==="00:00"){ //ya paso el tiempo del partido
                        avance=100;
                    }
                    else{
                        avance = avance + (100-valorNumero(operacionHorasDia(6,tiempo,datos.tiempoTotal.substring(3))))/2;
                    }
                }
                else{
                    avance = avance + (valorNumero(operacionHorasDia(6,datos.tiempoActual.substring(3),datos.tiempoTotal.substring(3))))/2;
                }
            }
            else avance=50;        

            avance=parseInt(avance);
            if(avance>0){
                estiloAvancePartido.width="" + avance + "%";
                porcentajeAvance=avance;
            } 

            estiloFinal.background=C_uniformeGrisOscuro;
            estiloFinal.color=C_uniformeBlanco;

        }
        else if(estado==="PENALTIS"){
            estiloFinal=estiloMarcador;
            txtFinal=txtMarcador;
            txtFechaFinal= txtFecha + " " + txtHora;

            estiloAvancePartido.width="100%";
            porcentajeAvance=100;
            txtFinalPenaltis= txtPenaltis;
            
        }
        else if(estado.includes("FINALIZADO")){
            estiloFinal=estiloMarcador;
            txtFinal=txtMarcador;
            txtFechaFinal= txtFecha + " " + txtHora;

            if(datos.golesEq1 === datos.golesEq2) txtFinalPenaltis= txtPenaltis;

            if(estado.includes("DEFAULT") && (datos.golesEq1 === datos.golesEq2)) txtFinal= "- : -";
        }
        else {
            estiloFinal=estiloHoraPartido;
            txtFinal=txtHora;
        }


        let escudo1=ic_escudo_base2;
        if(datos.imgEscudoEq1!=="") escudo1=ruta + datos.imgEscudoEq1;

        let escudo2=ic_escudo_base;
        if(datos.imgEscudoEq2!=="") escudo2=ruta + datos.imgEscudoEq2;

        let fotoArbitro=ic_jugadorsinimagen;
        if(datos.imgArbitro1!=="") fotoArbitro=ruta + datos.imgArbitro1;


        let textoJornada="";
        let calificacion=0;
        let txtCalificacion="Califica el Partido";
        let txtClick="";
        
        if(tipoPartido==="dpe"){
            txtFecha=formatearFecha(datos.fechaPartido,18);
            if(txtFecha==="") txtFecha="Fecha";
            if(datos.tipoJornada!=="1") txtJornada=datos.nomJornada;

            if(estado.includes("FINALIZADO")){
                let golesEq1=valorNumero(datos.golesEq1);
                let golesEq2=valorNumero(datos.golesEq2);
                let penaltisEq1=valorNumero(datos.penaltisEq1);
                let penaltisEq2=valorNumero(datos.penaltisEq2);
        
                if(golesEq1>golesEq2){
                    txtResultado="G";    
                    cssResultado.background=C_uniformeVerdeClaro;
                    cssResultado.color=C_uniformeBlanco;  
                }
                else if(golesEq1<golesEq2){
                    txtResultado="P";   
                    cssResultado.background=C_uniformeRojo;
                    cssResultado.color=C_uniformeBlanco;
                }
                else{
                    if(estado.includes("DEFAULT")){
                        txtResultado="P";   
                        cssResultado.background=C_uniformeRojo;
                        cssResultado.color=C_uniformeBlanco;
                    }
                    else{
                        if(penaltisEq1>penaltisEq2){
                            txtResultado="G";    
                            cssResultado.background=C_uniformeVerdeClaro;
                            cssResultado.color=C_uniformeBlanco;  
                        }
                        else if(penaltisEq1<penaltisEq2){
                            txtResultado="P";   
                            cssResultado.background=C_uniformeRojo;
                            cssResultado.color=C_uniformeBlanco;
                        }
                        else{
                            txtResultado="E";   
                            cssResultado.background=C_tarjetaAmarilla;
                            cssResultado.color=C_uniformeBlanco;
                        }
                    }
        
                }
            }
            else if(estado===""){
                txtResultado="";   
                cssResultado.background="";
            }
            else{
                txtResultado="-";   
                cssResultado.background="";
                cssResultado.color=C_uniformeNegro;
            }
            
        }
        else if(tipoPartido==="upj" || tipoPartido==="ppj"){
            if(datos.tipoJornada===1) textoJornada="Jornada No. " + datos.numNuevoJornada + " - Fase de Grupos"; 
            else{
                if(datos.nombreCopa!=="") textoJornada=datos.nombreCopa + ". " + datos.nomJornada
                else textoJornada="Jornada No. " + datos.numNuevoJornada + " - " + datos.nomJornada; 
            }

            if(tipoPartido==="upj"){
                if(datos.calificacionPartido===""){
                    txtCalificacion="No Calificable";
                    txtClick="PARTIDO_NOCALIFICABLE";
                }else{
                    if(datos.calificacionPartido!=="0"){
                        txtCalificacion="Calificación Asignada";
                        txtClick="PARTIDO_YACALIFICADO";
                        try{
                            calificacion=Math.ceil(datos.calificacionPartido);
                        }catch(err){
                            calificacion=0;
                        }
                    }
                    else txtClick="CALIFICAR_PARTIDO";
                    
                }
            }
            else if(tipoPartido==="ppj"){

            }
            
        }


        const manejarClick = () => {
            if(datos.idPartido!==""){
                if(idJugador!==""){
                    definirModulo(registro, modOrigen, paramOrigen, ID_MODULO_DETALLE_PARTIDO, datos.idPartido + "#2#" + idJugador);
                }
                else {
                    definirModulo(registro, modOrigen, paramOrigen, ID_MODULO_DETALLE_PARTIDO, datos.idPartido);
                }
            }         
        }

        const responderAsistir = (tipo) => {
            if(tipo==="ACEPTAR"){
                if(asistira!=="1"){
                    imgAcepto=ic_aceptar;
                    imgRechazo=ic_cancelar_gris;
                    setAsistira("1");


                    clickGenerico("ASISTIR_PARTIDO",[registro,"1"]);
                }
            } 
            else if(tipo==="RECHAZAR"){
                if(asistira!=="3"){
                    imgAcepto=ic_aceptar_gris;
                    imgRechazo=ic_cancelar;
                    setAsistira("3");

                    clickGenerico("ASISTIR_PARTIDO",[registro,"0"]);
                }
            }    
        }



        return (
        <div style={{minWidth: "300px"}}>
            {tipoPartido==="dpp" && (
                <table width="100%" className="TX_letraPeq" style={cssRegistro}  onClick={manejarClick}>
                <tbody>                
                    <tr><td colSpan="5" align="center">
                        <div style={{marginBottom: "-6px"}}>
                        {txtFechaFinal}
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td><img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo1} alt="" /></td>
                        <td colSpan={3} width="100%">
                        <div className="CT_contenedorPartido">
                            <div className="CT_elementoPartido TX_unaSolaLinea2" style={{textAlign: "left", fontSize:"14px", color:C_uniformeGrisOscuro}}>{formatearTexto(datos.nomCortoEq1,2)}</div>
                            <div className="CT_elementoPartido2" style={{textAlign: "center", marginTop: "0px"}}>
                                <div style={estiloFinal}>{txtFinal}</div>
                                {txtFinalPenaltis && <div style={estiloPenaltis}>{txtFinalPenaltis}</div>}
                                {porcentajeAvance>0 && <div style={{width: "95%", margin: "2px", align: "left"}}><div style={estiloAvancePartido}></div></div>}
                            </div>
                            <div className="CT_elementoPartido TX_unaSolaLinea2" style={{textAlign: "right", fontSize:"14px", color:C_uniformeGrisOscuro}}>{formatearTexto(datos.nomCortoEq2,2)}</div>
                        </div>
                        </td>
                        
                        <td><img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo2} alt="" /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan="3">
                            <div style={{marginTop: "-12px"}}>
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td><img className="IM_imgRedondeada28" height="28px" width="28px" src={fotoArbitro} alt="" /></td>
                                        <td width="100%"  align="center" className="TX_unaSolaLinea2">Arb: {datos.nomArbitro1}</td>
                                        <td width="28px"><img className="IM_imgRedondeada28" height="28px" width="28px" src={ic_transparente} alt="" /></td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    {linea && <tr><td colSpan="5" align="center"><div className="BF_lineaInferior"></div></td></tr>}
                </tbody>
                </table>
            )}
            
            {tipoPartido==="dpe" && (
                <table width="100%" className="TX_letraPeq" style={cssRegistro}  onClick={manejarClick}>
                <tbody>                
                    
                    <tr>
                        <td><div style={cssResultado}>{txtResultado}</div></td>
                        <td colSpan={3} width="100%">
                            <div className="CT_contenedorPartido">
                            <div className="CT_elementoPartido" style={{textAlign: "center", fontSize:"12px", color:C_uniformeGrisOscuro}}>
                                <div className="TX_unaSolaLinea2">{txtFecha}</div> 
                                <div>{txtHora}</div>
                                <div className="TX_unaSolaLinea2"><strong>{txtJornada}</strong></div>
                            </div>
                            <div className="CT_elementoPartido2" style={{textAlign: "center", marginTop: "0px"}}>
                                <div style={estiloFinal}>{txtFinal}</div>
                                {txtFinalPenaltis && <div style={estiloPenaltis}>{txtFinalPenaltis}</div>}
                                {porcentajeAvance>0 && <div style={{width: "95%", margin: "2px", align: "left"}}><div style={estiloAvancePartido}></div></div>}
                            </div>
                            <div className="CT_elementoPartido TX_unaSolaLinea2" style={{textAlign: "right", fontSize:"14px", color:C_uniformeGrisOscuro}}>{formatearTexto(datos.nomCortoEq2,2)}</div>
                            </div>
                        </td>                    
                        <td><img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo2} alt="" /></td>
                    </tr>                
                    {linea && <tr><td colSpan="5" align="center"><div className="BF_lineaInferior"></div></td></tr>}
                </tbody>
                </table>
            )}

            {tipoPartido==="upj" && (
                <>
                <table border="0" width="100%" className="TX_letraPeq" style={{cursor:"pointer", paddingInline:"4px"}}  onClick={manejarClick}>
                <tbody>                
                    <tr><td colSpan="5" align="center">
                        <div style={{marginBottom: "-6px"}}>
                        {txtFechaFinal}
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td><img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo1} alt="" /></td>
                        <td colSpan={3} width="100%">
                        <div className="CT_contenedorPartido">
                            <div className="CT_elementoPartido TX_unaSolaLinea2" style={{textAlign: "left", fontSize:"14px", color:C_uniformeGrisOscuro}}>{formatearTexto(datos.nomCortoEq1,2)}</div>
                            <div className="CT_elementoPartido2" style={{textAlign: "center", marginTop: "0px"}}>
                                <div style={estiloFinal}>{txtFinal}</div>
                                {txtFinalPenaltis && <div style={estiloPenaltis}>{txtFinalPenaltis}</div>}
                                {porcentajeAvance>0 && <div style={{width: "95%", margin: "2px", align: "left"}}><div style={estiloAvancePartido}></div></div>}
                            </div>
                            <div className="CT_elementoPartido TX_unaSolaLinea2" style={{textAlign: "right", fontSize:"14px", color:C_uniformeGrisOscuro}}>{formatearTexto(datos.nomCortoEq2,2)}</div>
                        </div>
                        </td>
                        
                        <td><img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo2} alt="" /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan="3">
                            <div style={{marginTop: "0px", textAlign:"center"}}>
                                {textoJornada}
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    {linea && <tr><td colSpan="5" align="center"><div className="BF_lineaInferior"></div></td></tr>}

                    {/*<tr><td colSpan="5" align="center">
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center", gap:"20px"}} onClick={() => {clickGenerico(txtClick)}}> 
                            <div style={{color:"black", fontSize:"14px", border: "0px solid red"}}>{txtCalificacion}</div> 
                            <div style={{border: "0px solid blue"}}>
                                <Stars defaultRating={calificacion} habilitado={false} />
                            </div>
                        </div>
                    </td></tr>*/}
                </tbody>
                </table>

                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center", gap:"20px"}} onClick={() => {clickGenerico(txtClick, index)}}> 
                            <div style={{color:"black", fontSize:"14px", border: "0px solid red"}}>{txtCalificacion}</div> 
                            <div>
                                <Stars valorInicial={calificacion} habilitado={false} />
                            </div>
                    </div>

                </>
                
            )}

            {tipoPartido==="ppj" && (
                <>
                <table border="0" width="100%" className="TX_letraPeq" style={{cursor:"pointer", paddingInline:"4px"}}  onClick={manejarClick}>
                <tbody>                
                    <tr><td colSpan="5" align="center">
                        <div style={{marginBottom: "-6px"}}>
                        {txtFechaFinal}
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td><img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo1} alt="" /></td>
                        <td colSpan={3} width="100%">
                        <div className="CT_contenedorPartido">
                            <div className="CT_elementoPartido TX_unaSolaLinea2" style={{textAlign: "left", fontSize:"14px", color:C_uniformeGrisOscuro}}>{formatearTexto(datos.nomCortoEq1,2)}</div>
                            <div className="CT_elementoPartido2" style={{textAlign: "center", marginTop: "0px"}}>
                                <div style={estiloFinal}>{txtFinal}</div>
                                {txtFinalPenaltis && <div style={estiloPenaltis}>{txtFinalPenaltis}</div>}
                                {porcentajeAvance>0 && <div style={{width: "95%", margin: "2px", align: "left"}}><div style={estiloAvancePartido}></div></div>}
                            </div>
                            <div className="CT_elementoPartido TX_unaSolaLinea2" style={{textAlign: "right", fontSize:"14px", color:C_uniformeGrisOscuro}}>{formatearTexto(datos.nomCortoEq2,2)}</div>
                        </div>
                        </td>
                        
                        <td><img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo2} alt="" /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan="3">
                            <div style={{marginTop: "0px", textAlign:"center"}}>
                                {textoJornada}
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    {linea && <tr><td colSpan="5" align="center"><div className="BF_lineaInferior"></div></td></tr>}

                </tbody>
                </table>

                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center", gap:"20px"}} > 
                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginLeft:"20px", cursor:"pointer"}} onClick={() => {clickGenerico("MOSTRAR_CANCHA")}}>
                                <img className="IM_imgIcono24" src={ic_ubicacion_verde} alt="" />
                                <span>Cancha</span>
                            </div>
                            <div style={{display:"flex", flexWrap:"nowrap", justifyContent:"center", alignItems:"center", flex:"1", color:"black", fontSize:"14px"}}>
                                <div style={{flex:"1"}}></div>
                                <div style={{marginRight:"20px"}}>Asistiras?</div>
                                <div style={{flex:"1", cursor:"pointer"}} onClick={() => {responderAsistir("ACEPTAR")}}><img className="IM_imgIcono24" src={imgAcepto} alt=""/></div>
                                <div style={{flex:"1", cursor:"pointer"}} onClick={() => {responderAsistir("RECHAZAR")}}><img className="IM_imgIcono24" src={imgRechazo} alt=""/></div>
                                <div style={{flex:"1"}}></div>
                            </div> 
                    </div>

                </>
                
            )}
                
        </div>
        );


    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }


  };
  
  export default DinamicoPartidosProgramados;