import { APP_ES_PRODUCCION, ARCHIVOS_POR_CARPETA, SERVIDOR_PRINCIPAL, SERVIDOR_PRUEBAS, WS_IMAGENES, WS_INICIAL } from '../constantes/generales';
import {presentarError} from '../helpers/funciones';

export const getDatos = () => {

    const WS_TOKEN=process.env.REACT_APP_WS_TOKEN;    

    let respuesta = {
        datos : "",
        exito : true,
        conectado : true,
        hayDatos : true,
        mensaje : ""
    }
    
    let url = "";

    if(APP_ES_PRODUCCION===true) url= SERVIDOR_PRINCIPAL + WS_INICIAL;
    else url= SERVIDOR_PRUEBAS + WS_INICIAL;

    
    //url=url.replace("https://","http://");


    const fetchData = (tipo_consulta,funcion, consulta, parametros, zona_horaria) => {

        const abortController = new AbortController();
        const senal = abortController.signal;
        
        const datosEnv = new FormData();

        if(tipo_consulta==="ENVIAR_IMAGEN"){
            url=url= SERVIDOR_PRINCIPAL + WS_IMAGENES;

            let imagen=parametros[0];
            let id=parametros[1];
            let indice=parametros[2];
            let tipoImagen=funcion;
                        
            datosEnv.append('wstoken', WS_TOKEN);
            datosEnv.append('archivosCarpeta', ARCHIVOS_POR_CARPETA);
            datosEnv.append('imagen', imagen);
            datosEnv.append('tipo', tipoImagen);
            datosEnv.append('id', id);
            datosEnv.append('indice', indice);
        }
        else{
            datosEnv.append('wstoken', WS_TOKEN);
            datosEnv.append('tipo_consulta', tipo_consulta);
            datosEnv.append('funcion', funcion);
            datosEnv.append('consulta', consulta);
            datosEnv.append('parametros', parametros);
            datosEnv.append('zona_horaria', zona_horaria);
        }        
        

        const requestOptions = {
            method: 'POST',
            body: datosEnv,
            signal: senal
        };    

        setTimeout(() => abortController.abort(),10000);

        function asignarRespuesta(texto){   
            try{
                if(tipo_consulta==="ENVIAR_IMAGEN"){
                    if(texto!=null && texto.length>0){
                        if(texto==="0" || texto==="1") { 
                            respuesta.datos=texto;
                        }
                        else {
                            respuesta.exito=false;
                            respuesta.conectado=false; 
                        }
                    }
                    else {
                        respuesta.exito=false;
                    }

                }
                else{
                    if(texto!=null && texto.length>=6){
                        if(texto.substring(0,6)==="CONEX:"){
                            respuesta.exito=false;
                            respuesta.conectado=false; 
                        } else if(texto.substring(0,6)==="QUERY:") respuesta.exito=false;
                        else if(texto.substring(0,6)==="ERROR:") respuesta.exito=false;
                        else if(texto.substring(0,6)==="VACIO:") respuesta.hayDatos=false;                  
                    }
    
                    if(!respuesta.exito){
                        respuesta.mensaje=texto;
                    }
                    else{
                        if(tipo_consulta === "consultarTabla"){
                            const sep_rows="#$";
                            const sep_cols="%";
    
                            let filas=texto.split(sep_rows);
    
                            if(filas.length>0){
                                let datosFinales=new Array(filas.length);
    
                                let i;
                                for(i=0;i<filas.length;i++){
                                    filas[i]=filas[i] + sep_cols + "0";
    
                                    var columnas=filas[i].split(sep_cols);
                                    if(columnas.length>0){
                                        datosFinales[i]=new Array(columnas.length);  
                                        let j=0;
                                        for(j=0;j<columnas.length;j++){                                                                          
                                            datosFinales[i][j]=columnas[j];
                                        }
                                        /*datosFinales[i]=new Array(2);                                    
                                        datosFinales[i][0]=columnas[0];
                                        datosFinales[i][1]=columnas[1];*/
                                        respuesta.hayDatos=true;
                                    }                                
                                }
    
                                if(respuesta.hayDatos){
                                    respuesta.datos=datosFinales;
                                }
                                else{
                                    respuesta.hayDatos=false;  
                                }
    
                            }
                            else respuesta.hayDatos=false;  
    
                        }
                        else{
                            respuesta.datos=texto;
                        }
                    } 
                }

                
            }catch(e){
                respuesta.exito=false;
                respuesta.mensaje=e.toString();
            }

            return respuesta;
        }

        function asignarError(err){
            presentarError("getDatos","fetch","",err);
            respuesta.exito=false;
            respuesta.conectado = false;
            return respuesta;
        }



        return fetch(url, requestOptions)
            .then(async (res) =>
                res.ok
                ? asignarRespuesta(await res.text())
                : Promise.reject(asignarError())
            )
            .catch((err) => asignarError(err));

    };

    const get = (tipo_consulta, funcion, consulta, parametros, zona_horaria) => fetchData(tipo_consulta, funcion, consulta, parametros, zona_horaria);

    return {get};
};