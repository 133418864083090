export const estilosSelect = {

    option: (style, state) =>({
        ...style,
        background: state.isSelected ? "rgba(212, 230, 68, 0.4)" : "transparent",
        cursor: "pointer",
        "&:hover": {
            background: state.isSelected ? "rgba(212, 230, 68, 0.4)" : "#f3f3f3",
        }
    })
}