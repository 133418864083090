import { useEffect, useState } from 'react';

import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_asistencia from '../recGraficos/ic_asistencia.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_disparo_arco from '../recGraficos/ic_disparo_arco.png';
import ic_falta_recibida from '../recGraficos/ic_falta_recibida.png';
import ic_falta_cometida from '../recGraficos/ic_falta_cometida.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_play_arrow from '../recGraficos/ic_play_arrow.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';


import { formatearPorcentaje, valorNumero, reportarError, registrarLogEventos } from '../helpers/funciones';
import DinamicoEstadisticasJugador from './DinamicoEstadisticasJugador';
import DinamicoResumen2Cols from './DinamicoResumen2Cols';
import DinamicoResumenGoles from './DinamicoResumenGoles';
import DinamicoResumenDisparos from './DinamicoResumenDisparos';
import DinamicoResumenTiempo from './DinamicoResumenTiempo';
import DinamicoResumenEfectividad from './DinamicoResumenEfectividad';
import { ID_MODULO_DETALLE_PARTIDO } from '../constantes/generales';

const nomClase="DP_InfoEstadisticasJugador_WB";
const idModulo=ID_MODULO_DETALLE_PARTIDO;

let titulos=[];
let iconos=["",ic_balon_gol,ic_asistencia,ic_disparo,ic_falta_recibida,ic_falta_cometida,ic_amarilla,ic_atajada,ic_tarjeta_roja];


const DP_InfoEstadisticasJugador = ({idJug, infoJugadores, tipo_registroinfo, tipoReporte="partido", tieneTarjetaAzul, idTorneo=""}) => {    
    const [iEstadisticas, setIEstadisticas] = useState(["","","","","","","","",""]);
    const [iTitular, setITitular] = useState(["No",""]);
    const [iGoles, setIGoles] = useState(["0","0","0","0","0","0","0"]);
    const [disparosTotales, setDisparosTotales] = useState("");
    const [iDisparos, setIDisparos] = useState(["","","","","","","","","","",""]);
    const [iEfectividad, setIEfectividad] = useState(["","","","","","","",""]);    
    const [iAutogoles, setIAutogoles] = useState("0");
    const [iTiempo, setITiempo] = useState(["","","","","","",""]);

  
    useEffect(() => {        
        if(tipoReporte==="partido") asignarDatosJugadorPartido();  
        else asignarDatosJugadorEquipo();  
        
        if(idTorneo!=="") registrarLogEventos(idModulo, "7", idTorneo,"" );

    }, [idJug]);


    

    const asignarDatosJugadorPartido = () => {
        const nomFuncion="asignarDatosJugadorPartido";

        try{
            let txtTitulo="Atajadas";
            let icono=ic_atajada;
            if(tieneTarjetaAzul===true){
                txtTitulo="T Azules";
                icono=ic_tarjeta_azul;
                iconos=["",ic_balon_gol,ic_asistencia,ic_disparo,ic_falta_recibida,ic_falta_cometida,ic_amarilla,icono,ic_tarjeta_roja];
            } 

            iconos=["",ic_balon_gol,ic_asistencia,ic_disparo,ic_falta_recibida,ic_falta_cometida,ic_amarilla,icono,ic_tarjeta_roja];
            titulos=["Minutos Jugados","Goles","Asistencias","Disparos al Arco","Faltas Recibidas","Faltas Cometidas","T Amarillas",txtTitulo,"T Rojas"];

            let datosFinales=["","","","","","","","",""];
            let datosGoles=["0","0","0","0","0","0","0"];
            let disparosTotales="0";
            let datosDisparos=["","","","","","","","","","",""];
            let datosEfectividad=["Efectividad de Disparo","","Goles/Total Disparos","Goles/Disparos al Arco","","","",""];
            let autogoles="0";
            let datosTiempo=["","","","","","",""];
            
            let i;
            let indice=0;
            let sinDatos=true;

            if(infoJugadores.length>0){
                for(i=0;i<infoJugadores.length;i++){
                    if(infoJugadores[i][1]===idJug){
                        sinDatos=false;
                        indice=i;
                        i=infoJugadores.length;
                    }
                }

                if(!sinDatos) { 

                    let minT1=valorNumero(infoJugadores[indice][26]);
                    let minT2=valorNumero(infoJugadores[indice][27]);
                    let minTT1=valorNumero(infoJugadores[indice][28]);
                    let minTT2=valorNumero(infoJugadores[indice][29]);

                    let tempInt=parseInt((minT1+minT2)/60);
                    let tempInt2=parseInt((minTT1+minTT2)/60);

                    let tempNum=0;
                    let tempS="";

                    datosFinales[0]=tempInt + "/" + tempInt2; //Min Jugados/Min Totales
                    datosFinales[1]=infoJugadores[indice][13]; //Goles

                    if(tipo_registroinfo==="2"){
                        datosFinales[2]=infoJugadores[indice][18]; //Asistencias
                        datosFinales[3]=infoJugadores[indice][21]; //Disparos dentro
                        datosFinales[4]=infoJugadores[indice][22]; //faltas recibidas
                        datosFinales[5]=infoJugadores[indice][23]; //faltas cometidas
                        datosFinales[7]=infoJugadores[indice][19]; //Atajadas
                    }else{
                        datosFinales[2]="-"; //Asistencias
                        datosFinales[3]="-"; //Disparos dentro
                        datosFinales[4]="-"; //faltas recibidas
                        datosFinales[5]="-"; //faltas cometidas
                        datosFinales[7]="-"; //Atajadas
                    }

                    datosFinales[6]=infoJugadores[indice][24]; //T Amarillas
                    datosFinales[8]=infoJugadores[indice][25]; //T Rojas

                    if(tieneTarjetaAzul) datosFinales[7]=infoJugadores[indice][33]; //T Azules



                    if(infoJugadores[indice][12]==="1") setITitular(["Si",""]);                        

                    tempNum=0;
                    datosGoles[0]=infoJugadores[indice][13];
                    tempInt=valorNumero(infoJugadores[indice][13]); //goles realizados
                    datosGoles[1]=infoJugadores[indice][14]; //En Jugada
                    tempInt2=valorNumero(infoJugadores[indice][14]);
                    if(tempInt>0){
                        tempNum=(tempInt2/tempInt)*100;
                        datosGoles[2]=formatearPorcentaje(tempNum); //% goles de jugada
                    }
                    else datosGoles[2]="0%";
        
                    datosGoles[3]=infoJugadores[indice][15]; //Tiro Libre
                    tempInt2=valorNumero(infoJugadores[indice][15]);
                    if(tempInt>0){
                        tempNum=(tempInt2/tempInt)*100;
                        datosGoles[4]=formatearPorcentaje(tempNum); //% goles de tirolibre
                    }
                    else datosGoles[4]="0%";
        
                    datosGoles[5]=infoJugadores[indice][16]; //Penalti
                    tempInt2=valorNumero(infoJugadores[indice][16]);
                    if(tempInt>0){
                        tempNum=(tempInt2/tempInt)*100;
                        datosGoles[6]=formatearPorcentaje(tempNum); //% goles de penalti
                    }
                    else datosGoles[6]="0%";


                    if(tipo_registroinfo==="2"){                        
        
                        disparosTotales=infoJugadores[indice][20]; // disparos totales

                        tempInt=valorNumero(infoJugadores[indice][20]); //Total disparos
                        tempInt2=valorNumero(infoJugadores[indice][21]); //al arco
                        datosDisparos[0]="" + (tempInt-tempInt2); //Total desviados
                        datosDisparos[3]="" + tempInt2; //Disparos al arco
                                                
        
                        tempInt=valorNumero(infoJugadores[indice][13]); //goles realizados
                        tempInt2=valorNumero(infoJugadores[indice][20]); //Total disparos
                        if(tempInt2>0){
                            tempNum=(tempInt/tempInt2)*100;
                            datosEfectividad[4]=formatearPorcentaje(tempNum); //%Goles/Total Disparos
                        }
                        else datosEfectividad[4]="0%"; //%Goles/Total Disparos

                        tempInt2=valorNumero(infoJugadores[indice][21]); //disparos al arco
                        if(tempInt2>0){
                            tempNum=(tempInt/tempInt2)*100;
                            datosEfectividad[5]=formatearPorcentaje(tempNum); //%Goles/disparos al arco
                        }
                        else datosEfectividad[5]="0%"; //%Goles/disparos al arco

                        datosEfectividad[6]=infoJugadores[indice][13] + "/" + infoJugadores[indice][20];
                        datosEfectividad[7]=infoJugadores[indice][13] + "/" + infoJugadores[indice][21];
                          
                        
                    }


                    autogoles=infoJugadores[indice][17];                        

                    try{
                        //datosTiempo[27]=""; //Tiempo Jugado

                        let tempTiempo=0;
                        let horas=0,minutos=0,segundos=0;

                        tempTiempo=minT1+minT2;
                        let valorTiempo="";
                        horas=valorNumero(tempTiempo/3600);
                        minutos=valorNumero((tempTiempo-(horas*3600))/60);
                        segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                        if(horas>0) valorTiempo=horas + " h ";
                        if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                        if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                        datosTiempo[0]=valorTiempo; //Tiempo Jugado Total

                        if((minTT1+minTT2)>0) tempNum=(tempTiempo/(minTT1+minTT2))*100;
                        else tempNum=0;
                        datosTiempo[1]=formatearPorcentaje(tempNum); //% tiempo total jugado

                        tempTiempo=minT1;
                        valorTiempo="";
                        horas=valorNumero(tempTiempo/3600);
                        minutos=valorNumero((tempTiempo-(horas*3600))/60);
                        segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                        if(horas>0) valorTiempo=horas + " h ";
                        if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                        if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                        datosTiempo[2]=valorTiempo; //Tiempo Jugado 1er tiempo

                        if((minTT1)>0) tempNum=(tempTiempo/(minTT1))*100;
                        else tempNum=0;
                        datosTiempo[3]=formatearPorcentaje(tempNum); //% tiempo 1er tiempo

                        tempTiempo=minT2;
                        valorTiempo="";
                        horas=valorNumero(tempTiempo/3600);
                        minutos=valorNumero((tempTiempo-(horas*3600))/60);
                        segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                        if(horas>0) valorTiempo=horas + " h ";
                        if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                        if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                        datosTiempo[4]=valorTiempo; //Tiempo Jugado 2do tiempo

                        if((minTT2)>0) tempNum=(tempTiempo/(minTT2))*100;
                        else tempNum=0;
                        datosTiempo[5]=formatearPorcentaje(tempNum); //% tiempo 2do tiempo
                    }catch(e){} 

                }
            }                  

            setIEstadisticas(datosFinales);
            setIGoles(datosGoles);
            setDisparosTotales(disparosTotales);
            setIDisparos(datosDisparos);
            setIEfectividad(datosEfectividad);
            setIAutogoles(autogoles);
            setITiempo(datosTiempo);

            
        }
        catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const asignarDatosJugadorEquipo = () => {
        const nomFuncion="asignarDatosJugadorEquipo";

        try{
            let txtTitulo="Atajadas";
            let icono=ic_atajada;
            if(tieneTarjetaAzul===true){
                txtTitulo="T Azules";
                icono=ic_tarjeta_azul;
                iconos=["",ic_balon_gol,ic_asistencia,ic_disparo,ic_falta_recibida,ic_falta_cometida,ic_amarilla,icono,ic_tarjeta_roja];
            } 
            
            iconos=["",ic_balon_gol,ic_asistencia,ic_disparo,ic_falta_recibida,ic_falta_cometida,ic_amarilla,icono,ic_tarjeta_roja];
            titulos=["Partidos Jugados","Goles","Asistencias","Disparos al Arco","Faltas Recibidas","Faltas Cometidas","T Amarillas",txtTitulo,"T Rojas"];

            let datosFinales=["","","","","","","","",""];
            let datosTitular=["",""];
            let datosGoles=["0","0","0","0","0","0","0"];
            let disparosTotales="0";
            let datosDisparos=["","","","","","","","","","",""];
            let datosEfectividad=["Efectividad de Disparo","","Goles/Total Disparos","Goles/Disparos al Arco","","","",""];
            let autogoles="0";
            let datosTiempo=["","","","","","",""];

            //titulos[0]="Partidos Jugados";
            
            let i;
            let indice=0;
            let sinDatos=true;

            if(infoJugadores.length>0){
                for(i=0;i<infoJugadores.length;i++){
                    if(infoJugadores[i][0]===idJug){
                        sinDatos=false;
                        indice=i;
                        i=infoJugadores.length;
                    }
                }

                if(!sinDatos) { 

                    
                    let tempInt=0;
                    let tempInt1=0;
                    let tempInt2=0;

                    let tempNum=0;
                    let tempFloat=0;

                    datosFinales[0]=infoJugadores[indice][11] + "/" + infoJugadores[indice][32]; //PJugados/Totales
                    datosFinales[1]=infoJugadores[indice][13]; //Goles

                    if(tipo_registroinfo==="2"){
                        datosFinales[2]=infoJugadores[indice][18]; //Asistencias
                        datosFinales[3]=infoJugadores[indice][21]; //Disparos dentro
                        datosFinales[4]=infoJugadores[indice][22]; //faltas recibidas
                        datosFinales[5]=infoJugadores[indice][23]; //faltas cometidas
                        datosFinales[7]=infoJugadores[indice][19]; //Atajadas
                    }else{
                        datosFinales[2]="-"; //Asistencias
                        datosFinales[3]="-"; //Disparos dentro
                        datosFinales[4]="-"; //faltas recibidas
                        datosFinales[5]="-"; //faltas cometidas
                        datosFinales[7]="-"; //Atajadas
                    }

                    datosFinales[6]=infoJugadores[indice][24]; //T Amarillas
                    datosFinales[8]=infoJugadores[indice][25]; //T Rojas
                    datosFinales[9]=infoJugadores[indice][11]; //partidos jugados

                    if(tieneTarjetaAzul) datosFinales[7]=infoJugadores[indice][33]; //T Azules


                    datosTitular[0]=infoJugadores[indice][12]; //partidos como titular
                    tempInt1=valorNumero(infoJugadores[indice][12]);
                    tempInt2=valorNumero(infoJugadores[indice][11]);// partidos que asistio
                    if(tempInt2>0) tempFloat=(tempInt1/tempInt2)*100;
                    datosTitular[1]=formatearPorcentaje(tempFloat); //% de partidos como tituar


                    tempNum=0;
                    datosGoles[0]=infoJugadores[indice][13];
                    tempInt=valorNumero(infoJugadores[indice][13]); //goles realizados
                    datosGoles[1]=infoJugadores[indice][14]; //En Jugada
                    tempInt2=valorNumero(infoJugadores[indice][14]);
                    if(tempInt>0){
                        tempNum=(tempInt2/tempInt)*100;
                        datosGoles[2]=formatearPorcentaje(tempNum); //% goles de jugada
                    }
                    else datosGoles[2]="0%";
        
                    datosGoles[3]=infoJugadores[indice][15]; //Tiro Libre
                    tempInt2=valorNumero(infoJugadores[indice][15]);
                    if(tempInt>0){
                        tempNum=(tempInt2/tempInt)*100;
                        datosGoles[4]=formatearPorcentaje(tempNum); //% goles de tirolibre
                    }
                    else datosGoles[4]="0%";
        
                    datosGoles[5]=infoJugadores[indice][16]; //Penalti
                    tempInt2=valorNumero(infoJugadores[indice][16]);
                    if(tempInt>0){
                        tempNum=(tempInt2/tempInt)*100;
                        datosGoles[6]=formatearPorcentaje(tempNum); //% goles de penalti
                    }
                    else datosGoles[6]="0%";


                    if(tipo_registroinfo==="2"){                        
        
                        disparosTotales=infoJugadores[indice][20]; // disparos totales

                        tempInt=valorNumero(infoJugadores[indice][20]); //Total disparos
                        tempInt2=valorNumero(infoJugadores[indice][21]); //al arco
                        datosDisparos[0]="" + (tempInt-tempInt2); //Total desviados
                        datosDisparos[3]="" + tempInt2; //Disparos al arco
                                                
        
                        tempInt=valorNumero(infoJugadores[indice][13]); //goles realizados
                        tempInt2=valorNumero(infoJugadores[indice][20]); //Total disparos
                        if(tempInt2>0){
                            tempNum=(tempInt/tempInt2)*100;
                            datosEfectividad[4]=formatearPorcentaje(tempNum); //%Goles/Total Disparos
                        }
                        else datosEfectividad[4]="0%"; //%Goles/Total Disparos

                        tempInt2=valorNumero(infoJugadores[indice][21]); //disparos al arco
                        if(tempInt2>0){
                            tempNum=(tempInt/tempInt2)*100;
                            datosEfectividad[5]=formatearPorcentaje(tempNum); //%Goles/disparos al arco
                        }
                        else datosEfectividad[5]="0%"; //%Goles/disparos al arco

                        datosEfectividad[6]=infoJugadores[indice][13] + "/" + infoJugadores[indice][20];
                        datosEfectividad[7]=infoJugadores[indice][13] + "/" + infoJugadores[indice][21];
                          
                        
                    }


                    autogoles=infoJugadores[indice][17];                        

                    try{
                        let tempTiempo=0;
                        let horas=0,minutos=0,segundos=0;

                        tempTiempo=valorNumero(infoJugadores[indice][28]);
                        let valorTiempo="";
                        horas=valorNumero(tempTiempo/3600);
                        minutos=valorNumero((tempTiempo-(horas*3600))/60);
                        segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                        if(horas>0) valorTiempo=horas + " h ";
                        if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                        if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                        datosTiempo[0]=valorTiempo; //Tiempo Jugado Total

                        tempInt1=valorNumero(infoJugadores[indice][31]);
                        if((tempInt1)>0) tempNum=(tempTiempo/(tempInt1))*100;
                        else tempNum=0;
                        datosTiempo[1]=formatearPorcentaje(tempNum); //% tiempo total jugado

                        tempTiempo=valorNumero(infoJugadores[indice][26]);
                        valorTiempo="";
                        horas=valorNumero(tempTiempo/3600);
                        minutos=valorNumero((tempTiempo-(horas*3600))/60);
                        segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                        if(horas>0) valorTiempo=horas + " h ";
                        if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                        if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                        datosTiempo[2]=valorTiempo; //Tiempo Jugado 1er tiempo

                        tempInt1=valorNumero(infoJugadores[indice][29]);
                        if((tempInt1)>0) tempNum=(tempTiempo/(tempInt1))*100;
                        else tempNum=0;
                        datosTiempo[3]=formatearPorcentaje(tempNum); //% tiempo 1er tiempo

                        tempTiempo=valorNumero(infoJugadores[indice][27]);
                        valorTiempo="";
                        horas=valorNumero(tempTiempo/3600);
                        minutos=valorNumero((tempTiempo-(horas*3600))/60);
                        segundos=valorNumero(tempTiempo-(horas*3600)-(minutos*60));
                        if(horas>0) valorTiempo=horas + " h ";
                        if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                        if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                        datosTiempo[4]=valorTiempo; //Tiempo Jugado 2do tiempo

                        tempInt1=valorNumero(infoJugadores[indice][30]);
                        if((tempInt1)>0) tempNum=(tempTiempo/(tempInt1))*100;
                        else tempNum=0;
                        datosTiempo[5]=formatearPorcentaje(tempNum); //% tiempo 2do tiempo
                    }catch(e){} 

                }
            }                  

            setIEstadisticas(datosFinales);
            setITitular(datosTitular);  
            setIGoles(datosGoles);
            setDisparosTotales(disparosTotales);
            setIDisparos(datosDisparos);
            setIEfectividad(datosEfectividad);
            setIAutogoles(autogoles);
            setITiempo(datosTiempo);

            
        }
        catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }


    return (
        <>
            {(iEstadisticas.length>0) && (iEstadisticas[0]!=="") && 
            (<><div className='CT_contenedorFlex' style={{margin: "10px"}}>
                <DinamicoEstadisticasJugador titulos={titulos} iconos={iconos} datos={iEstadisticas} />   

                <div className='CT_regSancion' style={{minWidth:"280px"}}>
                    <div className='BF_cajaGris'>
                        <p className='TX_tituloCaja'>Informacion Adicional</p>
                        <div className='BF_cajaBlanca'>
                            <DinamicoResumen2Cols imagen={ic_play_arrow} titulo={"Titular"} texto1={iTitular[0]} texto2={iTitular[1]} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumenGoles tipo={1} datos={iGoles} />
                           {(tipo_registroinfo==="2")  && 
                                (<><div className='BF_lineaInferior'></div>
                                <DinamicoResumen2Cols imagen={ic_disparo_arco} titulo={"Disparos Totales"} texto1={disparosTotales} texto2={""} />
                                <DinamicoResumenDisparos tipo={1} datos={iDisparos}/>   
                                <DinamicoResumenEfectividad datos={iEfectividad} />
                            </>)}

                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumen2Cols imagen={ic_balon_autogol} titulo={"Autogoles"} texto1={iAutogoles} texto2={""} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumenTiempo datos={iTiempo}/>
                        </div>
                    </div>     
                       
                </div>
            </div>
            
            </>)
            }
        </>
    );

};


export default DP_InfoEstadisticasJugador;