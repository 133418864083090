import ic_estrella1 from '../recGraficos/ic_estrella1.png';
import ic_estrella2 from '../recGraficos/ic_estrella2.png';
import ic_estrella3 from '../recGraficos/ic_estrella3.png';
import ic_estrella4 from '../recGraficos/ic_estrella4.png';
import ic_estrella5 from '../recGraficos/ic_estrella5.png';

import ic_transparente from '../recGraficos/ic_transparente.png';


import ic_primero from '../recGraficos/ic_primero.png';
import ic_segundo from '../recGraficos/ic_segundo.png';
import ic_tercero from '../recGraficos/ic_tercero.png';

import ic_pulgarabajo from '../recGraficos/ic_pulgarabajo.png';

import { formatearPorcentaje,  recuperarDatoLocal,  reportarError,  valorNumero } from '../helpers/funciones';
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary } from '../constantes/generales';


const ruta=recuperarDatoLocal("rutaRecursos");    

const DinamicoRankingIndicador = ({ imagen, indicador, posicion, percentil, positivo }) => {
    const nomClase="DinamicoRankingIndicador_WB";
    const nomFuncion="DinamicoRankingIndicador";

    try{


        let estrella1=ic_transparente, estrella2=ic_transparente, estrella3=ic_transparente, estrella4=ic_transparente, estrella5=ic_transparente;
        let txtPercentil="";
        let txtPosicion="";
        let imagenPodio=ic_transparente, imagenPodio2=ic_transparente;
    

        let tempPercentil=valorNumero(percentil);
        txtPercentil= formatearPorcentaje(tempPercentil);
        txtPosicion=posicion;


        if(valorNumero(posicion)<=0){
            txtPosicion="";
            txtPercentil="N/A";
        }
        else{

            if(positivo){
                if(posicion===(1)){
                    txtPercentil="";
                    imagenPodio=ic_primero;
                }
                else if(posicion===(2)) imagenPodio=ic_segundo;
                else if(posicion===(3)) imagenPodio=ic_tercero;

                if(imagenPodio!==ic_transparente){
                    txtPosicion=("");
                }

                if(tempPercentil>90 || posicion===(1)){
                    estrella1=ic_estrella5;
                    estrella2=ic_estrella5;
                    estrella3=ic_estrella5;
                    estrella4=ic_estrella5;
                    estrella5=ic_estrella5;                
                }
                else if(tempPercentil>75){                
                    estrella1=ic_estrella4;
                    estrella2=ic_estrella4;
                    estrella3=ic_estrella4;
                    estrella4=ic_estrella4;
                }
                else if(tempPercentil>55){
                    estrella1=ic_estrella3;
                    estrella2=ic_estrella3;
                    estrella3=ic_estrella3;
                }
                else if(tempPercentil>30){                
                    estrella1=ic_estrella2;
                    estrella2=ic_estrella2;
                }
                else{
                    estrella1=ic_estrella1;
                }

            }
            else{
                if(posicion===(1)){
                    txtPercentil=("");
                    imagenPodio2=ic_pulgarabajo;
                }
                else if(posicion===(2)) imagenPodio2=ic_pulgarabajo;
                else if(posicion===(3)) imagenPodio2=ic_pulgarabajo;

                if(tempPercentil>70){
                    estrella1=ic_estrella1;                
                    
                }
                else if(tempPercentil>45){
                    estrella1=ic_estrella2;
                    estrella2=ic_estrella2;
                    
                }
                else if(tempPercentil>25){
                    estrella1=ic_estrella3;
                    estrella2=ic_estrella3;
                    estrella3=ic_estrella3;
                    
                }
                else if(tempPercentil>10){
                    estrella1=ic_estrella4;
                    estrella2=ic_estrella4;
                    estrella3=ic_estrella4;
                    estrella4=ic_estrella4;                
                }
                else{
                    estrella1=ic_estrella5;
                    estrella2=ic_estrella5;
                    estrella3=ic_estrella5;
                    estrella4=ic_estrella5;
                    estrella5=ic_estrella5;
                }
            }




        }

        


        return(
            <>
            <div style={{display:"flex", alignItems:"center", padding:"2px", marginLeft:"10px"}}>
                <img className="IM_imgIcono36" src={imagen} style={{margin:"5px"}} alt=""/>
                <div style={{flex:"5", textAlign:"left", whiteSpace: "pre-wrap"}}>
                    <div style={{fontSize:"16px", color:C_uniformeGrisOscuro}}>{indicador}</div>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"left"}}>
                        <img className="IM_imgIcono16" src={estrella1} alt=""/>
                        <img className="IM_imgIcono16" src={estrella2} alt=""/>
                        <img className="IM_imgIcono16" src={estrella3} alt=""/>
                        <img className="IM_imgIcono16" src={estrella4} alt=""/>
                        <img className="IM_imgIcono16" src={estrella5} alt=""/>
                    </div>                              
                </div>
                <div style={{flex:"2", fontSize:"20px", color: C_colorAccent }}>{txtPercentil}</div>
                <div style={{flex:"2", display:"flex", justifyContent:"center", position:"relative"}}>
                    <div style={{position:"absolute", right:"0px", top:"0px"}}><img className="IM_imgIcono16" src={imagenPodio2} alt=""/></div>
                    {txtPosicion!=="" ? 
                    (<div style={{flex:"2", fontSize:"24px", color:C_colorPrimary}}>{txtPosicion}</div>) 
                    : 
                    (<>
                    <img className="IM_imgIcono36" src={imagenPodio} alt=""/>  
                    
                    
                    </>)}

                </div>
                
            </div>
            <div className='BF_lineaInferior'></div>

            </>
        );


    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

};


export default DinamicoRankingIndicador;