import { useEffect, useState } from 'react';

import ic_sanciones from '../recGraficos/ic_sanciones.png';
import ic_tarjeta_dobleamarilla from '../recGraficos/ic_tarjeta_dobleamarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_acumular_amarilla from '../recGraficos/ic_acumular_amarilla.png';
import ic_acumular_roja from '../recGraficos/ic_acumular_roja.png';
import ic_detalle_verde from '../recGraficos/ic_detalle_verde.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_acumular_azul from '../recGraficos/ic_acumular_azul.png';


import DinamicoSancionesJugador from './DinamicoSancionesJugador';
import { valorNumero, registrarLogEventos, reportarError } from '../helpers/funciones';
import { C_colorTextoSinInfo, C_uniformeBlanco, C_uniformeGrisClaro, ID_MODULO_DETALLE_TORNEO } from '../constantes/generales';

const nomClase="DT_InfoSanciones_WB";
const idModulo=ID_MODULO_DETALLE_TORNEO;


const DT_InfoSanciones = ({datos}) => {
    const [tipoLista, setTipoLista] = useState("activa");
    const [filtro, setFiltro] = useState("");
    const [tiposSancion, setTiposSancion] = useState([]);

    let iconoSanciones=["",ic_tarjeta_dobleamarilla,ic_tarjeta_roja,ic_acumular_amarilla,ic_acumular_roja, ic_detalle_verde, ic_tarjeta_azul, ic_acumular_azul];


    useEffect(() => {
        let idTorneo="";
        if(datos.length>0) idTorneo=datos[0].idtorneo;

        if(idTorneo!=="")
            registrarLogEventos(idModulo,"6",idTorneo,"");
    }, []);

    useEffect(() => {
        let nomFuncion="useEffect"; 

        try{ 
        
            let i;
            let ultTipo="";
            let contador=0;
            let datosTemp=[];
            let tempTiposSancion=[];
            for(i=0;i<datos.length;i++){      
                let esFiltro=true;   

                if(tipoLista==="activa"){
                    let totalSancion=valorNumero(datos[i].totalpartidos);
                    let totalPagada=valorNumero(datos[i].valSancion);

                    if(totalSancion<=totalPagada) esFiltro=false;   
                }
                else if(tipoLista==="inactiva"){
                    let totalSancion=valorNumero(datos[i].totalpartidos);
                    let totalPagada=valorNumero(datos[i].valSancion);

                    if(totalSancion>totalPagada) esFiltro=false;  
                }

                if(esFiltro){
                    if(filtro !== ""){
                        esFiltro=false;
                        if(datos[i].nomJugador.toUpperCase().includes(filtro) || datos[i].nomEquipo.toUpperCase().includes(filtro)) esFiltro=true;
                    }
                }


                if(esFiltro && datos[i].tipoSancion!==ultTipo){
                    if(contador>0){
                        tempTiposSancion[contador-1][3]=datosTemp;
                        datosTemp=[];
                    }
                    ultTipo=datos[i].tipoSancion;
                    let numTipo=valorNumero(datos[i].tipoSancion);
                    tempTiposSancion.push([datos[i].tipoSancion, datos[i].nomSancion, iconoSanciones[numTipo],[]]);

                    contador++;            
                }

                if(esFiltro) datosTemp.push(datos[i]);
            }

            if(datosTemp.length>0) tempTiposSancion[contador-1][3]=datosTemp;

            setTiposSancion(tempTiposSancion);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }

    }, [tipoLista, filtro]);

    if(!datos) return null;

    
    const incluirListaSancion = (listaSancion) => {

       return(
            <div className='BF_bordeTotal' style={{padding: "4px", marginBottom:"4px", background:C_uniformeBlanco, minWidth:"300px"}}>
                <div className="CT_contenedorTitulo" >
                    <img className='IM_imgIcono24' height="24px" width="24px" src={listaSancion[2]} alt='' />   
                    <p style={{marginLeft: "10px"}}>{listaSancion[1]}</p>             
                </div>
                <div style={{display: "flex", justifyContent:"left", paddingLeft:"46px"}}>
                    <table className='TX_textoPeq' border="0" style={{align: "left"}}>
                        <tbody>
                            <tr>
                                <td width="180px">Jugador/Equipo</td>
                                <td width="50px">Partidos</td>
                                <td width="50px">Pagada</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='BF_lineaInferior'></div>
                <div className="CT_contenedorFlex" style={{justifyContent:"left", columnGap:"50px"}}>
                {listaSancion[3].length>0 && 
                    (listaSancion[3].map((reg, index) => (<div key={index}><DinamicoSancionesJugador datos={reg} tipoLista={"sanciones_torneo"}/></div>)))
                }
                </div>
            </div>
       );

    };

    const cambiarTipoLista  = (selectedOption) => {
        setTipoLista(selectedOption.target.value);
    };

    const cambiarFiltro  = (elemento) => {
        setFiltro(elemento.target.value.toUpperCase());
    };



    return (

        <div>
            <div style={{marginLeft: "10px", marginRight: "10px"}}>
                <div className="CT_contenedorTitulo">
                    <img className='IM_imgIcono36' height="36px" width="36px" src={ic_sanciones} alt='' />   
                    <p style={{marginLeft: "10px", fontSize:"18px"}}>Sanciones del Torneo</p>             
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                    <label><input type="radio" value="activa" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="activa"} onChange={cambiarTipoLista}/>Activas</label>
                    <label><input type="radio" value="inactiva" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="inactiva"} onChange={cambiarTipoLista}/>Inactivas</label>
                    <label><input type="radio" value="todo" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="todo"} onChange={cambiarTipoLista}/>Todas</label>
                </div>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", margin: "0px"}}>
                    <p style={{marginRight: "10px"}}>Filtro:</p>
                    <input type="text" className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, flexBasis: "150px"}} placeholder="Jugador o Equipo"  onChange={cambiarFiltro} />
                </div>                

            </div>

            
                {tiposSancion.length>0 ?
                    (<div style={{background:C_uniformeGrisClaro, padding:"4px", minHeight: "70vh"}}>
                    {(tiposSancion.map((reg, index) => (<div key={index}>{incluirListaSancion(reg)}</div>))  )}
                    </div>
                    ) :
                    (<div><p style={{textAlign:"center", color:C_colorTextoSinInfo}}>No existen sanciones para los filtros definidos</p> </div>)
                }
            

        
        </div>

    );
};


export default DT_InfoSanciones;