import { useEffect, useState } from 'react';
import { dividirLista, formatearTexto, valorNumero, reportarError, dinamico_fixture_ganador, dinamico_fixture_grupo, dinamico_fixture_vacio, dinamico_fixture_partidos, dinamico_fixture_grupo_equipo, recuperarDatoLocal, dinamico_fixture_grupo_vacio, dinamico_fixture_grupo_contenedor } from '../helpers/funciones';

import ic_fixture from '../recGraficos/ic_fixture.png';
import ic_escudo_base from '../recGraficos/ic_escudo_base.png';
import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_escudo_base2 from '../recGraficos/ic_escudo_base2_w.png';
import { ID_MODULO_DETALLE_TORNEO, MAX_NIVELES_FIXTURE_NORMAL } from '../constantes/generales';

const nomClase="DT_PresentarFixture_WB";
const idModulo=ID_MODULO_DETALLE_TORNEO;

const ruta=recuperarDatoLocal("rutaRecursos");


let idPartidoArriba="";
let idPartidoAbajo="";


let InfoPartido = {
    idTorneo:"",
    nomTorneo:"",
    idPartido:"",
    numJornada:"",
    numCancha:"",
    idEquipo1:"",
    nomCortoEq1:"",
    imgEscudoEq1:"",
    idEquipo2:"",
    nomCortoEq2:"",
    imgEscudoEq2:"",
    nomArbitro1:"",
    nomArbitro2:"",
    fechaPartido:"",
    horaPartido:"",
    golesEq1:"",
    golesEq2:"",
    parte:"",
    tiempoActual:"",
    tiempoTotal:"",
    idEstado:"",
    nomEstado:"",
    fechaActual:"",
    horaActual:"",
    idJornada:"",
    estadoTiempo:"",
    fechaInicioTiempo:"",
    confirmoAsistencia:"",
    fechaConfirmo:"",
    tipoTorneo:"",
    tipoRegistroInfo:"",
    partidoActivo:"",
    idSede:"",
    idEstadoInfArbitro:"",
    imgArbitro1:"",
    calificacionPartido:"",
    penaltisEq1:"",
    penaltisEq2:"",
    tipoJornada:"",
    nomJornada:"",
    semFechainicial:"",
    semFechaFinal:"",
    esSemanaActual:"",
    resumenJornada:"",
    tipoJornada2:"",
    nomJornada2:"",
    idsemana:"",
    numsemana:"",
    semFechainicial2:"",
    semFechaFinal2:"",
    tipoJornadaEliminacion:"",
    idcopa:"",
    nombreCopa:"",
    ordenCopa:"",
    golesP1Eq1:"",
    golesP1Eq2:"", 
    idArbitro2:""
}

let InfoTablaPosicionJE = {
    jornada: "",
    grupo: 0,
    idequipo: "",
    nombre: "",
    escudo: "",
    posicion: 0,
    partidosJugados: 0,
    golAFavor: 0,
    golEnContra: 0,
    golDiferencia: 0,
    puntos: 0
}

let partidosFaseEliminacion = {
    idCopa: "",
    partidosGrupo: [],
    jornadasGrupo: [],
    tipoJornadas: [],
    titulosGrupo: [],
    titulosGrupoPeq: [],
    tablaClasificacion: [],
    fixture: null
}





let partidosEliminacion=[];
let jornadasEliminacion=[];
let titulosJornadas=[];
let titulosJornadasPeq=[];
let nombresGrupos=[];
let procesandoFixture=false;
let grupoPartidosEliminacion=[];


let partidos;
let jornadas;
let tipoJornadas;
let tablaClasificacionGrupos;

let partidosIncluidos = [];
let gruposIncluidos = [];


let fixtureGlobal;


const DT_PresentarFixture = ({ idTorneo, partidosProgramados, infoClasificacion, infoGruposFE, volverFG, infoTorneo }) => {
    const [tipoVistaFixtureSel, setTipoVistaFixtureSel] = useState(1);
    const [grupoSel, setGrupoSel] = useState(0);
    const [grupoPartidosUS, setGrupoPartidosUS] = useState([]);


    useEffect(() => {
        const nomFuncion="useEffect";

        try{

            generarInfoFixture();

            setGrupoSel(0);            
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [idTorneo]);


    const generarInfoFixture = () => {
        const nomFuncion="generarInfoFixture";


        try{
            /*
        0.idtorneo,1. ,2.idpartido,3.jornada,4.cancha,5.idequipo1,6.equipo1,7.escudoeq1,8.idequipo2,9.equipo2,
        10.escudoeq2,11.arb1,12. ,13.fecha,14.hora,15.golesequipo1,16.golesequipo2,17.parte,18.tiempo,
        19.minutospartido,20.idestado,21.estado,22.actualDate,23.actualHour,24.idjornada,25.estadotiempo,
        26.fechainiciotiempo,27.tipo_torneo,28.tipo_registroinfo,29. ,30. ,31. ,32.fotoarbitro1,33. ,34.penaltisequipo1,
        35.penaltisequipo2,36.numero,37.nombre,38.fechainicial,39.fechafinal,40. ,41.resumen,42.tipo_jornada
         */

            let grupoSel=0;
            
            grupoPartidosEliminacion=[];
            nombresGrupos=[];

            partidosEliminacion=[];

            let indexCopa=[]; //integer
            let numerosJornada=[]; //string
            let indicePartido= []; //integer
            let numCopa= []; //integer

            let partidosIdaVuelta= []; //string

            let ya=true; //boolean

            if(partidosProgramados.length>0){

                let i,j;
                for(i=0;i<partidosProgramados.length;i++){
                    let tempPartidos= dividirLista("","|",18);

                    //Tipo Jornada fase de eliminación
                    if(partidosProgramados[i][44]==="2"){

                        
                        tempPartidos[0]=partidosProgramados[i][2]; //idpartido
                        tempPartidos[1]=partidosProgramados[i][3]; //numJornada
                        tempPartidos[2]=partidosProgramados[i][5]; //idEquipo1
                        tempPartidos[3]=formatearTexto(partidosProgramados[i][6],2); //nombre
                        tempPartidos[4]=partidosProgramados[i][7]; //escudo
                        tempPartidos[5]=partidosProgramados[i][8]; //idEquipo2
                        tempPartidos[6]=formatearTexto(partidosProgramados[i][9],2); //nombre
                        tempPartidos[7]=partidosProgramados[i][10]; //escudo
                        tempPartidos[8]=partidosProgramados[i][15]; //goleseq1
                        tempPartidos[9]=partidosProgramados[i][16]; //goleseq2
                        tempPartidos[10]=partidosProgramados[i][36]; //penaltiseq1
                        tempPartidos[11]=partidosProgramados[i][37]; //penaltiseq2
                        tempPartidos[12]=partidosProgramados[i][20]; //idEstado
                        tempPartidos[15]=partidosProgramados[i][50]; //tipo jornada eliminacion

                        tempPartidos[16]=partidosProgramados[i][54]; //Id Partico con el que se cruza en la siguiente fase
                        tempPartidos[17]=partidosProgramados[i][55]; //orden del partido


                        let incluirPartido=true;

                        if (partidosProgramados[i][50]==("1")) { // partidos Tipo Jornada Ida y Vuelta
                            if (!esPartidoIdaVueltaRegistrado(partidosIdaVuelta, i)) {
                                let partidoIdaVuelta = buscarPartidoIdaVuelta(i);
                                tempPartidos[8] = partidoIdaVuelta[2]; //golesEq1Partido Vuelta
                                tempPartidos[9] = partidoIdaVuelta[3]; //golesEq2Partido Vuelta
                                tempPartidos[10] = partidoIdaVuelta[4]; //PenaltiesEq1Partido Vuelta
                                tempPartidos[11] = partidoIdaVuelta[5]; //PenaltiesEq2Partido Vuelta
                                tempPartidos[12] = partidoIdaVuelta[6]; //idEstado
                                tempPartidos[13] = partidoIdaVuelta[0]; //golesEq1Partido Ida
                                tempPartidos[14] = partidoIdaVuelta[1]; //golesEq2Partido Ida

                                partidosIdaVuelta.push(partidosProgramados[i][2]);
                                if (partidoIdaVuelta[7]!==(""))
                                    partidosIdaVuelta.push(partidoIdaVuelta[7]);
                            }
                            else incluirPartido = false;
                        }
                        else if (partidosProgramados[i][50]==("2") || partidosProgramados[i][50]==("3")) { //Partidos Tipo Jornada (Grupos)
                            incluirPartido = false;
                        }

                        let partido=asignarInfoPartido(tempPartidos);


                        partidosEliminacion.push(partido);


                        let existeCopa = false;
                        let indiceCopa = indexCopa.length;
                        let idCopa = valorNumero(partidosProgramados[i][51]);
                        //Identifica si es partido de una copa ya creada

                        for (j = 0; j < indexCopa.length; j++) {
                            if (indexCopa[j] === idCopa) {
                                existeCopa = true;
                                indiceCopa = j;
                                j = indexCopa.length;
                            } else {
                                if (indexCopa[j] > idCopa) {
                                    indiceCopa = j;
                                    j = indexCopa.length;
                                }
                            }
                        }

                        //Identifica si el partido es de una jornada ya creada
                        let existeJornada = false;
                        for (j = 0; j < numerosJornada.length; j++) {
                            if (numerosJornada[j]===partidosProgramados[i][3] &&
                                    partidosProgramados[indicePartido[j]][51]===partidosProgramados[i][51]) {
                                existeJornada = true;
                                j = numerosJornada.length;
                            }
                        }
                        if (!existeJornada) {
                            numerosJornada.push(partidosProgramados[i][3]);
                            indicePartido.push(i);
                        }

                        if (existeCopa) { //Si ya existe la copa agreaga el partido a la copa a la que pertenece
                            if (incluirPartido) {
                                //Esta sección ingresa el partido en el orden correspondiente de acuerdo a su jornada
                                let clasePartidos = grupoPartidosEliminacion[indiceCopa];
                                let listaPartidos = JSON.parse(JSON.stringify(clasePartidos.partidosGrupo)); 
                                if(listaPartidos.length>0) {
                                    for (j = listaPartidos.length- 1; j >= 0; j--) {
                                        let numJornada1 = valorNumero(listaPartidos[j].numJornada);
                                        let numJornada2 = valorNumero(partido.numJornada);
                                        if (numJornada2 >= numJornada1) {
                                            listaPartidos.splice(j + 1, 0, partido);                                            
                                            
                                            j = -1;
                                        }
                                    }
                                }
                                else{
                                    listaPartidos.push(partido);
                                }

                                grupoPartidosEliminacion[indiceCopa].partidosGrupo = JSON.parse(JSON.stringify(listaPartidos)); 

                            }
                        }
                        else { //Si es una copa nueva la crea y agrega el partido
                            nombresGrupos.splice(indiceCopa, 0, formatearTexto(partidosProgramados[i][52], 2));    
                            indexCopa.splice(indiceCopa, 0, idCopa);

                            let partidoNuevo = [];
                            if (incluirPartido) partidoNuevo.push(partido);
                            let clasePartidos2 = [];
                            clasePartidos2 = JSON.parse(JSON.stringify(partidosFaseEliminacion)); // Object.assign({} , partidosFaseEliminacion);
                            clasePartidos2.idCopa = partidosProgramados[i][51];
                            clasePartidos2.partidosGrupo= partidoNuevo;
                            
                            grupoPartidosEliminacion.splice(indiceCopa, 0, clasePartidos2);
                        }



                    }

                }



                if(numerosJornada.length>0) {
                    let jornadasOrdenadas = [];
                    let k;


                    for (k = 0; k < numerosJornada.length; k++) {
                        jornadasOrdenadas.push(k);
                    }

                    //Ordenar las jornadas de menor a mayor para cada copa
                    for (i = 0; i < numerosJornada.length; i++) {
                        let sorted = true; // asumo que para la iteración i el listado es ordenado,
                        for (j = 0; j < numerosJornada.length - i - 1; j++) { // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición
                            let idx=jornadasOrdenadas[j];
                            let idx1=jornadasOrdenadas[j+1];
                            let idxCopa1=valorNumero(partidosProgramados[indicePartido[idx]][53]);
                            let idxCopa2=valorNumero(partidosProgramados[indicePartido[idx1]][53]);
                            if (idxCopa1 > idxCopa2) {
                                let temp = jornadasOrdenadas[j];
                                jornadasOrdenadas[j] = jornadasOrdenadas[j + 1];
                                jornadasOrdenadas[j + 1] = temp;
                                sorted = false;
                            } else if (idxCopa1 == idxCopa2) {
                                let nj1 = valorNumero(numerosJornada[idx]);
                                let nj2 = valorNumero(numerosJornada[idx1]);
                                if (nj1 > nj2) {
                                    let temp = jornadasOrdenadas[j];
                                    jornadasOrdenadas[j] = jornadasOrdenadas[j + 1];
                                    jornadasOrdenadas[j + 1] = temp;
                                    sorted = false;
                                }
                            }
                        }
                        if (sorted) {
                            i = numerosJornada.length;
                        }
                    }


                    //Seccion que crea las jornadas (titulos y numero) de los partidos ya ordenados por jornada y por fecha)
                    for (i = 0; i < jornadasOrdenadas.length; i++) {
                        let idxCopa=-1;
                        let idx=jornadasOrdenadas[i];
                        for(j=0;j<grupoPartidosEliminacion.length;j++){
                            if(grupoPartidosEliminacion[j].idCopa===partidosProgramados[indicePartido[idx]][51]){
                                let idxPartido = indicePartido[idx];
                                let numJornada = numerosJornada[idx];
                                let nomJornada = partidosProgramados[idxPartido][45];
                                let tipoJornada = partidosProgramados[idxPartido][50];

                                grupoPartidosEliminacion[j].titulosGrupo.push(formatearTexto(nomJornada, 2));
                                grupoPartidosEliminacion[j].titulosGrupoPeq.push("J " + numJornada);
                                grupoPartidosEliminacion[j].jornadasGrupo.push(numJornada);
                                grupoPartidosEliminacion[j].tipoJornadas.push(tipoJornada);

                                idxCopa=j;
                                j=grupoPartidosEliminacion.length;
                            }
                        }
/*
                        if(idxCopa>=0) {
                            let idxPartido = indicePartido[jornadasOrdenadas[i]];
                            let numJornada = numerosJornada[jornadasOrdenadas[i]];

                            let nomJornada = partidosProgramados[idxPartido][45];
                            let tipoJornada = partidosProgramados[idxPartido][50];

                            let clasePartidos = JSON.parse(JSON.stringify(grupoPartidosEliminacion[idxCopa]));

                            let titulosJornada = clasePartidos.titulosGrupo;
                            let titulosJornadaPeq = clasePartidos.titulosGrupoPeq;
                            let jornadasGrupo = clasePartidos.jornadasGrupo;
                            let tiposJornada = clasePartidos.tipoJornadas;

                            titulosJornada.push(formatearTexto(nomJornada, 2));
                            titulosJornadaPeq.push("J " + numJornada);
                            jornadasGrupo.push(numJornada);
                            tiposJornada.push(tipoJornada);

                            clasePartidos.titulosGrupo = (titulosJornada);
                            clasePartidos.titulosGrupoPeq = (titulosJornadaPeq);
                            clasePartidos.jornadasGrupo = (jornadasGrupo);
                            clasePartidos.tipoJornadas = (tiposJornada);

                            //grupoPartidosEliminacion.splice(idxCopa, 0, clasePartidos);
                            grupoPartidosEliminacionTemp.push(clasePartidos);
                        }*/
                    }

                }



                //Sección que crea las tablas de clasificación de las jornadas de grupos de cada copa que exista
                if (infoGruposFE.length>0) {
                    let tabla = []; //InfoTablaPosicionJE
                    //let tablaTemp=Object.assign({} , InfoTablaPosicionJE);

                    let idCopaAnterior = "";
                    let indiceCopa = -1;
                    for (i = 0; i < infoGruposFE.length; i++) {
                        if (idCopaAnterior!=(infoGruposFE[i][1])) {
                            if (idCopaAnterior!=("") && indiceCopa >= 0) {
                                grupoPartidosEliminacion[indiceCopa].tablaClasificacion = ordenarTabla(tabla);
                                tabla = [];
                            }
                            indiceCopa = -1;
                            for (j = 0; j < grupoPartidosEliminacion.length; j++) {
                                if (grupoPartidosEliminacion[j].idCopa==(infoGruposFE[i][1])) {
                                    indiceCopa = j;
                                    j = grupoPartidosEliminacion.length;
                                }
                            }

                            idCopaAnterior = infoGruposFE[i][1];
                        }

                        if (indiceCopa >= 0) {
                            let equipo = buscarDatosTablaPosicionEquipo(i);
                            if (equipo != null) tabla.push(equipo);
                        }
                    }

                    if (idCopaAnterior!=("") && indiceCopa >= 0) {
                        grupoPartidosEliminacion[indiceCopa].tablaClasificacion = ordenarTabla(tabla);
                    }
                }

            }

            setGrupoPartidosUS(grupoPartidosEliminacion);


        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        }

    }


    const esPartidoIdaVueltaRegistrado = (partidosRegistrados, regPartido) => {
        const nomFuncion="esPartidoIdaVueltaRegistrado";

        let resultado=false;
        try{
            let idPartido=partidosProgramados[regPartido][2];

            let i;
            for(i=0;i<partidosRegistrados.length;i++){
                if(partidosRegistrados[i]==(idPartido)){
                    resultado=true;
                    i=partidosRegistrados.length;
                }
            }

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        }

        return resultado;
    }

    const buscarPartidoIdaVuelta = (regPartido) => {
        const nomFuncion="buscarPartidoIdaVuelta";

        let resultado=dividirLista("","|",8);
        try{
            let idPartido=partidosProgramados[regPartido][2];
            let idJornada=partidosProgramados[regPartido][24];
            let idEq1=partidosProgramados[regPartido][5];
            let idEq2=partidosProgramados[regPartido][8];

            let i;
            let regOtroPartido=-1;
            for(i=0;i<partidosProgramados.length;i++){
                if(partidosProgramados[i][2]!=(idPartido) && partidosProgramados[i][24]==(idJornada) &&
                        ((partidosProgramados[i][5]==(idEq1) && partidosProgramados[i][8]==(idEq2)) ||
                                (partidosProgramados[i][5]==(idEq2) && partidosProgramados[i][8]==(idEq1)))){
                    regOtroPartido=i;
                    i=partidosProgramados.length;
                }
            }

            if(regOtroPartido<0){
                let penaltisEq1=valorNumero(partidosProgramados[regPartido][36]);
                let penaltisEq2=valorNumero(partidosProgramados[regPartido][37]);

                resultado[0]=partidosProgramados[regPartido][15]; //goles equipo 1
                resultado[1]=partidosProgramados[regPartido][16]; //goles equipo 2
                if(penaltisEq1>0 || penaltisEq2>0){
                    resultado[4]=partidosProgramados[regPartido][36]; //penaltis Eq1
                    resultado[5]=partidosProgramados[regPartido][37]; //penaltis Eq2
                    resultado[6]="10";
                }
                else{
                    resultado[6]="1";
                }
            }
            else{
                resultado[6]="10";
                resultado[7]=partidosProgramados[regOtroPartido][2]; //idpartido
                if(partidosProgramados[regOtroPartido][36]!=("0") || partidosProgramados[regOtroPartido][37]!=("0")){
                    resultado[0]=partidosProgramados[regPartido][15]; //goles equipo 1
                    resultado[1]=partidosProgramados[regPartido][16]; //goles equipo 2

                    if(partidosProgramados[regPartido][5]==(partidosProgramados[regOtroPartido][5])){
                        resultado[2]=partidosProgramados[regOtroPartido][15]; //goles equipo 1 partido vuelta
                        resultado[3]=partidosProgramados[regOtroPartido][16]; //goles equipo 2 partido vuelta
                        resultado[4]=partidosProgramados[regOtroPartido][36]; //penaltis Eq1 partido vuelta
                        resultado[5]=partidosProgramados[regOtroPartido][37]; //penaltis Eq2 partido vuelta
                    }
                    else{
                        resultado[2]=partidosProgramados[regOtroPartido][16]; //goles equipo 1 partido vuelta
                        resultado[3]=partidosProgramados[regOtroPartido][15]; //goles equipo 2 partido vuelta
                        resultado[4]=partidosProgramados[regOtroPartido][37]; //penaltis Eq1 partido vuelta
                        resultado[5]=partidosProgramados[regOtroPartido][36]; //penaltis Eq2 partido vuelta
                    }

                }
                else{
                    if(partidosProgramados[regPartido][36]!=("0") || partidosProgramados[regPartido][37]!=("0")){
                        if(partidosProgramados[regPartido][5]==(partidosProgramados[regOtroPartido][5])){
                            resultado[0]=partidosProgramados[regOtroPartido][15];
                            resultado[1]=partidosProgramados[regOtroPartido][16];
                            resultado[2]=partidosProgramados[regPartido][15];
                            resultado[3]=partidosProgramados[regPartido][16];
                            resultado[4]=partidosProgramados[regPartido][36];
                            resultado[5]=partidosProgramados[regPartido][37];
                        }
                        else{
                            resultado[0]=partidosProgramados[regOtroPartido][16];
                            resultado[1]=partidosProgramados[regOtroPartido][15];
                            resultado[2]=partidosProgramados[regPartido][15];
                            resultado[3]=partidosProgramados[regPartido][16];
                            resultado[4]=partidosProgramados[regPartido][36];
                            resultado[5]=partidosProgramados[regPartido][37];
                        }

                    }
                    else{
                        if(partidosProgramados[regPartido][5]==(partidosProgramados[regOtroPartido][5])){
                            resultado[0]=partidosProgramados[regPartido][15];
                            resultado[1]=partidosProgramados[regPartido][16];
                            resultado[2]=partidosProgramados[regOtroPartido][15];
                            resultado[3]=partidosProgramados[regOtroPartido][16];
                        }
                        else{
                            resultado[0]=partidosProgramados[regPartido][15];
                            resultado[1]=partidosProgramados[regPartido][16];
                            resultado[2]=partidosProgramados[regOtroPartido][16];
                            resultado[3]=partidosProgramados[regOtroPartido][15];
                        }

                    }
                }
            }

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        }

        return resultado;
    }

    const asignarInfoPartido = (datos) => {
        const nomFuncion="asignarInfoPartido";

        let registro=Object.assign({} , InfoPartido);

        try{
            let i;
    
            for(i=0;i<datos.length;i++){
                if(i==0) registro.idPartido =datos[i];
                if(i==1) registro.numJornada =datos[i];
                if(i==2) registro.idEquipo1 =datos[i];
                if(i==3) registro.nomCortoEq1 =datos[i];
                if(i==4) registro.imgEscudoEq1 =datos[i];
                if(i==5) registro.idEquipo2 =datos[i];
                if(i==6) registro.nomCortoEq2 =datos[i];
                if(i==7) registro.imgEscudoEq2 =datos[i];
                if(i==8) registro.golesEq1 =datos[i];
                if(i==9) registro.golesEq2 =datos[i];
                if(i==10) registro.penaltisEq1 =datos[i];
                if(i==11) registro.penaltisEq2 =datos[i];
                if(i==12) registro.idEstado =datos[i];
                if(i==13) registro.golesP1Eq1 =datos[i];
                if(i==14) registro.golesP1Eq2 =datos[i];
                if(i==16) registro.idArbitro2=datos[i];
                if(i==17) registro.nomArbitro2=datos[i];
            }

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        }

        return registro;

    }

    const buscarDatosTablaPosicionEquipo = (regGrupoFE) => {
        const nomFuncion="buscarDatosTablaPosicionEquipo";

        let resultado=null;
        try{


            let permite_empate=1;
            let puntos_desempate=1;
            let puntos_ganador=3;

            if (infoTorneo.length>0){
                permite_empate=valorNumero(infoTorneo[0][19]);
                puntos_desempate=valorNumero(infoTorneo[0][20]);
                puntos_ganador=valorNumero(infoTorneo[0][21]);
            }


            let idJornada=infoGruposFE[regGrupoFE][0];
            let numJornada=infoGruposFE[regGrupoFE][4];
            let grupo=valorNumero(infoGruposFE[regGrupoFE][3]);
            let idEquipo=infoGruposFE[regGrupoFE][2];

            resultado=Object.assign({} , InfoTablaPosicionJE);
            resultado.jornada= numJornada;
            resultado.grupo= grupo;
            resultado.idequipo= idEquipo;

            let datosEquipo=buscarDatosEquipo(idEquipo);
            resultado.nombre = datosEquipo[0];
            resultado.escudo = datosEquipo[1];

            if(partidosProgramados.length) {
                let pj=0;
                let gf=0;
                let gc=0;
                let pts=0;

                let i;
                for (i = 0; i < partidosProgramados.length; i++) {
                    let idJP=partidosProgramados[i][24]; // idjornada
                    let idE=partidosProgramados[i][20]; // idEstado
                    let idEq1=partidosProgramados[i][5]; // idequipo1
                    let idEq2=partidosProgramados[i][8]; // idEquipo2

                    let partidoFinalizado=false;
                    if(idE==("5") || idE==("9") || idE==("10")) partidoFinalizado=true;

                    if(idJP==(idJornada) && partidoFinalizado && (idEq1==(idEquipo) || idEq2==(idEquipo))){
                        let ge1=valorNumero(partidosProgramados[i][15]);
                        let ge2=valorNumero(partidosProgramados[i][16]);

                        if(idEq1==(idEquipo)){
                            gf=gf + ge1;
                            gc=gc + ge2;
                            if(ge1>ge2) pts=pts + puntos_ganador;
                            else if(ge1==ge2){
                                if(permite_empate==1){
                                    pts=pts+1;
                                }
                                else{
                                    let pe1=valorNumero(partidosProgramados[i][36]);
                                    let pe2=valorNumero(partidosProgramados[i][37]);

                                    if(pe1>pe2){
                                        pts=pts+puntos_desempate;
                                    }
                                    else pts=pts+1;
                                }
                            }
                        }
                        else{
                            gf=gf + ge2;
                            gc=gc + ge1;
                            if(ge2>ge1) pts=pts+puntos_ganador;
                            else if(ge1==ge2){
                                if(permite_empate==1){
                                    pts=pts+1;
                                }
                                else{
                                    let pe1=valorNumero(partidosProgramados[i][36]);
                                    let pe2=valorNumero(partidosProgramados[i][37]);

                                    if(pe2>pe1){
                                        pts=pts+puntos_desempate;
                                    }
                                    else pts=pts+1;
                                }
                            }
                        }
                        pj++;

                    }
                }

                resultado.partidosJugados = (pj);
                resultado.golAFavor = (gf);
                resultado.golEnContra = (gc);
                resultado.golDiferencia = (gf-gc);
                resultado.puntos = (pts);
            }

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        }

        return resultado;
    }

    const buscarDatosEquipo = (idEquipo) => {
        const nomFuncion="buscarDatosEquipo";

        let resultado=dividirLista("","|",2);
        try{

            if(infoClasificacion.length){
                let i;
                for(i=0;i<infoClasificacion.length;i++){
                    if(infoClasificacion[i][2]==(idEquipo)){
                        resultado[0]=infoClasificacion[i][3];
                        resultado[1]=infoClasificacion[i][4];
                        i=infoClasificacion.length;
                    }
                }
            }

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        }

        return resultado;
    }

    const ordenarTabla = (tabla) =>{
        const nomFuncion="ordenarTabla";
    
        let tablaClasificacion=[];
        try{
            
            //let tablaClasificacion=Object.assign({} , registro.tablaClasificacion);
            let tablaClasificacion=JSON.parse(JSON.stringify(tabla));
    
            if(tablaClasificacion.length>0){
                let i;
                let tablaOrdenada=[];
                let indicesOrdenados=[]; //new int[tablaClasificacion.size()];
                for(i =0; i<tablaClasificacion.length;i++){
                    indicesOrdenados.push(i);
                }
    
                for(i =0; i< tablaClasificacion.length; i++){
                    let j;
                    let sorted = true; // asumo que para la iteración i el listado es ordenado,
                    for(j =0; j< tablaClasificacion.length - i - 1; j++) { // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición
                        let index=indicesOrdenados[j];
                        let index1=indicesOrdenados[j+1];
                        let numJornada1 = valorNumero(tablaClasificacion[index].jornada);
                        let grupo1 = tablaClasificacion[index].grupo;
                        let puntos1 = tablaClasificacion[index].puntos;
                        let golDif1 = tablaClasificacion[index].golDiferencia;
                        let golFav1 = tablaClasificacion[index].golAFavor;
    
                        let numJornada2 = valorNumero(tablaClasificacion[index1].jornada);
                        let grupo2 = tablaClasificacion[index1].grupo;
                        let puntos2 = tablaClasificacion[index1].puntos;
                        let golDif2 = tablaClasificacion[index1].golDiferencia;
                        let golFav2 = tablaClasificacion[index1].golAFavor;
    
                        if (numJornada1 > numJornada2) {
                            let temp = indicesOrdenados[j];
                            indicesOrdenados[j] = indicesOrdenados[j + 1];
                            indicesOrdenados[j + 1] = temp;
                            sorted = false;
                        } else if (numJornada1 == numJornada2) {
                            if (grupo1 > grupo2) {
                                let temp = indicesOrdenados[j];
                                indicesOrdenados[j] = indicesOrdenados[j + 1];
                                indicesOrdenados[j + 1] = temp;
                                sorted = false;
                            } else if (grupo1 == grupo2) {
                                if (puntos1 < puntos2) {
                                    let temp = indicesOrdenados[j];
                                    indicesOrdenados[j] = indicesOrdenados[j + 1];
                                    indicesOrdenados[j + 1] = temp;
                                    sorted = false;
                                } else if (puntos1 == puntos2) {
                                    if (golDif1 < golDif2) {
                                        let temp = indicesOrdenados[j];
                                        indicesOrdenados[j] = indicesOrdenados[j + 1];
                                        indicesOrdenados[j + 1] = temp;
                                        sorted = false;
                                    } else if (golDif1 == golDif2) {
                                        if (golFav1 < golFav2) {
                                            let temp = indicesOrdenados[j];
                                            indicesOrdenados[j] = indicesOrdenados[j + 1];
                                            indicesOrdenados[j + 1] = temp;
                                            sorted = false;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                    if(sorted){
                        i=tablaClasificacion.length;
                    }
    
                }
    
                for(i =0; i<indicesOrdenados.length;i++){
                    //let temp=Object.assign({} , tablaClasificacion[indicesOrdenados[i]]);
                    let temp=tablaClasificacion[indicesOrdenados[i]];
                    tablaOrdenada.push(temp);
                }
    
                //tablaClasificacion=tablaOrdenada;
    
                let posicion=1;
                let ultGrupo=-1;
                let ultJornada="";
                for(i=0;i<tablaOrdenada.length;i++){
                    if(tablaOrdenada[i].jornada!=(ultJornada) || tablaOrdenada[i].grupo!=ultGrupo){
                        posicion=1;
                        ultJornada=tablaOrdenada[i].jornada;
                        ultGrupo=tablaOrdenada[i].grupo;
                    }
    
                    tablaOrdenada[i].posicion=posicion;
                    posicion++;
                }
    
                return tablaOrdenada;
                          
    
            }
    
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    
        return tablaClasificacion;
    }



    const agregarVistaFixturePartidos = ( partidosOriginal, jornadasOriginal, tipoJornadasOriginal, tablaClasificacion) => {
        const nomFuncion="agregarVistaFixturePartidos";

        //partidosOriginal:  ArrayList<InfoPartido>
        //jornadasOriginal: ArrayList<String> 
        //tipoJornadasOriginal:  ArrayList<String> 
        //tablaClasificacion: ArrayList<InfoTablaPosicionJE> 

        let vista=(<></>);

        try {

            partidos = partidosOriginal;
            jornadas = jornadasOriginal;
            tipoJornadas = tipoJornadasOriginal;
            tablaClasificacionGrupos = tablaClasificacion;

            let niveles;
            let unionVistas=[];
            

            niveles = MAX_NIVELES_FIXTURE_NORMAL;

            let i;

            partidosIncluidos = [];
            gruposIncluidos = [];

            if (jornadas.length > 0) {

                let jornadaActual;
                let j;
                let contJornadas=0;

                for(j=jornadas.length-1;j>=0;j--){
                    jornadaActual = j;

                    //Si es jornada diferente a grupos (FE Partido Unico, o Ida y Vuelta)
                    if(tipoJornadas[jornadaActual]===("0") || tipoJornadas[jornadaActual]===("1")) {
                        for (i = 0; i < partidos.length; i++) {
                            if(partidos[i].numJornada===(jornadas[jornadaActual])){
                                let k;
                                let incluido = false;
                                for (k = 0; k < partidosIncluidos.length; k++) {
                                    if (partidosIncluidos[k] == i) {
                                        incluido = true;
                                        k = partidosIncluidos.length;
                                    }
                                }
                                if (!incluido) {
                                    partidosIncluidos.push(i);


                                    idPartidoArriba="";
                                    idPartidoAbajo="";
                                    if(partidos[i].idArbitro2!==("0")){ //idPartido cruce
                                        if(i<partidos.length-1){
                                            if(partidos[i].idArbitro2===(partidos[i+1].idPartido )){
                                                idPartidoAbajo=partidos[i].idPartido;
                                            }
                                        }                                        

                                    }
                                    else{
                                        if(i>0){
                                            if(partidos[i].idPartido===(partidos[i-1].idArbitro2)){
                                                idPartidoArriba=partidos[i].idPartido;
                                            }
                                        }
                                    }



                                    let vistaAdd = agregarVistaFixtureRecursiva(i, "", jornadaActual, true, niveles - contJornadas);
               
                                    unionVistas.push(vistaAdd);
                                    
                                }
                            }
                            
                        }
                    }
                    else{
                        let vistaAdd = agregarVistaFixtureGrupos("", jornadaActual, true, niveles - contJornadas);
 //FALTA PROCEDIMIENTO
                        unionVistas.push(vistaAdd);
                    }

                    contJornadas++;
                }

                

                vista = unirVistas(unionVistas);

            }




        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        }

        return vista;

    }

    const agregarVistaFixtureRecursiva = (numPartido, idEquipo, numJornada, incluirGanador, maxNiveles) => {


        //int id = layoutFixture;

        let vista=(<></>);

        if(maxNiveles<=0 || numJornada<0){
            return (<></>);
        }

        let niveles=maxNiveles-1;
        let i;
        let numJornadaAnterior=numJornada-1;
        

        if(idEquipo==="" && numPartido<0){

            let vistaAdd=(<></>);
            if(numJornadaAnterior>=0){
                vistaAdd=agregarVistaFixtureRecursiva(-1,"",numJornadaAnterior,false,niveles);
            }

            return dinamico_fixture_partidos(vistaAdd,"",null,null,"",true);

        }
        else if(numJornada>=0 && (tipoJornadas[numJornada]===("2") || tipoJornadas[numJornada]===("3"))){ //Si el tipo de la jornada es de grupos
            return agregarVistaFixtureGrupos(idEquipo, numJornada, true, niveles);
        }

        if(idEquipo!==("")){
            let numPartidoEq=-1;

            if(numJornadaAnterior>=0){
                if(tipoJornadas[numJornadaAnterior]===("0") || tipoJornadas[numJornadaAnterior]===("1")) {
                    let vistaAdd=(<></>);

                    for(i=0;i<partidos.length;i++){
                        if(partidos[i].numJornada===(jornadas[numJornadaAnterior]) && (partidos[i].idEquipo1===(idEquipo) || partidos[i].idEquipo2===(idEquipo))){
                            numPartidoEq=i;
                            i=partidos.length;
                        }
                    }

                    /*llContenedor1.setVisibility(View.INVISIBLE);
                    llContenedor2.setVisibility(View.GONE);
                    llContenedor31.setVisibility(View.INVISIBLE);
                    llContenedor32.setVisibility(View.GONE);
                    llContenedor01.setVisibility(View.INVISIBLE);
                    llContenedor02.setVisibility(View.GONE);*/

                    if(numPartidoEq>=0){
                        let ganadorEq1=false, ganadorEq2=false;
                        let golesEq1=valorNumero(partidos[numPartidoEq].golesEq1);
                        let golesEq2=valorNumero(partidos[numPartidoEq].golesEq2);

                        if(golesEq1>golesEq2) ganadorEq1=true;
                        else if(golesEq1<golesEq2) ganadorEq2=true;
                        else{
                            let penaltisEq1=valorNumero(partidos[numPartidoEq].penaltisEq1);
                            let penaltisEq2=valorNumero(partidos[numPartidoEq].penaltisEq2);

                            if(penaltisEq1>penaltisEq2) ganadorEq1=true;
                            else if(penaltisEq1<penaltisEq2) ganadorEq2=true;
                        }

                        if((idEquipo===(partidos[numPartidoEq].idEquipo1) && ganadorEq1) ||
                                (idEquipo===(partidos[numPartidoEq].idEquipo2) && ganadorEq2)){
                            partidosIncluidos.push(numPartidoEq);
                            vistaAdd=agregarVistaFixtureRecursiva(numPartidoEq,"",numJornadaAnterior,false,niveles);
                            //llZonaEquipo1Anterior.addView(vistaAdd);
                        }
                        else{
                            let idGanador="";
                            if(ganadorEq1) idGanador=partidos[numPartidoEq].idEquipo1;
                            else if(ganadorEq2) idGanador=partidos[numPartidoEq].idEquipo2;
                            else{
                                let k;
                                let incluido=false;
                                for(k=0;k<partidosIncluidos.length;k++){
                                    if(partidosIncluidos[k]===numPartidoEq){
                                        incluido=true;
                                        k=partidosIncluidos.length;
                                    }
                                }

                                if(!incluido){
                                    partidosIncluidos.push(numPartidoEq);
                                    vistaAdd=agregarVistaFixtureRecursiva(numPartidoEq,"",numJornadaAnterior,false,niveles);
                                    //llZonaEquipo1Anterior.addView(vistaAdd);
                                }
                                else{
                                    vistaAdd=agregarVistaFixtureRecursiva(-1,"",numJornadaAnterior,false,niveles);
                                    //llZonaEquipo1Anterior.addView(vistaAdd);
                                }
                            }

                            if(idGanador!==("") && ganadorTienePartido(idGanador,numJornada)){
                                vistaAdd=agregarVistaFixtureRecursiva(-1,"",numJornadaAnterior,false,niveles);
                                //llZonaEquipo1Anterior.addView(vistaAdd);
                            }
                            else if(idGanador!==("")){
                                partidosIncluidos.push(numPartidoEq);
                                vistaAdd=agregarVistaFixtureRecursiva(numPartidoEq,"",numJornadaAnterior,false,niveles);
                                //llZonaEquipo1Anterior.addView(vistaAdd);
                            }

                        }

                    }
                    else{
                        vistaAdd=agregarVistaFixtureRecursiva(-1,idEquipo,numJornadaAnterior,false,niveles);
                        //llZonaEquipo1Anterior.addView(vistaAdd);
                    }

                    return dinamico_fixture_partidos(vistaAdd,"",null,null,"",true);
                }
                else{
                    let vistaAnterior = agregarVistaFixtureGrupos(idEquipo,numJornadaAnterior,false,niveles);
                    //llZonaEquipo1Anterior.addView(vistaAnterior);
                    return dinamico_fixture_partidos(vistaAnterior,"",null,null,"",true);
                }
            }
            else{
                /*llContenedor1.setVisibility(View.INVISIBLE);
                llContenedor2.setVisibility(View.GONE);
                llContenedor31.setVisibility(View.INVISIBLE);
                llContenedor32.setVisibility(View.GONE);
                llContenedor01.setVisibility(View.INVISIBLE);
                llContenedor02.setVisibility(View.GONE);*/

                return dinamico_fixture_partidos((<></>),"",null,null,"",true);
            }

        }
        else if(numPartido>=0){
            let vistaGanador=(<></>);
            let vistaAntEq1=(<></>);
            let vistaAntEq2=(<></>);

            let ganadorEq1=false;
            let ganadorEq2=false;
            let ganoPorPenaltis=false;
            let eqGanador="", escudoGanador="";
            let golesEq1=valorNumero(partidos[numPartido].golesEq1);
            let golesEq2=valorNumero(partidos[numPartido].golesEq2);
            let golesP1Eq1=valorNumero(partidos[numPartido].golesP1Eq1);
            let golesP1Eq2=valorNumero(partidos[numPartido].golesP1Eq2);


            if((golesP1Eq1+golesEq1)>(golesP1Eq2+golesEq2)){
                ganadorEq1=true;
                eqGanador=partidos[numPartido].nomCortoEq1;
                escudoGanador=partidos[numPartido].imgEscudoEq1;
            }
            else if((golesP1Eq1+golesEq1)<(golesP1Eq2+golesEq2)){
                ganadorEq2=true;
                eqGanador=partidos[numPartido].nomCortoEq2;
                escudoGanador=partidos[numPartido].imgEscudoEq2;
            }
            else{
                let penaltisEq1=valorNumero(partidos[numPartido].penaltisEq1);
                let penaltisEq2=valorNumero(partidos[numPartido].penaltisEq2);

                if(penaltisEq1>penaltisEq2){
                    ganadorEq1=true;
                    ganoPorPenaltis=true;
                    eqGanador=partidos[numPartido].nomCortoEq1;
                    escudoGanador=partidos[numPartido].imgEscudoEq1;
                }
                else if(penaltisEq1<penaltisEq2){
                    ganadorEq2=true;
                    ganoPorPenaltis=true;
                    eqGanador=partidos[numPartido].nomCortoEq2;
                    escudoGanador=partidos[numPartido].imgEscudoEq2;
                }
            }

            if(incluirGanador ){

                let linea=0;
                if(idPartidoAbajo!==("")) linea=1;
                else if(idPartidoArriba!==("")) linea=2;

                if(ganadorEq1 || ganadorEq2){
                    if(escudoGanador!=="") escudoGanador=ruta + escudoGanador;
                    else escudoGanador=ic_escudo_base_gris;
                    vistaGanador=dinamico_fixture_ganador(eqGanador,escudoGanador, linea);                
                    //llZonaEquipoGanador.addView(vistaAdd);
                }
                else{
                    vistaGanador=dinamico_fixture_ganador("","", linea);    
                }                

            }

            let ivEquipo1="";
            let ivEquipo2="";

            let tvEquipo1="";
            let tvEquipo2="";

            let tvGolesP1Eq1="";
            let tvGolesP1Eq2="";

            let tvGolesEq1="";
            let tvGolesEq2="";

            let tvPenaltisEq1="";
            let tvPenaltisEq2="";

            tvEquipo1 = (partidos[numPartido].nomCortoEq1);
            tvEquipo2 = (partidos[numPartido].nomCortoEq2);
            tvGolesEq1 = (partidos[numPartido].golesEq1);
            tvGolesEq2 = (partidos[numPartido].golesEq2);

            if(partidos[numPartido].golesP1Eq1!==(""))
                tvGolesP1Eq1 = (partidos[numPartido].golesP1Eq1);
            if(partidos[numPartido].golesP1Eq2!==(""))
                tvGolesP1Eq2 = (partidos[numPartido].golesP1Eq2);


            if(ganadorEq1){
                //llContenedor1.setBackgroundResource(R.drawable.fondo_borde_primary_suave);
                //llContenedor2.setBackgroundResource(R.drawable.fondo_borde_gris);
                ivEquipo1 = (ic_escudo_base2);
                ivEquipo2 = (ic_escudo_base_gris);
                //tvGolesEq1.setTextColor(contexto.getResources().getColor(R.color.uniformeVerdeClaro));
                //tvGolesEq2.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));

                if(ganoPorPenaltis){
                    //tvGolesEq1.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));
                    //tvGolesEq2.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));
                    //tvPenaltisEq1.setTextColor(contexto.getResources().getColor(R.color.uniformeVerdeClaro));
                    //tvPenaltisEq2.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));
                    tvPenaltisEq1 = ("(" + partidos[numPartido].penaltisEq1 + ")");
                    tvPenaltisEq2 = ("(" + partidos[numPartido].penaltisEq2 + ")");
                }
            }
            else if(ganadorEq2){
                //llContenedor2.setBackgroundResource(R.drawable.fondo_borde_primary_suave);
                //llContenedor1.setBackgroundResource(R.drawable.fondo_borde_gris);
                ivEquipo1 = (ic_escudo_base_gris);
                ivEquipo2 = (ic_escudo_base2);
                //tvGolesEq2.setTextColor(contexto.getResources().getColor(R.color.uniformeVerdeClaro));
                //tvGolesEq1.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));

                if(ganoPorPenaltis){
                    //tvGolesEq1.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));
                    //tvGolesEq2.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));
                    //tvPenaltisEq1.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));
                    //tvPenaltisEq2.setTextColor(contexto.getResources().getColor(R.color.uniformeVerdeClaro));
                    tvPenaltisEq1 = ("(" + partidos[numPartido].penaltisEq1 + ")");
                    tvPenaltisEq2 = ("(" + partidos[numPartido].penaltisEq2 + ")");
                }
            }
            else{
                //llContenedor1.setBackgroundResource(R.drawable.fondo_borde_gris);
                //llContenedor2.setBackgroundResource(R.drawable.fondo_borde_gris);
                ivEquipo1 = (ic_escudo_base_gris);
                ivEquipo2 = (ic_escudo_base);
                //tvGolesEq2.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));
                //tvGolesEq1.setTextColor(contexto.getResources().getColor(R.color.uniformeGrisMedio));
            }

            if(partidos[numPartido].imgEscudoEq1!==("")){
                ivEquipo1= ruta + partidos[numPartido].imgEscudoEq1;                
            }

            if(partidos[numPartido].imgEscudoEq2!==("")){
                ivEquipo2= ruta + partidos[numPartido].imgEscudoEq2;  
            }


            if(numJornadaAnterior>=0){

                if(tipoJornadas[numJornadaAnterior]===("0") || tipoJornadas[numJornadaAnterior]===("1")) {
                    let numPartidoEq1=-1;
                    let numPartidoEq2=-1;

                    for(i=0;i<partidos.length;i++){
                        if(partidos[i].numJornada===(jornadas[numJornadaAnterior]) &&
                                (partidos[i].idEquipo1===(partidos[numPartido].idEquipo1) || partidos[i].idEquipo2===(partidos[numPartido].idEquipo1))){
                            numPartidoEq1=i;
                        }
                        if(partidos[i].numJornada===(jornadas[numJornadaAnterior]) &&
                                (partidos[i].idEquipo1===(partidos[numPartido].idEquipo2) || partidos[i].idEquipo2===(partidos[numPartido].idEquipo2))){
                            numPartidoEq2=i;
                        }
                        if(numPartidoEq1>=0 && numPartidoEq2>=0) i=partidos.length;
                    }

                    if(numPartidoEq1>=0){
                        let idEq1=partidos[numPartido].idEquipo1;
                        ganadorEq1=false;
                        ganadorEq2=false;
                        golesEq1=valorNumero(partidos[numPartidoEq1].golesEq1);
                        golesEq2=valorNumero(partidos[numPartidoEq1].golesEq2);
                        golesP1Eq1=valorNumero(partidos[numPartidoEq1].golesP1Eq1);
                        golesP1Eq2=valorNumero(partidos[numPartidoEq1].golesP1Eq2);

                        if((golesP1Eq1+golesEq1)>(golesP1Eq2+golesEq2)) ganadorEq1=true;
                        else if((golesP1Eq1+golesEq1)<(golesP1Eq2+golesEq2)) ganadorEq2=true;
                        else{
                            let penaltisEq1=valorNumero(partidos[numPartidoEq1].penaltisEq1);
                            let penaltisEq2=valorNumero(partidos[numPartidoEq1].penaltisEq2);

                            if(penaltisEq1>penaltisEq2) ganadorEq1=true;
                            else if(penaltisEq1<penaltisEq2) ganadorEq2=true;
                        }

                        if((idEq1===(partidos[numPartidoEq1].idEquipo1) && ganadorEq1) ||
                            (idEq1===(partidos[numPartidoEq1].idEquipo2) && ganadorEq2)){
                            partidosIncluidos.push(numPartidoEq1);
                            vistaAntEq1=agregarVistaFixtureRecursiva(numPartidoEq1,"",numJornadaAnterior,false,niveles);
                            //llZonaEquipo1Anterior.addView(vistaAdd);
                        }
                        else{
                            let idGanador="";
                            if(ganadorEq1) idGanador=partidos[numPartidoEq1].idEquipo1;
                            else if(ganadorEq2) idGanador=partidos[numPartidoEq1].idEquipo2;
                            else{
                                let k;
                                let incluido=false;
                                for(k=0;k<partidosIncluidos.length;k++){
                                    if(partidosIncluidos[k]===numPartidoEq1){
                                        incluido=true;
                                        k=partidosIncluidos.length;
                                    }
                                }

                                if(!incluido){
                                    partidosIncluidos.push(numPartidoEq1);
                                    vistaAntEq1=agregarVistaFixtureRecursiva(numPartidoEq1,"",numJornadaAnterior,false,niveles);
                                    //llZonaEquipo1Anterior.addView(vistaAdd);
                                }
                                else{
                                    vistaAntEq1=agregarVistaFixtureRecursiva(-1,"",numJornadaAnterior,false,niveles);
                                    //llZonaEquipo1Anterior.addView(vistaAdd);
                                }
                            }

                            if(idGanador!==("") && ganadorTienePartido(idGanador,numJornada)){
                                vistaAntEq1=agregarVistaFixtureRecursiva(-1,"",numJornadaAnterior,false,niveles);
                                //llZonaEquipo1Anterior.addView(vistaAdd);
                            }
                            else if(idGanador!==("")){
                                partidosIncluidos.push(numPartidoEq1);
                                vistaAntEq1=agregarVistaFixtureRecursiva(numPartidoEq1,"",numJornadaAnterior,false,niveles);
                                //llZonaEquipo1Anterior.addView(vistaAdd);
                            }

                        }

                    }
                    else{
                        vistaAntEq1=agregarVistaFixtureRecursiva(-1,partidos[numPartido].idEquipo1,numJornadaAnterior,false,niveles);
                        //llZonaEquipo1Anterior.addView(vistaAdd);
                    }

                    if(numPartidoEq2>=0){
                        let idEq2=partidos[numPartido].idEquipo2;
                        ganadorEq1=false;
                        ganadorEq2=false;
                        golesEq1=valorNumero(partidos[numPartidoEq2].golesEq1);
                        golesEq2=valorNumero(partidos[numPartidoEq2].golesEq2);
                        golesP1Eq1=valorNumero(partidos[numPartidoEq2].golesP1Eq1);
                        golesP1Eq2=valorNumero(partidos[numPartidoEq2].golesP1Eq2);

                        if((golesP1Eq1+golesEq1)>(golesP1Eq2+golesEq2)) ganadorEq1=true;
                        else if((golesP1Eq1+golesEq1)<(golesP1Eq2+golesEq2)) ganadorEq2=true;
                        else{
                            let penaltisEq1=valorNumero(partidos[numPartidoEq2].penaltisEq1);
                            let penaltisEq2=valorNumero(partidos[numPartidoEq2].penaltisEq2);

                            if(penaltisEq1>penaltisEq2) ganadorEq1=true;
                            else if(penaltisEq1<penaltisEq2) ganadorEq2=true;
                        }

                        if((idEq2===(partidos[numPartidoEq2].idEquipo1) && ganadorEq1) ||
                                (idEq2===(partidos[numPartidoEq2].idEquipo2) && ganadorEq2)){
                            partidosIncluidos.push(numPartidoEq2);
                            vistaAntEq2=agregarVistaFixtureRecursiva(numPartidoEq2,"",numJornadaAnterior,false,niveles);
                            //llZonaEquipo2Anterior.addView(vistaAdd);
                        }
                        else{
                            let idGanador="";
                            if(ganadorEq1) idGanador=partidos[numPartidoEq2].idEquipo1;
                            else if(ganadorEq2) idGanador=partidos[numPartidoEq2].idEquipo2;
                            else{
                                let k;
                                let incluido=false;
                                for(k=0;k<partidosIncluidos.length;k++){
                                    if(partidosIncluidos[k]===numPartidoEq2){
                                        incluido=true;
                                        k=partidosIncluidos.length;
                                    }
                                }

                                if(!incluido){
                                    partidosIncluidos.push(numPartidoEq2);
                                    vistaAntEq2=agregarVistaFixtureRecursiva(numPartidoEq2,"",numJornadaAnterior,false,niveles);
                                    //llZonaEquipo2Anterior.addView(vistaAdd);
                                }
                                else{
                                    vistaAntEq2=agregarVistaFixtureRecursiva(-1,"",numJornadaAnterior,false,niveles);
                                    //llZonaEquipo2Anterior.addView(vistaAdd);
                                }
                            }

                            if(idGanador!==("") && ganadorTienePartido(idGanador,numJornada)){
                                vistaAntEq2=agregarVistaFixtureRecursiva(-1,"",numJornadaAnterior,false,niveles);
                                //llZonaEquipo2Anterior.addView(vistaAdd);
                            }
                            else if(idGanador!==("")){
                                partidosIncluidos.push(numPartidoEq2);
                                vistaAntEq2=agregarVistaFixtureRecursiva(numPartidoEq2,"",numJornadaAnterior,false,niveles);
                                //llZonaEquipo2Anterior.addView(vistaAdd);
                            }

                        }

                    }
                    else{
                        vistaAntEq2=agregarVistaFixtureRecursiva(-1,partidos[numPartido].idEquipo2,numJornadaAnterior,false,niveles);
                        //llZonaEquipo2Anterior.addView(vistaAdd);
                    }
                }
                else{
                    vistaAntEq1 = agregarVistaFixtureGrupos(partidos[numPartido].idEquipo1,numJornadaAnterior,false,niveles);
                    //llZonaEquipo1Anterior.addView(vistaAnterior);

                    vistaAntEq2 = agregarVistaFixtureGrupos(partidos[numPartido].idEquipo2,numJornadaAnterior,false,niveles);
                    //llZonaEquipo2Anterior.addView(vistaAnterior);
                }
            }

            let datosEq1= {
                tvGolesP1: tvGolesP1Eq1,
                escudo: ivEquipo1,
                tvEquipo: tvEquipo1,
                tvGoles: tvGolesEq1,
                tvPenaltis: tvPenaltisEq1
            }

            let datosEq2= {
                tvGolesP1: tvGolesP1Eq2,
                escudo: ivEquipo2,
                tvEquipo: tvEquipo2,
                tvGoles: tvGolesEq2,
                tvPenaltis: tvPenaltisEq2
            }

            vista = dinamico_fixture_partidos(vistaAntEq1,vistaAntEq2,datosEq1, datosEq2, vistaGanador,false);

        }

        return vista;

    }

    const agregarVistaFixtureGrupos = (idEquipo, numJornada, incluirGanador, maxNiveles) => {


        let unionVistas=[];
        let unionGrupos=[];

        if(maxNiveles<=0 || numJornada<0){
            return (<></>);
        }
        
        let niveles=maxNiveles-1;
        let numJornadaAnterior=numJornada-1;


        let vista = (<></>);
        let vistaGanador = (<></>);
        let vistaAdd = (<></>);


        if(idEquipo!==("")){
            let i;
            let numGrupo=-1;
            for(i=0;i<tablaClasificacionGrupos.length;i++){
                if(tablaClasificacionGrupos[i].jornada===(jornadas[numJornada]) && tablaClasificacionGrupos[i].idequipo===(idEquipo)){
                    numGrupo=tablaClasificacionGrupos[i].grupo;
                    i=tablaClasificacionGrupos.length;
                }
            }

            //ViewGroup grupo = (ViewGroup) vista.findViewById(R.id.llPadreEquiposGrupo_dfcg);

            if(numGrupo>=0) {
                let k;
                let incluido = false;
                for (k = 0; k < gruposIncluidos.length; k++) {
                    let datos=gruposIncluidos[k];
                    if (datos[0]===(jornadas[numJornada]) && datos[1]===(numGrupo)) {
                        incluido = true;
                        k = gruposIncluidos.length;
                    }
                }

                if(!incluido) {
                    let datos=[jornadas[numJornada],(numGrupo)];
                    gruposIncluidos.push(datos);
                    let esPrimero=true;
                    let nombreGrupo="Grupo " + numGrupo;

                    for (i = 0; i < tablaClasificacionGrupos.length; i++) {
                        if (tablaClasificacionGrupos[i].jornada===(jornadas[numJornada]) && tablaClasificacionGrupos[i].grupo === numGrupo) {
                            let idEq=tablaClasificacionGrupos[i].idequipo;

                            if(esPrimero){
                                esPrimero=false;
                                nombreGrupo="Grupo " + numGrupo;
                            }
                            else nombreGrupo="";

                            let vistaAntEq1=(<></>);
                            
                            //vistaAdd = agregarVistaFixtureEquipoGrupo(contexto, tablaClasificacionGrupos.get(i),nombreGrupo);
                            //ViewGroup llZonaEquipo1Anterior = (ViewGroup) vistaAdd.findViewById(R.id.llZonaEquipo1Anterior_dfp);

                            if(numJornadaAnterior>=0){
                                //Si la jornada anterior no es de grupos
                                if(tipoJornadas[numJornadaAnterior]===("0") || tipoJornadas[numJornadaAnterior]===("1")) {
                                    let numPartidoEq = -1;

                                    for (k = 0; k < partidos.length; k++) {
                                        if (partidos[k].numJornada===(jornadas[numJornadaAnterior]) && (partidos[k].idEquipo1===(idEq) || partidos[k].idEquipo2===(idEq))) {
                                            numPartidoEq = k;
                                            k = partidos.length;
                                        }
                                    }

                                    if (numPartidoEq >= 0) {
                                        incluido = false;
                                        for (k = 0; k < partidosIncluidos.length; k++) {
                                            if (partidosIncluidos[k] == numPartidoEq) {
                                                incluido = true;
                                                k = partidosIncluidos.length;
                                            }
                                        }
                                        if (!incluido) {
                                            partidosIncluidos.push(numPartidoEq);
                                            vistaAntEq1 = agregarVistaFixtureRecursiva( numPartidoEq, "", numJornadaAnterior, false, niveles);
                                            //llZonaEquipo1Anterior.addView(vistaAnterior);
                                        }
                                        else{
                                            let vistaAnterior = agregarVistaFixtureRecursiva(-1, idEquipo, numJornadaAnterior-1, false, niveles);
                                            let partidoVacio = dinamico_fixture_partidos(vistaAnterior,"",null,null,"",true);
                                            vistaAntEq1=partidoVacio;

                                        }
                                    } else {

                                        let vistaAnterior = agregarVistaFixtureRecursiva( -1, idEquipo, numJornadaAnterior, false, niveles);
                                        let partidoVacio = dinamico_fixture_partidos(vistaAnterior,"",null,null,"",true);
                                        vistaAntEq1=partidoVacio;

                                    }
                                }
                                else{
                                    vistaAntEq1 = agregarVistaFixtureGrupos(idEq,numJornadaAnterior,false,niveles);   
                                }
                            }

                            //grupo.addView(vistaAdd);
                            vistaAdd =dinamico_fixture_grupo_equipo(vistaAntEq1, tablaClasificacionGrupos[i],nombreGrupo);
                            unionVistas.push(vistaAdd);

                        }
                    }
                }
                else{

                    let vistaAnterior = agregarVistaFixtureRecursiva( -1, "", numJornadaAnterior, false, niveles);
                    vistaAdd = dinamico_fixture_grupo_vacio(vistaAnterior, "Grupo " + numGrupo)
                    unionVistas.push(vistaAdd);
                }
            }
            else{
                let vistaAntEq1=(<></>);

                if(numJornadaAnterior>=0){
                    //Si la jornada anterior no es de grupos
                    if(tipoJornadas[numJornadaAnterior]===("0") || tipoJornadas[numJornadaAnterior]===("1")) {
                        let numPartidoEq = -1;
                        let k;
                        for (k = 0; k < partidos.length; k++) {
                            if (partidos[k].numJornada===(jornadas[numJornadaAnterior]) && (partidos[k].idEquipo1===(idEquipo) || partidos[k].idEquipo2===(idEquipo))) {
                                numPartidoEq = k;
                                k = partidos.length;
                            }
                        }

                        if (numPartidoEq >= 0) {
                            let incluido = false;
                            for (k = 0; k < partidosIncluidos.length; k++) {
                                if (partidosIncluidos[k] == numPartidoEq) {
                                    incluido = true;
                                    k = partidosIncluidos.length;
                                }
                            }
                            if (!incluido) {
                                partidosIncluidos.add(numPartidoEq);
                                vistaAntEq1 = agregarVistaFixtureRecursiva( numPartidoEq, "", numJornadaAnterior, false, niveles);
                                //llZonaEquipo1Anterior.addView(vistaAnterior);
                            }
                            else{

                                let vistaAnterior = agregarVistaFixtureRecursiva(-1, idEquipo, numJornadaAnterior-1, false, niveles);
                                let partidoVacio = dinamico_fixture_partidos(vistaAnterior,"",null,null,"",true);
                                vistaAntEq1=partidoVacio;

                            }
                        } else {
                            let vistaAnterior = agregarVistaFixtureRecursiva(-1, idEquipo, numJornadaAnterior-1, false, niveles);
                            let partidoVacio = dinamico_fixture_partidos(vistaAnterior,"",null,null,"",true);
                            vistaAntEq1=partidoVacio;
                        }
                    }
                    else{

                        vistaAntEq1 = agregarVistaFixtureGrupos(idEquipo,numJornadaAnterior,false,niveles);   

                        //LinearLayout vistaAnterior = agregarVistaFixtureGrupos(contexto,idEquipo,numJornadaAnterior,false,niveles);
                        //llZonaEquipo1Anterior.addView(vistaAnterior);
                    }
                }

                vistaAdd = dinamico_fixture_grupo_vacio(vistaAntEq1, "")
                unionVistas.push(vistaAdd);

            }

        }
        else{

            let nuevoGrupo=false;
            let vGrupo=[];
            let contVGrupo=0;
            //let vistaganador=(<></>);

            let i;
            let grupoActual=-1;
            let incluirEq=false;
            for(i=0;i<tablaClasificacionGrupos.length;i++){
                if(tablaClasificacionGrupos[i].jornada===(jornadas[numJornada])){
                    idEquipo=tablaClasificacionGrupos[i].idequipo;

                    
                    let nombreGrupo="";
                    if(grupoActual !== tablaClasificacionGrupos[i].grupo){

                        if(unionVistas.length>0){                                
                            let grupo=unirVistas(unionVistas);
                            let vistaGrupo = dinamico_fixture_grupo_contenedor(grupo, vistaGanador);
                            unionGrupos.push(vistaGrupo);
                            unionVistas=[];
                        }

                        grupoActual = tablaClasificacionGrupos[i].grupo;

                        let k;
                        let incluido = false;
                        for (k = 0; k < gruposIncluidos.length; k++) {
                            let datos=gruposIncluidos[k];
                            if (datos[0]===(jornadas[numJornada]) && datos[1]===(grupoActual)) {
                                incluido = true;
                                k = gruposIncluidos.length;
                            }
                        }

                        if(!incluido) {                            

                            incluirEq=true;
                            let datos = [jornadas[numJornada], grupoActual];
                            gruposIncluidos.push(datos);
                            nombreGrupo = "Grupo " + grupoActual;

                            //if(escudoGanador!=="") escudoGanador=ruta + escudoGanador;
                            //else escudoGanador=ic_escudo_base_gris;

                            if(tablaClasificacionGrupos[i].partidosJugados>0){
                                let escGanador=ic_escudo_base_gris;
                                if(tablaClasificacionGrupos[i].escudo!=="") escGanador=ruta + tablaClasificacionGrupos[i].escudo;
                                
                                vistaGanador = dinamico_fixture_ganador(tablaClasificacionGrupos[i].nombre, escGanador);
                            }
                            else{
                                vistaGanador = dinamico_fixture_ganador("", "");
                            }                           
                            

                        }
                        else incluirEq=false;
                    }

                    if(incluirEq) {
                        let vistaAnt=(<></>);
                        

                        if (numJornadaAnterior >= 0) {
                            //Si la jornada anterior no es de grupos
                            if (tipoJornadas[numJornadaAnterior]==="0" || tipoJornadas[numJornadaAnterior]==="1") {
                                let numPartidoEq = -1;
                                let k;
                                for (k = 0; k < partidos.length; k++) {
                                    if (partidos[k].numJornada===(jornadas[numJornadaAnterior]) && (partidos[k].idEquipo1===(idEquipo) || partidos[k].idEquipo2===(idEquipo))) {
                                        numPartidoEq = k;
                                        k = partidos.length;
                                    }
                                }
                               

                                if (numPartidoEq >= 0) {
                                    let incluido = false;
                                    for (k = 0; k < partidosIncluidos.length; k++) {
                                        if (partidosIncluidos[k] == numPartidoEq) {
                                            incluido = true;
                                            k = partidosIncluidos.length;
                                        }
                                    }
                                    if (!incluido) {
                                        partidosIncluidos.push(numPartidoEq);
                                        vistaAnt = agregarVistaFixtureRecursiva(numPartidoEq, "", numJornadaAnterior, false, niveles);
                                    }
                                    else{

                                        let vistaAnterior = agregarVistaFixtureRecursiva(-1, idEquipo, numJornadaAnterior-1, false, niveles);
                                        let partidoVacio = dinamico_fixture_partidos(vistaAnterior,"",null,null,"",true);
                                        vistaAnt=partidoVacio;
                                        
                                    }
                                }
                                else {

                                    let vistaAnterior = agregarVistaFixtureRecursiva(-1, idEquipo, numJornadaAnterior-1, false, niveles);
                                    let partidoVacio = dinamico_fixture_partidos(vistaAnterior,"",null,null,"",true);
                                    vistaAnt=partidoVacio;
                                }
                            }
                            else {
                                vistaAnt = agregarVistaFixtureGrupos(idEquipo,numJornadaAnterior,false,niveles);
                                
                            }
                        }

                        vistaAdd = dinamico_fixture_grupo_equipo(vistaAnt, tablaClasificacionGrupos[i], nombreGrupo);
                        unionVistas.push(vistaAdd);
                    }
                }
            }
            
        } 

        if(unionVistas.length>0){                                
            let grupo=unirVistas(unionVistas);
            let vistaGrupo = dinamico_fixture_grupo_contenedor(grupo, vistaGanador);
            unionGrupos.push(vistaGrupo);
            unionVistas=[];
        }
        

        vista=unirVistas(unionGrupos);
        //let grupo = unirVistas(unionVistas);

        //vista = dinamico_fixture_grupo_contenedor(grupo, vistaGanador);

        return vista;
    }



    const ganadorTienePartido = (idEquipo, indexJornada) => {
        let resultado=false;

        try{
            let i;
            let numJornada=valorNumero(jornadas[indexJornada]);
            let numJornadaPartido=0;
            for(i=0;i<partidos.length;i++){
                numJornadaPartido=valorNumero(partidos[i].numJornada);
                if(numJornadaPartido>=numJornada){
                    if(partidos[i].idEquipo1===(idEquipo) || partidos[i].idEquipo2===(idEquipo)){
                        resultado=true;
                        i=partidos.length;
                    }
                }
            }
        }catch (err){

        }

        return resultado;
    }



    const unirVistas = (vistas) => {
        const nomFuncion="unirVistas";


        return (
            <div style={{border:"0px solid blue"}}>
                {vistas.length>0 &&
                        (vistas.map((el, index) => ( <div key={index} style={{border:"0px solid red"}}>{el}</div> ))) 
                } 
            </div>
        );
    }





    const clickMenu = (e, numGrupo) => {
        if(numGrupo!==grupoSel){
            setGrupoSel(numGrupo);      
        }
         
    }


    const incluirMenuCopas = () => {

        if(nombresGrupos.length>0){
            return(
                <div className="CT_menuCopas" style={{lineHeight:"20px", marginBlock:"10px"}}>

                        <div><div className={"BF_botonBordeGris"} style={{flexGrow: "1", flexShrink: "1", flexBasis: "auto", whiteSpace: "pre", minWidth:"120px", maxHeight:"20px", margin:"8px", padding:"2px", fontSize:"14px"}} onClick={(e) => volverFG()}>{"Fase de Grupos"}</div></div>
                        {nombresGrupos.length>0 &&
                            (nombresGrupos.map((el, index) => (                
                                <div key={index}><div className={grupoSel===index ? ("BF_botonPrimaryFull") : ("BF_botonBordeGris")} style={{flexGrow: "1", flexShrink: "1", flexBasis: "auto", whiteSpace: "pre", minWidth:"120px", maxHeight:"20px", margin:"8px", padding:"2px", fontSize:"14px"}} onClick={(e) => clickMenu(e, index)}>{el}</div></div> ))) 
                        } 

                    
                    </div>
                
            );
        }
        else return (<></>);  
        
    }


    const incluirJornadas = () => {

        let titulosFinal=[];
        let jornadaInicial= 0;

        if(grupoPartidosUS[grupoSel].titulosGrupo.length>MAX_NIVELES_FIXTURE_NORMAL)
            jornadaInicial=grupoPartidosUS[grupoSel].titulosGrupo.length - MAX_NIVELES_FIXTURE_NORMAL;


        for(let i=jornadaInicial;i<grupoPartidosUS[grupoSel].titulosGrupo.length;i++){
            titulosFinal.push(grupoPartidosUS[grupoSel].titulosGrupo[i]);
        }

        if(titulosFinal.length>0){
            return(
                <div className="CT_menuCopas" style={{lineHeight:"20px"}}>
                        {titulosFinal.length>0 &&
                            (titulosFinal.map((el, index) => (                
                                <div key={index}><div className={"BF_cajaGris TX_unaSolaLinea2"} style={{width:"168px", height:"20px", margin:"2px", padding:"0px", fontSize:"14px"}}>{el}</div></div> ))) 
                        } 

                    
                    </div>
                
            );
        }
        else return (<></>);  
        
    }

    

    return (

      <div>
        <div className="CT_contenedorTitulo" style={{margin:"10px"}}>
            <img className='IM_imgIcono36' src={ic_fixture} alt='' />   
            <div style={{marginLeft: "10px", fontSize:"18px"}}>Fixture Fase Eliminación</div>             
        </div>  

        <div style={{margin:"10px"}}>
            {grupoSel>=0 && incluirMenuCopas()}

            {grupoSel>=0 && grupoPartidosUS.length>0 && incluirJornadas()}

            {grupoSel>=0 && grupoPartidosUS.length>0 && agregarVistaFixturePartidos(grupoPartidosUS[grupoSel].partidosGrupo,grupoPartidosUS[grupoSel].jornadasGrupo,grupoPartidosUS[grupoSel].tipoJornadas, grupoPartidosUS[grupoSel].tablaClasificacion)}

        </div>

        
      </div>
    );
  };
  
  export default DT_PresentarFixture;