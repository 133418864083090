import { useEffect, useState } from 'react';
import TablaClasificacionGrupo from "./DT_TablaClasificacion_Grupo";

import { registrarLogEventos, reportarError } from "../helpers/funciones";
import { ID_MODULO_DETALLE_TORNEO } from '../constantes/generales';

const nomClase="TablaClasificacion_WB";
const idModulo=ID_MODULO_DETALLE_TORNEO;

let idTorneo="";


const TablaClasificacion = ({ datos, imgAdd, modOrigen, paramOrigen, definirModulo }) => {
    const [grupos, setGrupos] = useState([]);
    //const [parametros, setParametros] = useState("");

    //const nomFuncion="TablaClasificacion";


    useEffect(() => {
        const nomFuncion="useEffect";    
  
        try{
            window.history.pushState("","","/");

            
            let gruposTemp =[];
            let i;
            let ultGrupo="";
        
            for(i=0;i<datos.length;i++){
                if(i===0) idTorneo=datos[i].idtorneo;
                if(datos[i].sinInfo==="1"){
                      if(datos[i].tc_numgrupo!==ultGrupo){
                          ultGrupo=datos[i].tc_numgrupo;
                          gruposTemp.push(ultGrupo);
                      }
                }       
            }    

            setGrupos(gruposTemp);
            
            registrarLogEventos(idModulo,"4",idTorneo,"");
  
        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        }
  
    }, []);


    if(!datos) return null;  


    
 
    const regClick = () => {
        registrarLogEventos(idModulo,"12",idTorneo,"");
    }  


    return (
        <>
            <div className="CT_contenedorFlex">
        
                        {grupos ? 
                        (grupos.map((el) => (                
                        <TablaClasificacionGrupo key={el} datos={datos} grupo={el} cantGrupos={grupos.length} modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />))) 
                        : 
                        (<tr><td>Sin Informacion</td></tr>) }  
        
            </div>

            {imgAdd.length>0 && 
            (
                <div className="CT_contenedorPublicidad">
                    {imgAdd.map((el) => (                
                        <div className="CT_flexPublicidad" key={el}>
                            {el[1]!=="" ? (<a  href={el[1]} target="_blank" rel="noreferrer noopener" onClick={regClick}><img className='IM_imgPublicidad' src={el[0]} alt="" /></a>) 
                            : 
                            (<img className='IM_imgPublicidad' src={el[0]} alt="" />)
                            }                            
                        </div>
                    ))}
                </div>
            )}
    </>
    );
  };
  
  export default TablaClasificacion;