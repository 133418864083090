import {recuperarDatoLocal, reportarError} from '../helpers/funciones';

import "../estilos/TX_Textos.css";
import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";


import ic_escudo from '../recGraficos/ic_escudo_base2_w.png';

import { C_uniformeGrisOscuro, C_uniformeNegro} from '../constantes/generales';


const nomClase="DinamicoCobrosPagosRealizados_WB";

const DinamicoCobrosPagosRealizados = ({datos}) => {
    const nomFuncion="DinamicoCobrosPagosRealizados";


    try{
        if(!datos) return null;

        let escudo=ic_escudo;
        if(datos.escudo!=="") escudo = recuperarDatoLocal("rutaRecursos") + datos.escudo;
    

            
        return (
            <>
                {datos.tieneLinea && (<div className="BF_lineaInferior"></div>)}
                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginBlock:"4px"}} >
                    <div style={{width:"25px"}}>{datos.contador + "."}</div>
                    <div style={{flex:"1"}}>
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                            <div style={{flex:"1", textAlign:"left", fontSize:"14px", color:C_uniformeNegro}}>{datos.fecha}</div>
                            <div style={{textAlign:"right", fontSize:"14px", color:C_uniformeGrisOscuro, fontWeight:"bold"}}>{datos.valor}</div>
                        </div>
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                            <div style={{textAlign:"left", fontSize:"12px", color:C_uniformeNegro, fontWeight:"bold"}}>{"Pagó:"}</div>
                            <img className="IM_imgRedondeada24" src={datos.escudo} alt="" style={{marginInline:"4px"}} />
                            <div style={{flex:"1", textAlign:"left", fontSize:"12px", color:C_uniformeGrisOscuro}}>{datos.quienPago}</div>
                        </div>
                    </div>

                </div>   
                
                </>
        );
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    
};


export default DinamicoCobrosPagosRealizados;