import { useEffect, useRef, useState } from "react";
import ShowError from "../recVisual/ShowError";
import Loader from "../recVisual/Loader";
import { crearTabla, dividirLista, esformatoEmail, esformatoNumero, formatearTexto, getConfirmacion, guardarDatoLocal, isFlagUpdate, openInNewTab, presentarError, presentarLog, quitarCharEspeciales, reconstruirDatosOrigen, recuperarDatoLocal, registrarLogEventos, reportarError, setFlagUpdate, showMensajePantalla, superaTiempoActualizacion, valorLong } from "../helpers/funciones";
import { getDatos } from "../gestionDatos/getDatos";
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeBlanco,  C_colorPrimaryDark, ID_MODULO_INFO_EQUIPO, SEP_3, SEP_4, SEP_D_1, SEPARADOR_PARAMETROS_BD, C_colorTextoSinInfo, C_colorTextoError, ID_MODULO_ADMIN_JUGADORES, ID_MODULO_PRINCIPAL_JUGADOR } from "../constantes/generales";


import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_salir from '../recGraficos/ic_salir_w.png'
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_flecha_expandir from '../recGraficos/ic_flecha_expandir.png';
import ic_tomarfoto_dark from '../recGraficos/ic_tomarfoto_dark.png';
import ic_llamada from '../recGraficos/ic_llamada.png';
import ic_whatsapp from '../recGraficos/ic_whatsapp.png';
import ic_email from '../recGraficos/ic_email.png';
import ic_configura_blanco from '../recGraficos/ic_configura_blanco.png';
import ic_jugador_registro from '../recGraficos/ic_jugador_registro.png';
import ic_entrar from '../recGraficos/ic_entrar_gris.png';


import DinamicoElementosTorneo from "../gestionVisual/DinamicoElementosTorneo";
import { useModal } from "../helpers/useModal";

import "../estilos/BF_BordesFondos.css";
import GestionImagen from "../gestionVisual/GestionImagen";
import ModalFull from "../recVisual/ModalFull";
import Modal from "../recVisual/Modal";


const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Admin_Jugadores_WB";
const idModulo=ID_MODULO_ADMIN_JUGADORES;

let idUser="";
let tipoUser=recuperarDatoLocal("tipoUser");;

let idJugador="";
let idTorneo="";
let idEquipo="";


let datosInicial = {
    tituloVentana:"Administración Jugadores",
    fotoUsuario:ic_jugadorsinimagen,
    rolUsuario:"",
    nomUsuario:"",
    nomEmpresa:"",
    nomEquipo:"",
    escudoEquipo:ic_escudo_base_gris
};

let matrizCampos=[
    {id: "NOMBRE",  idx: 0, valor: "", ultValor:"", error: "Ingrese el nombre del Jugador"},
    {id: "APELLIDO",  idx: 1, valor: "", ultValor:"", error: ""},
    {id: "IDENTIFICACION",  idx: 2, valor: "", ultValor:"", error: ""},
    {id: "TELEFONO",  idx: 3, valor: "", ultValor:"", error: ""},
    {id: "CORREO",  idx: 4, valor: "", ultValor:"", error: ""},
    {id: "FOTO",  idx: 5, valor: "", ultValor:"", error: ""},
    {id: "FILTRO_BUSQUEDA",  idx: 6, valor: "", ultValor:"", error: ""}
];


let estaRegistrado=true;
let puedeModificar=false;

let telefonoACAmbiar="";

let permiteSinTelefono=false;
let aceptoSinTelefono=false;

let titulo="";
let mensaje="";
let ventanaMensaje;


let datosSedeConsultados=false;
let datosProcesados=false;
let cancelarBusqueda=false;
let textoBuscar="";
let infoContactosTotal = [];
let datosFinal = [];
let listasUnidas=false;
let tempArrCampos=[];


let infoJugadorO = [];
let infoJugador = [];
function crearInfoJugador(datos){    
    const nomFuncion="crearInfoJugador";

    try{        
        let jugador = {
            idjugador:"",
            nombre:"",
            apellido:"",
            foto:"",
            telefono:"",
            correo:"",
            telefono1:"",
            documento:"",
            activo:"",
            partidosJugados:"",
            registrado:""
        }
    
        infoJugadorO = [];
        infoJugador = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.nombre=datos[i][j];
                if(j===2) registro.apellido=datos[i][j];
                if(j===3) registro.foto=datos[i][j];
                if(j===4) registro.telefono=datos[i][j];
                if(j===5) registro.correo=datos[i][j];
                if(j===6) registro.telefono1=datos[i][j];
                if(j===7) registro.documento=datos[i][j];
                if(j===8) registro.activo=datos[i][j];
                if(j===9) registro.partidosJugados=datos[i][j];
                if(j===10) registro.registrado=datos[i][j];
            }

            infoJugadorO.push(registro);
            infoJugador.push(datos[i]); 

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoEquiposO = [];
let infoEquipos = [];
function crearInfoEquipos(datos){    
    const nomFuncion="crearInfoEquipos";

    try{        
        let equipo = {
            idequipo:"",
            escudo:"",
            nombreTorneo:"",
            nombreEquipo:"",
            nomPosicion:"",
            camiseta:"",
            esadmin:"",
            activo:"",
            idtorneo:"",
            totalpartidos:""
        }
    
        infoEquiposO = [];
        infoEquipos = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , equipo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idequipo=datos[i][j];
                if(j===1) registro.escudo=datos[i][j];
                if(j===2) registro.nombreTorneo=datos[i][j];
                if(j===3) registro.nombreEquipo=datos[i][j];
                if(j===4) registro.nomPosicion=datos[i][j];
                if(j===5) registro.camiseta=datos[i][j];
                if(j===6) registro.esadmin=datos[i][j];
                if(j===7) registro.activo=datos[i][j];
                if(j===8) registro.idtorneo=datos[i][j];
                if(j===9) registro.totalpartidos=datos[i][j];

            }
            infoEquiposO.push(registro);
            infoEquipos.push(datos[i]);

        }

        if(infoEquiposO.length===1 && (idTorneo==="" || idEquipo==="")){
            idTorneo=infoEquiposO[0].idtorneo;
            idEquipo=infoEquiposO[0].idequipo;
        }
        else if(infoEquiposO.length>=0){
            idTorneo=infoEquiposO[0].idtorneo;
            idEquipo=infoEquiposO[0].idequipo;
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let jugadoresSedeO = [];
let jugadoresSede = [];
function crearInfoJugadoresSede(datos){    
    const nomFuncion="crearInfoJugadoresSede";

    try{        
        let jugador = {
            idjugador:"",
            foto:"",
            nombre:"",
            telefono:"",
            registrado:"",
            pendiente:"",
            cantEquipos:""
        }
    
        jugadoresSedeO = [];
        jugadoresSede = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.foto=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.telefono=datos[i][j];
                if(j===4) registro.registrado=datos[i][j];
                if(j===5) registro.pendiente=datos[i][j];
                if(j===6) registro.cantEquipos=datos[i][j];
            }
            jugadoresSedeO.push(registro);
            jugadoresSede.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let jugadoresTorneoO = [];
let jugadoresTorneo = [];
function crearInfoJugadoresTorneo(datos){    
    const nomFuncion="crearInfoJugadoresTorneo";

    try{        
        let jugador = {
            idjugador:"",
            telefono:"",
            nombre:"",
            nomEquipo:""
        }
    
        jugadoresTorneoO = [];
        jugadoresTorneo = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.telefono=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.nomEquipo=datos[i][j];
            }
            jugadoresTorneoO.push(registro);
            jugadoresTorneo.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoNuevoJugadorO = [];
let infoNuevoJugador = [];
function crearInfoNuevoJugador(datos){    
    const nomFuncion="crearInfoNuevoJugador";

    try{        
        let jugador = {
            tipo:"",
            nomTorneo:"",
            nomEquipo:"",
            idjugador:"",
            telefono:"",
            nombre:"",
            apellido:"",
            documento:"",
            correo:"",
            registrado:""
        }
    
        infoNuevoJugadorO = [];
        infoNuevoJugador = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.tipo=datos[i][j];
                if(j===1) registro.nomTorneo=datos[i][j];
                if(j===2) registro.nomEquipo=datos[i][j];
                if(j===3) registro.idjugador=datos[i][j];
                if(j===4) registro.telefono=datos[i][j];
                if(j===5) registro.nombre=datos[i][j];
                if(j===6) registro.apellido=datos[i][j];
                if(j===7) registro.documento=datos[i][j];
                if(j===8) registro.correo=datos[i][j];
                if(j===9) registro.registrado=datos[i][j];
            }

            infoNuevoJugadorO.push(registro);
            infoNuevoJugador.push(datos[i]); 

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}



const Admin_Jugadores = ({params="", definirModulo, modOrigen=0, paramOrigen=""}) => {
    const [esError, setEsError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);
    const [msjModal, setMsjModal] = useState("");

    const [isOpenBusqueda, openBusqueda, closeBusqueda] = useModal(false);
    const [isOpenImagen, openImagen, closeImagen] = useModal(false);
    
    const [estaRegistradoUS, setEstaRegistradoUS] = useState(false);
    const [puedeModificarUS, setPuedeModificarUS] = useState(false);
    const [estaCambiandoTelefonoUS, setEstaCambiandoTelefonoUS] = useState(false);

    const [arrCampos, setArrCampos] = useState(matrizCampos);
    const [datosMod, setDatosMod] = useState(datosInicial);
    const [datosEquipos, setDatosEquipos] = useState([]);
    const [datosEncontrados, setDatosEncontrados] = useState([]);
    const [datosErrores, setDatosErrores] = useState([]);
    const [datosAlertas, setDatosAlertas] = useState([]);
    

    const [showAlertaSinTelefono, setShowAlertaSinTelefono] = useState(false);
    const [permiteSinTelefonoUS, setPermiteSinTelefonoUS] = useState(false);
    const [confirmoSinTelefonoUS, setConfirmoSinTelefonoUS] = useState(false);
    const [aceptoSinTelefonoUS, setAceptoSinTelefonoUS] = useState(false);


    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{ 
            datosSedeConsultados=false;
            
            let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,SEP_4,5);
                let ultParametrosTotal=ultParametros;
                let datosParametrosGuardados=dividirLista(ultParametrosTotal,SEP_4,2);
                idJugador=datosParametrosGuardados[0];

                if(idJugador==="" || idJugador!==info[0]){
                    actualizar=true;
                    idJugador=info[0];
                }

                if(info[1]===true || info[1]==="true" || info[1]==="1"){
                    puedeModificar=true;
                    setPuedeModificarUS(true);
                }

                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                }
                
                guardarDatoLocal("ultParametros" + idModulo, idJugador);
                //actualizar=true;
                if(actualizar){
                    BD_solicitarDatos("INFO_INICIAL",[]);    
                }
                else{
                    cargarDatosGuardadosModulo();
                }
                
            }
    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [params]);


    if(params==="") return null;

    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleEquipo";
                    consulta="INFO_DETALLE_ADMIN_JUGADOR";
                    
                    paramQuery= idJugador;
                } else if(tipoDatos==="ACTUALIZAR_CAMPOS_JUGADOR"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarJugador";
                    consulta="ACTUALIZAR_CAMPOS_JUGADOR";
                    mostrarProcesando=false;

                    let txtParejasUdt="";
                    let parejas=params.split(SEP_D_1);
                    for(let i=0;i<parejas.length;i++){
                        let columnas=parejas[i].split(SEP_3);
                        let valor="";
                        if(columnas.length===2) valor=columnas[1].trim();

                        if(valor==="NULL"){
                            if(txtParejasUdt==="") txtParejasUdt=columnas[0] + "=NULL ";
                            else txtParejasUdt=txtParejasUdt + ", " + columnas[0] + "=NULL ";
                        }
                        else{
                            if(txtParejasUdt==="") txtParejasUdt=columnas[0] + "='" + valor + "' ";
                            else txtParejasUdt=txtParejasUdt + ", " + columnas[0] + "='" + valor + "' ";
                        }
                    }
                    
                    paramQuery= idJugador + SEP + txtParejasUdt;
                } else if(tipoDatos==="GUARDAR_IMAGEN_SERVIDOR"){
                    tipoConsulta="ENVIAR_IMAGEN";
                    funcion="jugador";
                    consulta="";

                    let imagen=params.replace("data:image/jpeg;base64,","");;
                    let id=idJugador;
                    let indice="0";

                    paramQuery=[imagen,id,indice];
                } else if(tipoDatos==="BORRAR_IMAGEN_SERVIDOR_JUGADOR"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarJugador";
                    consulta="BORRAR_IMAGEN_SERVIDOR_JUGADOR";
                    mostrarProcesando=false;

                    paramQuery=idJugador;
                } else if(tipoDatos==="CANTIDADES_INFO_JUGADORES_SEDE"){
                    tipoConsulta="consultarDato";
                    funcion="getListasDetalleEquipo";
                    consulta="CANTIDADES_INFO_JUGADORES_SEDE";
                    mostrarProcesando=false;

                    let idSede= recuperarDatoLocal("idSede");
                    paramQuery= idSede + SEP + idTorneo;

                    //datosSedeConsultados=false;
                } else if(tipoDatos==="RECUPERAR_INFO_JUGADORES_SEDE"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleEquipo";
                    consulta="RECUPERAR_INFO_JUGADORES_SEDE";
                    mostrarProcesando=false;

                    let idSede= recuperarDatoLocal("idSede");
                    paramQuery= idSede + SEP + idTorneo;

                    datosSedeConsultados=false;
                } else if(tipoDatos==="RECUPERAR_INFO_JUGADOR"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleJugador";
                    consulta="RECUPERAR_INFO_JUGADOR";
                    mostrarProcesando=true;
                    
                    let idSede= recuperarDatoLocal("idSede");
                    let telefono=params;
                    paramQuery= idSede + SEP + idTorneo + SEP + idEquipo + SEP + idJugador + SEP + telefono;
                    
                } else if(tipoDatos==="ACTUALIZAR_DATOS_JUGADOR"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarJugador";
                    consulta="ACTUALIZAR_DATOS_JUGADOR";
                    mostrarProcesando=true;
                    
                    let idSede= recuperarDatoLocal("idSede");
                    let nombre=params[0];
                    let apellido=params[1];
                    let identificacion=params[2];
                    let telefono=params[3];
                    paramQuery= idSede + SEP + idEquipo + SEP + idJugador + SEP + nombre + SEP + apellido + SEP + identificacion + SEP + telefono;
                    
                } 
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
    
                                guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                guardarDatoLocal("datos" + idModulo, respuesta.datos);
    
                                crearInfoJugador(crearTabla(respuesta.datos,"1"));
                                crearInfoEquipos(crearTabla(respuesta.datos,"2"));
    
                                generarDatosPresentar();
                            
                                registrarLogEventos(idModulo, "1", idJugador,"");
                            } else if(tipoDatos==="ACTUALIZAR_CAMPOS_JUGADOR"){
                                
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

    
                                let llaves=params.split(SEP_D_1);
                                for(let i=0;i<llaves.length;i++){
                                    let columnas=llaves[i].split(SEP_3);
                                    if(columnas.length===2){
                                        if(columnas[0]==="nombre1") {
                                            infoJugador[0][1]=columnas[1];
                                            infoJugadorO[0].nombre=columnas[1];
                                            tempArreglo[0].valor=columnas[1];
                                            tempArreglo[0].ultValor=columnas[1];
                                            setFlagUpdate(true);
                                        }
                                        else if(columnas[0]==="apellido1")  {
                                            infoJugador[0][2]=columnas[1];
                                            infoJugadorO[0].apellido=columnas[1];
                                            tempArreglo[1].valor=columnas[1];
                                            tempArreglo[1].ultValor=columnas[1];
                                            setFlagUpdate(true);
                                        }
                                        else if(columnas[0]==="telefono2")  {
                                            infoJugador[0][7]=columnas[1];
                                            infoJugadorO[0].documento=columnas[1];
                                            tempArreglo[2].valor=columnas[1];
                                            tempArreglo[2].ultValor=columnas[1];
                                        }
                                        else if(columnas[0]==="documento")  {
                                            infoJugador[0][4]=columnas[1];
                                            infoJugadorO[0].telefono=columnas[1];
                                            tempArreglo[3].valor=columnas[1];
                                            tempArreglo[3].ultValor=columnas[1];
                                        }
                                    }
                                }
    
                                setArrCampos(tempArreglo);                                                     
                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                reconstruirListasModulo();   

                            } else if(tipoDatos==="GUARDAR_IMAGEN_SERVIDOR"){
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                                tempArreglo[5].valor=params;

                                setArrCampos(tempArreglo);

                                titulo="";
                                mensaje="Imágen actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA"); 

                                setFlagUpdate(true);
                            } else if(tipoDatos==="BORRAR_IMAGEN_SERVIDOR_JUGADOR"){
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                                tempArreglo[5].valor="";
                                setArrCampos(tempArreglo);

                                infoJugador[0][3]="";
                                infoJugadorO[0].foto="";

                                titulo="";
                                mensaje="Imágen BORRADA con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA"); 

                                reconstruirListasModulo(); 
                                generarDatosPresentar();
                                setFlagUpdate(true);
                            } else if(tipoDatos==="CANTIDADES_INFO_JUGADORES_SEDE"){
                                presentarLog(nomClase + "SOLICITA_CANTIDADES " + "DS"); 

                                let valActual=respuesta.datos;
                                let valGuardado=recuperarDatoLocal("cantidades" + ID_MODULO_INFO_EQUIPO + "DS");

                                if(valGuardado===valActual){
                                    cargarDatosGuardadosAdicionales("DS");
                                }
                                else BD_solicitarDatos("RECUPERAR_INFO_JUGADORES_SEDE");
                                
                            } else if(tipoDatos==="RECUPERAR_INFO_JUGADORES_SEDE"){
                                let tipo="DS";
                                presentarLog(nomClase + "SOLICITA_DATOS " + tipo); 

                                //guardarDatoLocal("ultAcceso" + idModulo + tipo,Date.now());
                                guardarDatoLocal("datos" + ID_MODULO_INFO_EQUIPO + tipo, respuesta.datos);

                                crearInfoJugadoresSede(crearTabla(respuesta.datos,"1"));
                                crearInfoJugadoresTorneo(crearTabla(respuesta.datos,"2"));

                                guardarDatoLocal("cantidades" + ID_MODULO_INFO_EQUIPO + tipo, jugadoresSedeO.length + SEP + jugadoresTorneoO.length);

                                datosProcesados=false;
                                listasUnidas=false;
                                datosSedeConsultados=true;

                                registrarLogEventos(idModulo, "9", idJugador,"");
                            } else if(tipoDatos==="RECUPERAR_INFO_JUGADOR"){
                                presentarLog(nomClase + "SOLICITA_DATOS INJ"); 

                                crearInfoNuevoJugador(crearTabla(respuesta.datos,"1"));

                                analizarCambioTelefono();
                            } else if(tipoDatos==="ACTUALIZAR_DATOS_JUGADOR"){
                                let newIdJugador=respuesta.datos;

                                if(newIdJugador!==""){
                                    idJugador=newIdJugador;
                                    setFlagUpdate(true);
                                    BD_solicitarDatos("INFO_INICIAL");

                                    titulo="";
                                    mensaje="Teléfono actualizado con éxito";
                                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA"); 
                                }
                                else{
                                    titulo="";
                                    mensaje="Teléfono NO pudo ser actualizado. Intente nuevamente o reporte el problema a la línea de soporte";
                                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","MEDIA"); 
                                }
                            }       
                                                       
            
                        } else {
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                if(mostrarError) setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            }                         
                        } 

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const cargarDatosGuardadosModulo = () => {
        let nomFuncion="cargarDatosGuardadosModulo"; 

        try{             
            presentarLog(nomClase + " - RECUPERA_DATOS"); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo);

            if(datosModulo!==""){
                crearInfoJugador(crearTabla(datosModulo,"1"));
                crearInfoEquipos(crearTabla(datosModulo,"2"));     
                
                generarDatosPresentar();
            } 
            else BD_solicitarDatos("INFO_INICIAL",[]);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }

    const cargarDatosGuardadosAdicionales = (tipo) => {
        let nomFuncion="cargarDatosGuardadosAdicionales"; 

        try{             
            presentarLog("RECUPERA_DATOS " + tipo); 
            let datosModulo=recuperarDatoLocal("datos" + ID_MODULO_INFO_EQUIPO + tipo);

            if(datosModulo!==""){
                crearInfoJugadoresSede(crearTabla(datosModulo,"1"));
                crearInfoJugadoresTorneo(crearTabla(datosModulo,"2"));   
                
                datosProcesados=false;
                listasUnidas=false;
                datosSedeConsultados=true;
            } 
            else BD_solicitarDatos("RECUPERAR_INFO_JUGADORES_SEDE");

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }
    
    const reconstruirListasModulo = () => {
        let nomFuncion="reconstruirListasModulo"; 

        try{ 
            
            let datosFinales=[];
            datosFinales=reconstruirDatosOrigen(datosFinales,infoJugador,"1");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoEquipos,"2");
            
            guardarDatoLocal("datos" + idModulo, datosFinales);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const generarDatosPresentar = () => {
        let nomFuncion="generarDatosPresentar";

        try{

            setDatosAlertas([]);
            setDatosErrores([]);
            setEstaCambiandoTelefonoUS(false);

            //INFORMACIÓN GENERAL
            if(true){
                let datosTemp=JSON.parse(JSON.stringify(datosMod));         

                datosTemp.rolUsuario="Admin de Equipo:";   
                datosTemp.nomUsuario=recuperarDatoLocal("nomUsuario");
                datosTemp.nomEmpresa=recuperarDatoLocal("nomEmpresa");
                datosTemp.nomSede=recuperarDatoLocal("nomSede");
                datosTemp.fotoUsuario=recuperarDatoLocal("fotoUsuario");
                if(datosTemp.fotoUsuario!=="") datosTemp.fotoUsuario= ruta + datosTemp.fotoUsuario;
                else datosTemp.fotoUsuario=ic_jugadorsinimagen;
    
                datosTemp.nomEquipo=infoJugadorO[0].nombre;
                datosTemp.escudoEquipo=ic_escudo_base_gris;  

                //INFORMACIÓN DEL EQUIPO SELECCIONADO
                if(true){
                    if(infoEquiposO.length>0){
                        let registro=-1;
                        if(idTorneo!=="" && idEquipo!==""){
                            for(let i=0;i<infoEquiposO.length;i++){
                                if(idTorneo===infoEquiposO[i].idtorneo && idEquipo===infoEquiposO[i].idequipo){
                                    registro=i;
                                    i=infoEquiposO.length;
                                }
                            }
                        }


                        if(registro>=0){
                            let texto="";
                            if(infoEquiposO[registro].nombreEquipo!=="") texto=infoEquiposO[registro].nombreEquipo;
                            if(infoEquiposO[registro].nombreTorneo!=="") {
                                if(texto!=="") texto=texto + "\n" + infoEquiposO[registro].nombreTorneo
                                else texto=infoEquiposO[registro].nombreTorneo;
                            }

                            datosTemp.nomEquipo=texto;
                            if(infoEquiposO[registro].escudo!=="") datosTemp.escudoEquipo=ruta + infoEquiposO[registro].escudo; 
                        }


                    }
                }
    
                setDatosMod(datosTemp);
            }           


            //Información del Jugador            
            if(true){               
                let formularioTemp=JSON.parse(JSON.stringify(arrCampos)); 

                if(infoJugadorO[0].registrado==="1") {
                    estaRegistrado=true;
                    setEstaRegistradoUS(true);
                }
                else {
                    estaRegistrado=false;
                    setEstaRegistradoUS(false);
                }
                //estaRegistrado=false;
                //setEstaRegistradoUS(false);

                formularioTemp[0].valor=infoJugadorO[0].nombre;
                formularioTemp[0].ultValor=infoJugadorO[0].nombre;
                if(formularioTemp[0].valor.length>=3) formularioTemp[0].error="";
                formularioTemp[1].valor=infoJugadorO[0].apellido;
                formularioTemp[1].ultValor=infoJugadorO[0].apellido;
                formularioTemp[2].valor=infoJugadorO[0].documento;
                formularioTemp[2].ultValor=infoJugadorO[0].documento;
                formularioTemp[3].valor=infoJugadorO[0].telefono;
                formularioTemp[3].ultValor=infoJugadorO[0].telefono;
                formularioTemp[4].valor=infoJugadorO[0].correo;
                
                if(infoJugadorO[0].foto!=="") formularioTemp[5].valor= ruta + infoJugadorO[0].foto;
                else formularioTemp[5].valor= ic_jugadorsinimagen;
                
                setArrCampos(formularioTemp);
                
            } 


            //Datos de Equipos del jugador
            setDatosEquipos(infoEquiposO);


    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const validarCampo = (e, campo, maxlen) => {
        let nomFuncion="validarCampo"; 

        try{
            let newValor = e.target.value;
            if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);

            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

            if(campo==="NOMBRE"){  
                tempArreglo[0].error=matrizCampos[0].error;
                
                tempArreglo[0].valor=newValor; 
                if(newValor.length>=3) tempArreglo[0].error="";   
                
                setArrCampos(tempArreglo);  
            } else if(campo==="APELLIDO"){  
                tempArreglo[1].error="";
                
                tempArreglo[1].valor=newValor;    
                
                setArrCampos(tempArreglo);  
            } else if(campo==="IDENTIFICACION"){
                tempArreglo[2].error="";
                tempArreglo[2].valor=newValor; 
                setArrCampos(tempArreglo);  
            } else if(campo==="FILTRO_BUSQUEDA_TELEFONO"){
                if(newValor==="" || esformatoNumero(newValor)) {
                    tempArreglo[6].valor=newValor;
                    if(newValor.length>8 && newValor.length<10) tempArreglo[6].error="Ingrese un teléfono de MÍNIMO 10 números para que sea válido.";
                    else if(newValor.length>=10 && newValor.length<15) tempArreglo[6].error="";
                    
                    setArrCampos(tempArreglo);  
                    tempArrCampos=tempArreglo;
                    textoBuscar=newValor;
                }
                
                cancelarBusqueda=true;
                buscarTexto();
            } else if(campo==="FILTRO_BUSQUEDA_NOMBRE"){
                tempArreglo[10].error="";                
                tempArreglo[10].valor=newValor; 
                let tempDatosEncontrados=[];

                if(newValor.length>=3) {
                    tempArreglo[10].error="";  
                    let datoFinal=formatearTexto(newValor,2);
                    datoFinal=quitarCharEspeciales(datoFinal);
                    let tempJugador = {
                        idjugador:"",
                        foto:"",
                        nombre:datoFinal,
                        telefono:"SIN NÚMERO",
                        registrado:"0",
                        pendiente:"3",
                        cantEquipos:""
                    }
                    tempDatosEncontrados.push(tempJugador);                    
                    
                }
                else tempArreglo[10].error="Ingrese el Nombre del Jugador que desea inscribir en el equipo. Debe ser un Texto de mínimo 3 caracteres."; 
                
                setDatosEncontrados(tempDatosEncontrados); 
                setArrCampos(tempArreglo); 
            }           
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
        
    }

    const clickGenerico = (donde, params=null) => {
        let nomFuncion="clickGenerico"; 

        try{
            
            let tempArreglo=null;
            
            switch(donde) {
                  
                case "CLICK_MODIFCAR_ESCUDO":
                    
                    if(estaRegistrado){
                        titulo="NO MODIFICABLE";
                        mensaje="Jugador esta REGISTRADO en MarcaGol, su información basica NO podrá modificarse.";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                    }
                    else if(!puedeModificar){                        
                        titulo="NO MODIFICABLE";
                        mensaje="Fecha límite de modificaciones a datos del jugador superada. Contacte al organizador del torneo";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                    }
                    else{
                        openImagen();
                    }

                    break;
                case "CLICK_MODIFCAR_JUGADOR":      
                    if(estaRegistrado){
                        titulo="NO MODIFICABLE";
                        mensaje="Jugador esta REGISTRADO en MarcaGol, su información basica NO podrá modificarse.";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                    }
                    else if(!puedeModificar){                        
                        titulo="NO MODIFICABLE";
                        mensaje="Fecha límite de modificaciones a datos del jugador superada. Contacte al organizador del torneo";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                    }
                    
                    break;
                case "SALE_NOMBRE":
                    if(arrCampos[0].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[0].valor);
                        nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="nombre1" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[0].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_JUGADOR", txtActualizar);
                    }
                    break;
                case "SALE_APELLIDO":
                    if(arrCampos[1].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[1].valor);
                        nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="apellido1" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[1].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_JUGADOR", txtActualizar);
                    }
                    break;        
                case "SALE_IDENTIFICACION":
                    if(arrCampos[2].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[2].valor);
                        //nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="telefono2" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[2].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_JUGADOR", txtActualizar);
                    }
                    break;  
                case "ABRIR_BUSQUEDA_INSCRIBIR_JUGADORES":
                    if(estaRegistrado){
                        titulo="NO MODIFICABLE";
                        mensaje="Jugador esta REGISTRADO en MarcaGol, su información basica NO podrá modificarse.";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                    }
                    else if(!puedeModificar){                        
                        titulo="NO MODIFICABLE";
                        mensaje="Fecha límite de modificaciones a datos del jugador superada. Contacte al organizador del torneo";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                    }
                    else{
                        abrirVentanaBusqueda();
                    }                    

                    break;
                case "ACEPTO_ALERTA_SINTELEFONO":
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    tempArreglo[10].valor="";
                    tempArreglo[10].error="Ingrese el Nombre del Jugador que desea inscribir en el equipo. Debe ser un Texto de mínimo 3 caracteres.";
                    textoBuscar="";

                    aceptoSinTelefono=true;
                    setAceptoSinTelefonoUS(true);
                    setShowAlertaSinTelefono(false);
                    setArrCampos(tempArreglo);

                    break;
                case "CLICK_INCLUIR_SINTELEFONO":
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    tempArreglo[10].valor="";
                    tempArreglo[10].error="";
                    textoBuscar="";

                    aceptoSinTelefono=false;
                    setAceptoSinTelefonoUS(false);
                    //setConfirmoSinTelefonoUS(false);
                    
                    setArrCampos(tempArreglo);
                    setShowAlertaSinTelefono(true);
                    setDatosEncontrados([]);

                    break;
                case "CLICK_CANCELAR_SINTELEFONO":    
                        aceptoSinTelefono=false;
                        setAceptoSinTelefonoUS(false);
                        setConfirmoSinTelefonoUS(false);                        
                        setShowAlertaSinTelefono(false);
    
                        break;
                case "CLICK_INCLUIR_JUGADOR_BUSQUEDA":                    

                    let telefono=params.telefono;

                    if(telefono!==arrCampos[3].valor){
                        telefonoACAmbiar=telefono;
                        BD_solicitarDatos("RECUPERAR_INFO_JUGADOR", telefono);

                        tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                        tempArreglo[3].valor=telefono;
                        setArrCampos(tempArreglo);
    
                        closeBusqueda();   
                    }   
                    else{
                        titulo="SIN MODIFICACIONES";
                        mensaje="Teléfono ingresado es el mismo al teléfono actual.";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","BAJA"); 
                    }                            

                    break;     
                case "CLICK_HACER_LLAMADA":
                    //openInNewTab("mailto:dario2@hotmail.com");    
                    let ind2=recuperarDatoLocal("indicativo");
                    let tel2=infoJugadorO[0].telefono;
                    if(ind2!=="" && tel2.length>7) openInNewTab("tel:+" + ind2 + tel2);         
                    else{
                        titulo="SIN INFORMACIÓN";
                        mensaje="No se cuenta con la información requerida para realizar llamada";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","BAJA"); 
                    }        
                    
                    break;
                case "CLICK_ENVIAR_CORREO":
                    let correo=infoJugadorO[0].correo;
                    if(correo!=="" && esformatoEmail(correo)) openInNewTab("mailto:" + correo);         
                    else{
                        titulo="SIN INFORMACIÓN";
                        mensaje="No se cuenta con la información requerida para envio de correo";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","BAJA"); 
                    }         
                    
                    break;
                case "CLICK_ABRIR_WHATSAPP":
                    let indicativo=recuperarDatoLocal("indicativo");
                    let tel=infoJugadorO[0].telefono;

                    if(indicativo!=="" && tel.length>7) openInNewTab("https://wa.me/" + indicativo + tel);         
                    else{
                        titulo="SIN INFORMACIÓN";
                        mensaje="No se cuenta con la información requerida para abrir WhatsApp";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","BAJA"); 
                    } 
                        
                    break;
                case "CERRAR_MODAL_IMAGEN":
                    closeImagen();
                    break;
                case "GUARDAR_IMAGEN_SERVIDOR":
                    closeImagen();
                    BD_solicitarDatos("GUARDAR_IMAGEN_SERVIDOR",params);                   

                    break;
                case "SOLICITAR_CONFIRMACION_BORRADO_IMAGEN":
                    titulo="CONFIRMAR BORRADO";
                    mensaje="Esta acción borrará la Imágen Actualmente cargada. Esta de acuerdo?";
                    ventanaMensaje=getConfirmacion(titulo,mensaje,setShowMensaje,null,clickGenerico,"ACEPTAR_BORRADO_IMAGEN");

                    break;
                case "ACEPTAR_BORRADO_IMAGEN":

                    BD_solicitarDatos("BORRAR_IMAGEN_SERVIDOR_JUGADOR");

                    closeImagen();
                    break;
                case "CANCELAR_MODIFICACION_TELEFONO":
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    tempArreglo[0].valor=tempArreglo[0].ultValor;
                    tempArreglo[1].valor=tempArreglo[1].ultValor;
                    tempArreglo[2].valor=tempArreglo[2].ultValor;
                    tempArreglo[3].valor=tempArreglo[3].ultValor;

                    setArrCampos(tempArreglo);
                    setDatosAlertas([]);
                    setDatosErrores([]);
                    setEstaCambiandoTelefonoUS(false);

                    break;
                case "ACEPTAR_MODIFICACION_TELEFONO":
                    let nom=arrCampos[0].valor;
                    let ape=arrCampos[1].valor;
                    let ide=arrCampos[2].valor;
                    let tel3=telefonoACAmbiar;
                    BD_solicitarDatos("ACTUALIZAR_DATOS_JUGADOR", [nom,ape,ide,tel3]);
    
                    break;
                default:
                    break;
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const abrirVentanaBusqueda = () => {
        let nomFuncion="abrirVentanaBusqueda";

        try{
            textoBuscar="";
            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
            tempArreglo[6].valor="";
            tempArreglo[6].error="";
            setArrCampos(tempArreglo);

            cancelarBusqueda=false;

            aceptoSinTelefono=false;
            setAceptoSinTelefonoUS(false);
            setConfirmoSinTelefonoUS(false);            
            setShowAlertaSinTelefono(false);
            setDatosEncontrados([]);
            
            
            openBusqueda();
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    }

    const buscarTexto = () => {
        let nomFuncion="buscarTexto";

        try{
            cancelarBusqueda=false;
            let tipoLista="contacto";
            let tipoElemento=2;
            let regPagina=20;
            let mostrarJugadoresSede=true;
            let textoShow="";
            let tempDatosEncontrados=[];

            if(!isOpenBusqueda) return;


            if(textoBuscar!=="" && textoBuscar.length>8){
                
                let datos=[];
                let datosTI=false;

                let yaEstanContactos=[];
                let yaEstanMarcaGol=[];

                if(tipoUser==="administrador") mostrarJugadoresSede=true;


                if(listasUnidas && datosSedeConsultados){
                    datos=JSON.parse(JSON.stringify(datosFinal));
                }
                else{
                    if(datosSedeConsultados){
                        if(jugadoresSede.length>0 && mostrarJugadoresSede){
                            datos=JSON.parse(JSON.stringify(jugadoresSede));
                        }                   
                    
                        datosProcesados=false;
                        listasUnidas=true;
                    }                    
                }

                if(datos.length>0) datosTI=true;


                if(!datosProcesados && datosTI){
                    datosFinal=JSON.parse(JSON.stringify(datos));
                    //infoContactosTotal=JSON.parse(JSON.stringify(datosFinal));
                    datosProcesados=true;
                }

                let total=0;
                let texto="", textoBuscado="";
                
                textoBuscado=textoBuscar;
                textoBuscado=textoBuscado.replaceAll("+","");

                if(tipoLista==="contacto" && permiteSinTelefono && aceptoSinTelefono){

                    if (valorLong(textoBuscado) >0 || (valorLong(textoBuscado)===0 && textoBuscado.length < 4)) {
                        total = 1;
                        textoShow="Ingrese el Nombre del Jugador que desea inscribir en el equipo. Debe ser un Texto de mínimo 3 caracteres.";
                    }
                    else{
                        total++;
                        let datoFinal=formatearTexto(textoBuscado,2);

                        let tempJugador = {
                            idjugador:"",
                            foto:"",
                            nombre:"Nombre: " + datoFinal,
                            telefono:"Telefono a registrar: NINGUNO",
                            registrado:"1",
                            pendiente:"3",
                            cantEquipos:""
                        }
                        
                        tempDatosEncontrados.push(tempJugador);
                    }


                }
                else{
                    if(datosTI){
                        let i;
                        cancelarBusqueda=false;

                        for(i=0;i<datos.length;i++){
                            if(cancelarBusqueda) return;

                            if(!cancelarBusqueda){
                                let numregistro=i;

                                let busquedaEspecial=false;
                                let yaEsta=false;
                                let yaEstaPrincipal=false;
                                let yaEstaAuxiliar=false;

                                texto=datosFinal[i][3];                                

                                if(tipoLista==="contacto") {

                                    if(textoBuscado==="") busquedaEspecial=true;
                                    else{
                                        if(texto.includes(textoBuscado)) busquedaEspecial=true;
                                        /*else{
                                            texto=datos[i][3];
                                            if(texto.includes(textoBuscado)) busquedaEspecial=true;
                                        }*/
                                    }

                                    if(busquedaEspecial){
                                        for(let j=0;j<yaEstanContactos.length;j++){
                                            if(yaEstanContactos[j]===datos[i][3].trim()){
                                                yaEsta=true;
                                                busquedaEspecial=false;
                                                j=yaEstanContactos.length;
                                            }
                                        }
                                        
                                        if(!yaEsta){
                                            for(let j=0;j<yaEstanMarcaGol.length;j++){
                                                if(yaEstanMarcaGol[j]===datos[i][3].trim()){
                                                    yaEsta=true;
                                                    busquedaEspecial=false;
                                                    j=yaEstanMarcaGol.length;
                                                }
                                            }
                                        }

                                    }

                                }

                                if(busquedaEspecial || (!yaEsta && datos[i][4]==="1" && (textoBuscado==="" || texto.includes(textoBuscado)))){
                                    total++;

                                    if(tipoLista==="contacto") {
                                        if(datos[i][5]==="1") yaEstanMarcaGol.push(datos[i][3].trim());
                                        else yaEstanContactos.push(datos[i][3].trim());
                                    }

                                    tempDatosEncontrados.push(jugadoresSedeO[i]);

                                    if(total>=regPagina) i=datos.length;
                                }

                            }
                            else i=datos.length;

                        }

                    }
                }

                if(total==0) {
                    if (tipoLista==="contacto") {
                        if (textoBuscado.length >=10 && textoBuscado.length < 15){
                            total++;
                            let tempJugador = {
                                idjugador:"",
                                foto:"",
                                nombre:"Jugador MarcaGol Nuevo",
                                telefono: textoBuscado,
                                registrado:"1",
                                pendiente:"3",
                                cantEquipos:""
                            }
                            
                            tempDatosEncontrados.push(tempJugador);
                        }
                        
                    }

                }

                if(!cancelarBusqueda) setDatosEncontrados(tempDatosEncontrados);  

            }
            else setDatosEncontrados(tempDatosEncontrados);

             


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const analizarCambioTelefono = () => {
        let nomFuncion="analizarCambioTelefono";

        try{
            let tempErrores=[];
            let tempAlertas=[];
            let contAlertas=1;
            let hayErrores=false;
            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
            setEstaCambiandoTelefonoUS(false);
            tempArreglo[3].valor=telefonoACAmbiar;

            if(infoNuevoJugadorO.length>0){                

                for(let i=0;i<infoNuevoJugadorO.length;i++){
                    if(infoNuevoJugadorO[i].tipo==="1"){
                        tempErrores.push("Teléfono repetido con Jugador del equipo: " + infoNuevoJugadorO[i].nombre + " " + infoNuevoJugadorO[i].apellido);
                        hayErrores=true;
                        i=infoNuevoJugadorO.length;
                    }
                    else if(infoNuevoJugadorO[i].tipo==="2"){
                        if(tipoUser==="administrador"){
                            tempAlertas.push(contAlertas + ". Teléfono de " + infoNuevoJugadorO[i].nombre + " " + infoNuevoJugadorO[i].apellido + " Ya esta registrado en el equipo " + infoNuevoJugadorO[i].nomEquipo);
                            contAlertas++;
                        }
                        else{
                            tempErrores.push("Teléfono de " + infoNuevoJugadorO[i].nombre + " " + infoNuevoJugadorO[i].apellido + " Ya esta registrado en el equipo " + infoNuevoJugadorO[i].nomEquipo);
                            hayErrores=true;
                        }
                        
                        if(hayErrores) i=infoNuevoJugadorO.length;
                    }

                    if(!hayErrores){
                        if(infoNuevoJugadorO[i].tipo==="3"){
                            if(infoNuevoJugadorO[i].registrado==="1"){
                               
                                tempArreglo[0].valor=infoNuevoJugadorO[i].nombre;
                                tempArreglo[1].valor=infoNuevoJugadorO[i].apellido;
                                //tempArreglo[3].valor=infoNuevoJugadorO[i].telefono;

                                setArrCampos(tempArreglo);

                                tempAlertas.push(contAlertas + ". Jugador ya se encuentra REGISTRADO en MarcaGol, al actualizar el telefono no podra modificarle la información basica del usuario.");
                                contAlertas++;
                                i=infoNuevoJugadorO.length;
                            }
                        }
                    }
                }
            }

            setDatosErrores(tempErrores);
            setDatosAlertas(tempAlertas);

            if(!hayErrores && contAlertas===1){
                let nom=tempArreglo[0].valor;
                let ape=tempArreglo[1].valor;
                let ide=tempArreglo[2].valor;
                let tel=tempArreglo[3].valor;
                BD_solicitarDatos("ACTUALIZAR_DATOS_JUGADOR", [nom,ape,ide,tel]);

            }
            else{
                setEstaCambiandoTelefonoUS(true);
            }
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    }

    const salir = () => {       
        //definirModulo(0, idModulo, params, modOrigen, paramOrigen);
        /*if(modOrigen===0 || paramOrigen===""){
            definirModulo(0,0,"",ID_MODULO_INFO_EQUIPO,idEquipo);     
        }
        else  definirModulo(0,0,"",modOrigen,paramOrigen); */

        definirModulo(0,idModulo,params,modOrigen,paramOrigen);          
    }



    return (
        <>

            {isOpenImagen && (
                <ModalFull isOpen={isOpenImagen} closeModal={closeImagen} isCancelable={false}> 
                    <div><GestionImagen tipoImagen={"JUGADOR"} imgActual={arrCampos[5].valor} clickGenerico={clickGenerico}/></div>                
                </ModalFull>
            )}

            {isOpenBusqueda && (
                <Modal isOpen={isOpenBusqueda} closeModal={closeBusqueda} titulo={"Modificar Teléfono"}  isCancelable={true} txtCancelar="" txtAceptar="" >
                <div style={{margin:"10px", minHeight:"300px", maxHeight:"500px", maxWidth:"400px", fontSize:"16px", color:C_uniformeGrisOscuro}}>                    
                    
                    <div>Escriba o copie el número de teléfono que desea asignar para el jugador. Mínimo 10 dígitos.</div>

                    {showAlertaSinTelefono && (
                        <div className='IP_entradas' style={{margin:"20px"}}></div>
                    )}

                    {!showAlertaSinTelefono && (!permiteSinTelefonoUS || (permiteSinTelefonoUS && !aceptoSinTelefonoUS)) && (
                        <div style={{margin:"20px"}}><input type="number" value={arrCampos[6].valor} className='IP_entradas' style={{width:"100%"}} placeholder="" onChange={(e) => validarCampo(e, "FILTRO_BUSQUEDA_TELEFONO", 14)}/></div>
                    )}

                    {permiteSinTelefonoUS && !showAlertaSinTelefono && aceptoSinTelefonoUS && (
                        <div style={{margin:"20px"}}><input type="text" value={arrCampos[6].valor} className='IP_entradas' style={{width:"100%"}} placeholder="" onChange={(e) => validarCampo(e, "FILTRO_BUSQUEDA_NOMBRE", 40)}/></div>
                    )}
                    

                    {permiteSinTelefonoUS && !aceptoSinTelefonoUS && (
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"-10px", marginBottom:"10px", marginInline:"20px", cursor:"pointer"}} onClick={()=> clickGenerico("CLICK_INCLUIR_SINTELEFONO")}>
                            <div><img className="IM_imgIcono20" src={ic_entrar} alt=""/></div>
                            <div className="TX_letraPeq" style={{flex:"1", marginLeft:"10px", textAlign:"left"}}>{"Incliuir Jugador SIN registrar Teléfono"}</div>
                        </div>
                        
                    )}
                    {permiteSinTelefonoUS && showAlertaSinTelefono && (
                        <div style={{marginInline:"20px"}}>
                            <div style={{fontSize:"14px", color:C_colorTextoError}}>{"* Al ingresar un Jugador SIN número de teléfono, NO PODRÁ registrarse como jugador en MarcaGol y únicamente podrá consultar la información del torneo como Espectador."}</div>
                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"left", textAlign:"left", cursor:"pointer"}}>
                                    <input type="checkbox" name="opcionMenu" onChange={(e)=> setConfirmoSinTelefonoUS(e.target.checked)}/>
                                    <span style={{color:C_uniformeGrisOscuro, marginInline: "10px"}}>{"Estoy de acuerdo"}</span>
                                </label>
                                {confirmoSinTelefonoUS ? 
                                (<div className="BF_botonPrimaryFull" style={{margin:"0px",paddingInline:"0px"}} onClick={() => clickGenerico("ACEPTO_ALERTA_SINTELEFONO")}>Aceptar</div>)
                                : 
                                (<div className="BF_botonTransparenteFull" style={{margin:"0px",paddingInline:"0px"}} onClick={() => clickGenerico("CLICK_CANCELAR_SINTELEFONO")}>Cancelar</div>)
                                }
                                
                            </div>
                        </div>
                    )}
                    
                    
                    {datosEncontrados.length>0 && (<>
                        {(datosEncontrados.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"busqueda_jugadores"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                    </>)} 

                    {datosEncontrados.length===0 && arrCampos[6].error!=="" && (<div style={{color:C_colorTextoSinInfo, whiteSpace:"pre-wrap", marginTop:"10px"}}>
                          {arrCampos[6].error}
                    </div>)} 
                    

                </div> 
                </Modal>
            )}
            
            
            

            {showMensaje && (ventanaMensaje)}


            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                    <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px", cursor:"pointer"}} />  </div>
                    <div className="CT_elementoEncabezado"><img className="IM_imgRedondeada36" height="36px" width="36px" src={ic_configura_blanco} alt="" /></div>
                    <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{datosMod.tituloVentana}</p></div>
                </div>
            </div>

            <div style={{paddingTop:"50px"}}>

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", background:C_colorPrimaryDark, color:C_uniformeBlanco}}>
                    <img className="IM_imgRedondeada46" src={datosMod.fotoUsuario} alt="" style={{padding:"8px"}}/>
                    <div style={{flex:"1", fontSize:"14px", textAlign:"left", color: C_colorAccent }}>
                        <div className="TX_unaSolaLinea2"><span style={{marginRight:"10px"}}>{datosMod.rolUsuario}</span><span style={{color:C_uniformeBlanco}}>{datosMod.nomUsuario}</span></div>
                        <div className="TX_unaSolaLinea2"><span style={{marginRight:"10px"}}>{"Empresa:"}</span><span style={{color:C_uniformeBlanco}}>{datosMod.nomEmpresa}</span></div>
                        <div className="TX_unaSolaLinea2"><span style={{marginRight:"10px"}}>{"Sede:"}</span><span style={{color:C_uniformeBlanco}}>{datosMod.nomSede}</span></div>
                    </div> 
                </div>                  
            
            </div>
                 
                          

            {!loading && esError===0 && (
                <div style={{margin: "10px", paddingBottom:"20px"}}>  
                    <div className="CT_contenedorTitulo" style={{marginBottom:"20px", cursor:"pointer"}} onClick={salir}>
                        <img className='IM_imgIcono28' src={ic_flecha_expandir} alt='' />   
                        <img className='IM_imgRedondeada36' src={datosMod.escudoEquipo} alt='' style={{marginInline:"10px"}}/>   
                        <div style={{fontSize:"18px", whiteSpace:"pre", textAlign:"left"}}>{datosMod.nomEquipo}</div>             
                    </div>

                    {estaRegistradoUS && (
                        <div className="TX_sinInfo" style={{marginBottom:"20px"}}>{"Jugador esta REGISTRADO en MarcaGol, su información basica NO podrá modificarse."}</div>
                    )}

                    {!estaRegistradoUS && !puedeModificar && (
                        <div className="TX_sinInfo" style={{marginBottom:"20px"}}>{"Fecha Límite de Modificaciones SUPERADA, NO es posible modificar información del Jugador."}</div>
                    )}


                    <div style={{display:"flex", flexWrap:"wrap", gap:"20px", justifyContent:"center", alignItems:"start"}}>

                        <div style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"center"}}>
                            
                            <div style={{display:"flex", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"center"}}>
                                
                                <div style={{position:"relative", cursor:"pointer"}} onClick={() => {if(!estaCambiandoTelefonoUS) clickGenerico("CLICK_MODIFCAR_ESCUDO")} }>
                                    <img className="IM_imgRedondeada80" src={arrCampos[5].valor} alt="" style={{padding:"0px", zIndex:"1"}}/>
                                    <div style={{position:"absolute", bottom:"0px", left:"0px"}}>
                                        <img className="IM_imgIcono24" src={ic_tomarfoto_dark} alt="" style={{padding:"0px", zIndex:"2"}}/>
                                    </div>
                                </div>
                                
                                
                                <div style={{flex:"1", display:"block", justifyContent:"left", textAlign:"left"}}>
                                    <div style={{fontSize:"16px", color:"black"}}>Nombre*:</div>
                                    <div style={{display:"flex", alignItems:"center"}}>                                    
                                        {(!estaRegistradoUS && puedeModificarUS && !estaCambiandoTelefonoUS) ? (
                                            <input type="text" value={arrCampos[0].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px"}} placeholder="" onChange={(e) => validarCampo(e, "NOMBRE", 40)} onBlur={(e) => clickGenerico("SALE_NOMBRE")} />                                        
                                        ) : (
                                            <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px", paddingLeft:"10px", cursor:"pointer", display:"flex", alignItems:"center"}}  onClick={() => clickGenerico("CLICK_MODIFCAR_JUGADOR")}><span>{arrCampos[0].valor}</span></div>
                                        )}
                                    </div>
                                    {(!estaRegistradoUS && puedeModificarUS) && arrCampos[0].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[0].error}</span>
                                        </div>
                                    )}

                                    <div style={{fontSize:"16px", color:"black", marginTop:"10px"}}>{"Apellido:"}</div>
                                    <div style={{display:"flex"}}>                                    
                                        {(!estaRegistradoUS && puedeModificarUS && !estaCambiandoTelefonoUS) ? (
                                            <input type="text" value={arrCampos[1].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px"}} placeholder="" onChange={(e) => validarCampo(e, "APELLIDO", 30)} onBlur={(e) => clickGenerico("SALE_APELLIDO")}/>                                        
                                        ) : (
                                            <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px", paddingLeft:"10px", cursor:"pointer", display:"flex", alignItems:"center"}}  onClick={() => clickGenerico("CLICK_MODIFCAR_JUGADOR")}><span>{arrCampos[1].valor}</span></div>
                                        )}
                                    </div>
                                    {(!estaRegistradoUS && puedeModificarUS) && arrCampos[1].error!==""  && (
                                        <div>
                                            <span className="TX_error">{arrCampos[1].error}</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{marginTop:"10px", textAlign:"left"}}>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                    <div style={{width:"150px"}}>No. Identificación:</div>
                                    {(!estaRegistradoUS && puedeModificarUS && !estaCambiandoTelefonoUS) ? (
                                            <input type="number" value={arrCampos[2].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px"}} placeholder="" onChange={(e) => validarCampo(e, "IDENTIFICACION", 14)} onBlur={(e) => clickGenerico("SALE_IDENTIFICACION")}/>                                        
                                        ) : (
                                            <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px", paddingLeft:"10px", cursor:"pointer", display:"flex", alignItems:"center"}}  onClick={() => clickGenerico("CLICK_MODIFCAR_JUGADOR")}><span>{arrCampos[2].valor}</span></div>
                                    )}
                                </div>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                    <div style={{width:"150px"}}>Teléfono:</div>
                                    <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "150px", paddingLeft:"10px", cursor:"pointer", display:"flex", alignItems:"center", fontSize:"14px"}} onClick={() => clickGenerico("ABRIR_BUSQUEDA_INSCRIBIR_JUGADORES")} >
                                                <span>{arrCampos[3].valor}</span>
                                    </div>
                                </div>

                                {datosErrores.length>0 && (<>
                                    <div className="TX_error" style={{fontWeight:"bold"}}>!ALERTA¡ - Error</div>
                                    {(datosErrores.map((reg, index) => (
                                        <div className="TX_error" key={index}>{reg}</div>
                                    ))  )}   
                                </>)}

                                {datosErrores.length===0 && datosAlertas.length>0 && (<>
                                    <div className="TX_sinInfo" style={{fontWeight:"bold"}}>!ALERTA¡</div>
                                    {(datosAlertas.map((reg, index) => (
                                        <div className="TX_sinInfo" key={index}>{reg}</div>
                                    ))  )}   
                                </>)}

                                {(datosErrores.length>0 || datosAlertas.length>0) && (
                                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"nowrap", textAlign:"center"}}>
                                        <div className="BF_botonTransparenteFull" style={{flex:"1", fontSize:"14px", padding:"0px", margin:"0px", fontWeight:"normal", color: C_uniformeGrisOscuro}} onClick={() => clickGenerico("CANCELAR_MODIFICACION_TELEFONO")}>CANCELAR</div>
                                        {datosErrores.length>0 ? (<div style={{flex:"1"}}></div>) : (<div className="BF_botonBordePrimary" style={{flex:"1", fontSize:"14px", padding:"0px", margin:"0px"}} onClick={() => clickGenerico("ACEPTAR_MODIFICACION_TELEFONO")}>MODIFICAR</div>)}
                                    </div>
                                )}

                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                    <div style={{width:"90px"}}>Correo:</div>
                                    <div style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px", display:"flex", alignItems:"center"}} onClick={() => clickGenerico("ENVIAR_CORREO")}><span>{arrCampos[4].valor}</span></div>
                                </div>

                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"20px", cursor:"pointer"}}>
                                    <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}} onClick={() => clickGenerico("CLICK_HACER_LLAMADA")}><img className="IM_imgIcono24" src={ic_llamada} alt=""/><div>Llamada</div></div>
                                    <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}} onClick={() => clickGenerico("CLICK_ABRIR_WHATSAPP")}><img className="IM_imgIcono24" src={ic_whatsapp} alt=""/><div>Chat</div></div>
                                    <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}} onClick={() => clickGenerico("CLICK_ENVIAR_CORREO")}><img className="IM_imgIcono24" src={ic_email} alt=""/><div>Correo</div></div>
                                </div>

                            </div>
                            
                        </div>

                        <div style={{flex:"1", display:"flex", flexWrap:"nowrap", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"start", justifyContent:"space-around"}}>
                            <div className='BF_cajaAccent' style={{minWidth:"250px", maxWidth:"500px", flexGrow:"1", flexShrink:"1", }}>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}> 
                                    <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_jugador_registro} alt='' /> 
                                    <p className='TX_tituloCaja' style={{flex:"1"}}>{"Equipos que esta Inscrito"}</p> 
                                </div>

                                <div className='BF_cajaBlanca'>  
                                    
                                    {datosEquipos.length>0 && (<> 
                                        {(datosEquipos.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"Equipos_de_Jugador"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                                    </>)}                                     

                                    {datosEquipos.length===0  && (
                                    <div style={{padding:"8px"}}>
                                        <span className="TX_sinInfo">{"No esta Inscrito en Equipos"}</span>
                                    </div>
                                    )}        
                                </div>
                            </div>
                        </div>

                        

                    </div>

                    


                </div>
            )}               
              
            

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}   
        </>
    );

};

export default Admin_Jugadores;