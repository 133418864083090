import "../estilos/MD_modales.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";

import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png'

const MenuFlotante = ({ children, isOpen, closeModal, imgModal, titulo="", txtAceptar="ACEPTAR", txtCancelar="CANCELAR", clickCerrar=null, clickCancelar=null, clickAceptar, isCancelable=true}) => {
  const handleModalContainerClick = (e) => e.stopPropagation();

  const cerrar = () => {
    if(clickCerrar===null) closeModal();
    else clickCerrar();    
  } 
  
  const cancelar = () => {
    if(clickCancelar===null) cerrar();
    else clickCancelar();    
  } 

  const nada = () => {} 

  return (
    <article className={`MD_MenuFlotante ${isOpen && "isOpen"}`} onClick={cerrar}>
      <div className="MD_ContenedorFlotante" onClick={handleModalContainerClick} style={{margin:"10px"}}> 

        <div style={{overflow: "auto"}}>
            {children}
        </div>

        
      </div>
    </article>
  );
};

export default MenuFlotante;