import ic_torneo_gris from '../recGraficos/ic_torneo_gris_w.png';
import ic_entrar from '../recGraficos/ic_entrar_gris.png';
import ic_activo from '../recGraficos/ic_activo.png';
import ic_inactivo from '../recGraficos/ic_inactivo.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_uniforme_oscuro from '../recGraficos/ic_uniforme_oscuro.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_silbato from '../recGraficos/ic_silbato.png';
import ic_jugador from '../recGraficos/ic_jugador.png';
import ic_gestion_recursos from '../recGraficos/ic_gestion_recursos.png';


import { presentarError, recuperarDatoLocal, reportarError, valorLong } from '../helpers/funciones';
import { C_colorPrimary, C_fondoAccentSuave, C_uniformeGrisOscuro, C_uniformeNegro } from '../constantes/generales';


const DinamicoElementosTorneo = ({ registro, numRegistro, tipoLista, datos, modOrigen=0, paramOrigen="", modDestino=0, paramDestino="", definirModulo, incluirLinea=true }) => {
    const nomClase="DinamicoElementosTorneo_WB";
    const nomFuncion="DinamicoElementosTorneo";


    try{
        const ruta=recuperarDatoLocal("rutaRecursos");   
        
        let imagen_inicial=ic_torneo_gris;
        let imagen_camiseta="";
        let imagen_final=ic_activo;
        let imagen_entrar=ic_entrar;
        let txtPrincipal="";
        let txtSecundario="";
        let txtCamiseta="";
        let showTxtSecundario=true;
        let textoDestino="";
        let cursor="pointer";
        let colorTxtPrincipal=C_uniformeNegro;

        let estilo= {display:"flex", alignItems:"center", padding:"2px", cursor:cursor, backgroundColor:"transparent"};

        

            if(tipoLista==="torneo-destacado"){        
                if(datos[1]!=="") imagen_inicial= ruta + datos[1];
                txtPrincipal=datos[2];
                txtSecundario="Empresa: " + datos[4] + "\nSede: " + datos[5] + "";
                imagen_final="";
            }
            else if(tipoLista==="params_Info_Equipos"){     
                imagen_inicial="";   
                txtPrincipal=datos[0]
                txtSecundario=datos[1];
                imagen_camiseta="";
                imagen_final="";
                imagen_entrar="";
                estilo.cursor="";
                if(datos[2]==="0") imagen_final=ic_inactivo;
                else if(datos[2]==="1") imagen_final=ic_activo;
                if(datos[3]==="1") {
                    imagen_entrar=ic_entrar;
                    estilo.cursor="pointer";
                }
                if(datos[4]!=="") textoDestino=datos[4];
            }
            else if(tipoLista==="jugadores_Info_Equipos"){     
                imagen_inicial=ic_jugadorsinimagen; 

                if(datos.foto!=="") imagen_inicial= ruta + datos.foto;
                txtPrincipal=datos.nombre;

                txtSecundario="";
                let tempTelefono=datos.telefono;
                if(tempTelefono.length>14) tempTelefono="Sin Número";
                txtSecundario="Tel. " + tempTelefono + "\nPosición: " + datos.nomPosicion;
                
                imagen_camiseta=ic_uniforme_oscuro;
                txtCamiseta=datos.numero;

                imagen_final=ic_inactivo;
                if(datos.activo==="1") imagen_final=ic_activo;

                imagen_entrar=ic_entrar;

                estilo.cursor="pointer";
                if(datos.esadmin==="1") estilo.backgroundColor=C_fondoAccentSuave;
                
                textoDestino="CLICK_JUGADOR";
            }
            else if(tipoLista==="busqueda_jugadores"){
                imagen_inicial=ic_jugadorsinimagen; 

                if(datos.foto!=="") imagen_inicial= ruta + datos.foto;
                txtPrincipal=datos.nombre;

                txtSecundario="";
                let tempTelefono=datos.telefono;
                if(tempTelefono.length>14 && valorLong(tempTelefono)>0) tempTelefono="Sin Número";
                txtSecundario="Tel. " + tempTelefono;

                imagen_final="";
                imagen_entrar=ic_entrar;

                estilo.cursor="pointer";
                
                textoDestino="CLICK_INCLUIR_JUGADOR_BUSQUEDA";
            }
            else if(tipoLista==="Equipos_de_Jugador"){

                imagen_inicial=ic_jugadorsinimagen; 
                if(datos.escudo!=="") imagen_inicial= ruta + datos.escudo;

                txtPrincipal=datos.nombreTorneo + "\n" + datos.nombreEquipo;
                txtSecundario="POSICIÓN: " + datos.nomPosicion;
                imagen_camiseta=ic_uniforme_oscuro;
                txtCamiseta=datos.camiseta;

                imagen_final=ic_inactivo;
                if(datos.activo==="1") imagen_final=ic_activo;

                imagen_entrar="";

                estilo.cursor="";
                if(datos.esadmin==="1") estilo.backgroundColor=C_fondoAccentSuave;
                
                textoDestino="";
            }
            else if(tipoLista==="EQUIPOS_INFO_JUGADOR"){

                imagen_inicial=ic_escudo_base_gris; 
                if(datos.escudo!=="") imagen_inicial= ruta + datos.escudo;

                txtPrincipal=datos.nomTorneo + "\n" + datos.nomEquipo + "\nPosición: " + datos.posicion;
                txtSecundario=datos.nomEmpresa + "\nSede: " + datos.nomSede;
                imagen_camiseta=ic_uniforme_oscuro;
                txtCamiseta=datos.camiseta;

                imagen_final=ic_inactivo;
                if(datos.activo==="1") imagen_final=ic_activo;

                imagen_entrar=ic_entrar;

                estilo.cursor="pointer";
                if(datos.esadmin==="1") estilo.backgroundColor=C_fondoAccentSuave;
                
                textoDestino="EDITAR_DATOS_DE_JUEGO";
            }
            else if(tipoLista==="ARBITRO_INFO_JUGADOR"){

                imagen_inicial=ic_silbato; 

                txtPrincipal=datos.nomEmpresa + "\nSede: " + datos.nomSede;
                txtSecundario=datos.horario;

                imagen_final="";

                imagen_entrar=ic_entrar;

                estilo.cursor="pointer";
                
                textoDestino="POR_DEFINIR";
            }
            else if(tipoLista==="ROLES_GESTION_MICUENTA"){
                let rol = {
                    idusuario:"",
                    idrol:"",
                    nomRol:"",
                    idempresa:"",
                    nomEmpresa:"",
                    idsede:"",
                    nomSede:"",
                    idasociado:""
                }
                
                imagen_inicial=ic_jugador; 
                txtPrincipal="Jugador";
                if(datos.idrol==="1" || datos.idrol==="2") {
                    imagen_inicial=ic_gestion_recursos; 
                    txtPrincipal="Administrador";
                }
                else if(datos.idrol==="3") {
                    imagen_inicial=ic_silbato;  
                    txtPrincipal="Arbitro";
                }
                else if(datos.idrol==="4") {
                    imagen_inicial=ic_jugador; 
                    txtPrincipal="Jugador";
                } 

                txtSecundario=datos.nomEmpresa + "\nSede: " + datos.nomSede;

                imagen_final="";

                imagen_entrar="";

                estilo.cursor="";
                
                textoDestino="";
                colorTxtPrincipal=C_colorPrimary;
            }

            

        

        

        const manejarClick = () => {
            if(tipoLista==="torneo-destacado") definirModulo(registro, modOrigen, paramOrigen, modDestino, paramDestino); 
            else if(tipoLista==="params_Info_Equipos"){
                if(textoDestino!=="") definirModulo(textoDestino, datos);
            } 
            else if(tipoLista==="jugadores_Info_Equipos"){
                if(textoDestino!=="") definirModulo(textoDestino, datos.indice);
            } 
            else if(tipoLista==="busqueda_jugadores"){
                if(textoDestino!=="") definirModulo(textoDestino, datos);
            }
            else if(tipoLista==="EQUIPOS_INFO_JUGADOR"){
                if(textoDestino!=="") definirModulo(textoDestino, registro);
            }
        }

        return(
            <>
            <div style={estilo} onClick={manejarClick}>
                <span style={{width:"25px", fontSize:"14px", color:C_uniformeNegro, fontWeight:"bold", textAlign:"center"}}>{numRegistro}</span>
                {imagen_inicial!=="" && (<img className="IM_imgRedondeada36" src={imagen_inicial} style={{margin:"5px"}} alt=""/>)}  
                <div style={{flex:"1", textAlign:"left", whiteSpace: "pre-wrap"}}>
                    <div style={{fontSize:"14px", color: colorTxtPrincipal}}>{txtPrincipal}</div>
                    {showTxtSecundario && (<div style={{fontSize:"12px", color:C_uniformeGrisOscuro}}>{txtSecundario}</div>)}                
                </div>
                {imagen_camiseta!=="" && (
                    <div style={{position:"relative", justifyContent:"center"}}>
                        <img className="IM_camiseta" src={imagen_camiseta} alt="" style={{padding:"0px", zIndex:"1"}}/>
                        <div style={{position:"absolute", top:"5px", left:"0", zIndex:"2", width:"95%", display:"flex", justifyContent:"center"}}>
                            <span style={{zIndex:"2", color:"white", fontSize:"12px"}}>{txtCamiseta}</span>
                        </div>
                    </div>                
                )}   
                {imagen_final!=="" && (<img className="IM_imgIcono24" src={imagen_final} alt=""/>)}   
                {imagen_entrar!=="" && (<img className="IM_imgIcono24" src={imagen_entrar} alt=""/>)}  
            </div>
            {incluirLinea && (<div className='BF_lineaInferior'></div>)}
            
            </>
        );

    }catch(err){
        reportarError(nomClase, nomFuncion,"",err);
    } 

};


export default DinamicoElementosTorneo;