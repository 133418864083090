import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { useModal } from "../helpers/useModal";

import "../estilos/IM_Imagenes.css";

import ic_error from '../recGraficos/ic_error.png';
import ic_warning from '../recGraficos/ic_warning.png';
import ic_info from '../recGraficos/ic_info.png';
import ic_success from '../recGraficos/ic_success.png';
import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png';

let tiempo=4;
let icono=ic_info;
let fondo="#e5f6fd";
let letras="#3c7289";
let estilo={
  width: "100%"
};

const Mensaje = ({ titulo, mensaje, cerrar, tipo="NORMAL", duracion="MEDIA"}) => {
  const [isOpenMensaje, openMensaje, closeMensaje] = useModal (true);

  const [tiempoRestante, setTiempoRestante] = useState(0);
  const [contadorHabilitado, setContadorHabilitado] = useState(false);


  if(duracion==="BAJA") tiempo=2;
  else if(duracion==="MEDIA") tiempo=4;
  else if(duracion==="ALTA") tiempo=6;
  else tiempo=0;

  if(tipo==="ERROR") {
    icono=ic_error;
    fondo="#fdeded";
    letras="#612423";
    estilo={
      minHeight: "100vh"
    };
  }
  else if(tipo==="INFO") {
    icono=ic_info;
    fondo="#e5f6fd";
    letras="#3c7289";
    estilo={
      width: "100%"
    };
  }
  else if(tipo==="ALERTA") {
    icono=ic_warning;
    fondo="#fff4e5";
    letras="#7a551f";
    estilo={
      width: "100%"
    };
  }
  else if(tipo==="EXITO") {
    icono=ic_success;
    fondo="#edf7ed";
    letras="#6a876b";
    estilo={
      width: "100%"
    };
  }

    useEffect(() => {
      if(isOpenMensaje){
         if(tiempo>0){
          setContadorHabilitado(true);
          setTiempoRestante(tiempo);
         }           
      }       
      else{
        setContadorHabilitado(false);
      } 
    }, [isOpenMensaje]);

    useEffect(() => {
      if(contadorHabilitado){
          if(tiempoRestante<=0){
            clickCerrar();
          } 
          else{
              const timer = setTimeout(() => {
                  let t=tiempoRestante-1;
                  setTiempoRestante(t);
                }, 1000);
              
                return () => clearTimeout(timer);
          }
          
      }        
    }, [tiempoRestante]);

  const clickCerrar = () => {
    if(cerrar===null) closeMensaje();
    else cerrar();    
  } 

  let estiloTitulo = {
    textAlign:"left", 
    whiteSpace:"pre-line", 
    fontSize:"18px", 
    fontWeight:"bold", 
    color: letras
  };

  let estiloMensaje = {
    textAlign:"left", 
    whiteSpace:"pre-line", 
    fontSize:"16px", 
    color: letras
  };
  

  return (
    <>
    {tipo==="NORMAL" && (
      <Modal isOpen={isOpenMensaje} closeModal={clickCerrar}  titulo={titulo}  clickAceptar={clickCerrar} txtCancelar='' >
                <div style={{margin:"30px"}}>
                    <span className="TX_neutro" style={{whiteSpace:"pre-line"}}>{mensaje}</span>                  
                </div>         
                
      </Modal>
    )}

    {tipo!=="NORMAL" && (
      <div className={`MD_Modal_Alerta ${isOpenMensaje && "isOpen"}`}  style={estilo} >
        <div style={{flex:"1", position:"relative", maxWidth:"600px", display:"block", cursor:"pointer", backgroundColor: fondo, margin:"10px", borderRadius:"5px"}} onClick={clickCerrar} > 
            {tiempo===0 && (
              <div style={{position:"absolute", top:"0", right:"0"}}><img className="IM_imgIcono16" src={ic_cancelar_gris} style={{marginRight:"10px", marginTop:"10px"}}/></div>
            )}
            <div style={{margin:"20px", display:"flex", alignItems:"center"}}>
              <img className="IM_imgIcono36" src={icono} alt=""/>
              <div style={{flex:"1", textAlign:"left", display:"block", marginLeft:"20px"}}>
                  {titulo!=="" && (<div style={estiloTitulo}>{titulo}</div>  )}
                  {mensaje!=="" && (<div style={estiloMensaje}>{mensaje}</div> )}                 
              </div> 
            </div>  
        </div>
                   
          
      </div>
    )}
    
    </>
  );
};

export default Mensaje;