import DT_TablaClasificacion_Detalle from "./DT_TablaClasificacion_Detalle";


const TablaClasificacionGrupo = ({ datos, grupo, cantGrupos, modOrigen, paramOrigen, definirModulo}) => {

    let regGrupo=[];

    let i;
    for(i=0;i<datos.length;i++){
        if(datos[i].tc_numgrupo===grupo){
            regGrupo.push(datos[i]);
        }        
    }


    return (
        <div className="CT_tablaCGrupo">
          <table align="center" >
              <tbody>
                  {regGrupo ? 
                  (regGrupo.map((el) => (                
                  <DT_TablaClasificacion_Detalle key={el.idEquipo} datos={el} cantGrupos={cantGrupos} modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />))) 
                  : 
                  (<tr><td>Sin Informacion</td></tr>) }  
              </tbody>
          </table>      
        </div>
      );

};

export default TablaClasificacionGrupo;