import { useEffect, useState } from "react";
import ShowError from "../recVisual/ShowError";
import Loader from "../recVisual/Loader";
import { crearTabla, dividirLista, esformatoEmail, guardarDatoLocal, isFlagUpdate, presentarError, presentarLog, reconstruirDatosOrigen, recuperarDatoLocal, registrarLogEventos, reportarError, showMensajePantalla, superaTiempoActualizacion } from "../helpers/funciones";
import { getDatos } from "../gestionDatos/getDatos";
import { C_colorAccent, C_uniformeBlanco,  C_colorPrimaryDark, SEP_3, SEP_4, SEP_D_1, SEPARADOR_PARAMETROS_BD, C_colorTextoSinInfo, ID_MODULO_PRINCIPAL_JUGADOR, ID_MODULO_GESTION_MICUENTA } from "../constantes/generales";


import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_salir from '../recGraficos/ic_salir_w.png'
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_flecha_expandir from '../recGraficos/ic_flecha_expandir.png';
import ic_tomarfoto_dark from '../recGraficos/ic_tomarfoto_dark.png';
import ic_silbato_blanco from '../recGraficos/ic_silbato_blanco.png';
import ic_jugador_registro from '../recGraficos/ic_jugador_registro.png';
import ic_entrar from '../recGraficos/ic_entrar_gris.png';
import ic_seleccionar_imagen from '../recGraficos/ic_seleccionar_imagen.png';
import ic_mi_informacion_blanco from '../recGraficos/ic_mi_informacion_blanco.png';
import ic_mi_informacion from '../recGraficos/ic_mi_informacion.png';
import ic_aceptar from '../recGraficos/ic_aceptar.png';
import ic_cancelar from '../recGraficos/ic_cancelar.png';


import DinamicoElementosTorneo from "../gestionVisual/DinamicoElementosTorneo";
import { useModal } from "../helpers/useModal";

import "../estilos/BF_BordesFondos.css";

const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Gestion_MiCuenta_WB";
const idModulo=ID_MODULO_GESTION_MICUENTA;

let idUser="";
let tipoUser=recuperarDatoLocal("tipoUser");

let fotoUsuario=ic_jugadorsinimagen;
let nomUsuario="";
let correoUsuario="";
let claveUsuario="";

let estadoCorreo="SIN_VALIDAR";
let dioClickAceptar=false;


let matrizCampos=[
    {id: "CORREO",  idx: 0, valor: "", ultValor:"", error: "Ingrese un correo válido"},
    {id: "CLAVE_ACTUAL",  idx: 1, valor: "", ultValor:"", error: "Contraseña NO válida"},
    {id: "NUEVA_CLAVE",  idx: 2, valor: "", ultValor:"", error: "Nueva Contraseña NO válida"},
    {id: "NUEVA_CLAVE2",  idx: 3, valor: "", ultValor:"", error: "Contraseña NO válida"}
];


let titulo="";
let mensaje="";
let ventanaMensaje;


let infoUsuarioO = [];
let infoUsuario = [];
function crearInfoUsuario(datos){    
    const nomFuncion="crearInfoUsuario";

    try{        
        let usuario = {
            idusuario:"",
            nombre:"",
            telefono:"",
            correo:"",
            password:"",
            activo:"",
            apellido:"",
            foto:""
        }
    
        infoUsuarioO = [];
        infoUsuario = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , usuario);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idusuario=datos[i][j];
                if(j===1) registro.nombre=datos[i][j];
                if(j===2) registro.telefono=datos[i][j];
                if(j===3) registro.correo=datos[i][j];
                if(j===4) registro.password=datos[i][j];
                if(j===5) registro.activo=datos[i][j];
                if(j===6) registro.apellido=datos[i][j];
                if(j===7) registro.foto=datos[i][j];
            }

            infoUsuarioO.push(registro);
            infoUsuario.push(datos[i]); 

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoRolesO = [];
let infoRoles = [];
function crearInfoRoles(datos){    
    const nomFuncion="crearInfoRoles";

    try{        
        let rol = {
            idusuario:"",
            idrol:"",
            nomRol:"",
            idempresa:"",
            nomEmpresa:"",
            idsede:"",
            nomSede:"",
            idasociado:""
        }
    
        infoRolesO = [];
        infoRoles = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , rol);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idusuario=datos[i][j];
                if(j===1) registro.idrol=datos[i][j];
                if(j===2) registro.nomRol=datos[i][j];
                if(j===3) registro.idempresa=datos[i][j];
                if(j===4) registro.nomEmpresa=datos[i][j];
                if(j===5) registro.idsede=datos[i][j];
                if(j===6) registro.nomSede=datos[i][j];
                if(j===7) registro.idasociado=datos[i][j];
            }

            infoRolesO.push(registro);
            infoRoles.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}







const Gestion_MiCuenta = ({params="", definirModulo, modOrigen=0, paramOrigen=""}) => {
    const [esError, setEsError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);
    const [msjModal, setMsjModal] = useState("");

    const [arrCampos, setArrCampos] = useState(matrizCampos);  
    
    const [cambiandoCorreo, setCambiandoCorreo] = useState(false);  
    const [cambiandoClave, setCambiandoClave] = useState(false);  
    const [acepto, setAcepto] = useState(false); 
    const [correoValido, setCorreoValido] = useState(false); 
    const [validando, setValidando] = useState(false); 




    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{        
           
            let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,SEP_4,5);
                
                idUser=ultParametros;

                if(idUser==="" || idUser!==info[0]){
                    actualizar=true;
                    idUser=info[0];
                }

                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                }
                
                guardarDatoLocal("ultParametros" + idModulo, idUser);
                actualizar=true;
                if(actualizar){
                    BD_solicitarDatos("INFO_INICIAL",[]);    
                }
                else{
                    cargarDatosGuardadosModulo();
                }
                
            }
    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [params]);


    if(params==="") return null;

    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleUsuario";
                    consulta="INFO_DETALLE_GESTION_MICUENTA";
                    
                    paramQuery= idUser;
                } else if(tipoDatos==="ACTUALIZAR_CAMPOS_USUARIO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="ACTUALIZAR_CAMPOS_USUARIO";
                    mostrarProcesando=false;

                    let txtParejasUdt="";
                    let parejas=params.split(SEP_D_1);
                    for(let i=0;i<parejas.length;i++){
                        let columnas=parejas[i].split(SEP_3);
                        let valor="";
                        if(columnas.length===2) valor=columnas[1].trim();

                        if(valor==="NULL"){
                            if(txtParejasUdt==="") txtParejasUdt=columnas[0] + "=NULL ";
                            else txtParejasUdt=txtParejasUdt + ", " + columnas[0] + "=NULL ";
                        }
                        else{
                            if(txtParejasUdt==="") txtParejasUdt=columnas[0] + "='" + valor + "' ";
                            else txtParejasUdt=txtParejasUdt + ", " + columnas[0] + "='" + valor + "' ";
                        }
                    }
                    
                    paramQuery= idUser + SEP + txtParejasUdt;
                } else if(tipoDatos==="VALIDAR_NUEVO_CORREO"){
                    tipoConsulta="consultarDato";
                    funcion="getListasDetalleUsuario";
                    consulta="VALIDAR_NUEVO_CORREO";
                    mostrarProcesando=false;
                    setCorreoValido(false);
                    
                    paramQuery= arrCampos[0].valor;
                } 
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
    
                                guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                guardarDatoLocal("datos" + idModulo, respuesta.datos);
    
                                crearInfoUsuario(crearTabla(respuesta.datos,"1"));
                                crearInfoRoles(crearTabla(respuesta.datos,"2"));
    
                                generarDatosPresentar();
                            
                                registrarLogEventos(idModulo, "1", idUser,"");
                            } else if(tipoDatos==="ACTUALIZAR_CAMPOS_USUARIO"){
                                
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
    
                                let llaves=params.split(SEP_D_1);
                                for(let i=0;i<llaves.length;i++){
                                    let columnas=llaves[i].split(SEP_3);
                                    if(columnas.length===2){
                                        if(columnas[0]==="correo") {
                                            infoUsuario[0][3]=columnas[1];
                                            infoUsuarioO[0].correo=columnas[1];
                                            tempArreglo[0].valor=columnas[1];
                                            tempArreglo[0].ultValor=columnas[1];
                                            guardarDatoLocal("email", columnas[1]);
                                        }
                                        else if(columnas[0]==="password")  {
                                            infoUsuario[0][4]=columnas[1];
                                            infoUsuarioO[0].password=columnas[1];
                                            tempArreglo[1].valor=columnas[1];
                                            tempArreglo[1].ultValor=columnas[1];
                                            guardarDatoLocal("password", columnas[1]);
                                        }
                                        
                                    }
                                }
    
                                setArrCampos(tempArreglo);                                                     
                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                reconstruirListasModulo();   
                                generarDatosPresentar();

                            } else if(tipoDatos==="VALIDAR_NUEVO_CORREO"){
                                setValidando(false);
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                                if(respuesta.datos!==""){
                                    estadoCorreo="CON_ERROR";    
                                    setAcepto(true);
                                    setCorreoValido(false);                                    
                                    tempArreglo[0].error="Correo YA existe"
                                    dioClickAceptar=false;
                                }
                                else {
                                    estadoCorreo="VALIDO";    
                                    setCorreoValido(true);
                                    tempArreglo[0].error="";

                                    if(dioClickAceptar && arrCampos[1].error===""){
                                        let nuevoCorreo=arrCampos[0].valor.toLowerCase();
                                        let txtActualizar="correo" + SEP_3 + nuevoCorreo;
                                        if(nuevoCorreo!==arrCampos[0].ultValor.toLowerCase()) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                                    }
                                }
                                setArrCampos(tempArreglo);
                            }      
                                                       
            
                        } else {
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                if(mostrarError) setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            }                       
                        } 

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const cargarDatosGuardadosModulo = () => {
        let nomFuncion="cargarDatosGuardadosModulo"; 

        try{             
            presentarLog(nomClase + " - RECUPERA_DATOS"); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo);

            if(datosModulo!==""){
                crearInfoUsuario(crearTabla(respuesta.datos,"1"));
                crearInfoRoles(crearTabla(respuesta.datos,"2"));
                
                generarDatosPresentar();
            } 
            else BD_solicitarDatos("INFO_INICIAL",[]);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }
    
    const reconstruirListasModulo = () => {
        let nomFuncion="reconstruirListasModulo"; 

        try{ 
            
            let datosFinales=[];
            datosFinales=reconstruirDatosOrigen(datosFinales,infoUsuario,"1");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoRoles,"2");
            
            guardarDatoLocal("datos" + idModulo, datosFinales);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const generarDatosPresentar = () => {
        let nomFuncion="generarDatosPresentar";

        try{

            if(infoUsuarioO[0].foto!=="") fotoUsuario=ruta + infoUsuarioO[0].foto;
            else fotoUsuario=ic_jugadorsinimagen;

            nomUsuario=infoUsuarioO[0].nombre + infoUsuarioO[0].apellido;
            correoUsuario=infoUsuarioO[0].correo;

            let fL=infoUsuarioO[0].password.substring(0,1);
            let lL=infoUsuarioO[0].password.substring(infoUsuarioO[0].password.length-1);
            claveUsuario=fL + "********" + lL;

            estadoCorreo="SIN_VALIDAR";    

            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
            tempArreglo[0].valor=infoUsuarioO[0].correo;
            tempArreglo[0].ultValor=infoUsuarioO[0].correo;
            tempArreglo[0].error="Correo sin modificación";   
            tempArreglo[1].ultValor=infoUsuarioO[0].password;    
            tempArreglo[1].valor="";  
            tempArreglo[2].valor="";
            tempArreglo[3].valor="";
            setArrCampos(tempArreglo);

            setAcepto(false);
            setCambiandoClave(false);
            setCambiandoCorreo(false);
            dioClickAceptar=false;

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const validarCampo = (e, campo, maxlen) => {
        let nomFuncion="validarCampo"; 

        try{
            let newValor = e.target.value;
            if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);

            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

            if(campo==="CORREO"){  
                tempArreglo[0].error=matrizCampos[0].error;
                
                tempArreglo[0].valor=newValor; 
                if(esformatoEmail(newValor)) {
                    if(newValor===tempArreglo[0].ultValor) tempArreglo[0].error="Correo sin modificación";   
                    else tempArreglo[0].error="";   
                }
                
                setArrCampos(tempArreglo);  
            } else if(campo==="CLAVE_ACTUAL"){  
                tempArreglo[1].error=matrizCampos[1].error;
                
                tempArreglo[1].valor=newValor;  
                if(newValor.length>=8) {
                    if(newValor!==arrCampos[1].ultValor) tempArreglo[1].error="NO coincide con contraseña ACTUAL";     
                    else tempArreglo[1].error="";     
                }
                
                setArrCampos(tempArreglo);  
            } else if(campo==="CLAVE_NUEVA"){
                tempArreglo[2].error=matrizCampos[2].error;
                tempArreglo[2].valor=newValor; 
                if(newValor.length>=8) {
                    if(newValor===arrCampos[1].ultValor) tempArreglo[2].error="Contraseña debe ser diferente a ACTUAL";
                    else tempArreglo[2].error="";   
                    if(tempArreglo[3].error==="" && tempArreglo[2].valor!==tempArreglo[3].valor) {
                        tempArreglo[2].error=""; 
                        tempArreglo[3].error="Contraseñas NO coinciden";  
                    }
                }

                setArrCampos(tempArreglo);  
            } else if(campo==="CLAVE_REPETIR"){
                tempArreglo[3].error=matrizCampos[3].error;
                tempArreglo[3].valor=newValor; 
                if(newValor.length>=8) {
                    tempArreglo[3].error="";   
                    if(tempArreglo[2].error==="" && tempArreglo[2].valor!==tempArreglo[3].valor) {
                        tempArreglo[3].error="Contraseñas NO coinciden";  
                    }
                } 
                
                setArrCampos(tempArreglo);  
            }       
                    
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
        
    }

    const clickGenerico = (donde, params=null) => {
        let nomFuncion="clickGenerico"; 

        try{
            
            let tempArreglo=null;
            
            switch(donde) {
                  
                case "SALE_CORREO":
                    if(arrCampos[0].error===""){                        
                        if(arrCampos[0].valor.toLowerCase()!==arrCampos[0].ultValor.toLowerCase()) {   
                            estadoCorreo="VALIDANDO";                         
                            setCorreoValido(false);
                            setValidando(true);
                            BD_solicitarDatos("VALIDAR_NUEVO_CORREO");
                        }
                        else {
                            estadoCorreo="CON_ERROR";     
                            tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                            tempArreglo[0].error="Correo sin modificación";
                            setArrCampos(tempArreglo);
                            setCorreoValido(false);
                        }
                    }
                    break; 
                case "CLICK_CANCELAR_CAMBIO":
                    generarDatosPresentar();                    

                    break;
                case "CLICK_ACEPTAR_CAMBIO":
                    setAcepto(true);

                    let hayErrores=false;
                    dioClickAceptar=true;

                    if(cambiandoCorreo){
                        if(estadoCorreo==="VALIDANDO"){
                            if(arrCampos[1].error==="") setLoading(true);
                            else hayErrores=true;
                        }
                        else{
                            if(estadoCorreo==="VALIDO" && arrCampos[0].error==="" && arrCampos[1].error===""){
                                let nuevoCorreo=arrCampos[0].valor.toLowerCase();
                                let txtActualizar="correo" + SEP_3 + nuevoCorreo;
                                if(nuevoCorreo!==arrCampos[0].ultValor.toLowerCase()) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                            }
                            else hayErrores=true;
                        }                        
                    } else  if(cambiandoClave){
                        if(arrCampos[1].error==="" && arrCampos[2].error==="" && arrCampos[3].error===""){
                            let nuevoDato=arrCampos[2].valor;
                            let txtActualizar="password" + SEP_3 + nuevoDato;
                            if(nuevoDato!==arrCampos[1].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_USUARIO", txtActualizar);
                        }
                        else hayErrores=true;
                    }


                    if(hayErrores){
                        titulo="CON ERRORES";
                        mensaje="Los campos presentan errores, corrija la información y continue el proceso.";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","BAJA");
                        dioClickAceptar=false;
                    }

                    break;
                default:
                    break;
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 


    const salir = () => {       
        definirModulo(0,idModulo,params,ID_MODULO_PRINCIPAL_JUGADOR,recuperarDatoLocal("idUser"));     
    }



    return (
        <>

            {showMensaje && (ventanaMensaje)}


            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                    <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px", cursor:"pointer"}} />  </div>
                    <div className="CT_elementoEncabezado"><img className="IM_imgIcono28" height="36px" width="36px" src={ic_mi_informacion_blanco} alt="" /></div>
                    <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{"Mi Cuenta"}</p></div>
                </div>
            </div>

            <div style={{paddingTop:"50px"}}>

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", background:C_colorPrimaryDark, color:C_uniformeBlanco}}>
                    <img className="IM_imgRedondeada46" src={fotoUsuario} alt="" style={{padding:"8px"}}/>
                    <div style={{flex:"1", fontSize:"14px", textAlign:"left" }}>
                        <div className="TX_unaSolaLinea2"><span style={{color:C_uniformeBlanco}}>{nomUsuario}</span></div>
                        <div className="TX_unaSolaLinea2"><span style={{color:C_colorAccent}}>{"USUARIO"}</span></div>
                    </div> 
                </div>                  
            
            </div>
                 
                          

            {!loading && esError===0 && (
                <div style={{margin: "10px", paddingBottom:"20px"}}>  
                    <div className="CT_contenedorTitulo" style={{marginBottom:"20px", cursor:"pointer"}} onClick={salir}>
                        <img className='IM_imgIcono28' src={ic_flecha_expandir} alt='' />   
                        <img className='IM_imgIcono28' src={ic_mi_informacion} alt='' style={{marginInline:"10px"}}/>   
                        <div style={{fontSize:"18px", whiteSpace:"pre", textAlign:"left"}}>{"Administra tus datos de acceso"}</div>             
                    </div>


                    <div style={{display:"flex", flexWrap:"wrap", gap:"30px", justifyContent:"center", alignItems:"start"}}>

                        <div style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"400px", alignItems:"center"}}>
                            
                            
                            <div style={{marginTop:"10px", textAlign:"left"}}>

                                <div style={{fontWeight:"bold"}}>Correo:</div>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>                                    
                                    <div className="TX_neutro TX_unaSolaLinea2" style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px", display:"flex", alignItems:"center"}}><span>{correoUsuario}</span></div>
                                    {!cambiandoCorreo && !cambiandoClave && (<div className="BF_botonTransparenteFull" style={{margin:"0px", textAlign:"center", color:C_colorTextoSinInfo}} onClick={() => setCambiandoCorreo(true)}>CAMBIAR</div>)}                                    
                                </div>

                                <div style={{marginTop:"20px", fontWeight:"bold"}}>Contraseña:</div>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>                                    
                                    <div className="TX_neutro TX_unaSolaLinea2" style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px", display:"flex", alignItems:"center"}}><span>{claveUsuario}</span></div>
                                    {!cambiandoCorreo && !cambiandoClave && (<div className="BF_botonTransparenteFull" style={{margin:"0px", textAlign:"center", color:C_colorTextoSinInfo}} onClick={() => setCambiandoClave(true)}>CAMBIAR</div>)}
                                </div>

                                {(cambiandoCorreo || cambiandoClave) && (
                                    <div className="TX_sinInfo" style={{margin:"15px"}}>Ingrese los datos solicitados para realizar el cambio requerido</div>
                                ) }

                                {cambiandoCorreo && (<>
                                    <div >Correo Nuevo*:</div>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>                                        
                                        <input type="email" value={arrCampos[0].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px"}} placeholder="" onChange={(e) => validarCampo(e, "CORREO", 40)} onBlur={(e) => clickGenerico("SALE_CORREO")}/>  
                                        {validando && (<div style={{marginInline:"6px"}}><Loader tipo={"SMALL"}/></div>)}
                                        {correoValido && arrCampos[0].error==="" && (<img style={{marginInline:"6px"}} className='IM_imgIcono20' src={ic_aceptar} alt='' />   )}
                                        {arrCampos[0].error!=="" && (<img style={{marginInline:"6px"}} className='IM_imgIcono20' src={ic_cancelar} alt='' />   )}
                                    </div>
                                    {acepto && arrCampos[0].error!==""  && (
                                            <div>
                                                <span className="TX_error">{arrCampos[0].error}</span>
                                            </div>
                                    )}
                                </>)}
                                

                                {(cambiandoCorreo || cambiandoClave) && (<>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                        <div style={{width:"180px"}}>Contraseña ACTUAL*:</div>
                                        <input type="password" value={arrCampos[1].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "250px"}} placeholder="" onChange={(e) => validarCampo(e, "CLAVE_ACTUAL", 14)} onBlur={(e) => clickGenerico("SALE_CLAVE_ACTUAL")}/>   
                                    </div>
                                    {acepto && arrCampos[1].error!==""  && (
                                            <div>
                                                <span className="TX_error">{arrCampos[1].error}</span>
                                            </div>
                                    )}
                                    </>
                                )}
                                
                                {cambiandoClave && (<>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                        <div style={{width:"180px"}}>Contraseña NUEVA*:</div>
                                        <input type="password" value={arrCampos[2].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "250px"}} placeholder="" onChange={(e) => validarCampo(e, "CLAVE_NUEVA", 14)} onBlur={(e) => clickGenerico("SALE_CLAVE_NUEVA")}/>   
                                    </div>
                                    {acepto && arrCampos[2].error!==""  && (
                                            <div>
                                                <span className="TX_error">{arrCampos[2].error}</span>
                                            </div>
                                    )}

                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                        <div style={{width:"180px"}}>REPETIR*:</div>
                                        <input type="password" value={arrCampos[3].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "250px"}} placeholder="" onChange={(e) => validarCampo(e, "CLAVE_REPETIR", 14)} onBlur={(e) => clickGenerico("SALE_CLAVE_REPETIR")}/>   
                                    </div>
                                    {acepto && arrCampos[3].error!==""  && (
                                            <div>
                                                <span className="TX_error">{arrCampos[3].error}</span>
                                            </div>
                                    )}
                                </>)}
                                


                            </div>

                            {(cambiandoCorreo || cambiandoClave)  && (
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginBlock:"40px"}}>
                                    <div className="BF_botonTransparenteFull" style={{flexGrow:"3", flexShrink:"1"}} onClick={() => clickGenerico("CLICK_CANCELAR_CAMBIO")}>{"CANCELAR"}</div>                                    
                                    <div style={{flexGrow:"1", flexShrink:"1"}}></div>                                    
                                    <div className="BF_botonPrimaryFull" style={{flexGrow:"3", flexShrink:"1"}} onClick={() => clickGenerico("CLICK_ACEPTAR_CAMBIO")}>{"ACEPTAR"}</div>
                                </div>
                            )}
                            
                            
                        </div>




                        {!cambiandoCorreo && !cambiandoClave && (
                        <div style={{flex:"1", display:"flex", flexWrap:"wrap", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", gap:"20px"}}>
                            
                            {infoRolesO.length>0 && (
                                <div className='BF_cajaAccent' style={{minWidth:"250px", maxWidth:"500px", flexGrow:"1", flexShrink:"1", }}>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}> 
                                        <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_jugador_registro} alt='' /> 
                                        <p className='TX_tituloCaja' style={{flex:"1"}}>{"Roles Activos en MarcaGol"}</p> 
                                    </div>

                                    <div className='BF_cajaBlanca'>  
                                        
                                        {infoRolesO.length>0 && (<> 
                                            {(infoRolesO.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"ROLES_GESTION_MICUENTA"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                                        </>)}                                          
                                    </div>
                                </div>
                            )}                          
                            

                        </div>
                        )}
                        

                        

                    </div>

                    


                </div>
            )}               
              
            

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}   
        </>
    );

};

export default Gestion_MiCuenta;