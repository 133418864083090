import { C_uniformeBlanco, C_uniformeGrisOscuro } from '../constantes/generales';
import {formatearDecimal, presentarError, recuperarDatoLocal, reportarError, valorNumero} from '../helpers/funciones';

import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import DinamicoRankingJugadoresDet from './DinamicoRankingJugadoresDet';

let datosFinales=[];

const ruta=recuperarDatoLocal("rutaRecursos");


const DinamicoRankingIndicadores = ({ indicador, titulo, subtitulo, imagen, esporcentaje, idxCant, infoOrdenadaJugadores, infoEstadisticas, modOrigen, paramOrigen, definirModulo, idTorneo="" }) => {
    const nomClase="DinamicoRankingIndicadores_WB";
    const nomFuncion="DinamicoRankingIndicadores";

       
    try{
 
        const llenarDatos = () => {

            let matrizDatos=[];

            if(infoOrdenadaJugadores.length>0 && infoEstadisticas.length>0){           

        
                let i=0;
                let hayDatos=false;
    
                try{
                    let registros=infoOrdenadaJugadores[indicador].length-1;
                    let contRegistros=0;
            
                    for(i=0;i<registros+1;i++) {
                        let datos=["","","","","","","",""];

                        if (true) { //infoEstadisticas[i][0]===idTorneo
                            let cantidad = valorNumero(infoEstadisticas[infoOrdenadaJugadores[indicador][i]][idxCant]);
                            let partidos = valorNumero(infoEstadisticas[infoOrdenadaJugadores[indicador][i]][4]);

                            if (partidos > 0 && cantidad > 0) {
                                if (!hayDatos) {
                                    hayDatos = true;
                                }

                                datos[0] = infoEstadisticas[infoOrdenadaJugadores[indicador][i]][1];

                                datos[1] = infoEstadisticas[infoOrdenadaJugadores[indicador][i]][2]; //Nombre Jugador
                                datos[2] = infoEstadisticas[infoOrdenadaJugadores[indicador][i]][3]; //Nombre Equipo
                                datos[3] = "" + cantidad; //Cantidad

                                datos[4] = "" + partidos; //Partidos Jugados
                                let promedio = 0;
                                if (partidos > 0)
                                    promedio = cantidad / partidos;
                                if (esporcentaje) datos[5] = "";
                                else datos[5] = formatearDecimal(promedio);

                                datos[6] = infoEstadisticas[infoOrdenadaJugadores[indicador][i]][18]; //foto

                                if(datos[6]==="") datos[6]=ic_jugadorsinimagen;
                                else datos[6] = ruta + datos[6];
                                datos[7]=i+1; 

                                datos[8] = infoEstadisticas[infoOrdenadaJugadores[indicador][i]][19]; //idequipo

                                matrizDatos.push(datos);
                            
                                contRegistros++;
                                if (contRegistros === 100) i = registros + 1;
                            }
                        }
        
                        
                    }
                    
                }catch(err){
                    presentarError("",nomFuncion,"",err);
                }
        
                
                
            }     
            
            //setDatosFinales(matrizDatos); 

            datosFinales=matrizDatos;
        }

        const incluirRegistro = (registro, indice) => {

            return(
                <DinamicoRankingJugadoresDet key={indice} idTorneo={idTorneo} registro={registro}  tipoRanking={"jugadores"} modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />            
            );
    
        }; 

        
        if(indicador!=null && indicador>=0) llenarDatos();
        else return null;

        
        return(
            <div className='CT_regSancion BF_bordeTotal' style={{padding: "6px",background:C_uniformeBlanco, minWidth:"280px", maxWidth:"500px"}}>
                    <div className="CT_contenedorTitulo" style={{paddingLeft:"10px", paddingTop:"4px", paddingBottom:"4px", cursor:"pointer"}}>                    
                        <img className='IM_imgIcono24' src={imagen} alt='' />   
                        <span style={{flex:"1", marginLeft: "10px", marginRight:"10px", textAlign:"left", fontSize:"14px", fontWeight:"bold"}}>{titulo}</span>    
                            
                    </div>
    
                    <div className='BF_cajaGris' style={{marginLeft:"2px", marginRight:"2px", marginBottom:"4px"}}>
                            <p className='TX_tituloCaja'>{subtitulo}</p>
                            <div className='BF_cajaBlanca'>
                                <table width={"100%"} border="0" style={{textAlign:"center"}}><tbody>                                
                                
                                {datosFinales.length>0 && (
                                <tr  style={{alignItems:"center", fontSize:"12px", color:C_uniformeGrisOscuro, padding:"2px"}}>
                                    <td style={{fontWeight:"bold"}}>#</td>
                                    <td width="36px"></td>
                                    <td>Jugador</td>
                                    <td>Cant</td>
                                    <td>PJ</td>
                                    <td>Prom</td>                                
                                </tr>                        
                                )}

                                {datosFinales.length>0 && (
                                    <tr><td colSpan={6} align="center"><div className='BF_lineaInferior' ></div></td></tr>
                                )}


                                {datosFinales.length>0 ? 
                                (datosFinales.map((el, index) => (incluirRegistro(el, index) ))) 
                                : 
                                (<tr><td><p className='TX_sinInfo'>No hay información de este indicador</p></td></tr>)}
                                    
                                </tbody></table>
                            </div>
                    </div>  
                    
                    
                    
                </div>

        );

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

};


export default DinamicoRankingIndicadores;