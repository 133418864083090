import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png';
import ic_jugador from '../recGraficos/ic_jugador.png';
import ic_silbato from '../recGraficos/ic_silbato.png';
import ic_gestion_recursos from '../recGraficos/ic_gestion_recursos.png';
import ic_aceptar from '../recGraficos/ic_aceptar.png';
import ic_aceptar_gris from '../recGraficos/ic_aceptar_gris.png';
import ic_cancelar from '../recGraficos/ic_cancelar.png';
import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png';
import { C_colorPrimary, C_uniformeRojo } from '../constantes/generales';


let txtAceptar="";
let txtRechazar="";

const Template_lista_roles = ({ datos, indice, handleClick, tipoLista }) => {

    if(!datos) return null;


    if(tipoLista===null || (tipoLista!==null && tipoLista==="ROLES")){
        txtAceptar="ACEPTAR";
        txtRechazar="RECHAZAR";
    }
    else if(tipoLista!==null && tipoLista==="NEW_ROLES"){
        txtAceptar="ACEPTAR_NR";
        txtRechazar="RECHAZAR_NR";
    }

    let imagen=ic_jugador;
    let texto1="Jugador";
    if(datos.idrol===2) {
        imagen=ic_gestion_recursos;
        texto1="Administrador";
    }
    else if(datos.idrol===3) {
        imagen=ic_silbato;
        texto1="Arbitro";
    }

    let imgAcepto=ic_aceptar;
    let imgRechazo=ic_cancelar_gris;
    let textoAcepto="Acepto";
    let textoRechazo="Rechazo";

    if(datos.aceptado===true){
        imgAcepto=ic_aceptar;
        imgRechazo=ic_cancelar_gris;
        textoAcepto="Acepto";
        textoRechazo="";
    }
    else{
        imgAcepto=ic_aceptar_gris;
        imgRechazo=ic_cancelar;
        textoAcepto="";
        textoRechazo="Rechazo";
    }

    const clickRegistro = (donde) => {
        handleClick(indice, donde);
    }

    const noHacerNada = () => {}

    return (
        <>
        <tr>
            <td><img className='IM_imgIcono24' src={imagen} alt="" style={{marginRight:"10px"}}/></td>
            <td> 
                <div style={{textAlign:"left"}}> 
                    <div className="TX_exito" style={{margin:"0px", padding:"0px", fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>{texto1}</div>
                    <div className="TX_letraPeq">{datos.nombreUser}</div>
                    <div className="TX_letraPeq">{"Organizador: " + datos.nombreEmpresa}</div>
                </div>
            </td>
            {}
            <td>
                <div style={{display:"block", textAlign:"center", padding:"3px", cursor:"pointer"}} onClick={() => {clickRegistro(txtAceptar)}}> 
                    <div style={{display:"flex", justifyContent:"center"}}><img className='IM_imgIcono24' src={imgAcepto} alt="" /></div>
                    <div className='TX_letraMuyPeq' style={{color:C_colorPrimary, marginTop:"-2px"}}>{textoAcepto}</div>
                </div>
            </td>
            <td>
                <div style={{display:"block", textAlign:"center", padding:"3px", cursor:"pointer"}} onClick={() => {clickRegistro(txtRechazar)}}> 
                    <div style={{display:"flex", justifyContent:"center"}}><img className='IM_imgIcono24' src={imgRechazo} alt="" /></div>
                    <div className='TX_letraMuyPeq' style={{color:"red", marginTop:"-2px"}}>{textoRechazo}</div>
                </div>
            </td>
        </tr>
        <tr><td colSpan={4} align="center"><div className="BF_lineaInferior" style={{margin:"5px"}}></div></td></tr>
        </>
    );
  };
  
  export default Template_lista_roles;