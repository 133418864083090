import React from "react";
import "../estilos/Loader.css";

const Loader = ({tipo="PARCIAL"}) => {
  
  return (
    <>
    {tipo==="PARCIAL" && (
      <>
        <div className="contenedorLoader"></div>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </>
    )}

    {tipo==="SMALL" && (
      <>
        <div className="spin a"></div>
      </>
    )}
    
    {tipo==="TOTAL" && (
      <>
        <div className="loading">
          <div className="spin"></div>
        </div>
      </>
    )}
    </>
  );
};

export default Loader;
