import { useEffect, useState } from 'react';
import { getDatos } from '../gestionDatos/getDatos';

import {   valorFloat, formatearPorcentaje, dividirLista, formatearDecimal, recuperarDatoLocal, valorLong, guardarDatoLocal, isFlagUpdate, superaTiempoActualizacion, infoGuardadaEsMismoDia, presentarLog } from "../helpers/funciones";

import {registrarLogEventos, reportarError, presentarError, crearTabla, valorNumero} from '../helpers/funciones';

import Loader from '../recVisual/Loader';

import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/TX_Textos.css";
import "../estilos/BF_BordesFondos.css";
import "../estilos/IP_Inputs.css";

import ic_uniforme_claro from '../recGraficos/ic_uniforme_claro.png';
import ic_reloj from '../recGraficos/ic_reloj.png';
import ic_salir from '../recGraficos/ic_salir_w.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';
import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_disparo_arco from '../recGraficos/ic_disparo_arco.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_falta_cometida from '../recGraficos/ic_falta_cometida.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_equipo from '../recGraficos/ic_equipo.png';

import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_asistencia from '../recGraficos/ic_asistencia.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_porcentajetiempo from '../recGraficos/ic_porcentajetiempo.png';
import ic_falta_recibida from '../recGraficos/ic_falta_recibida.png';

import ic_fase_grupos from '../recGraficos/ic_fase_grupos.png';
import ic_fase_eliminacion from '../recGraficos/ic_fase_eliminacion.png';
import ic_comparar from '../recGraficos/ic_comparar.png';
import ic_ranking from '../recGraficos/ic_ranking.png';


import ShowError from '../recVisual/ShowError';
import DinamicoPartidosProgramados from '../gestionVisual/DinamicoPartidosProgramados';
import DinamicoResumenBarra from '../gestionVisual/DinamicoResumenBarra';
import DinamicoResumenDisparos from '../gestionVisual/DinamicoResumenDisparos';
import DinamicoResumenEfectividad from '../gestionVisual/DinamicoResumenEfectividad';
import DinamicoRankingIndicador from '../gestionVisual/DinamicoRankingIndicador';
import DinamicoDatosJugador from '../gestionVisual/DinamicoDatosJugador';
import DinamicoSancionesJugador from '../gestionVisual/DinamicoSancionesJugador';
import DP_InfoEstadisticasJugador from '../gestionVisual/DP_InfoEstadisticasJugador';
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary, C_colorPrimaryDark, ID_MODULO_DETALLE_JUGADOR, ID_MODULO_DETALLE_TORNEO, C_uniformeNegro, SEPARADOR_PARAMETROS_BD } from '../constantes/generales';



const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Detalle_Jugador_WB";
const idModulo=ID_MODULO_DETALLE_JUGADOR;
const subModulo="DJJ";

let idJugador="";
//let idSede="";
let idTorneo="";
let idEquipo="";

let paramsAjustado="";
//let paramsBase="";

let nombreJug="";
let fotoJug=ic_jugadorsinimagen;
let edadJug="";
let estaturaJug="";
let pesoJug="";
let posicionJug="";
let camisetaJug="";

let nombreEq="";
let escudoEq=ic_escudo_base_gris;
let nombreTorneo="";
//let tipo_torneo="1";
let tipo_registroinfo="1";
//let escudoTorneo=ic_torneo;

let cantidadJug=-1;

let tieneTarjetaAzul=false;

let yaConsultoInfoJugadores=false;


/*let columnasASumar=[6,7,8,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
let columnasOrdenar=[0,1,1,0,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,1,1];
let columnasOrdenarJug=[5,6,7,8,9,10,11,12,13,14,15,16,17];
*/

let columnasOrdenar=[3,4,5,6,7,8,9,10,11,12,13,14,15,16];
let infoOrdenadaJugadores = [];
let columnasSuma = [];
let columnasAvg = [];
let torneos=[];


var infoJugadorO = [];
var infoJugador = [];
function crearInfoJugador(datos){   
    const nomFuncion="crearInfoJugador";

    try{
        let jugador = {
            idjugador:"",
            nombre:"",
            edad:"",
            fechanacimiento:"",
            estatura:"",
            peso:"",
            foto:"",
            idsede:"",  
            idtorneo:"",
            idequipo:"",
            idjugador1:"",
            pjugados:"",
            titular:"",
            goles:"",
            golesjugada:"",
            golestirolibre:"",
            golespenalti:"",
            autogoles:"",
            asistencias:"",
            atajadas:"",
            disparosarco:"",
            disparosdentro:"",
            faltasrecibidas:"",
            faltascometidas:"",
            tarjetasamarillas:"",
            tarjetasrojas:"",
            tiempojugadot1:"",
            tiempojugadot2:"",
            jugadototal:"",
            totaljugadot1:"",
            totaljugadot2:"",
            jugadograntotal:"",
            totalpartidos:"",
            tarjetasazules:""
        }

        
    
        infoJugadorO = [];
        infoJugador = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.nombre=datos[i][j];
                if(j===2) registro.edad=datos[i][j];
                if(j===3) registro.fechanacimiento=datos[i][j];
                if(j===4) registro.estatura=datos[i][j];
                if(j===5) registro.peso=datos[i][j];
                if(j===6) registro.foto=datos[i][j];
                if(j===7) registro.idsede  =datos[i][j];
                if(j===8) registro.idtorneo=datos[i][j];
                if(j===9) registro.idequipo=datos[i][j];
                if(j===10) registro.idjugador1=datos[i][j];
                if(j===11) registro.pjugados=datos[i][j];
                if(j===12) registro.titular=datos[i][j];
                if(j===13) registro.goles=datos[i][j];
                if(j===14) registro.golesjugada=datos[i][j];
                if(j===15) registro.golestirolibre=datos[i][j];
                if(j===16) registro.golespenalti=datos[i][j];
                if(j===17) registro.autogoles=datos[i][j];
                if(j===18) registro.asistencias=datos[i][j];
                if(j===19) registro.atajadas=datos[i][j];
                if(j===20) registro.disparosarco=datos[i][j];
                if(j===21) registro.disparosdentro=datos[i][j];
                if(j===22) registro.faltasrecibidas=datos[i][j];
                if(j===23) registro.faltascometidas=datos[i][j];
                if(j===24) registro.tarjetasamarillas=datos[i][j];
                if(j===25) registro.tarjetasrojas=datos[i][j];
                if(j===26) registro.tiempojugadot1=datos[i][j];
                if(j===27) registro.tiempojugadot2=datos[i][j];
                if(j===28) registro.jugadototal=datos[i][j];
                if(j===29) registro.totaljugadot1=datos[i][j];
                if(j===30) registro.totaljugadot2=datos[i][j];
                if(j===31) registro.jugadograntotal=datos[i][j];
                if(j===32) registro.totalpartidos=datos[i][j];
                if(j===33) registro.tarjetasazules=datos[i][j];
            }
            infoJugadorO.push(registro);
            infoJugador.push(datos[i]);

            if(i===0){
                //idSede=registro.idsede;
                nombreJug=registro.nombre;
                if(registro.foto!=="") fotoJug=ruta + registro.foto;
                else fotoJug=ic_jugadorsinimagen;
                edadJug=registro.edad;
                estaturaJug=registro.estatura;
                pesoJug=registro.peso;
            }
        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }      

}



var infoEquiposO = [];
var infoEquipos = [];
function crearInfoEquipos(datos){    
    const nomFuncion="crearInfoEquipo";

    try{        
        let equipo = {
            idequipo:"",
            nombrelargo:"",
            nombrecorto:"",
            idtorneo:"",
            nomTorneo:"",
            posicion:"",
            camiseta:"",
            tipo_torneo:"",
            tipo_registroinfo:"",
            escudoEq:"",
            tiene_tarjeta_azul:""
        }
    
        infoEquiposO = [];
        infoEquipos = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , equipo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idequipo=datos[i][j];
                if(j===1) registro.nombrelargo=datos[i][j];
                if(j===2) registro.nombrecorto=datos[i][j];
                if(j===3) registro.idtorneo=datos[i][j];
                if(j===4) registro.nomTorneo=datos[i][j];
                if(j===5) registro.posicion=datos[i][j];
                if(j===6) registro.camiseta=datos[i][j];
                if(j===7) registro.tipo_torneo=datos[i][j];
                if(j===8) registro.tipo_registroinfo=datos[i][j];
                if(j===9) registro.escudoEq=datos[i][j];
                if(j===10) registro.tiene_tarjeta_azul=datos[i][j];
            }
            infoEquiposO.push(registro);
            infoEquipos.push(datos[i]);

            if(i===0){                
                camisetaJug=registro.camiseta;
                posicionJug=registro.posicion;
                //tipo_torneo=registro.tipo_torneo;
                tipo_registroinfo=registro.tipo_registroinfo;

                nombreEq=registro.nombrelargo;
                nombreTorneo=registro.nomTorneo;
                if(registro.escudoEq!=="") escudoEq= ruta + registro.escudoEq;
                else escudoEq=ic_escudo_base_gris;  
                if(registro.tiene_tarjeta_azul==="1") tieneTarjetaAzul=true;
                else tieneTarjetaAzul=false; 
            }
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let partido = {
    idTorneo:"",
    nomTorneo:"",
    idPartido:"",
    numJornada:"",
    numCancha:"",
    idEquipo1:"",
    nomCortoEq1:"",
    imgEscudoEq1:"",
    idEquipo2:"",
    nomCortoEq2:"",
    imgEscudoEq2:"",
    nomArbitro1:"",
    nomArbitro2:"",
    fechaPartido:"",
    horaPartido:"",
    golesEq1:"",
    golesEq2:"",
    parte:"",
    tiempoActual:"",
    tiempoTotal:"",
    idEstado:"",
    nomEstado:"",
    fechaActual:"",
    horaActual:"",
    idJornada:"",
    estadoTiempo:"",
    fechaInicioTiempo:"",
    confirmoAsistencia:"",
    fechaConfirmo:"",
    tipoTorneo:"",
    tipoRegistroInfo:"",
    partidoActivo:"",
    idSede:"",
    idEstadoInfArbitro:"",
    imgArbitro1:"",
    calificacionPartido:"",
    penaltisEq1:"",
    penaltisEq2:"",
    tipoJornada:"",
    nomJornada:"",
    semFechainicial:"",
    semFechaFinal:"",
    esSemanaActual:"",
    resumenJornada:"",
    tipoJornada2:"",
    nomJornada2:"",
    idsemana:"",
    numsemana:"",
    semFechainicial2:"",
    semFechaFinal2:"",
    tipoJornadaEliminacion:"",
    idcopa:"",
    nombreCopa:"",
    ordenCopa:""
}
var infoPartidosO = [];
var infoPartidos = [];
function crearInfoPartidos(datos){   
    const nomFuncion="crearInfoPartido";

    try{       
    
        infoPartidosO = [];
        infoPartidos = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , partido);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idTorneo=datos[i][j];
                if(j===1) registro.nomTorneo=datos[i][j];
                if(j===2) registro.idPartido=datos[i][j];
                if(j===3) registro.numJornada=datos[i][j];
                if(j===4) registro.numCancha=datos[i][j];
                if(j===5) registro.idEquipo1=datos[i][j];
                if(j===6) registro.nomCortoEq1=datos[i][j];
                if(j===7) registro.imgEscudoEq1=datos[i][j];
                if(j===8) registro.idEquipo2=datos[i][j];
                if(j===9) registro.nomCortoEq2=datos[i][j];
                if(j===10) registro.imgEscudoEq2=datos[i][j];
                if(j===11) registro.nomArbitro1=datos[i][j];
                if(j===12) registro.nomArbitro2=datos[i][j];
                if(j===13) registro.fechaPartido=datos[i][j];
                if(j===14) registro.horaPartido=datos[i][j];
                if(j===15) registro.golesEq1=datos[i][j];
                if(j===16) registro.golesEq2=datos[i][j];
                if(j===17) registro.parte=datos[i][j];
                if(j===18) registro.tiempoActual=datos[i][j];
                if(j===19) registro.tiempoTotal=datos[i][j];
                if(j===20) registro.idEstado=datos[i][j];
                if(j===21) registro.nomEstado=datos[i][j];
                if(j===22) registro.fechaActual=datos[i][j];
                if(j===23) registro.horaActual=datos[i][j];
                if(j===24) registro.idJornada=datos[i][j];
                if(j===25) registro.estadoTiempo=datos[i][j];
                if(j===26) registro.fechaInicioTiempo=datos[i][j];
                if(j===27) registro.confirmoAsistencia=datos[i][j];
                if(j===28) registro.fechaConfirmo=datos[i][j];
                if(j===29) registro.tipoTorneo=datos[i][j];
                if(j===30) registro.tipoRegistroInfo=datos[i][j];
                if(j===31) registro.partidoActivo=datos[i][j];
                if(j===32) registro.idSede=datos[i][j];
                if(j===33) registro.idEstadoInfArbitro=datos[i][j];
                if(j===34) registro.imgArbitro1=datos[i][j];
                if(j===35) registro.calificacionPartido=datos[i][j];
                if(j===36) registro.penaltisEq1=datos[i][j];
                if(j===37) registro.penaltisEq2=datos[i][j];
                if(j===38) registro.tipoJornada=datos[i][j];
                if(j===39) registro.nomJornada=datos[i][j];
                if(j===40) registro.semFechainicial=datos[i][j];
                if(j===41) registro.semFechaFinal=datos[i][j];
                if(j===42) registro.esSemanaActual=datos[i][j];
                if(j===43) registro.resumenJornada=datos[i][j];
                if(j===44) registro.tipoJornada2=datos[i][j];
                if(j===45) registro.nomJornada2=datos[i][j];
                if(j===46) registro.idsemana=datos[i][j];
                if(j===47) registro.numsemana=datos[i][j];
                if(j===48) registro.semFechainicial2=datos[i][j];
                if(j===49) registro.semFechaFinal2=datos[i][j];
                if(j===50) registro.tipoJornadaEliminacion=datos[i][j];
                if(j===51) registro.idcopa=datos[i][j];
                if(j===52) registro.nombreCopa=datos[i][j];
                if(j===53) registro.ordenCopa=datos[i][j];
            }
            infoPartidosO.push(registro);
            infoPartidos.push(datos[i]);
         
        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}



var infoEstadisticasO = [];
var infoEstadisticas = [];
function crearInfoEstadisticas(datos){    
    const nomFuncion="crearInfoEstadisticas";

    try{
        let estadistica = {
            idtorneo:"",
            idjugador:"",
            pjugados:"",
            goles:"",
            autogoles:"",
            asistencias:"",
            atajadas:"", 
            disparosarco:"",
            disparosdentro:"",
            faltasrecibidas:"",
            faltascometidas:"",
            tarjetasamarillas:"", 
            tarjetasrojas:"",
            jugadototal:"",
            participacionGoles:"",
            efectividadDisparo:"",
            tarjetasazules:""
        }
    
        infoEstadisticasO = [];
        infoEstadisticas = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , estadistica);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idjugador=datos[i][j];
                if(j===2) registro.pjugados=datos[i][j];
                if(j===3) registro.goles=datos[i][j];
                if(j===4) registro.autogoles=datos[i][j];
                if(j===5) registro.asistencias=datos[i][j];
                if(j===6) registro.atajadas=datos[i][j];
                if(j===7) registro.disparosarco=datos[i][j];
                if(j===8) registro.disparosdentro=datos[i][j];
                if(j===9) registro.faltasrecibidas=datos[i][j];
                if(j===10) registro.faltascometidas=datos[i][j];
                if(j===11) registro.tarjetasamarillas =datos[i][j];
                if(j===12) registro.tarjetasrojas=datos[i][j];
                if(j===13) registro.jugadototal=datos[i][j];
                if(j===14) registro.participacionGoles=datos[i][j];
                if(j===15) registro.efectividadDisparo=datos[i][j];
                if(j===16) registro.tarjetasazules=datos[i][j];
            }
            infoEstadisticasO.push(registro);
            infoEstadisticas.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    } 

    
}

var infoSancionesO = [];
var infoSanciones = [];
function crearInfoSanciones(datos){    
    const nomFuncion="crearInfoSanciones";

    try{        
        let sancion = {
            idtorneo:"",
            idpartido:"",
            tipoSancion:"",
            nomSancion:"",
            totalpartidos:"",
            valSancion:"",
            txtsancion:"",
            fecha_sancion:"",
            escudoEq1:"",
            nomEquipo1:"",
            escudoEq2:"",
            nomEquipo2:"",
            jornada:"",
            fechaPartido:"",
            horaPartido:""
        }
    
        infoSancionesO = [];
        infoSanciones = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , sancion);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idpartido=datos[i][j];
                if(j===2) registro.tipoSancion=datos[i][j];
                if(j===3) registro.nomSancion=datos[i][j];
                if(j===4) registro.totalpartidos=datos[i][j];
                if(j===5) registro.valSancion=datos[i][j];
                if(j===6) registro.txtsancion=datos[i][j];
                if(j===7) registro.fecha_sancion=datos[i][j];
                if(j===8) registro.escudoEq1=datos[i][j];
                if(j===9) registro.nomEquipo1=datos[i][j];
                if(j===10) registro.escudoEq2=datos[i][j];
                if(j===11) registro.nomEquipo2=datos[i][j];
                if(j===12) registro.jornada=datos[i][j];
                if(j===13) registro.fechaPartido=datos[i][j];
                if(j===14) registro.horaPartido=datos[i][j];
            }
            infoSancionesO.push(registro);
            infoSanciones.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


function ordenarInfomacionJugadores(){
    const nomFuncion="ordenarInfomacionJugadores";

    try{
        let i,j;
        let suma=0;
        let contTieneInfo=0;
        let promedio=0;

        infoOrdenadaJugadores=[];
        columnasSuma=[];
        columnasAvg=[];

        if(infoEstadisticas.length>0) {
            let id = "";
            let cont = 1;
            let tempTorneos=[infoEstadisticas[0][0]];
            
            if (cont > 0) {
                torneos = tempTorneos;

                let columnasSumaTemp = dividirLista("","|",columnasOrdenar.length);
                let columnasAvgTemp = dividirLista("","|",columnasOrdenar.length);

                for (i = 0; i < columnasOrdenar.length; i++) {
                    let col = columnasOrdenar[i];
                    let datosTemp = dividirLista("","|",infoEstadisticas.length);
                    suma = 0;
                    promedio = 0;
                    id = "";
                    let contTorneos = 0;
                    let contEquipos = 0;
                    contTieneInfo=0;
                    for (j = 0; j < infoEstadisticas.length; j++) {
                        id = infoEstadisticas[j][0];
                        if (!id===(torneos[contTorneos])) {
                            if (contEquipos > 0) {
                                promedio = (suma / contTieneInfo);
                            }
                            else promedio = 0;
                            columnasSumaTemp[i] = suma;
                            columnasAvgTemp[i] = promedio;
                            suma = 0;
                            promedio = 0;
                            contTorneos++;
                            contEquipos = 0;
                            contTieneInfo=0;
                        }

                        datosTemp[j] = valorNumero(infoEstadisticas[j][col]);
                        suma = suma + datosTemp[j];
                        contEquipos++;
                        if(datosTemp[j]>0) contTieneInfo++;
                    }
                 
                    if (contTieneInfo > 0) promedio = (suma / contTieneInfo);
                    else promedio = 0;
                    columnasSumaTemp[i] = suma;
                    columnasAvgTemp[i] = promedio;


                    let datosTemp2 = organizarListaJugadores(datosTemp);

                    infoOrdenadaJugadores.push(datosTemp2);  

                }

                columnasSuma.push(columnasSumaTemp);
                columnasAvg.push(columnasAvgTemp);
            }
        }


    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

}

function organizarListaJugadores(list){
    const nomFuncion="organizarListaJugadores";
    
    let posiciones=[];

    try{
        let i,j,k;
        for(k=0;k<list.length;k++){
            posiciones.push(k);
        }

        for(i =0; i< list.length; i++){
            let sorted = true; // asumo que para la iteración i el listado es ordenado,
            for(j =0; j< list.length - i - 1; j++){ // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición
                let idt1=valorNumero(infoEstadisticas[j][0]);
                let idt2=valorNumero(infoEstadisticas[j+1][0]);
                if(idt1>idt2){
                    let temp = list[j];
                    list[j] = list[j+1];
                    list[j+1] = temp;
                    sorted = false;

                    temp=posiciones[j];
                    posiciones[j]=posiciones[j+1];
                    posiciones[j+1]=temp;
                }
                else if(idt1===idt2){
                    if(list[j] < list[j+1]){
                        let temp = list[j];
                        list[j] = list[j+1];
                        list[j+1] = temp;
                        sorted = false;

                        temp=posiciones[j];
                        posiciones[j]=posiciones[j+1];
                        posiciones[j+1]=temp;
                    }
                    else if(list[j] === list[j+1]){
                        let partJugados=valorNumero(infoEstadisticas[posiciones[j]][2]);
                        let partJugados2=valorNumero(infoEstadisticas[posiciones[j+1]][2]);
                        let tempNum=0;
                        let tempNum2=0;
                        if(partJugados>0) tempNum = (list[j] / partJugados);
                        if(partJugados2>0) tempNum2 = (list[j + 1] / partJugados2);

                        if(tempNum<tempNum2){
                            let temp = list[j];
                            list[j] = list[j+1];
                            list[j+1] = temp;
                            sorted = false;

                            temp=posiciones[j];
                            posiciones[j]=posiciones[j+1];
                            posiciones[j+1]=temp;
                        }
                        else if(tempNum===tempNum2){
                            if(partJugados<partJugados2){
                                let temp = list[j];
                                list[j] = list[j+1];
                                list[j+1] = temp;
                                sorted = false;

                                temp=posiciones[j];
                                posiciones[j]=posiciones[j+1];
                                posiciones[j+1]=temp;
                            }
                        }

                    }
                }


            }
            if(sorted){
                return posiciones;
            }
        }

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    return posiciones;
}


const Detalle_Jugador = ({params="", modOrigen, paramOrigen, definirModulo}) => {
    const [esError, setEsError] = useState(0);
    //const [data, setData] = useState(false);
    const [pestana, setPestana] = useState("1");
    const [loading, setLoading] = useState(false);
    //const [indicadorSel, setIndicadorSel] = useState(1);

    const [listaPestanaJug, setListaPestanaJug] = useState(false);


    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{

            yaConsultoInfoJugadores=false;
            let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);

            paramsAjustado=params;

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,"#",5);
                let ultParametrosTotal=ultParametros;
                let datosParametrosGuardados=dividirLista(ultParametrosTotal,"#",3);
                idJugador=datosParametrosGuardados[0];
                idEquipo=datosParametrosGuardados[1];
                idTorneo=datosParametrosGuardados[2];

                if(idJugador==="" || idJugador!==info[0]){
                    actualizar=true;                    
                    idJugador=info[0];
                }  
                if(idEquipo==="" || idEquipo!==info[2]){
                    actualizar=true;
                    idEquipo=info[2];
                }
                if(idTorneo==="" || idTorneo!==info[3]){
                    actualizar=true;
                    idTorneo=info[3];
                }

                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                }

                guardarDatoLocal("ultParametros" + idModulo,idJugador + "#" + idEquipo + "#" + idTorneo);

                if(info[1]!=="" && info[1]!==pestana){
                    paramsAjustado=info[0] + "#" + info[1] + "#" + info[2] + "#" + info[3];
                    setPestana(info[1]); 
                }  
                else {
                    paramsAjustado=info[0] + "#" + "1" + "#" + info[2] + "#" + info[3];
                    setPestana("1"); 
                }

                //paramsBase=paramsAjustado;

                //actualizar=true;
                if(actualizar){
                    BD_solicitarDatos("INFO_INICIAL");    
                }
                else{
                    cargarDatosGuardadosModulo();
                }
                
            }
            

        }catch(err){
            let msj="params: " + params + " modOrigen: " + modOrigen + " paramOrigen: " + paramOrigen + " idJugador: " + idJugador + " idEquipo: " + idEquipo + " idTorneo: " + idTorneo;
            reportarError( nomClase, nomFuncion, msj, err);
        }        

    }, [params]);

    if(params==="") return null;

    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getInfoJugadores";
                    consulta="INFO_DETALLE_JUGADOR";
                    
                    paramQuery= idJugador + SEP + idEquipo + SEP + idTorneo;

                } else if(tipoDatos==="INFO_DETALLE_JUGADOR_JUGADORES"){
                    tipoConsulta="consultarTabla";
                    funcion="getInfoJugadores";
                    consulta="INFO_DETALLE_JUGADOR_JUGADORES";
                    
                    paramQuery= idTorneo;
                } 
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
    
                                guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                guardarDatoLocal("datos" + idModulo, respuesta.datos);
    
                                crearInfoJugador(crearTabla(respuesta.datos,"1"));
                                crearInfoEquipos(crearTabla(respuesta.datos,"2"));
                                crearInfoPartidos(crearTabla(respuesta.datos,"3")); 
                                crearInfoEstadisticas(crearTabla(respuesta.datos,"4"));
                                crearInfoSanciones(crearTabla(respuesta.datos,"5"));
    
    
                                if(infoEstadisticas.length>0) ordenarInfomacionJugadores();
                            
                                if(pestana===""){
                                    setPestana("1");
                                }                            
        
                                registrarLogEventos(idModulo, "1", idJugador,idEquipo);

                            } else if(tipoDatos==="INFO_DETALLE_JUGADOR_JUGADORES"){
                                presentarLog(nomClase + " - SOLICITA_DATOS " + subModulo); 

                                guardarDatoLocal("ultParametros" + idModulo + subModulo, idTorneo);
                                guardarDatoLocal("ultAcceso" + idModulo + subModulo,Date.now());
                                guardarDatoLocal("datos" + idModulo  + subModulo, respuesta.datos);

                                crearInfoEstadisticas(crearTabla(respuesta.datos,"4"));

                                if(infoEstadisticas.length>0) ordenarInfomacionJugadores();

                                setListaPestanaJug(true);
                                yaConsultoInfoJugadores=true;

                                registrarLogEventos(idModulo, "14", idTorneo,"" );
                            }       
                                                       
            
                        } else {
                            if(mostrarError){
                                if(respuesta.conectado){
                                    reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                    setEsError(1);
                                } 
                                else{    
                                    presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                    setEsError(2);
                                } 
                            }                        
                        } 

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const cargarDatosGuardadosModulo = () => {
        let nomFuncion="cargarDatosGuardadosModulo"; 

        try{       
            
            if(pestana==="") setPestana("1");

            presentarLog(nomClase + " - RECUPERA_DATOS"); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo);

            if(datosModulo!==""){
                crearInfoJugador(crearTabla(datosModulo,"1"));
                crearInfoEquipos(crearTabla(datosModulo,"2"));
                crearInfoPartidos(crearTabla(datosModulo,"3")); 
                crearInfoEstadisticas(crearTabla(datosModulo,"4"));
                crearInfoSanciones(crearTabla(datosModulo,"5"));

                if(infoEstadisticas.length>0) ordenarInfomacionJugadores();          
            }  
            else BD_solicitarDatos("INFO_INICIAL",[]);
                    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }

    const cargarDatosGuardadosDTJModulo = () => {
        let nomFuncion="cargarDatosGuardadosDTJModulo"; 

        try{             
            presentarLog(nomClase + " - RECUPERA_DATOS " + subModulo); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo + subModulo);

            if(datosModulo!==""){
                crearInfoEstadisticas(crearTabla(datosModulo,"4"));

                if(infoEstadisticas.length>0) ordenarInfomacionJugadores();

                setListaPestanaJug(true);
                yaConsultoInfoJugadores=true;                 
            }     
            else BD_solicitarDatos("INFO_DETALLE_JUGADOR_JUGADORES");

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }

    

    function presentarPestana(){ 
        let nomFuncion="presentarPestana";       
        
        try{

            if((pestana==="1")) {
                nomFuncion="presentarPestana.1";                 
                
                return(
                    <div  style={{maxWidth:"800px"}}>
                        {incluirTorneo()}    

                        <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"600px", marginTop:"20px", marginBottom:"20px"}}>
                            <p className='TX_tituloCaja'>{"Datos Principales"}</p>
                            <div className='BF_cajaBlanca'>                            
                                <DinamicoDatosJugador infoJugador={infoJugadorO[0]}  posicion={posicionJug} tipo_registroinfo={tipo_registroinfo} />            
                        </div>
                        </div> 

                        <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"600px", marginTop:"20px", marginBottom:"20px"}}>
                            <p className='TX_tituloCaja'>{"Partidos del Jugador"}</p>
                            <div className='BF_cajaBlanca'>                                     
                                {incluirInfoPartidos()}                                           
                            </div>
                        </div>  

                                                        
                        {infoSancionesO.length>0 && 
                        (
                            <>
                                <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"600px", marginTop:"20px", marginBottom:"20px"}}>
                                    <p className='TX_tituloCaja'>{"Sanciones Activas"}</p>
                                    <div className='BF_cajaBlanca'>   
                                        <div>
                                            <table border={0} width="100%">
                                                <tbody>
                                                    <tr className='TX_letraPeq'>
                                                        <td style={{width: "36px"}}></td>
                                                        <td>{"Tipo Sanción"}</td>
                                                        <td style={{width: "50px", minWidth:"50px",}}>{"Partidos"}</td>
                                                        <td style={{width: "50px", minWidth:"50px",}}>{"Pagada"}</td>
                                                        <td style={{width: "24px"}}></td>
                                                    </tr>                                        
                                                </tbody>
                                            </table>
                                        </div>
                                        {infoSancionesO.map((reg, index) => (<div key={index}><DinamicoSancionesJugador datos={reg} tipoLista={"sanciones_jugador"} conLinea={false} /></div>))}
                                    </div> 
                                </div>
                            </>)
                    
                        }  

                    </div>                    
            
                );
            }  
            else if((pestana==="2")) {
                nomFuncion="presentarPestana.2";
            
                return(
                <div  style={{maxWidth:"1800px"}}>                
                    {incluirTorneo()}                 

                    <div  style={{marginTop:"20px"}}>
                        <DP_InfoEstadisticasJugador idTorneo={idTorneo} idJug={idJugador} infoJugadores={infoJugador} tipo_registroinfo={tipo_registroinfo} tipoReporte={"equipo"} tieneTarjetaAzul={tieneTarjetaAzul} />
                    </div>
                </div>
                );
            }                
            else if((pestana==="3")) {
                nomFuncion="presentarPestana.3";

                if(!yaConsultoInfoJugadores){
                    let ultParametro = recuperarDatoLocal("ultParametros" + idModulo + subModulo);
                    if(ultParametro !== idTorneo) BD_solicitarDatos("INFO_DETALLE_JUGADOR_JUGADORES");  
                    else{
                        if(infoGuardadaEsMismoDia(idModulo, subModulo)) cargarDatosGuardadosDTJModulo();
                        else BD_solicitarDatos("INFO_DETALLE_JUGADOR_JUGADORES");  
                    }                              
                } 
            }                    
            else if((pestana==="4")) {      
                nomFuncion="presentarPestana.4";

                if(!yaConsultoInfoJugadores){
                    let ultParametro = recuperarDatoLocal("ultParametros" + idModulo + subModulo);
                    if(ultParametro !== idTorneo) BD_solicitarDatos("INFO_DETALLE_JUGADOR_JUGADORES");  
                    else{
                        if(infoGuardadaEsMismoDia(idModulo, subModulo)) cargarDatosGuardadosDTJModulo();
                        else BD_solicitarDatos("INFO_DETALLE_JUGADOR_JUGADORES");  
                    }             
                } 
            }  
            else return(<></>);


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
        
    }

    function presentarPestanaJug(){
        let nomFuncion="presentarPestanaJug";
        
        if((pestana==="3")) {
            nomFuncion="presentarPestana.3";

            let golesAnotados1="0", golesAnotados2="0";
            let participacionGoles1="0", participacionGoles2="0";
            let asistencias1="0", asistencias2="0";
            let disparosT1="0", disparosT2="0";
            let datosDisparos = ["0","0","0","0"];
            let datosEfectividad=dividirLista("","|",8);
            let atajadas1="0", atajadas2="0";
            let autogoles1="0", autogoles2="0";
            //let datosPorteria=dividirLista("","|",8);
            let faltasReibidas1="0", faltasReibidas2="0";
            let faltasCometidas1="0", faltasCometidas2="0";
            let tAmarillas1="0", tAmarillas2="0";
            let tRojas1="0", tRojas2="0";
            let tAzules1="0", tAzules2="0";
            //let numJugadores1="0", numJugadores2="0";
            //let numCambios1="0", numCambios2="0";

            try{               
                if(infoEstadisticas.length){
                    let i;
                    let indice=0;
                    let indice2=0;
                    let sinDatos=true;
    
                    for (i = 0; i < infoEstadisticas.length; i++) {
                        if (infoEstadisticas[i][0]===(idTorneo) && infoEstadisticas[i][1]===(idJugador)) {
                            sinDatos = false;
                            indice = i;
                            i = infoEstadisticas.length;
                        }
                    }
    
                    for (i = 0; i < torneos.length; i++) {
                        if (torneos[i]===(idTorneo)) {
                            indice2 = i;
                            i = torneos.length;
                        }
                    }
    
                        if(!sinDatos){
    
                            golesAnotados1=infoEstadisticas[indice][3];
                            golesAnotados2=formatearDecimal(columnasAvg[indice2][0]);
    
                            
    
                            if(tipo_registroinfo===("2")){
                                asistencias1=infoEstadisticas[indice][5];
                                asistencias2=formatearDecimal(columnasAvg[indice2][2]);
    
                                participacionGoles1=infoEstadisticas[indice][14];
                                participacionGoles2=formatearDecimal(columnasAvg[indice2][11]);
    
                                disparosT1=infoEstadisticas[indice][7];
                                disparosT2=formatearDecimal(columnasAvg[indice2][4]);
    
                                datosDisparos[0]="" + valorNumero(infoEstadisticas[indice][7]) - valorNumero(infoEstadisticas[indice][8]);
                                datosDisparos[1]=formatearDecimal((columnasAvg[indice2][4] - columnasAvg[indice2][5]));
                                datosDisparos[2]=infoEstadisticas[indice][8];
                                datosDisparos[3]=formatearDecimal(columnasAvg[indice2][5]);
    
                                datosEfectividad[0]="Efectividad de Disparo";
                                datosEfectividad[1]="Goles/Disparos al Arco";
                                let tempFloat1=valorFloat(infoEstadisticas[indice][3]);
                                let tempFloat2=valorFloat(infoEstadisticas[indice][8]);
                                let tempNum=0;
                                if(tempFloat2>0) tempNum=(tempFloat1/tempFloat2)*100;
                                datosEfectividad[4]=formatearPorcentaje(tempNum);
                                datosEfectividad[6]=formatearDecimal(tempFloat1) + "/" + formatearDecimal(tempFloat2);
    
                                tempFloat1=columnasAvg[indice2][0];
                                tempFloat2=columnasAvg[indice2][5];
                                tempNum=0;
                                if(tempFloat2>0) tempNum=(tempFloat1/tempFloat2)*100;
                                datosEfectividad[5]=formatearPorcentaje(tempNum);
                                datosEfectividad[7]=formatearDecimal(tempFloat1) + "/" + formatearDecimal(tempFloat2);
     
                                atajadas1=infoEstadisticas[indice][6];
                                atajadas2=formatearDecimal(columnasAvg[indice2][3]);
    
    
                            }
    
                            autogoles1=infoEstadisticas[indice][4];
                            autogoles2=formatearDecimal(columnasAvg[indice2][1]);
    
                            if(tipo_registroinfo===("2")){
                                faltasReibidas1=infoEstadisticas[indice][9];
                                faltasReibidas2=formatearDecimal(columnasAvg[indice2][6]);
    
                                faltasCometidas1=infoEstadisticas[indice][10];
                                faltasCometidas2=formatearDecimal(columnasAvg[indice2][7]);                            
                            }
    
                            tAmarillas1=infoEstadisticas[indice][11];
                            tAmarillas2=formatearDecimal(columnasAvg[indice2][8]);
    
                            tAzules1=infoEstadisticas[indice][16];
                            tAzules2=formatearDecimal(columnasAvg[indice2][13]);
    
                            tRojas1=infoEstadisticas[indice][12];
                            tRojas2=formatearDecimal(columnasAvg[indice2][9]); 
    
    
                        }
    
    
                }
            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            }
            

            
          
            return(
                <>
                    <div className="CT_contenedorTitulo" style={{marginBottom:"10px"}}>
                        <img className='IM_imgIcono36' src={ic_comparar} alt='' />   
                        <div style={{marginLeft: "10px", fontSize:"18px"}}>Jugador vs. Promedio Jugadores Torneo</div>             
                    </div>  
                    <div  style={{maxWidth:"800px"}}>  
                        {incluirTorneo()}     

                        <div className='CT_contenedorFlex'  style={{marginTop:"20px", marginBottom:"20px"}}>
                            <div>
                                <img className='IM_imgRedondeada36' src={fotoJug} alt='' />   
                                <div>{nombreJug}</div>
                            </div>
                            <div>VS</div>
                            <div>
                                <img className='IM_imgRedondeada36' src={ic_equipo} alt='' />   
                                <div>{"Promedio Torneo"}</div>
                            </div>
                        </div>    
                        
                        <DinamicoResumenBarra imagen={ic_balon_gol} titulo={"Goles Anotados"} cantidad1={golesAnotados1} cantidad2={golesAnotados2}/>     
                        
                        {tipo_registroinfo==="2" && (
                            <>
                            <DinamicoResumenBarra imagen={ic_asistencia} titulo={"Asistencias"} cantidad1={asistencias1} cantidad2={asistencias2}/>   
                            <DinamicoResumenBarra imagen={ic_asistencia} titulo={"Goles + Asistencias"} cantidad1={participacionGoles1} cantidad2={participacionGoles2}/> 
                            <DinamicoResumenBarra imagen={ic_disparo_arco} titulo={"Disparos Totales"} cantidad1={disparosT1} cantidad2={disparosT2}/>     
 
                            <DinamicoResumenDisparos tipo={2} datos={datosDisparos} />
                            <DinamicoResumenEfectividad datos={datosEfectividad} />

                            <DinamicoResumenBarra imagen={ic_atajada} titulo={"Atajadas"} cantidad1={atajadas1} cantidad2={atajadas2}/>

                            </>
                        )}

                        <DinamicoResumenBarra imagen={ic_balon_autogol} titulo={"Autogoles"} cantidad1={autogoles1} cantidad2={autogoles2}/>


                        <p width="100%" style={{fontSize:"16px", fontWeight:"bold", color:C_uniformeNegro, textAlign:"center", margin:"20px"}}>DISCIPLINA</p>

                        {tipo_registroinfo==="2" && (<>
                            <DinamicoResumenBarra imagen={ic_falta_recibida} titulo={"Faltas Recibidas"} cantidad1={faltasReibidas1} cantidad2={faltasReibidas2}/>
                            <DinamicoResumenBarra imagen={ic_falta_cometida} titulo={"Faltas Cometidas"} cantidad1={faltasCometidas1} cantidad2={faltasCometidas2}/>
                        </>)}

                        <DinamicoResumenBarra imagen={ic_amarilla} titulo={"Tarjetas Amarillas"} cantidad1={tAmarillas1} cantidad2={tAmarillas2}/>

                        {tieneTarjetaAzul===true && (<DinamicoResumenBarra imagen={ic_tarjeta_azul} titulo={"Tarjetas Azules"} cantidad1={tAzules1} cantidad2={tAzules2}/>)}

                        <DinamicoResumenBarra imagen={ic_tarjeta_roja} titulo={"Tarjetas Rojas"} cantidad1={tRojas1} cantidad2={tRojas2}/>
        

                    </div>
                </>
            
            );
        }                    
        else if((pestana==="4")) {                
            nomFuncion="presentarPestana.4";

            let golesAnotados1=0, golesAnotados2=0;
            let asistencias1=0, asistencias2=0;
            let participacionGoles1=0, participacionGoles2=0;
            let totalDisparos1=0, totalDisparos2=0;
            let disparosArco1=0, disparosArco2=0;
            let eDisparo1=0, eDisparo2=0;
            let atajadas1=0, atajadas2=0;
            //let eAtajadas1=0, eAtajadas2=0;
            let minJugados1=0, minJugados2=0;
            let faltasReibidas1=0, faltasReibidas2=0;
            let faltasCometidas1=0, faltasCometidas2=0;
            let tAmarillas1=0, tAmarillas2=0;
            let tRojas1=0, tRojas2=0;
            let tAzules1=0, tAzules2=0;
            let autogoles1=0, autogoles2=0;

            try{
                if(infoEstadisticas.length>0){                


                        cantidadJug=infoEstadisticas.length;

                        golesAnotados1=encontrarPosicionJugador(0,false,true);
                        golesAnotados2=0;
                        if(cantidadJug>0) golesAnotados2=100-(((golesAnotados1)/cantidadJug)*100);
                        
                                

                        if(tipo_registroinfo===("2")){
                            asistencias1=encontrarPosicionJugador(2,false,true);
                            asistencias2=0;
                            if(cantidadJug>0) asistencias2=100-(((asistencias1)/cantidadJug)*100);

                            participacionGoles1=encontrarPosicionJugador(11,false,true);
                            participacionGoles2=0;
                            if(cantidadJug>0) participacionGoles2=100-(((participacionGoles1)/cantidadJug)*100);
                            
                            totalDisparos1=encontrarPosicionJugador(4,false,true);
                            totalDisparos2=0;
                            if(cantidadJug>0) totalDisparos2=100-(((totalDisparos1)/cantidadJug)*100);
                        
                            disparosArco1=encontrarPosicionJugador(5,false,true);
                            disparosArco2=0;
                            if(cantidadJug>0) disparosArco2=100-(((disparosArco1)/cantidadJug)*100);
                            
                            eDisparo1=encontrarPosicionJugador(12,false,true);
                            eDisparo2=0;
                            if(cantidadJug>0) eDisparo2=100-(((eDisparo1)/cantidadJug)*100);                        

                            atajadas1=encontrarPosicionJugador(3,false,true);
                            atajadas2=0;
                            if(cantidadJug>0) atajadas2=100-(((atajadas1)/cantidadJug)*100);                        

                            minJugados1=encontrarPosicionJugador(10,false,true);
                            minJugados2=0;
                            if(cantidadJug>0) minJugados2=100-(((minJugados1)/cantidadJug)*100);    
                            
                            faltasReibidas1=encontrarPosicionJugador(4,false,true);
                            faltasReibidas2=0;
                            if(cantidadJug>0) faltasReibidas2=100-(((faltasReibidas1)/cantidadJug)*100);
                        }

                        if(tipo_registroinfo===("2")){
                            faltasCometidas1=encontrarPosicionJugador(7,false,true);
                            faltasCometidas2=0;
                            if(cantidadJug>0) faltasCometidas2=100-(((faltasCometidas1)/cantidadJug)*100);
                        }


                        tAmarillas1=encontrarPosicionJugador(8,false,true);
                        tAmarillas2=0;
                        if(cantidadJug>0) tAmarillas2=100-(((tAmarillas1)/cantidadJug)*100);

                        tAzules1=encontrarPosicionJugador(13,false,true);
                        tAzules2=0;
                        if(cantidadJug>0) tAzules2=100-(((tAzules1)/cantidadJug)*100);
                        
                        tRojas1=encontrarPosicionJugador(9,false,true);
                        tRojas2=0;
                        if(cantidadJug>0) tRojas2=100-(((tRojas1)/cantidadJug)*100);
                        
                        autogoles1=encontrarPosicionJugador(1,false,true);
                        autogoles2=0;
                        if(cantidadJug>0) autogoles2=100-(((autogoles1)/cantidadJug)*100);
                        
                }

            }catch(err){
                reportarError( nomClase, nomFuncion, "", err);
            }
            
            return(
                <>
                    <div className="CT_contenedorTitulo" style={{marginBottom:"10px"}}>
                        <img className='IM_imgIcono36' src={ic_ranking} alt='' />   
                        <div style={{marginLeft: "10px", fontSize:"18px"}}>Ranking del Jugador</div>             
                    </div>  
                    <div  style={{maxWidth:"800px"}}>  
                        {incluirTorneo()}   

                        <div style={{background:C_colorAccent, fontSize:"14px", color:C_colorPrimaryDark, marginTop:"20px", padding:"4px"}}>Indicadores Positivos</div> 
                        <div className='TX_letraPeq' style={{display:"flex", alignItems:"center", marginLeft:"46px"}}>
                            <div style={{flex:"5", textAlign:"center"}}>{"Indicador / Puntuacion"}</div>
                            <div style={{flex:"2", textAlign:"center"}}>{"%Supera"}</div>   
                            <div style={{flex:"2", textAlign:"center"}}>{"Posición"}</div>                            
                        </div>
                        <div className='BF_lineaInferior'></div>

                        <DinamicoRankingIndicador imagen={ic_balon_gol} indicador={"Goles Anotados"} posicion={golesAnotados1} percentil={golesAnotados2} positivo={true} />
                        
                          
                        {tipo_registroinfo==="2" && (<>
                            <DinamicoRankingIndicador imagen={ic_asistencia} indicador={"Asistencias"} posicion={asistencias1} percentil={asistencias2} positivo={true} />
                            <DinamicoRankingIndicador imagen={ic_balon_gol} indicador={"Goles + Asistencias"} posicion={participacionGoles1} percentil={participacionGoles2} positivo={true} />
                            <DinamicoRankingIndicador imagen={ic_disparo_arco} indicador={"Total Disparos"} posicion={totalDisparos1} percentil={totalDisparos2} positivo={true} />
                            <DinamicoRankingIndicador imagen={ic_disparo} indicador={"Disparos Al Arco"} posicion={disparosArco1} percentil={disparosArco2} positivo={true} />
                            <DinamicoRankingIndicador imagen={ic_porcentajetiempo} indicador={"Efectividad Disparo"} posicion={eDisparo1} percentil={eDisparo2} positivo={true} />
                            <DinamicoRankingIndicador imagen={ic_atajada} indicador={"Atajadas"} posicion={atajadas1} percentil={atajadas2} positivo={true} />
                            <DinamicoRankingIndicador imagen={ic_reloj} indicador={"Minutos Jugados"} posicion={minJugados1} percentil={minJugados2} positivo={true} />
                            <DinamicoRankingIndicador imagen={ic_falta_recibida} indicador={"Faltas Recibidas"} posicion={faltasReibidas1} percentil={faltasReibidas2} positivo={true} />
                        </>)}

                    
                        <div style={{background:C_colorPrimaryDark, fontSize:"14px", color:C_uniformeBlanco, marginTop:"20px", padding:"4px"}}>Indicadores Negativos</div> 
                        <div className='TX_letraPeq' style={{display:"flex", alignItems:"center", marginLeft:"46px"}}>
                            <div style={{flex:"5", textAlign:"center"}}>{"Indicador / Puntuacion"}</div>
                            <div style={{flex:"2", textAlign:"center"}}>{"%Supera"}</div>   
                            <div style={{flex:"2", textAlign:"center"}}>{"Posición"}</div>                            
                        </div>
                        <div className='BF_lineaInferior'></div>

                        {tipo_registroinfo==="2" && (<> 
                            <DinamicoRankingIndicador imagen={ic_falta_cometida} indicador={"Faltas Cometidas"} posicion={faltasCometidas1} percentil={faltasCometidas2} positivo={false} />
                        </>)}

                        <DinamicoRankingIndicador imagen={ic_amarilla} indicador={"Tarjetas Amarillas"} posicion={tAmarillas1} percentil={tAmarillas2} positivo={false} />
                        
                        {tieneTarjetaAzul===true && (<DinamicoRankingIndicador imagen={ic_tarjeta_azul} indicador={"Tarjetas Azules"} posicion={tAzules1} percentil={tAzules2} positivo={false} />)}

                        <DinamicoRankingIndicador imagen={ic_tarjeta_roja} indicador={"Tarjetas Rojas"} posicion={tRojas1} percentil={tRojas2} positivo={false} />
                        <DinamicoRankingIndicador imagen={ic_balon_autogol} indicador={"Autogoles"} posicion={autogoles1} percentil={autogoles2} positivo={false} />
                       
                    </div>
                </>
            
            );
        } 
    }


    const encontrarPosicionJugador = (indicador, todos, ascendente) => {
        const nomFuncion="encontrarPosicionJugador";

        let posicion=0;
        try{
            let i;
            //let suma=0;
            //let promedio=0;
            let encontrado=false;
            let estaEq=false;
            let contador=0;

            if(infoEstadisticas.length>0) {
                let col=columnasOrdenar[indicador];

                /*if(!idTorneoSel.equals(idTorneo)){
                    cantidadJug=-1;
                    posicionJug=-1;
                    idTorneoSel=idTorneo;
                }*/

                //let cantidadJug=infoEstadisticas.length;
                let posJug=-1;

                /*if(cantidadJug<0){
                    cantidadJug=0;
                    for(i=0;i<infoEstadisticas.length;i++){
                        if(infoEstadisticas[i][0].equals(idTorneo)){
                            cantidadJug++;
                        }
                    }
                }*/

                if(posJug<0){
                    for(i=0;i<infoEstadisticas.length;i++){
                        if(infoEstadisticas[i][0]===(idTorneo) && infoEstadisticas[i][1]===(idJugador)){
                            posJug=i;
                            i=infoEstadisticas.length;
                            estaEq=true;
                        }
                    }
                }
                else estaEq=true;


                if(estaEq){

                    if(ascendente){
                        for(i=0;i<infoOrdenadaJugadores[0].length;i++){
                            let reg=infoOrdenadaJugadores[indicador][i];
                            if(infoEstadisticas[reg][0]===(idTorneo)){
                                contador++;

                                if(infoOrdenadaJugadores[indicador][i]===posJug){
                                    i=infoOrdenadaJugadores[0].length;
                                    encontrado=true;
                                }
                            }

                        }
                        if(encontrado){
                            if(!todos){
                                let partidos=valorNumero(infoEstadisticas[posJug][2]);
                                let cantidad=valorNumero(infoEstadisticas[posJug][col]);

                                if(partidos>0 && cantidad>0){
                                    posicion=contador;
                                }

                            }
                            else posicion=contador;
                        }
                    }
                    else{
                        for(i=infoOrdenadaJugadores[0].length-1;i>=0;i--){
                            let reg=infoOrdenadaJugadores[indicador][i];
                            let partidos=valorNumero(infoEstadisticas[reg][2]);
                            if(infoEstadisticas[reg][0]===(idTorneo) && (partidos>0 || todos)){
                                contador++;

                                if(infoOrdenadaJugadores[indicador][i]===posJug){
                                    i=-1;
                                    encontrado=true;
                                    posicion=contador;
                                }
                            }

                        }


                    }

                }

            }



        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }

        return posicion;
    }

/*
    const encontrarPosicionEquipos = (indicador, todos, ascendente) => {
        const nomFuncion="encontrarPosicionEquipos";
    
        let posicion=0;
        try{
            let i,j,k;
            let suma=0;
            let promedio=0;
            let encontrado=false;
            let estaEq=false;
            let contador=0;
    
            if(infoClasificacion.length>0) {
                let col=columnasASumar[indicador];
    
    
                cantidadEq=-1;
                posicionEq=-1;
    
                if(cantidadEq<0){
                    cantidadEq=0;
                    for(i=0;i<infoClasificacion.length;i++){
                        if(infoClasificacion[i][0]===(idTorneo)){
                            cantidadEq++;
                        }
                    }
                }
    
                if(posicionEq<0){
                    for(i=0;i<infoClasificacion.length;i++){
                        if(infoClasificacion[i][0]===(idTorneo) && infoClasificacion[i][2]===(idEquipo)){
                            posicionEq=i;
                            i=infoClasificacion.length;
                            estaEq=true;
                        }
                    }
                }
                else estaEq=true;
    
    
                if(estaEq){
    
                    if(ascendente){
                        for(i=0;i<infoOrdenadaEquipos[0].length;i++){
                            let reg=infoOrdenadaEquipos[indicador][i];
                            if(infoClasificacion[reg][0]===(idTorneo)){
                                contador++;
    
                                if(infoOrdenadaEquipos[indicador][i]===posicionEq){
                                    i=infoOrdenadaEquipos[0].length;
                                    encontrado=true;
                                }
                            }
    
                        }
                        if(encontrado){
                            if(!todos){
                                let partidos=valorNumero(infoClasificacion[posicionEq][6]);
                                let cantidad=valorNumero(infoClasificacion[posicionEq][col]);
    
                                if(partidos>0 && cantidad>0){
                                    posicion=contador;
                                }
    
                            }
                            else posicion=contador;
                        }
                    }
                    else{
                        for(i=infoOrdenadaEquipos[0].length-1;i>=0;i--){
                            let reg=infoOrdenadaEquipos[indicador][i];
                            let partidos=valorNumero(infoClasificacion[reg][6]);
                            if(infoClasificacion[reg][0]===(idTorneo) && (partidos>0 || todos)){
                                contador++;
    
                                if(infoOrdenadaEquipos[indicador][i]===posicionEq){
                                    i=-1;
                                    encontrado=true;
                                    posicion=contador;
                                }
                            }
    
                        }
    
    
                    }
    
                }
    
            }
    
    
    
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    
        return posicion;
    }

    


    const incluirTorneo = () => {
        let nomTorneo="";
        let nomSede="";

        if(infoTorneo.length>0){
            nomTorneo=infoTorneo[0][1];
            nomSede="Sede: " + infoTorneo[0][2];
        }


        return(
            <div className='BF_botonBordeAccent'  style={{minWidth:"250px", maxWidth:"500px", display:"flex", alignItems:"center", justifyContent:"left", cursor:"auto"}}>
                    <img className='IM_imgRedondeada36' src={escudoTorneo} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                    <div style={{textAlign:"left"}}>
                        <div className='TX_unaSolaLinea2' style={{fontSize:"16px", color:C_uniformeNegro}}>{nomTorneo}</div>
                        <div className='TX_unaSolaLinea2' style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{nomSede}</div>
                    </div>
                </div>
        );
    }

    const incluirInfoTC = () => {
        let posicion="-", nombreEquipo="-", pJugados="-", goles="-", golDiferencia="-", puntos="-", escudo=escudoEq;
        let i;

        if(infoClasificacionO.length>0) {
            for (i = 0; i < infoClasificacion.length; i++) {
                if (infoClasificacionO[i].idtorneo===idTorneo && infoClasificacionO[i].idequipo===idEquipo) {
                    posicion = infoClasificacionO[i].tc_posicion;
                    nombreEquipo = infoClasificacionO[i].nombrecorto;
                    pJugados = infoClasificacionO[i].tc_partidosjugados;
                    goles = infoClasificacionO[i].tc_golesafavor + ":" + infoClasificacionO[i].tc_golesencontra;
                    golDiferencia = infoClasificacionO[i].tc_goldiferencia;
                    puntos = infoClasificacionO[i].tc_puntos;

                    i = infoClasificacion.length;
                }

            }
        }

        return(
            <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"500px", marginTop:"20px", marginBottom:"20px"}}>
                <p className='TX_tituloCaja'>{"Clasificación Fase de Grupos"}</p>
                <div className='BF_cajaBlanca'>
                    <table width="100%">           
                        <tbody>
                            <tr className="TX_textoPeq" align="center">
                                        <td style={{maxWidth:"15px"}}><strong>Pos</strong></td>
                                        <td style={{maxWidth:"40px"}}>{""}</td>
                                        <td style={{maxWidth:"120px"}}>Equipo</td>
                                        <td>PJ</td>
                                        <td>Goles</td>
                                        <td>DG</td>
                                        <td><strong>Pts</strong></td>
                                        <td>{""}</td>
                            </tr>
        
                            <tr style={{cursor:"pointer"}} align="center">
                                <td><strong>{posicion}</strong></td>
                                <td> <img className="IM_imgRedondeada36" src={escudo} alt="" /></td>
                                <td align="left" className="TX_unaSolaLinea2">{nombreEquipo}</td>
                                <td>{pJugados}</td>
                                <td>{goles}</td>
                                <td>{golDiferencia}</td>
                                <td><strong>{puntos}</strong></td>
                                <td><img className="IM_imgIcono24" src={ic_entrar_gris} alt="Ingresar"  height="20px" width="20px"  /></td>
                            </tr>
                        
                        </tbody>
                    </table>
                </div>
            </div>   
        );
    }

    const incluirInfoPartidos = () => {
        const nomFuncion="incluirInfoPartidos";

        let partidosFG=[];
        let partidosFE=[];
        let partidosNP=[];

        try{            
            let i;
            if(infoPartidos.length>0) {
                    for(i=0;i<infoPartidos.length;i++){
                        if (infoPartidos[i][0]===idTorneo && (infoPartidos[i][5]===idEquipo || infoPartidos[i][8]===idEquipo)) {
                            
                            let tempDatos=Object.assign({} , partido);
                            tempDatos=infoPartidosO[i];

                            if(idEquipo!==infoPartidos[i][5]){ //Eq actual es local o visitante
                                let temp=tempDatos.nomCortoEq1;
                                tempDatos.nomCortoEq1=tempDatos.nomCortoEq2;
                                tempDatos.nomCortoEq2=temp;

                                temp=tempDatos.imgEscudoEq1;
                                tempDatos.imgEscudoEq1=tempDatos.imgEscudoEq2;
                                tempDatos.imgEscudoEq2=temp;

                                temp=tempDatos.golesEq1;
                                tempDatos.golesEq1=tempDatos.golesEq2;
                                tempDatos.golesEq2=temp;

                                temp=tempDatos.penaltisEq1;
                                tempDatos.penaltisEq1=tempDatos.penaltisEq2;
                                tempDatos.penaltisEq2=temp;
                            }
                    
                            if(infoPartidos[i][38]===("1")){
                                partidosFG.push(tempDatos);                        
                            }
                            else{
                                partidosFE.push(tempDatos);
                            }

                        }
                    }
            }

            if(infoEquiposGrupo.length && tipo_torneo!=="5"){
                for(i=0;i<infoEquiposGrupo.length;i++){
                    if(infoEquiposGrupo[i][0]===idTorneo){
                        let tempDatos=Object.assign({} , partido);
                        tempDatos.nomJornada="Por Definir";

                        if(tipo_torneo==="1" || tipo_torneo==="3"){
                            if(!tienenPartido(idEquipo, infoEquiposGrupo[i][1],false)){
                                tempDatos.nomCortoEq2=infoEquiposGrupoO[i].nombrecorto;
                                tempDatos.imgEscudoEq2=infoEquiposGrupoO[i].escudo;
                                partidosNP.push(tempDatos); 
                            }
                        }
                        else{
                            if(!tienenPartido(idEquipo, infoEquiposGrupo[i][1],true)){
                                tempDatos.nomCortoEq2=infoEquiposGrupoO[i].nombrecorto;
                                tempDatos.imgEscudoEq2=infoEquiposGrupoO[i].escudo;
                                partidosNP.push(tempDatos); 
                            }

                            if(!tienenPartido(infoEquiposGrupo[i][1], idEquipo, true)){
                                tempDatos.nomCortoEq2=infoEquiposGrupoO[i].nombrecorto;
                                tempDatos.imgEscudoEq2=infoEquiposGrupoO[i].escudo;
                                partidosNP.push(tempDatos); 
                            }
                        }
                    }
                }


            }
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  

        return(
            <>
            {partidosFG.length>0 && 
            (<>
                <div className='BF_botonBordePrimary'  style={{display:"flex", alignItems:"center", justifyContent:"center", border:"2px solid " + C_colorPrimary, padding:"0px", maxWidth:"800px"}}>
                    <img className='IM_imgIcono24' src={ic_fase_grupos} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                    <div style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{"FASE DE GRUPOS"}</div>
                </div>
                {partidosFG.map((el) => (<DinamicoPartidosProgramados key={el.idPartido} partido={el} linea={false} tipoPartido="dpe" modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo}/>))}
                <div style={{height:"10px"}}></div>
            </>)}

            {partidosNP.length>0 && 
            (<>
                {partidosNP.map((el, index) => (<DinamicoPartidosProgramados key={index} partido={el} linea={false} tipoPartido="dpe" modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo}/>))}
                <div style={{height:"10px"}}></div>
            </>)}

            {partidosFE.length>0 && 
            (<>
                <div className='BF_botonBordeRojo'  style={{display:"flex", alignItems:"center", justifyContent:"center", border:"2px solid " + C_uniformeRojo, padding:"0px", maxWidth:"800px"}}>
                    <img className='IM_imgIcono24' src={ic_fase_eliminacion} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                    <div style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{"FASE DE ELIMINACION"}</div>
                </div>
                {partidosFE.map((el) => (<DinamicoPartidosProgramados key={el.idPartido} partido={el} linea={false} tipoPartido="dpe" modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo}/>))}
                <div style={{height:"10px"}}></div>
            </>)}
            
            {partidosFG.length===0 && partidosFE.length===0 && partidosNP.length===0 &&
            (<div className='TX_sinInfo'>{"No Tiene Partidos Registrados"}</div>)}

            </>
        );

    }

    const tienenPartido = (idEquipo1, idEquipo2, mantenerOrden) => {
        const nomFuncion="tienenPartido";

        let resultado=false;
        try{
            // infoPartidos[i][10].equals("1") &&

            if(infoPartidos.length>0){
                let i,j;
                for(i=0;i<infoPartidos.length;i++){
                    if(infoPartidos[i][0]===(idTorneo) && infoPartidos[i][38]===("1")){
                        if(mantenerOrden){
                            if(infoPartidos[i][5]===(idEquipo1) && infoPartidos[i][8]===(idEquipo2)){
                                resultado=true;
                                i=infoPartidos.length;
                            }
                        }
                        else{
                            if((infoPartidos[i][5]===(idEquipo1) && infoPartidos[i][8]===(idEquipo2)) ||
                                    (infoPartidos[i][5]===(idEquipo2) && infoPartidos[i][8]===(idEquipo1))){
                                resultado=true;
                                i=infoPartidos.length;
                            }
                        }

                    }
                }
            }



        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
        return resultado;
    }

    */

    const incluirTorneo = () => {

        return(
            <div className='BF_botonBordeAccent'  style={{minWidth:"250px", maxWidth:"500px", display:"flex", alignItems:"center", justifyContent:"left", cursor:"auto"}}>
                    <img className='IM_imgRedondeada36' src={escudoEq} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                    <div style={{textAlign:"left"}}>
                        <div className='TX_unaSolaLinea2' style={{fontSize:"16px", color:C_uniformeNegro}}>{nombreEq}</div>
                        <div className='TX_unaSolaLinea2' style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{"Torneo: " + nombreTorneo}</div>
                    </div>
                </div>
        );
    }

    const incluirInfoPartidos = () => {
        const nomFuncion="incluirInfoPartidos";

        let partidosFG=[];
        let partidosFE=[];

        try{            
            let i;
            if(infoPartidos.length>0) {
                    for(i=0;i<infoPartidos.length;i++){
                        if (infoPartidos[i][0]===idTorneo && (infoPartidos[i][5]===idEquipo || infoPartidos[i][8]===idEquipo)) {
                            
                            //let tempDatos=Object.assign({} , infoPartidosO[i]);
                            let tempDatos;
                            tempDatos=JSON.parse(JSON.stringify(infoPartidosO[i]));

                            if(idEquipo!==infoPartidos[i][5]){ //Eq actual es local o visitante
                                let temp=tempDatos.nomCortoEq1;
                                tempDatos.nomCortoEq1=tempDatos.nomCortoEq2;
                                tempDatos.nomCortoEq2=temp;

                                temp=tempDatos.imgEscudoEq1;
                                tempDatos.imgEscudoEq1=tempDatos.imgEscudoEq2;
                                tempDatos.imgEscudoEq2=temp;

                                temp=tempDatos.golesEq1;
                                tempDatos.golesEq1=tempDatos.golesEq2;
                                tempDatos.golesEq2=temp;

                                temp=tempDatos.penaltisEq1;
                                tempDatos.penaltisEq1=tempDatos.penaltisEq2;
                                tempDatos.penaltisEq2=temp;
                            }
                    
                            if(infoPartidos[i][38]===("1")){
                                partidosFG.push(tempDatos);                        
                            }
                            else{
                                partidosFE.push(tempDatos);
                            }

                        }
                    }
            }

         

            return(
                <>
                {partidosFG.length>0 && 
                (<>
                    <div className='BF_botonBordePrimary'  style={{display:"flex", alignItems:"center", justifyContent:"center", border:"2px solid " + C_colorPrimary, padding:"0px", maxWidth:"800px"}}>
                        <img className='IM_imgIcono24' src={ic_fase_grupos} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                        <div style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{"FASE DE GRUPOS"}</div>
                    </div>
                    {partidosFG.map((el) => (<DinamicoPartidosProgramados key={el.idPartido} partido={el} linea={false} tipoPartido="dpe" modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} idJugador={idJugador}/>))}
                    <div style={{height:"10px"}}></div>
                </>)}

                {partidosFE.length>0 && 
                (<>
                    <div className='BF_botonBordeRojo'  style={{display:"flex", alignItems:"center", justifyContent:"center", border:"2px solid " + C_uniformeRojo, padding:"0px", maxWidth:"800px"}}>
                        <img className='IM_imgIcono24' src={ic_fase_eliminacion} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                        <div style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{"FASE DE ELIMINACION"}</div>
                    </div>
                    {partidosFE.map((el) => (<DinamicoPartidosProgramados key={el.idPartido} partido={el} linea={false} tipoPartido="dpe" modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} idJugador={idJugador}/>))}
                    <div style={{height:"10px"}}></div>
                </>)}
                
                {partidosFG.length===0 && partidosFE.length===0 && 
                (<div className='TX_sinInfo'>{"No Tiene Partidos Registrados"}</div>)}

                </>
            );

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const clickMenu = (e, numPestana) => {
        if(pestana!==numPestana){
            let info=dividirLista(params,"#",5);
            // eslint-disable-next-line
            paramsAjustado=info[0] + "#" + numPestana + "#" + info[2] + "#" + info[3];
            setPestana(numPestana);    
            
        }
        setPestana(numPestana);     
    }

    const salir = () => {
        if(modOrigen===0 || paramOrigen===""){
            definirModulo(0,0,"",ID_MODULO_DETALLE_TORNEO,idTorneo);     
        }
        else  definirModulo(0,0,"",modOrigen,paramOrigen);          
    }  

    

    return (
        <>        
            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                        <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px"}} />  </div>
                        <div className="CT_elementoEncabezado"><img className="IM_imgRedondeada36" height="36px" width="36px" src={ic_jugadorsinimagen} alt="" /></div>
                        <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{"Información Jugador"}</p></div>
                </div>                
            </div>

            <div style={{paddingTop:"50px"}}>

                <div style={{display:"flex", background:C_colorPrimaryDark, paddingTop:"8px", alignItems:"center", color:C_uniformeBlanco}}>
                    <img className="IM_imgRedondeada46" src={fotoJug} alt="" />
                    <div style={{flex:"1"}}>
                        <div style={{flex:"1", fontSize:"16px"}}>{nombreJug}</div> 
                        <div style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                            <div>{edadJug!=="" && (edadJug + " Años")}</div>
                            <div>{estaturaJug!=="" && (estaturaJug + " cm")}</div>
                            <div>{pesoJug!=="" && (pesoJug + " kg")}</div>
                        </div> 
                        <div style={{flex:"1", fontSize:"14px", color: C_colorAccent }}>{posicionJug}</div> 
                    </div>  
                    <div className='IM_imagenConTexto'>
                        <img className="IM_camiseta55" src={ic_uniforme_claro} alt="" />
                        <span className='IM_numeroCamiseta' style={{color:C_uniformeNegro, top:"10px"}}>{camisetaJug}</span>
                    </div>
                </div>  

                <div className="CT_menuDeslizable">
                        <div className="CT_elementoDeslizable"><div className={pestana==="1" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "1")}>TEMPORADA</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="2" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "2")}>ESTADISTICAS</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="3" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "3")}>JUG Vs LIGA</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="4" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "4")}>RK JUGADOR</div></div>
                </div>

                <div>

                    {!loading && esError===0 && pestana!=="" && (<div style={{margin:"10px"}}>{presentarPestana()}</div>)  } 

                    {!loading && esError===0 && (pestana==="3" || pestana==="4") && listaPestanaJug && (<div style={{margin:"10px"}}>{presentarPestanaJug()}</div>)  } 
                    
                </div>
                
            </div>
            <div style={{marginBottom:"40px"}}></div>

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}  

        </>
    );

};

export default Detalle_Jugador;