import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary } from "../constantes/generales";

const DinamicoResumenEfectividad = ({ datos }) => {

 
    return(
        <div style={{margin:"5px"}}>
                <div style={{fontSize:"16px", color:C_uniformeGrisOscuro}}>{datos[0]}</div>
                {datos[1]!=="" && (<div style={{fontSize:"12px", color:C_uniformeGrisClaro}}>{datos[1]}</div>)}
                
                <div style={{display:"flex", marginTop:"4px"}}>
                    <div style={{flex:"1"}}>
                        <div style={{fontSize:"12px", color:C_uniformeGrisClaro}}>{datos[2]}</div>
                        <div style={{fontSize:"24px", color:C_colorPrimary}}>{datos[4]}</div>
                        <div style={{fontSize:"12px", color:C_uniformeGrisOscuro}}>{datos[6]}</div>
                    </div>
                    <div style={{flex:"1"}}>
                        <div style={{fontSize:"12px", color:C_uniformeGrisClaro}}>{datos[3]}</div>
                        <div style={{fontSize:"24px", color: C_colorAccent }}>{datos[5]}</div>
                        <div style={{fontSize:"12px", color:C_uniformeGrisOscuro}}>{datos[7]}</div>
                    </div>
                </div>
            </div>
    );

};


export default DinamicoResumenEfectividad;