import { C_uniformeGrisOscuro, C_uniformeVerdeClaro, ID_MODULO_BASE, ID_MODULO_DETALLE_EQUIPO, ID_MODULO_DETALLE_JUGADOR } from "../constantes/generales";
import { formatearTexto, reportarError } from "../helpers/funciones";
const nomClase="DinamicoRankingJugadoresDet_WB";

const DinamicoRankingJugadoresDet = ({ registro, idTorneo="", tipoRanking, modOrigen, paramOrigen, definirModulo }) => {

    const manejarClick = () => {
        let nomFuncion="manejarClick"; 

        try{ 

            if(tipoRanking==="equipos"){
                let idEquipo=registro[0];
                if(idEquipo!=="") definirModulo(0, modOrigen, paramOrigen, ID_MODULO_DETALLE_EQUIPO, idEquipo); 
                else definirModulo(0, modOrigen, paramOrigen, ID_MODULO_BASE, ""); 
            }
            else if(tipoRanking==="jugadores"){
                
                let idEquipo=registro[8];
                let idJugador=registro[0];
                let parametros=idJugador + "#1#" + idEquipo + "#" + idTorneo;

                if(idJugador!=="" && idEquipo!=="" && idTorneo!=="") definirModulo(0, modOrigen, paramOrigen, ID_MODULO_DETALLE_JUGADOR, parametros); 
                else definirModulo(0, modOrigen, paramOrigen, ID_MODULO_BASE, ""); 
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
               
    }

    return(
        <>
        <tr style={{padding:"2px", cursor:"pointer"}}  onClick={manejarClick}>
                <td style={{fontWeight:"bold"}}>{registro[7]}</td>
                <td width="36px"><img className='IM_imgRedondeada36' src={registro[6]} alt='' /></td> 
                <td style={{maxWidth:"180px"}}>
                    <div style={{textAlign:"left", marginLeft:"6px"}}>
                        <div className="TX_unaSolaLinea2"  style={{fontSize:"16px", color:C_uniformeGrisOscuro}}>{formatearTexto(registro[1],2)}</div>
                        {registro[2]!=="" && (<div className="TX_unaSolaLinea2" style={{fontSize:"12px", color:C_uniformeVerdeClaro}}>{formatearTexto(registro[2],2)}</div>)}
                    </div>    
                </td>                     
                <td style={{fontWeight:"bold", fontSize:"16px"}}>{registro[3]}</td>
                <td style={{fontSize:"12px", color:C_uniformeGrisOscuro}}>{registro[4]}</td>
                <td style={{fontSize:"12px", color:C_uniformeGrisOscuro}}>{registro[5]}</td>                    
        </tr>
        <tr><td colSpan={6} align="center"><div className='BF_lineaInferior' ></div></td></tr>   
        </>         
    );

}


export default DinamicoRankingJugadoresDet;