import { useState } from 'react';

import {formatearFecha, recuperarDatoLocal, reportarError} from '../helpers/funciones';

import "../estilos/TX_Textos.css";
import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";

import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png';
import ic_cancelar from '../recGraficos/ic_cancelar.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_escudo_base from '../recGraficos/ic_escudo_base.png';
import ic_escudo_base2_w from '../recGraficos/ic_escudo_base2_w.png';

import ic_tarjeta_dobleamarilla from '../recGraficos/ic_tarjeta_dobleamarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_acumular_amarilla from '../recGraficos/ic_acumular_amarilla.png';
import ic_acumular_roja from '../recGraficos/ic_acumular_roja.png';
import ic_detalle_verde from '../recGraficos/ic_detalle_verde.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import { C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeVerdeClaro } from '../constantes/generales';



const DinamicoSancionesJugador = ({ datos, tipoLista="detalle_jugador", conLinea=true }) => {
    const nomClase="DinamicoSancionesJugador_WB";
    const nomFuncion="DinamicoSancionesJugador";

    const [esVisible, setEsVisible] = useState(false);

    try{

        if(!datos) return null;

        const ruta=recuperarDatoLocal("rutaRecursos");

        

        let escudoEq1=ic_escudo_base;
        if(datos.escudoEq1!=="") escudoEq1=ruta + datos.escudoEq1;

        let escudoEq2=ic_escudo_base2_w;
        if(datos.escudoEq2!=="") escudoEq2=ruta + datos.escudoEq2;

        let presentarPartido=false;
        if(datos.tipoSancion==="1" || datos.tipoSancion==="2") presentarPartido=true;

        let jornada="FG";
        if(datos.jornada==="2") jornada="FE";

        let imagen=ic_jugadorsinimagen;
        let textoPrincipal="";
        let textoSecundario=""
        let claseImagen="IM_imgRedondeada36";

        if(tipoLista==="sanciones_jugador"){
            imagen=ic_transparente;
            claseImagen="IM_imgIcono28";
            if(datos.tipoSancion===("1")) imagen=ic_tarjeta_dobleamarilla;
            if(datos.tipoSancion===("2")) imagen=ic_tarjeta_roja;
            if(datos.tipoSancion===("3")) imagen=ic_acumular_amarilla;
            if(datos.tipoSancion===("4")) imagen=ic_acumular_roja;
            if(datos.tipoSancion===("5")) imagen=ic_detalle_verde;

            textoPrincipal=datos.nomSancion;
        }
        else{
            if(datos.fotoJugador!=="") imagen=ruta + datos.fotoJugador;
            textoPrincipal=datos.nomJugador;
            textoSecundario=datos.nomEquipo;
        }

        //{/*style={{minWidth: "180px", maxWidth: "180px"}}*/}

        return (

                <div className="CT_regSancion" onClick={() => {setEsVisible(!esVisible)}} style={{cursor:"pointer"}}> 
                    {tipoLista==="sanciones_torneo" && (
                        <table border={0} width="100%">
                        <tbody>
                            <tr>
                                <td rowSpan={3} style={{width: "36px"}}><img className={claseImagen} height="36px" width="36px" src={imagen} alt="" /></td>
                                <td className='TX_unaSolaLinea2' style={{minWidth: "180px", maxWidth: "180px"}}>{textoPrincipal}</td>
                                <td rowSpan={3} style={{width: "50px", minWidth:"50px", textAlign:"center", fontSize:"24px", fontWeight:"bold", color:C_uniformeRojo}}>{datos.totalpartidos}</td>
                                <td rowSpan={3} style={{width: "50px", minWidth:"50px", textAlign:"center", fontSize:"24px", fontWeight:"bold"}}>{datos.valSancion}</td>
                                <td rowSpan={3} style={{width: "24px"}}><img className="IM_imgIcono24" src={esVisible ? ic_cancelar : ic_entrar_gris} alt=""  height="24px" width="24px"  /></td>
                            </tr>
                            <tr><td className='TX_unaSolaLinea2 TX_textoPeq' style={{color: C_uniformeVerdeClaro}}>{textoSecundario}</td></tr>
                            <tr><td className='TX_unaSolaLinea2 TX_textoPeq' style={{color: C_uniformeGrisOscuro}}><strong>F Sancion:</strong>{" " + formatearFecha(datos.fecha_sancion,6)}</td></tr>
                        </tbody>
                    </table>
                    )}

                    {tipoLista!=="sanciones_torneo" && (
                        <table border={0} width="100%" style={{minWidth: "100px"}}>
                        <tbody>
                            <tr>
                                <td rowSpan={3} style={{width: "28px"}}><img className={claseImagen} src={imagen} alt="" /></td>
                                <td style={{minWidth:"100px"}}><span style={{minWidth:"100px"}}>{textoPrincipal}</span></td>
                                <td rowSpan={3} style={{width: "50px", minWidth:"50px", textAlign:"center", fontSize:"24px", fontWeight:"bold", color:C_uniformeRojo}}>{datos.totalpartidos}</td>
                                <td rowSpan={3} style={{width: "50px", minWidth:"50px", textAlign:"center", fontSize:"24px", fontWeight:"bold", color:C_uniformeGrisOscuro}}>{datos.valSancion}</td>
                                <td rowSpan={3} style={{width: "16px"}}><img className="IM_imgIcono16" src={esVisible ? ic_cancelar : ic_entrar_gris} alt="" /></td>
                            </tr>
                            {textoSecundario!=="" && (
                                <tr><td className='TX_unaSolaLinea2 TX_textoPeq' style={{color: C_uniformeVerdeClaro, minWidth:"100px"}}>{textoSecundario}</td></tr>
                            )}                        
                            <tr><td className='TX_unaSolaLinea2 TX_textoPeq' style={{color: C_uniformeGrisOscuro, minWidth:"100px"}}><strong>F Sancion:</strong>{" " + formatearFecha(datos.fecha_sancion,6)}</td></tr>
                        </tbody>
                    </table>
                    )}
                    
                    {esVisible && 
                    (
                    <><div><p style={{textAlign:"left", fontSize:"12px", color: C_uniformeGrisOscuro, margin:"0"}}><strong>Obs:</strong>{" " + (datos.txtsancion || "Sin Observación")}</p></div>
                    {presentarPartido && 
                    (<div>
                        <table border={0} width="100%">
                            <tbody>
                                <tr>
                                    <td rowSpan={2} style={{width: "28px"}}><img className="IM_imgRedondeada28" height="28px" width="28px" src={escudoEq1} alt="" /></td>
                                    <td colSpan={1} className='TX_textoPeq' style={{textAlign:"center"}}>{formatearFecha(datos.fechaPartido + " " + datos.horaPartido,12)}</td>
                                    <td rowSpan={2} style={{width: "28px"}}><img className="IM_imgRedondeada28" height="28px" width="28px" src={escudoEq2} alt="" /></td>
                                </tr>
                                <tr className='TX_textoPeq'>
                                    <td>
                                    <div className="CT_contenedorPartido">
                                        <div className="CT_elementoPartido" style={{textAlign: "left"}}>{datos.nomEquipo1}</div>
                                        <div className="CT_elementoPartido2" style={{textAlign: "center", marginTop: "0px"}}>{jornada}</div>
                                        <div className="CT_elementoPartido" style={{textAlign: "right"}}>{datos.nomEquipo2}</div>
                                    </div>                              
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    )}
                    </>)
                    }
                    
                    {conLinea && (<div className="BF_lineaInferior"></div>)}
                    
                </div>
        
        );

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

};


export default DinamicoSancionesJugador;