import { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { crearTabla, dividirLista, dividirListaNueva, esAndroidSO, esformatoEmail, esformatoNumero, formatearDecimal, formatearTexto, guardarDatoLocal, isFlagUpdate, openInNewTab, presentarError, presentarLog, quitarCharEspeciales, reconstruirDatosOrigen, recuperarDatoLocal, registrarLogEventos, reportarError, setFlagUpdate, setParametros, showMensajePantalla, superaTiempoActualizacion, valorLong, valorNumero,  } from '../helpers/funciones';
import { useModal } from '../helpers/useModal';

import {estilosSelect} from '../estilos/estilosSelect'

import Select from "react-select";

import { getDatos } from '../gestionDatos/getDatos';

import Loader from "../recVisual/Loader";
import MenuFlotante from '../recVisual/MenuFlotante';
import Modal from '../recVisual/Modal';

import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/TX_Textos.css";
import "../estilos/BF_BordesFondos.css";
import "../estilos/IP_Inputs.css";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import ic_logo_mg from '../recGraficos/ic_logo_mg.png';
import ic_menu from '../recGraficos/ic_menu.png';
import ic_actualizar_blanco from '../recGraficos/ic_actualizar_blanco.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen_blanco.png';
import ic_jugadorsinimagen_gris from '../recGraficos/ic_jugadorsinimagen.png';
import ic_sombrero from '../recGraficos/ic_sombrero.png';
import ic_mi_informacion from '../recGraficos/ic_mi_informacion.png';
import ic_equipo from '../recGraficos/ic_equipo.png';
import ic_salir_negro from '../recGraficos/ic_salir_negro.png';
import ic_cobros_gestionar from '../recGraficos/ic_cobros_gestionar.png';
import ic_cobros_recibir from '../recGraficos/ic_cobros_recibir.png';
import ic_sitio_web from '../recGraficos/ic_sitio_web.png';
import ic_partido_verde from '../recGraficos/ic_partido_verde.png';
import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_buscar_blanco from '../recGraficos/ic_buscar_blanco.png';
import ic_torneo from '../recGraficos/ic_torneo.png';
import ic_torneo_gris_w from '../recGraficos/ic_torneo_gris_w.png';
import ic_escudo_base2_w from '../recGraficos/ic_escudo_base2_w.png';
import ic_detalle_primary from '../recGraficos/ic_detalle_primary.png';
import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png';
import ic_manual_primary from '../recGraficos/ic_manual_primary.png';
import ic_llamada from '../recGraficos/ic_llamada.png';
import ic_whatsapp from '../recGraficos/ic_whatsapp.png';
import ic_email from '../recGraficos/ic_email.png';

import MenuLateral from '../recVisual/MenuLateral';
import { listaDosItems, listaIconoDosItems } from '../recVisual/listaDesplegable';
import DinamicoDatosJugador from '../gestionVisual/DinamicoDatosJugador';
import DinamicoSancionesJugador from '../gestionVisual/DinamicoSancionesJugador';
import DinamicoCobrosJugador from '../gestionVisual/DinamicoCobrosJugador';
import DinamicoPartidosProgramados from '../gestionVisual/DinamicoPartidosProgramados';
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary, ID_MODULO_BASE, ID_MODULO_DETALLE_EQUIPO, ID_MODULO_DETALLE_JUGADOR, ID_MODULO_DETALLE_TORNEO, ID_MODULO_INFO_EQUIPO, ID_MODULO_PRINCIPAL_INVITADO, ID_MODULO_PRINCIPAL_JUGADOR, ID_MODULO_INFO_JUGADOR, ID_MODULO_GESTION_MICUENTA, C_uniformeVerdeClaro, C_uniformeAzulClaro, C_uniformeNegro, C_uniformeGrisMedio, SEPARADOR_PARAMETROS_BD, ID_MODULO_INFO_COBROS, SEP_4, ID_MODULO_INFO_COBROS_JUGADOR } from '../constantes/generales';
import ShowError from '../recVisual/ShowError';
import Stars from '../recVisual/Stars';
import { callUrl } from '../gestionDatos/callUrl';
import ContactoMG from '../recVisual/ContactoMG';



const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Principal_Jugador_WB";
const idModulo=ID_MODULO_PRINCIPAL_JUGADOR;

let ultAcceso="";

let idUser="";
let tipoUser="";
let idJugador="";

var infoUsuarioO = [];

let paramsAjustado="";
let paramsBase="";


let idSede = "";
let nomSede = "";
let nomEmpresa = "";
let nombreUser = "";
let fotoJugador = "";
let posicionJugador ="";
let esPrimerIngreso = true;
let esSedePilotos = true;
let mostrarBienvenida = true;

let hayVariasSedes=false;
let idTorneo = "";
let idTorneoNotificacion="";
let idMensajeNotificacion="";
let idEquipo="";
let esAdminEquipo=false;
let tipo_registroinfo="1";
let tieneTarjetaAzul=false;

let tipoPermisoCobros="";
let indicativo= "";

let escudoEq=ic_escudo_base_gris;

let ventanaMensaje;

let numTorneoSel=0;

let ultPartidoSel=0;
let fotoArbcalificacion="";
let nomArbCalificacion="";


let matrizCampos=[
    {id: "NOMBRE",  idx: 0, valor: "", ultValor:"", error: "Ingrese su nombre"},
    {id: "CORREO",  idx: 1, valor: "", ultValor:"", error: "Ingrese un correo válido"},
    {id: "PAIS",  idx: 2, valor: "", ultValor:"", error: "Seleccione el país de contacto"},
    {id: "TELEFONO",  idx: 3, valor: "", ultValor:"", error: "Ingrese un teléfono válido"},
    {id: "ASUNTO",  idx: 4, valor: "", ultValor:"", error: "Seleccione el asunto del contacto"},
    {id: "MENSAJE",  idx: 5, valor: "", ultValor:"", error: "Ingrese su mensaje"}
];

let paises=[
    {value:"0", label:"Selecciona tú País", texto2:""},
    {value:"57", label:"Colombia", texto2:""},
    {value:"54", label:"Argentina", texto2:""},
    {value:"56", label:"Chile", texto2:""},
    {value:"593", label:"Ecuador", texto2:""},
    {value:"1", label:"Estados Unidos", texto2:""},
    {value:"52", label:"México", texto2:""},
    {value:"51", label:"Perú", texto2:""},
    {value:"-1", label:"Otro", texto2:""}
];

let asuntos=[
    {value:"0", label:"Selecciona tú asunto", texto2:""},
    {value:"1", label:"Pregunta sobre la funcionalidad", texto2:""},
    {value:"2", label:"Soporte Técnico", texto2:""},
    {value:"3", label:"Reportar un problema", texto2:""},
    {value:"4", label:"Sugerencia, comentario, felicitación", texto2:""},
    {value:"5", label:"Otro asunto", texto2:""}
];


var infoJugadorO = [];
var infoJugador = [];
function crearInfoJugador(datos){   
    const nomFuncion="crearInfoJugador";

    try{
        let jugador = {
            idjugador:"",
            nombre:"",
            idsede:"",
            sede:"",
            idempresa:"",
            empresa:"",
            numingresos:"",
            pilotos:"",
            activo:"",
            foto:"",
            bienvenida:""
        }
    
        infoJugadorO = [];
        infoJugador = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.nombre=datos[i][j];
                if(j===2) registro.idsede=datos[i][j];
                if(j===3) registro.sede=datos[i][j];
                if(j===4) registro.idempresa=datos[i][j];
                if(j===5) registro.empresa=datos[i][j];
                if(j===6) registro.numingresos=datos[i][j];
                if(j===7) registro.pilotos=datos[i][j];
                if(j===8) registro.activo=datos[i][j];
                if(j===9) registro.foto=datos[i][j];
                if(j===10) registro.bienvenida=datos[i][j];
            }

            infoJugadorO.push(registro);
            infoJugador.push(datos[i]);

            if(idJugador===registro.idjugador){
                idSede = registro.idsede;
                nomSede = registro.sede;
                nomEmpresa = registro.empresa;
                nombreUser = registro.nombre;
                fotoJugador = registro.foto;

                if (registro.numingresos==="0") esPrimerIngreso = true;
                else esPrimerIngreso = false;
                if (registro.pilotos==="1") esSedePilotos = true;
                else esSedePilotos = false;
                if (registro.bienvenida==="1") mostrarBienvenida = true;
                else mostrarBienvenida = false;
            }

            if(i>=1) hayVariasSedes=true;
        }



    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoClasificacionO = [];
var infoClasificacion = [];
var infoTorneos = [];
function crearInfoClasificacion(datos){   
    const nomFuncion="crearInfoClasificacion";

    try{
        let clasificacion = {
            idtorneo:"",
            nomTorneo:"",
            idequipo:"",
            nombrelargo:"",
            nombrecorto:"",
            escudoEquipo:"",
            esadmin:"",
            idposicion:"",
            nomPosicion:"",
            numCamiseta:"",
            posicionEq:"",
            partidosjugados:"",
            golesafavor:"",
            golesencontra:"",
            goldiferencia:"",
            puntos:"",
            posicionTC:"",
            partidosjugadosTC:"",
            golesafavorTC:"",
            golesencontraTC:"",
            goldiferenciaTC:"",
            puntosTC:"",
            numgrupoTC:"",
            idjugador:"",
            escudoTorneo:"",
            idpermisocobros:"",
            nombreEmpresa:"",
            nombreSede:"",
            telefonoSede:"",
            correoSede:"",
            indicativoSede:"",
            tipo_registroinfo:"",
            tieneTarjetaAzul:""
        }
    
        infoClasificacionO = [];
        infoClasificacion = [];
        infoTorneos = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , clasificacion);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.nomTorneo=datos[i][j];
                if(j===2) registro.idequipo=datos[i][j];
                if(j===3) registro.nombrelargo=datos[i][j];
                if(j===4) registro.nombrecorto=datos[i][j];
                if(j===5) registro.escudoEquipo=datos[i][j];
                if(j===6) registro.esadmin=datos[i][j];
                if(j===7) registro.idposicion=datos[i][j];
                if(j===8) registro.nomPosicion=datos[i][j];
                if(j===9) registro.numCamiseta=datos[i][j];
                if(j===10) registro.posicionEq=datos[i][j];
                if(j===11) registro.partidosjugados=datos[i][j];
                if(j===12) registro.golesafavor=datos[i][j];
                if(j===13) registro.golesencontra=datos[i][j];
                if(j===14) registro.goldiferencia=datos[i][j];
                if(j===15) registro.puntos=datos[i][j];
                if(j===16) registro.posicionTC=datos[i][j];
                if(j===17) registro.partidosjugadosTC=datos[i][j];
                if(j===18) registro.golesafavorTC=datos[i][j];
                if(j===19) registro.golesencontraTC=datos[i][j];
                if(j===20) registro.goldiferenciaTC=datos[i][j];
                if(j===21) registro.puntosTC=datos[i][j];
                if(j===22) registro.numgrupoTC=datos[i][j];
                if(j===23) registro.idjugador=datos[i][j];
                if(j===24) registro.escudoTorneo=datos[i][j];
                if(j===25) registro.idpermisocobros=datos[i][j];
                if(j===26) registro.nombreEmpresa=datos[i][j];
                if(j===27) registro.nombreSede=datos[i][j];
                if(j===28) registro.telefonoSede=datos[i][j];
                if(j===29) registro.correoSede=datos[i][j];
                if(j===30) registro.indicativoSede=datos[i][j];
                if(j===31) registro.tipo_registroinfo=datos[i][j];
                if(j===32) registro.tieneTarjetaAzul=datos[i][j];
            }

            infoClasificacionO.push(registro);
            infoClasificacion.push(datos[i]);

            let tempTorneo = [];
            tempTorneo.push(datos[i][0]);
            tempTorneo.push(datos[i][1]);
            tempTorneo.push(datos[i][2]);
            tempTorneo.push(datos[i][3]);
            tempTorneo.push(datos[i][6]);
            tempTorneo.push(datos[i][8]);
            tempTorneo.push(datos[i][23]);
            tempTorneo.push(datos[i][24]);
            tempTorneo.push(datos[i][25]);
            tempTorneo.push(datos[i][26]);
            tempTorneo.push(datos[i][27]);
            tempTorneo.push(datos[i][28]);
            tempTorneo.push(datos[i][29]);
            tempTorneo.push(datos[i][30]);
            tempTorneo.push(datos[i][31]);
            tempTorneo.push(datos[i][32]);
            tempTorneo.push(datos[i][5]);

            infoTorneos.push(tempTorneo);

            
            
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoEstadisticasO = [];
var infoEstadisticas = [];
var estadisticasJugador = [];
function crearInfoEstadisticas(datos){   
    const nomFuncion="crearInfoEstadisticas";

    try{
        let estadistica = {
            idtorneo:"",
            idequipo:"",
            idjugador:"",
            pjugados:"",
            goles:"",
            asistencias:"",
            disparosdentro:"",
            atajadas:"",
            tarjetasamarillas:"",
            tarjetasrojas:"",
            tarjetasazules:""
        }        
    
        infoEstadisticasO = [];
        infoEstadisticas = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , estadistica);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idequipo=datos[i][j];
                if(j===2) registro.idjugador=datos[i][j];
                if(j===3) registro.pjugados=datos[i][j];
                if(j===4) registro.goles=datos[i][j];
                if(j===5) registro.asistencias=datos[i][j];
                if(j===6) registro.disparosdentro=datos[i][j];
                if(j===7) registro.atajadas=datos[i][j];
                if(j===8) registro.tarjetasamarillas=datos[i][j];
                if(j===9) registro.tarjetasrojas=datos[i][j];
                if(j===10) registro.tarjetasazules=datos[i][j];
            }

            infoEstadisticasO.push(registro);
            infoEstadisticas.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoUltimoPartidoO = [];
var infoUltimoPartido = [];
function crearInfoUltimoPartido(datos){   
    const nomFuncion="crearInfoUltimoPartido";

    try{   

        let ultPartido = {
            indice:0,
            idTorneo:"",
            nomTorneo:"",
            idPartido:"",
            numJornada:"",
            numCancha:"",
            idEquipo1:"",
            nomCortoEq1:"",
            imgEscudoEq1:"",
            idEquipo2:"",
            nomCortoEq2:"",
            imgEscudoEq2:"",
            nomArbitro1:"",
            nomArbitro2:"",
            fechaPartido:"",
            horaPartido:"",
            golesEq1:"",
            golesEq2:"",
            parte:"",
            tiempoActual:"",
            tiempoTotal:"",
            idEstado:"",
            nomEstado:"",
            fechaActual:"",
            horaActual:"",
            idJornada:"",
            estadoTiempo:"",
            fechaInicioTiempo:"",
            confirmoAsistencia:"",
            fechaConfirmo:"",
            tipoTorneo:"",
            tipoRegistroInfo:"",
            partidoActivo:"",
            idSede:"",
            idEstadoInfArbitro:"",
            imgArbitro1:"",
            calificacionPartido:"",
            penaltisEq1:"",
            penaltisEq2:"",
            numNuevoJornada:"",
            nomJornada:"",
            semFechainicial:"",
            semFechaFinal:"",
            esSemanaActual:"",
            resumenJornada:"",
            tipoJornada:"",
            nomJornada2:"",
            idsemana:"",
            numsemana:"",
            semFechainicial2:"",
            semFechaFinal2:"",
            tipoJornadaEliminacion:"",
            idcopa:"",
            nombreCopa:"",
            ordenCopa:""
        }
    
        infoUltimoPartidoO = [];
        infoUltimoPartido = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , ultPartido);
            registro.indice=i;
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idTorneo=datos[i][j];
                if(j===1) registro.nomTorneo=datos[i][j];
                if(j===2) registro.idPartido=datos[i][j];
                if(j===3) registro.numJornada=datos[i][j];
                if(j===4) registro.numCancha=datos[i][j];
                if(j===5) registro.idEquipo1=datos[i][j];
                if(j===6) registro.nomCortoEq1=datos[i][j];
                if(j===7) registro.imgEscudoEq1=datos[i][j];
                if(j===8) registro.idEquipo2=datos[i][j];
                if(j===9) registro.nomCortoEq2=datos[i][j];
                if(j===10) registro.imgEscudoEq2=datos[i][j];
                if(j===11) registro.nomArbitro1=datos[i][j];
                if(j===12) registro.nomArbitro2=datos[i][j];
                if(j===13) registro.fechaPartido=datos[i][j];
                if(j===14) registro.horaPartido=datos[i][j];
                if(j===15) registro.golesEq1=datos[i][j];
                if(j===16) registro.golesEq2=datos[i][j];
                if(j===17) registro.parte=datos[i][j];
                if(j===18) registro.tiempoActual=datos[i][j];
                if(j===19) registro.tiempoTotal=datos[i][j];
                if(j===20) registro.idEstado=datos[i][j];
                if(j===21) registro.nomEstado=datos[i][j];
                if(j===22) registro.fechaActual=datos[i][j];
                if(j===23) registro.horaActual=datos[i][j];
                if(j===24) registro.idJornada=datos[i][j];
                if(j===25) registro.estadoTiempo=datos[i][j];
                if(j===26) registro.fechaInicioTiempo=datos[i][j];
                if(j===27) registro.confirmoAsistencia=datos[i][j];
                if(j===28) registro.fechaConfirmo=datos[i][j];
                if(j===29) registro.tipoTorneo=datos[i][j];
                if(j===30) registro.tipoRegistroInfo=datos[i][j];
                if(j===31) registro.partidoActivo=datos[i][j];
                if(j===32) registro.idSede=datos[i][j];
                if(j===33) registro.idEstadoInfArbitro=datos[i][j];
                if(j===34) registro.imgArbitro1=datos[i][j];
                if(j===35) registro.calificacionPartido=datos[i][j];
                if(j===36) registro.penaltisEq1=datos[i][j];
                if(j===37) registro.penaltisEq2=datos[i][j];
                if(j===38) registro.numNuevoJornada=datos[i][j];
                if(j===39) registro.nomJornada=datos[i][j];
                if(j===40) registro.semFechainicial=datos[i][j];
                if(j===41) registro.semFechaFinal=datos[i][j];
                if(j===42) registro.esSemanaActual=datos[i][j];
                if(j===43) registro.resumenJornada=datos[i][j];
                if(j===44) registro.tipoJornada=datos[i][j];
                if(j===45) registro.nomJornada2=datos[i][j];
                if(j===46) registro.idsemana=datos[i][j];
                if(j===47) registro.numsemana=datos[i][j];
                if(j===48) registro.semFechainicial2=datos[i][j];
                if(j===49) registro.semFechaFinal2=datos[i][j];
                if(j===50) registro.tipoJornadaEliminacion=datos[i][j];
                if(j===51) registro.idcopa=datos[i][j];
                if(j===52) registro.nombreCopa=datos[i][j];
                if(j===53) registro.ordenCopa=datos[i][j];
            }

            infoUltimoPartidoO.push(registro);
            infoUltimoPartido.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoProximoPartidoO = [];
var infoProximoPartido = [];
function crearInfoProximoPartido(datos){   
    const nomFuncion="crearInfoProximoPartido";

    try{      

        let proximoPartido = {
            indice:0,
            idTorneo:"",
            nomTorneo:"",
            idPartido:"",
            numJornada:"",
            numCancha:"",
            idEquipo1:"",
            nomCortoEq1:"",
            imgEscudoEq1:"",
            idEquipo2:"",
            nomCortoEq2:"",
            imgEscudoEq2:"",
            nomArbitro1:"",
            nomArbitro2:"",
            fechaPartido:"",
            horaPartido:"",
            golesEq1:"",
            golesEq2:"",
            parte:"",
            tiempoActual:"",
            tiempoTotal:"",
            idEstado:"",
            nomEstado:"",
            fechaActual:"",
            horaActual:"",
            idJornada:"",
            estadoTiempo:"",
            fechaInicioTiempo:"",
            confirmoAsistencia:"",
            fechaConfirmo:"",
            tipoTorneo:"",
            tipoRegistroInfo:"",
            partidoActivo:"",
            idSede:"",
            idEstadoInfArbitro:"",
            imgArbitro1:"",
            calificacionPartido:"",
            penaltisEq1:"",
            penaltisEq2:"",
            numNuevoJornada:"",
            nomJornada:"",
            idcancha:"",
            nombreCancha:"",
            ubicacionCancha:"",
            resumenJornada:"",
            tipoJornada:"",
            nomJornada2:"",
            idsemana:"",
            numsemana:"",
            semFechainicial2:"",
            semFechaFinal2:"",
            tipoJornadaEliminacion:"",
            idcopa:"",
            nombreCopa:"",
            ordenCopa:""
        }
    
        infoProximoPartidoO = [];
        infoProximoPartido = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , proximoPartido);
            registro.indice=i;
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idTorneo=datos[i][j];
                if(j===1) registro.nomTorneo=datos[i][j];
                if(j===2) registro.idPartido=datos[i][j];
                if(j===3) registro.numJornada=datos[i][j];
                if(j===4) registro.numCancha=datos[i][j];
                if(j===5) registro.idEquipo1=datos[i][j];
                if(j===6) registro.nomCortoEq1=datos[i][j];
                if(j===7) registro.imgEscudoEq1=datos[i][j];
                if(j===8) registro.idEquipo2=datos[i][j];
                if(j===9) registro.nomCortoEq2=datos[i][j];
                if(j===10) registro.imgEscudoEq2=datos[i][j];
                if(j===11) registro.nomArbitro1=datos[i][j];
                if(j===12) registro.nomArbitro2=datos[i][j];
                if(j===13) registro.fechaPartido=datos[i][j];
                if(j===14) registro.horaPartido=datos[i][j];
                if(j===15) registro.golesEq1=datos[i][j];
                if(j===16) registro.golesEq2=datos[i][j];
                if(j===17) registro.parte=datos[i][j];
                if(j===18) registro.tiempoActual=datos[i][j];
                if(j===19) registro.tiempoTotal=datos[i][j];
                if(j===20) registro.idEstado=datos[i][j];
                if(j===21) registro.nomEstado=datos[i][j];
                if(j===22) registro.fechaActual=datos[i][j];
                if(j===23) registro.horaActual=datos[i][j];
                if(j===24) registro.idJornada=datos[i][j];
                if(j===25) registro.estadoTiempo=datos[i][j];
                if(j===26) registro.fechaInicioTiempo=datos[i][j];
                if(j===27) registro.confirmoAsistencia=datos[i][j];
                if(j===28) registro.fechaConfirmo=datos[i][j];
                if(j===29) registro.tipoTorneo=datos[i][j];
                if(j===30) registro.tipoRegistroInfo=datos[i][j];
                if(j===31) registro.partidoActivo=datos[i][j];
                if(j===32) registro.idSede=datos[i][j];
                if(j===33) registro.idEstadoInfArbitro=datos[i][j];
                if(j===34) registro.imgArbitro1=datos[i][j];
                if(j===35) registro.calificacionPartido=datos[i][j];
                if(j===36) registro.penaltisEq1=datos[i][j];
                if(j===37) registro.penaltisEq2=datos[i][j];
                if(j===38) registro.numNuevoJornada=datos[i][j];
                if(j===39) registro.nomJornada=datos[i][j];
                if(j===40) registro.idcancha=datos[i][j];
                if(j===41) registro.nombreCancha=datos[i][j];
                if(j===42) registro.ubicacionCancha=datos[i][j];
                if(j===43) registro.resumenJornada=datos[i][j];
                if(j===44) registro.tipoJornada=datos[i][j];
                if(j===45) registro.nomJornada2=datos[i][j];
                if(j===46) registro.idsemana=datos[i][j];
                if(j===47) registro.numsemana=datos[i][j];
                if(j===48) registro.semFechainicial2=datos[i][j];
                if(j===49) registro.semFechaFinal2=datos[i][j];
                if(j===50) registro.tipoJornadaEliminacion=datos[i][j];
                if(j===51) registro.idcopa=datos[i][j];
                if(j===52) registro.nombreCopa=datos[i][j];
                if(j===53) registro.ordenCopa=datos[i][j];
            }

            infoProximoPartidoO.push(registro);
            infoProximoPartido.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoSancionesO = [];
var infoSanciones = [];
function crearInfoSanciones(datos){   
    const nomFuncion="crearInfoSanciones";

    try{
        let sancion = {
            idtorneo:"",
            idpartido:"",
            tipoSancion:"",
            nomSancion:"",
            totalpartidos:"",
            valSancion:"",
            txtsancion:"",
            fecha_sancion:"",
            escudoEq1:"",
            nomEquipo1:"",
            escudoEq2:"",
            nomEquipo2:"",
            jornada:"",
            fechaPartido:"",
            horaPartido:""
        }
    
        infoSancionesO = [];
        infoSanciones = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , sancion);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idpartido=datos[i][j];
                if(j===2) registro.tipoSancion=datos[i][j];
                if(j===3) registro.nomSancion=datos[i][j];
                if(j===4) registro.totalpartidos=datos[i][j];
                if(j===5) registro.valSancion=datos[i][j];
                if(j===6) registro.txtsancion=datos[i][j];
                if(j===7) registro.fecha_sancion=datos[i][j];
                if(j===8) registro.escudoEq1=datos[i][j];
                if(j===9) registro.nomEquipo1=datos[i][j];
                if(j===10) registro.escudoEq2=datos[i][j];
                if(j===11) registro.nomEquipo2=datos[i][j];
                if(j===12) registro.jornada=datos[i][j];
                if(j===13) registro.fechaPartido=datos[i][j];
                if(j===14) registro.horaPartido=datos[i][j];
            }

            infoSancionesO.push(registro);
            infoSanciones.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoCobrosO = [];
var infoCobros = [];
function crearInfoCobros(datos){   
    const nomFuncion="crearInfoCobros";

    try{
        let cobro = {
            idtorneo:"",
            idjugador:"",
            tipocobro:"",
            tipoevento:"",
            valor_total:"",
            valor_pagado:"",
            cantidad:""
        }
    
        infoCobrosO = [];
        infoCobros = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , cobro);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idjugador=datos[i][j];
                if(j===2) registro.tipocobro=datos[i][j];
                if(j===3) registro.tipoevento=datos[i][j];
                if(j===4) registro.valor_total=datos[i][j];
                if(j===5) registro.valor_pagado=datos[i][j];
                if(j===6) registro.cantidad=datos[i][j]; 
            }

            infoCobrosO.push(registro);
            infoCobros.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}


var configuracionSliders = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: false,
    initialSlide: 0,
  };



const Principal_Jugador = ({params="", definirModulo}) => {
    const [esError, setEsError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);

    const [arrCampos, setArrCampos] = useState(matrizCampos);  

    const [isOpenMenuFlotante, openMenuFlotante, closeMenuFlotante] = useModal(false);
    const [isOpenMenuLateral, openMenuLateral, closeMenuLateral] = useModal(false);
    const [isOpenCalificar, openCalificar, closeCalificar] = useModal(false);
    const [isOpenContactoLiga, openContactoLiga, closeContactoLiga] = useModal(false);

    const [clickAceptarModal, setClickAceptarModal] = useState(false);
    const [msjModal, setMsjModal] = useState("");

    const [sliderUltPartidos, setSliderUltPartidos] = useState(configuracionSliders);
    const [sliderPrxPartidos, setSliderPrxPartidos] = useState(configuracionSliders);

    const [esAdminEquipoUS, setEsAdminEquipoUS] = useState(false);
    const [tieneAccesoCobros, setTieneAccesoCobros] = useState(false);
    const [torneoSel, setTorneoSel] = useState(0);
    const [selTorneos, setSelTorneos] = useState([]);
    const [selJugador, setSelJugador] = useState([]);
    const [sancionesJugador, setSancionesJugador] = useState([]);
    const [cobrosJugador, setCobrosJugador] = useState([]);

    const [ultPartidos, setUltPartidos] = useState([]);
    const [proxPartidos, setProxPartidos] = useState([]);

    const [calificaciones, setCalificaciones] = useState([0,0,0,0,0,0,0]);

    const [datosMDContacto, setDatosMDContacto] = useState([]);
    const [acepto, setAcepto] = useState(false);


    const [showContactoMG, setShowContactoMG] = useState(false);

    let sliderUlt = useRef(null);
    let sliderProx = useRef(null);

     
    

    
    useEffect(() => {
        const nomFuncion="useEffect";

        try{ 

            idUser=recuperarDatoLocal("idUser");
            tipoUser=recuperarDatoLocal("tipoUser");
            let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);
            

              
             
            paramsAjustado=params;  

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,"#",2);

                if(ultParametros!==info[0]) {
                    actualizar=true;
                    guardarDatoLocal("ultParametros" + idModulo,info[0]);
                }               
                

                if(info[0]!=="" && info[0]!==idUser){
                    actualizar=true;
                    idUser=info[0];
                }  

                if(info[1]!=="" && info[1]!==idTorneo){
                    //actualizar=true;
                    idTorneo=info[1];
                } 

                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                }

                //idUser=recuperarDatoLocal("idUser");
                

                let tieneRoles=recuperarDatoLocal("tieneRoles");
                if(tieneRoles==="1"){
                    infoUsuarioO=recuperarDatoLocal("infoUsuario");                   
                }
                
                paramsBase=paramsAjustado;
    
                if(actualizar){                   
                    BD_solicitarDatos("INFO_INICIAL",[]);
                }
                else{
                    presentarLog(nomClase + " - RECUPERA_DATOS"); 
                    let datosModulo=recuperarDatoLocal("datos" + idModulo);

                    if(datosModulo!==""){
                        crearInfoJugador(crearTabla(datosModulo,"1"));
                        crearInfoClasificacion(crearTabla(datosModulo,"2"));
                        crearInfoEstadisticas(crearTabla(datosModulo,"3"));
                        crearInfoUltimoPartido(crearTabla(datosModulo,"4"));
                        crearInfoProximoPartido(crearTabla(datosModulo,"5"));  
                        crearInfoSanciones(crearTabla(datosModulo,"6"));   
                        crearInfoCobros(crearTabla(datosModulo,"7"));  

                        guardarDatoLocal("idUser",idUser);
                        guardarDatoLocal("tipoUser",tipoUser);
                        //guardarDatoLocal("idSede",idSede);
                        //guardarDatoLocal("idAsociado",idJugador);

                        cargarListaTorneos();

                    } else BD_solicitarDatos("INFO_INICIAL",[]);
                            
                } 
                
            }
    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [params]);


    if(params==="") return null;

    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }


    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let separador=SEPARADOR_PARAMETROS_BD;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){
                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleJugador";
                    consulta="INFO_PANELCONTROL_JUGADOR";
                    
                    idUser=recuperarDatoLocal("idUser");
                    paramQuery= idUser;
                } else if(tipoDatos==="ASISTIR_PARTIDO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarJugador";
                    consulta="ACEPTAR_ASISTIR_PARTIDO";
                    mostrarProcesando=false;
                    
                    let id_partido=infoProximoPartidoO[params[0]].idPartido;
                    let id_equipo=idEquipo;
                    let id_jugador=idJugador;
                    let txtAcepto=params[1];

                    paramQuery= id_partido + separador + id_equipo + separador + id_jugador + separador + txtAcepto;
                } else if(tipoDatos==="CALIFICAR_PARTIDO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarJugador";
                    consulta="CALIFICAR_PARTIDO";
                    mostrarProcesando=false;
                    
                    let id_partido=ultPartidos[ultPartidoSel].idPartido;
                    let id_jugador=idJugador;
                    let calific=calificaciones[6] + separador + calificaciones[0] + separador + calificaciones[1] + separador + calificaciones[2] + separador + calificaciones[3] + separador + calificaciones[4];

                    paramQuery=  id_partido + separador + id_jugador + separador + calific;
                } else if(tipoDatos==="ENVIAR_MENSAJE_CONTACTO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="ENVIAR_MENSAJE_CONTACTO";
                    mostrarProcesando=false;
                    
                    /*let idSede=recuperarDatoLocal("idSede");
                    if(valorNumero(idSede)<=0) idSede="-1";
                    let nombre= formatearTexto(quitarCharEspeciales(arrCampos[0].valor),2);
                    let correo=arrCampos[1].valor.toLowerCase();
                    let txtPais=paises[posPaisSel].label;
                    let indicativo=paises[posPaisSel].value;
                    let telefono=arrCampos[3].valor;
                    let txtAsunto=asuntos[posAsuntoSel].label;
                    let mensaje=quitarCharEspeciales(arrCampos[5].valor);

                    paramQuery=  idSede + SEP + idUser + SEP + tipoUser + SEP + nombre + SEP + correo + SEP + txtPais + SEP + indicativo + SEP + telefono + SEP + txtAsunto + SEP + mensaje;
                    */

                    paramQuery=params;
                }      
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    respuesta=res;
                    if (respuesta.exito) {
                        if(tipoDatos==="INFO_INICIAL"){
                            presentarLog(nomClase + " - SOLICITA_DATOS"); 

                            guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                            guardarDatoLocal("datos" + idModulo, respuesta.datos);

                            crearInfoJugador(crearTabla(respuesta.datos,"1"));
                            crearInfoClasificacion(crearTabla(respuesta.datos,"2"));
                            crearInfoEstadisticas(crearTabla(respuesta.datos,"3"));
                            crearInfoUltimoPartido(crearTabla(respuesta.datos,"4"));
                            crearInfoProximoPartido(crearTabla(respuesta.datos,"5"));  
                            crearInfoSanciones(crearTabla(respuesta.datos,"6"));   
                            crearInfoCobros(crearTabla(respuesta.datos,"7"));  

                            guardarDatoLocal("infoTorneos",JSON.stringify(infoTorneos));


                            guardarDatoLocal("idUser",idUser);
                            guardarDatoLocal("tipoUser",tipoUser);
                            //guardarDatoLocal("idSede",idSede);
                            //guardarDatoLocal("idAsociado",idJugador);

                            cargarListaTorneos();                            

                            registrarLogEventos(idModulo, "1", idJugador,"1" );
                        } else if(tipoDatos==="ASISTIR_PARTIDO"){   
                            infoProximoPartido[params[0]][27]=params[1];
                            infoProximoPartido[params[0]][28]="1";
                            infoProximoPartidoO[params[0]].confirmoAsistencia=params[1];
                            infoProximoPartidoO[params[0]].fechaConfirmo="1";
                            reconstruirListasModulo();
                            //setFlagUpdate(true);                        
                            ventanaMensaje=showMensajePantalla("","Confirmación de si asistirás o no realizada con exito.",setShowMensaje,null,"EXITO","BAJA");
                        } else if(tipoDatos==="CALIFICAR_PARTIDO"){   
                            let indice=ultPartidos[ultPartidoSel].indice;
                            infoUltimoPartido[indice][35]="" + calificaciones[6];   
                            infoUltimoPartidoO[indice].calificacionPartido ="" + calificaciones[6];                      
                            reconstruirListasModulo();
                                                  
                            ventanaMensaje=showMensajePantalla("","Calificación de partido realizada con exito.",setShowMensaje,null,"EXITO","BAJA");
                        } else if(tipoDatos==="ENVIAR_MENSAJE_CONTACTO"){
                            ventanaMensaje=showMensajePantalla("MENSAJE RECIBIDO","Gracias por comunicarte con MarcaGol. Muy pronto nos contactaremos contigo.",setShowMensaje,null,"EXITO","MEDIA");

                            try{
                                let retorno=dividirListaNueva(params,SEPARADOR_PARAMETROS_BD);

                                let nombre=retorno[3];
                                let telefono=retorno[7];

                                //let nombre=formatearTexto(quitarCharEspeciales(arrCampos[0].valor),2);
                                //let telefono=arrCampos[3].valor;
                                let msj="Nombre:" + nombre.replaceAll(" ","") + "Telefono:" + telefono.replaceAll(" ","");
                                let datos=[msj];
                                callUrl().get("CORREO_CONTACTO", datos);
                            }catch(e){}

                        }                                                    
        
                    } else {
                        if(respuesta.conectado){
                            reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                            if(mostrarError) setEsError(1);
                        } 
                        else{    
                            presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                            setEsError(2);
                        }                        
                    }
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    }  


    const cargarListaTorneos = () => {
        let nomFuncion="cargarListaTorneos"; 

        try{

            const lista ={
                index:0,
                value:"",
                label:"",
                texto2:"",
                imagen:""
              }  

            let tempTorneos=[];
            let seleccionar=0;   
            let contador=0;
            
            if(infoTorneos.length>0){
                let textoSP1="";
                let textoSP2="";
                let torneoNotificacion=false;    
                         

                let i;
                for(i=0;i<infoTorneos.length;i++){
                    textoSP1="";
                    textoSP2="";

                    let registro=Object.assign({} , lista);

                    if(idTorneoNotificacion!==""){
                        if(infoTorneos[i][0]===idTorneoNotificacion){
                            seleccionar=i;
                            torneoNotificacion=true;
                        }
                    }
                    else if(idTorneo!==""){
                        if(infoTorneos[i][0]===idTorneo && !torneoNotificacion) seleccionar=i;
                    }
                    else if(idEquipo!==""){
                        if(infoTorneos[i][2]===idEquipo && !torneoNotificacion) seleccionar=i;
                    }

                    let sede="";

                    if(hayVariasSedes){
                        let j;
                        for(j=0;j<infoJugador.length;j++){
                            if(infoTorneos[i][6]===infoJugador[j][0]){
                                sede = "Sede: " + infoJugador[j][3] + "\n";
                                j=infoJugador.length;
                            }
                        }
                    }

                    textoSP1= infoTorneos[i][1];
                    textoSP2= sede + "Equipo. " + infoTorneos[i][3];

                    let imagen=ic_torneo_gris_w;
                    if(infoTorneos[i][7]!=="") imagen = ruta + infoTorneos[i][7];

                    registro.index=contador;
                    registro.value=infoTorneos[i][0];
                    registro.label=textoSP1;
                    registro.texto2=textoSP2;
                    registro.imagen=imagen;

                    tempTorneos.push(registro);
                    contador++;
                }

                let indicativo= infoTorneos[seleccionar][13];
                guardarDatoLocal("indicativo",indicativo);

            }
            else{
                let registro=Object.assign({} , lista);
                registro.index=contador;
                registro.value="-1";
                registro.label="Sin Torneos Activos";
                registro.texto2="";
                registro.imagen=ic_torneo_gris_w;

                tempTorneos.push(registro);
                contador++;
            }
            
            numTorneoSel=seleccionar;
            setTorneoSel(seleccionar);
            setSelTorneos(tempTorneos);

            cambiarTorneo(null);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const reconstruirListasModulo = () => {
        let nomFuncion="reconstruirListasModulo"; 

        try{ 
            
            let datosFinales=[];
            datosFinales=reconstruirDatosOrigen(datosFinales,infoJugador,"1");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoClasificacion,"2");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoEstadisticas,"3");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoUltimoPartido,"4");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoProximoPartido,"5");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoSanciones,"6");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoCobros,"7");
            
            guardarDatoLocal("datos" + idModulo, datosFinales);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 


    const clickGenerico = (donde, params=null) => {
        let nomFuncion="clickGenerico"; 

        try{
            let titulo="";
            let mensaje="";
            //let params="";

            let tempArreglo;
            
            switch(donde) {
                case "CERRAR_SESION":
                    guardarDatoLocal("password","");   
                    definirModulo(0, 0, "", ID_MODULO_BASE, ""); 
                     
                    break;
                case "BTN_ESPECTADORES":
                    definirModulo(0,idModulo,paramsAjustado,ID_MODULO_PRINCIPAL_INVITADO,"");   
                     
                    break;                
                case "CALIFICAR_PARTIDO":
                    fotoArbcalificacion=ic_jugadorsinimagen_gris;
                    nomArbCalificacion="";
                    ultPartidoSel=-1;
                    if(params>=0){
                        if(ultPartidos[params].imgArbitro1!=="") fotoArbcalificacion=ruta + ultPartidos[params].imgArbitro1;
                        if(ultPartidos[params].nomArbitro1!=="") nomArbCalificacion= ultPartidos[params].nomArbitro1;
                        ultPartidoSel=params;
                    }
                    
                    setCalificaciones([0,0,0,0,0,0,0]);
                    setMsjModal("");
                    setClickAceptarModal(false);
                    openCalificar();
                         
                    break;
                case "CLICK_STAR":
                    let tempCalificaciones=JSON.parse(JSON.stringify(calificaciones));
                    if(params[0]==="APLI_REGLAMENTO") tempCalificaciones[0]=params[1];
                    else if(params[0]==="UBI_JUGADAS") tempCalificaciones[1]=params[1];
                    else if(params[0]==="MANEJO_PARTIDO") tempCalificaciones[2]=params[1];
                    else if(params[0]==="ESTADO_CANCHA") tempCalificaciones[3]=params[1];
                    else if(params[0]==="INSTALACIONES") tempCalificaciones[4]=params[1];

                    //promedio de calificacion al arbitro
                    tempCalificaciones[5]=(tempCalificaciones[0]+tempCalificaciones[1]+tempCalificaciones[2])/3;

                    //promedio de calificacion al arbitro
                    tempCalificaciones[6]=(tempCalificaciones[5]+tempCalificaciones[3]+tempCalificaciones[4])/3;

                    setCalificaciones(tempCalificaciones);

                    if(tempCalificaciones[0]===0 || tempCalificaciones[1]===0 || tempCalificaciones[2]===0 || tempCalificaciones[3]===0 || tempCalificaciones[4]===0 )
                        setMsjModal("Es necesario calificar todos los items evaluados");
                    else setMsjModal("");

                    break;
                case "ACEPTAR_CALIFICAR":
                    setClickAceptarModal(true);

                    if(calificaciones[0]===0 || calificaciones[1]===0 || calificaciones[2]===0 || calificaciones[3]===0 || calificaciones[4]===0 )
                        setMsjModal("Es necesario calificar todos los items evaluados");
                    else{
                        if(ultPartidoSel>=0) BD_solicitarDatos("CALIFICAR_PARTIDO");     
                        
                        closeCalificar();
                    }

                    break;
                case "PARTIDO_NOCALIFICABLE":
                    titulo="Partido NO CALIFICABLE";
                    mensaje="Unicamente los jugadores que quedaron registrados que asistieron al partido podrán calificarlo.\n\nNo hay registro de tu participación en este partido, por lo cual no puedes calificarlo.";
                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");

                    break;
                case "PARTIDO_YACALIFICADO":
                    titulo="Partido YA CALIFICADO";
                    mensaje="Este Partido ya cuenta con una calificación asignada por usted, por lo que no es posible modificarla.";
                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");

                    break;
                case "ACTUALIZAR_DATOS":
                    BD_solicitarDatos("INFO_INICIAL",[]);

                    break;
                case "MOSTRAR_CANCHA":
                    //BD_solicitarDatos("MOSTRAR_CANCHA",[]);
                    titulo="FALTA PROGRAMAR";
                    mensaje="Generar modal para presentar mapa con la ubicación de la cancha";
                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA");
    
                    break;
                case "ASISTIR_PARTIDO":
                    BD_solicitarDatos("ASISTIR_PARTIDO",params);

                    break;
                case "CLICK_VER_TORNEO":
                    if(idTorneo!=="") definirModulo(0,idModulo,paramsAjustado,ID_MODULO_DETALLE_TORNEO,idTorneo);   

                    break;
                case "CLICK_VER_JORNADAULT":
                    params=setParametros(5,[idTorneo,"3","jornada","",params.idJornada])
                    if(idTorneo!=="") definirModulo(0,idModulo,paramsAjustado,ID_MODULO_DETALLE_TORNEO,params);   

                    break;
                case "CLICK_VER_EQUIPO":
                    params=setParametros(2,[idEquipo,"1"])
                    if(idEquipo!=="") definirModulo(0,idModulo,paramsAjustado,ID_MODULO_DETALLE_EQUIPO,params);   

                    break;
                case "CLICK_VER_JUGADOR":
                    if(idJugador!=="" && idEquipo!=="" && idTorneo!==""){
                        let parametros=idJugador + "#1#" + idEquipo + "#" + idTorneo;
                        definirModulo(0, idModulo, paramsAjustado, ID_MODULO_DETALLE_JUGADOR, parametros); 
                    }
                    
                    break;
                case "CLICK_MENU_MIEQUIPO":
                    if(idEquipo!=="" && idTorneo!==""){
                        let parametros=idTorneo + "#" + idEquipo;
                        definirModulo(0, idModulo, paramsAjustado, ID_MODULO_INFO_EQUIPO, parametros); 
                    }

                    break;
                case "CLICK_MENU_MIINFORMACION":
                    if(idUser!==""){
                        let parametros=idUser;
                        definirModulo(0, idModulo, paramsAjustado, ID_MODULO_INFO_JUGADOR, parametros); 
                    }

                    break;
                case "CLICK_MENU_MICUENTA":
                    if(idUser!==""){
                        let parametros=idUser;
                        definirModulo(0, idModulo, paramsAjustado, ID_MODULO_GESTION_MICUENTA, parametros); 
                    }

                    break;
                case "CLICK_MENU_COBROS_MIEQUIPO":
                    //ventanaMensaje=showMensajePantalla("EN CONSTRUCCIÓN","Módulo actualmente NO se encuentra habilitado para la versión web de MarcaGol. Será habilitado en los próximos dias.",setShowMensaje,null,"INFO","MEDIA");
                    if(idTorneo!==""){
                        let parametros=idTorneo + SEP_4 + idEquipo;
                        definirModulo(0, idModulo, paramsAjustado, ID_MODULO_INFO_COBROS, parametros); 
                    }

                    break;
                case "CLICK_MENU_MISCOBROS":
                    //ventanaMensaje=showMensajePantalla("EN CONSTRUCCIÓN","Módulo actualmente NO se encuentra habilitado para la versión web de MarcaGol. Será habilitado en los próximos dias.",setShowMensaje,null,"INFO","MEDIA");
                    if(idTorneo!==""){
                        let parametros=idTorneo + SEP_4 + idJugador;
                        definirModulo(0, idModulo, paramsAjustado, ID_MODULO_INFO_COBROS_JUGADOR, parametros); 
                    }

                    break;
                case "CLICK_LLAMADA_TELEFONICA":
                    if(infoTorneos[numTorneoSel][11].trim()!==""){
                        let ind=recuperarDatoLocal("indicativo");
                        let tel=infoTorneos[numTorneoSel][11];
                        if(ind!=="" && tel.length>7) openInNewTab("tel:+" + ind + tel); 
                    }
                    else{
                        ventanaMensaje=showMensajePantalla("SIN INFORMACIÓN","El Organizador del Torneo no tiene definido un número de teléfono de contacto.",setShowMensaje,null,"INFO","BAJA");
                    }
                    
                    break;
                case "CLICK_ABRIR_WHATSAPP":
                    if(infoTorneos[numTorneoSel][11].trim()!==""){
                        let ind2=recuperarDatoLocal("indicativo");
                        let tel2=infoTorneos[numTorneoSel][11];
                        if(ind2!=="" && tel2.length>7) openInNewTab("https://wa.me/" + ind2 + tel2);  
                    }
                    else{
                        ventanaMensaje=showMensajePantalla("SIN INFORMACIÓN","El Organizador del Torneo no tiene definido un número de teléfono de contacto.",setShowMensaje,null,"INFO","BAJA");
                    }
                    
                    break;
                case "CLICK_ENVIAR_EMAIL":
                    if(infoTorneos[numTorneoSel][12].trim()!==""){
                        let correo=infoTorneos[numTorneoSel][12];
                        if(correo!=="" && esformatoEmail(correo)) openInNewTab("mailto:" + correo);
                    }
                    else{
                        ventanaMensaje=showMensajePantalla("SIN INFORMACIÓN","El Organizador del Torneo no tiene definido un email de contacto.",setShowMensaje,null,"INFO","BAJA");
                    }  
                    
                    break;
                case "ABRIR_MODAL_CONTACTOMG":
                    setShowContactoMG(true);

                    break;
                case "ABRIR_MODAL_CONTACTO_LIGA":
                    if(idTorneo!=="") openContactoLiga();
                    else ventanaMensaje=showMensajePantalla("SIN TORNEOS ACTIVOS","No tiene ningún torneo activo con el cual pueda realizar contacto.",setShowMensaje,null,"INFO","BAJA");
    
                    break;
                case "ENVIAR_MENSAJE_CONTACTO":
                    BD_solicitarDatos("ENVIAR_MENSAJE_CONTACTO", params);


                    break;
                default:
                    break;
              }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 


    const salir = () => {       
        definirModulo(0, 0, "", ID_MODULO_BASE, "");
    }

    const buscarUltimosPartidos = () => {
        let nomFuncion="buscarUltimosPartidos"; 

        try{    
            let numMaximo=10;
            let i=0;
            let datos=[];
            for(i=0;i<infoUltimoPartidoO.length;i++){
                if(infoUltimoPartidoO[i].idTorneo===idTorneo && (infoUltimoPartidoO[i].idEquipo1===idEquipo || infoUltimoPartidoO[i].idEquipo2===idEquipo) ){                     
                    datos.push(infoUltimoPartidoO[i]);   
                    numMaximo--;
                    if(numMaximo<0) i=infoUltimoPartidoO.length;                   
                }
            }

            setUltPartidos(datos);
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const buscarProximosPartidos = () => {
        let nomFuncion="buscarProximosPartidos"; 

        try{      
            let numMaximo=10;
            let i=0;
            let datos=[];
            let data=[];
            for(i=0;i<infoProximoPartidoO.length;i++){
                if(infoProximoPartidoO[i].idTorneo===idTorneo && (infoProximoPartidoO[i].idEquipo1===idEquipo || infoProximoPartidoO[i].idEquipo2===idEquipo) ){                     
                    datos.push(infoProximoPartidoO[i]);   
                    numMaximo--;
                    if(numMaximo<0) i=infoProximoPartidoO.length;                   
                }
            }

            setProxPartidos(datos);            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const buscarDatosJugador = (idJug) => {
        let nomFuncion="buscarDatosJugador"; 

        try{
            let jugador = {
                idjugador:"",
                nombre:"",
                foto:"",
                posicion:"",
                pjugados:"",
                goles:"",
                autogoles:"",
                asistencias:"",
                disparosdentro:"",
                atajadas:"",
                tarjetasamarillas:"",
                tarjetasrojas:"",
                tarjetasazules:""
            }

            let i=0;
            let registro=Object.assign({} , jugador);
            let datos=[];

            if(infoEstadisticasO.length>0){
                for(i=0;i<infoEstadisticasO.length;i++){
                    if(infoEstadisticasO[i].idjugador===idJug){
                        registro.idjugador=idJug;
                        registro.nombre=nombreUser;
                        registro.foto=fotoJugador;
                        registro.posicion=posicionJugador;
    
                        registro.pjugados=infoEstadisticasO[i].pjugados;
                        registro.goles=infoEstadisticasO[i].goles;
                        registro.autogoles="0";
                        registro.asistencias=infoEstadisticasO[i].asistencias;
                        registro.disparosdentro=infoEstadisticasO[i].disparosdentro;
                        registro.atajadas=infoEstadisticasO[i].atajadas;
                        registro.tarjetasamarillas=infoEstadisticasO[i].tarjetasamarillas;
                        registro.tarjetasrojas=infoEstadisticasO[i].tarjetasrojas;
                        registro.tarjetasazules=infoEstadisticasO[i].tarjetasazules; 
    
                        datos.push(registro);
                        setSelJugador(datos);
    
                        i=infoEstadisticasO.datos;
                    }
                }
            }

            if(datos.length===0){
                if(infoJugador.length>0){
                    registro.idjugador=idJug;
                    registro.nombre=nombreUser;
                    registro.foto=fotoJugador;
                    registro.posicion="";

                    datos.push(registro);
                    setSelJugador(datos);
                }
            }
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const buscarSancionesJugador = () => {
        let nomFuncion="buscarSancionesJugador"; 

        try{
            let sancion = {
                idtorneo:"",
                idpartido:"",
                tipoSancion:"",
                nomSancion:"",
                totalpartidos:"",
                valSancion:"",
                txtsancion:"",
                fecha_sancion:"",
                escudoEq1:"",
                nomEquipo1:"",
                escudoEq2:"",
                nomEquipo2:"",
                jornada:"",
                fechaPartido:"",
                horaPartido:""
            }

            let i=0;
            let registro=Object.assign({} , sancion);
            let datos=[];
            for(i=0;i<infoSancionesO.length;i++){
                if(infoSancionesO[i].idtorneo===idTorneo){ 
                    registro=infoSancionesO[i];
                    datos.push(registro);                    
                }
            }

            setSancionesJugador(datos);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const buscarCobrosJugador = () => {
        let nomFuncion="buscarCobrosJugador"; 

        try{      
            let cobro = {
                idtorneo:"",
                idjugador:"",
                tipocobro:"",
                tipoevento:"",
                valor_total:"",
                valor_pagado:"",
                cantidad:""
            }

            let i=0;
            let registro=Object.assign({} , cobro);
            let datos=[];
            for(i=0;i<infoCobrosO.length;i++){
                if(infoCobrosO[i].idtorneo===idTorneo){                     
                    registro=infoCobrosO[i];
                    datos.push(registro);                     
                }
            }

            setCobrosJugador(datos);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const cambiarTorneo = (selectedOption) => {
        let nomFuncion="cambiarTorneo"; 

        try{
            try{ if(sliderUlt!==null && sliderUlt.current!==null)  sliderUlt.slickGoTo(0); }catch(err){}
            try{ if(sliderProx!==null && sliderProx.current!==null)  sliderProx.slickGoTo(0); }catch(err){}

            let tempDatosMDContacto=[];
            setTieneAccesoCobros(false);

            
            let position=0;
            let valor="";

            if(infoTorneos.length===0) valor="-1";
            
            if(selectedOption!==null) {
                position=selectedOption.index;
                valor=selectedOption.value;

                numTorneoSel=position;
                setTorneoSel(position);
            }
            else{
                position=numTorneoSel;
                //valor=selTorneos[position];
            }

            if(valor!=="-1"){                
                
                if(infoTorneos[position][4]==="1"){
                    esAdminEquipo=true;
                    setEsAdminEquipoUS(true);
                    setTieneAccesoCobros(true);
                }
                else{
                    esAdminEquipo=false;
                    setEsAdminEquipoUS(false);
                    if(infoTorneos[position][8]!=="1"){
                        setTieneAccesoCobros(true);
                    }
                }
               

                    idTorneo = infoTorneos[position][0];
                    idEquipo = infoTorneos[position][2];
                    posicionJugador=infoTorneos[position][5];
                    idJugador= infoTorneos[position][6];
                    tipoPermisoCobros= infoTorneos[position][8];
                    indicativo= infoTorneos[position][13];
                    tipo_registroinfo= infoTorneos[position][14];
                    tieneTarjetaAzul= infoTorneos[position][15];

                    paramsAjustado=idUser + "#" + idTorneo;
                    paramsAjustado=setParametros(2,[idUser,idTorneo]);

                    guardarDatoLocal("indicativo",indicativo);

                    if(hayVariasSedes){
                        let i;
                        if(infoJugador.length>0){
                            for(i=0;i<infoJugador.length;i++){
                                if(idJugador===infoJugador[i][0]){

                                    idSede = infoJugador[i][2];
                                    nomSede = infoJugador[i][3];
                                    nomEmpresa = infoJugador[i][5];
                                    nombreUser = infoJugador[i][1];
                                    fotoJugador = infoJugador[i][9];

                                    if (infoJugador[i][6]==="0") esPrimerIngreso = true;
                                    else esPrimerIngreso = false;
                                    if (infoJugador[i][7]==="1") esSedePilotos = true;
                                    else esSedePilotos = false;

                                    i=infoJugador.length;
                                }
                            }
                        }

                    }
                    else{
                        if(infoJugador.length>0){
                            idSede = infoJugador[0][2];
                            nomSede = infoJugador[0][3];
                            nomEmpresa = infoJugador[0][5];
                            nombreUser = infoJugador[0][1];
                            fotoJugador = infoJugador[0][9];
                            idJugador = infoJugador[0][0];

                            if (infoJugador[0][6]==="0") esPrimerIngreso = true;
                            else esPrimerIngreso = false;
                            if (infoJugador[0][7]==="1") esSedePilotos = true;
                            else esSedePilotos = false;
                        }

                    }
                    

                    guardarDatoLocal("idSede",idSede);
                    guardarDatoLocal("nomSede",nomSede);
                    guardarDatoLocal("nomEmpresa",nomEmpresa);
                    guardarDatoLocal("nomUsuario",nombreUser);
                    guardarDatoLocal("fotoUsuario",fotoJugador);
                    guardarDatoLocal("idAsociado",idJugador);

                    buscarUltimosPartidos();
                    buscarProximosPartidos();
                    buscarDatosJugador(idJugador);
                    buscarSancionesJugador();
                    buscarCobrosJugador();


                    //INFORMACIÓN MODULO DE CONTACTO
                    let tmpEscudo=ic_torneo_gris_w;
                    if(infoTorneos[position][7]!=="") tmpEscudo=ruta + infoTorneos[position][7];
                    let tmpNombre=infoTorneos[position][1];
                    let tmpTxtSec="Empresa: " + nomEmpresa +"\n" +
                        "Sede: " + nomSede;
                    
                    let tmpTelefono="Sin Asignar";
                    if(infoTorneos[position][11].trim()!=="") tmpTelefono=infoTorneos[position][11].trim();

                    let tmpCorreo="Sin Asignar";
                    if(infoTorneos[position][12].trim()!=="") tmpCorreo=infoTorneos[position][12].trim();

                    tempDatosMDContacto=[tmpEscudo,tmpNombre,tmpTxtSec,tmpTelefono,tmpCorreo];

                    setDatosMDContacto(tempDatosMDContacto);


                    
                    
            }
            else {
                idTorneo="";
                idEquipo="";
                

                if(infoJugador.length>0){
                    idSede = infoJugador[0][2];
                    nomSede = infoJugador[0][3];
                    nomEmpresa = infoJugador[0][5];
                    nombreUser = infoJugador[0][1];
                    fotoJugador = infoJugador[0][9];
                    idJugador = infoJugador[0][0];

                    guardarDatoLocal("idSede",idSede);
                    guardarDatoLocal("nomSede",nomSede);
                    guardarDatoLocal("nomEmpresa",nomEmpresa);
                    guardarDatoLocal("nomUsuario",nombreUser);
                    guardarDatoLocal("fotoUsuario",fotoJugador);
                    guardarDatoLocal("idAsociado",idJugador);

                    buscarDatosJugador(idJugador);
                }
                
            }


            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
            tempArreglo[0].valor=nombreUser;
            if(nombreUser.length>=5) tempArreglo[0].error="";
            tempArreglo[1].valor=recuperarDatoLocal("email");
            if(esformatoEmail(tempArreglo[1].valor)) tempArreglo[1].error="";
            setArrCampos(tempArreglo);

            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const incluirInfoTC = () => {
        let nomFuncion="incluirInfoTC"; 

        try{

            let posicion="-", nombreEquipo="-", pJugados="-", goles="-", golDiferencia="-", puntos="-", escudo=escudoEq;
            let i;

            if(infoClasificacionO.length>0) {
                for (i = 0; i < infoClasificacionO.length; i++) {
                    if (infoClasificacionO[i].idtorneo===idTorneo && infoClasificacionO[i].idequipo===idEquipo) {
                        posicion = infoClasificacionO[i].posicionTC;
                        if(infoClasificacionO[i].escudoEquipo!=="") escudo = ruta + infoClasificacionO[i].escudoEquipo;
                        else escudo=escudoEq;
                        nombreEquipo = infoClasificacionO[i].nombrecorto;
                        pJugados = infoClasificacionO[i].partidosjugadosTC;
                        goles = infoClasificacionO[i].golesafavorTC + ":" + infoClasificacionO[i].golesencontraTC;
                        golDiferencia = infoClasificacionO[i].goldiferenciaTC;
                        puntos = infoClasificacionO[i].puntosTC;

                        

                        i = infoClasificacion.length;
                    }

                }
            }

            return(
                <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"900px", marginBottom:"10px", marginInline:"4px"}}>
                    <p className='TX_tituloCaja'>{"Clasificación Fase de Grupos"}</p>
                    <div className='BF_cajaBlanca'>
                        <table border="0" width="100%" onClick={() => {clickGenerico("CLICK_VER_TORNEO")}}>           
                            <tbody>
                                <tr className="TX_textoPeq" align="center">
                                            <td style={{maxWidth:"15px"}}><strong>Pos</strong></td>
                                            <td style={{maxWidth:"40px"}}>{""}</td>
                                            <td style={{maxWidth:"120px"}}>Equipo</td>
                                            <td>PJ</td>
                                            <td>Goles</td>
                                            <td>DG</td>
                                            <td><strong>Pts</strong></td>
                                            <td style={{width:"16px"}}>{""}</td>
                                </tr>
            
                                <tr style={{cursor:"pointer"}} align="center">
                                    <td><strong>{posicion}</strong></td>
                                    <td> <img className="IM_imgRedondeada36" src={escudo} alt="" style={{marginBlock:"4px"}}/></td>
                                    <td align="left" className="TX_unaSolaLinea2">{nombreEquipo}</td>
                                    <td>{pJugados}</td>
                                    <td>{goles}</td>
                                    <td>{golDiferencia}</td>
                                    <td><strong>{puntos}</strong></td>
                                    <td><img className="IM_imgIcono16" src={ic_entrar_gris} alt="Ingresar"   /></td>
                                </tr>

                                {idEquipo!=="" && (
                                    <tr><td colSpan={8}>
                                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", padding:"4px", cursor:"pointer"}} >
                                                <img className="IM_imgIcono16"  src={ic_manual_primary} alt=""/>
                                                <div className='TX_textoPeq' style={{color:C_colorPrimary, marginLeft:"8px"}}>Ver Info Torneo</div>                                            
                                        </div>
                                    </td></tr>                                        
                                )}
                            
                            </tbody>
                        </table>
                        
                    </div>
                </div>   
            );

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
        
    }


    return (
        <>
            {isOpenMenuFlotante && (
                <MenuFlotante isOpen={isOpenMenuFlotante} closeModal={closeMenuFlotante} >
                <div style={{margin:"4px"}}>
                    {/*<div className='MD_ItemMenuFlotante'>
                                    <img src={ic_sombrero} className="IM_imgIcono24" alt=""/>
                                    <div className='TX_unaSolaLinea2' style={{padding:"8px"}}>Cambiar de Rol</div>
                    </div>*/}
                    {esAdminEquipoUS===true && (<div className='MD_ItemMenuFlotante' onClick={() => clickGenerico("CLICK_MENU_MIEQUIPO")}>
                                    <img src={ic_equipo} className="IM_imgIcono24" alt=""/>
                                    <div className='TX_unaSolaLinea2' style={{padding:"8px"}}>Mi Equipo</div>
                    </div>)}
                    <div className='MD_ItemMenuFlotante' onClick={() => clickGenerico("CLICK_MENU_MIINFORMACION")}>
                                    <img src={ic_mi_informacion} className="IM_imgIcono24" alt=""/>
                                    <div className='TX_unaSolaLinea2' style={{padding:"8px"}}>Mi Información</div>
                    </div>
                    <div className='MD_ItemMenuFlotante' onClick={() => clickGenerico("CERRAR_SESION")}>
                                    <img src={ic_salir_negro} className="IM_imgIcono24" alt=""/>
                                    <div className='TX_unaSolaLinea2' style={{padding:"8px"}}>Cerrar Sesión</div>
                    </div>
                                
                </div> 
                </MenuFlotante>
            )}
            
            {isOpenMenuLateral && (
                <MenuLateral isOpen={isOpenMenuLateral} closeModal={closeMenuLateral} >
                <>
                <div  style={{display:"flex", minWidth:"200px", maxWidth:"75vw", height:"190px"}}>
                    <div className='CT_imagenFondoMenu'   style={{width:"100%", display:"block", justifyContent:"center"}}>
                            <div style={{marginTop:"30px", marginInline:"10px", maxWidth:"75vw", flexGrow:"1", flexShrink:"1", display:"flex", justifyContent:"right"}}><img src={ic_logo_mg} height="70px"/></div>
                            <div><span style={{marginBlock:"4px", marginInline:"10px", color: C_colorAccent , fontSize: "14px", flexGrow:"1", flexShrink:"1", display:"flex", justifyContent:"right"}}>Vive Tú Afición Como Un Profesional</span></div>
                            <div><span style={{marginBlock:"4px", marginInline:"10px", color:C_uniformeBlanco, fontSize: "14px", flexGrow:"1", flexShrink:"1", display:"flex", justifyContent:"right"}}>contacto@marcagol.net</span></div>
                    </div>
                </div>

                <div style={{margin:"4px", paddingBottom:"100px"}}>
                    <div className='MD_ItemMenuFlotante' style={{padding:"16px"}} onClick={() => clickGenerico("CLICK_MENU_MICUENTA")}>
                                    <img src={ic_jugadorsinimagen_gris} className="IM_imgIcono24" alt=""/>
                                    <div className='TX_unaSolaLinea2' style={{marginLeft:"20px"}}>Mi Cuenta</div>
                    </div>
                    <div className='BF_lineaInferior'></div>  


                    <div style={{padding:"16px", textAlign:"left", fontSize:"16px", fontWeight:"bold", color:"gray"}}>Cobros y Pagos</div>
                    {tieneAccesoCobros && (
                        <div className='MD_ItemMenuFlotante' style={{padding:"16px"}} onClick={() => clickGenerico("CLICK_MENU_COBROS_MIEQUIPO")}>
                            <img src={ic_cobros_gestionar} className="IM_imgIcono24" alt=""/>
                            <div className='TX_unaSolaLinea2' style={{marginLeft:"20px"}}>De Mi Equipo</div>
                        </div>
                    )}
                    
                    <div className='MD_ItemMenuFlotante' style={{padding:"16px"}} onClick={() => clickGenerico("CLICK_MENU_MISCOBROS")}>
                        <img src={ic_cobros_recibir} className="IM_imgIcono24" alt=""/>
                        <div className='TX_unaSolaLinea2' style={{marginLeft:"20px"}}>Mis Cobros/Pagos</div>
                    </div>
                    <div className='BF_lineaInferior'></div> 


                    <div style={{padding:"16px", textAlign:"left", fontSize:"16px", fontWeight:"bold", color:"gray"}}>Conocenos</div>
                    <a href='https://www.marcagol.net' target="_blank" style={{textDecoration:"none", color:"black"}}>
                        <div className='MD_ItemMenuFlotante' style={{padding:"16px"}}>
                            <img src={ic_sitio_web} className="IM_imgIcono24" alt=""/>                        
                            <div className='TX_unaSolaLinea2' style={{marginLeft:"20px"}}>MarcaGol.net</div>  
                        </div>
                    </a>
                    <div className='BF_lineaInferior'></div> 


                    <div style={{padding:"16px", textAlign:"left", fontSize:"16px", fontWeight:"bold", color:"gray"}}>Contacta a</div>
                    <div className='MD_ItemMenuFlotante' style={{padding:"16px"}} onClick={() => clickGenerico("ABRIR_MODAL_CONTACTO_LIGA")}>
                        <img src={ic_partido_verde} className="IM_imgIcono24" alt=""/>
                        <div className='TX_unaSolaLinea2' style={{marginLeft:"20px"}}>Liga/Torneo/Cancha</div>
                    </div>
                    <div className='MD_ItemMenuFlotante' style={{padding:"16px"}} onClick={() => clickGenerico("ABRIR_MODAL_CONTACTOMG")}>
                        <img src={ic_balon_gol} className="IM_imgIcono24" alt=""/>
                        <div className='TX_unaSolaLinea2' style={{marginLeft:"20px"}}>MarcaGol</div>
                    </div>
                    

                </div> 

                </>
                
                </MenuLateral>
            )}
            
            {isOpenCalificar && (
                <Modal isOpen={isOpenCalificar} closeModal={closeCalificar}  titulo={"Califica el Partido"}  clickAceptar={() => clickGenerico("ACEPTAR_CALIFICAR")} isCancelable={false} >
                <div style={{marginBlock:"20px", marginInline:"30px"}}>
                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                        <div style={{flex:"1", fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Arbitro Central</div>
                        <div style={{fontSize:"18px", fontWeight:"bold", color:C_colorPrimary, width:"40px", textAlign:"right"}}>{formatearDecimal(calificaciones[5])}</div>
                    </div>
                    
                    <div style={{margin:"10px"}}>
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                            <img className="IM_imgRedondeada28" src={fotoArbcalificacion} alt="" />
                            <div style={{flex:"1", fontSize:"14px", textAlign:"left", marginLeft:"10px"}}>{nomArbCalificacion}</div> 
                        </div>
                        <div style={{fontSize:"14px", textAlign:"center", marginTop:"10px"}}>Aplicación del Reglamento</div>
                        <div><Stars valorInicial={calificaciones[0]} habilitado={true} iconSize={28} identificador={"APLI_REGLAMENTO"} clickGenerico={clickGenerico} /></div>
                        <div style={{fontSize:"14px", textAlign:"center"}}>Ubicación en las Jugadas</div>
                        <div><Stars valorInicial={calificaciones[1]} habilitado={true} iconSize={28} identificador={"UBI_JUGADAS"} clickGenerico={clickGenerico} /></div>
                        <div style={{fontSize:"14px", textAlign:"center"}}>Manejo del Partido</div>
                        <div><Stars valorInicial={calificaciones[2]} habilitado={true} iconSize={28} identificador={"MANEJO_PARTIDO"} clickGenerico={clickGenerico} /></div>
                    </div>

                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                        <div style={{flex:"1", fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Estado de la Cancha</div>
                        <div style={{fontSize:"18px", fontWeight:"bold", color:C_colorPrimary, width:"40px", textAlign:"right"}}>{formatearDecimal(calificaciones[3])}</div>
                    </div>                    
                    <div><Stars valorInicial={calificaciones[3]} habilitado={true} iconSize={36} identificador={"ESTADO_CANCHA"} clickGenerico={clickGenerico} /></div>

                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                        <div style={{flex:"1", fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Instalaciones Complementarias</div>
                        <div style={{fontSize:"18px", fontWeight:"bold", color:C_colorPrimary, width:"40px", textAlign:"right"}}>{formatearDecimal(calificaciones[4])}</div>
                    </div>                    
                    <div><Stars valorInicial={calificaciones[4]} habilitado={true} iconSize={36} identificador={"INSTALACIONES"} clickGenerico={clickGenerico} /></div>

                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"20px"}}>
                        <span style={{flex:"1", fontSize:"14px", textAlign:"left"}}>Calificación General: </span>
                        <span style={{fontSize:"16px", fontWeight:"bold", color:C_colorPrimary}}>{formatearDecimal(calificaciones[6])}</span>
                    </div>

                    {clickAceptarModal && msjModal!=="" && (
                                <div style={{marginTop:"20px", marginBottom:"0px", whiteSpace:"pre-line"}}>
                                    <span className="TX_error">{msjModal}</span>
                                </div>
                    )}
                </div>            
                
                </Modal>
            )}
            
            {isOpenContactoLiga && (
                <Modal isOpen={isOpenContactoLiga} closeModal={closeContactoLiga}  titulo={"Contacto Liga/Torneo/Cancha"} txtAceptar='' txtCancelar=''>
                <div style={{marginTop:"20px", marginBottom:"30px", marginInline:"20px"}}>

                    <div style={{display:"flex", alignItems:"center", justifyContent:"left", cursor:"auto", marginBottom:"20px"}}>
                        <img className='IM_imgRedondeada36' src={datosMDContacto[0]} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                        <div style={{textAlign:"left"}}>
                            <div className='TX_unaSolaLinea2' style={{fontSize:"16px", color:C_uniformeNegro}}>{datosMDContacto[1]}</div>
                            <div className='TX_unaSolaLinea2' style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{datosMDContacto[2]}</div>
                        </div>
                    </div>

                    <div>Seleccione el medio de contacto con el que quiere establecer comunicación con el Organizador o responsable de la Liga/Torneo/Cancha</div>
                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", margin:"20px", cursor:"pointer"}} onClick={() => clickGenerico("CLICK_LLAMADA_TELEFONICA")}>
                        <img src={ic_llamada} className="IM_imgIcono24" alt=""/>
                        <div className='TX_unaSolaLinea2' style={{marginLeft:"20px", color:C_uniformeGrisOscuro, textAlign:"left", fontWeight:"bold"}}>{"Llamada Telefónica\n(" + datosMDContacto[3] + ")"}</div>
                    </div>

                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", margin:"20px", cursor:"pointer"}} onClick={() => clickGenerico("CLICK_ABRIR_WHATSAPP")} >
                        <img src={ic_whatsapp} className="IM_imgIcono24" alt=""/>
                        <div className='TX_unaSolaLinea2' style={{marginLeft:"20px", color:C_uniformeVerdeClaro, textAlign:"left", fontWeight:"bold"}}>{"WhatsApp\n(" + datosMDContacto[3] + ")"}</div>
                    </div>

                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", margin:"20px", cursor:"pointer"}} onClick={() => clickGenerico("CLICK_ENVIAR_EMAIL")} >
                        <img src={ic_email} className="IM_imgIcono24" alt=""/>
                        <div className='TX_unaSolaLinea2' style={{marginLeft:"20px", color:C_uniformeAzulClaro, textAlign:"left", fontWeight:"bold"}}>{"Correo\n(" + datosMDContacto[4] + ")"}</div>
                    </div>

                </div>  
                </Modal>
            )}


            {showContactoMG && (<ContactoMG datos={arrCampos} funcionExterna={clickGenerico} cerrar={() => setShowContactoMG(false)}  /> )}
            
          
            {showMensaje && (ventanaMensaje)}

            <div className='CT_fondoVentana'> </div> 

            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                        <div className="CT_elementoEncabezado" onClick={() => {openMenuLateral()}}><img src={ic_menu} className="IM_imgIcono24" alt="" style={{padding: "8px", cursor:"pointer"}} />  </div>                    
                        <div className="CT_elementoEncabezado" style={{flexGrow:"1", flexShrink:"1", minWidth:"100px"}}><p className="TX_unaSolaLinea2" style={{textAlign:"left"}}>{"Panel de Control JUGADOR"}</p></div>
                        <div className="CT_elementoEncabezado"><img className="IM_imgIcono24" src={ic_actualizar_blanco} alt="" style={{padding: "4px", cursor:"pointer"}} onClick={() => clickGenerico("ACTUALIZAR_DATOS")}/></div>
                        <div className="CT_elementoEncabezado" onClick={() => {openMenuFlotante()}}>
                            <img className="IM_imgIcono24" src={ic_jugadorsinimagen} alt="" style={{padding: "8px", cursor:"pointer"}}/>                            
                        </div>
                </div>          
            </div>    

            
            <div style={{position: "relative"}}>

            
                    <div style={{paddingTop:"70px", paddingBottom:"10px", paddingInline:"10px", backgroundColor:"white"}}>
                        <div  style={{display:"flex", justifyContent:"left", alignItems:"center"}} >
                            <img className="IM_imgRedondeada28" src={ic_torneo} alt="" />
                            <div style={{flex:"1", fontSize:"16px", fontWeight:"bold", textAlign:"left", marginLeft:"10px"}}>Mis Torneos</div> 
                            {idTorneo!=="" && (
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", padding:"4px", cursor:"pointer"}} onClick={() => {clickGenerico("CLICK_VER_TORNEO")}}>
                                    <div className='TX_textoPeq' style={{color:C_colorPrimary, marginRight:"8px"}}>Ver Torneo</div>
                                    <img className="IM_imgIcono16"  src={ic_manual_primary} alt=""/>
                                </div>
                            )}
                            
                        </div>  
                    </div>                    
                    {selTorneos.length>0 &&  (   
                        <div style={{backgroundColor:"white", paddingBottom:"10px", paddingInline:"6px"}}>           
                        <Select     
                            styles={estilosSelect}
                            defaultValue={selTorneos[torneoSel]}
                            formatOptionLabel={listaIconoDosItems}
                            options={selTorneos}
                            isSearchable={false}   
                            onChange={(e,index) => {cambiarTorneo(e,index)}}            
                        />   
                        </div>                 
                    )}

                    <div style={{marginBottom:"50px", marginInline:"0px", border: "0px solid red"}}>
                        
                        <div className='BF_cajaBlanca' style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"300px", margin:"6px"}}>
                            <div  style={{display:"flex", justifyContent:"left", alignItems:"center", padding:"10px"}}>
                                <img className="IM_imgRedondeada28" src={ic_partido_verde} alt="" />
                                <div style={{flex:"1", fontSize:"16px", fontWeight:"bold", textAlign:"left", marginLeft:"10px"}}>Mis Partidos</div> 
                            </div>

                            <div style={{display:"flex", flexWrap:"wrap", gap:"8px", margin:"4px"}}> 
                                <div style={{flexGrow:"1", flexShrink:"1", flexBasis:"300px", minWidth:"250px", marginBottom:"30px", marginInline:"0px"}}>
                                       
                                            {ultPartidos.length>0 && (
                                                <div className="slider-container">             
                                                <Slider {...sliderUltPartidos}  ref={slider => {sliderUlt = slider;}}  >
                                                    {ultPartidos.map((reg, index) => (    
                                                    <div key={reg.idPartido}>
                                                        <div className='BF_cajaGris' style={{display:"flex", flexDirection:"column", margin:"1px"}}>
                                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                                <p className='TX_tituloCaja' style={{flex:"1"}}>{"Últimos Partidos"}</p>
                                                                {reg.idJornada>0 && (
                                                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", paddingInline:"6px", paddingBlock:"4px", cursor:"pointer"}} onClick={() => {clickGenerico("CLICK_VER_JORNADAULT", reg)}}>
                                                                        <div className='TX_textoPeq' style={{color:C_colorPrimary, marginRight:"8px"}}>Ver Jornada</div>
                                                                        <img className="IM_imgIcono16"  src={ic_manual_primary} alt=""/>
                                                                    </div>  
                                                                )}                                                                                      
                                                            </div>
                                                        
                                                            <div className='BF_cajaBlanca' style={{flex:"1"}}> 
                                                                    <DinamicoPartidosProgramados  key={reg.idPartido} index={index} partido={reg} linea={true} tipoPartido="upj" modOrigen={idModulo} paramOrigen={paramsAjustado} definirModulo={definirModulo} clickGenerico={clickGenerico}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))}
                                                </Slider> 
                                                </div>
                                            )} 
                                            {ultPartidos.length===0 && (
                                            <div className='BF_cajaGris' style={{display:"flex", flexDirection:"column"}}>
                                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                    <p className='TX_tituloCaja' style={{flex:"1"}}>{"Últimos Partidos"}</p>          
                                                </div>
                                                
                                                <div className='BF_cajaBlanca' style={{flex:"1"}}> 
                                                    <div style={{flex:"1", margin:"20px"}}>
                                                        <span className="TX_sinInfo">No has jugado partidos</span>
                                                    </div>
                                                </div>
                                            </div>
                                            )}                                            
                                        
                                </div> 

                                <div style={{flexGrow:"1", flexShrink:"1", flexBasis:"300px", minWidth:"250px", marginBottom:"30px", marginInline:"0px"}}>
                                       
                                            {proxPartidos.length>0 && (
                                                <div className="slider-container">             
                                                <Slider {...sliderPrxPartidos}>
                                                    {proxPartidos.map((reg) => (    
                                                    <div key={reg.idPartido}>
                                                        <div className='BF_cajaGris' style={{display:"flex", flexDirection:"column", margin:"1px"}}>
                                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                                <p className='TX_tituloCaja' style={{flex:"1"}}>{"Próximos Partidos"}</p>
                                                                {reg.idJornada>0 && (
                                                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", paddingInline:"6px", paddingBlock:"4px", cursor:"pointer"}} onClick={() => {clickGenerico("CLICK_VER_JORNADAULT", reg)}}>
                                                                        <div className='TX_textoPeq' style={{color:C_colorPrimary, marginRight:"8px"}}>Ver Jornada</div>
                                                                        <img className="IM_imgIcono16"  src={ic_manual_primary} alt=""/>
                                                                    </div>  
                                                                )}                                                                                      
                                                            </div>
                                                        
                                                            <div className='BF_cajaBlanca' style={{flex:"1"}}> 
                                                                    <DinamicoPartidosProgramados  key={reg.idPartido} partido={reg} linea={true} tipoPartido="ppj" modOrigen={idModulo} paramOrigen={paramsAjustado} definirModulo={definirModulo} clickGenerico={clickGenerico}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))}
                                                </Slider> 
                                                </div>
                                            )} 
                                            {proxPartidos.length===0 && (
                                            <div className='BF_cajaGris' style={{display:"flex", flexDirection:"column"}}>
                                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                    <p className='TX_tituloCaja' style={{flex:"1"}}>{"Próximos Partidos"}</p>          
                                                </div>
                                                
                                                <div className='BF_cajaBlanca' style={{flex:"1"}}> 
                                                    <div style={{flex:"1", margin:"20px"}}>
                                                        <span className="TX_sinInfo">No tienes partidos programados</span>
                                                    </div>
                                                </div>
                                            </div>
                                            )}                                            
                                        
                                </div> 

                        

                            </div>  

                        </div>



                        <div style={{display:"flex", flexWrap:"wrap", gap:"6px", margin:"6px"}}>
                            
                            <div className='BF_cajaBlanca' style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"300px"}}>
                                <div  style={{display:"flex", justifyContent:"left", alignItems:"center", padding:"10px"}}>
                                    <img className="IM_imgRedondeada28" src={ic_escudo_base2_w} alt="" />
                                    <div style={{flex:"1", fontSize:"16px", fontWeight:"bold", textAlign:"left", marginLeft:"10px"}}>Mi Equipo</div> 
                                    {idEquipo!=="" && (
                                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", padding:"4px", cursor:"pointer"}} onClick={() => {clickGenerico("CLICK_VER_EQUIPO")}}>
                                            <div className='TX_textoPeq' style={{color:C_colorPrimary, marginRight:"8px"}}>Ver Mi Equipo</div>
                                            <img className="IM_imgIcono16"  src={ic_manual_primary} alt=""/>
                                        </div>
                                    )}
                                </div>

                                {incluirInfoTC()}

                            </div>


                            <div className='BF_cajaBlanca' style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"300px", marginTop:"0px", marginLeft:"0px"}}>
                                <div  style={{display:"flex", justifyContent:"left", alignItems:"center", padding:"10px"}}>
                                    <img className="IM_imgRedondeada28" src={ic_jugadorsinimagen_gris} alt="" />
                                    <div style={{flex:"1", fontSize:"16px", fontWeight:"bold", textAlign:"left", marginLeft:"10px"}}>Mis datos</div> 
                                    {selJugador.length>0 && idTorneo!=="" && (
                                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", padding:"4px", cursor:"pointer"}} onClick={() => {clickGenerico("CLICK_VER_JUGADOR")}}>
                                            <div className='TX_textoPeq' style={{color:C_colorPrimary, marginRight:"8px"}}>Ver Mis Datos</div>
                                            <img className="IM_imgIcono16"  src={ic_manual_primary} alt=""/>
                                        </div>
                                    )}
                                </div>

                                <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"900px", marginBottom:"10px", marginInline:"4px"}}>
                                    <p className='TX_tituloCaja'>{"Estadísticas"}</p>
                                    <div className='BF_cajaBlanca'>                            
                                        <DinamicoDatosJugador infoJugador={selJugador[0]}  posicion={posicionJugador} tipo_registroinfo={tipo_registroinfo} tieneTarjetaAzul={tieneTarjetaAzul} />            
                                    </div>
                                </div> 

                                {sancionesJugador.length>0 && (
                                    <>
                                        <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"900px", marginInline:"4px", marginBottom:"10px"}}>
                                            <p className='TX_tituloCaja'>{"Sanciones Activas"}</p>
                                            <div className='BF_cajaBlanca'>   
                                                <div>
                                                    <table border={0} width="100%">
                                                        <tbody>
                                                            <tr className='TX_letraPeq'>
                                                                <td style={{width: "28px"}}></td>
                                                                <td>{"Tipo Sanción"}</td>
                                                                <td style={{width: "50px", minWidth:"50px", textAlign:"center"}}>{"Partidos"}</td>
                                                                <td style={{width: "50px", minWidth:"50px", textAlign:"center"}}>{"Pagada"}</td>
                                                                <td style={{width: "16px"}}></td>
                                                            </tr>                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {sancionesJugador.map((reg, index) => (<div key={index}><DinamicoSancionesJugador datos={reg} tipoLista={"sanciones_jugador"} conLinea={false} /></div>))}
                                            </div> 
                                        </div>
                                    </>
                                )} 

                                {cobrosJugador.length>0 && (
                                    <>
                                        <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"900px", marginInline:"4px", marginBottom:"10px"}} >
                                            
                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                <p className='TX_tituloCaja' style={{flex:"1"}}>{"Cobros Pendientes"}</p>
                                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", paddingInline:"6px", paddingBlock:"4px", cursor:"pointer"}} onClick={() => {clickGenerico("CLICK_MENU_MISCOBROS")}}>
                                                    <div className='TX_textoPeq' style={{color:C_colorPrimary, marginRight:"8px"}}>Ver Detalle</div>
                                                    <img className="IM_imgIcono16"  src={ic_manual_primary} alt=""/>
                                                </div>                                                                                       
                                            </div>
                                            <div className='BF_cajaBlanca'>   
                                                <div>
                                                    <table border={0} width="100%">
                                                        <tbody>
                                                            <tr className='TX_letraPeq'>
                                                                <td style={{width: "24px"}}></td>
                                                                <td style={{width: "24px"}}></td>
                                                                <td>{"Topo Cobro"}</td>
                                                                <td className='TX_unaSolaLinea' style={{width: "70px", minWidth:"50px", textAlign:"center"}}>{"V. Total"}</td>
                                                                <td className='TX_unaSolaLinea' style={{width: "70px", minWidth:"50px", textAlign:"center"}}>{"V. Pagado"}</td>
                                                            </tr>                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {cobrosJugador.map((reg, index) => (<div key={index}><DinamicoCobrosJugador datos={reg} conLinea={false} /></div>))}
                                            </div> 
                                        </div>
                                    </>
                                )} 


                            </div>
                        </div>
                    </div>

                

            </div>

            <div className='CT_zonaFijaInferior' style={{cursor:"pointer", padding:"10px"}} onClick={() => clickGenerico("BTN_ESPECTADORES")}>
                <img src={ic_buscar_blanco} className="IM_imgIcono24" alt="" style={{margin:"0px", padding:"0px"}}/>
                <span className='TX_unaSolaLinea2' style={{marginLeft:"20px"}}>Encuentra mas Torneos MarcaGol</span>
            </div>

            {loading && <Loader tipo={"TOTAL"}/>}   

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}
            
        </>
    );


};

export default Principal_Jugador;