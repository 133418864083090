import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary } from '../constantes/generales';
import ic_equipo from '../recGraficos/ic_equipo.png';

const DinamicoResumenJugadores = ({ datos }) => {

    return(
        <div>
            <span style={{fontSize:"16px", color:C_uniformeGrisOscuro}}>Jugadores</span>

            <div style={{display:"flex", alignItems:"center", marginTop:"5px", marginBottom:"5px"}}>
                <img className="IM_imgIcono36" src={ic_equipo} style={{margin:"5px"}} alt=""/>
                <span style={{flex:"2", fontSize:"24px", color:C_colorPrimary}}>{datos[0]}</span>
                <div style={{flex:"5"}}>
                    {datos[1]!=="" && (
                        <div style={{display:"flex", alignItems:"center"}}>
                            <span style={{flex:"3", fontSize:"14px", color:C_uniformeGrisOscuro}}>Promedio x Equipo</span>
                            <span style={{flex:"1", fontSize:"14px", color: C_colorAccent }}>{datos[1]}</span>
                        </div>
                    )}
                    
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"3", fontSize:"14px", color:C_uniformeGrisOscuro}}>Promedio x Partido</span>
                        <span style={{flex:"1", fontSize:"14px", color: C_colorAccent }}>{datos[2]}</span>
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"3", fontSize:"14px", color:C_uniformeGrisOscuro}}>Cambios x Partido</span>
                        <span style={{flex:"1", fontSize:"14px", color: C_colorAccent }}>{datos[3]}</span>
                    </div>
                </div>
            </div>

        </div>
    );

};


export default DinamicoResumenJugadores;