import { useEffect, useState } from 'react';

import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_asistencia from '../recGraficos/ic_asistencia.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_disparo_arco from '../recGraficos/ic_disparo_arco.png';
import ic_falta_cometida from '../recGraficos/ic_falta_cometida.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_play_arrow from '../recGraficos/ic_play_arrow.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';


import { formatearDecimal, formatearPorcentaje, valorNumero, reportarError, registrarLogEventos } from '../helpers/funciones';
import DinamicoEstadisticasJugador from './DinamicoEstadisticasJugador';
import DinamicoResumen2Cols from './DinamicoResumen2Cols';
import DinamicoResumenJugadores from './DinamicoResumenJugadores';
import DinamicoResumenGoles from './DinamicoResumenGoles';
import DinamicoResumenDisparos from './DinamicoResumenDisparos';
import DinamicoResumenTiempo from './DinamicoResumenTiempo';
import DinamicoResumenEfectividad from './DinamicoResumenEfectividad';
import { ID_MODULO_DETALLE_TORNEO } from '../constantes/generales';

const nomClase="DT_InfoEstadisticas_WB";
const idModulo=ID_MODULO_DETALLE_TORNEO;

//const nomFuncion="asignarDatosFinales";

let titulos=["No. Equipos","Goles","Asistencias","Atajadas","Disparos Totales","Disparos al Arco","T Amarillas","Faltas Cometidas","T Rojas"];
let iconos=["",ic_balon_gol,ic_asistencia,ic_atajada,ic_disparo_arco,ic_disparo,ic_amarilla,ic_falta_cometida,ic_tarjeta_roja];

const DT_InfoEstadisticas = ({idTorneo, infoClasificacion, columnasSuma, columnasAvg, tipo_registroinfo, numPartidos, cantJugadores, tieneTarjetaAzul}) => {    
    const [iEstadisticas, setIEstadisticas] = useState(["","","","","","","","","",""]);
    const [iJugadores, setIJugadores] = useState(["0","0","0","0"]);
    const [iGoles, setIGoles] = useState(["0","0","0","0","0","0","0"]);
    const [iDisparos, setIDisparos] = useState(["","","","","","","","","","",""]);
    const [iEfectividad, setIEfectividad] = useState(["","","","","","","",""]);
    const [disparosTotales, setDisparosTotales] = useState("");
    const [autogoles, setAutogoles] = useState("0");
    const [iTiempo, setITiempo] = useState(["","","","","","",""]);

    /*let columnasASumar=[6,7,8,9,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
    let columnasOrdenar=[0,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,1,1];
    let columnasOrdenarJug=[3,4,5,6,7,8,9,10,11,12,13,14,15,16];

    let columnasSuma=[];
    let columnasAvg=[];
    let infoOrdenadaEquipos=[];*/

  
    useEffect(() => {        
        
        let texto="Faltas Recibidas";
        let icono=ic_falta_cometida;
        if(tieneTarjetaAzul){
            texto="T Azules";
            icono=ic_tarjeta_azul;
        }

        titulos=["No. Equipos","Goles","Asistencias","Atajadas","Disparos Totales","Disparos al Arco","T Amarillas",texto,"T Rojas"];
        iconos=["",ic_balon_gol,ic_asistencia,ic_atajada,ic_disparo_arco,ic_disparo,ic_amarilla,icono,ic_tarjeta_roja];

        asignarDatosFinales();     
               
        registrarLogEventos(idModulo, "7", idTorneo,"" );


    }, [idTorneo]);



    const asignarDatosFinales = () => {
        const nomFuncion="asignarDatosFinales";
    
        try{
            let datosEstadisticas=["","","","","","","","","",""];
            
            datosEstadisticas[0]="" + infoClasificacion.length; //Numero de Equipos
            datosEstadisticas[1]="" + columnasSuma[1]; //goles
    
            if(tipo_registroinfo==="1"){
                datosEstadisticas[2]="-"; //asistencias
                datosEstadisticas[3]="-"; //atajadas
                datosEstadisticas[4]="-"; //disparos totales
                datosEstadisticas[5]="-"; //disparos dentro del arco
                datosEstadisticas[7]="-"; //faltas cometidas
            }
            else{
                datosEstadisticas[2]="" + columnasSuma[13]; //asistencias
                datosEstadisticas[3]="" + columnasSuma[9]; //atajadas
                datosEstadisticas[4]="" + columnasSuma[10]; //disparos totales
                datosEstadisticas[5]="" + columnasSuma[11]; //disparos dentro del arco
                datosEstadisticas[7]="" + columnasSuma[5]; //faltas cometidas
            }
    
            datosEstadisticas[6]="" + columnasSuma[6]; //tarjetas amarillas
            datosEstadisticas[8]="" + columnasSuma[7]; //tarjetas rojas
            datosEstadisticas[9]="" + numPartidos; //partidos jugados
    
            if(tieneTarjetaAzul){
                datosEstadisticas[7]="" + columnasSuma[46]; //tarjetas azules
            }
    
            //setIEstadisticas(datosEstadisticas);
            //iEstadisticas=datosEstadisticas;
    
            let tempNum=0;
    
            let datosJugadores=["0","0","0","0"];
    
            datosJugadores[0]=cantJugadores; //Total Registrados
            if(infoClasificacion.length===0) tempNum=0;
            else tempNum=valorNumero(cantJugadores)/infoClasificacion.length;
            datosJugadores[1]=formatearDecimal(tempNum); //Prom x Equipo
            tempNum=0;
            if(columnasSuma[0]>0) tempNum=((columnasSuma[17]/columnasSuma[0])/100);
            datosJugadores[2]=formatearDecimal((columnasAvg[17]/100)); //Prom x Equipo x Partido
            tempNum=0;
            if(columnasSuma[0]>0) tempNum=((columnasSuma[18]/columnasSuma[0])/100);
            //tempNum=((float)columnasAvg[18]/100);
            datosJugadores[3]=formatearDecimal((columnasAvg[18]/100)); //Prom cambios x partido
    
            //setIJugadores(datosJugadores);
            //iJugadores=datosJugadores;

    
            let datosGoles=["0","0","0","0","0","0","0"];
    
            datosGoles[0]="" + columnasSuma[1]; //Goles
            datosGoles[1]="" + columnasSuma[14]; //En Jugada
            if(columnasSuma[1]>0){
                tempNum=(columnasSuma[14]/columnasSuma[1])*100;
                datosGoles[2]=formatearPorcentaje(tempNum); //% goles de jugada
            }
            else datosGoles[2]="0%";
    
            datosGoles[3]="" + columnasSuma[15]; //Tiro Libre
            if(columnasSuma[1]>0){
                tempNum=(columnasSuma[15]/columnasSuma[1])*100;
                datosGoles[4]=formatearPorcentaje(tempNum); //% goles Tiro Libre
            }
            else datosGoles[4]="0%";
    
            datosGoles[5]="" + columnasSuma[16]; //Penalti
            if(columnasSuma[1]>0){
                tempNum=(columnasSuma[16]/columnasSuma[1])*100;
                datosGoles[6]=formatearPorcentaje(tempNum); //% goles Penalti
            }
            else datosGoles[6]="0%";
    
            //setIGoles(datosGoles);
            //iGoles=datosGoles;
    
    
            let datosDisparos=["","","","","","","","","","",""];
            if(tipo_registroinfo==="2"){
                
    
                datosDisparos[0]="" + (columnasSuma[10]-columnasSuma[11]); //disparos desviados
                datosDisparos[3]="" + columnasSuma[11]; //disparos al arco
                //setIDisparos(datosDisparos);
                //iDisparos=datosDisparos;

    
                let datosEfectividad=["","","","","","","",""];
                datosEfectividad[0]="Efectividad de Disparo";
                datosEfectividad[1]="";
                datosEfectividad[2]="Goles/Total Disparos";
                datosEfectividad[3]="Goles/Disparos al Arco";
    
                if(columnasSuma[10]>0){
                    tempNum=(columnasSuma[1]/columnasSuma[10])*100;
                    datosEfectividad[4]=formatearPorcentaje(tempNum); //%Goles/Total Disparos
                }
                else datosEfectividad[4]="0%"; //%Goles/Total Disparos
    
                if(columnasSuma[11]>0){
                    tempNum=(columnasSuma[1]/columnasSuma[11])*100;
                    datosEfectividad[5]=formatearPorcentaje(tempNum); //%Goles/disparos al arco
                }
                else datosEfectividad[5]="0%"; //%Goles/disparos al arco
    
                datosEfectividad[6]=columnasSuma[1] + "/" + columnasSuma[10]; //Goles/Total Disparos
                datosEfectividad[7]=columnasSuma[1] + "/" + columnasSuma[11]; //Goles/Disparos Arco
    
                setIEfectividad(datosEfectividad);
                //iEfectividad=datosEfectividad;

                
                if(columnasSuma.length>=11){
                    setDisparosTotales("" + columnasSuma[10]);
                    //disparosTotales="" + columnasSuma[10];
                } 
                
            }
    
            if(columnasSuma.length>=13){
                setAutogoles(columnasSuma[12]);
                //autogoles=columnasSuma[12];
            }
    
            let datosTiempo=["","","","","","",""];
    
            try{
                let tempTiempo=0;
                let horas=0,minutos=0,segundos=0;
    
                //if(columnasSuma[0]>0) tempTiempo=(columnasSuma[21]/columnasSuma[0]);
                //tempTiempo=columnasSuma[21];
                tempTiempo=valorNumero(columnasAvg[21]);
                let valorTiempo="";
                horas=valorNumero((tempTiempo/3600));
                minutos=valorNumero(((tempTiempo-(horas*3600))/60));
                segundos=valorNumero((tempTiempo-(horas*3600)-(minutos*60)));
                if(horas>0) valorTiempo=horas + " h ";
                if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                datosTiempo[0]=valorTiempo; //Tiempo Jugado Total
    
                tempTiempo=0;
                //if(columnasSuma[0]>0) tempTiempo=(columnasSuma[19]/columnasSuma[0]);
                //tempTiempo=columnasSuma[19];
                tempTiempo=valorNumero(columnasAvg[19]);
                valorTiempo="";
                horas=valorNumero(tempTiempo/3600);
                minutos=valorNumero((tempTiempo-(horas*3600))/60);
                segundos=valorNumero((tempTiempo-(horas*3600)-(minutos*60)));
                if(horas>0) valorTiempo=horas + " h ";
                if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                if(horas===0 && segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                datosTiempo[2]=valorTiempo; //Tiempo Jugado 1er tiempo
    
                tempTiempo=0;
                tempTiempo=valorNumero(columnasAvg[20]);
                valorTiempo="";
                horas=valorNumero(tempTiempo/3600);
                minutos=valorNumero((tempTiempo-(horas*3600))/60);
                segundos=valorNumero((tempTiempo-(horas*3600)-(minutos*60)));
                if(horas>0) valorTiempo=horas + " h ";
                if(minutos>0) valorTiempo=valorTiempo + minutos + " m ";
                if(horas===0 && segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                datosTiempo[4]=valorTiempo; //Tiempo Jugado 2do tiempo
            }catch(e){}           
    
            //datosTiempo[0]=datosFinales[33]; //Tiempo Jugado Total
            datosTiempo[1]="";
            //datosTiempo[2]=datosFinales[34]; //Tiempo 1er tiempo
            datosTiempo[3]="";
            //datosTiempo[4]=datosFinales[35]; //Tiempo 2do tiempo
            datosTiempo[5]="";
            datosTiempo[6]="Tiempo Promedio Jugado";
    
            //setITiempo(datosTiempo);
            //iTiempo=datosTiempo;


            setIEstadisticas(datosEstadisticas);
            setIJugadores(datosJugadores);
            setIGoles(datosGoles);
            setIDisparos(datosDisparos);
            setITiempo(datosTiempo);
            
        }
        catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }


     
    


    return (
        <>
            
            {(iEstadisticas.length>0) && (iEstadisticas[0]!=="") && 
            (<><div className='CT_contenedorFlex' style={{margin: "10px"}}>
                <DinamicoEstadisticasJugador titulos={titulos} iconos={iconos} datos={iEstadisticas} />   

                <div className='CT_regSancion' style={{minWidth:"280px"}}>
                    <div className='BF_cajaGris'>
                        <p className='TX_tituloCaja'>Informacion Adicional</p>
                        <div className='BF_cajaBlanca'>
                            <DinamicoResumen2Cols imagen={ic_play_arrow} titulo={"Partidos Jugados"} texto1={numPartidos} texto2={""} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumenJugadores datos={iJugadores} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumenGoles tipo={1} datos={iGoles} />
                            {(tipo_registroinfo==="2") && columnasSuma.length>=0 && 
                                (<><div className='BF_lineaInferior'></div>
                                <DinamicoResumen2Cols imagen={ic_disparo_arco} titulo={"Disparos Totales"} texto1={disparosTotales} texto2={""} />
                                <DinamicoResumenDisparos tipo={1} datos={iDisparos}/>   
                                <DinamicoResumenEfectividad datos={iEfectividad} />
                            </>)}

                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumen2Cols imagen={ic_balon_autogol} titulo={"Autogoles"} texto1={autogoles} texto2={""} />
                            <div className='BF_lineaInferior'></div>
                            <DinamicoResumenTiempo datos={iTiempo}/>
                        </div>
                    </div>     
                       
                </div>
            </div>
            
            </>)
            }
        </>
    );
};


export default DT_InfoEstadisticas;