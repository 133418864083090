import React from "react";

import "../estilos/IM_Imagenes.css";
import { C_uniformeGrisOscuro, C_uniformeNegro } from "../constantes/generales";

const Template_lista_conimagen = (txtPrincipal, txtSecundario, imagen) => {


  return (
    <div style={{display: "flex", flexWrap: "nowrap", alignItems: "center", background:"white"}}>
      <div><img className="IM_imgRedondeada36" height="36px" width="36px" src={imagen} alt="" /></div>
      <div style={{display: "block", textAlign: "left", marginLeft: "5px"}}>        
        <div className="TX_unaSolaLinea2" style={{ color: C_uniformeNegro, fontSize:"16px"}}>{txtPrincipal}</div>
        {txtSecundario!=="" && (
          <div style={{ color: C_uniformeGrisOscuro, fontSize:"14px", whiteSpace: "pre" }}>
            {txtSecundario}
          </div>
        )}        
      </div>
    </div> 
  );
};

export default Template_lista_conimagen;