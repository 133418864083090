import ic_escudo_base2 from '../recGraficos/ic_escudo_base2_w.png'
import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png'
import { formatearTexto, recuperarDatoLocal, reportarError } from '../helpers/funciones';
import { C_colorAccent, C_fondoPaginas, C_uniformeAzulClaro, C_uniformeNegro, C_uniformeRosa, ID_MODULO_DETALLE_EQUIPO } from '../constantes/generales';

const ruta=recuperarDatoLocal("rutaRecursos");


const DetalleTablaClasificacion = ({ datos, cantGrupos, registro=0, modOrigen, paramOrigen, definirModulo }) => {
    const nomClase="DetalleTablaClasificacion_WB";
    const nomFuncion="DetalleTablaClasificacion";

    try{

        let tit={
            width: "100%",
            borderRadius: "5px",    
            backgroundColor: C_fondoPaginas,
            padding: "4px",
            textAlign: "center",
            color: C_uniformeNegro,
            fontWeight: "bold"
        };

        let item={
            cursor: "pointer",
            fontSize: "16px"
        }
    
        
        let titulo=false;
        let txtTitulo="";
        if(datos.sinInfo==="1"){
            titulo=true;
            if(cantGrupos>1){
                if(datos.tc_numgrupo==="0") txtTitulo="SIN Grupo";
                else txtTitulo="Grupo. " + datos.tc_numgrupo;
            }
        }     

        let tituloEq="";
        let colorEq="";
        if(datos.titulo_equipo!==""){
            let tempDatos=datos.titulo_equipo.split("|");
            
            if(tempDatos.length>=2){
                if(tempDatos[0]==="1") colorEq=C_colorAccent;
                if(tempDatos[0]==="2") colorEq=C_uniformeAzulClaro;
                if(tempDatos[0]==="3") colorEq=C_uniformeRosa;

                tituloEq=tempDatos[1];
            }
        }

        let escudo=ic_escudo_base2;
        if(datos.escudo!=="") escudo=ruta + datos.escudo;

        
    
        let colorEquipo={
            color: colorEq,
            bordeBottom: "1px solid " + colorEq,
            fontSize: "12px",
            margin: "0 0 -15px 0"
        }


        const manejarClick = () => {
            definirModulo(registro, modOrigen, paramOrigen, ID_MODULO_DETALLE_EQUIPO, datos.idEquipo);        
        }

        return (   
            <>           
                        {titulo && txtTitulo &&
                            (<><tr><td colSpan={8}  style={tit}>{txtTitulo}</td></tr></>) 
                        }

                        {titulo && 
                            (<>
                                <tr className="TX_textoPeq" align="center">
                                    <td><strong>Pos</strong></td>
                                    <td>{""}</td>
                                    <td width="47%">Equipo</td>
                                    <td width="11%">PJ</td>
                                    <td width="20%">Goles</td>
                                    <td width="11%">DG</td>
                                    <td width="11%"><strong>Pts</strong></td>
                                    <td>{""}</td>
                                </tr>
                                <tr><td colSpan={8} align="center"><div className="BF_lineaInferior"></div></td></tr></>
                            ) 
                        }

                        {colorEq && 
                        (<><tr><td colSpan={8} align="center">
                            <div style={colorEquipo}>{tituloEq}</div>
                            </td></tr></>                            
                        )}

                        <tr style={item} align="center" onClick={manejarClick}>
                            <td><strong>{datos.tc_posicion}</strong></td>
                            <td> <img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo} alt="" /></td>
                            <td align="left" className="TX_unaSolaLinea2">{formatearTexto(datos.nombrecorto,2)}</td>
                            <td style={{fontSize:"14px"}}>{datos.tc_partidosjugados}</td>
                            <td style={{fontSize:"14px"}}>{datos.tc_golesafavor + ":" + datos.tc_golesencontra}</td>
                            <td style={{fontSize:"14px"}}>{datos.tc_goldiferencia}</td>
                            <td><strong>{datos.tc_puntos}</strong></td>
                            <td><img className="IM_imgIcono24" src={ic_entrar_gris} alt="Ingresar"  height="20px" width="20px"  /></td>
                        </tr>
                        <tr><td colSpan={8} align="center"><div className="BF_lineaInferior"></div></td></tr>
                
            </>
        

            
        );

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

  };
  
  export default DetalleTablaClasificacion;