import React from "react";
import { useEffect, useState } from 'react';
import Select from "react-select";
import DinamicoPartidosProgramados from "./DinamicoPartidosProgramados";

import { formatearFecha, registrarLogEventos, reportarError, dividirLista } from "../helpers/funciones";
import { listaDosItems } from '../recVisual/listaDesplegable';
import { C_colorTextoSinInfo, ID_MODULO_DETALLE_TORNEO } from "../constantes/generales";

const nomClase="DT_InfoPartidos_WB";
const idModulo=ID_MODULO_DETALLE_TORNEO;

let idTorneo="";

const DT_InfoPartidos = ({ tiempo_antes, datos, infoFechas, idSemanaActual, infoJornadas, idJornadaActual, tipoListaBase, modOrigen, paramOrigen, definirModulo }) => {
  const [idSemana, setIdSemana] = useState("");
  const [idJornada, setIdJornada] = useState("");
  const [partidos, setPartidos] = useState(false);
  const [partidosShow, setPartidosShow] = useState([]);
  const [jornadas, setJornadas] = useState([]);  
  const [jornadaSel, setJornadaSel] = useState(0);
  const [fechas, setFechas] = useState([]);  
  const [fechaSel, setFechaSel] = useState(0);
  const [tipoLista, setTipoLista] = useState("");
  const [parametros, setParametros] = useState("");
  const [minAntesCancha, setMinAntesCancha] = useState("");
  const [tienePartidosSinFinalizar, setTienePartidosSinFinalizar] = useState(false);

  
  let texto_tiempo="* Presentarse en Cancha " + tiempo_antes + " minutos antes de la hora programada del partido";


    const lista ={
      value:"",
      label:"",
      texto2:""
    }  

    useEffect(() => {
      const nomFuncion="useEffect";  
      
      if(datos.length>0) idTorneo=datos[0].idTorneo;
      else idTorneo="";

      

      let opciones=[];
      let jornadaBase=0;
      let i;

      try{

        let datosParams=dividirLista(paramOrigen,"#",5);

        let idSemanaFinal=idSemanaActual;
        let idJornadaFinal=idJornadaActual;
        let tipoListaFinal=tipoListaBase;

        if(datosParams[2]!=="") tipoListaFinal=datosParams[2];
        if(datosParams[3]!=="") idSemanaFinal=datosParams[3];
        if(datosParams[4]!=="") idJornadaFinal=datosParams[4];

        let param2=datosParams[0] + "#" + "3" + "#" + tipoListaFinal + "#" + idSemanaFinal + "#" + idJornadaFinal;
        setParametros(param2);

  
        if(infoJornadas.length>0){
          let ultJor=""
        
          for(i=0;i<infoJornadas.length;i++){
      
            let registro=Object.assign({} , lista);
            
      
            if(infoJornadas[i][0]===idJornadaFinal) jornadaBase=i;
      
            let txtLabel="Fase de Grupos";
            let txtTexto2="Jornada No. " + infoJornadas[i][1];
            if(infoJornadas[i][2]==="2"){
              txtLabel = infoJornadas[i][4];
              txtTexto2=infoJornadas[i][3];
            } 
      
            registro.value=infoJornadas[i][0];
            registro.texto2=txtTexto2;
      
            if(ultJor!==txtLabel){
              ultJor=txtLabel;
              registro.label=txtLabel;
            }
      
            opciones.push(registro);
      
          }
      
          setJornadaSel(jornadaBase);
          setJornadas(opciones);
          setIdJornada(infoJornadas[jornadaBase][0]);
      
        }
    
        if(infoFechas.length>0){
          let opcionesFecha=[];
          let fechaBase=0;
      
          for(i=0;i<infoFechas.length;i++){
      
            let registro=Object.assign({} , lista);
      
            if(infoFechas[i][0]===idSemanaFinal) fechaBase=i;
      
            let txtLabel=formatearFecha(infoFechas[i][2],13) + "  -  " + formatearFecha(infoFechas[i][3],13);
      
            registro.value=infoFechas[i][0];
            registro.texto2=txtLabel;   
            
            opcionesFecha.push(registro);      
          }
      
          setFechaSel(fechaBase);
          setFechas(opcionesFecha);
          setIdSemana(infoFechas[fechaBase][0]);
        }

        setTipoLista(tipoListaFinal);
        setMinAntesCancha(tiempo_antes);

        if(idTorneo!=="")
          registrarLogEventos(idModulo,"5",idTorneo,"");


      }catch(err){
          reportarError(nomClase, nomFuncion, "", err);
      }

    }, []);

    useEffect(() => {
      const nomFuncion="useEffect.varios";


      if(tipoLista!==""){

        let numPartidos=5;
        setPartidos(false);      

        let divPartidos=[];
        let partidosSinFinalizar=false;

        

        try{
          if(tipoLista==="fecha"){
            let i;
            let contador=1;
            let partidosGrupo=[];
            let max = datos.length; //>20 ? 20 : datos.length;
            
            for(i=0;i<max;i++){
              if(idSemana===datos[i].idsemana){
                if(contador<=numPartidos){
                  partidosGrupo.push(datos[i]);

                  if(!datos[i].nomEstado.includes("FINALIZADO")) partidosSinFinalizar=true;

                  contador++;
                }
                else{
                  contador=1;
                  divPartidos.push(partidosGrupo);
                  partidosGrupo=[];
                  partidosGrupo.push(datos[i]);

                  if(!datos[i].nomEstado.includes("FINALIZADO")) partidosSinFinalizar=true;

                  contador++;
                }
              }      
            }
        
            if(partidosGrupo.length>0){
              divPartidos.push(partidosGrupo);
            }
          }  
    
    
          if(tipoLista==="jornada"){
            let i;
            let contador=1;
            let partidosGrupo=[];
            let max = datos.length; //>20 ? 20 : datos.length;
            
            for(i=0;i<max;i++){
              if(idJornada===datos[i].idJornada){
                if(contador<=numPartidos){
                  partidosGrupo.push(datos[i]);
                  if(!datos[i].nomEstado.includes("FINALIZADO")) partidosSinFinalizar=true;
                  contador++;
                }
                else{
                  contador=1;
                  divPartidos.push(partidosGrupo);
                  partidosGrupo=[];
                  partidosGrupo.push(datos[i]);
                  if(!datos[i].nomEstado.includes("FINALIZADO")) partidosSinFinalizar=true;
                  contador++;
                }
              }      
            }
        
            if(partidosGrupo.length>0){
              divPartidos.push(partidosGrupo);
            }
          } 
        }catch(err){
          reportarError(nomClase, nomFuncion, "", err);
        }  
                
        
        setPartidos(true);
        setPartidosShow(divPartidos);
        setTienePartidosSinFinalizar(partidosSinFinalizar);

        let datosParams=dividirLista(paramOrigen,"#",5);
        let param2=datosParams[0] + "#" + "3" + "#" + tipoLista + "#" + idSemana + "#" + idJornada;
        setParametros(param2);

      }      

    }, [tipoLista, idSemana, idJornada]);

    

    function generarGrupo(registros){

      return(
        registros.map((el) => (<DinamicoPartidosProgramados key={el.idPartido} partido={el} linea={true} modOrigen={modOrigen} paramOrigen={parametros} definirModulo={definirModulo}/>))
      );
    }

    
    const cambiarLista = (selectedOption, tipo) => {

      if(tipo===1) setIdSemana(selectedOption.value);
      else setIdJornada(selectedOption.value);

    };

    const cambiarTipoLista = (selectedOption) => {

        if(selectedOption.target.value==="fecha") setIdSemana(fechas[fechaSel].value);
        else setIdJornada(jornadas[jornadaSel].value);

        setTipoLista(selectedOption.target.value);

    };


  return (
      <>

        <div style={{ minWidth: "200px", maxWidth: "400px", marginLeft: "10px", marginRight: "10px", marginBottom: "20px"}}>
            {tipoLista && (
              <div style={{ width: "100%", display: "flex", justifyContent: "space-around", margin: "10px"}}>
                <label><input type="radio" value="fecha" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="fecha"} onChange={cambiarTipoLista}/>Por Fecha</label>
                <label><input type="radio" value="jornada" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista!=="fecha"} onChange={cambiarTipoLista}/>Por Jornada</label>
              </div>
            )}

          <div className='BF_bordePrimary'>
            {tipoLista==="fecha" && fechas.length>0 && 
            (
              
              <Select      
                defaultValue={fechas[fechaSel]}
                formatOptionLabel={listaDosItems}
                options={fechas}
                isSearchable={false}
                onChange={(e) => {cambiarLista(e, 1)}}
              />
              
            )}

            

            {tipoLista==="jornada" && jornadas.length>0 && 
            (
              <Select      
                defaultValue={jornadas[jornadaSel]}
                formatOptionLabel={listaDosItems}
                options={jornadas}
                isSearchable={false}
                onChange={(e) => {cambiarLista(e, 2)}}
              />
            )}

          </div>


        </div>

        

        {minAntesCancha>0 && tienePartidosSinFinalizar===true && (
          <div><p className='TX_letraPeq'>{texto_tiempo}</p></div>
        )}
        
        
        
        {partidos &&
        (
          <div style={{margin: "0.5em"}} className="CT_contenedorFlex">
                      {partidosShow.length>0 ? 
                            ( 
                              partidosShow.map((reg, index) => (<div className="CT_tablaCGrupo" key={index}>{generarGrupo(reg)}</div>))                    
                            ) 
                            : 
                            (<h2 style={{marginTop: "30px", color: C_colorTextoSinInfo}}>Sin Partidos Programados</h2>) }  
                  </div>

        )}
        
        
      </>
    );
  };
  
  export default DT_InfoPartidos;