import { useEffect, useRef, useState } from 'react';
import "cropperjs/dist/cropper.css";

import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";

import { C_background, C_colorAccent, C_colorPrimary, C_colorPrimaryDark, C_uniformeBlanco, ID_MODULO_GESTION_IMAGENES } from '../constantes/generales';
import { reportarError } from '../helpers/funciones';
import { Cropper } from 'react-cropper';

import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_jugadorsinimagen_blanco from '../recGraficos/ic_jugadorsinimagen_blanco.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_agregar from '../recGraficos/ic_atajada.png';
import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png';
import ic_salir from '../recGraficos/ic_salir_w.png';
import ic_ok from '../recGraficos/ic_ok.png';
import ic_mas_negro from '../recGraficos/ic_mas_negro.png';
import ic_menos_negro from '../recGraficos/ic_menos_negro.png';
import ic_actualizar_negro from '../recGraficos/ic_actualizar_negro.png';
import ic_rotar_negro from '../recGraficos/ic_rotar_negro.png';
import ic_abrirfoto_blanco from '../recGraficos/ic_abrirfoto_blanco.png';
import ic_borrar_blanco from '../recGraficos/ic_borrar_blanco.png';
import ic_seleccionar_imagen from '../recGraficos/ic_seleccionar_imagen.png';


const nomClase="GestionImagen_WB";
const idModulo=ID_MODULO_GESTION_IMAGENES;

let imgOrigen=null;

const GestionImagen = ({tipoImagen, imgActual="", clickGenerico}) => {
  const [output, setOutput] = useState(null);
  const [imgSrc, setImgSrc] = useState('')  
  const [loading, setLoading] = useState(true);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [momento, setMomento] = useState(1);
  const [letBorrar, setLetBorrar] = useState(false);

  const cropperRef = useRef(null);
  const fileRef = useRef(null);


  useEffect(() => {
    let nomFuncion="useEffect"; 

        try{ 

    
          if(momento===1){
            let tmpImg=imgActual;
            if(imgActual==="") tmpImg=ic_jugadorsinimagen_blanco;
            else if(imgActual===ic_jugadorsinimagen) tmpImg=ic_jugadorsinimagen_blanco;
            
            setLetBorrar(true);

            if(tipoImagen==="JUGADOR") {
              if(tmpImg===ic_jugadorsinimagen_blanco) setLetBorrar(false);        
            }
            if(tipoImagen==="EQUIPO") {
              if(tmpImg===ic_escudo_base_gris) setLetBorrar(false);
            }
            if(tipoImagen==="USUARIO") {
              if(tmpImg===ic_seleccionar_imagen) setLetBorrar(false);
            }

            setOutput(tmpImg);
          } 

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  

  },[momento, tipoImagen]);

     

  const cortarImagen = () => {
    let nomFuncion="cortarImagen"; 

    try{ 

      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;

      const img = cropper.getCroppedCanvas().toDataURL();
      
      resizeImage(img, 300, 300).then(result => {
        setMomento(3);
        setOutput(result);
      });

    }catch(err){
      reportarError( nomClase, nomFuncion, "", err);
    } 

  };


  function resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
    return new Promise(resolve => {
      const nomFuncion="resizeImage.Promise";
  
      try{
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          const nomFuncion="resizeImage.Promise.onload";
  
          try{
            let canvas = document.createElement("canvas");
            const MAX_WIDTH = maxWidth;
            const MAX_HEIGHT = maxHeight;
            let width = img.width;
            let height = img.height;
            let shouldResize = false;
      
            if (width > height) {
              if (width !== MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
                shouldResize = true;
              }
            } else {
              if (height !== MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
                shouldResize = true;
              }
            }
            shouldResize = true;

            if (shouldResize) {
              canvas.width = width;
              canvas.height = height;
              let ctx = canvas.getContext("2d");     
              ctx.fillStyle = "white";
              ctx.fillRect(0, 0, width, height);         
              ctx.drawImage(img, 0, 0, width, height);
              resolve(canvas.toDataURL("image/jpeg",0.9));
            } else {
              resolve(base64Str);
            }
  
          }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
          } 
          
        };
      
      }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
      } 
      
    });
  }

  const cargarImagen = (e) => {
    let nomFuncion="cargarImagen"; 

    try{ 

      let file=e.target.files[0];
      let a=URL.createObjectURL(file);
      imgOrigen=file;
      setImgSrc(a);
      setMomento(2);

    }catch(err){
      reportarError( nomClase, nomFuncion, "", err);
    } 

  }

  const rotate = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(90);
  }

  const restablecer = () => {
    let a=URL.createObjectURL(imgOrigen);
    setImgSrc(a);
  }

  const zoom = (type) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (type === "mas") {
      if(scaleX<1.5){
        cropper.scaleX(scaleX+0.1);
        cropper.scaleY(scaleY+0.1);
        setScaleX(scaleX+0.1);
        setScaleY(scaleY+0.1);
      }      
    } else {
      if(scaleX>0.5){
        cropper.scaleX(scaleX-0.1);
        cropper.scaleY(scaleY-0.1);
        setScaleX(scaleX-0.1);
        setScaleY(scaleY-0.1);
      }      
    }
  }

  const triggerInputFile = () => {
    if (fileRef.current != undefined && fileRef.current.click != undefined)
      fileRef.current.click()
  }

  const clickAceptar = () => {
    if(momento===1) {

    }
    else if (momento===2){      
      cortarImagen();
    }
    else if (momento===3){
      setMomento(1);
      clickGenerico("GUARDAR_IMAGEN_SERVIDOR",output);
    }
  }

  const clickCancelar = () => {
    if(momento===1) {
      setMomento(1);
      clickGenerico("CERRAR_MODAL_IMAGEN");
    }
    else if (momento>1){
      setMomento(momento-1);
    }
  }

  const clickCerrar = () => {
    setMomento(1);
    clickGenerico("CERRAR_MODAL_IMAGEN");
  }


    return (
        <div style={{height:"85vh", display:"flex", flexDirection:"column", flexWrap:"nowrap"}}>

            <div style={{cursor:"pointer", position: "absolute", top: "0rem", left: "0rem", width:"100%", display:"flex", backgroundColor: C_colorPrimary, borderTopLeftRadius:"5px", borderTopRightRadius:"5px" }}
            onClick={() => clickCerrar()}>
                  <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"left"}}>
                    <img className="IM_imgIcono24" src={ic_salir} style={{padding:"6px"}}/>
                    <span style={{fontSize:"16px", fontWeight:"bold", color: C_uniformeBlanco}}>Gestión de Imágen</span>
                  </div>
            </div>

            <div style={{height:"50px"}}></div>
        

            <div style={{flex:"1", paddingTop:"0px", paddingBottom:"0px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                
                {momento===1 && (
                  <div style={{flex:"1", marginInline:"30px", display:"flex", flexWrap:"wrap", alignItems:"center", justifyContent:"center"}}>

                    <div style={{flex:"1", flexShrink:"1", flexGrow:"1", minWidth:"300px", maxWidth:"500px", minHeight:"300px", maxHeight:"500px"}}>
                        {output && <img src={output} className='IM_imgRedondeada250' style={{border:"1px solid gray", marginBottom:"20px"}}  />}
                    </div>

                    <div style={{flex:"1", flexShrink:"1", flexGrow:"1", minWidth:"300px", maxWidth:"500px", display:"flex", flexDirection:"column", flexWrap:"nowrap", justifyContent:"center", alignItems:"center"}}>
                        <div >
                          <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"left",minWidth:"250px", cursor:"pointer"}} onClick={(e) => {triggerInputFile()}} >
                              <img src={ic_abrirfoto_blanco} className='IM_imgIcono36' style={{margin:"10px"}}/>
                              <div className='TX_neutro' style={{color:C_uniformeBlanco}}>Definir Imágen</div>
                          </div>
                          <input hidden ref={fileRef} type="file" accept="image/*" onChange={(e) => {cargarImagen(e)}} />
                        </div>
                        {letBorrar && (
                          <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"left", minWidth:"250px", cursor:"pointer"}} onClick={() => {setMomento(1); clickGenerico("SOLICITAR_CONFIRMACION_BORRADO_IMAGEN")}}>
                              <img src={ic_borrar_blanco} className='IM_imgIcono36' style={{margin:"10px"}}/>
                              <div className='TX_neutro' style={{color:C_uniformeBlanco}}>Borrar Imágen Actual</div>
                          </div>
                        )}
                        
                    </div>

                    

                  </div>                  
                )}               


                {imgSrc && momento===2 && (
                      <div style={{marginInline:"30px"}}>
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}}>
                            <img className='IM_botonRedondeado' src={ic_rotar_negro} alt="" onClick={rotate}/>
                            <img className='IM_botonRedondeado' src={ic_actualizar_negro} alt="" onClick={(e) => {restablecer() }}/>
                            <div style={{flex:"1"}}></div>
                            <img className='IM_botonRedondeado' src={ic_mas_negro} alt="" onClick={(e) => {zoom("mas") }}/>
                            <img className='IM_botonRedondeado' src={ic_menos_negro} alt="" onClick={(e) => {zoom("menos") }}/>
                        </div>                       

                        <div style={{border:"1px solid gray"}}>
                          <Cropper
                            src={imgSrc}
                            style={{ maxHeight:"70svh"}}
                            initialAspectRatio={1}
                            aspectRatio={1}
                            zoomOnTouch={false}
                            zoomOnWheel={false}
                            minCropBoxWidth={50}
                            highlight={false}
                            guides={true}
                            ref={cropperRef}
                          />                   
                        </div>


                      </div>
                )}

                {momento===3 && (
                  <div style={{flex:"1", flexShrink:"1", flexGrow:"1", minWidth:"300px", maxWidth:"500px", minHeight:"300px", maxHeight:"500px"}}>
                      {output && <img src={output} className='IM_imgRedondeada250' style={{border:"1px solid gray", marginBottom:"20px"}}  />}
                  </div>
                )}
                

            </div>




            <div style={{height:"50px"}}></div>

            <div style={{position: "absolute", bottom: "0rem", left: "0rem", width:"100%", display:"flex", alignItems:"center", justifyContent:"center", backgroundColor: C_colorPrimary, cursor:"pointer"}}>
                  <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}} onClick={() => clickCancelar()}>
                    <img className="IM_imgIcono24" src={ic_cancelar_gris} style={{padding:"6px"}}/>
                    <span style={{fontSize:"16px", fontWeight:"bold", color: C_uniformeBlanco}}>Cancelar</span>
                  </div>
                  {momento>1 && (
                    <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}} onClick={() => clickAceptar()}>
                      <img className="IM_imgIcono24" src={ic_ok} style={{padding:"6px"}}/>
                      <span style={{fontSize:"16px", fontWeight:"bold", color: C_uniformeBlanco}}>Aceptar</span>
                    </div>
                  )}
                  
            </div>

        </div>
    );
};

export default GestionImagen;