import { useState } from 'react';

import {reportarError} from '../helpers/funciones';

import "../estilos/TX_Textos.css";
import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";


import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png';

import { C_fondoAccentSuave,  C_transparente,  C_uniformeAzulClaro,  C_uniformeGrisOscuro, C_uniformeNegro, C_uniformeRojo, C_uniformeRosa, C_uniformeVerdeClaro } from '../constantes/generales';


const nomClase="DinamicoCobrosPorTipo_WB";

const DinamicoCobrosPorTipo = ({datos, seleccionado=false, eventoClick}) => {
    const nomFuncion="DinamicoCobrosPorTipo";

    const [esVisible, setEsVisible] = useState(false);

    let colorBG=C_fondoAccentSuave;
    if(!seleccionado) colorBG=C_transparente;

    try{
        if(!datos) return null;


        let idConfigSel=datos[6];
        let regConfig=datos[7];
            
        return (
            <>
                {datos[5] && (<div className="BF_lineaInferior"></div>)}
                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer", marginBlock:"4px", backgroundColor: colorBG}} 
                    onClick={() => {eventoClick("CLICK_TIPOCOBRO",[idConfigSel, regConfig])}}>
                    {datos[4]!=="" && (<img className="IM_imgIcono24" src={datos[4]} alt="" />)}
                    <div style={{flex:"1", textAlign:"left", fontSize:"14px", color:C_uniformeGrisOscuro}}>{datos[0]}</div>
                    <div style={{width:"70px", display:"block", textAlign:"right"}}>
                        <div style={{fontSize:"12px", color:C_uniformeNegro, fontWeight:"bold"}}>{datos[1]}</div>
                        <div style={{fontSize:"12px", color:C_uniformeVerdeClaro}}>{datos[2]}</div>
                        <div style={{fontSize:"12px", color:C_uniformeRojo}}>{datos[3]}</div>
                    </div>
                    <img className="IM_imgIcono24" src={ic_entrar_gris} alt="" />
                </div>   
                
                </>
        );
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    
};


export default DinamicoCobrosPorTipo;