import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary } from "../constantes/generales";

const DinamicoResumen2Cols = ({ imagen, titulo, texto1, texto2 }) => {

    return(
        <div style={{display:"flex", alignItems:"center"}}>
            <img className="IM_imgIcono36" src={imagen} style={{margin:"5px"}} alt=""/>
            <span style={{flex:"5", fontSize:"16px", color:C_uniformeGrisOscuro}}>{titulo}</span>
            <span style={{flex:"2", fontSize:"20px", color:C_colorPrimary}}>{texto1}</span>
            {texto2!=="" && (<p style={{flex:"3", fontSize:"20px", color: C_colorAccent }}>{texto2}</p>)}
        </div>

    );

};


export default DinamicoResumen2Cols;