import { APP_ES_PRODUCCION, SERVIDOR_PRINCIPAL, SERVIDOR_PRUEBAS, WS_ENVIARCORREO_CONTACTO, WS_ENVIARCORREO_PASSWORD } from '../constantes/generales';
import {presentarError} from '../helpers/funciones';

export const callUrl = () => {

    const WS_TOKEN=process.env.REACT_APP_WS_TOKEN;    

    let respuesta = {
        datos : "",
        exito : true,
        conectado : true,
        hayDatos : true,
        mensaje : ""
    }
    
    let url = "";

    
    const fetchData = (tipoCall, datos) => {

        const abortController = new AbortController();
        const senal = abortController.signal;

        if(APP_ES_PRODUCCION===true) url= SERVIDOR_PRINCIPAL;
        else url= SERVIDOR_PRUEBAS;
        
        if(datos!==null && datos.length>0){
            if(tipoCall==="CORREO_PASSWORD"){
                url=url + WS_ENVIARCORREO_PASSWORD + "?user=" + datos[0] + "&dominio=" + datos[1] + "&id=" + datos[2];
            }
            else if(tipoCall==="CORREO_CONTACTO"){
                url=url + WS_ENVIARCORREO_CONTACTO + "?user=dario2&dominio=hotmail.com&id=" + datos[0];
            }
        }
        
        /*const datosEnv = new FormData();
        datosEnv.append('wstoken', WS_TOKEN);
        datosEnv.append('tipo_consulta', tipo_consulta);
        datosEnv.append('funcion', funcion);
        datosEnv.append('consulta', consulta);
        datosEnv.append('parametros', parametros);
        datosEnv.append('zona_horaria', zona_horaria);*/

        const requestOptions = {
            method: 'GET',
            signal: senal
        };    

        setTimeout(() => abortController.abort(),10000);

        function asignarRespuesta(texto){   
            respuesta.exito=true;
            return respuesta;
        }

        function asignarError(err){
            presentarError("getDatos","fetch","",err);
            respuesta.exito=false;
            respuesta.conectado = false;
            return respuesta;
        }



        return fetch(url, requestOptions)
            .then(async (res) =>
                res.ok
                ? asignarRespuesta(await res.text())
                : Promise.reject(asignarError())
            )
            .catch((err) => asignarError(err));

    };

    const get = (tipoCall, datos) => fetchData(tipoCall, datos);

    return {get};
};