import { useState } from 'react';

import {formatearDecimal, formatearFecha, recuperarDatoLocal, reportarError} from '../helpers/funciones';

import "../estilos/TX_Textos.css";
import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";

import ic_torneo from '../recGraficos/ic_torneo.png';
import ic_partido_verde from '../recGraficos/ic_partido_verde.png';
import ic_puntos from '../recGraficos/ic_puntos.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_manual_primary from '../recGraficos/ic_manual_primary.png';
import ic_cancelar from '../recGraficos/ic_cancelar.png';
import { C_uniformeGrisOscuro, C_uniformeVerdeClaro } from '../constantes/generales';


const nomClase="DinamicoCobrosJugador_WB";

const DinamicoCobrosJugador = ({ datos, conLinea=true }) => {
    const nomFuncion="DinamicoCobrosJugador";

    const [esVisible, setEsVisible] = useState(false);

    try{
        if(!datos) return null;

        const ruta=recuperarDatoLocal("rutaRecursos");
    
        let imgTipoCobro=ic_torneo;
        let imgTipoEvento=ic_transparente;
        let nombreCobro="";
        let detalleCobro="";
        let vTotal="";
        let vPagado="";
    
    
        /*0.idtorneo,1.idjugador,2.tipocobro,3.tipoevento,4.valor_total),5.valor_pagado),6.Cantidad cobros*/
    
        if(datos.tipocobro==="1"){
            imgTipoCobro=ic_torneo;
            imgTipoEvento=ic_transparente;
    
            nombreCobro="Por Torneo";
            if(datos.cantidad==="1") detalleCobro="";
            else{
                detalleCobro=datos.cantidad + " Cobros de Torneo";
            }
        }
        if(datos.tipocobro==="2"){
            imgTipoCobro=ic_partido_verde;
            imgTipoEvento=ic_transparente;
    
            nombreCobro="Por Partido";
            if(datos.cantidad==="1") detalleCobro="";
            else{
                detalleCobro=datos.cantidad + " Cobros de Partido";
            }
        }
        if(datos.tipocobro==="3"){
            imgTipoCobro=ic_puntos;
    
            nombreCobro="Por Evento";
    
            if(datos.tipoevento==="1"){
                imgTipoEvento=ic_amarilla;
                if(datos.cantidad==="1") detalleCobro="Tarjeta Amarilla";
                else detalleCobro=datos.cantidad + " Tarjetas Amarillas";
            }
            if(datos.tipoevento==="2"){
                imgTipoEvento=ic_tarjeta_roja;
                if(datos.cantidad==="1") detalleCobro="Tarjeta Roja";
                else detalleCobro=datos.cantidad + " Tarjetas Rojas";
            }
            if(datos.tipoevento==="3"){
                imgTipoEvento=ic_cancelar;
                if(datos.cantidad==="1") detalleCobro="Partido Perdido W";
                else detalleCobro=datos.cantidad + " Partidos Perdidos W";
            }
            if(datos.tipoevento==="4"){
                imgTipoEvento=ic_manual_primary;
                if(datos.cantidad==="1") detalleCobro="Cobro Manual";
                else detalleCobro=datos.cantidad + " Cobros Manuales";
            }
            if(datos.tipoevento==="5"){
                imgTipoEvento=ic_tarjeta_azul;
                if(datos.cantidad==="1") detalleCobro="Tarjeta Azul";
                else detalleCobro=datos.cantidad + " Tarjetas Azules";
            }
        }
    
        vTotal="$ " + formatearDecimal(datos.valor_total,2);
    
        vPagado="$ " + formatearDecimal(datos.valor_pagado,2);
        
    
        return (
    
                <div className="CT_regSancion" style={{cursor:"pointer"}}> 
                    <table border={0} width="100%" style={{minWidth: "100px"}}>
                        <tbody>
                            <tr>
                                <td rowSpan={2} style={{width: "24px"}}><img className="IM_imgIcono24" src={imgTipoCobro} alt="" /></td>
                                <td rowSpan={2} style={{width: "24px"}}><img className="IM_imgIcono24" src={imgTipoEvento} alt="" /></td>
                                <td style={{minWidth:"50px"}}><span className='TX_unaSolaLinea2'>{nombreCobro}</span></td>
                                <td  className='TX_unaSolaLinea'  rowSpan={2} style={{width: "70px", minWidth:"50px", textAlign:"right", fontSize:"16px", fontWeight:"bold", color:C_uniformeGrisOscuro}}>{vTotal}</td>
                                <td  className='TX_unaSolaLinea'  rowSpan={2} style={{width: "70px", minWidth:"50px", textAlign:"right", fontSize:"16px", fontWeight:"bold", color:C_uniformeVerdeClaro}}>{vPagado}</td>
                            </tr>
                            <tr><td className='TX_textoPeq' style={{color: C_uniformeGrisOscuro, minWidth:"100px"}}>{detalleCobro}</td></tr>
                        </tbody>
                    </table>  
                    {conLinea && (<div className="BF_lineaInferior"></div>)}
                    
                </div>
        
        );
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    
};


export default DinamicoCobrosJugador;