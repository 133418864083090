import ic_circulo_primary from '../recGraficos/ic_circulo_primary.png';
import ic_circulo_accent from '../recGraficos/ic_circulo_accent.png';


import { valorFloat, formatearPorcentaje, presentarError, reportarError } from '../helpers/funciones';
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary } from '../constantes/generales';

const nomClase="DinamicoResumenDisparos_WB";

const DinamicoResumenDisparos = ({ tipo, datos }) => {

    const nomFuncion="DinamicoResumenDisparos";

    let tvPorcFueraEq1="";
    let tvPorcDentroEq1="";
    let tvPorcFueraEq2="";
    let tvPorcDentroEq2="";

    let porc0=0, porc3=0;

    try{
        if(tipo===1){
            let num1=valorFloat(datos[0]);
            let num2=valorFloat(datos[3]);
                        
            if((num1+num2)>0){
                porc0=(num1/(num1+num2))*100;
                porc3=(num2/(num1+num2))*100;
            } 
        
            tvPorcFueraEq1=formatearPorcentaje(porc0);
            tvPorcDentroEq2=formatearPorcentaje(porc3);
        }
        else{
            let num1=valorFloat(datos[0]);
            let num2=valorFloat(datos[2]);
            let porc=0;
                
            if((num1+num2)>0) porc=(num1/(num1+num2))*100;
            tvPorcFueraEq1=formatearPorcentaje(porc);
    
            porc=0;
            if((num1+num2)>0) porc=(num2/(num1+num2))*100;
            tvPorcDentroEq1=formatearPorcentaje(porc);
    
            num1=valorFloat(datos[1]);
            num2=valorFloat(datos[3]);
            porc=0;
            if((num1+num2)>0) porc=(num1/(num1+num2))*100;
            tvPorcFueraEq2=formatearPorcentaje(porc);
    
            porc=0;
            if((num1+num2)>0) porc=(num2/(num1+num2))*100;
            tvPorcDentroEq2=formatearPorcentaje(porc);
        }
        
    }catch(err){
        reportarError(nomClase,nomFuncion,"",err);
    }



    return(
        <div>
            <div style={{display:"flex", margin:"5px"}}>
                <div style={{flex:"1"}}>
                    <div>
                        <div className='IM_imagenConTexto' style={{margin:"5px"}}>
                            <img className="IM_imgIcono36" src={ic_circulo_primary} alt=""/>
                            <span className='IM_textoCentardoImagen' style={{fontSize:"12px", fontWeight:"bold", color:C_uniformeBlanco}}>{datos[0]}</span>
                        </div> 
                    </div>
                    <span style={{fontSize:"14px", color:C_colorPrimary}}>{tvPorcFueraEq1}</span>
                </div>
                
                <div style={{flex:"3"}}>
                    <div style={{fontSize:"14px", color:C_uniformeGrisOscuro, margin:"4px"}}>Disparos Desviados</div>
                    <div className='BF_fondoArco' style={{height:"75px"}}>
                        <div style={{fontSize:"14px", color:C_uniformeGrisOscuro, marginTop:"8px"}}>Disparos al arco</div>
                        <div style={{display:"flex"}}>
                            {tipo===2 && 
                            (
                            <div style={{display:"flex", alignItems:"center"}}>
                                <div className='IM_imagenConTexto' style={{margin:"5px"}}>
                                    <img className="IM_imgIcono36" src={ic_circulo_primary} alt=""/>
                                    <span className='IM_textoCentardoImagen' style={{fontSize:"12px", fontWeight:"bold", color:C_uniformeBlanco}}>{datos[2]}</span>
                                </div> 
                                <span style={{fontSize:"14px", color:C_colorPrimary}}>{tvPorcDentroEq1}</span>
                            </div>
                            )}
                            
                            <div style={{flex:"1"}}></div>
                            <div style={{display:"flex", alignItems:"center"}}>
                                <span style={{fontSize:"14px", color: C_colorAccent }}>{tvPorcDentroEq2}</span>
                                <div className='IM_imagenConTexto' style={{margin:"5px"}}>
                                    <img className="IM_imgIcono36" src={ic_circulo_accent} alt=""/>
                                    <span className='IM_textoCentardoImagen' style={{fontSize:"12px", fontWeight:"bold", color:C_uniformeBlanco}}>{datos[3]}</span>
                                </div>                             
                            </div>
                        </div>
                    </div>
                </div>
                

                <div style={{flex:"1"}}>
                    {tipo===2 && 
                    (<>
                    <div>
                        <div className='IM_imagenConTexto' style={{margin:"5px"}}>
                            <img className="IM_imgIcono36" src={ic_circulo_accent} alt=""/>
                            <span className='IM_textoCentardoImagen' style={{fontSize:"12px", fontWeight:"bold", color:C_uniformeBlanco}}>{datos[1]}</span>
                        </div> 
                    </div>
                    <span style={{fontSize:"14px", color: C_colorAccent }}>{tvPorcFueraEq2}</span>
                    </>
                    )}
                    
                </div>          

            </div>
            
        </div>
    );

};


export default DinamicoResumenDisparos;