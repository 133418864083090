import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png'


const TemplateLista2Items = ({ llevaImagen, imagen, dato1, dato2, llevaEntrar, modDestino, paramDestino, modOrigen, paramOrigen, definirModulo }) => {

    
    if(!dato1) return null;

    let numCols=3;
    let cursor={
      cursor: "pointer"
    }

    if(llevaImagen) numCols++;

    let imgEntrar=ic_entrar_gris;
    if(!llevaEntrar){
      imgEntrar="";
      cursor={};
    } 


    let stDato1={
        textTransform: "capitalize",
        fontWeight: "bold",
        verticalAlign: "text-top",
        align: "left",
        width: "35%",
    }

    let stDato2={
        verticalAlign: "text-top",
        align: "left",
        width: "65%",
        color: "gray",
        whiteSpace: "pre-wrap"

    }


    const clickRegistro = () => {
      if(llevaEntrar){
        definirModulo(0,modOrigen,paramOrigen,modDestino,paramDestino);  
      }            
    }  

    return (
      <>
        <tr style={cursor} onClick={clickRegistro}>
            {llevaImagen && <td><img className="IM_imgRedondeada36" style={{marginRight:"6px"}} src={imagen} alt=""/></td>}
            <td style={stDato1}>{dato1}</td>
            <td style={stDato2}>{dato2}</td>
            <td><img className="IM_imgIcono24" src={imgEntrar} alt=""/></td>
        </tr>
        <tr><td colSpan={numCols} align="center"><div className="BF_lineaInferior"></div></td></tr>
      </>
    );
  };
  
  export default TemplateLista2Items;