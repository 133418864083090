
import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_play_arrow from '../recGraficos/ic_play_arrow.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';
import ic_asistencia from '../recGraficos/ic_asistencia.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';

import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import { presentarError, recuperarDatoLocal, reportarError, valorNumero } from '../helpers/funciones';
import { C_uniformeGrisOscuro, C_uniformeNegro, C_uniformeVerdeClaro } from '../constantes/generales';





const DinamicoDatosJugador = ({ infoJugador, posicion, tipo_registroinfo, tieneTarjetaAzul=false}) => {
    const nomClase="DinamicoDatosJugador_WB";
    const nomFuncion="DinamicoDatosJugador";

    try{
        //if(tipo_registroinfo===null || tipo_registroinfo==="") return null;
        //if(infoJugador==null) return null;
            
        const ruta=recuperarDatoLocal("rutaRecursos");   

        let columnas=8;
        if(tipo_registroinfo==="1") columnas=5;

        let nombre="";
        let imagen=ic_jugadorsinimagen;
        let pj="0";
        let g="-";
        let ag="-";
        let a="-";
        let da="-";
        let at="-";
        let ta="-";
        let tr="-";

        let tituloAG="AG";
        let imagenAG=ic_balon_autogol;

        if(infoJugador!=null && infoJugador.idjugador!==""){
            nombre=infoJugador.nombre;

            if(infoJugador.foto!=="") imagen=ruta + infoJugador.foto;
            pj=infoJugador.pjugados;

            if(valorNumero(infoJugador.pjugados)>0){
                g=infoJugador.goles;
                ag=infoJugador.autogoles;
                a=infoJugador.asistencias;
                da=infoJugador.disparosdentro;
                at=infoJugador.atajadas;
                ta=infoJugador.tarjetasamarillas;
                tr=infoJugador.tarjetasrojas;

                if(tieneTarjetaAzul===true || tieneTarjetaAzul==="1") {
                    ag=infoJugador.tarjetasazules;
                    tituloAG="TZ";
                    imagenAG=ic_tarjeta_azul;
                }
            }
            
        }


        
        return(
            <div style={{display:"flex", alignItems:"center", textAlign:"center"}}>
                <div style={{display:"flex", flexDirection:"column", textAlign:"center", justifyContent:"center", alignItems:"center"}}>
                    <img className="IM_imgRedondeada46" src={imagen} style={{margin:"5px"}} alt=""/>
                    <div style={{fontSize:"12px", color:C_uniformeVerdeClaro}}>{posicion}</div>
                </div>
                <div style={{flex:"1"}}>
                    <table valign="center" width="100%" border={0} style={{fontSize:"12px", color:C_uniformeGrisOscuro}}><tbody>
                        <tr><td colSpan={8}><div style={{fontSize:"14px", fontWeight:"bold", color:C_uniformeGrisOscuro}}>{nombre}</div></td></tr>
                        <tr>
                            <td>PJ</td><td>G</td><td>{tituloAG}</td>
                            <td>TA</td><td>TR</td>
                            {columnas===8 && (<><td>A</td><td>DA</td><td>AT</td></>)}  
                        </tr>
                        <tr style={{color:C_uniformeNegro, fontSize:"14px", fontWeight:"bold"}}>
                            <td>{pj}</td>
                            <td>{g}</td>
                            <td>{ag}</td>
                            <td>{ta}</td>
                            <td>{tr}</td>
                            {columnas===8 && (<>
                                <td>{a}</td>
                                <td>{da}</td>
                                <td>{at}</td>
                            </>)}  
                        </tr>
                        <tr align='center'>
                            <td><img className="IM_imgIcono16" src={ic_play_arrow} alt=""/></td>
                            <td><img className="IM_imgIcono16" src={ic_balon_gol} alt=""/></td>
                            <td><img className="IM_imgIcono16" src={imagenAG} alt=""/></td>
                            <td><img className="IM_imgIcono16" src={ic_amarilla} alt=""/></td>
                            <td><img className="IM_imgIcono16" src={ic_tarjeta_roja} alt=""/></td>   
                            {columnas===8 && (<>
                                <td><img className="IM_imgIcono16" src={ic_asistencia} alt=""/></td>
                                <td><img className="IM_imgIcono16" src={ic_disparo} alt=""/></td>
                                <td><img className="IM_imgIcono16" src={ic_atajada} alt=""/></td>
                            </>)}                         
                                            
                        </tr>
                    </tbody></table>
                </div>
            </div>

        );

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    

};


export default DinamicoDatosJugador;