import { useEffect, useState } from 'react';
import { useModal } from "../helpers/useModal";

import { getDatos } from '../gestionDatos/getDatos';
import { callUrl } from '../gestionDatos/callUrl';


import ic_android_blanco from '../recGraficos/ic_android_blanco.png';
import ic_logo_mg from '../recGraficos/ic_logo_mg.png';
import ic_logo_mg2 from '../recGraficos/ic_logo_mg2.png';


import "../estilos/IM_Imagenes.css";
import "../estilos/CT_Contenedor.css";
import "../estilos/BF_BordesFondos.css";
import "../estilos/TX_Textos.css";



import Loader from '../recVisual/Loader';
import Modal from '../recVisual/Modal';
import {registrarLogEventos, valorLong, reportarError, valorNumero, esformatoEmail, presentarError, crearTabla, formatearTexto, quitarCharEspeciales, esformatoNumero, recuperarDatoLocal, guardarDatoLocal, superaTiempoActualizacion, presentarLog, showMensajePantalla, dividirListaNueva, esAndroidSO, openInNewTab } from '../helpers/funciones';
import Template_lista_roles from '../gestionVisual/Template_lista_roles';
import Mensaje from '../recVisual/Mensaje';
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary, ID_MODULO_BASE, ID_MODULO_PRINCIPAL_INVITADO, ID_MODULO_PRINCIPAL_JUGADOR, ID_MODULO_CONTACTOMG, SEPARADOR_PARAMETROS_BD } from '../constantes/generales';
import ShowError from '../recVisual/ShowError';
import ContactoMG from '../recVisual/ContactoMG';


const nomClase="MainActivity_WB";
const idModulo=ID_MODULO_BASE;

//let idSede="-1";
let idUser="";
let tipoUser="";

let idRol="";

let tieneRoles=false;
let revisarTodo="1";

const ruta= recuperarDatoLocal("rutaRecursos");
let urlTerminos= ruta + "mg/terminosycondiciones.html";

let c_idregistro="0";
let c_idUsuario="0";

let txtAceptar="ACEPTAR";
let txtTitulo="";
let txtMensaje="";

let idUsuario="0";

let eEnter;

let ventanaMensaje;



let matrizCampos=[
    {id: "CORREO",  idx: 0, valor: recuperarDatoLocal("email"), error: "Ingrese su Correo Electrónico"},
    {id: "PASSWORD",  idx: 1, valor: "", error: "Ingrese su Contraseña"},
    {id: "MD1_CORREO",  idx: 2, valor: "", error: "Ingrese su Correo Electrónico"},
    {id: "MD1_CODIGO",  idx: 3, valor: "", error: ""},
    {id: "MD2_TELEFONO",  idx: 4, valor: "", error: "Ingrese su número de telefono"},
    {id: "MD2_TERMINOS",  idx: 5, valor: false, error: "Debe revisar y ACEPTAR los Terminos y Condiciones de Uso de MarcaGol para poder continuar"},
    {id: "MD1_NEWPASSWORD1",  idx: 6, valor: "", error: "Ingrese su Nueva Contraseña"},
    {id: "MD1_NEWPASSWORD2",  idx: 7, valor: "", error: "Confirme la Contraseña asignada"},
    {id: "MD3_TELEFONO",  idx: 8, valor: "", error: "Ingrese su número de telefono"},
    {id: "MD3_DOCUMENTO",  idx: 9, valor: "", error: "Ingrese su documento de identidad"},
    {id: "MD3_NOMBRE",  idx: 10, valor: "", error: "Ingrese su Nombre"},
    {id: "MD3_APELLIDO",  idx: 11, valor: "", error: ""},
    {id: "MD3_CORREO",  idx: 12, valor: "", error: "Ingrese su Correo electrónico"},
    {id: "MD3_PASSWORD1",  idx: 13, valor: "", error: "Ingrese su Contraseña"},
    {id: "MD3_PASSWORD2",  idx: 14, valor: "", error: "Confirme la contraseña"},
    {id: "MD3_TERMINOS",  idx: 15, valor: "", error: "Debe revisar y ACEPTAR los Terminos y Condiciones de Uso de MarcaGol para poder continuar"}
];



var infoUsuarioO = [];
var infoUsuario = [];
function crearInfoUsuario(datos){   
    const nomFuncion="crearInfoUsuario";

    try{
        let usuario = {
            idusuario:"",
            idrol:"",
            idasociado:"",
            nombreRol:"",
            idempresa:"",
            nombreEmpresa:"",
            idsede:"",
            nombreSede:"",
            userActivo:"",
            zonaHorariaTF:"",
            zonaHorariaTel:"",
            zonaHorariaNombre:"",
            indicativo:""
        }
    
        infoUsuarioO = [];
        infoUsuario = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , usuario);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idusuario=datos[i][j];
                if(j===1) registro.idrol=datos[i][j];
                if(j===2) registro.idasociado=datos[i][j];
                if(j===3) registro.nombreRol=datos[i][j];
                if(j===4) registro.idempresa=datos[i][j];
                if(j===5) registro.nombreEmpresa=datos[i][j];
                if(j===6) registro.idsede=datos[i][j];
                if(j===7) registro.nombreSede=datos[i][j];
                if(j===8) registro.userActivo=datos[i][j];
                if(j===9) registro.zonaHorariaTF=datos[i][j];
                if(j===10) registro.zonaHorariaTel=datos[i][j];
                if(j===11) registro.zonaHorariaNombre=datos[i][j];
                if(j===12) registro.indicativo=datos[i][j];
            }

            infoUsuarioO.push(registro);
            infoUsuario.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}


var infoNuevosRolesO = [];
var infoNuevosRoles = [];
function crearInfoNuevosRoles(datos){   
    const nomFuncion="crearInfoNuevosRoles";

    try{
        let nuevoRol = {
            idregistro:"",
            idrol:"",
            nombreRol:"",
            idempresa:"",
            nombreEmpresa:"",
            idsede:"",
            nombreSede:"",
            idasociado:"",
            correo:"",
            telefono:"",
            nombreUser:"",
            aceptado:true
        }
    
        infoNuevosRolesO = [];
        infoNuevosRoles = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , nuevoRol);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idregistro=datos[i][j];
                if(j===1) registro.idrol=datos[i][j];
                if(j===2) registro.nombreRol=datos[i][j];
                if(j===3) registro.idempresa=datos[i][j];
                if(j===4) registro.nombreEmpresa=datos[i][j];
                if(j===5) registro.idsede=datos[i][j];
                if(j===6) registro.nombreSede=datos[i][j];
                if(j===7) registro.idasociado=datos[i][j];
                if(j===8) registro.correo=datos[i][j];
                if(j===9) registro.telefono=datos[i][j];
                if(j===10) registro.nombreUser=datos[i][j];
            }

            infoNuevosRolesO.push(registro);
            infoNuevosRoles.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}


var infoUsuarioBaseO = [];
var infoUsuarioBase = [];
function crearInfoUsuarioBase(datos){   
    const nomFuncion="crearInfoUsuarioBase";

    try{
        let usuarioBase = {
            idusuario:"",
            nombreUser:"",
            activo:""
        }
    
        infoUsuarioBaseO = [];
        infoUsuarioBase = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , usuarioBase);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idusuario=datos[i][j];
                if(j===1) registro.nombreUser=datos[i][j];
                if(j===2) registro.activo=datos[i][j];
            }

            infoUsuarioBaseO.push(registro);
            infoUsuarioBase.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}


var infoRolesO = [];
//var infoRoles = [];
function crearInfoRoles(datos){   
    const nomFuncion="crearInfoRoles";

    try{
        let rol = {
            idregistro:"",
            idrol:"",
            nombreRol:"",
            idempresa:"",
            nombreEmpresa:"",
            idsede:"",
            nombreSede:"",
            idasociado:"",
            correo:"",
            telefono:"",
            nombreUser:"",
            aceptado:true
        }
    
        infoRolesO = [];
        //infoRoles = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , rol);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idregistro=datos[i][j];
                if(j===1) registro.idrol=datos[i][j];
                if(j===2) registro.nombreRol=datos[i][j];
                if(j===3) registro.idempresa=datos[i][j];
                if(j===4) registro.nombreEmpresa=datos[i][j];
                if(j===5) registro.idsede=datos[i][j];
                if(j===6) registro.nombreSede=datos[i][j];
                if(j===7) registro.idasociado=datos[i][j];
                if(j===8) registro.correo=datos[i][j];
                if(j===9) registro.telefono=datos[i][j];
                if(j===10) registro.nombreUser=datos[i][j];
            }

            infoRolesO.push(registro);
            //infoRoles.push(datos[i]);
        }        

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoUserTelO = [];
var infoUserTel = [];
function crearInfoUserTel(datos){   
    const nomFuncion="crearInfoUserTel";

    try{
        let userTel = {
            correo:"",
            nombre:""
        }
    
        infoUserTelO = [];
        infoUserTel = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , userTel);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.correo=datos[i][j];
                if(j===1) registro.nombre=datos[i][j];
            }

            infoUserTelO.push(registro);
            infoUserTel.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}


const Main = ({definirModulo}) => {
    const [esError, setEsError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [msjModal, setMsjModal] = useState("");
    const [showMsjFlotante, setMsjFlotante] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);
    const [ingresando, setIngresando] = useState(true);

    const [isOpenEspectador, openEspectador, closeEspectador] = useModal(false);
    const [isOpenPassword, openPassword, closePassword] = useModal(false);
    const [isOpenRegistro, openRegistro, closeRegistro] = useModal(false);
    const [isOpenNewRoles, openNewRoles, closeNewRoles] = useModal(false);
    const [isOpenContacto, openContacto, closeContacto] = useModal(false);
    

    const [clickAceptar, setClickAceptar] = useState(false);
    const [clickAceptarModal, setClickAceptarModal] = useState(false);
    const [arrCampos, setArrCampos] = useState(matrizCampos);
    const [indicadorAceptar, setIndicadorAceptar] = useState(0);

    const [infoRoles, setInfoRoles] = useState([]);
    const [infoNewRoles, setInfoNewRoles] = useState([]);
    
    const [showContactoMG, setShowContactoMG] = useState(false);
    const [datosContacto, setDatosContacto] = useState([]);

    const [esUserAndroid, setEsUserAndroid] = useState(false);


    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{ 
            /*var t = new Date();
            t.setTime(valorLong(recuperarDatoLocal("ultAcceso" + idModulo)));     
            console.log(nomClase + " - " + nomFuncion);  
            console.log("ultVez: " + t); */ 


            if(esAndroidSO()) setEsUserAndroid(true);

            revisarTodo="1";

            idRol = recuperarDatoLocal("idRol");
            
            let email=recuperarDatoLocal("email");
            let password=recuperarDatoLocal("password");

            if(email!==""){
                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                tempArreglo[0].valor=email;
                tempArreglo[0].error="";
                setArrCampos(tempArreglo);
            }

            if(email!=="" && password!==""){
                if(superaTiempoActualizacion(idModulo)) BD_solicitarDatos("ACCESO",[email,password]);
                else {
                    let datosModulo=recuperarDatoLocal("datos" + idModulo);

                    if(datosModulo!=="") {
                        presentarLog(nomClase + " - RECUPERA_DATOS"); 
                        procesarInfoAcceso("PARCIAL",datosModulo,[email,password]);
                    }
                    else BD_solicitarDatos("ACCESO",[email,password]);
                }
            }
            else {
                idRol="";
                setIngresando(false);
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, []);

    

    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let separador=SEPARADOR_PARAMETROS_BD;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){
                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

                if(tipoDatos==="ACCESO"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleUsuario";
                    consulta="INFO_DETALLE_ACCESO";

                    let txtRevisarTodo="1";
                    if(!revisarTodo) txtRevisarTodo="0";
                    
                    let email=params[0];
                    let password=params[1];
                    paramQuery= email + separador + password + separador + txtRevisarTodo;

                } else if(tipoDatos==="VALIDAR_CORREO"){
                    tipoConsulta="consultarDato";
                    funcion="getListasDetalleUsuario";
                    consulta="VERIFICAR_CORREO";

                    let email=params[0];
                    paramQuery= email;
                } else if(tipoDatos==="ENVIAR_CODIGO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="NOTIFICAR_CODIGO_CORREO";
                    
                    let idusuario=params[0];

                    let num=parseInt(Math.random()*999999);
                    codigoFinal="000000" + num;
                    let longitud=codigoFinal.length;
                    codigoFinal=codigoFinal.substring(longitud-6);

                    paramQuery= idusuario + separador + codigoFinal;
                } else if(tipoDatos==="VALIDAR_CODIGO"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleUsuario";
                    consulta="INFO_CAMBIO_PASSWORD";

                    let idusuario=params[0];
                    paramQuery= idusuario;
                } else if(tipoDatos==="CAMBIAR_PASSWORD"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="CAMBIAR_PASSWORD";

                    let newPassword=arrCampos[6].valor;
                    paramQuery= c_idregistro + separador + newPassword + separador + c_idUsuario;
                } else if(tipoDatos==="VALIDAR_REGISTRO_TELEFONO"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleUsuario";
                    consulta="VALIDAR_REGISTRO_TELEFONO";

                    let telefono=arrCampos[8].valor;
                    paramQuery= telefono;
                } else if(tipoDatos==="REGISTRAR_NUEVO_USUARIO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="REGISTRAR_NUEVO_USUARIO";

                    let documento=arrCampos[9].valor.trim();
                    let nombre=quitarCharEspeciales(formatearTexto(arrCampos[10].valor,2));
                    let apellido=quitarCharEspeciales(formatearTexto(arrCampos[11].valor,2));
                    let telefono=arrCampos[8].valor;
                    let correo=arrCampos[12].valor.trim();
                    let password=arrCampos[13].valor;

                    let aceptados="";
                    let rechazados="";
                    for(let i=0;i<infoRoles.length;i++){
                        if(infoRoles[i].aceptado===true){
                            if(aceptados!=="") aceptados=aceptados + "," + infoRoles[i].idregistro;
                            else aceptados=infoRoles[i].idregistro;
                        } 
                        else{
                            if(rechazados!=="") rechazados=rechazados + "," + infoRoles[i].idregistro;
                            else rechazados=infoRoles[i].idregistro;
                        }
                    }

                    if(aceptados!=="") aceptados="(" + aceptados + ")";
                    if(rechazados!=="") rechazados="(" + rechazados + ")";
                    
                    paramQuery= documento + SEP + nombre + SEP + apellido + SEP + telefono + SEP + correo + SEP + password + SEP + aceptados + SEP + rechazados;

                } else if(tipoDatos==="ASOCIAR_NUEVOS_ROLES"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="ASOCIAR_NUEVOS_ROLES";

                    let aceptados="";
                    let rechazados="";
                    for(let i=0;i<infoNewRoles.length;i++){
                        if(infoNewRoles[i].aceptado===true){
                            if(aceptados!=="") aceptados=aceptados + "," + infoNewRoles[i].idregistro;
                            else aceptados=infoNewRoles[i].idregistro;
                        } 
                        else{
                            if(rechazados!=="") rechazados=rechazados + "," + infoNewRoles[i].idregistro;
                            else rechazados=infoNewRoles[i].idregistro;
                        }
                    }

                    if(aceptados!=="") aceptados="(" + aceptados + ")";
                    if(rechazados!=="") rechazados="(" + rechazados + ")";
                    
                    paramQuery= idUsuario + SEP + aceptados + SEP + rechazados;


                } else if(tipoDatos==="ENVIAR_MENSAJE_CONTACTO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarUsuario";
                    consulta="ENVIAR_MENSAJE_CONTACTO"; 
                    mostrarProcesando=false;

                    paramQuery=  params;                    
                }     
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    respuesta=res;
                    if (respuesta.exito) {
                        if(tipoDatos==="ACCESO"){
                            presentarLog(nomClase + " - SOLICITA_DATOS"); 
                            guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                            guardarDatoLocal("datos" + idModulo, respuesta.datos);

                            procesarInfoAcceso("TOTAL",respuesta.datos,params);

                            registrarLogEventos(idModulo, "1", "","" );
                            
                        } else if(tipoDatos==="VALIDAR_CORREO"){
                            let idusuario=valorNumero(respuesta.datos);

                            if(isOpenPassword){
                                if(idusuario>0){
                                    if(arrCampos[3].valor==="")
                                        BD_solicitarDatos("ENVIAR_CODIGO",[idusuario]);
                                    else
                                        BD_solicitarDatos("VALIDAR_CODIGO",[idusuario]);
                                }
                                else{
                                    tempArreglo[2].error="El correo NO corresponde a un usuario MarcaGol válido.";
                                    setIndicadorAceptar(0);
                                    setArrCampos(tempArreglo);
                                }
    
                            } else if(isOpenRegistro){
                                if(idusuario>0){
                                    tempArreglo[12].error="Correo electrónico YA ESTA REGISTRADO en MarcaGol";
                                    setIndicadorAceptar(3);
                                    setClickAceptarModal(true);
                                    setArrCampos(tempArreglo);
                                    let msj="El proceso de registro en MarcaGol se realiza una UNICA VEZ, si se registró previamente acceda a su cuenta con el correo y clave registrados previamente. Si nunca ha realizado un registro contacte la línea de soporte MarcaGol para ayudarle.";
                                    setMsjModal(msj);
                                }
                                else{
                                    setIndicadorAceptar(4);
                                    setClickAceptarModal(false);
                                    setMsjModal("");
                                }
                            }

                            
                        } else if(tipoDatos==="ENVIAR_CODIGO"){                            
                            let msj="Se ha enviado correo con el código requerido para realizar el cambio de contraseña.\nNo olvides verificar la bandeja de spam y marcar nuestro correo como correo seguro.";
                            setMsjModal(msj);
                            setIndicadorAceptar(2);

                            try{
                                let temp=arrCampos[2].valor.split("@");
                                let datos=[temp[0],temp[1],codigoFinal];
                                callUrl().get("CORREO_PASSWORD", datos);
                            }catch(e){}
                            
                        } else if(tipoDatos==="VALIDAR_CODIGO"){  
                            let campos=respuesta.datos;
                            let tempCodigo="000000" + campos[0][2];
                            let longitud=tempCodigo.length;
                            tempCodigo=tempCodigo.substring(longitud-6);
                            if(tempCodigo===arrCampos[3].valor){
                                c_idregistro=campos[0][0];
                                c_idUsuario=campos[0][1];
                                
                                setClickAceptarModal(false);
                                setIndicadorAceptar(3);
                            }
                            else{
                                c_idregistro="0";
                                c_idUsuario="0";
                                tempArreglo[3].error="El código ingresado no corresponde al enviado, vuelva a intentarlo o solicite un nuevo código.";
                                setIndicadorAceptar(0);
                                setArrCampos(tempArreglo);
                            }
                        } else if(tipoDatos==="CAMBIAR_PASSWORD"){
                            c_idUsuario="0";
                            c_idregistro="0";
                            setIndicadorAceptar(4);
                        } else if(tipoDatos==="VALIDAR_REGISTRO_TELEFONO"){
                            crearInfoRoles(crearTabla(respuesta.datos,"1"));
                            crearInfoUserTel(crearTabla(respuesta.datos,"2"));

                            if(infoRolesO.length===0 && infoUserTel.length===0){
                                let msj="El número ingresado NO ESTA INSCRITO en ningun torneo gestionado en MarcaGol, por favor consulta con el delegado del equipo o el organizador del torneo para verificar los datos de registro.";
                                setMsjModal(msj);
                                setIndicadorAceptar(1); 
                            }
                            else if(infoRolesO.length===0 && infoUserTel.length>0){
                                let msj="El número ingresado YA ESTA REGISTRADO en MarcaGol. \n\nEl proceso de registro se realiza una única vez. Si el usuario registrado es tuyo, accede a tu cuenta con el correo y clave registradas. Si no corresponde, contacta nuestra línea de soporte para ayudarte.";
                                setMsjModal(msj);
                                setIndicadorAceptar(1); 
                            } else if(infoRolesO.length>0){
                                setMsjModal("");
                                setInfoRoles(infoRolesO);
                                setIndicadorAceptar(2); 
                            }


                            registrarLogEventos(idModulo, "3","0","0");
                        } else if(tipoDatos==="REGISTRAR_NUEVO_USUARIO"){                            
                            setIndicadorAceptar(5);
                            registrarLogEventos(idModulo,"7","0","0");
                        } else if(tipoDatos==="ASOCIAR_NUEVOS_ROLES"){                           
                            revisarTodo=false;
                            BD_solicitarDatos("ACCESO",[arrCampos[0].valor, arrCampos[1].valor]);
                            registrarLogEventos(idModulo,"6","0","0");
                        } else if(tipoDatos==="ENVIAR_MENSAJE_CONTACTO"){
                            ventanaMensaje=showMensajePantalla("MENSAJE RECIBIDO","Gracias por comunicarte con MarcaGol. Muy pronto nos contactaremos contigo.",setMsjFlotante,null,"EXITO","MEDIA");

                            try{
                                let retorno=dividirListaNueva(params,SEPARADOR_PARAMETROS_BD);

                                let nombre=retorno[3];
                                let telefono=retorno[7];
                                let msj="Nombre:" + nombre.replaceAll(" ","") + "Telefono:" + telefono.replaceAll(" ","");
                                let datos=[msj];
                                callUrl().get("CORREO_CONTACTO", datos);
                            }catch(e){}

                        }                                                       
        
                    } else {
                        
                        if(respuesta.conectado){
                            reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                            setIngresando(false);
                            if(mostrarError) setEsError(1);
                        } 
                        else{    
                            presentarError(nomClase,nomFuncion,respuesta.mensaje,"");
                            setIngresando(false);                        
                            setEsError(2);
                        } 

                    
                    }
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    }  
    
    const procesarInfoAcceso = (tipo, datos, params) => {
        let nomFuncion="procesarInfoAcceso"; 

        try{            
            if(tipo==="TOTAL"){
                crearInfoUsuario(crearTabla(datos,"1"));
                crearInfoNuevosRoles(crearTabla(datos,"2"));
                crearInfoUsuarioBase(crearTabla(datos,"4"));
            } else{
                crearInfoUsuario(crearTabla(datos,"1"));
                crearInfoNuevosRoles(crearTabla("","2"));
                crearInfoUsuarioBase(crearTabla(datos,"4"));
                //crearInfoPermisos(crearTabla(respuesta.datos,"5"));   
            }


            if(infoUsuarioBaseO.length>=1){
                guardarDatoLocal("email",params[0]);
                guardarDatoLocal("password",params[1]);
                if(infoUsuarioBaseO[0].activo==="1"){

                    if(infoNuevosRoles.length>0) presentarListaNuevosRoles();
                    else{
                        if(infoUsuarioO.length>0) resultadoValidacion();
                        else {
                            guardarDatoLocal("password","");  
                            presentarMensaje("USUARIO_INACTIVO"); //Sin Roles Activos
                            setIngresando(false);
                        }
                    }
                }
                else{
                    guardarDatoLocal("password","");  
                    presentarMensaje("USUARIO_INACTIVO");
                    setIngresando(false);
                }

                guardarDatoLocal("idUser",infoUsuarioBaseO[0].idusuario);
            } else{
                guardarDatoLocal("password","");   
                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));     
                tempArreglo[0].valor=params[0];
                if(params[0]!=="" && esformatoEmail(params[0])) tempArreglo[0].error="";
                else tempArreglo[0].error=matrizCampos[0].error;                        
                tempArreglo[1].valor="";
                tempArreglo[1].error="* Usuario o contraseña Invalidos";

                setArrCampos(tempArreglo);
                setIngresando(false);
            } 

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }    
    }

    const clickGenerico = (donde, params=null) => {
        let nomFuncion="clickGenerico"; 

        try{
            
            switch(donde) {
                case "BTN_INGRESAR":
                    setClickAceptar(true);
                    if(arrCampos[0].error==="" && arrCampos[1].error===""){
                        BD_solicitarDatos("ACCESO",[arrCampos[0].valor,arrCampos[1].valor]);
                    }
                     
                    break;
                case "MD_CB_TERMINOS":
                    let temp=JSON.parse(JSON.stringify(arrCampos));
                    temp[5].valor=!arrCampos[5].valor;
                    if(temp[5].valor===true) temp[5].error="";
                    else temp[5].error="Debe revisar y ACEPTAR los Terminos y Condiciones de Uso de MarcaGol para poder continuar";
                    setArrCampos(temp);

                    break;
                case "BTN_ESPECTADOR":
                    let telInvitado=recuperarDatoLocal("telInvitado");
                    let idInvitado=recuperarDatoLocal("idInvitado");
                              
                    if(telInvitado!==null){
                        telInvitado=telInvitado.replaceAll(".","");
                        telInvitado=telInvitado.replaceAll("+","");
                        telInvitado=telInvitado.replaceAll("-","");
                    }                    
            
                    if(telInvitado!==null && telInvitado.length>=10 && telInvitado.length<=14 && valorLong(telInvitado)>0){
                        if(idInvitado===null || idInvitado==="" || idInvitado.length>9){
                            idInvitado="" + Date.now();
                            if(idInvitado.length>9) idInvitado=idInvitado.substring(idInvitado.length-9,idInvitado.length);
                        } 
                        
                        guardarDatoLocal("telInvitado",telInvitado);
                        guardarDatoLocal("idInvitado",idInvitado);
                        guardarDatoLocal("idUser",idInvitado);
                        guardarDatoLocal("tipoUser","invitado");
                        definirModulo(0,idModulo,"",ID_MODULO_PRINCIPAL_INVITADO,"");   
                        //setClickAceptarModal(false);                         
                        //openEspectador();
                    }    
                    else{
                        setClickAceptarModal(false);
                        openEspectador();
                    } 

                    break;
                case "ACEPTAR_ESPECTADOR":
                    setClickAceptarModal(true);
                    if(arrCampos[4].error==="" && arrCampos[5].error===""){
                        let idInvitado="" + Date.now();
                        if(idInvitado.length>9) idInvitado=idInvitado.substring(idInvitado.length-9,idInvitado.length);

                        guardarDatoLocal("idInvitado",idInvitado);
                        guardarDatoLocal("idUser",idInvitado);

                        guardarDatoLocal("tipoUser","invitado")
                        guardarDatoLocal("telInvitado",arrCampos[4].valor);
                        definirModulo(0,idModulo,"",ID_MODULO_PRINCIPAL_INVITADO,"");   
                    }

                    break;
                case "CERRAR_ESPECTADOR":
                    c_idUsuario="0";
                    c_idregistro="0";
                    setArrCampos(matrizCampos);
                    setClickAceptarModal(false);
                    setIndicadorAceptar(0);
                    closeEspectador();

                    break;
                case "LNK_PASSWORD":
                    setClickAceptarModal(false);
                    openPassword();

                    break; 
                case "ACEPTAR_PASSWORD":
                    setClickAceptarModal(true);
                    if(indicadorAceptar===0){
                        if(arrCampos[2].error==="" && arrCampos[3].error==="" && arrCampos[3].valor===""){
                            let msj="Esta acción creará y enviará a tu correo un código para reestablecer tu contraseña, si ya solicitaste un código previamente quedará inactivo. De acuerdo?";
                            setMsjModal(msj);
                            setIndicadorAceptar(1);                                
                        }
                        else if(arrCampos[2].error==="" && arrCampos[3].error==="" && arrCampos[3].valor!==""){
                            BD_solicitarDatos("VALIDAR_CORREO",[arrCampos[2].valor]);
                        }
                    } else if(indicadorAceptar===1){
                        if(arrCampos[2].error==="" && arrCampos[3].error==="")
                            BD_solicitarDatos("VALIDAR_CORREO",[arrCampos[2].valor]);
                    } else if(indicadorAceptar===2){                            
                        if(arrCampos[2].error==="" && arrCampos[3].error==="" && arrCampos[3].valor===""){
                            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                            tempArreglo[3].error="El código de recuperación YA fue enviado, debe ingresar el código recibido en el correo electronico para continuar.";    
                            setArrCampos(tempArreglo);      
                            setMsjModal("");                 
                        }
                        else if(arrCampos[2].error==="" && arrCampos[3].error===""  && arrCampos[3].valor.length===6){
                            BD_solicitarDatos("VALIDAR_CORREO",[arrCampos[2].valor]);
                        }
                        else{
                            let msj="";
                            setMsjModal(msj);
                        }
                    } else if(indicadorAceptar===3){
                        if(arrCampos[6].error==="" && arrCampos[7].error===""){
                            BD_solicitarDatos("CAMBIAR_PASSWORD",[arrCampos[6].valor]);
                        }                               
                    } else if(indicadorAceptar===4){
                        c_idUsuario="0";
                        c_idregistro="0";
                        setArrCampos(matrizCampos);
                        setClickAceptarModal(false);
                        setIndicadorAceptar(0);
                        closePassword();
                    }                  

                    break;
                case "CERRAR_PASSWORD":
                    c_idUsuario="0";
                    c_idregistro="0";
                    setArrCampos(matrizCampos);
                    setClickAceptarModal(false);
                    setIndicadorAceptar(0);
                    closePassword();

                    break;
                case "LNK_REGISTRO":
                    setClickAceptarModal(false);
                    openRegistro();

                    break;
                case "ACEPTAR_REGISTRO":
                    setClickAceptarModal(true);
                    if(indicadorAceptar<=1){
                        if(arrCampos[8].error===""){
                            BD_solicitarDatos("VALIDAR_REGISTRO_TELEFONO",[""]);
                        }
                    } else if(indicadorAceptar===2){
                        let i=0;
                        let hayRolesAceptados=false;
                        for(i=0;i<infoRoles.length;i++){
                            if(infoRoles[i].aceptado){
                                hayRolesAceptados=true;
                                i=infoRoles.length;
                            }
                        }
                        if(hayRolesAceptados){
                            setIndicadorAceptar(3);
                            setClickAceptarModal(false);
                            setMsjModal("");
                        } 
                        else{
                            let msj="Debe ACEPTAR al menos un rol para poder continuar con el registro y creación de su Usuario MarcaGol";
                            setMsjModal(msj);
                        }
                    } else if(indicadorAceptar===4){
                        let hayErrores=false;
                        let i=9;
                        for(i=9;i<=15;i++){
                            if(arrCampos[i].error!=="") hayErrores=true;
                        }

                        if(!hayErrores){
                            BD_solicitarDatos("REGISTRAR_NUEVO_USUARIO",[""]);
                        }
                    } else if(indicadorAceptar===5){
                        clickGenerico("CERRAR_REGISTRO");
                    }


                    break;
                case "CERRAR_REGISTRO":
                    if(indicadorAceptar===5){
                        let correoFinal=arrCampos[12].valor;

                        setArrCampos(matrizCampos);
                        setClickAceptarModal(false);
                        setIndicadorAceptar(0);
                        closeRegistro();

                        let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                        tempArreglo[0].valor=correoFinal;
                        tempArreglo[0].error="";
                        setArrCampos(tempArreglo);
                    }
                    else{
                        setArrCampos(matrizCampos);
                        setClickAceptarModal(false);
                        setIndicadorAceptar(0);
                        closeRegistro();
                    }                        
    
                    break;
                case "MD3_CB_TERMINOS":
                    let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    tempArreglo[15].valor=!arrCampos[15].valor;
                    if(tempArreglo[15].valor===true) tempArreglo[15].error="";
                    else tempArreglo[15].error="Debe revisar y ACEPTAR los Terminos y Condiciones de Uso de MarcaGol para poder continuar";
                    setArrCampos(tempArreglo);

                    break;
                case "MD3_VALIDAR_CORREO":
                    if(arrCampos[12].error===""){
                        BD_solicitarDatos("VALIDAR_CORREO",[arrCampos[12].valor]);
                    }

                    break;
                case "CERRAR_NEWROLES":
                    setClickAceptarModal(false);
                    setIndicadorAceptar(0);
                    closeNewRoles();    
                    
                    resultadoValidacion();
    
                    break;
                case "ACEPTAR_NEWROLES":
                    setClickAceptarModal(true);
                    if(indicadorAceptar===0){
                        let i=0;
                        let hayRolesAceptados=false;
                        for(i=0;i<infoNewRoles.length;i++){
                            if(infoNewRoles[i].aceptado){
                                hayRolesAceptados=true;
                                i=infoNewRoles.length;
                            }
                        }
                        if(hayRolesAceptados){
                            setClickAceptarModal(false);
                            setIndicadorAceptar(0);
                            closeNewRoles();   

                            BD_solicitarDatos("ASOCIAR_NUEVOS_ROLES",[""]);
                        } 
                        else{
                            let msj="Debe Aceptar o Rechazar los nuevos Roles asociados, si prefiere realizarlo despues seleccione CANCELAR.";
                            setMsjModal(msj);
                        }
                    }
    
                    break;
                case "ENTER_PASSWORD":
                    if(eEnter.keyCode===13){
                        setClickAceptar(true);
                        if(arrCampos[0].error==="" && arrCampos[1].error===""){
                            BD_solicitarDatos("ACCESO",[arrCampos[0].valor,arrCampos[1].valor]);
                        }
                    }                    
                    
                     
                    break;
                case "ABRIR_VENTANA_CONTACTOMG":
                    //definirModulo(0, idModulo , "", ID_MODULO_CONTACTOMG, null);
                    setShowContactoMG(true);

                    break;
                case "ENVIAR_MENSAJE_CONTACTO":
                    BD_solicitarDatos("ENVIAR_MENSAJE_CONTACTO", params);

                break;
                default:
                    break;
              }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    }   

    const validarCampo = (e, campo, maxlen) => {
        let nomFuncion="validarCampo"; 

        try{
            let newValor = e.target.value;
            if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);

            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

            if(campo==="CORREO"){
                tempArreglo[0].error=matrizCampos[0].error;
                tempArreglo[0].valor=newValor;
                if(esformatoEmail(newValor)){
                    tempArreglo[0].error="";
                }
                setArrCampos(tempArreglo);    
            } else if(campo==="PASSWORD"){
                tempArreglo[1].error=matrizCampos[1].error;
                tempArreglo[1].valor=newValor;
                if(newValor.length>=8){
                    tempArreglo[1].error="";
                }
                setArrCampos(tempArreglo);    
            } else if(campo==="MD1_CORREO"){
                tempArreglo[2].error=matrizCampos[2].error;
                tempArreglo[2].valor=newValor;
                if(esformatoEmail(newValor)){
                    tempArreglo[2].error="";
                }
                setArrCampos(tempArreglo);    
            } else if(campo==="MD1_CODIGO"){
                tempArreglo[3].error="";
                if(newValor===""){
                    tempArreglo[3].valor="";
                }
                else{
                    tempArreglo[3].error="Codigo debe ser de 6 digitos";
                    if(esformatoNumero(newValor) && newValor.length<=6) {
                        if(newValor.length===6) {
                            tempArreglo[3].error="";
                        }
                        tempArreglo[3].valor=newValor;
                    }
                }   
                if(tempArreglo[3].valor!==arrCampos[3].valor) setArrCampos(tempArreglo);                
            } else if(campo==="MD2_TELEFONO"){  
                tempArreglo[4].error=matrizCampos[4].error;
                if(newValor===""){
                    tempArreglo[4].valor="";                    
                }
                else{
                    if(esformatoNumero(newValor) && newValor.length<=14){
                        tempArreglo[4].valor=newValor;
                        if(newValor.length>=10){
                            tempArreglo[4].error="";
                        }
                        else tempArreglo[4].error="Longitud mínima de 10 digitos";
                    }                     
                }   
                
                setArrCampos(tempArreglo);
            } else if(campo==="MD1_NEWPASSWORD1"){
                tempArreglo[6].error=matrizCampos[6].error;
                tempArreglo[6].valor=newValor;
                if(newValor.length>=8){
                    tempArreglo[6].error="";

                    if(arrCampos[7].valor!=="" && arrCampos[7].valor.length>=8){
                        if(newValor!==arrCampos[7].valor) tempArreglo[7].error="Contraseñas NO coinciden";
                        else tempArreglo[7].error="";
                    }
                }                

                setArrCampos(tempArreglo);    
            } else if(campo==="MD1_NEWPASSWORD2"){
                tempArreglo[7].error=matrizCampos[7].error;
                tempArreglo[7].valor=newValor;
                if(newValor.length>=8){
                    if(newValor===arrCampos[6].valor) tempArreglo[7].error="";
                    else tempArreglo[7].error="Contraseñas NO coinciden";
                }
                setArrCampos(tempArreglo);    
            } else if(campo==="MD3_TELEFONO"){  
                tempArreglo[8].error=matrizCampos[8].error;
                if(newValor===""){
                    tempArreglo[8].valor="";                    
                }
                else{
                    if(esformatoNumero(newValor) && newValor.length<=14){
                        tempArreglo[8].valor=newValor;
                        if(newValor.length>=10){
                            tempArreglo[8].error="";
                        }
                        else tempArreglo[8].error="Longitud mínima de 10 digitos";
                    }                     
                }   
                
                setArrCampos(tempArreglo);
            } else if(campo==="MD3_DOCUMENTO"){  
                tempArreglo[9].error=matrizCampos[9].error;
                if(newValor===""){
                    tempArreglo[9].valor="";                    
                }
                else{
                    if(esformatoNumero(newValor) && newValor.length<=maxlen){
                        tempArreglo[9].valor=newValor;
                        if(newValor.length>=5){
                            tempArreglo[9].error="";
                        }
                        else tempArreglo[9].error="Longitud mínima de 5 digitos";
                    }                     
                }   
                
                setArrCampos(tempArreglo);
            } else if(campo==="MD3_NOMBRE"){  
                tempArreglo[10].error=matrizCampos[10].error;
                if(newValor===""){
                    tempArreglo[10].valor="";                    
                }
                else{
                    tempArreglo[10].valor=newValor; 
                    if(newValor.length>=5) tempArreglo[10].error="";                  
                }   
                
                setArrCampos(tempArreglo);
            } else if(campo==="MD3_APELLIDO"){  
                tempArreglo[11].error="";
                tempArreglo[11].valor=newValor;                 
                
                setArrCampos(tempArreglo);
            } else if(campo==="MD3_CORREO"){
                tempArreglo[12].error=matrizCampos[12].error;
                tempArreglo[12].valor=newValor;
                if(esformatoEmail(newValor)){
                    tempArreglo[12].error="";
                }
                setArrCampos(tempArreglo);    
            } else if(campo==="MD3_PASSWORD1"){
                tempArreglo[13].error=matrizCampos[13].error;
                tempArreglo[13].valor=newValor;
                if(newValor.length>=8){
                    tempArreglo[13].error="";

                    if(arrCampos[14].valor!=="" && arrCampos[14].valor.length>=8){
                        if(newValor!==arrCampos[14].valor) tempArreglo[14].error="Contraseñas NO coinciden";
                        else tempArreglo[14].error="";
                    }
                }                

                setArrCampos(tempArreglo);    
            } else if(campo==="MD3_PASSWORD2"){
                tempArreglo[14].error=matrizCampos[14].error;
                tempArreglo[14].valor=newValor;
                if(newValor.length>=8){
                    if(newValor===arrCampos[13].valor) tempArreglo[14].error="";
                    else tempArreglo[14].error="Contraseñas NO coinciden";
                }
                setArrCampos(tempArreglo);    
            }
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
        
    }

    const aceptarRechazar = (index, donde) => {
        let nomFuncion="aceptarRechazar"; 

        try{
            let tempRoles=[];
            if(donde==="ACEPTAR" || donde==="RECHAZAR") tempRoles=JSON.parse(JSON.stringify(infoRoles));
            else if(donde==="ACEPTAR_NR" || donde==="RECHAZAR_NR") tempRoles=JSON.parse(JSON.stringify(infoNewRoles));

            if(donde==="ACEPTAR" && tempRoles[index].aceptado===false) tempRoles[index].aceptado=true;
            if(donde==="RECHAZAR" && tempRoles[index].aceptado===true) tempRoles[index].aceptado=false;
            if(donde==="ACEPTAR_NR" && tempRoles[index].aceptado===false) tempRoles[index].aceptado=true;
            if(donde==="RECHAZAR_NR" && tempRoles[index].aceptado===true) tempRoles[index].aceptado=false;
    
            if(donde==="ACEPTAR" || donde==="RECHAZAR") setInfoRoles(tempRoles);
            else if(donde==="ACEPTAR_NR" || donde==="RECHAZAR_NR") setInfoNewRoles(tempRoles);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
    }

    const presentarMensaje = (tipo, tit="", msj="") => {
        let nomFuncion="presentarMensaje"; 

        try{

            if(tipo==="USUARIO_INACTIVO"){
                txtTitulo="Usuario Inactivo";
                txtMensaje="Tu usuario se encuentra INACTIVO en MarcaGol, para revisar la razón o solicitar su activación por favor contactanos.";
            }else if(tipo==="SIN_ROLES"){
                txtTitulo="Sin Roles Activos";
                txtMensaje="Actualmente NO TIENE roles (Organizador, Jugador o Arbitro) de empresas Activas en MarcaGol, por lo que no puede acceder a su cuenta.\n\nPara disfrutar de todo lo que te trae MarcaGol inscribete en torneos gestionados con MarcaGol o consulta los torneos actuales desde la sección para ESPECTADORES";
            }else if(tipo==="NO_JUGADOR"){
                txtTitulo="Acceso Exclusivo para Jugadores";
                txtMensaje="Su cuenta NO TIENE roles de JUGADOR de empresas Activas en MarcaGol, por lo que no puede acceder a su cuenta.\n\nPara disfrutar de todo lo que te trae MarcaGol inscribete en torneos gestionados con MarcaGol o consulta los torneos actuales desde la sección para ESPECTADORES";
            }
            else{
                txtTitulo=tit;
                txtMensaje=msj;
            }

            setShowMensaje(true);


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
    }

    const presentarListaNuevosRoles = () => {
        let nomFuncion="presentarListaNuevosRoles"; 

        try{

            if(infoNuevosRolesO.length>0){

                let cantRoles=0;
                let i;
                
                let tempNewRoles=[];
                for(i=0;i<infoNuevosRoles.length;i++){
                    if(infoNuevosRoles[i][10]!==""){                        
                        cantRoles++;
                        tempNewRoles.push(infoNuevosRolesO[i]);                        
                    }
                }

                if(cantRoles===0){
                    resultadoValidacion();
                }
                else {
                    setIngresando(false);
                    setInfoNewRoles(tempNewRoles);
                    idUsuario=infoUsuarioBaseO[0].idusuario;
                    openNewRoles();
                }

            }
            else{
                resultadoValidacion();
            }


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }

    const resultadoValidacion = () => {
        let nomFuncion="resultadoValidacion";

        try{

            tieneRoles=false;
            let contRoles=0;

            if(infoUsuario.length>0){

                let roles=[];               

                if(infoUsuario.length>1){
                    let i;
                    let rolActual="";

                    for(i=0;i<infoUsuario.length;i++){
                        let tempRoles= ["","","",""];
                        if(rolActual!==infoUsuario[i][1]){
                            tempRoles[0]=infoUsuario[i][1]; //idrol
                            tempRoles[1]="" + i; //fila en infoUsuario
                            tempRoles[2]=infoUsuario[i][5]; //Nombre empresa
                            tempRoles[3]=infoUsuario[i][7]; //Nombre sede

                            roles.push(tempRoles);

                            rolActual=infoUsuario[i][1];
                            contRoles++;

                            if(contRoles>1) tieneRoles=true;
                        }
                        else{
                            tieneRoles=true;
                            if(roles[contRoles-1][2]!==infoUsuario[i][5] && roles[contRoles-1][2]!=="Varias Empresas"){
                                roles[contRoles-1][2]="Varias Empresas";
                                roles[contRoles-1][3]="Varias Sedes";                                
                            }

                            if(roles[contRoles-1][3]!==infoUsuario[i][7] && roles[contRoles-1][3]!=="Varias Sedes"){
                                roles[contRoles-1][3]="Varias Sedes";
                            }
                        }

                    }

                }


                if(contRoles<=1){

                    if(infoUsuario[0][8]==="1"){
                        try{
                            guardarDatoLocal("zonahoraria",infoUsuario[0][9]);
                            guardarDatoLocal("telefono",infoUsuario[0][10]);
                            guardarDatoLocal("nom_zonahoraria",infoUsuario[0][11]);
                            guardarDatoLocal("indicativo",infoUsuario[0][12]);
                        }catch (err){         
                            presentarError(nomClase,nomFuncion,"",err);                   
                        }

                        if(infoUsuario[0][3].toLowerCase() ==="jugador"){
                            //if(idRol!=="4") idTorneoNotificacion="";
                            idUser=infoUsuario[0][0];
                            tipoUser=infoUsuario[0][3];
                            
                            ingresoJugador(infoUsuario[0][2]);
                        }
                        else if(infoUsuario[0][3].toLowerCase()==="arbitro"){
                            //if(idRol!=="3") idSedeNotificacion="";
                            idUser=infoUsuario[0][0];
                            tipoUser=infoUsuario[0][3];
                            //alert("Rol ARBITRO no implementado, descargue la app para Android");
                            guardarDatoLocal("password","");
                            presentarMensaje("", "Acceso Exclusivo JUGADORES", "Actualmente MarcaGol Web tiene acceso exclusivo para Jugadores y Espectadores. Para Organizadores o Arbitros es necesario instalen la aplicación Android desde el PlayStore")
                            //ingresoArbitro(infoUsuario[0][2],infoUsuario[0][6]);
                        }
                        else if(infoUsuario[0][3].toLowerCase()==="administrador"){
                            idUser=infoUsuario[0][0];
                            tipoUser=infoUsuario[0][3];
                            //alert("Rol ADMINISTRADOR no implementado, descargue la app para Android");
                            //ingresoAdmin(infoUsuario[0][6]);
                            guardarDatoLocal("password","");
                            presentarMensaje("", "Acceso Exclusivo JUGADORES", "Actualmente MarcaGol Web tiene acceso exclusivo para Jugadores y Espectadores. Para Organizadores o Arbitros es necesario instalen la aplicación Android desde el PlayStore")
                            
                        }
                        else if(infoUsuario[0][3].toLowerCase()==="superadmin"){
                            //alert("Rol NO implementado, por favor contacte a soporte.");
                            guardarDatoLocal("password","");
                            presentarMensaje("", "Acceso Exclusivo JUGADORES", "Actualmente MarcaGol Web tiene acceso exclusivo para Jugadores y Espectadores. Para Organizadores o Arbitros es necesario instalen la aplicación Android desde el PlayStore")
                            
                        }
                        else {
                            //alert("Rol NO implementado, por favor contacte a soporte.");
                            guardarDatoLocal("password","");
                            presentarMensaje("", "Acceso Exclusivo JUGADORES", "Actualmente MarcaGol Web tiene acceso exclusivo para Jugadores y Espectadores. Para Organizadores o Arbitros es necesario instalen la aplicación Android desde el PlayStore")
                            
                        }
                    }
                    else{
                        presentarMensaje("USUARIO_INACTIVO");
                    }
                }
                else{
                    let tempIdUser="";
                    let tempTipoUser="";
                    let tempIdSede="";
                    let tempIdAsociado="";
                    let encontroUser=false;

                    try{
                        tempIdUser = recuperarDatoLocal("idUser");
                        tempTipoUser = recuperarDatoLocal("tipoUser");
                        tempIdSede = recuperarDatoLocal("idSede");
                        tempIdAsociado = recuperarDatoLocal("idAsociado");                      
                    }catch(err){
                        presentarError(nomClase, nomFuncion,"",err);
                    }

                        let i=0;
                        let i_sel=0;
                        for(i=0;i<infoUsuario.length;i++){
                            if(infoUsuario[i][8]==="1"){

                                if(idRol===infoUsuario[i][1]){
                                    encontroUser=true;
                                    tieneRoles=true;
                                    i_sel=i;
                                    i=infoUsuario.length;
                                }
                                else{
                                    if(tempIdUser===infoUsuario[i][0] && tempTipoUser===infoUsuario[i][3] &&
                                            tempIdSede===infoUsuario[i][6] && tempIdAsociado===infoUsuario[i][2]){

                                        encontroUser=true;
                                        tieneRoles=true;
                                        i_sel=i;

                                    }
                                }

                            }

                        }

                    if(encontroUser) {
                        try{
                            guardarDatoLocal("zonahoraria",infoUsuario[i_sel][9]);
                            guardarDatoLocal("telefono",infoUsuario[i_sel][10]);
                            guardarDatoLocal("nom_zonahoraria",infoUsuario[i_sel][11]);
                            guardarDatoLocal("indicativo",infoUsuario[i_sel][12]);

                        }catch(err){
                            presentarError(nomClase, nomFuncion,"",err);
                        }

                        if(infoUsuario[i_sel][3].toLowerCase()==="jugador"){
                            //if(idRol!=="4") idTorneoNotificacion="";
                            idUser=infoUsuario[i_sel][0];
                            tipoUser=infoUsuario[i_sel][3];
                            ingresoJugador(infoUsuario[i_sel][2]);
                        }
                        else if(infoUsuario[i_sel][3].toLowerCase()==="arbitro"){
                            //if(idRol!=="3") idSedeNotificacion="";
                            idUser=infoUsuario[i_sel][0];
                            tipoUser=infoUsuario[i_sel][3];
                            //ingresoArbitro(infoUsuario[i_sel][2],infoUsuario[i_sel][6]);
                            //alert("Rol ARBITRO no implementado, descargue la app para Android");
                            guardarDatoLocal("password","");
                            presentarMensaje("", "Acceso Exclusivo JUGADORES 2", "Actualmente MarcaGol Web tiene acceso exclusivo para Jugadores y Espectadores. Para Organizadores o Arbitros es necesario instalen la aplicación Android desde el PlayStore")
                            
                        }
                        else if(infoUsuario[i_sel][3].toLowerCase()==="administrador"){
                            idUser=infoUsuario[i_sel][0];
                            tipoUser=infoUsuario[i_sel][3];
                            //ingresoAdmin(infoUsuario[i_sel][6]);
                            //alert("Rol ADMINISTRADOR no implementado, descargue la app para Android");
                            guardarDatoLocal("password","");
                            presentarMensaje("", "Acceso Exclusivo JUGADORES 2", "Actualmente MarcaGol Web tiene acceso exclusivo para Jugadores y Espectadores. Para Organizadores o Arbitros es necesario instalen la aplicación Android desde el PlayStore")
                            
                        }
                    }
                    else{
                        presentarListaRoles(roles);
                    }
                }
            }
            else{
                // password blanco
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const ingresoJugador = (idJugador)  => {
        let nomFuncion="ingresoJugador";

        try{
            if(tieneRoles){
                guardarDatoLocal("tieneRoles","1");
                guardarDatoLocal("infoUsuario",infoUsuarioO);
            } 
            else {
                guardarDatoLocal("tieneRoles","0");
                guardarDatoLocal("infoUsuario","");
            }

            guardarDatoLocal("idUser",idUser);
            guardarDatoLocal("tipoUser",tipoUser);

            definirModulo(0,idModulo,"",ID_MODULO_PRINCIPAL_JUGADOR,idUser);


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const presentarListaRoles = (roles)  => {
        let nomFuncion="presentarListaRoles";

        try{

            let i;
            tieneRoles=false;
            for(i=0;i<roles.length;i++){

                if(roles[i][0]!==""){
                    
                    let fila=valorNumero(roles[i][1]);

                    if(infoUsuario[fila][3].toLowerCase()==="jugador"){
                        tieneRoles=true;
                        idUser=infoUsuario[fila][0];
                        tipoUser=infoUsuario[fila][3];
                        ingresoJugador(infoUsuario[fila][2]);

                        i=roles.length;
                    }

                }
                else i=roles.length;

            }

            if(!tieneRoles){
               presentarMensaje("NO_JUGADOR");
            }


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const salir = () => {       
        definirModulo(0, 0, "", ID_MODULO_BASE, "");
    }


    if(isOpenRegistro){
        if(indicadorAceptar<=1) txtAceptar="BUSCAR";
        if(indicadorAceptar===2) txtAceptar="CONTINUAR";
        if(indicadorAceptar===3) txtAceptar="";
        if(indicadorAceptar===4) txtAceptar="REGISTRAR";
        if(indicadorAceptar===5) txtAceptar="FINALIZAR";
    }


    return (
        <>

            <Modal isOpen={isOpenEspectador} closeModal={closeEspectador}  titulo={"Ingreso Espectador@s"} clickCerrar={() => clickGenerico("CERRAR_ESPECTADOR")} clickAceptar={() => clickGenerico("ACEPTAR_ESPECTADOR")} >
                <div style={{margin:"10px"}}>
                    <div style={{marginTop:"20px"}}>
                        Si eres un ESPECTADOR que desea seguir y ver toda la información de tus torneos MarcaGol favoritos, ingresa tu teléfono y accede a la comunidad MarcaGol.
                    </div>
                    <div style={{marginTop:"20px"}}>
                        Si eres un JUGADOR de algún torneo gestionado con MarcaGol, ingresa a tú cuenta con tu correo y contraseña o ingresa por la sección Registrarme y obtén todos los beneficios de crear tu cuenta MarcaGol.
                    </div>
                    <div style={{marginTop:"0px", display:"flex", justifyContent:"center"}}>
                        <input type="text" value={arrCampos[4].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, flexBasis: "250px", minWidth:"150px", maxWidth: "250px", margin: "20px"}} placeholder="Num. Telefono" onChange={(e) => validarCampo(e, "MD2_TELEFONO", 14)}/>
                    </div>
                    {arrCampos[4].error!=="" && clickAceptarModal && (
                        <div style={{marginTop:"-20px", marginBottom:"20px"}}>
                        <span className="TX_error">{arrCampos[4].error}</span>
                    </div>
                    )}
                    <div style={{marginBottom:"20px", verticalAlign:"center"}}>
                        <input type="checkbox" id="cB_Terminos" checked={arrCampos[5].valor} onChange={() => clickGenerico("MD_CB_TERMINOS")} style={{padding:"8px", cursor:"pointer"}}/>{" Acepto "}<a href={urlTerminos} target="_blank" style={{textDecoration:"none"}}><span className="TX_link">Terminos y Condiciones</span></a>
                    </div>
                    {arrCampos[5].error!=="" && clickAceptarModal && (
                        <div style={{marginTop:"-10px", marginBottom:"20px"}}>
                        <span className="TX_error">{arrCampos[5].error}</span>
                    </div>
                    )}
                </div>              
                
            </Modal>

            <Modal isOpen={isOpenPassword} closeModal={closePassword}  titulo={"Olvide Mi Contraseña"} clickCerrar={() => clickGenerico("CERRAR_PASSWORD")} clickAceptar={() => clickGenerico("ACEPTAR_PASSWORD")}  showCancelar={indicadorAceptar===3 ? (false): (true)} isCancelable={false}>
                {loadingModal && (<Loader tipo={"TOTAL"}/>)}

                <div style={{margin:"10px"}}>

                    {indicadorAceptar<=2 && (
                        <div>
                        <div style={{marginTop:"20px"}}>
                            Ingresa el correo electrónico registrado de Tú cuenta MarcaGol para solicitar el código de recuperación de contraseña.
                        </div>
                        <div style={{marginTop:"20px"}}>
                            Una vez recibido en tú correo, ingresa el código para asignar una nueva contraseña.
                        </div>
                        <div style={{marginTop:"0px", display:"flex", justifyContent:"center"}}>
                            <input type="email" maxLength={40} value={arrCampos[2].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, flexBasis: "250px", minWidth:"150px", maxWidth: "250px", margin: "20px"}} placeholder="Correo Electrónico" onChange={(e) => validarCampo(e, "MD1_CORREO", 40)}/>
                        </div>
                        {arrCampos[2].error!=="" && clickAceptarModal && (
                            <div style={{marginTop:"-20px", marginBottom:"20px"}}>
                                <span className="TX_error">{arrCampos[2].error}</span>
                            </div>
                        )}

                        <div style={{marginTop:"0px", display:"flex", justifyContent:"center"}}>
                            <input type="text" value={arrCampos[3].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, flexBasis: "250px", minWidth:"150px", maxWidth: "250px", marginInline: "20px", marginBottom:"20px"}} placeholder="Código Recibido" onChange={(e) => validarCampo(e, "MD1_CODIGO", 6)}/>
                        </div>
                        {arrCampos[3].error!=="" && clickAceptarModal && (
                            <div style={{marginTop:"-20px", marginBottom:"20px"}}>
                                <span className="TX_error">{arrCampos[3].error}</span>
                            </div>
                        )}
                        

                        {indicadorAceptar>0 && clickAceptarModal && msjModal!=="" && (
                            <div style={{marginTop:"0px", marginBottom:"20px"}}>
                                <span className={indicadorAceptar===1 ? ("TX_sinInfo") : ("TX_exito")}>{msjModal}</span>
                            </div>
                        )}
                        </div>

                    )}

                    {indicadorAceptar===3 && (
                        <>
                        <div style={{marginTop:"20px", marginInline: "10px"}}>
                        Para finalizar el proceso, asigna tú nueva contraseña.
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexWrap:"nowrap", alignItems: "center", justifyContent: "left", verticalAlign:"middle", minWidth:"200px", maxWidth: "400px", marginInline: "10px"}}>
                            <span style={{marginRight:"10px"}}>Correo:</span>
                            <span style={{flexBasis:"200px", minWidth:"100px", maxWidth: "300px", color:C_colorPrimary}}>{arrCampos[2].valor}</span>
                        </div>

                        <div style={{marginTop:"20px", display:"flex", flexWrap:"nowrap", alignItems: "center", justifyContent: "left", verticalAlign:"middle", minWidth:"200px", maxWidth: "400px", marginInline: "10px"}}>
                            <span style={{flexBasis:"200px", minWidth:"100px", maxWidth: "200px", marginRight:"10px"}}>Contraseña Nueva</span>
                            <input type="password" maxLength={20} value={arrCampos[6].valor} className='IP_entradas' style={{flexBasis:"200px", minWidth:"100px", maxWidth: "200px"}} placeholder="" onChange={(e) => validarCampo(e, "MD1_NEWPASSWORD1", 20)}/>
                        </div>

                        {arrCampos[6].error!=="" && clickAceptarModal && (
                            <div style={{marginTop:"0px", marginBottom:"20px"}}>
                                <span className="TX_error">{arrCampos[6].error}</span>
                            </div>
                        )}

                        <div style={{marginBlock:"20px", display:"flex", flexWrap:"nowrap", alignItems: "center", justifyContent: "left", verticalAlign:"middle", minWidth:"200px", maxWidth: "400px", marginInline: "10px"}}>
                            <span style={{flexBasis:"200px", minWidth:"100px", maxWidth: "200px", marginRight:"10px"}}>Repetir Contraseña</span>
                            <input type="password" maxLength={20} value={arrCampos[7].valor} className='IP_entradas' style={{flexBasis:"200px", minWidth:"100px", maxWidth: "200px"}} placeholder="" onChange={(e) => validarCampo(e, "MD1_NEWPASSWORD2", 20)}/>
                        </div>
                        {arrCampos[7].error!=="" && clickAceptarModal && (
                            <div style={{marginTop:"-20px", marginBottom:"20px"}}>
                                <span className="TX_error">{arrCampos[7].error}</span>
                            </div>
                        )}
                        </>
                    )}

                    {indicadorAceptar===4 && (
                        <>
                            <div style={{marginBlock:"40px", marginInline: "20px"}}>
                                {"Contraseña modificada con EXITO."}
                            </div>
                        </>
                    )}
                    

                </div>              
                
            </Modal>

            <Modal isOpen={isOpenRegistro} closeModal={closeRegistro}  titulo={"Registro de Usuarios"} clickCerrar={() => clickGenerico("CERRAR_REGISTRO")} clickAceptar={() => clickGenerico("ACEPTAR_REGISTRO")} isCancelable={false}  txtCancelar={indicadorAceptar===5 ? (""): ("CANCELAR")} txtAceptar={txtAceptar}>
                {loadingModal && (<Loader tipo={"TOTAL"}/>)}

                <div style={{margin:"10px"}}>

                    {indicadorAceptar<2 && (
                        <div>
                            {indicadorAceptar===0 && (
                                <>
                                <div style={{marginTop:"20px"}}>
                                Este proceso de creación de Tú cuenta MarcaGol es para JUGADORES o ARBITROS inscritos en torneos gestionados con MarcaGol.
                                <br></br><br></br>Si eres aficionado o espectador de algún torneo MarcaGol ingresa por la opción para Espectadores.
                                </div>
                                </>
                            )}
                        
                            <div style={{marginTop:"20px"}}>
                                Ingresa tú Teléfono o dato con el cual fuiste inscrito en la Liga/Torneo/Cancha en la que juegas.
                            </div>
                            <div style={{marginTop:"0px", display:"flex", justifyContent:"center"}}>
                                <input type="text" value={arrCampos[8].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, flexBasis: "250px", minWidth:"150px", maxWidth: "250px", margin: "20px"}} placeholder="Num. Telefono" onChange={(e) => validarCampo(e, "MD3_TELEFONO", 14)}/>
                            </div>
                            {arrCampos[8].error!=="" && clickAceptarModal && (
                                <div style={{marginTop:"-20px", marginBottom:"20px"}}>
                                    <span className="TX_error">{arrCampos[8].error}</span>
                                </div>
                            )}                      

                            {indicadorAceptar===1 && clickAceptarModal && msjModal!=="" && (
                                <div style={{marginTop:"0px", marginBottom:"20px", whiteSpace:"pre-line"}}>
                                    <span className="TX_sinInfo">{msjModal}</span>
                                </div>
                            )}
                        </div>

                    )}

                    {indicadorAceptar===2 && (
                         <div>
                            <div style={{marginTop:"20px"}}>
                                <span className="TX_exito" style={{fontWeight:"bold"}}>{"[" + arrCampos[8].valor + "]"}</span>
                                posee Roles inscritos en MarcaGol como parte de una Liga/Torneo/Cancha
                            </div>

                            <div style={{marginTop:"10px", marginBottom:"20px", whiteSpace:"pre-line"}}>
                                    <span className="TX_sinInfo">Acepte o rechace cada rol presentado, de acuerdo a si participa o no en torneos de la empresa u organizador encontrado</span>
                                </div>

                            <div className='BF_cajaGris' style={{marginTop:"10px", marginBottom:"10px"}}>
                                <p className='TX_tituloCaja'>{"Roles registrados en MarcaGol"}</p>
                                <div className='BF_cajaBlanca'>  
                                    <table width="100%" border="0"><tbody>
                                        {infoRoles ? (
                                            infoRoles.map((reg, index) => (<Template_lista_roles key={reg.idregistro} datos={reg} indice={index} handleClick={aceptarRechazar}/> ) )
                                        ) : (<tr><td>Sin Informacion</td></tr>)}                                         
                                    </tbody></table>                                         
                                </div>
                            </div>

                            {clickAceptarModal && msjModal!=="" && (
                                <div style={{marginTop:"20px", marginBottom:"20px", whiteSpace:"pre-line"}}>
                                    <span className="TX_error">{msjModal}</span>
                                </div>
                            )}

                         </div>       
                    )}

                    {indicadorAceptar>2 && indicadorAceptar<=4 && (
                         <div>
                            <div style={{marginTop:"20px"}}>
                                Para finalizar el proceso, complete la información requerida para crear su cuenta de usuario MarcaGol
                            </div>

                            <div style={{marginTop:"20px"}}>

                                <div>
                                    <div style={{display:"grid", gridTemplateColumns:"1fr 3fr", gap:"10px", textAlign:"left", maxWidth: "450px"}}>
                                        <div><span style={{fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Telefono</span></div>
                                        <div><span className='TX_exito' style={{fontSize:"16px", fontWeight:"bold", textAlign:"left", margin:"0px", padding:"0px"}}>{arrCampos[8].valor}</span></div>

                                        <div><span style={{fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Documento Ident*</span></div>
                                        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left", minWidth:"50px", maxWidth: "400px"}}>
                                            <input type="text" value={arrCampos[9].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"50px", maxWidth: "150px"}}  placeholder="Documento" onChange={(e) => validarCampo(e, "MD3_DOCUMENTO", 14)}/>
                                            {arrCampos[9].error!=="" && clickAceptarModal && (
                                                <div style={{width:"100%"}}><div className="TX_error" style={{margin:"0px", padding:"0px", fontSize:"14px", textAlign:"left"}}>{arrCampos[9].error}</div></div>
                                            )}
                                        </div>

                                        <div><span style={{fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Nombre*</span></div>
                                        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left", minWidth:"50px", maxWidth: "400px"}}>
                                            <input type="text" value={arrCampos[10].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"50px", maxWidth: "300px"}} placeholder="Nombre" onChange={(e) => validarCampo(e, "MD3_NOMBRE", 40)}/>
                                            {arrCampos[10].error!=="" && clickAceptarModal && (
                                                <div style={{width:"100%"}}><div className="TX_error" style={{margin:"0px", padding:"0px", fontSize:"14px", textAlign:"left"}}>{arrCampos[10].error}</div></div>                                                
                                            )}
                                        </div>

                                        <div><span style={{fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Apellido</span></div>
                                        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left", minWidth:"50px", maxWidth: "400px"}}>
                                            <input type="text" value={arrCampos[11].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"50px", maxWidth: "300px"}} placeholder="Apellido" onChange={(e) => validarCampo(e, "MD3_APELLIDO", 40)}/>
                                            {arrCampos[11].error!=="" && clickAceptarModal && (
                                                <div style={{width:"100%"}}><div className="TX_error" style={{margin:"0px", padding:"0px", fontSize:"14px", textAlign:"left"}}>{arrCampos[11].error}</div></div>                                                
                                            )}
                                        </div>

                                        <div><span style={{fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Correo*</span></div>
                                        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left", minWidth:"50px", maxWidth: "400px"}}>
                                            <input type="email" value={arrCampos[12].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"50px", maxWidth: "300px"}} placeholder="Será tú usuario en MarcaGol" onChange={(e) => validarCampo(e, "MD3_CORREO", 40)} onBlur={() => clickGenerico("MD3_VALIDAR_CORREO")}/>
                                            {arrCampos[12].error!=="" && (
                                                <div style={{width:"100%"}}><div className="TX_error" style={{margin:"0px", padding:"0px", fontSize:"14px", textAlign:"left"}}>{arrCampos[12].error}</div></div>                                                
                                            )}
                                        </div>

                                        <div><span style={{fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Contraseña*</span></div>
                                            <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left", minWidth:"50px", maxWidth: "400px"}}>
                                                <input type="password" value={arrCampos[13].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"50px", maxWidth: "150px"}} placeholder="Asigne su contraseña" onChange={(e) => validarCampo(e, "MD3_PASSWORD1", 16)}/>
                                                {arrCampos[13].error!=="" && indicadorAceptar===4 && clickAceptarModal && (
                                                    <div style={{width:"100%"}}><div className="TX_error" style={{margin:"0px", padding:"0px", fontSize:"14px", textAlign:"left"}}>{arrCampos[13].error}</div></div>                                                
                                                )}
                                        </div>

                                        <div><span style={{fontSize:"14px", fontWeight:"bold", textAlign:"left"}}>Repetir*</span></div>
                                        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left", minWidth:"50px", maxWidth: "400px"}}>
                                                <input type="password" value={arrCampos[14].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"50px", maxWidth: "150px"}} placeholder="Repita la contraseña" onChange={(e) => validarCampo(e, "MD3_PASSWORD2", 16)}/>
                                                {arrCampos[14].error!=="" && indicadorAceptar===4 && clickAceptarModal && (
                                                    <div style={{width:"100%"}}><div className="TX_error" style={{margin:"0px", padding:"0px", fontSize:"14px", textAlign:"left"}}>{arrCampos[14].error}</div></div>                                                
                                                )}
                                        </div>
                                        


                                    </div>

                                    {indicadorAceptar===4 && (
                                        <>
                                        <div style={{marginTop:"20px", marginBottom:"20px", verticalAlign:"center"}}>
                                            <input type="checkbox" id="cB3_Terminos" checked={arrCampos[15].valor} onChange={() => clickGenerico("MD3_CB_TERMINOS")} style={{padding:"8px", cursor:"pointer"}}/>{" Acepto "}<a href={urlTerminos} target="_blank" style={{textDecoration:"none"}}><span className="TX_link">Terminos y Condiciones</span></a>
                                        </div>
                                        {arrCampos[15].error!=="" && clickAceptarModal && (
                                            <div style={{marginTop:"-10px", marginBottom:"20px"}}>
                                                <span className="TX_error">{arrCampos[15].error}</span>
                                            </div>
                                        )}
                                        </>
                                    )}                                        
                                    

                                </div>

                                
                            </div>

                            

                            {clickAceptarModal && msjModal!=="" && (
                                <div style={{marginTop:"20px", marginBottom:"20px", whiteSpace:"pre-line"}}>
                                    <span className="TX_sinInfo">{msjModal}</span>
                                </div>
                            )}

                         </div>       
                    )}

                    {indicadorAceptar===5 && (
                        <>
                            <div className='TX_exito' style={{marginBlock:"40px", marginInline: "20px", fontWeight:"bold"}}>
                                {"¡REGISTRO EXITOSO!"}
                            </div>
                            <div className='TX_sinInfo' style={{marginBlock:"40px", marginInline: "20px"}}>
                                {"Bienvenido, ya eres parte de la comunidad MarcaGol, ingresa con el correo y contraseña registrados y empieza a vivir tu Afición como un Profesional."}
                            </div>
                        </>
                    )}
                    

                </div>              
                
            </Modal>

            <Modal isOpen={isOpenNewRoles} closeModal={closeNewRoles}  titulo={"Nuevo Rol Encontrado"} clickCerrar={() => clickGenerico("CERRAR_NEWROLES")} clickAceptar={() => clickGenerico("ACEPTAR_NEWROLES")} isCancelable={false}  >
                {loadingModal && (<Loader tipo={"TOTAL"}/>)}

                <div style={{margin:"10px"}}>

                    {indicadorAceptar===0 && (
                         <div>
                            <div style={{marginTop:"20px"}}>
                                Tiene nuevos Roles inscritos en MarcaGol como parte de una Liga/Torneo/Cancha
                            </div>

                            <div style={{marginTop:"10px", marginBottom:"20px", whiteSpace:"pre-line"}}>
                                    <span className="TX_sinInfo">Acepte o rechace cada rol presentado, de acuerdo a si participa o no en torneos de la empresa u organizador encontrado</span>
                                </div>

                            <div className='BF_cajaGris' style={{marginTop:"10px", marginBottom:"10px"}}>
                                <p className='TX_tituloCaja'>{"Roles registrados en MarcaGol"}</p>
                                <div className='BF_cajaBlanca'>  
                                    <table width="100%" border="0"><tbody>
                                        {infoNewRoles ? (
                                            infoNewRoles.map((reg, index) => (<Template_lista_roles key={reg.idregistro} datos={reg} indice={index} handleClick={aceptarRechazar} tipoLista={"NEW_ROLES"}/> ) )
                                        ) : (<tr><td>Sin Informacion</td></tr>)}                                         
                                    </tbody></table>                                         
                                </div>
                            </div>

                            {clickAceptarModal && msjModal!=="" && (
                                <div style={{marginTop:"20px", marginBottom:"20px", whiteSpace:"pre-line"}}>
                                    <span className="TX_error">{msjModal}</span>
                                </div>
                            )}

                         </div>       
                    )}

                   
                    

                </div>              
                
            </Modal>

            {showMsjFlotante && (ventanaMensaje)}

            {showContactoMG && (<ContactoMG datos={null} funcionExterna={clickGenerico} cerrar={() => setShowContactoMG(false)}  /> )}


            {showMensaje && (<Mensaje titulo={txtTitulo} mensaje={txtMensaje} cerrar={() => setShowMensaje(false)} />)}


            {loading && !ingresando && (<Loader tipo={"TOTAL"}/>)}
            
            {ingresando && (
                <div className='CT_contenedorFondo' >             
                    <div className='CT_colorFondo'>
                        <div style={{display:"flex", maxWidth:"200px", minWidth:"100px", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
                            <img src={ic_logo_mg2} width="100%" />
                            <Loader tipo={"PARCIAL"}/>
                        </div>                    
                    </div>
                </div>
            )}

            {!ingresando && (
                <div className='CT_contenedorFondo' > 
            
                    <div className='CT_imagenFondo'>
                        <div style={{display:"flex", flexWrap:"wrap", maxWidth:"500px", minWidth:"200px", justifyContent:"center"}}>
                            <div style={{marginTop:"20px", marginInline:"10px"}}><img src={ic_logo_mg} width="100%" /></div>
                            <div><span style={{color:C_uniformeBlanco, fontSize: "20px"}}>Vive Tú Afición Como Un Profesional</span></div>

                            <form>

                            <div style={{marginTop:"40px", width: "100%", display: "flex", flexWrap:"wrap", alignItems: "center", justifyContent: "center"}}>
                                <input type="email" maxLength={40} value={arrCampos[0].valor} autoComplete='on' className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"200px", maxWidth: "400px", marginTop: "20px", marginLeft:"30px", marginRight:"30px"}} placeholder="Correo Electrónico"  onChange={(e) => validarCampo(e, "CORREO", 40)}/>
                                {arrCampos[0].error!=="" && clickAceptar && (
                                    <div style={{flexGrow: 1, flexShrink: 1, minWidth:"200px", maxWidth: "400px", marginLeft:"30px", marginRight:"30px", textAlign:"left"}}>
                                        <span className="TX_error">{arrCampos[0].error}</span>
                                    </div>
                                )}
                            </div>  
                            
                            <div style={{marginTop:"20px", width: "100%", display: "flex", flexWrap:"wrap", alignItems: "center", justifyContent: "center"}}>
                                <div  style={{display: "flex", flexWrap:"nowrap", alignItems: "center", justifyContent: "center", verticalAlign:"middle", flexGrow: 1, flexShrink: 1, minWidth:"200px", maxWidth: "400px", marginLeft: "30px", marginRight:"30px"}}>
                                    <input type="password" maxLength={20} autoComplete='on' className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, flexBasis: "100px", minWidth:"100px"}} placeholder="Contraseña" onChange={(e) => validarCampo(e, "PASSWORD", 20)} onKeyDown={(e) => {eEnter=e; clickGenerico("ENTER_PASSWORD");}}/>
                                    <div style={{width:"20px"}}></div>
                                    <div onClick={() => clickGenerico("BTN_INGRESAR")} className="BF_botonPrimaryFull" style={{marginBottom:"0px"}}>INGRESAR</div>
                                </div> 
                                {arrCampos[1].error!=="" && clickAceptar && (
                                    <div style={{flexGrow: 1, flexShrink: 1, minWidth:"200px", maxWidth: "400px", marginLeft:"30px", marginRight:"30px", textAlign:"left"}}>
                                        <span className="TX_error">{arrCampos[1].error}</span>
                                    </div>
                                )}  
                            </div>  
                            </form>

                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "40px"}}>
                                <div style={{marginLeft: "40px", marginRight:"30px", verticalAlign:"middle", justifyContent:"left",  flexBasis: "400px", minWidth:"200px", maxWidth:"400px"}}>
                                    <p onClick={() => clickGenerico("LNK_PASSWORD")} style={{width:"100%", textAlign:"left"}}><span style={{cursor: "pointer", color:"black", fontSize: "18px", fontWeight:"bold", fontStyle:"italic"}}>Olvidé mi contraseña</span></p>
                                    <p onClick={() => clickGenerico("LNK_REGISTRO")} style={{width:"100%", textAlign:"left"}}><span style={{cursor: "pointer", color:"black", fontSize: "18px", fontWeight:"bold", fontStyle:"italic"}}>{"Registrarme (JUGADOR)"}</span></p>
                                </div>
                            </div> 

                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <div onClick={() => clickGenerico("BTN_ESPECTADOR")} className="BF_botonBordeAccentFull" style={{flexGrow: 1, flexShrink: 1, minWidth:"200px", maxWidth:"400px", marginInline: "30px", marginTop:"30px", fontWeight:"bold" }}>
                                    Ingresar como ESPECTADOR
                                </div>
                            </div> 

                            

                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "30px"}}>
                                <div style={{borderRadius: "0px", marginInline: "20px", verticalAlign:"middle", justifyContent:"left",  flexBasis: "400px", minWidth:"200px", maxWidth:"400px"}}>
                                    <p style={{width:"100%", textAlign:"center"}}><span style={{color:C_uniformeBlanco, fontSize: "14px"}}>
                                        Quieres ser parte de la comunidad MarcaGol?<br />
                                        Quieres llevar al siguiente nivel tú Liga/Torneo/Cancha?<br />
                                        Quieres inscribirte en nuestros torneos?
                                        </span></p>
                                </div>
                            </div>


                            <div style={{ width: "100%", display: "flex", flexWrap:"wrap", alignItems: "center", justifyContent: "center", marginTop:"20px", marginBottom:"40px"}}>
                                <div style={{flexGrow: 1, flexShrink: 1, minWidth:"150px", maxWidth: "200px", textAlign:"center", verticalAlign:"middle"}}>
                                    <a href='https://www.marcagol.net' target="_blank">
                                        <span style={{cursor: "pointer", color: C_colorAccent , fontSize:"18px", fontWeight:"bold", textAlign:"center", verticalAlign:"middle"}}>www.marcagol.net</span>
                                    </a>
                                </div>
                                <div style={{flexGrow: 1, flexShrink: 1, minWidth:"150px", maxWidth: "200px", textAlign:"center", verticalAlign:"middle", alignItems: "center", justifyContent: "center"}}>
                                    <span onClick={() => clickGenerico("ABRIR_VENTANA_CONTACTOMG")} style={{cursor: "pointer", color:C_uniformeBlanco, fontSize: "18px", fontWeight:"bold", textAlign:"center", verticalAlign:"middle"}}>Contáctanos</span>
                                </div>
                            </div> 

                            {esUserAndroid && (
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer"}} onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=marcagol.net")}>
                                    <img className='IM_imgIcono24' src={ic_android_blanco} alt='' />  
                                    <div style={{color: C_uniformeBlanco, marginLeft:"10px"}}>Usuarios Android Descargar AQUI</div>
                                </div>
                                
                                
                            )}
                            
                        </div>
                    
                    </div>               

                </div> 
            )}

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}
                       
        </>
    );

};

export default Main;