import { useEffect, useState } from 'react';

import { valorNumero, registrarLogEventos, reportarError} from '../helpers/funciones';

import ic_equipo from '../recGraficos/ic_equipo.png';
import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_disparo_arco from '../recGraficos/ic_disparo_arco.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_falta_cometida from '../recGraficos/ic_falta_cometida.png';
import ic_porcentajetiempo from '../recGraficos/ic_porcentajetiempo.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_reloj from '../recGraficos/ic_reloj.png';
import ic_falta_recibida from '../recGraficos/ic_falta_recibida.png';
import ic_asistencia from '../recGraficos/ic_asistencia.png';


import DinamicoRankingJugadores from './DinamicoRankingJugadores';
import { C_uniformeGrisClaro, ID_MODULO_DETALLE_TORNEO } from '../constantes/generales';

let columnasOrdenarJug=[3,4,5,6,7,8,9,10,11,12,13,14,15,16,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,42,43,44];


//let columnasOrdenarJug=[3,4,5,6,7,8,9,10,11,12,13,14,15,16];
let infoOrdenada=[];

const nomClase="DT_InfoRankingJugadores_WB";
const idModulo=ID_MODULO_DETALLE_TORNEO;

let colPartidosJugados=3;

const DT_InfoRankingJugadores = ({infoJugadores, tipo_registroinfo, modOrigen, paramOrigen, definirModulo, tieneTarjetaAzul, hayPartidosFE, idTorneo=""}) => {
    const [positivo, setPositivo] = useState(true);
    const [numTodos, setNumTodos] = useState(-1);
    const [hayInfo, setHayInfo] = useState(false);
    const [tipoLista, setTipoLista] = useState("total");
    const [indicadoresPos, setIndicadoresPos] = useState([1, 3, 12, 5, 6, 13, 4, 7, 11]);
    const [indicadoresNeg, setIndicadoresNeg] = useState([2, 8, 9, 38, 10]);



    useEffect(() => {    
        ordenarInfomacionJugadores();

        registrarLogEventos(idModulo,"9",idTorneo,"1");
    }, []);

    useEffect(() => {
        let indicador="1";
        if(!positivo) indicador="0";
        registrarLogEventos(idModulo,"9",idTorneo,indicador);
    }, [positivo]);

    useEffect(() => {

        if(numTodos>-1){  
            registrarLogEventos(idModulo,"11",idTorneo,"" + numTodos);
        }
        
    }, [numTodos]);

    useEffect(() => {
        actualizarListas(tipoLista);        
    }, [tipoLista]);



    const ordenarInfomacionJugadores = () => {
        let nomFuncion="ordenarInfomacionJugadores";
    
        try{
            let i,j;    
            let infoOrdenadaJugadores=[];
            
            if(infoJugadores.length>0){
    
                for(i=0;i<columnasOrdenarJug.length;i++){
                    let col=columnasOrdenarJug[i];
                    let datosTemp=[]; 

                    for(j=0;j<infoJugadores.length;j++){
                        datosTemp.push(valorNumero(infoJugadores[j][col]));                        
                    }
                        
                    let datosTemp2=organizarListaJugadores(datosTemp);
                        
                    infoOrdenadaJugadores.push(datosTemp2);
    
                }
            }

            //setInfoOrdenada(infoOrdenadaJugadores);
            infoOrdenada=infoOrdenadaJugadores;
            setHayInfo(true);
            
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    
    }
    

    const organizarListaJugadores = (list) => {
        let nomFuncion="organizarListaJugadores";
        let posiciones=[]; //new int[list.length];
    
        try{
            let k, i, j;
            for(k=0;k<list.length;k++){
                posiciones.push(k);
            }
    
            for(i =0; i< list.length; i++){
                let sorted = true; // asumo que para la iteración i el listado es ordenado,
                for(j =0; j< list.length - i - 1; j++){ // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición
                    if(list[j] < list[j+1]){
                        let temp = list[j];
                        list[j] = list[j+1];
                        list[j+1] = temp;
                        sorted = false;
    
                        temp=posiciones[j];
                        posiciones[j]=posiciones[j+1];
                        posiciones[j+1]=temp;
                    }
                    else if(list[j] === list[j+1]){
                        let partJugados=valorNumero(infoJugadores[posiciones[j]][3]);
                        let partJugados2=valorNumero(infoJugadores[posiciones[j+1]][3]);
                        let tempNum=0;
                        let tempNum2=0;
                        if(partJugados>0) tempNum = (list[j] / partJugados);
                        if(partJugados2>0) tempNum2 = (list[j + 1] / partJugados2);
    
                        if(tempNum<tempNum2){
                            let temp = list[j];
                            list[j] = list[j+1];
                            list[j+1] = temp;
                            sorted = false;
    
                            temp=posiciones[j];
                            posiciones[j]=posiciones[j+1];
                            posiciones[j+1]=temp;
                        }
                        else if(tempNum===tempNum2){
                            if(partJugados<partJugados2){
                                let temp = list[j];
                                list[j] = list[j+1];
                                list[j+1] = temp;
                                sorted = false;
    
                                temp=posiciones[j];
                                posiciones[j]=posiciones[j+1];
                                posiciones[j+1]=temp;
                            }
                        }
    
                    }
                }
                if(sorted){
                    return posiciones;
                }
            }
    
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    
        return posiciones;
    }

    const incluirIndicador = (indicador, todos, titulo, subtitulo, imagen, bgnd, ascendente, esporcentaje) => {
        if(indicador>0){

            let indice=indicador;
            if(todos) indice=-1;

            
            return(         
                    <DinamicoRankingJugadores idTorneo={idTorneo} indicador={indicador} todos={todos} titulo={titulo} subtitulo={subtitulo} 
                    imagen={imagen} bgnd={bgnd} ascendente={ascendente} esporcentaje={esporcentaje} idxCant={columnasOrdenarJug[indicador]}
                    infoOrdenadaEquipos={infoOrdenada} infoClasificacion={infoJugadores} clickVerTodos={() => {setNumTodos(indice)}} modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} colPartidosJugados={colPartidosJugados} /> 
                            
            );
        }
        else{
            return(<></>);
        }
        
    }

    const cambiarTipoLista  = (selectedOption) => {          

        actualizarListas(selectedOption.target.value);
        setTipoLista(selectedOption.target.value);
    };

    const actualizarListas  = (valor) => {  
        
        let indicadoresPos1=[1, 3, 12, 5, 6, 13, 4, 7, 11];
        let indicadoresNeg1=[2, 8, 9, 38, 10];

        if(valor==="total"){
            colPartidosJugados=3;
            indicadoresPos1 =[1, 3, 12, 5, 6, 13, 4, 7, 11];
            indicadoresNeg1 =[2, 8, 9, 38, 10];
        }
        else if(valor==="grupos"){
            colPartidosJugados=18;
            indicadoresPos1 =[15, 17, 25, 19, 20, 0, 18, 21, 0];
            indicadoresNeg1 =[16, 22, 23, 39, 24];
        }
        else if(valor==="eliminacion"){
            colPartidosJugados=41;
            indicadoresPos1 =[26, 28, 36, 30, 31, 0, 29, 32, 0];
            indicadoresNeg1 =[27, 33, 34, 37, 35];
        }

        setIndicadoresPos(indicadoresPos1);
        setIndicadoresNeg(indicadoresNeg1);

    };




    return (

        <div>
            <div style={{marginLeft: "10px", marginRight: "10px"}}>
                <div className="CT_contenedorTitulo">
                    <img className='IM_imgIcono36' height="36px" width="36px" src={ic_equipo} alt='' />   
                    <p style={{marginLeft: "10px", fontSize:"18px"}}>Ranking de Jugadores</p>             
                </div>  
                {numTodos===-1 && (
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <div style={{flex:"1"}}>
                            <div style={{width:"100px", alignItems:"center"}} className={positivo ? "BF_botonPrimaryFull" : "BF_botonPrimary"} onClick={() => {setPositivo(!positivo)}}>
                                <span>+ Positivos</span>
                            </div>
                        </div>
                        <div style={{flex:"1"}}>
                            <div style={{width:"100px", alignItems:"center"}} className={positivo ? "BF_botonBordeAccent" : "BF_botonBordeAccentFull"} onClick={() => {setPositivo(!positivo)}}>
                                <span>Negativos -</span>
                            </div>
                        </div>
                    </div> 
                )}    

                {numTodos===-1 && hayPartidosFE && (
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-around", marginBottom: "10px" }}>
                        <label><input type="radio" value="total" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="total"} onChange={cambiarTipoLista}/>Total</label>
                        <label><input type="radio" value="grupos" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="grupos"} onChange={cambiarTipoLista}/>Grupos</label>
                        <label><input type="radio" value="eliminacion" name="tipoLista" style={{marginRight: "15px"}} checked={tipoLista==="eliminacion"} onChange={cambiarTipoLista}/>Eliminación</label>
                    </div>
                ) }   
                
                
            </div>

           
            <div style={{background:C_uniformeGrisClaro, padding:"4px", minHeight: "70vh"}}>
            
                {hayInfo && positivo && numTodos===-1 && (<div  className='CT_contenedorFlex'>

                    {incluirIndicador(indicadoresPos[0],false,"Goles","Mayor Cantidad de Goles Realizados",ic_balon_gol,1,true,false)}
                   
                    
                    {tipo_registroinfo==="2" && (<>
                        {incluirIndicador(indicadoresPos[1],false,"Asistencias","Mayor Cantidad de Asistencias",ic_asistencia,1,true,false)}
                        {incluirIndicador(indicadoresPos[2],false,"Goles y Asistencias","Mayor Participación en Goles",ic_balon_gol,1,true,false)}
                        {incluirIndicador(indicadoresPos[3],false,"Disparos Totales","Disparos totales realizados",ic_disparo_arco,1,true,false)}
                        {incluirIndicador(indicadoresPos[4],false,"Disparos al Arco","Total Disparos al Arco realizados",ic_disparo,1,true,false)}
                        {incluirIndicador(indicadoresPos[5],false,"Efectividad de Disparo","Goles por cada 100 Disparos al Arco",ic_porcentajetiempo,1,true,true)}
                        {incluirIndicador(indicadoresPos[6],false,"Atajadas","Total Atajadas realizadas",ic_atajada,1,true,false)}
                        {incluirIndicador(indicadoresPos[7],false,"Faltas Recibidas","Mayor Cantidad de Faltas Recibidas",ic_falta_recibida,1,true,false)}
                        {incluirIndicador(indicadoresPos[8],false,"Minutos Jugados","Mayor Cantidad de Minutos Jugados",ic_reloj,1,true,false)}
                    </>)}

                </div>)}

                {hayInfo && !positivo && numTodos===-1 && (<div  className='CT_contenedorFlex'>
                    {incluirIndicador(indicadoresNeg[0],false,"Autogoles","Mas autogoles ha realizado",ic_balon_autogol,2,true,false)} 

                    {tipo_registroinfo==="2" && (<>
                        {incluirIndicador(indicadoresNeg[1],false,"Faltas Cometidas","Mas faltas realiza",ic_falta_cometida,2,true,false)} 
                    </>)}  

                    {incluirIndicador(indicadoresNeg[2],false,"Tarjetas Amarillas","Mas Tarjetas Amarillas ha recibido",ic_amarilla,2,true,false)} 

                    {tieneTarjetaAzul===true && incluirIndicador(indicadoresNeg[3],false,"Tarjetas Azules","Mas Tarjetas Azules ha recibido",ic_tarjeta_azul,2,true,false)} 

                    {incluirIndicador(indicadoresNeg[4],false,"Tarjetas Rojas","Mas Tarjetas Rojas ha recibido",ic_tarjeta_roja,2,true,false)} 
        
                </div>)}

                {numTodos>=0 && positivo && (<div  className='CT_contenedorFlex'>

                    {numTodos===indicadoresPos[0] && (incluirIndicador(indicadoresPos[0],true,"Goles","Mayor Cantidad de Goles Realizados",ic_balon_gol,1,true,false))}
                    
                    {numTodos===indicadoresPos[1] && (incluirIndicador(indicadoresPos[1],true,"Asistencias","Mayor Cantidad de Asistencias",ic_asistencia,1,true,false))}
                    {numTodos===indicadoresPos[2] && (incluirIndicador(indicadoresPos[2],true,"Goles y Asistencias","Mayor Participación en Goles",ic_balon_gol,1,true,false))}
                    {numTodos===indicadoresPos[3] && (incluirIndicador(indicadoresPos[3],true,"Disparos Totales","Disparos totales realizados",ic_disparo_arco,1,true,false))}
                    {numTodos===indicadoresPos[4] && (incluirIndicador(indicadoresPos[4],true,"Disparos al Arco","Total Disparos al Arco realizados",ic_disparo,1,true,false))}
                    {numTodos===indicadoresPos[5] && (incluirIndicador(indicadoresPos[5],true,"Efectividad de Disparo","Goles por cada 100 Disparos al Arco",ic_porcentajetiempo,1,true,true))}
                    {numTodos===indicadoresPos[6] && (incluirIndicador(indicadoresPos[6],true,"Atajadas","Total Atajadas realizadas",ic_atajada,1,true,false))}
                    {numTodos===indicadoresPos[7] && (incluirIndicador(indicadoresPos[7],true,"Faltas Recibidas","Mayor Cantidad de Faltas Recibidas",ic_falta_recibida,1,true,false))}
                    {numTodos===indicadoresPos[8] && (incluirIndicador(indicadoresPos[8],true,"Minutos Jugados","Mayor Cantidad de Minutos Jugados",ic_reloj,1,true,false))}
    
                </div>)}

                {numTodos>=0 && !positivo && (<div  className='CT_contenedorFlex'>

                    {numTodos===indicadoresNeg[0] && (incluirIndicador(indicadoresNeg[0],true,"Autogoles","Mas autogoles ha realizado",ic_balon_autogol,2,true,false))}
                    {numTodos===indicadoresNeg[1] && (incluirIndicador(indicadoresNeg[1],true,"Faltas Cometidas","Mas faltas realiza",ic_falta_cometida,2,true,false))}   

                    {numTodos===indicadoresNeg[2] && (incluirIndicador(indicadoresNeg[2],true,"Tarjetas Amarillas","Mas Tarjetas Amarillas ha recibido",ic_amarilla,2,true,false))} 

                    {tieneTarjetaAzul===true && numTodos===indicadoresNeg[3] && incluirIndicador(indicadoresNeg[3],true,"Tarjetas Azules","Mas Tarjetas Azules ha recibido",ic_tarjeta_azul,2,true,false)} 

                    {numTodos===indicadoresNeg[4] && (incluirIndicador(indicadoresNeg[4],true,"Tarjetas Rojas","Mas Tarjetas Rojas ha recibido",ic_tarjeta_roja,2,true,false))} 
                   
                </div>)}
                

            </div>

         

        
        </div>

    );
};


export default DT_InfoRankingJugadores;