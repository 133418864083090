import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary } from '../constantes/generales';
import ic_balon_gol from '../recGraficos/ic_balon_gol.png';

const DinamicoResumenGoles = ({ tipo, datos }) => {

    return(
        <>
        {tipo===2 ?
        (<div style={{marginTop:"5px", marginBottom:"20px"}}>
            <span style={{fontSize:"16px", color:C_uniformeGrisOscuro}}>Goles</span>

            <div style={{display:"flex", alignItems:"center", marginTop:"5px"}}>
                <div style={{flex:"1"}}>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[2]}</span>
                        <span style={{flex:"5", fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"left"}}>Jugada</span>                        
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[4]}</span>
                        <span style={{flex:"5", fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"left"}}>T Libre</span>                         
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[6]}</span>
                        <span style={{flex:"5", fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"left"}}>Penalti</span>   
                    </div>
                </div>
                <span style={{flex:"1", fontSize:"36px", color:C_colorPrimary, fontWeight:"bold"}}>{datos[0]}</span>

                <img className="imgIcono36" src={ic_balon_gol} style={{margin:"5px"}} alt=""/>

                <span style={{flex:"1", fontSize:"36px", color: C_colorAccent , fontWeight:"bold"}}>{datos[1]}</span>
                <div style={{flex:"1"}}>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"5", fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"right"}}>Jugada</span>  
                        <span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[3]}</span>                      
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"5", fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"right"}}>T Libre</span>  
                        <span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[5]}</span>                       
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"5", fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"right"}}>Penalti</span>   
                        <span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[7]}</span>
                    </div>
                </div>
            </div>

        </div>)
        :
        (<div style={{marginTop:"5px", marginBottom:"20px"}}>
            <span style={{fontSize:"16px", color:C_uniformeGrisOscuro}}>Goles</span>

            <div style={{display:"flex", alignItems:"center", marginTop:"5px", marginBottom:"5px"}}>
                <img className="IM_imgIcono36" src={ic_balon_gol} style={{margin:"5px"}} alt=""/>
                <span style={{flex:"1", fontSize:"24px", color:C_colorPrimary}}>{datos[0]}</span>

                <div style={{flex:"2"}}>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"2", fontSize:"14px", color:C_uniformeGrisOscuro, textAlign:"left"}}>Jugada</span>
                        <span style={{flex:"1", fontSize:"14px", color:C_colorPrimary}}>{datos[1]}</span>  
                        <span style={{flex:"1", fontSize:"14px", color: C_colorAccent }}>{datos[2]}</span>                       
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                    <span style={{flex:"2", fontSize:"14px", color:C_uniformeGrisOscuro, textAlign:"left"}}>Tiro Libre</span>
                        <span style={{flex:"1", fontSize:"14px", color:C_colorPrimary}}>{datos[3]}</span>  
                        <span style={{flex:"1", fontSize:"14px", color: C_colorAccent }}>{datos[4]}</span>                        
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                    <span style={{flex:"2", fontSize:"14px", color:C_uniformeGrisOscuro, textAlign:"left"}}>Penalti</span>
                        <span style={{flex:"1", fontSize:"14px", color:C_colorPrimary}}>{datos[5]}</span>  
                        <span style={{flex:"1", fontSize:"14px", color: C_colorAccent }}>{datos[6]}</span>   
                    </div>
                </div> 
                
            </div>

        </div>)
        }
        </>
    );

};


export default DinamicoResumenGoles;