import { useEffect, useState } from 'react';
import { getDatos } from '../gestionDatos/getDatos';

import { formatearFecha, esformatoHoraDia, operacionFechas,sumarMinToHoras, recuperarDatoLocal, valorLong, guardarDatoLocal, isFlagUpdate, superaTiempoActualizacion, presentarLog } from "../helpers/funciones";
import {  operacionHorasDia, difHoras, difFechas, dividirLista, formatearPorcentaje } from "../helpers/funciones";

import {registrarLogEventos, reportarError, presentarError, crearTabla, valorNumero} from '../helpers/funciones';
import Loader from '../recVisual/Loader';

import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/TX_Textos.css";
import "../estilos/BF_BordesFondos.css";
import "../estilos/IP_Inputs.css";

import ic_uniforme_oscuro from '../recGraficos/ic_uniforme_oscuro.png';
import ic_uniforme_claro from '../recGraficos/ic_uniforme_claro.png';
import ic_torneo from '../recGraficos/ic_torneo_gris_w.png';
import ic_sedes from '../recGraficos/ic_sedes.png';
import ic_partido from '../recGraficos/ic_partido.png';
import ic_partido_verde from '../recGraficos/ic_partido_verde.png';
import ic_programar from '../recGraficos/ic_programar.png';
import ic_salir from '../recGraficos/ic_salir_w.png';
import ic_escudo_base from '../recGraficos/ic_escudo_base.png';
import ic_escudo_base2 from '../recGraficos/ic_escudo_base2_w.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_disparo_arco from '../recGraficos/ic_disparo_arco.png';
import ic_atajada from '../recGraficos/ic_atajada.png';
import ic_falta_cometida from '../recGraficos/ic_falta_cometida.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_equipo from '../recGraficos/ic_equipo.png';
import ic_cambiojugador from '../recGraficos/ic_cambiojugador.png';

import ic_balon_gol from '../recGraficos/ic_balon_gol.png';
import ic_asistencia from '../recGraficos/ic_asistencia.png';
import ic_disparo from '../recGraficos/ic_disparo.png';
import ic_porcentajetiempo from '../recGraficos/ic_porcentajetiempo.png';
import ic_reloj from '../recGraficos/ic_reloj.png';
import ic_falta_recibida from '../recGraficos/ic_falta_recibida.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';

import ic_jugador from '../recGraficos/ic_jugador.png';
import ic_flecha_expandir from '../recGraficos/ic_flecha_expandir.png';


import ShowError from '../recVisual/ShowError';
import TemplateLista2Items from '../gestionVisual/TemplateLista2Items';
import Template_lista_jugadorespartido from '../gestionVisual/Template_lista_jugadorespartido';
import DinamicoResumenGanador from '../gestionVisual/DinamicoResumenGanador';
import DinamicoResumenGoles from '../gestionVisual/DinamicoResumenGoles';
import DinamicoResumenBarra from '../gestionVisual/DinamicoResumenBarra';
import DinamicoResumenDisparos from '../gestionVisual/DinamicoResumenDisparos';
import DinamicoResumenEfectividad from '../gestionVisual/DinamicoResumenEfectividad';
import DinamicoResumenTiempo from '../gestionVisual/DinamicoResumenTiempo';
import DinamicoRankingIndicadores from '../gestionVisual/DinamicoRankingIndicadores';
import DP_InfoEstadisticasJugador from '../gestionVisual/DP_InfoEstadisticasJugador';
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimaryDark, ID_MODULO_DETALLE_EQUIPO, ID_MODULO_DETALLE_PARTIDO, ID_MODULO_DETALLE_TORNEO, C_uniformeNegro, C_uniformeVerdeClaro, C_colorTextoSinInfo } from '../constantes/generales';



const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Detalle_Partido_WB";
const idModulo=ID_MODULO_DETALLE_PARTIDO;

let idPartido="";

let paramsAjustado="";
//let paramsBase="";

let escudoeq1=ic_escudo_base;
let escudoeq2=ic_escudo_base2;
let uniformeEq1=ic_uniforme_claro;
let uniformeEq2=ic_uniforme_oscuro;
let nomeq1="";
let nomeq2="";

let idTorneo="";
let idEquipo1="";
let idEquipo2="";
let idEquipoSel="";
let idSede="";

let estado="";
let txtFecha="";
let txtHora="";
let txtMarcador="";
let txtPenaltis="";
let estiloFinal;
let txtFechaFinal="";
let txtFinal="";
let txtFinalPenaltis="";
let porcentajeAvance=0;
let tipo_registroinfo="1";

let numSel=0;
let textoPSel="Goles";
let textoSSel="Mayor Cantidad de Goles Realizados";
let imagenSel=ic_balon_gol;

let tieneTarjetaAzul=false;


let columnasOrdenarJug=[5,6,7,8,9,10,11,12,13,14,15,16,17,20];


var infoPartidoO = [];
var infoPartido = [];
function crearInfoPartido(datos){   
    const nomFuncion="crearInfoPartido";

    try{
        let partido = {
            torneo:"",
            sede:"",
            cancha:"",
            jornada:"",
            fecha:"",
            hora:"",
            equipo1:"",
            escudoeq1:"",
            equipo2:"",
            escudoeq2:"",
            arbitro1:"",
            arbitro2:"",
            estado:"",
            idtorneo:"",
            idsede:"",
            idpartido:"",
            idequipo1:"",
            nomequipo1:"",
            uniformeeq1:"",
            idequipo2:"",
            nomequipo2:"",
            uniformeeq2:"",
            goleseq1:"",
            goleseq2:"",
            parte:"",
            tiempo:"",
            minutospartido:"",
            idestado:"",
            fecha_actual:"",
            hora_actual:"",
            idjornada:"",
            estadotiempo:"",
            fechainiciotiempo:"",
            tipo_torneo:"",
            tipo_registroinfo:"",
            penaltisequipo1:"",
            penaltisequipo2:"",
            escudotorneo:"",
            fotoarbitro1:"",
            fotoarbitro2:"",
            idarbitro1:"",
            idarbitro2:"",
            idarbitro_def:"",
            calificaciones:"",
            nomcancha:"",
            ubicacion:"",
            idcancha:"",
            numNuevoJornada:"",
            tipoJornada:"",
            nomJornada:"",
            nomCopa:"",
            minutosantes:"",
            tiene_tarjeta_azul:""
        }
    
        infoPartidoO = [];
        infoPartido = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , partido);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.torneo=datos[i][j];
                if(j===1) registro.sede=datos[i][j];
                if(j===2) registro.cancha=datos[i][j];
                if(j===3) registro.jornada=datos[i][j];
                if(j===4) registro.fecha=datos[i][j];
                if(j===5) registro.hora=datos[i][j];
                if(j===6) registro.equipo1=datos[i][j];
                if(j===7) registro.escudoeq1=datos[i][j];
                if(j===8) registro.equipo2=datos[i][j];
                if(j===9) registro.escudoeq2=datos[i][j];                
                if(j===10) registro.arbitro1=datos[i][j];
                if(j===11) registro.arbitro2=datos[i][j];
                if(j===12) registro.estado=datos[i][j];
                if(j===13) registro.idtorneo=datos[i][j];
                if(j===14) registro.idsede=datos[i][j];
                if(j===15) registro.idpartido=datos[i][j];
                if(j===16) registro.idequipo1=datos[i][j];
                if(j===17) registro.nomequipo1=datos[i][j];
                if(j===18) registro.uniformeeq1=datos[i][j];
                if(j===19) registro.idequipo2=datos[i][j];
                if(j===20) registro.nomequipo2=datos[i][j];
                if(j===21) registro.uniformeeq2=datos[i][j];
                if(j===22) registro.goleseq1=datos[i][j];
                if(j===23) registro.goleseq2=datos[i][j];
                if(j===24) registro.parte=datos[i][j];
                if(j===25) registro.tiempo=datos[i][j];
                if(j===26) registro.minutospartido=datos[i][j];
                if(j===27) registro.idestado=datos[i][j];
                if(j===28) registro.fecha_actual=datos[i][j];
                if(j===29) registro.hora_actual=datos[i][j];
                if(j===30) registro.idjornada=datos[i][j];
                if(j===31) registro.estadotiempo=datos[i][j];
                if(j===32) registro.fechainiciotiempo=datos[i][j];
                if(j===33) registro.tipo_torneo=datos[i][j];
                if(j===34) registro.tipo_registroinfo=datos[i][j];
                if(j===35) registro.penaltisequipo1=datos[i][j];
                if(j===36) registro.penaltisequipo2=datos[i][j];
                if(j===37) registro.escudotorneo=datos[i][j];
                if(j===38) registro.fotoarbitro1=datos[i][j];
                if(j===39) registro.fotoarbitro2=datos[i][j];
                if(j===40) registro.idarbitro1=datos[i][j];
                if(j===41) registro.idarbitro2=datos[i][j];
                if(j===42) registro.idarbitro_def=datos[i][j];
                if(j===43) registro.calificaciones=datos[i][j];
                if(j===44) registro.nomcancha=datos[i][j];
                if(j===45) registro.ubicacion=datos[i][j];
                if(j===46) registro.idcancha=datos[i][j];
                if(j===47) registro.numNuevoJornada=datos[i][j];
                if(j===48) registro.tipoJornada=datos[i][j];
                if(j===49) registro.nomJornada=datos[i][j];
                if(j===50) registro.nomCopa=datos[i][j];
                if(j===51) registro.minutosantes=datos[i][j];
                if(j===52) registro.tiene_tarjeta_azul=datos[i][j];
            }
            infoPartidoO.push(registro);
            infoPartido.push(datos[i]);

            if(i===0){
                idTorneo=registro.idtorneo;
                idEquipo1=registro.idequipo1;
                idEquipo2=registro.idequipo2;
                idEquipoSel=idEquipo1;
                idSede=registro.idsede;

                if(registro.escudoeq1!=="") escudoeq1=ruta + registro.escudoeq1;
                else escudoeq1=ic_escudo_base;
                if(registro.escudoeq2!=="") escudoeq2=ruta + registro.escudoeq2;
                else escudoeq2=ic_escudo_base2;
                if(registro.uniformeeq1!=="") uniformeEq1=ruta + registro.uniformeeq1;
                else uniformeEq1=ic_uniforme_claro;
                if(registro.uniformeeq2!=="") uniformeEq2=ruta + registro.uniformeeq2;
                else uniformeEq2=ic_uniforme_oscuro;
                if(registro.tiene_tarjeta_azul==="1") tieneTarjetaAzul=true;
                else tieneTarjetaAzul=false;
                nomeq1=registro.equipo1;
                nomeq2=registro.equipo2;

                estado=registro.estado;
                txtFecha=formatearFecha(registro.fecha,9);
                txtHora=esformatoHoraDia(registro.hora);
                txtMarcador=registro.goleseq1 + " : " + registro.goleseq2;
                txtPenaltis="(" + registro.penaltisequipo1 + " - " + registro.penaltisequipo2 + ")";
                txtFechaFinal=txtFecha;
                txtFinal="";
                txtFinalPenaltis="";
                porcentajeAvance=0;
                tipo_registroinfo=registro.tipo_registroinfo;

            }
        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

var infoJugadoresO = [];
var infoJugadores = [];
var infoOrdenadaJugadores = [];
function crearInfoJugadores(datos){   
    const nomFuncion="crearInfoJugadores";

    try{
        let jugador = {
            idequipo:"",
            idjugador:"",
            foto:"",
            camiseta_base:"",
            nombre:"",
            activo_eq:"",
            activo_sede:"",
            camiseta_partido:"",
            confirmo:"",
            fechaconfirmo:"",
            llego:"",
            fechallego:"",
            titular:"",
            goles:"",
            jugada:"",
            tirolibre:"",
            penalti:"",
            autogol:"",
            asistencias:"",
            atajadas:"",
            disparosarco:"",
            disparosdentro:"",
            faltasrecibidas:"",
            faltascometidas:"",
            tarjetasamarillas:"",
            tarjetasrojas:"",
            minutostiempo1:"",
            minutostiempo2:"",
            tiempojugadoparte1:"",
            tiempojugadoparte2:"",
            tarjetasazules:""
        }
    
        infoJugadoresO = [];
        infoJugadores = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idequipo=datos[i][j];
                if(j===1) registro.idjugador=datos[i][j];
                if(j===2) registro.foto=datos[i][j];
                if(j===3) registro.camiseta_base=datos[i][j];
                if(j===4) registro.nombre=datos[i][j];
                if(j===5) registro.activo_eq=datos[i][j];
                if(j===6) registro.activo_sede=datos[i][j];
                if(j===7) registro.camiseta_partido=datos[i][j];
                if(j===8) registro.confirmo=datos[i][j];
                if(j===9) registro.fechaconfirmo=datos[i][j];
                if(j===10) registro.llego=datos[i][j];
                if(j===11) registro.fechallego=datos[i][j];
                if(j===12) registro.titular=datos[i][j];
                if(j===13) registro.goles=datos[i][j];
                if(j===14) registro.jugada=datos[i][j];
                if(j===15) registro.tirolibre=datos[i][j];
                if(j===16) registro.penalti=datos[i][j];
                if(j===17) registro.autogol=datos[i][j];
                if(j===18) registro.asistencias=datos[i][j];
                if(j===19) registro.atajadas=datos[i][j];
                if(j===20) registro.disparosarco=datos[i][j];
                if(j===21) registro.disparosdentro=datos[i][j];
                if(j===22) registro.faltasrecibidas=datos[i][j];
                if(j===23) registro.faltascometidas=datos[i][j];
                if(j===24) registro.tarjetasamarillas=datos[i][j];
                if(j===25) registro.tarjetasrojas=datos[i][j];
                if(j===26) registro.minutostiempo1=datos[i][j];
                if(j===27) registro.minutostiempo2=datos[i][j];
                if(j===28) registro.tiempojugadoparte1=datos[i][j];
                if(j===29) registro.tiempojugadoparte2=datos[i][j];
                if(j===30) registro.tarjetasazules=datos[i][j];
            }
            infoJugadoresO.push(registro);
            infoJugadores.push(datos[i]);
        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }      

}

var infoAccionesO = [];
var infoAcciones = [];
function crearInfoAcciones(datos){    
    const nomFuncion="crearInfoAcciones";

    try{
        
    
        infoAccionesO = [];
        infoAcciones = [];
    
    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }    

}

let resumen = {
    idequipo:"",
    tiporesultado:"",
    puntos:"",
    gft1:"",
    gft2:"",
    gct1:"",
    gct2:"",
    golesjugada:"",
    golestirolibre:"",
    golespenalti:"",
    autogoles:"",
    asistencias:"",
    faltasrecibidas:"",
    faltascometidas:"",
    tarjetasamarillas:"",
    tarjetasrojas:"",
    atajadas:"",
    disparosarco:"",
    disparosdentro:"",
    numjugadores:"",
    numcambios:"",
    fechainicio:"",
    fechafin:"",
    duraciontiempo1:"",
    duraciontiempo2:"",
    tarjetasazules:""
}
var infoResumenO = [];
var infoResumen = [];
function crearInfoResumen(datos){    
    const nomFuncion="crearInfoResumen";

    try{        
    
        infoResumenO = [];
        infoResumen = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , resumen);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idequipo=datos[i][j];
                if(j===1) registro.tiporesultado=datos[i][j];
                if(j===2) registro.puntos=datos[i][j];
                if(j===3) registro.gft1=datos[i][j];
                if(j===4) registro.gft2=datos[i][j];
                if(j===5) registro.gct1=datos[i][j];
                if(j===6) registro.gct2=datos[i][j];
                if(j===7) registro.golesjugada=datos[i][j];
                if(j===8) registro.golestirolibre=datos[i][j];
                if(j===9) registro.golespenalti=datos[i][j];
                if(j===10) registro.autogoles=datos[i][j];
                if(j===11) registro.asistencias=datos[i][j];
                if(j===12) registro.faltasrecibidas=datos[i][j];
                if(j===13) registro.faltascometidas=datos[i][j];
                if(j===14) registro.tarjetasamarillas=datos[i][j];
                if(j===15) registro.tarjetasrojas=datos[i][j];
                if(j===16) registro.atajadas=datos[i][j];
                if(j===17) registro.disparosarco=datos[i][j];
                if(j===18) registro.disparosdentro=datos[i][j];
                if(j===19) registro.numjugadores=datos[i][j];
                if(j===20) registro.numcambios=datos[i][j];
                if(j===21) registro.fechainicio=datos[i][j];
                if(j===22) registro.fechafin=datos[i][j];
                if(j===23) registro.duraciontiempo1=datos[i][j];
                if(j===24) registro.duraciontiempo2=datos[i][j];
                if(j===25) registro.tarjetasazules=datos[i][j];
            }
            infoResumenO.push(registro);
            infoResumen.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}

var infoEstadisticasO = [];
var infoEstadisticas = [];
function crearInfoEstadisticas(datos){    
    const nomFuncion="crearInfoEstadisticas";

    try{
        let estadistica = {
            idtorneo:"",
            idjugador:"",
            nom_jugador:"",
            nom_equipo:"",
            fijo:"",
            goles:"",
            autogol:"",
            asistencias:"",
            atajadas:"",
            disparosarco:"",
            disparosdentro:"",
            faltasrecibidas:"",
            faltascometidas:"",
            tarjetasamarillas:"",
            tarjetasrojas:"",
            minjugados:"",
            participacion_goles:"",
            efectividad:"",
            foto:"",
            idequipo:"",
            tarjetasazules:""
        }
    
        infoEstadisticasO = [];
        infoEstadisticas = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , estadistica);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idjugador=datos[i][j];
                if(j===2) registro.nom_jugador=datos[i][j];
                if(j===3) registro.nom_equipo=datos[i][j];
                if(j===4) registro.fijo=datos[i][j];
                if(j===5) registro.goles=datos[i][j];
                if(j===6) registro.autogol=datos[i][j];
                if(j===7) registro.asistencias=datos[i][j];
                if(j===8) registro.atajadas=datos[i][j];
                if(j===9) registro.disparosarco=datos[i][j];
                if(j===10) registro.disparosdentro=datos[i][j];
                if(j===11) registro.faltasrecibidas=datos[i][j];
                if(j===12) registro.faltascometidas=datos[i][j];
                if(j===13) registro.tarjetasamarillas=datos[i][j];
                if(j===14) registro.tarjetasrojas=datos[i][j];
                if(j===15) registro.minjugados=datos[i][j];
                if(j===16) registro.participacion_goles=datos[i][j];
                if(j===17) registro.efectividad=datos[i][j];
                if(j===18) registro.foto=datos[i][j];
                if(j===19) registro.idequipo=datos[i][j];
                if(j===20) registro.tarjetasazules=datos[i][j];
            }
            infoEstadisticasO.push(registro);
            infoEstadisticas.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    } 

    
}

function ordenarInfomacionJugadores(){
    let nomFuncion="ordenarInfomacionJugadores";

    try{
        let i,j;
        let infoOrdenada=[];

        for(i=0;i<columnasOrdenarJug.length;i++){
            let col=columnasOrdenarJug[i];
            let datosTemp=[];
            for(j=0;j<infoEstadisticas.length;j++){
                datosTemp.push(valorNumero(infoEstadisticas[j][col]));  
            }

            let datosTemp2=organizarListaJugadores(datosTemp);
                        
            infoOrdenada.push(datosTemp2);

        }

        infoOrdenadaJugadores=infoOrdenada;


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    } 

}

function organizarListaJugadores(list){
    let nomFuncion="organizarListaJugadores";
    let posiciones=[];

    try{
        let k, i, j;
        for(k=0;k<list.length;k++){
            posiciones.push(k);
        }

        for(i =0; i< list.length; i++){
            let sorted = true; // asumo que para la iteración i el listado es ordenado,
            for(j =0; j< list.length - i - 1; j++){ // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición
                let idt1=valorNumero(infoEstadisticas[j][0]);
                let idt2=valorNumero(infoEstadisticas[j+1][0]);
                if(idt1>idt2){
                    let temp = list[j];
                    list[j] = list[j+1];
                    list[j+1] = temp;
                    sorted = false;

                    temp=posiciones[j];
                    posiciones[j]=posiciones[j+1];
                    posiciones[j+1]=temp;
                }
                else if(idt1===idt2){
                    if(list[j] < list[j+1]){
                        let temp = list[j];
                        list[j] = list[j+1];
                        list[j+1] = temp;
                        sorted = false;

                        temp=posiciones[j];
                        posiciones[j]=posiciones[j+1];
                        posiciones[j+1]=temp;
                    }
                    else if(list[j] === list[j+1]){
                        let partJugados=valorNumero(infoEstadisticas[posiciones[j]][4]);
                        let partJugados2=valorNumero(infoEstadisticas[posiciones[j+1]][4]);
                        let tempNum=0;
                        let tempNum2=0;
                        if(partJugados>0) tempNum = ( list[j] / partJugados);
                        if(partJugados2>0) tempNum2 = ( list[j + 1] / partJugados2);

                        if(tempNum<tempNum2){
                            let temp = list[j];
                            list[j] = list[j+1];
                            list[j+1] = temp;
                            sorted = false;

                            temp=posiciones[j];
                            posiciones[j]=posiciones[j+1];
                            posiciones[j+1]=temp;
                        }
                        else if(tempNum===tempNum2){
                            if(partJugados<partJugados2){
                                let temp = list[j];
                                list[j] = list[j+1];
                                list[j+1] = temp;
                                sorted = false;

                                temp=posiciones[j];
                                posiciones[j]=posiciones[j+1];
                                posiciones[j+1]=temp;
                            }
                        }

                    }
                }


            }
            if(sorted){
                return posiciones;
            }
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

    return posiciones;
}


const Detalle_Partido = ({params="", modOrigen, paramOrigen, definirModulo}) => {

    

    const [esError, setEsError] = useState(0);
    const [data, setData] = useState(false);
    const [pestana, setPestana] = useState("0");
    const [equipoSel, setEquipoSel] = useState("");
    const [loading, setLoading] = useState(false);
    const [tipoLista, setTipoLista] = useState(true);
    const [indicadorSel, setIndicadorSel] = useState(1);
    const [idJugSel, setIdJugSel] = useState("");

    try{
    
    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{

            let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);

            paramsAjustado=params;

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,"#",5);
                idPartido=ultParametros;

                if(idPartido==="" || idPartido!==info[0]){
                    actualizar=true;
                    guardarDatoLocal("ultParametros" + idModulo,info[0]);
                    idPartido=info[0];
                }  

                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                }

                if(info[1]!=="" && info[1]!==pestana){
                    paramsAjustado=info[0] + "#" + info[1] + "#" + info[2] + "#" + info[3] + "#" + info[4];
                    setPestana(info[1]); 
                }  
                else {
                    paramsAjustado=info[0] + "#" + "1" + "#" + info[2] + "#" + info[3] + "#" + info[4];
                    setPestana("1"); 
                }

                //paramsBase=paramsAjustado;

                if(info[2]!==""){
                    setIdJugSel(info[2]);
                }


                if(actualizar){
                    setLoading(true);

                    getDatos()
                    .get("consultarTabla","getInfoPartido","INFO_DETALLE_PARTIDO",idPartido ,"0")
                    .then((res) => {
                        respuesta=res;
                        if (respuesta.exito) {
                            presentarLog(nomClase + " - SOLICITA_DATOS"); 

                            guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                            guardarDatoLocal("datos" + idModulo, respuesta.datos);

                            crearInfoPartido(crearTabla(respuesta.datos,"1"));
                            crearInfoJugadores(crearTabla(respuesta.datos,"2"));
                            crearInfoAcciones(crearTabla(respuesta.datos,"3"));
                            crearInfoResumen(crearTabla(respuesta.datos,"4"));
                            crearInfoEstadisticas(crearTabla(respuesta.datos,"5"));

                            //if(pestana==="1"){
                            if(info[1]===""){
                                if(estado==="PROGRAMADO") setPestana("1");
                                if(estado==="REGISTRADO") setPestana("2");
                                if(estado==="JUGANDO" || estado==="DESCANSO" || estado==="PENALTIS") setPestana("3");
                                if(estado.includes("FINALIZADO")) setPestana("4");
                                else setPestana("1");

                                setEquipoSel(idEquipoSel);
                            }

                            numSel=0;
                            textoPSel="Goles";
                            textoSSel="Mayor Cantidad de Goles Realizados";
                            imagenSel=ic_balon_gol;
                            setIndicadorSel(1);

                            if(info[2]==="") setIdJugSel("");

                            if(infoEstadisticas.length>0) ordenarInfomacionJugadores();                            
                            
                            setData(true);
    
                            registrarLogEventos(idModulo, "1", idPartido,"1" );
            
                        } else {
                            setData(false);
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            } 
                        }
            
                        setLoading(false);
                        
                    });
    
                }
                else{
                    if(pestana==="") setPestana("1");

                    presentarLog(nomClase + " - RECUPERA_DATOS"); 
                    let datosModulo=recuperarDatoLocal("datos" + idModulo);

                    if(datosModulo!==""){
                        crearInfoPartido(crearTabla(datosModulo,"1"));
                        crearInfoJugadores(crearTabla(datosModulo,"2"));
                        crearInfoAcciones(crearTabla(datosModulo,"3"));
                        crearInfoResumen(crearTabla(datosModulo,"4"));
                        crearInfoEstadisticas(crearTabla(datosModulo,"5"));

                        //if(pestana==="1"){
                        if(info[1]===""){
                            if(estado==="PROGRAMADO") setPestana("1");
                            if(estado==="REGISTRADO") setPestana("2");
                            if(estado==="JUGANDO" || estado==="DESCANSO" || estado==="PENALTIS") setPestana("3");
                            if(estado.includes("FINALIZADO")) setPestana("4");
                            else setPestana("1");

                            setEquipoSel(idEquipoSel);
                        }

                        numSel=0;
                        textoPSel="Goles";
                        textoSSel="Mayor Cantidad de Goles Realizados";
                        imagenSel=ic_balon_gol;
                        setIndicadorSel(1);

                        if(info[2]==="") setIdJugSel("");

                        if(infoEstadisticas.length>0) ordenarInfomacionJugadores();                            
                        
                        setData(true);
                    }     
                }
                
            }
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }        

    }, [params]);

    if(params==="") return null;

    
    function presentarPestana(){ 
        let nomFuncion="presentarPestana";

        try{
        
            if((pestana==="1")) {
                nomFuncion="presentarPestana.1";
                let datos=[];
                //let dato1="";
                //let dato2="";

                let jornada="";            
                
                
                    if(infoPartidoO.length>0){
                        if(infoPartidoO[0].tipoJornada==="1"){
                            jornada= "No. " + infoPartidoO[0].numNuevoJornada + " - Fase de Grupos";
                        }
                        else{
                            jornada = infoPartidoO[0].nomJornada + " (" + infoPartidoO[0].nomCopa + ")";
                        }

                        let escudo=ic_torneo; 
                        if(infoPartidoO[0].escudotorneo!== "") escudo = ruta + infoPartidoO[0].escudotorneo;
                        datos.push([escudo,"Torneo", (infoPartidoO[0].torneo || ""),true,ID_MODULO_DETALLE_TORNEO,idTorneo]);
                        datos.push([ic_sedes,"Sede", (infoPartidoO[0].sede || ""),false]);
                        
                        let nomCancha="N. " + infoPartidoO[0].cancha;                
                        if(infoPartidoO[0].nomcancha!=="") nomCancha="[" + infoPartidoO[0].cancha + "] " + infoPartidoO[0].nomcancha;
                        datos.push([ic_partido_verde,"Cancha", nomCancha,(infoPartidoO[0].ubicacion!=="" ? false : false)]);
        
                        datos.push([ic_transparente,"Jornada", (jornada), false]);
                        datos.push([ic_programar,"Fecha Partido", formatearFecha(infoPartidoO[0].fecha,9), false]);

                        let minAntes=valorNumero(infoPartidoO[0].minutosantes);
                        if(minAntes>0) datos.push([ic_transparente,"Hora Partido", esformatoHoraDia(infoPartidoO[0].hora) + "\nEn cancha " + minAntes + " min antes", false]);
                        else datos.push([ic_transparente,"Hora Partido", esformatoHoraDia(infoPartidoO[0].hora), false]);
        
                        escudo=ic_escudo_base_gris; 
                        if(infoPartidoO[0].escudoeq1!== "") escudo = ruta + infoPartidoO[0].escudoeq1;
                        datos.push([escudo,"Eq. Local", (infoPartidoO[0].equipo1 || ""), true,ID_MODULO_DETALLE_EQUIPO,idEquipo1]);
        
                        escudo=ic_escudo_base_gris; 
                        if(infoPartidoO[0].escudoeq2!== "") escudo = ruta + infoPartidoO[0].escudoeq2;
                        datos.push([escudo,"Eq. Visitante", (infoPartidoO[0].equipo2 || ""), true,ID_MODULO_DETALLE_EQUIPO,idEquipo2]);
        
                        let entrar=false;
                        if(infoPartidoO[0].idarbitro1==="1" || infoPartidoO[0].idarbitro1===infoPartidoO[0].idarbitro_def) entrar=false;
                        escudo=ic_jugadorsinimagen; 
                        if(infoPartidoO[0].fotoarbitro1!== "") escudo = ruta + infoPartidoO[0].fotoarbitro1;
                        datos.push([escudo,"Arb. Principal", (infoPartidoO[0].arbitro1 || ""), entrar]);
        
                        datos.push([ic_transparente,"Estado", (infoPartidoO[0].estado || ""), false]);
        
                    }

                    
                
                return(
                    <div  style={{maxWidth:"800px"}}>
                    <table width="100%">
                        <tbody>
                        {(datos.map((reg, index) => (<TemplateLista2Items key={index} llevaImagen={true} imagen={reg[0]} dato1={reg[1]} dato2={reg[2]} llevaEntrar={reg[3]} modDestino={reg[4]} paramDestino={reg[5]} modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />))  )}
                        </tbody>
                    </table>    
                    </div>            
                );
            }            
            else if((pestana==="2")) {
                nomFuncion="presentarPestana.2";
                let datos=[];
                let i;  

                if(idJugSel===""){
                        if(equipoSel==="") setEquipoSel(idEquipo1);
                        
                        for(i=0;i<infoJugadoresO.length;i++){
                            let id="", foto=ic_jugadorsinimagen, nombre="", numCamiseta="", confirmo="0", fechaConfirmo="", llego="0", fechaLlego="";
                            let activo=false;
                            let siAsistio=false;
                            let entrar=false;                        
            
                            if(infoJugadoresO[i].activo_eq==="1" && infoJugadoresO[i].activo_sede==="1") activo=true;
                            if(infoJugadoresO[i].llego==="1") siAsistio=true;
            
                            if(infoJugadoresO[i].idequipo===equipoSel && (activo || siAsistio)){
                                id=infoJugadoresO[i].idjugador;
                                nombre=infoJugadoresO[i].nombre;
                                if(infoJugadoresO[i].foto!=="") foto=ruta + infoJugadoresO[i].foto;
            
                                if(valorNumero(infoJugadoresO[i].camiseta_partido)>0) numCamiseta=infoJugadoresO[i].camiseta_partido;
                                else numCamiseta=infoJugadoresO[i].camiseta_base;
            
                                if(infoJugadoresO[i].confirmo==="0" || infoJugadoresO[i].confirmo===""){
                                    if(infoJugadoresO[i].fechaconfirmo==="") confirmo="0";
                                    else confirmo="2";
                                }
                                else if(infoJugadoresO[i].confirmo==="1") confirmo="1";
            
                                
                                if(infoJugadoresO[i].fechaconfirmo!=="") fechaConfirmo=formatearFecha(infoJugadoresO[i].fechaconfirmo,11);
                                else fechaConfirmo="";
            
                                if(infoJugadoresO[i].llego==="0" || infoJugadoresO[i].llego===""){
                                    if(infoJugadoresO[i].fechallego==="") llego="0";
                                    else llego="2";
                                }
                                else if(infoJugadoresO[i].llego==="1") llego="1";
            
                                
                                if(infoJugadoresO[i].fechallego!=="") fechaLlego=formatearFecha(infoJugadoresO[i].fechallego,11);
                                else fechaLlego="";

                                if(estado.includes("FINALIZADO") && siAsistio) entrar=true;
            
                                datos.push([id, foto, nombre, numCamiseta, confirmo, fechaConfirmo, llego, fechaLlego, entrar]);
            
                            }
                        }
        
                                
                    
                    return(
                        <div  style={{maxWidth:"800px"}}>
                        <div width="100%" style={{display:"flex", flexWrap:"nowrap", justifyContent:"space-between", alignItems:"center"}}>
                            <img className="IM_camiseta" src={uniformeEq1} alt=""  />
                            <div style={{flex:"1", alignItems:"center"}} className={tipoLista ? "BF_botonPrimaryFull" : "BF_botonSinColor"} onClick={() => {clickEquipos(!tipoLista)}}>
                                        <span>{nomeq1}</span>
                            </div>
                            <div style={{flex:"1", alignItems:"center"}} className={!tipoLista ? "BF_botonPrimaryFull" : "BF_botonSinColor"} onClick={() => {clickEquipos(!tipoLista)}}>
                                        <span>{nomeq2}</span>
                            </div>
                            <img className="IM_camiseta" src={uniformeEq2} alt=""  />
                        </div>
                        <table width="100%" border="0">
                            <tbody>
                                <tr className='TX_letraMuyPeq' style={{textAlign:"center"}}><td></td><td>Nombre</td><td>Num</td><td>Confirmo</td><td>Asiste</td></tr>
                                {(datos.map((reg, index) => (<Template_lista_jugadorespartido key={index} datos={reg} handleClick={clickJugador} />))  )}
                            </tbody>
                        </table>   
                        </div>             
                    );
                }
                else{
                    let idxJug=buscarJugador(idJugSel);
                    
                    let nombre="";
                    let foto=ic_jugadorsinimagen;
                    let numCamiseta="";

                    if(idxJug>=0){
                        nombre=infoJugadoresO[idxJug].nombre;
                        if(infoJugadoresO[idxJug].foto!=="") foto=ruta + infoJugadoresO[idxJug].foto;
                        
                        if(valorNumero(infoJugadoresO[idxJug].camiseta_partido)>0) numCamiseta=infoJugadoresO[idxJug].camiseta_partido;
                        else numCamiseta=infoJugadoresO[idxJug].camiseta_base;
                    }


                    return(
                        <>
                        <div className="CT_contenedorTitulo">
                            <img className='IM_imgIcono36' src={ic_jugador} alt='' />   
                            <p style={{marginLeft: "10px", fontSize:"18px"}}>Estadisticas de Jugador</p>             
                        </div> 

                        <div className='BF_botonBordeAccent'  style={{minWidth:"250px", maxWidth:"500px", display:"flex", alignItems:"center", justifyContent:"center", marginLeft:"10px", marginRight:"10px"}} onClick={() => {setIdJugSel("")}}>
                            <img className='IM_imgIcono24' src={ic_flecha_expandir} alt="" />
                            <img className='IM_imgRedondeada36' src={foto} alt="" style={{marginLeft:"10px", marginRight:"10px"}}/>
                            <span className='TX_unaSolaLinea2' style={{flex:"1", fontSize:"16px", textAlign:"left", color:C_uniformeGrisOscuro}}>{nombre}</span>
                            <div className='IM_imagenConTexto'>
                                <img className="IM_camiseta" src={ic_uniforme_oscuro} alt=""  />
                                <span className='IM_numeroCamiseta'>{numCamiseta}</span>
                            </div>
                        </div>

                        <div  style={{marginTop:"20px"}}>
                            <DP_InfoEstadisticasJugador idTorneo={idTorneo} idJug={idJugSel} infoJugadores={infoJugadores} tipo_registroinfo={tipo_registroinfo} tipoReporte={"partido"} />
                        </div>
                        </>
                        

                    );
                }
                
                
            }            
            else if((pestana==="4")) {
                nomFuncion="presentarPestana.4";
                //let sinDatos=false;
                let regEq1=0, regEq2=0, i;
                let tempInt1=0, tempInt2=0;

                let datos=dividirLista("","|",8);
                let datosDisparos=dividirLista("","|",4);
                let datosEfectividad=dividirLista("","|",8);
                let datosPorteria=dividirLista("","|",8);
                let datosTiempo=dividirLista("","|",6);

            
                    if(infoResumenO.length>0) {
                        for (i = 0; i < infoResumenO.length; i++) {
                            if (infoResumenO[i].idequipo===idEquipo1) regEq1 = i;
                            else if (infoResumenO[i].idequipo===idEquipo2) regEq2 = i;
                        }
                    }
                    else{
                        //sinDatos=true;
                        infoResumenO.push(resumen);
                        infoResumenO.push(resumen);
                    }
        
                    
                    datos[0]="" + (valorNumero(infoResumenO[regEq1].gft1) + valorNumero(infoResumenO[regEq1].gft2));
                    datos[1]="" + (valorNumero(infoResumenO[regEq2].gft1) + valorNumero(infoResumenO[regEq2].gft2));
                    datos[2]=infoResumenO[regEq1].golesjugada;
                    datos[3]=infoResumenO[regEq2].golesjugada;
                    datos[4]=infoResumenO[regEq1].golestirolibre;
                    datos[5]=infoResumenO[regEq2].golestirolibre;
                    datos[6]=infoResumenO[regEq1].golespenalti;
                    datos[7]=infoResumenO[regEq2].golespenalti;
        
                    if(tipo_registroinfo==="2"){
                        
                        let tempNum=0;
        
                        //INFORME DISAPAROS
                        datosDisparos[0]="" + (infoResumenO[regEq1].disparosarco-infoResumenO[regEq1].disparosdentro); //disparos desviados
                        datosDisparos[1]="" + (infoResumenO[regEq2].disparosarco-infoResumenO[regEq2].disparosdentro); //disparos desviados
                        datosDisparos[2]="" + infoResumenO[regEq1].disparosdentro; //disparos al arco
                        datosDisparos[3]="" + infoResumenO[regEq2].disparosdentro; //disparos al arco
        
                        
                        datosEfectividad[0]="Efectividad de Disparo";
                        datosEfectividad[1]="Goles/Disparos al Arco";
                        tempInt1=valorNumero(infoResumenO[regEq1].gft1) + valorNumero(infoResumenO[regEq1].gft2);
                        tempInt2=valorNumero(infoResumenO[regEq1].disparosdentro);
                        tempNum=0;
                        if(tempInt2>0) tempNum=(tempInt1/tempInt2)*100;
                        datosEfectividad[4]=formatearPorcentaje(tempNum);
                        datosEfectividad[6]=tempInt1 + "/" + tempInt2;
                        tempInt1=valorNumero(infoResumenO[regEq2].gft1) + valorNumero(infoResumenO[regEq2].gft2);
                        tempInt2=valorNumero(infoResumenO[regEq2].disparosdentro);
                        tempNum=0;
                        if(tempInt2>0) tempNum=(tempInt1/tempInt2)*100;
                        datosEfectividad[5]=formatearPorcentaje(tempNum);
                        datosEfectividad[7]=tempInt1 + "/" + tempInt2;
        
        
                        //EFECTIVIDAD DE PORTERIA                 
                        datosPorteria[0]="Efectividad Porteria";
                        datosPorteria[1]="Atajadas/Disparos Recibidos";
                        tempInt1=valorNumero(infoResumenO[regEq1].gct1) + valorNumero(infoResumenO[regEq1].gct2);
                        tempInt2=valorNumero(infoResumenO[regEq1].atajadas);
                        tempNum=0;
                        if((tempInt2+tempInt1)>0) tempNum=(tempInt2/(tempInt2+tempInt1))*100;
                        datosPorteria[4]=formatearPorcentaje(tempNum);
                        datosPorteria[6]=tempInt2 + "/" + (tempInt2+tempInt1);
                        tempInt1=valorNumero(infoResumenO[regEq2].gct1) + valorNumero(infoResumenO[regEq2].gct2);
                        tempInt2=valorNumero(infoResumenO[regEq2].atajadas);
                        tempNum=0;
                        if((tempInt2+tempInt1)>0) tempNum=(tempInt2/(tempInt2+tempInt1))*100;
                        datosPorteria[5]=formatearPorcentaje(tempNum);
                        datosPorteria[7]=tempInt2 + "/" + (tempInt2+tempInt1);
        
                                        
                    }
                    
                    let tempTiempo=0;
                    let horas=0,minutos=0,segundos=0;
        
                    tempTiempo=valorNumero(infoResumenO[regEq1].duraciontiempo1)+valorNumero(infoResumenO[regEq1].duraciontiempo2);
                    let valorTiempo="";
                    horas=parseInt(tempTiempo/3600);
                    minutos=parseInt((tempTiempo-(horas*3600))/60);
                    segundos=parseInt(tempTiempo-(horas*3600)-(minutos*60));
                    if(horas>0) valorTiempo=horas + "h ";
                    if(minutos>0) valorTiempo=valorTiempo + minutos + "m ";
                    if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                    datosTiempo[0]=valorTiempo; //Tiempo Jugado Total
                    datosTiempo[1]="";
        
                    tempTiempo=valorNumero(infoResumenO[regEq1].duraciontiempo1);
                    valorTiempo="";
                    horas=parseInt(tempTiempo/3600);
                    minutos=parseInt((tempTiempo-(horas*3600))/60);
                    segundos=parseInt(tempTiempo-(horas*3600)-(minutos*60));
                    if(horas>0) valorTiempo=horas + "h ";
                    if(minutos>0) valorTiempo=valorTiempo + minutos + "m ";
                    if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                    datosTiempo[2]=valorTiempo; //Tiempo Jugado Total
                    datosTiempo[3]="";
        
                    tempTiempo=valorNumero(infoResumenO[regEq1].duraciontiempo2);
                    valorTiempo="";
                    horas=parseInt(tempTiempo/3600);
                    minutos=parseInt((tempTiempo-(horas*3600))/60);
                    segundos=parseInt(tempTiempo-(horas*3600)-(minutos*60));
                    if(horas>0) valorTiempo=horas + "h ";
                    if(minutos>0) valorTiempo=valorTiempo + minutos + "m ";
                    if(segundos>0) valorTiempo=valorTiempo + segundos + " s ";
                    datosTiempo[4]=valorTiempo; //Tiempo Jugado Total
                    datosTiempo[5]="";

                    


                return(
                <div  style={{maxWidth:"800px"}}>
                    {infoResumenO[regEq1].idequipo==="" ? 
                    (<p width="100%" style={{fontSize:"18px", color:C_colorTextoSinInfo, textAlign:"center"}}>Resumen Será Actualizado al Finalizar el Partido</p>) 
                    : (<DinamicoResumenGanador texto1={infoResumenO[regEq1].tiporesultado} texto2={infoResumenO[regEq2].tiporesultado} puntos1={infoResumenO[regEq1].puntos} puntos2={infoResumenO[regEq2].puntos} />)
                    }
                    <div className='BF_lineaInferior'></div>
                    <DinamicoResumenGoles tipo={2} datos={datos} />

                    {tipo_registroinfo==="2" && (
                        <>
                        <DinamicoResumenBarra imagen={ic_disparo_arco} titulo={"Disparos Totales"} cantidad1={infoResumenO[regEq1].disparosarco} cantidad2={infoResumenO[regEq2].disparosarco}/>
                        
                        <DinamicoResumenDisparos tipo={2} datos={datosDisparos} />
                        <DinamicoResumenEfectividad datos={datosEfectividad} />

                        <DinamicoResumenBarra imagen={ic_atajada} titulo={"Atajadas"} cantidad1={infoResumenO[regEq1].atajadas} cantidad2={infoResumenO[regEq2].atajadas}/>

                        <DinamicoResumenEfectividad datos={datosPorteria} />

                        </>
                    )}

                    <p width="100%" style={{fontSize:"16px", fontWeight:"bold", color:C_uniformeNegro, textAlign:"center", margin:"20px"}}>DISCIPLINA</p>

                    {tipo_registroinfo==="2" && (
                        <DinamicoResumenBarra imagen={ic_falta_cometida} titulo={"Faltas Cometidas"} cantidad1={infoResumenO[regEq1].faltascometidas} cantidad2={infoResumenO[regEq2].faltascometidas}/>
                    )}
                    <DinamicoResumenBarra imagen={ic_amarilla} titulo={"Tarjetas Amarillas"} cantidad1={infoResumenO[regEq1].tarjetasamarillas} cantidad2={infoResumenO[regEq2].tarjetasamarillas}/>
                    {tieneTarjetaAzul===true && (<DinamicoResumenBarra imagen={ic_tarjeta_azul} titulo={"Tarjetas Azules"} cantidad1={infoResumenO[regEq1].tarjetasazules} cantidad2={infoResumenO[regEq2].tarjetasazules}/>)}
                    <DinamicoResumenBarra imagen={ic_tarjeta_roja} titulo={"Tarjetas Rojas"} cantidad1={infoResumenO[regEq1].tarjetasrojas} cantidad2={infoResumenO[regEq2].tarjetasrojas}/>

                    <p width="100%" style={{fontSize:"16px", fontWeight:"bold", color:C_uniformeNegro, textAlign:"center", margin:"20px"}}>JUGADORES</p>
                    <DinamicoResumenBarra imagen={ic_equipo} titulo={"Jugadores Presentes"} cantidad1={infoResumenO[regEq1].numjugadores} cantidad2={infoResumenO[regEq2].numjugadores}/>
                    <DinamicoResumenBarra imagen={ic_cambiojugador} titulo={"Cambios Realizados"} cantidad1={infoResumenO[regEq1].numcambios} cantidad2={infoResumenO[regEq2].numcambios}/>

                    <p width="100%" style={{fontSize:"16px", fontWeight:"bold", color:C_uniformeNegro, textAlign:"center", margin:"20px"}}>DATOS GENERALES</p>
                    <DinamicoResumenTiempo datos={datosTiempo} />

                </div>
                );
            }            
            else if((pestana==="5")) {                
                nomFuncion="presentarPestana.5";
                
                return(
                <>
                    <div className="CT_contenedorTitulo">
                        <img className='IM_imgIcono36' src={ic_equipo} alt='' />   
                        <p style={{marginLeft: "10px", fontSize:"18px"}}>Ranking de Jugadores</p>             
                    </div> 

                    {indicadorSel>=1 && (
                    <div className='CT_menuIndicadores' style={tipo_registroinfo==="1" ? ({maxWidth:"400px"}) :  ({maxWidth:"800px"})}>
                    
                        {incluirIndicador(1)}                    
                        {tipo_registroinfo==="2" && incluirIndicador(2)}
                        {tipo_registroinfo==="2" && incluirIndicador(3)}
                        {tipo_registroinfo==="2" && incluirIndicador(4)}
                        {tipo_registroinfo==="2" && incluirIndicador(5)}
                        {tipo_registroinfo==="2" && incluirIndicador(6)}
                        {tipo_registroinfo==="2" && incluirIndicador(7)}
                        {tipo_registroinfo==="2" && incluirIndicador(8)}
                        {tipo_registroinfo==="2" && incluirIndicador(9)}
                        {tipo_registroinfo==="2" && incluirIndicador(10)}
                        {incluirIndicador(11)}
                        {incluirIndicador(12)}
                        {tieneTarjetaAzul===true && incluirIndicador(13)}
                        {incluirIndicador(14)}

                    </div>  
                    ) }

                    <div style={{height:"20px"}}></div>     

                    {indicadorSel>=1 && (
                        <div  className='CT_contenedorFlex'>
                        <DinamicoRankingIndicadores idTorneo={idTorneo} indicador={numSel} titulo={textoPSel} subtitulo={textoSSel} imagen={imagenSel} esporcentaje={numSel===12 ? true : false} idxCant={columnasOrdenarJug[numSel]} infoOrdenadaJugadores={infoOrdenadaJugadores} infoEstadisticas={infoEstadisticas}  modOrigen={idModulo} paramOrigen={paramsAjustado} definirModulo={definirModulo} />
                        </div>
                    ) }        

                </>
                ); 
            }      
            else return(<></>);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

        
    }

    const selIndicador = (e, sel, num, textoP, textoS, imagen) => {        
        let nomFuncion="selIndicador";

        try{
            if(indicadorSel!==sel){
                numSel=num;
                textoPSel=textoP;
                textoSSel=textoS;
                imagenSel=imagen;
                setIndicadorSel(sel);
            }

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        } 
    }

    const incluirIndicador = (indicador) => {
        let nomFuncion="incluirIndicador";

        try{

            let fondo="";
            
            let imagen="";
            let texto="";
            let num=0;
            let textoP="";
            let textoS="";

            if(indicador===indicadorSel) fondo="BF_fondolistaspar";

            if(indicador===1){
                imagen=ic_balon_gol;
                texto="G";
                num=0;
                textoP="Goles";
                textoS="Mayor Cantidad de Goles Realizados";
            }
            else if(indicador===2){
                imagen=ic_asistencia;
                texto="A";
                num=2;
                textoP="Asistencias";
                textoS="Mayor Cantidad de Asistencias";
            }
            else if(indicador===3){
                imagen=ic_asistencia;
                texto="G + A";
                num=11;
                textoP="Goles y Asistencias";
                textoS="Mayor Participación en Goles";
            }
            else if(indicador===4){
                imagen=ic_disparo_arco;
                texto="DT";
                num=4;
                textoP="Disparos Totales";
                textoS="Disparos Totales Realizados";
            }
            else if(indicador===5){
                imagen=ic_disparo;
                texto="DA";
                num=5;
                textoP="Disparos al Arco";
                textoS="Disparos Realizados dentro del Arco";
            }
            else if(indicador===6){
                imagen=ic_porcentajetiempo;
                texto="ED";
                num=12;
                textoP="Efectividad de Disparo";
                textoS="Goles por cada 100 Disparos al Arco";
            }
            else if(indicador===7){
                imagen=ic_atajada;
                texto="AT";
                num=3;
                textoP="Atajadas";
                textoS="Total Atajadas Realizadas";
            }
            else if(indicador===8){
                imagen=ic_reloj;
                texto="MJ";
                num=10;
                textoP="Minutos Jugados";
                textoS="Mayor Cantidad de Minutos Jugados";
            }
            else if(indicador===9){
                imagen=ic_falta_recibida;
                texto="FR";
                num=6;
                textoP="Faltas Recibidas";
                textoS="Mayor Cantidad de Faltas Recibidas";
            }
            else if(indicador===10){
                imagen=ic_falta_cometida;
                texto="FC";
                num=7;
                textoP="Faltas Cometidas";
                textoS="Mayor Cantidad de Faltas Cometidas";
            }
            else if(indicador===11){
                imagen=ic_amarilla;
                texto="TA";
                num=8;
                textoP="Tarjetas Amarillas";
                textoS="Mas Tarjetas Amarillas ha Recibido";
            }
            else if(indicador===12){
                imagen=ic_tarjeta_roja;
                texto="TR";
                num=9;
                textoP="Tarjetas Rojas";
                textoS="Mas Tarjetas Rojas ha Recibido";
            }
            else if(indicador===13){
                imagen=ic_tarjeta_azul;
                texto="TZ";
                num=13;
                textoP="Tarjetas Azules";
                textoS="Mas Tarjetas Azules ha Recibido";
            }
            else if(indicador===14){
                imagen=ic_balon_autogol;
                texto="AG";
                num=1;
                textoP="Autogoles";
                textoS="Mas Autogoles Realizados";
            }



        return(      
                <div className="CT_indicador">    
                        <div className={fondo} align="center" style={{minWidth:"20px", maxWidth:"80px", padding:"10px"}} onClick={(e) => selIndicador(e, indicador, num, textoP, textoS, imagen)}  >
                            <img className='IM_imgIcono24' src={imagen} alt="" />
                            <span>{texto}</span>
                        </div>     
                </div>         
        );

        }catch(err){
            reportarError(nomClase, nomFuncion, "", err);
        } 
        
    }

    const clickEquipos = (estado) => {
        if(equipoSel===idEquipo1) setEquipoSel(idEquipo2);
        else setEquipoSel(idEquipo1);
        setTipoLista(estado);       
    }

    const clickMenu = (e, numPestana) => {
        if(numPestana!==pestana){
            setIdJugSel("");
            let info=dividirLista(params,"#",5);
            paramsAjustado=info[0] + "#" + numPestana + "#" + info[2] + "#" + info[3] + "#" + info[4];
            setPestana(numPestana);  
        }
                     
    }

    const clickJugador = (idJug) => {
        setIdJugSel(idJug);       
    }

    const buscarJugador = (idJug) => {
        const nomFuncion="";
        let indice=-1;

        try{
            let i;
            for(i=0;i<infoJugadores.length;i++){
                if(infoJugadores[i][1]===idJug){
                    indice=i;
                    i=infoJugadores.length;
                }
            }
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
        
        return indice;
    }


    const clickEquipo = (idEq) => {
        definirModulo(0,modOrigen,paramOrigen,ID_MODULO_DETALLE_EQUIPO,idEq);        
    }  


    const salir = () => {
        if(modOrigen===0 || paramOrigen===""){
            definirModulo(0,0,"",ID_MODULO_DETALLE_TORNEO,idTorneo);     
        }
        else  definirModulo(0,0,"",modOrigen,paramOrigen); 
    }  


    let estiloHoraPartido={
        height: "22px",
        width: "50px",
        background: C_uniformeGrisClaro,
        color:C_uniformeNegro,
        align: "center",
        alignItems: "center",
        fontSize: "medium"
    };

    let estiloMarcador={
        height: "22px",
        width: "50px",
        background: C_uniformeGrisClaro,
        color:C_uniformeNegro,
        align: "center",
        fontWeight: "bold",
        alignItems: "center",
        fontSize: "medium"
    };

    let estiloPenaltis={        
        width: "50px",
        color:C_uniformeVerdeClaro,
        align: "center",
        alignItems: "center",
        fontSize: "12px"     
    };

    let estiloAvancePartido={
        height: "3px",
        background:C_uniformeVerdeClaro,
        align: "left",
        alignItems: "left"
    };
    

    if(data && infoPartidoO.length>0){
        let comp1, comp2, hora1, hora2;

        let datos=infoPartidoO[0];

        if(valorNumero(datos.penaltisequipo1)===0 && valorNumero(datos.penaltisequipo2)===0) txtPenaltis="";
                      
    
        if(estado==="PROGRAMADO" || estado==="REGISTRADO"){
            estiloFinal=estiloHoraPartido;
            txtFinal=txtHora;
    
            comp1=operacionFechas(3,datos.fecha,datos.fecha_actual);
            if(comp1==="3"){
                hora1=sumarMinToHoras(datos.hora,-60);
                hora2=sumarMinToHoras(datos.hora,process.env.REACT_APP_TIEMPO_REGISTRO_RESULTADOS);
                comp1=operacionHorasDia(5,datos.hora_actual,hora1);
                comp2=operacionHorasDia(5,datos.hora_actual,hora2);
    
                if((comp2==="2" || comp2==="3")){
                    estiloFinal.color=C_uniformeRojo;
                }
            }
            else if(comp1==="1"){
                estiloFinal.color=C_uniformeRojo;
            }   
    
        }
        else if(estado==="JUGANDO" || estado==="DESCANSO"){
            estiloFinal=estiloMarcador;
            txtFinal=txtMarcador;
            txtFechaFinal= txtFecha + " " + txtHora;
            
            let avance=0;
    
            if(estado==="JUGANDO"){
                if(datos.parte==="2") avance=50;
    
                let difhora1=difHoras(datos.tiempo, datos.minutospartido);
        
                if(datos.estadotiempo==="1"){ //el tiempo esta corriendo o esta detenido? 1. corriendo
                    let difhora2=difFechas(datos.fecha_actual + " " + datos.hora_actual, datos.fechainiciotiempo); //cuanto tiempo ha pasado desde q esta corriendo el tiempo
                    let tiempo=difHoras(difhora1,difhora2);
        
                   if(tiempo==="00:00"){ //ya paso el tiempo del partido
                        avance=100;
                    }
                    else{
                        avance = avance + (100-valorNumero(operacionHorasDia(6,tiempo,datos.tiempo.substring(3))))/2;
                    }
                }
                else{
                    avance = avance + (valorNumero(operacionHorasDia(6,datos.tiempo.substring(3),datos.minutospartido.substring(3))))/2;
                }
            }
            else avance=50;        
    
            avance=parseInt(avance);
            if(avance>0){
                estiloAvancePartido.width="" + avance + "%";
                porcentajeAvance=avance;
            } 
    
            estiloFinal.background=C_uniformeGrisOscuro;
            estiloFinal.color=C_uniformeBlanco;
    
        }
        else if(estado==="PENALTIS"){
            estiloFinal=estiloMarcador;
            txtFinal=txtMarcador;
            txtFechaFinal= txtFecha + " " + txtHora;
    
            estiloAvancePartido.width="100%";
            porcentajeAvance=100;
            txtFinalPenaltis= txtPenaltis;
            
        }
        else if(estado.includes("FINALIZADO")){
            estiloFinal=estiloMarcador;
            txtFinal=txtMarcador;
            txtFechaFinal= txtFecha + " " + txtHora;
    
            if(datos.goleseq1 === datos.goleseq2) txtFinalPenaltis= txtPenaltis;
        }
        else {
            estiloFinal=estiloHoraPartido;
            txtFinal=txtHora;
        }

    }
    
    

    return (
        <>        
            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                        <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px", cursor:"pointer"}} />  </div>
                        <div className="CT_elementoEncabezado"><img className="IM_imgRedondeada36" height="36px" width="36px" src={ic_partido} alt="" /></div>
                        <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{"Informacion Partido"}</p></div>
                </div>                
            </div>

            <div style={{paddingTop:"50px"}}>

                <div style={{display:"flex", background:C_colorPrimaryDark, paddingTop:"8px", alignItems:"center", color:C_uniformeBlanco}}>
                        <div style={{flex:"2", cursor:"pointer"}} onClick={() => {clickEquipo(idEquipo1)}}>
                            <img className="IM_imgRedondeada46" src={escudoeq1} alt="" />
                            <span className='TX_unaSolaLinea2' style={{fontSize:"12px", fontWeight:"bold"}}>{nomeq1}</span>
                        </div>
                        <div  style={{flex:"3", textAlign: "center", alignItems:"center"}}>
                            <div className='TX_unaSolaLinea2' style={{fontSize:"12px", margin:"2px"}}>{txtFechaFinal}</div>
                            <div style={{display:"block", width:"50px", textAlign: "center", margin:"auto"}}>
                                    <div style={estiloFinal}>{txtFinal}</div>
                                    {txtFinalPenaltis && <div style={estiloPenaltis}>{txtFinalPenaltis}</div>}
                                    {porcentajeAvance>0 && <div style={{width: "95%", margin: "2px", align: "left"}}><div style={estiloAvancePartido}></div></div>}
                            </div>
                            <div className='TX_unaSolaLinea2' style={{fontSize:"12px", color: C_colorAccent }}>{estado}</div>
                        </div>
                        <div style={{flex:"2", cursor:"pointer"}} onClick={() => {clickEquipo(idEquipo2)}}>
                            <img className="IM_imgRedondeada46" src={escudoeq2} alt="" />
                            <span className='TX_unaSolaLinea2' style={{fontSize:"12px", color:C_uniformeBlanco, fontWeight:"bold"}}>{nomeq2}</span>
                        </div>
                </div>  

                <div className="CT_menuDeslizable">
                        <div className="CT_elementoDeslizable"><div className={pestana==="1" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "1")}>INFO</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="2" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "2")}>EQUIPOS</div></div>
                        {/*<div className="CT_elementoDeslizable"><div className={pestana===3 ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, 3)}>ACCIONES</div></div>*/}
                        <div className="CT_elementoDeslizable"><div className={pestana==="4" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "4")}>RESUMEN</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="5" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "5")}>RK PARTIDO</div></div>
                </div>

                <div>

                    {!loading && esError===0 && pestana!=="" && (<div style={{margin:"10px"}}>{presentarPestana()}</div>)  } 
                    
                </div>
                
            </div>

            <div style={{marginBottom:"30px"}}></div>

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}  

        </>
    );



    }catch(err){
        reportarError( nomClase, "Sec_General_WB", "", err);
    } 

};

export default Detalle_Partido;