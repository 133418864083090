import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimary } from '../constantes/generales';
import ic_reloj from '../recGraficos/ic_reloj.png';

const DinamicoResumenTiempo = ({ datos }) => {

    return(
        <div>
            <span style={{fontSize:"16px", color:C_uniformeGrisOscuro}}>{datos.lenght>6 ? (datos[6]) : ("Tiempo Jugado")}</span>

            <div style={{display:"flex", alignItems:"center", marginTop:"5px", marginBottom:"5px"}}>
                <img className="IM_imgIcono36" src={ic_reloj} style={{margin:"5px"}} alt=""/>
                <div style={{flex:"5"}}>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"4", fontSize:"14px", color:C_uniformeGrisOscuro}}>Total</span>
                        <span style={{flex:"3", fontSize:"14px", color:C_colorPrimary}}>{datos[0]}</span>
                        {datos.lenght>0 && datos[1]!=="" &&
                        (<span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[1]}</span>)
                        }   
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"4", fontSize:"14px", color:C_uniformeGrisOscuro}}>Primer Tiempo</span>
                        <span style={{flex:"3", fontSize:"14px", color:C_colorPrimary}}>{datos[2]}</span>
                        {datos.lenght>0 && datos[1]!=="" &&
                        (<span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[3]}</span>)
                        } 
                    </div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <span style={{flex:"4", fontSize:"14px", color:C_uniformeGrisOscuro}}>Segundo Tiempo</span>
                        <span style={{flex:"3", fontSize:"14px", color:C_colorPrimary}}>{datos[4]}</span>
                        {datos.lenght>0 && datos[1]!=="" &&
                        (<span style={{flex:"2", fontSize:"14px", color: C_colorAccent }}>{datos[5]}</span>)
                        } 
                    </div>
                </div>
            </div>

        </div>
    );

};


export default DinamicoResumenTiempo;