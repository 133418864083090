import { useEffect, useState } from "react";
import ShowError from "../recVisual/ShowError";
import Loader from "../recVisual/Loader";
import { convertirHora, crearTabla, dividirLista, esformatoNumero, formatearFecha, formatearTexto, generarDiasSemana, getConfirmacion, guardarDatoLocal, infoGuardadaEsMismoDia, isFlagUpdate, openInNewTab, operacionFechas, presentarError, presentarLog, quitarCharEspeciales, reconstruirDatosOrigen, recuperarDatoLocal, registrarLogEventos, reportarError, setFlagUpdate, showMensajePantalla, sumarMinToHoras, superaTiempoActualizacion, valorLong, valorNumero } from "../helpers/funciones";
import { getDatos } from "../gestionDatos/getDatos";
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeBlanco,  C_colorPrimary, C_colorPrimaryDark, DIAS_SEMANA, ID_MODULO_INFO_EQUIPO, SEP_1, SEP_3, SEP_4, SEP_D_1, SEPARADOR_PARAMETROS_BD, UNIFORMES_COLORES, UNIFORMES_EQUIPOS, C_uniformeNegro, C_tarjetaAzul, C_colorTextoSinInfo, C_colorTextoError, SEP_2, ID_MODULO_ADMIN_JUGADORES, ID_MODULO_PRINCIPAL_JUGADOR } from "../constantes/generales";


import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png';
import ic_salir from '../recGraficos/ic_salir_w.png'
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_flecha_expandir from '../recGraficos/ic_flecha_expandir.png';
import ic_torneo_gris_w from '../recGraficos/ic_torneo_gris_w.png';
import ic_tomarfoto_dark from '../recGraficos/ic_tomarfoto_dark.png';
import ic_uniforme_claro from '../recGraficos/ic_uniforme_claro.png';
import ic_configura_blanco from '../recGraficos/ic_configura_blanco.png';
import ic_expandir_vertical_blanco from '../recGraficos/ic_expandir_vertical_blanco.png';
import ic_contraer_vertical_blanco from '../recGraficos/ic_contraer_vertical_blanco.png';
import ic_registro_manual from '../recGraficos/ic_registro_manual.png';
import ic_jugador_registro from '../recGraficos/ic_jugador_registro.png';
import ic_jugador_registro_primary from '../recGraficos/ic_jugador_registro_primary.png';
import ic_reloj from '../recGraficos/ic_reloj.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png';
import ic_agregar from '../recGraficos/ic_agregar.png';
import ic_buscar_gris from '../recGraficos/ic_buscar_gris.png';
import ic_entrar from '../recGraficos/ic_entrar_gris.png';


import DinamicoElementosTorneo from "../gestionVisual/DinamicoElementosTorneo";
import Modal from "../recVisual/Modal";
import { useModal } from "../helpers/useModal";
import { estilosSelect } from "../estilos/estilosSelect";
import { listaDosItems, listaIconoDosItems } from "../recVisual/listaDesplegable";
import Select from "react-select";

import "../estilos/BF_BordesFondos.css";
import GestionImagen from "../gestionVisual/GestionImagen";
import ModalFull from "../recVisual/ModalFull";


const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Info_Equipo_WB";
const idModulo=ID_MODULO_INFO_EQUIPO;

let idUser="";
let tipoUser=recuperarDatoLocal("tipoUser");;

let idEquipo="";
let idTorneo="";


let datosInicial = {
    tituloVentana:"",
    fotoUsuario:ic_jugadorsinimagen,
    rolUsuario:"",
    nomUsuario:"",
    nomEmpresa:"",
    nomSede:"",
    nomTorneo:"",
    escudoTorneo:ic_torneo_gris_w
};

let matrizCampos=[
    {id: "NOMBRE_LARGO",  idx: 0, valor: "", ultValor:"", error: "Ingrese el nombre del equipo"},
    {id: "NOMBRE_CORTO",  idx: 1, valor: "", ultValor:"", error: "Nombre corto (Max 14 letras)"},
    {id: "UNIFORME_PRI",  idx: 2, valor: "", ultValor:"", error: ""},
    {id: "UNIFORME_SEC",  idx: 3, valor: "", ultValor:"", error: ""},
    {id: "TEXTO_RESALTAR",  idx: 4, valor: "", ultValor:"", error: ""},
    {id: "TEXTO_PUNTOS_BARRA",  idx: 5, valor: "", ultValor:"", error: ""},
    {id: "MD1_HORARIO_PREFERENCIA1",  idx: 6, valor: "", ultValor:"", error: ""},
    {id: "MD1_HORARIO_PREFERENCIA2",  idx: 7, valor: "", ultValor:"", error: ""},
    {id: "MD1_HORARIO_PREFERENCIA3",  idx: 8, valor: "", ultValor:"", error: ""},
    {id: "MD2_CAMISETA",  idx: 9, valor: "", ultValor:"", error: "Debe ingresar un número válido"},
    {id: "FILTRO_BUSQUEDA",  idx: 10, valor: "", ultValor:"", error: ""},
    {id: "ESCUDO",  idx: 11, valor: "", ultValor:"", error: ""}
];

let inicialNuevosJugadores={
    id:"",
    nombre:"",
    telefono:"",
    error:"",
    habilitado: true
};

let paramsAjustado="";
 

let fechaActual="";
let fechaMaxRegistro="";
let fechaMaxEquipo="";
let maxJugadores=30;
let tiempoTotalPartido="60";
let permiteSinTelefono=false;
let aceptoSinTelefono=false;
let definirHorarioPreferencia=false;
let incluir_opciones=false;

let puedeIncluirJugadores=false;
let fechaModificacionesActiva=false;
let puedeModificarEquipo=false;
let jugadoresActivos=0;

let titulo="";
let mensaje="";
let ventanaMensaje;

let tipoUniformeSel="";

let prefEquipo = "";
let prefSeleccionada=-1;
let numSelLista1=0;
let numSelLista2=0;
let numSelLista3=0;
let diaSelLista1=0;
let diaSelLista2=0;
let diaSelLista3=0;
let horarioTorneo=[];

let numJugadorSel=0;

let numNuevosJugadores=0;
let numNuevoJugadorSel=0;;

let datosSedeConsultados=false;
let datosProcesados=false;
let cancelarBusqueda=false;
let textoBuscar="";
let infoContactosTotal = [];
let datosFinal = [];
let listasUnidas=false;
let tempArrCampos=[];
let textoInscribirJugNuevos="";


let infoEquipoO = [];
let infoEquipo = [];
function crearInfoEquipo(datos){    
    const nomFuncion="crearInfoEquipo";

    try{        
        let equipo = {
            idequipo:"",
            idsede:"",
            nombrelargo:"",
            nombrecorto:"",
            escudo:"",
            uniforme1:"",
            uniforme2:"",
            fotos:"",
            activo:"",
            indice:""
        }
    
        infoEquipoO = [];
        infoEquipo = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , equipo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idequipo=datos[i][j];
                if(j===1) registro.idsede=datos[i][j];
                if(j===2) registro.nombrelargo=datos[i][j];
                if(j===3) registro.nombrecorto=datos[i][j];
                if(j===4) registro.escudo=datos[i][j];
                if(j===5) registro.uniforme1=datos[i][j];
                if(j===6) registro.uniforme2=datos[i][j];
                if(j===7) registro.fotos=datos[i][j];
                if(j===8) registro.activo=datos[i][j];
                if(j===9) registro.indice=datos[i][j];

            }
            infoEquipoO.push(registro);
            infoEquipo.push(datos[i]);            

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoTorneoO = [];
let infoTorneo = [];
function crearInfoTorneo(datos){    
    const nomFuncion="crearInfoTorneo";

    try{        
        let torneo = {
            idtorneo:"",
            escudo:"",
            nombre:"",
            activo:"",
            sinInfo1:"",
            maxJugadoresEq:"",
            preferencias:"",
            horario:"",
            fecha_maxreg:"",
            actualDate:"",
            tiempoTotalPartido:"",
            nombreEquiposTorneo:"",
            tienePrefHorario:"",
            incluirOpciones:"",
            textoTC:"",
            puntosBarra:"",
            permiteSinTelefono:"",
            fecha_max_equipo:""
        }
    
        infoTorneoO = [];
        infoTorneo = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , torneo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.escudo=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.activo=datos[i][j];
                if(j===4) registro.sinInfo1=datos[i][j];
                if(j===5) registro.maxJugadoresEq=datos[i][j];
                if(j===6) registro.preferencias=datos[i][j];
                if(j===7) registro.horario=datos[i][j];
                if(j===8) registro.fecha_maxreg=datos[i][j];
                if(j===9) registro.actualDate=datos[i][j];
                if(j===10) registro.tiempoTotalPartido=datos[i][j];
                if(j===11) registro.nombreEquiposTorneo=datos[i][j];
                if(j===12) registro.tienePrefHorario=datos[i][j];
                if(j===13) registro.incluirOpciones=datos[i][j];
                if(j===14) registro.textoTC=datos[i][j];
                if(j===15) registro.puntosBarra=datos[i][j];
                if(j===16) registro.permiteSinTelefono=datos[i][j];
                if(j===17) registro.fecha_max_equipo=datos[i][j];
            }
            infoTorneoO.push(registro);
            infoTorneo.push(datos[i]);

            if(i===0){
                fechaActual=registro.actualDate;
                fechaMaxRegistro=registro.fecha_maxreg;
                fechaMaxEquipo=registro.fecha_max_equipo;
                if(fechaMaxEquipo==="1900-01-01") fechaMaxEquipo="";

                maxJugadores= valorNumero(registro.maxJugadoresEq);
                tiempoTotalPartido=registro.tiempoTotalPartido;
                permiteSinTelefono=false;
                if(registro.permiteSinTelefono==="1") permiteSinTelefono=true;
                
                definirHorarioPreferencia=false;
                if(registro.tienePrefHorario==="1" || tipoUser==="administrador") definirHorarioPreferencia=true;

                incluir_opciones=false;
                if(registro.incluirOpciones==="1" && tipoUser==="administrador") incluir_opciones=true;
            }

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoJugadoresO = [];
let infoJugadores = [];
function crearInfoJugadores(datos){    
    const nomFuncion="crearInfoJugadores";

    try{        
        let jugador = {
            indice:0,
            idjugador:"",
            foto:"",
            nomPosicion:"",
            numero:"",
            activo:"",
            nombre:"",
            tieneEstadisticasCobros:"",
            esadmin:"",
            telefono:"",
            registrado:"",
            idposicion:""
        }
    
        infoJugadoresO = [];
        infoJugadores = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            registro.indice=i;
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.foto=datos[i][j];
                if(j===2) registro.nomPosicion=datos[i][j];
                if(j===3) registro.numero=datos[i][j];
                if(j===4) registro.activo=datos[i][j];
                if(j===5) registro.nombre=datos[i][j];
                if(j===6) registro.tieneEstadisticasCobros=datos[i][j];
                if(j===7) registro.esadmin=datos[i][j];
                if(j===8) registro.telefono=datos[i][j];
                if(j===9) registro.registrado=datos[i][j];
                if(j===10) registro.idposicion=datos[i][j];
            }
            infoJugadoresO.push(registro);
            infoJugadores.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let jugadoresSedeO = [];
let jugadoresSede = [];
function crearInfoJugadoresSede(datos){    
    const nomFuncion="crearInfoJugadoresSede";

    try{        
        let jugador = {
            idjugador:"",
            foto:"",
            nombre:"",
            telefono:"",
            registrado:"",
            pendiente:"",
            cantEquipos:""
        }
    
        jugadoresSedeO = [];
        jugadoresSede = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.foto=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.telefono=datos[i][j];
                if(j===4) registro.registrado=datos[i][j];
                if(j===5) registro.pendiente=datos[i][j];
                if(j===6) registro.cantEquipos=datos[i][j];
            }
            jugadoresSedeO.push(registro);
            jugadoresSede.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}

let jugadoresTorneoO = [];
let jugadoresTorneo = [];
function crearInfoJugadoresTorneo(datos){    
    const nomFuncion="crearInfoJugadoresTorneo";

    try{        
        let jugador = {
            idjugador:"",
            telefono:"",
            nombre:"",
            nomEquipo:""
        }
    
        jugadoresTorneoO = [];
        jugadoresTorneo = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.telefono=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.nomEquipo=datos[i][j];
            }
            jugadoresTorneoO.push(registro);
            jugadoresTorneo.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


const Info_Equipo = ({params="", definirModulo, modOrigen=0, paramOrigen=""}) => {
    const [esError, setEsError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);
    const [msjModal, setMsjModal] = useState("");

    const [showParametros, setShowParametros] = useState(true);
    const [showOpciones, setShowOpciones] = useState(true);

    const [isOpenUniformes, openUniformes, closeUniformes] = useModal(false);
    const [isOpenPreferencias, openPreferencias, closePreferencias] = useModal(false);
    const [isOpenSelHora, openSelHora, closeSelHora] = useModal(false);
    const [isOpenMenu, openMenu, closeMenu] = useModal(false);
    const [isOpenCambioPC, openCambioPC, closeCambioPC] = useModal(false);
    const [isOpenBusqueda, openBusqueda, closeBusqueda] = useModal(false);
    const [isOpenImagen, openImagen, closeImagen] = useModal(false);

    const [puedeModificarJug, setPuedeModificarJug] = useState(false);
    const [puedeModificarEq, setPuedeModificarEq] = useState(false);
    const [arrCampos, setArrCampos] = useState(matrizCampos);
    const [datosMod, setDatosMod] = useState(datosInicial);
    const [datosEq, setDatosEq] = useState([]);
    const [datosJug, setDatosJug] = useState([]);
    const [datosTecnicos, setDatosTecnicos] = useState([]);
    const [datosEncontrados, setDatosEncontrados] = useState([]);

    const [parametros, setParametros] = useState([]);
    const [tipoLista, setTipoLista] = useState("Sin Borde");
    const [tipoUniforme, setTipoUniforme] = useState("Basicos");

    const [listaHorarioTorneo1, setListaHorarioTorneo1] = useState([]);
    const [listaHorarioTorneo2, setListaHorarioTorneo2] = useState([]);
    const [listaHorarioTorneo3, setListaHorarioTorneo3] = useState([]);
    const [selListaHorario1, setSelListaHorario1] = useState(0);
    const [selListaHorario2, setSelListaHorario2] = useState(0);
    const [selListaHorario3, setSelListaHorario3] = useState(0);
    
    const [textoHoraSel, setTextoHoraSel] = useState("Seleccione la hora...");
    const [horaSel, setHoraSel] = useState(0);
    const [AmPmSel, setAmPmSel] = useState(0);

    const [opcionesMenu, setOpcionesMenu] = useState([]);
    const [opcionSel, setOpcionSel] = useState(-1);

    const [infoPosicion, setInfoPosicion] = useState([]);
    const [posicionSel, setPosicionSel] = useState(0);
    const [nombreJug, setNombreJug] = useState("");

    const [showNewJug, setShowNewJug] = useState(false);
    const [nuevosJugadores, setNuevosJugadores] = useState([]);
    const [showAlertaSinTelefono, setShowAlertaSinTelefono] = useState(false);
    const [permiteSinTelefonoUS, setPermiteSinTelefonoUS] = useState(false);
    const [confirmoSinTelefonoUS, setConfirmoSinTelefonoUS] = useState(false);
    const [aceptoSinTelefonoUS, setAceptoSinTelefonoUS] = useState(false);
    const [hayNombresNoEditUS, setHayNombresNoEditUS] = useState(false);



    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{          

            datosSedeConsultados=false;

            let tempPosicion=[
                {value:"0", label:"SIN ASIGNAR", texto2:""},
                {value:"1", label:"ARQUERO", texto2:""},
                {value:"2", label:"DEFENSA", texto2:""},
                {value:"3", label:"VOLANTE", texto2:""},
                {value:"4", label:"DELANTERO", texto2:""},
                {value:"5", label:"CUERPO TECNICO", texto2:""}
            ];
            setInfoPosicion(tempPosicion);
            
            let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,SEP_4,5);
                let ultParametrosTotal=ultParametros;
                let datosParametrosGuardados=dividirLista(ultParametrosTotal,SEP_4,2);
                idTorneo=datosParametrosGuardados[0];
                idEquipo=datosParametrosGuardados[1];

                if(idTorneo==="" || idTorneo!==info[0]){
                    actualizar=true;
                    idTorneo=info[0];
                } 
                if(idEquipo==="" || idEquipo!==info[1]){
                    actualizar=true;
                    idEquipo=info[1];
                }

                paramsAjustado=idTorneo + SEP_4 + idEquipo;

                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                }
                
                guardarDatoLocal("ultParametros" + idModulo, idTorneo + SEP_4 + idEquipo);
                //actualizar=true;
                if(actualizar){
                    BD_solicitarDatos("INFO_INICIAL",[]);    
                }
                else{
                    cargarDatosGuardadosModulo();
                }
                
            }
    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [params]);


    if(params==="") return null;

    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleEquipo";
                    consulta="INFO_DETALLE_ADMIN_EQUIPO";
                    
                    paramQuery= idEquipo + SEP + idTorneo;
                } else if(tipoDatos==="ACTUALIZAR_CAMPOS_EQUIPO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarEquipo";
                    consulta="ACTUALIZAR_CAMPOS_EQUIPO";
                    mostrarProcesando=false;

                    let txtParejasUdt="";
                    let parejas=params.split(SEP_D_1);
                    for(let i=0;i<parejas.length;i++){
                        let columnas=parejas[i].split(SEP_3);
                        let valor="";
                        if(columnas.length===2) valor=columnas[1].trim();

                        if(valor==="NULL"){
                            if(txtParejasUdt==="") txtParejasUdt=columnas[0] + "=NULL ";
                            else txtParejasUdt=txtParejasUdt + ", " + columnas[0] + "=NULL ";
                        }
                        else{
                            if(txtParejasUdt==="") txtParejasUdt=columnas[0] + "='" + valor + "' ";
                            else txtParejasUdt=txtParejasUdt + ", " + columnas[0] + "='" + valor + "' ";
                        }
                    }
                    
                    paramQuery= idEquipo + SEP + txtParejasUdt;
                } else if(tipoDatos==="ACTUALIZAR_PREFERENCIAS_HORARIO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarEquipo";
                    consulta="ACTUALIZAR_PREFERENCIAS_HORARIO";
                    mostrarProcesando=false;

                    let pref1=params[0];
                    let pref2=params[1];
                    let pref3=params[2];
                    paramQuery= idTorneo + SEP + idEquipo + SEP + pref1 + SEP + pref2 + SEP + pref3;
                } else if(tipoDatos==="CAMBIAR_PRIVILEGIO_ADMINEQUIPO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarEquipo";
                    consulta="CAMBIAR_PRIVILEGIO_ADMINEQUIPO";
                    mostrarProcesando=false;

                    let idJugModificado= infoJugadoresO[numJugadorSel].idjugador;
                    let nuevoValor=params;
                    paramQuery= idEquipo + SEP + idJugModificado + SEP + nuevoValor;
                } else if(tipoDatos==="CAMBIAR_ESTADO_JUGADOR"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarEquipo";
                    consulta="CAMBIAR_ESTADO_JUGADOR";
                    mostrarProcesando=false;

                    let idJugModificado= infoJugadoresO[numJugadorSel].idjugador;
                    let nuevoValor=params;
                    paramQuery= idEquipo + SEP + idJugModificado + SEP + nuevoValor;
                    
                } else if(tipoDatos==="ACTUALIZAR_POSICION_CAMISETA"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarJugador";
                    consulta="ACTUALIZAR_POSICION_CAMISETA";
                    mostrarProcesando=false;

                    let idJugModificado= infoJugadoresO[numJugadorSel].idjugador;
                    let posicion=params[0];
                    let camiseta=params[1]
                    paramQuery= idJugModificado + SEP + idEquipo + SEP + posicion + SEP + camiseta;
                } else if(tipoDatos==="ACTUALIZAR_RETIRO_JUGADOR"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarEquipo";
                    consulta="ACTUALIZAR_RETIRO_JUGADOR";
                    mostrarProcesando=false;

                    let idJugModificado= params;
                    paramQuery= idEquipo + SEP + idJugModificado;
                } else if(tipoDatos==="CANTIDADES_INFO_JUGADORES_SEDE"){
                    tipoConsulta="consultarDato";
                    funcion="getListasDetalleEquipo";
                    consulta="CANTIDADES_INFO_JUGADORES_SEDE";
                    mostrarProcesando=false;

                    let idSede= recuperarDatoLocal("idSede");
                    paramQuery= idSede + SEP + idTorneo;

                    //datosSedeConsultados=false;
                } else if(tipoDatos==="RECUPERAR_INFO_JUGADORES_SEDE"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleEquipo";
                    consulta="RECUPERAR_INFO_JUGADORES_SEDE";
                    mostrarProcesando=false;

                    let idSede= recuperarDatoLocal("idSede");
                    paramQuery= idSede + SEP + idTorneo;

                    datosSedeConsultados=false;
                } else if(tipoDatos==="INSCRIBIR_NUEVOS_JUGADORES"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarEquipo";
                    consulta="INSCRIBIR_NUEVOS_JUGADORES";

                    let idSede= recuperarDatoLocal("idSede");
                    
                    paramQuery= idSede + SEP + idEquipo + SEP + textoInscribirJugNuevos;
                } else if(tipoDatos==="GUARDAR_IMAGEN_SERVIDOR"){
                    tipoConsulta="ENVIAR_IMAGEN";
                    funcion="equipo";
                    consulta="";

                    let imagen=params.replace("data:image/jpeg;base64,","");
                    let id=idEquipo;
                    let indice=infoEquipoO[0].indice;

                    paramQuery=[imagen,id,indice];
                } else if(tipoDatos==="BORRAR_IMAGEN_SERVIDOR_EQUIPO"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarEquipo";
                    consulta="BORRAR_IMAGEN_SERVIDOR_EQUIPO";
                    mostrarProcesando=false;

                    paramQuery=idEquipo;
                }
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
    
                                guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                guardarDatoLocal("datos" + idModulo, respuesta.datos);
    
                                crearInfoEquipo(crearTabla(respuesta.datos,"1"));
                                crearInfoTorneo(crearTabla(respuesta.datos,"2"));
                                crearInfoJugadores(crearTabla(respuesta.datos,"3")); 
    
                                verificarIngresoJugadores();
                                generarDatosPresentar();
                            
                                registrarLogEventos(idModulo, "1", idEquipo,"");
                            } else if(tipoDatos==="ACTUALIZAR_CAMPOS_EQUIPO"){
                                
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
    
                                let llaves=params.split(SEP_D_1);
                                for(let i=0;i<llaves.length;i++){
                                    let columnas=llaves[i].split(SEP_3);
                                    if(columnas.length===2){
                                        if(columnas[0]==="nombrelargo") {
                                            infoEquipo[0][2]=columnas[1];
                                            infoEquipoO[0].nombrelargo=columnas[1];
                                            tempArreglo[0].valor=columnas[1];
                                            tempArreglo[0].ultValor=columnas[1];
                                            setFlagUpdate(true);
                                        }
                                        else if(columnas[0]==="nombrecorto")  {
                                            infoEquipo[0][3]=columnas[1];
                                            infoEquipoO[0].nombrecorto=columnas[1];
                                            tempArreglo[1].valor=columnas[1];
                                            tempArreglo[1].ultValor=columnas[1];
                                            setFlagUpdate(true);
                                        }
                                        else if(columnas[0]==="uniforme1")  {
                                            infoEquipo[0][5]=columnas[1];
                                            infoEquipoO[0].uniforme1=columnas[1];
                                            if(columnas[1]!=="") tempArreglo[2].valor=ruta + columnas[1];
                                            else tempArreglo[2].valor=ic_uniforme_claro;
                                            tempArreglo[2].ultValor=columnas[1];
                                        }
                                        else if(columnas[0]==="uniforme2")  {
                                            infoEquipo[0][6]=columnas[1];
                                            infoEquipoO[0].uniforme2=columnas[1];
                                            if(columnas[1]!=="") tempArreglo[3].valor=ruta + columnas[1];
                                            else tempArreglo[3].valor=ic_uniforme_claro;
                                            tempArreglo[3].ultValor=columnas[1];
                                        }
                                    }
                                }
    
                                setArrCampos(tempArreglo);                                                     
                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                reconstruirListasModulo();   
                            } else if(tipoDatos==="ACTUALIZAR_PREFERENCIAS_HORARIO"){
                                let preferencia=params[3];
                                infoTorneo[0][6]=preferencia;
                                infoTorneoO[0].preferencias=preferencia;
                                                                                    
                                titulo="";
                                mensaje="Preferencias de horario actualizadas con exito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                reconstruirListasModulo();   
                            } else if(tipoDatos==="CAMBIAR_PRIVILEGIO_ADMINEQUIPO"){
                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");

                                
                                reconstruirListasModulo();  
                            } else if(tipoDatos==="CAMBIAR_ESTADO_JUGADOR"){
                                titulo="";
                                mensaje="Información actualizadas con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                
                                reconstruirListasModulo();  
                            } else if(tipoDatos==="ACTUALIZAR_POSICION_CAMISETA"){
                                titulo="";
                                mensaje="Información actualizadas con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                
                                reconstruirListasModulo();  
                            } else if(tipoDatos==="ACTUALIZAR_RETIRO_JUGADOR"){
                                if(respuesta.datos==="-1"){
                                    titulo="NO RETIRABLE";
                                    mensaje="Jugador No puede ser retirado del equipo. Ya tiene partidos jugados o pagos realizados.";
                                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","MEDIA");

                                    cargarDatosGuardadosModulo();
                                }
                                else{
                                    titulo="";
                                    mensaje="Información actualizadas con éxito";
                                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");
                                    
                                    reconstruirListasModulo();
                                }
                                  
                            } else if(tipoDatos==="CANTIDADES_INFO_JUGADORES_SEDE"){
                                presentarLog("SOLICITA_CANTIDADES " + "DS"); 

                                let valActual=respuesta.datos;
                                let valGuardado=recuperarDatoLocal("cantidades" + idModulo + "DS");

                                if(valGuardado===valActual){
                                    cargarDatosGuardadosAdicionales("DS");
                                }
                                else BD_solicitarDatos("RECUPERAR_INFO_JUGADORES_SEDE");
                                
                            } else if(tipoDatos==="RECUPERAR_INFO_JUGADORES_SEDE"){
                                let tipo="DS";
                                presentarLog("SOLICITA_DATOS " + tipo); 

                                //guardarDatoLocal("ultAcceso" + idModulo + tipo,Date.now());
                                guardarDatoLocal("datos" + idModulo + tipo, respuesta.datos);

                                crearInfoJugadoresSede(crearTabla(respuesta.datos,"1"));
                                crearInfoJugadoresTorneo(crearTabla(respuesta.datos,"2"));

                                guardarDatoLocal("cantidades" + idModulo + tipo, jugadoresSedeO.length + SEP + jugadoresTorneoO.length);

                                datosProcesados=false;
                                listasUnidas=false;
                                datosSedeConsultados=true;

                                registrarLogEventos(idModulo, "9", idEquipo,"");
                            } else if(tipoDatos==="INSCRIBIR_NUEVOS_JUGADORES") {
                                setShowNewJug(false);
                                setNuevosJugadores([]);
                                setShowAlertaSinTelefono(false);
                                setPermiteSinTelefonoUS(false);
                                setConfirmoSinTelefonoUS(false);
                                setAceptoSinTelefonoUS(false);
                                setHayNombresNoEditUS(false);

                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");

                                BD_solicitarDatos("INFO_INICIAL");

                                registrarLogEventos(idModulo,"8",idEquipo,"0");
                            } else if(tipoDatos==="GUARDAR_IMAGEN_SERVIDOR"){
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                                tempArreglo[11].valor=params;
                                setArrCampos(tempArreglo);

                                let newIndice=valorNumero(infoEquipoO[0].indice) + 1;
                                infoEquipo[0][9]="" + newIndice;
                                infoEquipoO[0].indice="" + newIndice;

                                titulo="";
                                mensaje="Imágen actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA"); 

                                setFlagUpdate(true);
                                reconstruirListasModulo();   
                            } else if(tipoDatos==="BORRAR_IMAGEN_SERVIDOR_EQUIPO"){
                                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                                tempArreglo[11].valor="";
                                setArrCampos(tempArreglo);

                                infoEquipo[0][4]="";
                                infoEquipoO[0].escudo="";

                                titulo="";
                                mensaje="Imágen BORRADA con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA"); 

                                reconstruirListasModulo(); 
                                generarDatosPresentar();
                                setFlagUpdate(true);
                            }                                  
            
                        } else {
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                if(mostrarError) setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            }                       
                        }

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const cargarDatosGuardadosModulo = () => {
        let nomFuncion="cargarDatosGuardadosModulo"; 

        try{             
            presentarLog(nomClase + " - RECUPERA_DATOS"); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo);

            if(datosModulo!==""){
                crearInfoEquipo(crearTabla(datosModulo,"1"));
                crearInfoTorneo(crearTabla(datosModulo,"2"));
                crearInfoJugadores(crearTabla(datosModulo,"3"));     
                
                verificarIngresoJugadores();
                generarDatosPresentar();

            } 
            else BD_solicitarDatos("INFO_INICIAL",[]);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }

    const cargarDatosGuardadosAdicionales = (tipo) => {
        let nomFuncion="cargarDatosGuardadosAdicionales"; 

        try{             
            presentarLog("RECUPERA_DATOS " + tipo); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo + tipo);

            if(datosModulo!==""){
                crearInfoJugadoresSede(crearTabla(datosModulo,"1"));
                crearInfoJugadoresTorneo(crearTabla(datosModulo,"2"));   
                
                datosProcesados=false;
                listasUnidas=false;
                datosSedeConsultados=true;
            } 
            else BD_solicitarDatos("RECUPERAR_INFO_JUGADORES_SEDE");

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }

    const reconstruirListasModulo = () => {
        let nomFuncion="reconstruirListasModulo"; 

        try{ 
            
            let datosFinales=[];
            datosFinales=reconstruirDatosOrigen(datosFinales,infoEquipo,"1");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoTorneo,"2");
            datosFinales=reconstruirDatosOrigen(datosFinales,infoJugadores,"3");
            
            guardarDatoLocal("datos" + idModulo, datosFinales);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const verificarIngresoJugadores = () => {
        let nomFuncion="verificarIngresoJugadores";

        try{
            puedeIncluirJugadores=true;
            puedeModificarEquipo=true;
            fechaModificacionesActiva=true;
            jugadoresActivos=0;
            let i=0;



            setDatosEq(infoEquipoO[0]);
            setPermiteSinTelefonoUS(permiteSinTelefono);
            numNuevosJugadores=0;
            setShowNewJug(false);
            setNuevosJugadores([]);


            let datosTemp=JSON.parse(JSON.stringify(datosMod));         

            datosTemp.nomUsuario=recuperarDatoLocal("nomUsuario");
            datosTemp.nomEmpresa=recuperarDatoLocal("nomEmpresa");
            datosTemp.nomSede=recuperarDatoLocal("nomSede");
            datosTemp.fotoUsuario=recuperarDatoLocal("fotoUsuario");
            if(datosTemp.fotoUsuario!=="") datosTemp.fotoUsuario= ruta + datosTemp.fotoUsuario;
            else datosTemp.fotoUsuario=ic_jugadorsinimagen;

            datosTemp.nomTorneo=infoTorneoO[0].nombre;
            datosTemp.escudoTorneo=infoTorneoO[0].escudo;
            if(datosTemp.escudoTorneo!=="") datosTemp.escudoTorneo= ruta + datosTemp.escudoTorneo;
            else datosTemp.escudoTorneo=ic_torneo_gris_w;            


            if(infoJugadores.length>0){
                for(i=0;i<infoJugadores.length;i++){
                    if(infoJugadores[i][4]==="1" && infoJugadores[i][10]!=="5") jugadoresActivos++;
                }
            }

            if(tipoUser!=="administrador"){
                datosTemp.tituloVentana="Administración Equipo";
                datosTemp.rolUsuario="Admin de Equipo:";       
                
                

                if(fechaMaxRegistro!==""){
                    let comp= operacionFechas(3,fechaActual,fechaMaxRegistro);
                    if(comp==="2") {
                        fechaModificacionesActiva=false;
                        puedeIncluirJugadores=false;
                    }
                }

                if(fechaMaxEquipo!==""){
                    let comp=operacionFechas(3,fechaActual,fechaMaxEquipo);
                    if(comp==="2") puedeModificarEquipo=false;
                }

                if(maxJugadores<=jugadoresActivos && puedeIncluirJugadores){
                    puedeIncluirJugadores=false;
                }

            }
            else{
                datosTemp.tituloVentana="Mi Equipo";
                datosTemp.rolUsuario="Administrador:";                
            }

            setPuedeModificarJug(puedeIncluirJugadores);
            setPuedeModificarEq(puedeModificarEquipo);
            setDatosMod(datosTemp);


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const generarDatosPresentar = () => {
        let nomFuncion="generarDatosPresentar";

        try{

            //Información del Equipo
            let formularioTemp=JSON.parse(JSON.stringify(arrCampos)); 
            if(true){
                 
                formularioTemp[0].valor=infoEquipoO[0].nombrelargo;
                formularioTemp[0].ultValor=infoEquipoO[0].nombrelargo;
                if(formularioTemp[0].valor.length>=3) formularioTemp[0].error="";
                formularioTemp[1].valor=infoEquipoO[0].nombrecorto;
                formularioTemp[1].ultValor=infoEquipoO[0].nombrecorto;
                if(formularioTemp[1].valor.length>=3) formularioTemp[1].error="";
                formularioTemp[2].valor=infoEquipoO[0].uniforme1;
                formularioTemp[2].ultValor=infoEquipoO[0].uniforme1;
                formularioTemp[3].valor=infoEquipoO[0].uniforme2;
                formularioTemp[3].ultValor=infoEquipoO[0].uniforme2;
                if(formularioTemp[2].valor!=="") formularioTemp[2].valor= ruta + formularioTemp[2].valor;
                else formularioTemp[2].valor=ic_uniforme_claro;
                if(formularioTemp[3].valor!=="") formularioTemp[3].valor= ruta + formularioTemp[3].valor;
                else formularioTemp[3].valor=ic_uniforme_claro;

                formularioTemp[11].valor=infoEquipoO[0].escudo;
                formularioTemp[11].ultValor=infoEquipoO[0].escudo;
                if(formularioTemp[11].valor!=="") formularioTemp[11].valor= ruta + formularioTemp[11].valor;
                else formularioTemp[11].valor=ic_escudo_base_gris;
    
                
            } 

            //Datos Parametros de Configuración
            if(true){
                let t=infoTorneoO[0].preferencias.trim();
                t=t.replace("||","|");
                if(t!==""){
                    if(t.substring(0,1)==="|") t=t.substring(1);
                }

                if(t!==""){
                    if(t.substring(t.length-1)==="|") t=t.substring(0,t.length-1);
                }
                t=t.trim();

                let tempParams=[];
                let tempDatos=dividirLista("","|",5);
                tempDatos[0]="Preferencias de Horario de Partidos";
                tempDatos[1]="SIN definir";
                if(t!=="") tempDatos[1]=t;
                if(definirHorarioPreferencia) tempDatos[2]="1";
                else tempDatos[2]="0";
                tempDatos[3]="1";
                tempDatos[4]="GESTIONAR_PREFERENCIAS";
                tempParams.push(tempDatos);
    
                tempDatos=dividirLista("","|",5);
                tempDatos[0]="Fecha Límite Modificaciones";
                let tempFecha="";
                    if(fechaMaxEquipo!=="") tempFecha="Datos Equipo: " + formatearFecha(fechaMaxEquipo,9);
                    else tempFecha=tempFecha + "Datos Equipo: SIN Límite";
                    if(fechaMaxRegistro!=="") tempFecha=tempFecha + "\nPlantilla Jugadores: " + formatearFecha(fechaMaxRegistro,9);
                    else tempFecha=tempFecha + "\nPlantilla Jugadores: SIN Límite";
                tempDatos[1]=tempFecha;
                tempDatos[2]="";
                tempDatos[3]="";
                tempDatos[4]="";
                tempParams.push(tempDatos);
    
                tempDatos=dividirLista("","|",5);
                tempDatos[0]="Cantidad Máxima de Jugadores Activos";
                tempDatos[1]="" + maxJugadores;
                tempDatos[2]="";
                tempDatos[3]="";
                tempDatos[4]="";
                tempParams.push(tempDatos);
    
                tempDatos=dividirLista("","|",5);
                tempDatos[0]="Permite Inscribir Jugadores SIN Teléfono";
                tempDatos[1]="NO";
                if(permiteSinTelefono) tempDatos[1]="SI";
                tempDatos[2]="";
                tempDatos[3]="";
                tempDatos[4]="";
                tempParams.push(tempDatos);                
                
                setParametros(tempParams);
            }


            //Datos de Jugadores y cuerpo tecnico
            generarListaJugadores();


            //Datos de preferencias de horario
            if(true){
                let horarioT = infoTorneoO[0].horario;
                prefEquipo = infoTorneoO[0].preferencias;

                horarioTorneo= generarDiasSemana(horarioT);

                const lista ={
                    index:0,
                    value:"",
                    label:"",
                    texto2:"",
                    imagen:""
                  } 
    
                let tempListaHorarioTorneo=[];
                let contador=0;
                let i;
                for (i = 0; i < 7; i++) {
                    if (!(horarioTorneo[i][1]==="00:00" && horarioTorneo[i][2]==="00:00")) {
                        let txtHorario="De: " + convertirHora(horarioTorneo[i][1], "NORMAL")  + " a " + convertirHora(horarioTorneo[i][2], "NORMAL");
                        //tempListaHorarioTorneo.push(["" + i,DIAS_SEMANA[i], txtHorario]);
                        let registro=Object.assign({} , lista);
                        registro.index=contador;
                        registro.value="" + i;
                        registro.label=DIAS_SEMANA[i];
                        registro.texto2=txtHorario;
                        contador++;
    
                        tempListaHorarioTorneo.push(registro);
                    }
                }
    
                setListaHorarioTorneo1(tempListaHorarioTorneo);
                setListaHorarioTorneo2(tempListaHorarioTorneo);
                setListaHorarioTorneo3(tempListaHorarioTorneo);

                if(prefEquipo!=="") {
                    let listaHActuales = prefEquipo.split(SEP_1);
                    for (let j = 0; j < listaHActuales.length; j++) {                        
                        let temp = []
                        if(listaHActuales[j]!=="") temp = listaHActuales[j].split(" ");
                        if (temp.length > 0) {
                            let diaSel = "0";
                            let diaActual = 0;
                            if (temp[0]==="LU") diaSel = "0";
                            if (temp[0]==="MA") diaSel = "1";
                            if (temp[0]==="MI") diaSel = "2";
                            if (temp[0]==="JU") diaSel = "3";
                            if (temp[0]==="VI") diaSel = "4";
                            if (temp[0]==="SA") diaSel = "5";
                            if (temp[0]==="DO") diaSel = "6";
    
                            //let i;
                            for (i = 0; i < tempListaHorarioTorneo.length; i++) {
                                if(tempListaHorarioTorneo[i].value===diaSel){
                                    diaActual = i;
                                    i = tempListaHorarioTorneo.length;
                                }
                            }
    
                            let id = valorNumero(diaSel);
    
                            let horaMin = horarioTorneo[id][1];
                            let horaMax = horarioTorneo[id][2];
    
                            if (j === 0) {
                                diaSelLista1=diaSel;
                                numSelLista1=diaActual;
                                setSelListaHorario1(diaActual);
                                prefSeleccionada = 1;
                                if (validarHoraMinMax(convertirHora(temp[1], "NORMAL"), horaMin, horaMax)) {
                                    formularioTemp[6].valor=convertirHora(temp[1], "NORMAL");
                                }
                                cambiarHorarioLista(null,"pref1");
                            }
                            if (j === 1) {
                                diaSelLista2=diaSel;
                                numSelLista2=diaActual;
                                setSelListaHorario2(diaActual);
                                prefSeleccionada = 2;
                                if (validarHoraMinMax(convertirHora(temp[1], "NORMAL"), horaMin, horaMax)) {
                                    formularioTemp[7].valor=convertirHora(temp[1], "NORMAL");
                                }
                                cambiarHorarioLista(null,"pref2");
                            }
                            if (j === 2) {
                                diaSelLista3=diaSel;
                                numSelLista3=diaActual;
                                setSelListaHorario3(diaActual);
                                prefSeleccionada = 3;
                                if (validarHoraMinMax(convertirHora(temp[1], "NORMAL"), horaMin, horaMax)) {
                                    formularioTemp[8].valor=convertirHora(temp[1], "NORMAL");
                                }
                                cambiarHorarioLista(null,"pref3");
                            }
                        }
                    }
                }
    
            }
            
            setArrCampos(formularioTemp);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const generarListaJugadores = () => {
        let nomFuncion="generarListaJugadores"; 

        try{

            let jugadores=[];
            let tecnicos=[];
            let i;
            
            for(i=0;i<infoJugadoresO.length;i++){
                if(infoJugadoresO[i].idjugador!==""){
                    if(infoJugadoresO[i].idposicion==="5") tecnicos.push(infoJugadoresO[i]);
                    else jugadores.push(infoJugadoresO[i]);
                }                
            }

            setDatosJug(jugadores);
            setDatosTecnicos(tecnicos);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
        
    }

    const validarHoraMinMax = (horaSeleccionada, horaMin, horaMax) => {
        let nomFuncion="validarHoraMinMax";
    
        let resultado=true;
        try{

            if(horaSeleccionada!==""){
    
                let txtMensaje="";
    
                let horaSel=valorNumero(convertirHora(horaSeleccionada, "MILITAR"));
                let tempHoraMin=horaMin.split(":");
                let tempHoraMax= sumarMinToHoras(horaMax,(valorNumero(tiempoTotalPartido)*-1)).split(":");
                let horaMinima=valorNumero(tempHoraMin[0]);
                let minutosMinima=0;
                if(tempHoraMin.length>1) minutosMinima=valorNumero(tempHoraMin[1]);
                let horaMaxima=valorNumero(tempHoraMax[0]);
                let minutosMaxima=0;
                if(tempHoraMax.length>1) minutosMaxima=valorNumero(tempHoraMax[1]);
    
                if((minutosMinima<=40 && horaSel<horaMinima) || (minutosMinima>40 && horaSel<=horaMinima) ){
                    txtMensaje="Hora Seleccionada NO PUEDE SER MENOR a la hora de inicio del torneo en el dia Seleccionado.";
                    resultado=false;
                }
                else if(horaSel>horaMaxima ){
                    txtMensaje="Hora seleccionada más el tiempo requerido para partido SUPERA la hora de finalización del torneo en el día Seleccionado.";
                    resultado=false;
                }
    
                if(resultado){
                    let diaSel1= numSelLista1;
                    let diaSel2= numSelLista2;
                    let diaSel3= numSelLista3;

                    let esIgual=false;
                    let diaSel="";
                    if(prefSeleccionada===1){
                        diaSel=diaSel1;
                        if(diaSel===diaSel2 && horaSeleccionada===arrCampos[7].valor) esIgual=true;
                        if(diaSel===diaSel3 && horaSeleccionada===arrCampos[8].valor) esIgual=true;
                    }
                    if(prefSeleccionada===2){
                        diaSel=diaSel2;
                        if(diaSel===diaSel1 && horaSeleccionada===arrCampos[6].valor) esIgual=true;
                        if(diaSel===diaSel3 && horaSeleccionada===arrCampos[8].valor) esIgual=true;
                    }
                    if(prefSeleccionada===3){
                        diaSel=diaSel3;
                        if(diaSel===diaSel1 && horaSeleccionada===arrCampos[6].valor) esIgual=true;
                        if(diaSel===diaSel2 && horaSeleccionada===arrCampos[7].valor) esIgual=true;
                    }

                    if(esIgual){
                        txtMensaje="Dia y Hora Seleccionada YA ESTA definida en sus preferencias de horario.";
                        resultado=false;
                    }
                }

                if(resultado) setMsjModal("");
                else setMsjModal(txtMensaje);


            }
    
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }

        return resultado;
    
    }

    const validarCampo = (e, campo, maxlen) => {
        let nomFuncion="validarCampo"; 

        try{
            let newValor = e.target.value;
            if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);

            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

            if(campo==="NOMBRE_LARGO"){  
                tempArreglo[0].error=matrizCampos[0].error;
                
                tempArreglo[0].valor=newValor; 
                if(newValor.length>=3) tempArreglo[0].error="";   
                
                setArrCampos(tempArreglo);  
            } else if(campo==="NOMBRE_CORTO"){  
                tempArreglo[1].error=matrizCampos[1].error;
                
                tempArreglo[1].valor=newValor; 
                    if(newValor.length>=3) tempArreglo[1].error="";   
                
                setArrCampos(tempArreglo);  
            } else if(campo==="TEXTO_RESALTAR"){
                tempArreglo[4].error="";
                tempArreglo[4].valor=newValor; 
                setArrCampos(tempArreglo);  
            } else if(campo==="TEXTO_PUNTOS_BARRA"){
                tempArreglo[5].error="";
                if(newValor==="") tempArreglo[5].valor=newValor;                
                else{
                    if(esformatoNumero(newValor))  tempArreglo[5].valor=newValor;                    
                }   
                setArrCampos(tempArreglo); 
            } else if(campo==="MD2_CAMISETA"){
                
                tempArreglo[9].valor=newValor;
                if(esformatoNumero(newValor) && valorNumero(newValor)>0) {
                    let valCamiseta=validarCamiseta(newValor);
                    if(valCamiseta==="") tempArreglo[9].error="";    
                    else tempArreglo[9].error=valCamiseta;
                }  
                else{
                    tempArreglo[9].error=matrizCampos[9].error;
                }
                setArrCampos(tempArreglo); 
            } else if(campo==="FILTRO_BUSQUEDA_TELEFONO"){
                if(newValor==="" || esformatoNumero(newValor)) {
                    tempArreglo[10].valor=newValor;
                    if(newValor.length>8 && newValor.length<10) tempArreglo[10].error="Ingrese un teléfono de MÍNIMO 10 números para ingresar el Jugador Nuevo.";
                    else if(newValor.length>=10 && newValor.length<15) tempArreglo[10].error="";
                    
                    setArrCampos(tempArreglo);  
                    tempArrCampos=tempArreglo;
                    textoBuscar=newValor;
                }
                
                cancelarBusqueda=true;
                buscarTexto();
            } else if(campo==="FILTRO_BUSQUEDA_NOMBRE"){
                tempArreglo[10].error="";                
                tempArreglo[10].valor=newValor; 
                let tempDatosEncontrados=[];

                if(newValor.length>=3) {
                    tempArreglo[10].error="";  
                    let datoFinal=formatearTexto(newValor,2);
                    datoFinal=quitarCharEspeciales(datoFinal);
                    let tempJugador = {
                        idjugador:"",
                        foto:"",
                        nombre:datoFinal,
                        telefono:"SIN NÚMERO",
                        registrado:"0",
                        pendiente:"3",
                        cantEquipos:""
                    }
                    tempDatosEncontrados.push(tempJugador);                    
                    
                }
                else tempArreglo[10].error="Ingrese el Nombre del Jugador que desea inscribir en el equipo. Debe ser un Texto de mínimo 3 caracteres."; 
                
                setDatosEncontrados(tempDatosEncontrados); 
                setArrCampos(tempArreglo); 
            }           
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
        
    }

    const clickGenerico = (donde, params=null) => {
        let nomFuncion="clickGenerico"; 

        try{
            
            let tempArreglo=null;
            
            switch(donde) {
                case "GESTIONAR_PREFERENCIAS":
                    if(params[2]==="1"){
                        abrirPreferenciasHorario();
                        openPreferencias();
                    }
                    else{
                        titulo="NO HABILITADAS";
                        mensaje="El organizador del Torneo, no tiene actualmente habilitadas la asignación de preferencias de horario para este torneo.";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","MEDIA");
                    }                    
                     
                    break;  
                case "CLICK_PARAMETROS":
                    setShowParametros(!showParametros);
                         
                    break;     
                case "CLICK_OPCIONES":
                    setShowOpciones(!showOpciones);
                             
                    break;    
                case "CLICK_MODIFCAR_ESCUDO":
                    if(!puedeModificarEquipo){
                        titulo="NO MODIFICABLE";
                        mensaje="Fecha límite de modificaciones a datos del equipo (Escudo, nombres) superada. Contacte al organizador del torneo";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                    }
                    else{
                        openImagen();
                    }
                    break;
                case "CLICK_MODIFCAR_EQUIPO":
                    if(!puedeModificarEquipo){
                        titulo="NO MODIFICABLE";
                        mensaje="Fecha límite de modificaciones a datos del equipo (Escudo, nombres) superada. Contacte al organizador del torneo";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO");
                    }
                    
                    break;
                case "SALE_NOMBRE_LARGO":
                    if(arrCampos[0].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[0].valor);
                        nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="nombrelargo" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[0].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_EQUIPO", txtActualizar);
                    }
                    break;
                case "SALE_NOMBRE_CORTO":
                    if(arrCampos[1].error===""){
                        let nuevoDato=quitarCharEspeciales(arrCampos[1].valor);
                        nuevoDato=formatearTexto(nuevoDato,2);
                        let txtActualizar="nombrecorto" + SEP_3 + nuevoDato;
                        if(nuevoDato!==arrCampos[1].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_EQUIPO", txtActualizar);
                    }
                    break;
                case "CLICK_ABRIR_UNIFORME":
                    tipoUniformeSel=params;

                    openUniformes();
                    break;
                case "SELECCIONAR_UNIFORME":
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    let nuevoUniforme="";

                    if(tipoUniforme==="Basicos") nuevoUniforme=UNIFORMES_COLORES[params][1];
                    else nuevoUniforme=UNIFORMES_EQUIPOS[params][1];

                    if(tipoUniformeSel==="Principal"){
                        if(nuevoUniforme!=="")
                            tempArreglo[2].valor=ruta + nuevoUniforme;
                        else tempArreglo[2].valor=ic_uniforme_claro;
                        let txtActualizar="uniforme1" + SEP_3 + nuevoUniforme;
                        if(nuevoUniforme!==arrCampos[2].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_EQUIPO", txtActualizar);
                    }
                    if(tipoUniformeSel==="Alterno"){
                        if(nuevoUniforme!=="")
                            tempArreglo[3].valor=ruta + nuevoUniforme;
                        else tempArreglo[3].valor=ic_uniforme_claro;
                        let txtActualizar="uniforme2" + SEP_3 + nuevoUniforme;
                        if(nuevoUniforme!==arrCampos[3].ultValor) BD_solicitarDatos("ACTUALIZAR_CAMPOS_EQUIPO", txtActualizar);
                    }

                    closeUniformes();
                    setArrCampos(tempArreglo);                    
                    break;
                case "CLICK_ABRIR_SELHORA":
                    prefSeleccionada=params;
                    setMsjModal("");
                    setHoraSel(0);
                    setAmPmSel(0);
                    openSelHora();

                    break;
                case "CLICK_SELECCIONAR_HORA":
                    setHoraSel(params);
                    if(AmPmSel===0) {
                        setMsjModal("");
                        setTextoHoraSel("Seleccione AM o PM...");
                    }
                    else{
                        let tmpHora="";
                        if(params===12){
                            if(AmPmSel===1) tmpHora="0 AM";
                            else tmpHora="12 M";
                        }
                        else{
                            tmpHora=params + " " + (AmPmSel===1 ? "AM" : "PM");
                        }                        
                        
                        tempArreglo=JSON.parse(JSON.stringify(arrCampos));

                        let diaSel=0;
                        if(prefSeleccionada===1) diaSel=valorNumero(diaSelLista1);
                        if(prefSeleccionada===2) diaSel=valorNumero(diaSelLista2);
                        if(prefSeleccionada===3) diaSel=valorNumero(diaSelLista3);

                        let horaMin = horarioTorneo[diaSel][1];
                        let horaMax = horarioTorneo[diaSel][2];

                        if(validarHoraMinMax(tmpHora,horaMin, horaMax)) {
                            if(prefSeleccionada===1) tempArreglo[6].valor=tmpHora;
                            if(prefSeleccionada===2) tempArreglo[7].valor=tmpHora;
                            if(prefSeleccionada===3) tempArreglo[8].valor=tmpHora;

                            setArrCampos(tempArreglo);

                            closeSelHora();
                        }
                        else{
                            setHoraSel(0);
                            setAmPmSel(0);
                            setTextoHoraSel("Seleccione la hora...");
                        }
                    }

                    break;
                case "CLICK_SELECCIONAR_AMPM":
                    setAmPmSel(params);
                    if(horaSel===0) {
                        setMsjModal("");
                        setTextoHoraSel("Seleccione la hora...");
                    }
                    else{
                        let tmpHora="";
                        if(horaSel===12){
                            if(params===1) tmpHora="0 AM";
                            else tmpHora="12 M";
                        }
                        else{
                            tmpHora=horaSel + " " + (params===1 ? "AM" : "PM");
                        }                        
                        
                        tempArreglo=JSON.parse(JSON.stringify(arrCampos));

                        let diaSel=0;
                        if(prefSeleccionada===1) diaSel=valorNumero(diaSelLista1);
                        if(prefSeleccionada===2) diaSel=valorNumero(diaSelLista2);
                        if(prefSeleccionada===3) diaSel=valorNumero(diaSelLista3);

                        let horaMin = horarioTorneo[diaSel][1];
                        let horaMax = horarioTorneo[diaSel][2];

                        if(validarHoraMinMax(tmpHora,horaMin, horaMax)) {
                            if(prefSeleccionada===1) tempArreglo[6].valor=tmpHora;
                            if(prefSeleccionada===2) tempArreglo[7].valor=tmpHora;
                            if(prefSeleccionada===3) tempArreglo[8].valor=tmpHora;

                            setArrCampos(tempArreglo);
                            closeSelHora();
                        }
                        else{
                            setHoraSel(0);
                            setAmPmSel(0);
                            setTextoHoraSel("Seleccione la hora...");
                        }
                    }

                    break;
                case "BORRAR_HORA_ACTUAL":
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    if(prefSeleccionada===1) tempArreglo[6].valor="";
                    if(prefSeleccionada===2) tempArreglo[7].valor="";
                    if(prefSeleccionada===3) tempArreglo[8].valor="";

                    setArrCampos(tempArreglo);
                    closeSelHora();

                    break;
                case "ACEPTAR_PREFERENCIAS_HORARIO":
                    let  nomFuncion="clickGenerico.ACEPTAR_PREFERENCIAS_HORARIO";

                    try{
                        let tempCampos=JSON.parse(JSON.stringify(arrCampos)); 
                        let preferencia="";
                        let pref1="", pref2="", pref3="";
                        let hora=arrCampos[6].valor;
                        if(hora!==""){                            

                            let dia=listaHorarioTorneo1[numSelLista1].label.substring(0,2).toUpperCase() + " ";
                            let horaM=convertirHora(hora,"MILITAR");

                            let horaFin=sumarMinToHoras(horaM + ":00",valorNumero(tiempoTotalPartido));
                            let tempHora=horaFin.split(":");
                            let horaFinal="";
                            let iHora=valorNumero(tempHora[0]);
                            let iMin=valorNumero(tempHora[1]);
                            if(iMin>0){
                                iHora=iHora + 1;
                                if(iHora>24) iHora=24;
                            }
                            horaFinal="00" + iHora;
                            horaFinal=horaFinal.substring(horaFinal.length-2);

                            pref1=dia + horaM + "-" + horaFinal;                            
                        }

                        hora=arrCampos[7].valor;
                        if(hora!==""){

                            let dia=listaHorarioTorneo2[numSelLista2].label.substring(0,2).toUpperCase() + " ";
                            let horaM=convertirHora(hora,"MILITAR");

                            let horaFin=sumarMinToHoras(horaM + ":00",valorNumero(tiempoTotalPartido));
                            let tempHora=horaFin.split(":");
                            let horaFinal="";
                            let iHora=valorNumero(tempHora[0]);
                            let iMin=valorNumero(tempHora[1]);
                            if(iMin>0){
                                iHora=iHora + 1;
                                if(iHora>24) iHora=24;
                            }
                            horaFinal="00" + iHora;
                            horaFinal=horaFinal.substring(horaFinal.length-2);

                            if(pref1==="") {
                                pref1=dia + horaM + "-" + horaFinal;
                                tempCampos[6].valor=hora;
                                tempCampos[7].valor="";
                            }
                            else pref2=dia + horaM + "-" + horaFinal;

                        }

                        hora=arrCampos[8].valor;
                        if(hora!==""){

                            let dia=listaHorarioTorneo3[numSelLista3].label.substring(0,2).toUpperCase() + " ";
                            let horaM=convertirHora(hora,"MILITAR");

                            let horaFin=sumarMinToHoras(horaM + ":00",valorNumero(tiempoTotalPartido));
                            let tempHora=horaFin.split(":");
                            let horaFinal="";
                            let iHora=valorNumero(tempHora[0]);
                            let iMin=valorNumero(tempHora[1]);
                            if(iMin>0){
                                iHora=iHora + 1;
                                if(iHora>24) iHora=24;
                            }
                            horaFinal="00" + iHora;
                            horaFinal=horaFinal.substring(horaFinal.length-2);

                            if(pref1==="") {
                                pref1=dia + horaM + "-" + horaFinal;
                                tempCampos[6].valor=hora;
                                tempCampos[7].valor="";
                                tempCampos[8].valor="";
                            }
                            else if(pref2==="") {
                                pref2=dia + horaM + "-" + horaFinal;
                                tempCampos[7].valor=hora;
                                tempCampos[8].valor="";
                            }
                            else pref3=dia + horaM + "-" + horaFinal;

                            
                        }

                        if(pref1!==""){
                            preferencia=pref1;
                            if(pref2!==""){
                                preferencia=pref1 + "|" + pref2;
                                if(pref3!==""){
                                    preferencia=pref1 + "|" + pref2 + "|" + pref3;
                                }
                            }
                        }

                        if(prefEquipo===preferencia){
                            titulo="SIN MODIFICACIONES";
                            mensaje="Preferencias de horario no presentan modificaciones.";
                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA");
                        }
                        else{
                            let tempParametros=JSON.parse(JSON.stringify(parametros)); 
                            if(preferencia==="") tempParametros[0][1]="SIN Definir";
                            else tempParametros[0][1]=preferencia;

                            setArrCampos(tempCampos);
                            setParametros(tempParametros);
                            closePreferencias();

                            BD_solicitarDatos("ACTUALIZAR_PREFERENCIAS_HORARIO",[pref1,pref2,pref3, preferencia]);

                        }


                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 

                    break;
                case "CLICK_JUGADOR":

                    numJugadorSel=params;
                    let tempOpciones=[];
                    let txtOpcion="";
                    let valOpcion="";

                    if(tipoUser==="administrador"){
                        //NUEVO LISTADO DE OPCIONES
                        txtOpcion="Ver/Editar Información del Jugador";
                        valOpcion="EDITAR INFO JUGADOR";
                        tempOpciones.push([txtOpcion,valOpcion]);

                        txtOpcion="Cambiar datos de Juego (Posición y Camiseta)";
                        valOpcion="EDITAR DATOS DE JUEGO";
                        tempOpciones.push([txtOpcion,valOpcion]);

                        if(infoJugadoresO[numJugadorSel].esadmin==="1")
                            txtOpcion="YA NO SERÁ Administrador de Equipo";
                        else txtOpcion="ASIGNAR como Administrador de Equipo";
                        valOpcion="ASIGNAR/RETIRAR ADMIN EQUIPO";
                        tempOpciones.push([txtOpcion,valOpcion]);


                        if(infoJugadoresO[numJugadorSel].activo==="1")
                            txtOpcion="INACTIVAR Jugador";
                        else txtOpcion="ACTIVAR Jugador";
                        valOpcion="ACTIVAR/INACTIVAR JUGADOR";
                        tempOpciones.push([txtOpcion,valOpcion]);
                        

                        if(infoJugadoresO[numJugadorSel].tieneEstadisticasCobros==="0"){
                            txtOpcion="RETIRAR Jugador del Equipo";
                            valOpcion="RETIRAR JUGADOR";
                            tempOpciones.push([txtOpcion,valOpcion]);
                        }

                        if(infoJugadoresO[numJugadorSel].telefono.length<15){
                            txtOpcion="Enviar Mensaje (WhatsApp)";
                            valOpcion="ABRIR WHATSAPP";
                            tempOpciones.push([txtOpcion,valOpcion]);
                        }
                        
                    }
                    else{
                        //NUEVO LISTADO DE OPCIONES
                        txtOpcion="Ver/Editar Información del Jugador";
                        valOpcion="EDITAR INFO JUGADOR";
                        tempOpciones.push([txtOpcion,valOpcion]);
                
                        txtOpcion="Cambiar datos de Juego (Posición y Camiseta)";
                        valOpcion="EDITAR DATOS DE JUEGO";
                        tempOpciones.push([txtOpcion,valOpcion]);

                        if(fechaModificacionesActiva && infoJugadoresO[numJugadorSel].esadmin==="0"){
                            if(infoJugadoresO[numJugadorSel].activo==="1")
                                txtOpcion="INACTIVAR Jugador";
                            else txtOpcion="ACTIVAR Jugador";
                            valOpcion="ACTIVAR/INACTIVAR JUGADOR";
                            tempOpciones.push([txtOpcion,valOpcion]);
                        }

                        if(fechaModificacionesActiva && infoJugadoresO[numJugadorSel].tieneEstadisticasCobros==="0" && infoJugadoresO[numJugadorSel].esadmin==="0"){
                            txtOpcion="RETIRAR Jugador del Equipo";
                            valOpcion="RETIRAR JUGADOR";
                            tempOpciones.push([txtOpcion,valOpcion]);
                        }

                        if(infoJugadoresO[numJugadorSel].telefono.length<15){
                            txtOpcion="Enviar Mensaje (WhatsApp)";
                            valOpcion="ABRIR WHATSAPP";
                            tempOpciones.push([txtOpcion,valOpcion]);
                        }

                    }

                    if(tempOpciones.length>0){
                        setOpcionesMenu(tempOpciones);
                        setOpcionSel(-1);
                        openMenu();
                    }

                    break;
                case "ACEPTAR_MENU":
                    if(opcionesMenu.length>0){
                        if(opcionSel>=0){
                            
                            switch(opcionesMenu[opcionSel][1]) {
                                case "EDITAR INFO JUGADOR":
                                    let idJug=infoJugadoresO[numJugadorSel].idjugador;
                                    definirModulo(0,idModulo, paramsAjustado,ID_MODULO_ADMIN_JUGADORES,idJug + SEP_4 + puedeIncluirJugadores);

                                    break;
                                case "EDITAR DATOS DE JUEGO":
                                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                                    let posicion=valorNumero(infoJugadoresO[numJugadorSel].idposicion);
                                    
                                    let camiseta=valorNumero(infoJugadoresO[numJugadorSel].numero);
                                    if(camiseta>0 && camiseta<=999){
                                        tempArreglo[9].valor="" + camiseta;
                                        tempArreglo[9].error="";
                                    }
                                    else tempArreglo[9].valor="";

                                    setNombreJug(infoJugadoresO[numJugadorSel].nombre);
                                    setPosicionSel(posicion);
                                    setArrCampos(tempArreglo);
                                    
                                    openCambioPC();
        
                                    break;
                                case "ASIGNAR/RETIRAR ADMIN EQUIPO":
                                    let nuevoAdmin="";
                                    if(infoJugadoresO[numJugadorSel].esadmin==="1"){
                                        nuevoAdmin="0";
                                        infoJugadores[numJugadorSel][7]="0";
                                        infoJugadoresO[numJugadorSel].esadmin="0";                                        
                                    }
                                    else{
                                        nuevoAdmin="1";
                                        infoJugadores[numJugadorSel][7]="1";
                                        infoJugadoresO[numJugadorSel].esadmin="1";   
                                        infoJugadores[numJugadorSel][4]="1";
                                        infoJugadoresO[numJugadorSel].activo="1";   
                                    }

                                    closeMenu();
                                    verificarIngresoJugadores();
                                    generarListaJugadores();
                                    BD_solicitarDatos("CAMBIAR_PRIVILEGIO_ADMINEQUIPO",nuevoAdmin);
                                    

                                    break;
                                case "ACTIVAR/INACTIVAR JUGADOR":
                                    let nuevoActivo="";
                                    if(infoJugadoresO[numJugadorSel].activo==="1") nuevoActivo="0";
                                    else nuevoActivo="1";

                                    if(fechaModificacionesActiva){
                                        if(tipoUser!=="administrador" && ((maxJugadores<=jugadoresActivos && nuevoActivo==="1") && infoJugadoresO[numJugadorSel].idposicion!=="5")){
                                            titulo="LÍMITE SUPERADO";
                                            mensaje="Máximo número de jugadores activos permitidos: " + maxJugadores;
                                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","BAJA");
                                        }
                                        else{
                                            if(infoJugadoresO[numJugadorSel].activo==="1"){
                                                nuevoActivo="0";
                                                infoJugadores[numJugadorSel][7]="0";
                                                infoJugadores[numJugadorSel][4]="0";
                                                infoJugadoresO[numJugadorSel].esadmin="0";   
                                                infoJugadoresO[numJugadorSel].activo="0";                                        
                                            }
                                            else{
                                                nuevoActivo="1";  
                                                infoJugadores[numJugadorSel][4]="1";
                                                infoJugadoresO[numJugadorSel].activo="1";   
                                            }
        
                                            closeMenu();
                                            verificarIngresoJugadores();
                                            generarListaJugadores();
                                            BD_solicitarDatos("CAMBIAR_ESTADO_JUGADOR",nuevoActivo);
                                        }
                                    }
                                    else{
                                        titulo="NO MODIFICABLE";
                                        mensaje="Fecha de Modificaciones superada. No puede Activar o Inactivar Jugadores. Consulte con Administrador del Torneo.";
                                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","BAJA");
                                        closeMenu();
                                    }    

                                    break;
                                case "RETIRAR JUGADOR":
                                    
                                    if(fechaModificacionesActiva){
                                        if(infoJugadoresO[numJugadorSel].tieneEstadisticasCobros==="0"){
                                            let msg="Esta acción retirará este Jugador del Equipo. ";
                                            if(puedeIncluirJugadores) msg=msg + "\n\nPodrá inscribirlo nuevamente por la opción de registro de jugadores hasta la fecha permitida de acuerdo a las reglas del torneo.";
                                            else msg=msg + "\n\nEl torneo actuamente no permite modificaciones, por lo que no podrá volver a registrar a este Jugador para el equipo en este Torneo.";
                                            msg=msg + "\n\nConfirma el retiro y continua el proceso?";

                                            titulo="CONFIRMAR RETIRO";
                                            mensaje=msg;
                                            ventanaMensaje=getConfirmacion(titulo,mensaje,setShowMensaje,null,clickGenerico,"ACEPTAR_CONFIRMACION_RETIRO");
                                        }
                                        else{
                                            titulo="NO RETIRABLE";
                                            let txtMsg="";
                                            if(fechaModificacionesActiva) txtMsg=" Puede utilizar la opción de Inactivar el Jugador.";
                                            mensaje="Jugador No puede ser retirado del equipo. Ya tiene partidos jugados." + txtMsg;
                                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","MEDIA");
                                        }
                                    }
                                    else{
                                        titulo="NO MODIFICABLE";
                                        mensaje="Fecha de Modificaciones superada. No puede Modificar jugadores. Consulte con Organizador del Torneo.";
                                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","MEDIA");
                                    }

                                    break;
                                case "ABRIR WHATSAPP":
                                    let indicativo=recuperarDatoLocal("indicativo");
                                    let telefono=infoJugadoresO[numJugadorSel].telefono.trim();

                                    if(esformatoNumero(indicativo) && esformatoNumero(telefono) && telefono.length<=14)
                                        openInNewTab("https://wa.me/" + indicativo + telefono);
                                    else{
                                        titulo="SIN INFORMACIÓN";
                                        mensaje="La información del telefono NO permite redireccionarlo a WhatsApp";
                                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA");
                                    }

                                    break;                                
                                default:
                                    break;
                            }

                            closeMenu();
                        }
                        else{
                            titulo="SIN SELECCION";
                            mensaje="Seleccione alguna de las opciones presentadas";
                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","BAJA");
                        }
                    }  
                    
                    break;
                case "CAMBIAR_POSICION":
                    let posOriginal=posicionSel;
                    let posFinal=valorNumero(params.value);
                    let cambiar=true;

                    titulo="NO MODIFICABLE";
                    if(tipoUser!=="administrador") {
                        if (posOriginal !== posFinal && posFinal === 5) {
                            if (!fechaModificacionesActiva) {
                                cambiar=false;                                
                                mensaje="Fecha de Modificaciones superada.\n\nSi cambia su posición a CUERPO TÉCNICO ya no podrá cambiarla a una posición de Jugador.\n\nEl CUERPO TÉCNICO no puede actuar como jugador en los partidos.";
                            }
                        } else if (posOriginal !== posFinal && posOriginal === 5) {
                            if(fechaModificacionesActiva){
                                if(maxJugadores<=(jugadoresActivos + numNuevosJugadores)){
                                    cambiar=false;
                                    mensaje="No es posible cambiar a posición de Jugador. Número máximo de Jugadores Activos alcanzado";
                                }
                            }
                            else{
                                cambiar=false;
                                mensaje="Fecha de Modificaciones superada. No puede Modificar los datos de registro de los jugadores. Consulte con Organizador del Torneo.";
                            }
                        }
                    }

                    if(cambiar) setPosicionSel(posFinal);
                    else ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","ALTA");

                    break;
                case "ACEPTAR_CAMBIAR_POSICION":

                    let numC=arrCampos[9].valor;
                    if(arrCampos[9].error===""){
                        if(infoJugadoresO[numJugadorSel].idposicion!==("" + posicionSel) || infoJugadoresO[numJugadorSel].numero!==numC){

                            infoJugadores[numJugadorSel][2]=infoPosicion[posicionSel].label;
                            infoJugadores[numJugadorSel][3]=numC;
                            infoJugadores[numJugadorSel][10]="" + posicionSel;

                            infoJugadoresO[numJugadorSel].nomPosicion=infoPosicion[posicionSel].label;
                            infoJugadoresO[numJugadorSel].numero=numC;
                            infoJugadoresO[numJugadorSel].idposicion="" + posicionSel;

                            generarListaJugadores();
                            closeCambioPC();

                            BD_solicitarDatos("ACTUALIZAR_POSICION_CAMISETA",[posicionSel,numC]);

                            verificarIngresoJugadores();
                        }
                        else{
                            titulo="SIN MODIFICACIONES";
                            mensaje="Datos de posición y camiseta NO presentan modificaciones";
                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","BAJA");
                        }
                    }

                    break;
                case "ACEPTAR_CONFIRMACION_RETIRO":
                    setShowMensaje(false);

                    let idJug=infoJugadoresO[numJugadorSel].idjugador;

                    infoJugadores[numJugadorSel][0]="";
                    infoJugadoresO[numJugadorSel].idjugador="";
                    infoJugadores[numJugadorSel][4]="0";
                    infoJugadoresO[numJugadorSel].activo="0";
                    infoJugadores[numJugadorSel][5]="";
                    infoJugadoresO[numJugadorSel].nombre="";
                    infoJugadores[numJugadorSel][8]="";
                    infoJugadoresO[numJugadorSel].telefono="";

                    
                    verificarIngresoJugadores();
                    generarListaJugadores();

                    BD_solicitarDatos("ACTUALIZAR_RETIRO_JUGADOR",idJug);

                    break;
                case "HABILITAR_INCLUIR_JUGADORES":
                    if(datosSedeConsultados===false){
                        if(!infoGuardadaEsMismoDia(idModulo,"DS")) BD_solicitarDatos("RECUPERAR_INFO_JUGADORES_SEDE");
                        else BD_solicitarDatos("CANTIDADES_INFO_JUGADORES_SEDE");
                    }                                     

                    tempArreglo=[];
                    tempArreglo.push(inicialNuevosJugadores);
                    
                    numNuevosJugadores=1;
                    setShowNewJug(true);
                    setNuevosJugadores(tempArreglo);

                    break;
                case "HABILITAR_CASILLAS_NUEVOS_JUGADORES":
                    if(tipoUser==="administrador" || (maxJugadores>(jugadoresActivos+numNuevosJugadores))){
                        tempArreglo=JSON.parse(JSON.stringify(nuevosJugadores));
                        tempArreglo.push(inicialNuevosJugadores);
                        
                        numNuevosJugadores++;
                        setShowNewJug(true);
                        setNuevosJugadores(tempArreglo);
                    }
                    else{
                        titulo="LÍMITE ALCANZADO";
                        mensaje="Maximo número de jugadores activos permitidos: " + maxJugadores + ", NO puede ingresar mas jugadores";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"INFO","MEDIA");
                    }

                    break;
                case "LIMPIAR_CASILLA_NUEVOS_JUGADORES":
                    let indice=params;
                    tempArreglo=JSON.parse(JSON.stringify(nuevosJugadores));
                    tempArreglo[indice]=inicialNuevosJugadores;
                    setNuevosJugadores(tempArreglo);

                    let hayNoModificables=false;
                    for(let a=0;a<tempArreglo.length;a++){
                        if(tempArreglo[a].habilitado===false) {
                            hayNoModificables=true;
                            a=tempArreglo.length;
                        }
                    }

                    setHayNombresNoEditUS(hayNoModificables);

                    break;
                case "VALIDAR_NOMBRE_NUEVO_JUGADOR": 
                    let newValor = params[0].target.value;
                    let numReg=params[1];
                    let maxlen=params[2];
                    if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);
                    tempArreglo=JSON.parse(JSON.stringify(nuevosJugadores));                    

                    let nombre=newValor;
                    let telefono=tempArreglo[numReg].telefono.trim();
                    tempArreglo[numReg].nombre=nombre;

                    if(tempArreglo[numReg].error===""){
                        if(telefono!=="" && (nombre.length<3 || esformatoNumero(nombre))){
                            tempArreglo[numReg].error="Nombre es un campo obligatorio";
                        }
                        else if(nombre.length>=3 && !esformatoNumero(nombre) && telefono===""){
                            tempArreglo[numReg].error="Teléfono es un campo obligatorio";
                        }
                    }    
                    else if(tempArreglo[numReg].error==="Nombre es un campo obligatorio" ||
                        tempArreglo[numReg].error==="Teléfono es un campo obligatorio"){
                        if(telefono==="" && nombre.length<3) tempArreglo[numReg].error="";
                        else if(telefono!=="" && nombre.length>=3 && !esformatoNumero(nombre)) tempArreglo[numReg].error="";
                    }              
    
                    setNuevosJugadores(tempArreglo);
                    break;
                case "ABRIR_BUSQUEDA_INSCRIBIR_JUGADORES":
                    numNuevoJugadorSel=params;
                    abrirVentanaBusqueda();

                    break;
                case "ACEPTO_ALERTA_SINTELEFONO":
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    tempArreglo[10].valor="";
                    tempArreglo[10].error="Ingrese el Nombre del Jugador que desea inscribir en el equipo. Debe ser un Texto de mínimo 3 caracteres.";
                    textoBuscar="";

                    aceptoSinTelefono=true;
                    setAceptoSinTelefonoUS(true);
                    setShowAlertaSinTelefono(false);
                    setArrCampos(tempArreglo);

                    break;
                case "CLICK_INCLUIR_SINTELEFONO":
                    tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                    tempArreglo[10].valor="";
                    tempArreglo[10].error="";
                    textoBuscar="";

                    aceptoSinTelefono=false;
                    setAceptoSinTelefonoUS(false);
                    //setConfirmoSinTelefonoUS(false);
                    
                    setArrCampos(tempArreglo);
                    setShowAlertaSinTelefono(true);
                    setDatosEncontrados([]);

                    break;
                case "CLICK_CANCELAR_SINTELEFONO":    
                        aceptoSinTelefono=false;
                        setAceptoSinTelefonoUS(false);
                        setConfirmoSinTelefonoUS(false);                        
                        setShowAlertaSinTelefono(false);
    
                        break;
                case "CLICK_INCLUIR_JUGADOR_BUSQUEDA":

                    tempArreglo=JSON.parse(JSON.stringify(nuevosJugadores));

                    tempArreglo[numNuevoJugadorSel].id=params.idjugador;
                    tempArreglo[numNuevoJugadorSel].telefono=params.telefono;
                    tempArreglo[numNuevoJugadorSel].nombre=params.nombre;

                    if(params.idjugador!=="") tempArreglo[numNuevoJugadorSel].habilitado=false;
                    else tempArreglo[numNuevoJugadorSel].habilitado=true;

                    let conError=false;
                    let telefonoSel=params.telefono;
                    let a=0;

                    if(esformatoNumero(telefonoSel)){
                        if(params.idjugador==="") tempArreglo[numNuevoJugadorSel].nombre="";
                        if(infoJugadores.length>0){
                            for(a=0;a<infoJugadores.length;a++){
                                if(telefonoSel===infoJugadores[a][8]){
                                    conError=true;
                                    tempArreglo[numNuevoJugadorSel].error="Teléfono repetido con Jugador del equipo: " + infoJugadores[a][5]  + " No. " + (a+1);    
                                    a=infoJugadores.length;
                                }
                            }
                        }

                        if(jugadoresTorneo.length>0 && !conError){
                            for(a=0;a<jugadoresTorneo.length;a++){
                                if(telefonoSel===jugadoresTorneo[a][1]){
                                    conError=true;
                                    tempArreglo[numNuevoJugadorSel].error="Teléfono de " + jugadoresTorneo[a][2] + " Ya esta registrado en el equipo " + jugadoresTorneo[a][3]
         
                                    a=jugadoresTorneo.length;
                                }
                            }
                        }
                    }
                    else {
                        tempArreglo[numNuevoJugadorSel].nombre=formatearTexto(quitarCharEspeciales(params.nombre),2);

                        if(esformatoNumero(tempArreglo[numNuevoJugadorSel].nombre)) {
                            conError=true;
                            tempArreglo[a].error="Nombre es un campo obligatorio";
                        }
                    }

                    if(!conError) tempArreglo[numNuevoJugadorSel].error="";     
                    
                    for(a=0;a<tempArreglo.length;a++){
                        if(tempArreglo[a].habilitado===false) {
                            setHayNombresNoEditUS(true);
                            a=tempArreglo.length;
                        }
                    }

                    setNuevosJugadores(tempArreglo);
                    closeBusqueda();                    

                    break;                
                case "CLICK_INSCRIBIR_NUEVOS_JUGADORES":
                    textoInscribirJugNuevos="";
                    let hayErrores=false;
                    let hayValidos=false;
                    tempArreglo=JSON.parse(JSON.stringify(nuevosJugadores));

                    for(let a=0;a<tempArreglo.length;a++){
                        let nombre=formatearTexto(quitarCharEspeciales(tempArreglo[a].nombre),2);
                        tempArreglo[a].nombre=nombre;
                        let telefono=tempArreglo[a].telefono.trim();
                        let id=tempArreglo[a].id;

                        if(telefono!=="" && (nombre.length<3 || esformatoNumero(nombre))){
                            tempArreglo[a].error="Nombre es un campo obligatorio";
                            hayErrores=true;
                        }
                        else if(nombre.length>=3 && !esformatoNumero(nombre)  && telefono===""){
                            tempArreglo[a].error="Teléfono es un campo obligatorio";
                            hayErrores=true;
                        }
                        else if(telefono!=="" && nombre.length>=3){
                            hayValidos=true;
                            if(tempArreglo[a].error!=="") {
                                hayErrores=true;
                            }
                        }
                    }

                    if(hayErrores){
                        setNuevosJugadores(tempArreglo);
                        titulo="CON ERRORES";
                        mensaje="Existen errores en los Jugadores Nuevos a inscribir en el equipo, corrija la información y continue el proceso.";
                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","MEDIA");
                    }
                    else{
                        if(!hayValidos){
                            titulo="SIN INFORMACIÓN";
                            mensaje="No hay jugadores validos en la lista para inscribirlos como parte del equipo.";
                            ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","MEDIA");
                        }
                        else{
                            let texto="";

                            for(let i=0;i<tempArreglo.length;i++){
                                if(tempArreglo[i].telefono!=="" && tempArreglo[i].nombre.length>=3 && tempArreglo[i].error===""){
                                    if(texto!=="") texto=texto + SEP_2;
                                    let nombreJ=tempArreglo[i].nombre.trim();
                                    let idJ=tempArreglo[i].id.trim();
                                    let telefonoJ=tempArreglo[i].telefono.trim();
                                    if(telefonoJ==="SIN NÚMERO") telefonoJ="";

                                    texto=texto + idJ + SEP_3 +
                                            telefonoJ + SEP_3 +
                                            nombreJ;

                                }
                            }

                            if(texto!==""){
                                textoInscribirJugNuevos=texto;
                                titulo="CONFIRMAR";
                                mensaje="Se tomarán los jugadores nuevos con información válida y se inscribirán en el equipo.\n\nConfirma realizar esta acción?";
                                ventanaMensaje=getConfirmacion(titulo,mensaje,setShowMensaje,null,clickGenerico,"CLICK_CONFIRMO_INCRIBIR_JUGADORES");  
                            }
                            else{
                                titulo="SIN INFORMACIÓN";
                                mensaje="No hay jugadores validos en la lista para inscribirlos como parte del equipo.";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"ALERTA","MEDIA"); 
                            }
                                               
                           
                        }                        
                    }

                    break;
                case "CLICK_CONFIRMO_INCRIBIR_JUGADORES":
                    setShowMensaje(false);
                    
                    BD_solicitarDatos("INSCRIBIR_NUEVOS_JUGADORES");


                    break;
                case "CERRAR_MODAL_IMAGEN":
                    closeImagen();
                    break;
                case "GUARDAR_IMAGEN_SERVIDOR":
                    closeImagen();
                    BD_solicitarDatos("GUARDAR_IMAGEN_SERVIDOR",params);                   
    
                    break;
                case "SOLICITAR_CONFIRMACION_BORRADO_IMAGEN":
                    titulo="CONFIRMAR BORRADO";
                    mensaje="Esta acción borrará la Imágen Actualmente cargada. Esta de acuerdo?";
                    ventanaMensaje=getConfirmacion(titulo,mensaje,setShowMensaje,null,clickGenerico,"ACEPTAR_BORRADO_IMAGEN");
    
                    break;
                case "ACEPTAR_BORRADO_IMAGEN":    
                    BD_solicitarDatos("BORRAR_IMAGEN_SERVIDOR_EQUIPO");
    
                    closeImagen();
                    break;
                default:
                    break;
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const abrirPreferenciasHorario = () => {
        let nomFuncion="abrirPreferenciasHorario"; 

        try{
            let tempLista1=JSON.parse(JSON.stringify(listaHorarioTorneo1)); 
            let formularioTemp=JSON.parse(JSON.stringify(arrCampos)); 

            diaSelLista1=valorNumero(tempLista1[0].value);
            numSelLista1=0;
            setSelListaHorario1(0);
            formularioTemp[6].valor="";

            diaSelLista2=valorNumero(tempLista1[0].value);
            numSelLista2=0;
            setSelListaHorario2(0);
            formularioTemp[7].valor="";

            diaSelLista3=valorNumero(tempLista1[0].value);
            numSelLista3=0;
            setSelListaHorario3(0);
            formularioTemp[8].valor="";


            prefEquipo = infoTorneoO[0].preferencias;

            if(prefEquipo!=="") {
                let listaHActuales = prefEquipo.split(SEP_1);
                for (let j = 0; j < listaHActuales.length; j++) {                        
                    let temp = []
                    if(listaHActuales[j]!=="") temp = listaHActuales[j].split(" ");
                    if (temp.length > 0) {
                        let diaSel = "0";
                        let diaActual = 0;
                        if (temp[0]==="LU") diaSel = "0";
                        if (temp[0]==="MA") diaSel = "1";
                        if (temp[0]==="MI") diaSel = "2";
                        if (temp[0]==="JU") diaSel = "3";
                        if (temp[0]==="VI") diaSel = "4";
                        if (temp[0]==="SA") diaSel = "5";
                        if (temp[0]==="DO") diaSel = "6";

                        let i;
                        for (i = 0; i < tempLista1.length; i++) {
                            if(tempLista1[i].value===diaSel){
                                diaActual = i;
                                i = tempLista1.length;
                            }
                        }

                        let id = valorNumero(diaSel);

                        let horaMin = horarioTorneo[id][1];
                        let horaMax = horarioTorneo[id][2];

                        if (j === 0) {
                            diaSelLista1=diaSel;
                            numSelLista1=diaActual;
                            setSelListaHorario1(diaActual);
                            prefSeleccionada = 1;
                            if (validarHoraMinMax(convertirHora(temp[1], "NORMAL"), horaMin, horaMax)) {
                                formularioTemp[6].valor=convertirHora(temp[1], "NORMAL");
                            } 
                        }
                        if (j === 1) {
                            diaSelLista2=diaSel;
                            numSelLista2=diaActual;
                            setSelListaHorario2(diaActual);
                            prefSeleccionada = 2;
                            if (validarHoraMinMax(convertirHora(temp[1], "NORMAL"), horaMin, horaMax)) {
                                formularioTemp[7].valor=convertirHora(temp[1], "NORMAL");
                            } 
                            
                        }
                        if (j === 2) {
                            diaSelLista3=diaSel;
                            numSelLista3=diaActual;
                            setSelListaHorario3(diaActual);
                            prefSeleccionada = 3;
                            if (validarHoraMinMax(convertirHora(temp[1], "NORMAL"), horaMin, horaMax)) {
                                formularioTemp[8].valor=convertirHora(temp[1], "NORMAL");
                            } 
                        }
                    }
                }
            }

            setArrCampos(formularioTemp);
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const cambiarTipoLista  = (selectedOption) => {
        setTipoLista(selectedOption.target.value);
    };

    const cambiarTipoUniforme  = (selectedOption) => {
        setTipoUniforme(selectedOption.target.value);
    };

    const cambiarHorarioLista = (selectedOption, lista) => {
        let nomFuncion="cambiarHorarioLista"; 

        try{
            
            let position=0;
            let valor="";

            if(selectedOption!==null) {
                position=selectedOption.index;
                valor=selectedOption.value;
                if(lista==="pref1") {
                    numSelLista1=position;
                    diaSelLista1=valor;
                }
                if(lista==="pref2") {
                    numSelLista2=position;
                    diaSelLista2=valor;
                }
                if(lista==="pref3") {
                    numSelLista3=position;
                    diaSelLista3=valor;
                }
                
            }
            else {
                if(lista==="pref1") {
                    position=numSelLista1;
                    valor=valorNumero(diaSelLista1);
                }
                if(lista==="pref2") {
                    position=numSelLista2;
                    valor=valorNumero(diaSelLista2);
                }
                if(lista==="pref3") {
                    position=numSelLista3;
                    valor=valorNumero(diaSelLista3);
                }
            }

            let id=valorNumero(valor);
            let horaActual="";
            let horaMin=horarioTorneo[id][1];
            let horaMax=horarioTorneo[id][2];

            if(lista==="pref1"){
                setSelListaHorario1(position);
                horaActual=arrCampos[6].valor;
            }  
            if(lista==="pref2"){
                setSelListaHorario2(position);
                horaActual=arrCampos[7].valor;
            }  
            if(lista==="pref3"){
                setSelListaHorario3(position);
                horaActual=arrCampos[8].valor;
            }       

            if(!validarHoraMinMax(horaActual, horaMin, horaMax) ){
                let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
                if(lista==="pref1") tempArreglo[6].valor="";
                if(lista==="pref2") tempArreglo[7].valor="";
                if(lista==="pref3") tempArreglo[8].valor="";

                setArrCampos(tempArreglo);
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const validarCamiseta = (numC) => {
        let nomFuncion="validarCamiseta";

        let resultado="";

        try{

            let i;
            for(i=0;i<infoJugadoresO.length;i++){
                if(numC===infoJugadoresO[i].numero && infoJugadoresO[i].idjugador!==infoJugadoresO[numJugadorSel].idjugador){
                    resultado="Número de camiseta ya existe, lo tiene " + infoJugadoresO[i].nombre + ".";                    
                    i=infoJugadoresO.length;
                }
            }
           


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  

        return resultado;
    }

    const abrirVentanaBusqueda = (numC) => {
        let nomFuncion="abrirVentanaBusqueda";

        try{
            textoBuscar="";
            let tempArreglo=JSON.parse(JSON.stringify(arrCampos));
            tempArreglo[10].valor="";
            tempArreglo[10].error="";
            setArrCampos(tempArreglo);

            cancelarBusqueda=false;

            aceptoSinTelefono=false;
            setAceptoSinTelefonoUS(false);
            setConfirmoSinTelefonoUS(false);            
            setShowAlertaSinTelefono(false);
            setDatosEncontrados([]);
            
            
            openBusqueda();
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    }

    const buscarTexto = () => {
        let nomFuncion="buscarTexto";

        try{
            cancelarBusqueda=false;
            let tipoLista="contacto";
            let tipoElemento=2;
            let regPagina=20;
            let mostrarJugadoresSede=true;
            let textoShow="";
            let tempDatosEncontrados=[];

            if(!isOpenBusqueda) return;


            if(textoBuscar!=="" && textoBuscar.length>8){
                
                let datos=[];
                let datosTI=false;

                let yaEstanContactos=[];
                let yaEstanMarcaGol=[];

                if(tipoUser==="administrador") mostrarJugadoresSede=true;


                if(listasUnidas && datosSedeConsultados){
                    datos=JSON.parse(JSON.stringify(datosFinal));
                }
                else{
                    if(datosSedeConsultados){
                        if(jugadoresSede.length>0 && mostrarJugadoresSede){
                            datos=JSON.parse(JSON.stringify(jugadoresSede));
                        }                   
                    
                        datosProcesados=false;
                        listasUnidas=true;
                    }                    
                }

                if(datos.length>0) datosTI=true;


                if(!datosProcesados && datosTI){
                    datosFinal=JSON.parse(JSON.stringify(datos));
                    //infoContactosTotal=JSON.parse(JSON.stringify(datosFinal));
                    datosProcesados=true;
                }

                let total=0;
                let texto="", textoBuscado="";
                
                textoBuscado=textoBuscar;
                textoBuscado=textoBuscado.replaceAll("+","");

                if(tipoLista==="contacto" && permiteSinTelefono && aceptoSinTelefono){

                    if (valorLong(textoBuscado) >0 || (valorLong(textoBuscado)===0 && textoBuscado.length < 4)) {
                        total = 1;
                        textoShow="Ingrese el Nombre del Jugador que desea inscribir en el equipo. Debe ser un Texto de mínimo 3 caracteres.";
                    }
                    else{
                        total++;
                        let datoFinal=formatearTexto(textoBuscado,2);

                        let tempJugador = {
                            idjugador:"",
                            foto:"",
                            nombre:"Nombre: " + datoFinal,
                            telefono:"Telefono a registrar: NINGUNO",
                            registrado:"1",
                            pendiente:"3",
                            cantEquipos:""
                        }
                        
                        tempDatosEncontrados.push(tempJugador);
                    }


                }
                else{
                    if(datosTI){
                        let i;
                        cancelarBusqueda=false;

                        for(i=0;i<datos.length;i++){
                            if(cancelarBusqueda) return;

                            if(!cancelarBusqueda){
                                let numregistro=i;

                                let busquedaEspecial=false;
                                let yaEsta=false;
                                let yaEstaPrincipal=false;
                                let yaEstaAuxiliar=false;

                                texto=datosFinal[i][3];                                

                                if(tipoLista==="contacto") {

                                    if(textoBuscado==="") busquedaEspecial=true;
                                    else{
                                        if(texto.includes(textoBuscado)) busquedaEspecial=true;
                                        /*else{
                                            texto=datos[i][3];
                                            if(texto.includes(textoBuscado)) busquedaEspecial=true;
                                        }*/
                                    }

                                    if(busquedaEspecial){
                                        for(let j=0;j<yaEstanContactos.length;j++){
                                            if(yaEstanContactos[j]===datos[i][3].trim()){
                                                yaEsta=true;
                                                busquedaEspecial=false;
                                                j=yaEstanContactos.length;
                                            }
                                        }
                                        
                                        if(!yaEsta){
                                            for(let j=0;j<yaEstanMarcaGol.length;j++){
                                                if(yaEstanMarcaGol[j]===datos[i][3].trim()){
                                                    yaEsta=true;
                                                    busquedaEspecial=false;
                                                    j=yaEstanMarcaGol.length;
                                                }
                                            }
                                        }

                                    }

                                }

                                if(busquedaEspecial || (!yaEsta && datos[i][4]==="1" && (textoBuscado==="" || texto.includes(textoBuscado)))){
                                    total++;

                                    if(tipoLista==="contacto") {
                                        if(datos[i][5]==="1") yaEstanMarcaGol.push(datos[i][3].trim());
                                        else yaEstanContactos.push(datos[i][3].trim());
                                    }

                                    tempDatosEncontrados.push(jugadoresSedeO[i]);

                                    if(total>=regPagina) i=datos.length;
                                }

                            }
                            else i=datos.length;

                        }

                    }
                }

                if(total==0) {
                    if (tipoLista==="contacto") {
                        if (textoBuscado.length >=10 && textoBuscado.length < 15){
                            total++;
                            let tempJugador = {
                                idjugador:"",
                                foto:"",
                                nombre:"Jugador MarcaGol Nuevo",
                                telefono: textoBuscado,
                                registrado:"1",
                                pendiente:"3",
                                cantEquipos:""
                            }
                            
                            tempDatosEncontrados.push(tempJugador);
                        }
                        
                    }

                }

                if(!cancelarBusqueda) setDatosEncontrados(tempDatosEncontrados);  

            }
            else setDatosEncontrados(tempDatosEncontrados);

             


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }

    const salir = () => {       
        definirModulo(0,idModulo,params,ID_MODULO_PRINCIPAL_JUGADOR,recuperarDatoLocal("idUser"));   
    }

    return (
        <>


            {isOpenUniformes && (
            <Modal isOpen={isOpenUniformes} closeModal={closeUniformes}  titulo={"Seleccionar Uniforme"}  txtAceptar="" txtCancelar="" isCancelable={true} >
                    <div style={{margin:"10px"}}>
                                <div style={{display: "flex", justifyContent: "space-around", alignItems:"center", fontSize:"14px", margin:"10px"}}>
                                        <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"center"}}>
                                            <input type="radio" value="Basicos" name="tipoUniforme" style={{marginInline: "10px"}} checked={tipoUniforme==="Basicos"} onChange={cambiarTipoUniforme}/>
                                                <span style={{color:C_uniformeGrisOscuro}}>Basicos</span>
                                            </label>
                                            <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"center"}}>
                                                <input type="radio" value="Equipos" name="tipoUniforme" style={{marginInline: "10px"}} checked={tipoUniforme==="Equipos"} onChange={cambiarTipoUniforme}/>
                                                <span style={{color:C_uniformeGrisOscuro}}>Equipos</span>
                                            </label>
                                        </div>

                    <div style={{display:"flex", flexWrap:"wrap", justifyContent:"center", minWidth:"200px", maxWidth:"500px"}}>
                        {tipoUniforme==="Basicos" && (<>
                            {UNIFORMES_COLORES.map((reg, index) => (
                                <div key={reg[1]} style={{marginBlock:"15px", marginInline:"10px", cursor:"pointer"}} onClick={() => clickGenerico("SELECCIONAR_UNIFORME",index)}>
                                    <img className="IM_camiseta65" src={reg[1]==="" ? (ic_uniforme_claro) : (ruta + reg[1]) } alt="" /><span>{reg[3]}</span>
                                </div>                        
                            ))} 
                        </>)}  
                        {tipoUniforme==="Equipos" && (<>
                            {UNIFORMES_EQUIPOS.map((reg, index) => (
                                <div key={reg[1]} style={{marginBlock:"15px", marginInline:"10px", cursor:"pointer"}} onClick={() => clickGenerico("SELECCIONAR_UNIFORME",index)}>
                                    <img className="IM_camiseta65" src={reg[1]==="" ? (ic_uniforme_claro) : (ruta + reg[1]) } alt="" /><span>{reg[3]}</span>
                                </div>                        
                            ))} 
                        </>)}                    
                    </div> 
                </div>              
                
            </Modal>
            )}
            
            {isOpenPreferencias && (
                <Modal isOpen={isOpenPreferencias} closeModal={closePreferencias} imgModal={ic_reloj}  titulo={"Definir Preferencias"}  isCancelable={false} clickAceptar={() => clickGenerico("ACEPTAR_PREFERENCIAS_HORARIO")} >
                <div style={{margin:"10px"}}>
                    <div className="CT_contenedorTitulo" style={{marginBottom:"20px"}}>  
                        <img className='IM_imgRedondeada28' src={datosMod.escudoTorneo} alt='' style={{marginInline:"10px"}}/>   
                        <div style={{flex:"1", textAlign:"left", whiteSpace: "pre-wrap"}}>
                            <div style={{fontSize:"14px", color:C_uniformeNegro}}>{datosMod.nomTorneo}</div>
                            <div style={{fontSize:"12px", color:C_uniformeGrisOscuro}}>{"Min x Partido: " + tiempoTotalPartido}</div>            
                        </div>            
                    </div>                               
                    
                    <div style={{fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"center", marginTop:"10px"}}>{"Defina hasta 3 horarios (Incluidos en el Torneo) en los que prefiere jugar sus partidos de este Torneo."}</div>
                    <div style={{fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"center", marginTop:"10px"}}>{"Esta información puede ser actualizada cada vez que requiera y podrá ser utilizada por el organizador del torneo para generar la programación de los partidos."}</div>
                    
                    <div style={{marginTop:"20px", maxWidth:"400px", fontSize:"14px"}}>
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", textAlign:"center"}}>
                            <div style={{flex:"1", fontWeight:"bold"}}>Dia</div><div style={{flex:"1"}}></div>
                            <div style={{flex:"1", fontWeight:"bold"}}>Hora Partido</div>
                        </div>
                        <div style={{color:C_colorPrimary, fontWeight:"bold", textAlign:"left"}}>Horario Ideal de Partidos</div>
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                            <div style={{flex:"2"}}>
                                {listaHorarioTorneo1.length>0 &&  (   
                                    <div style={{backgroundColor:"white", paddingBottom:"10px", paddingInline:"6px"}}>           
                                    <Select     
                                        styles={estilosSelect}
                                        value={listaHorarioTorneo1[selListaHorario1]}
                                        formatOptionLabel={listaIconoDosItems}
                                        options={listaHorarioTorneo1}
                                        isSearchable={false}   
                                        onChange={(e) => {cambiarHorarioLista(e, "pref1")}}            
                                    />   
                                    </div>                 
                                )}
                            </div>
                            <div style={{flex:"1"}}>
                                <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"60px", maxWidth: "60px", cursor:"pointer", display:"flex", alignItems:"center", justifyContent:"center"}}  onClick={() => clickGenerico("CLICK_ABRIR_SELHORA",1)}><span>{arrCampos[6].valor}</span></div>
                            </div>
                        </div>

                        <div style={{color: C_colorAccent , fontWeight:"bold", textAlign:"left"}}>Horario Alternativo 1</div>
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                            <div style={{flex:"2"}}>
                                {listaHorarioTorneo2.length>0 &&  (   
                                    <div style={{backgroundColor:"white", paddingBottom:"10px", paddingInline:"6px"}}>           
                                    <Select     
                                        styles={estilosSelect}
                                        value={listaHorarioTorneo2[selListaHorario2]}
                                        formatOptionLabel={listaIconoDosItems}
                                        options={listaHorarioTorneo2}
                                        isSearchable={false}   
                                        onChange={(e) => {cambiarHorarioLista(e, "pref2")}}            
                                    />   
                                    </div>                 
                                )}
                            </div>
                            <div style={{flex:"1"}}>
                                <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"60px", maxWidth: "60px", cursor:"pointer", display:"flex", alignItems:"center", justifyContent:"center"}}  onClick={() => clickGenerico("CLICK_ABRIR_SELHORA",2)}><span>{arrCampos[7].valor}</span></div>
                            </div>
                        </div>

                        <div style={{color: C_colorAccent , fontWeight:"bold", textAlign:"left"}}>Horario Alternativo 2</div>
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                            <div style={{flex:"2"}}>
                                {listaHorarioTorneo3.length>0 &&  (   
                                    <div style={{backgroundColor:"white", paddingBottom:"10px", paddingInline:"6px"}}>           
                                    <Select     
                                        styles={estilosSelect}
                                        value={listaHorarioTorneo3[selListaHorario3]}
                                        formatOptionLabel={listaIconoDosItems}
                                        options={listaHorarioTorneo3}
                                        isSearchable={false}   
                                        onChange={(e) => {cambiarHorarioLista(e, "pref3")}}            
                                    />   
                                    </div>                 
                                )}
                            </div>
                            <div style={{flex:"1"}}>
                                <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"60px", maxWidth: "60px", cursor:"pointer", display:"flex", alignItems:"center", justifyContent:"center"}}  onClick={() => clickGenerico("CLICK_ABRIR_SELHORA",3)}><span>{arrCampos[8].valor}</span></div>
                            </div>
                        </div>

                    </div>

                </div>              
                
                </Modal>
            )}
            
            {isOpenSelHora && (
                <Modal isOpen={isOpenSelHora} closeModal={closeSelHora} imgModal={ic_reloj}  titulo={"Definir Hora"}  txtAceptar="" txtCancelar=""  isCancelable={true} >
                <div style={{margin:"30px"}}>                    
                    <div style={{display:"flex", flexWrap:"nowrap", justifyContent:"center"}}>
                        <div style={{width:"50px", height:"50px"}}></div>
                        <div style={{display:"flex", alignItems:"end", width:"40px", height:"50px"}}><div className={`BF_circuloGris ${horaSel===11 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",11)}>11</div></div>
                        <div style={{display:"flex", alignItems:"start", justifyContent:"center", width:"60px", height:"50px"}}><div className={`BF_circuloGris ${horaSel===12 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",12)}>12</div></div>
                        <div style={{display:"flex", alignItems:"end", width:"40px", height:"50px"}}><div className={`BF_circuloGris ${horaSel===1 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",1)}>1</div></div>
                        <div style={{width:"50px", height:"50px"}}></div>
                    </div>

                    <div style={{display:"flex", flexWrap:"nowrap", justifyContent:"center"}}>                        
                        <div style={{display:"flex", justifyContent:"right", width:"50px", height:"40px"}}><div className={`BF_circuloGris ${horaSel===10 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",10)}>10</div></div>
                        <div style={{width:"40px", height:"40px"}}></div>
                        <div style={{display:"flex", alignItems:"end", justifyContent:"center", width:"50px", height:"40px", border:"0px solid red"}}><div className={`BF_botonAMPM ${AmPmSel===1 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_AMPM",1)}>AM</div></div>
                        <div style={{width:"40px", height:"40px"}}></div>
                        <div style={{display:"flex", justifyContent:"left", width:"50px", height:"40px"}}><div className={`BF_circuloGris ${horaSel===2 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",2)}>2</div></div>
                    </div>

                    <div style={{display:"flex", flexWrap:"nowrap", justifyContent:"center"}}>                        
                        <div style={{display:"flex", alignItems:"center", width:"40px", height:"60px"}}><div className={`BF_circuloGris ${horaSel===9 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",9)}>9</div></div>
                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"160px", height:"60px"}}><span className="TX_sinInfo" style={{fontSize:"14px"}}>{textoHoraSel}</span></div>
                        <div style={{display:"flex", alignItems:"center", width:"40px", height:"60px"}}><div className={`BF_circuloGris ${horaSel===3 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",3)}>3</div></div>
                    </div>

                    <div style={{display:"flex", flexWrap:"nowrap", justifyContent:"center"}}>                        
                        <div style={{display:"flex", justifyContent:"right", width:"50px", height:"40px"}}><div className={`BF_circuloGris ${horaSel===8 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",8)}>8</div></div>
                        <div style={{width:"40px", height:"40px"}}></div>
                        <div style={{display:"flex", alignItems:"start", justifyContent:"center", width:"50px", height:"40px"}}><div className={`BF_botonAMPM ${AmPmSel===2 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_AMPM",2)}>PM</div></div>
                        <div style={{width:"40px", height:"40px"}}></div>
                        <div style={{display:"flex", justifyContent:"left", width:"50px", height:"40px"}}><div className={`BF_circuloGris ${horaSel===4 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",4)}>4</div></div>
                    </div>

                    <div style={{display:"flex", flexWrap:"nowrap", justifyContent:"center"}}>
                        <div style={{width:"50px", height:"50px"}}></div>
                        <div style={{display:"flex", alignItems:"start", width:"40px", height:"50px"}}><div className={`BF_circuloGris ${horaSel===7 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",7)}>7</div></div>
                        <div style={{display:"flex", alignItems:"end", justifyContent:"center", width:"60px", height:"50px"}}><div className={`BF_circuloGris ${horaSel===6 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",6)}>6</div></div>
                        <div style={{display:"flex", alignItems:"start", width:"40px", height:"50px"}}><div className={`BF_circuloGris ${horaSel===5 && "selected"}`} onClick={() => clickGenerico("CLICK_SELECCIONAR_HORA",5)}>5</div></div>
                        <div style={{width:"50px", height:"50px"}}></div>
                    </div>

                    {msjModal!=="" ? 
                    (
                        <div style={{marginTop:"20px", marginBottom:"20px"}}>
                                <span className="TX_error">{msjModal}</span>
                        </div>
                    ) 
                    : 
                    (<div className="BF_botonBordeAccentFull" style={{marginTop:"30px"}} onClick={() => clickGenerico("BORRAR_HORA_ACTUAL",5)}>Borrar Hora Actual</div>)}
                    

                </div> 
                </Modal>
            )}
            
            {isOpenMenu && (
                <Modal isOpen={isOpenMenu} closeModal={closeMenu} titulo={"Seleccione la Opción"}  isCancelable={true} clickAceptar={() => clickGenerico("ACEPTAR_MENU")} >
                <div style={{margin:"20px"}}>                    
                    
                    {opcionesMenu.length>0 && (<> 
                        {(opcionesMenu.map((reg, index) => (
                            <div key={index} >
                                <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"left", textAlign:"left", marginBlock:"15px"}}>
                                    <input type="radio" value={index} name="opcionMenu" checked={index===opcionSel} onChange={() => setOpcionSel(index)}/>
                                    <span style={{color:C_uniformeGrisOscuro, marginInline: "10px"}}>{reg[0]}</span>
                                </label>
                            </div>
                        ))  )}     
                    </>)} 

                </div> 
                </Modal>
            )}
            
            {isOpenCambioPC && (
                <Modal isOpen={isOpenCambioPC} closeModal={closeCambioPC} titulo={nombreJug}  isCancelable={false} clickAceptar={() => clickGenerico("ACEPTAR_CAMBIAR_POSICION")} >
                <div style={{margin:"20px", minHeight:"300px", maxWidth:"300px"}}>                    
                    
                    <div>Define la posición y el número de camiseta para el jugador seleccionado.</div>
                    <div style={{display:"flex", alignItems:"center", flexWrap:"nowrap", minWidth:"150px", maxWidth:"400px", marginTop:"30px"}}>
                        <div style={{flex:"1", marginRight:"10px", textAlign:"left"}}>Posición</div>
                        <div style={{width:"180px", backgroundColor:"white"}}>           
                        <Select     
                            styles={estilosSelect}
                            value={infoPosicion[posicionSel]}
                            formatOptionLabel={listaDosItems}
                            options={infoPosicion}
                            isSearchable={false}   
                            onChange={(e,index) => {clickGenerico("CAMBIAR_POSICION",e)}}            
                        />   
                        </div>   
                    </div>
                    <div style={{display:"flex", alignItems:"center", flexWrap:"nowrap", minWidth:"150px", maxWidth:"400px", marginTop:"20px"}}>
                        <div style={{flex:"1", marginRight:"10px", textAlign:"left"}}>Número Camiseta</div>
                        <input type="number" value={arrCampos[9].valor} className='IP_entradas' style={{width:"100px"}} placeholder="" onChange={(e) => validarCampo(e, "MD2_CAMISETA", 3)}/>
                        
                    </div>
                    {arrCampos[9].error!=="" && (
                        <div style={{marginTop:"4px", marginBottom:"0px", textAlign:"right"}}>
                                    <span className="TX_error">{arrCampos[9].error}</span>
                        </div>
                    )}
                    

                </div> 
                </Modal>
            )}
            
            {isOpenBusqueda && (
                <Modal isOpen={isOpenBusqueda} closeModal={closeBusqueda} titulo={"Inscribir Jugador"}  isCancelable={true} txtCancelar="" txtAceptar="" >
                <div style={{margin:"10px", minHeight:"300px", maxHeight:"500px", maxWidth:"400px", fontSize:"16px", color:C_uniformeGrisOscuro}}>                    
                    
                    <div>Escriba o copie el número de telefono del Jugador que desea inscribir en el equipo. Mínimo 10 dígitos.</div>

                    {showAlertaSinTelefono && (
                        <div className='IP_entradas' style={{margin:"20px"}}></div>
                    )}

                    {!showAlertaSinTelefono && (!permiteSinTelefonoUS || (permiteSinTelefonoUS && !aceptoSinTelefonoUS)) && (
                        <div style={{margin:"20px"}}><input type="number" value={arrCampos[10].valor} className='IP_entradas' style={{width:"100%"}} placeholder="" onChange={(e) => validarCampo(e, "FILTRO_BUSQUEDA_TELEFONO", 14)}/></div>
                    )}

                    {permiteSinTelefonoUS && !showAlertaSinTelefono && aceptoSinTelefonoUS && (
                        <div style={{margin:"20px"}}><input type="text" value={arrCampos[10].valor} className='IP_entradas' style={{width:"100%"}} placeholder="" onChange={(e) => validarCampo(e, "FILTRO_BUSQUEDA_NOMBRE", 40)}/></div>
                    )}
                    

                    {permiteSinTelefonoUS && !aceptoSinTelefonoUS && (
                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"-10px", marginBottom:"10px", marginInline:"20px", cursor:"pointer"}} onClick={()=> clickGenerico("CLICK_INCLUIR_SINTELEFONO")}>
                            <div><img className="IM_imgIcono20" src={ic_entrar} alt=""/></div>
                            <div className="TX_letraPeq" style={{flex:"1", marginLeft:"10px", textAlign:"left"}}>{"Incliuir Jugador SIN registrar Teléfono"}</div>
                        </div>
                        
                    )}
                    {permiteSinTelefonoUS && showAlertaSinTelefono && (
                        <div style={{marginInline:"20px"}}>
                            <div style={{fontSize:"14px", color:C_colorTextoError}}>{"* Al ingresar un Jugador SIN número de teléfono, NO PODRÁ registrarse como jugador en MarcaGol y únicamente podrá consultar la información del torneo como Espectador."}</div>
                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"10px"}}>
                                <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"left", textAlign:"left", cursor:"pointer"}}>
                                    <input type="checkbox" name="opcionMenu" onChange={(e)=> setConfirmoSinTelefonoUS(e.target.checked)}/>
                                    <span style={{color:C_uniformeGrisOscuro, marginInline: "10px"}}>{"Estoy de acuerdo"}</span>
                                </label>
                                {confirmoSinTelefonoUS ? 
                                (<div className="BF_botonPrimaryFull" style={{margin:"0px",paddingInline:"0px"}} onClick={() => clickGenerico("ACEPTO_ALERTA_SINTELEFONO")}>Aceptar</div>)
                                : 
                                (<div className="BF_botonTransparenteFull" style={{margin:"0px",paddingInline:"0px"}} onClick={() => clickGenerico("CLICK_CANCELAR_SINTELEFONO")}>Cancelar</div>)
                                }
                                
                            </div>
                        </div>
                    )}
                    
                    
                    {datosEncontrados.length>0 && (<>
                        {(datosEncontrados.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"busqueda_jugadores"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                    </>)} 

                    {datosEncontrados.length===0 && arrCampos[10].error!=="" && (<div style={{color:C_colorTextoSinInfo, whiteSpace:"pre-wrap", marginTop:"10px"}}>
                          {arrCampos[10].error}
                    </div>)} 
                    

                </div> 
                </Modal>
            )}
            

            {isOpenImagen && (
                <ModalFull isOpen={isOpenImagen} closeModal={closeImagen} isCancelable={false}> 
                    <div><GestionImagen tipoImagen={"EQUIPO"} imgActual={arrCampos[11].valor} clickGenerico={clickGenerico}/></div>                
                </ModalFull>
            )}

            {showMensaje && (ventanaMensaje)}


            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                    <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px", cursor:"pointer"}} />  </div>
                    <div className="CT_elementoEncabezado"><img className="IM_imgRedondeada36" height="36px" width="36px" src={ic_escudo_base_gris} alt="" /></div>
                    <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{datosMod.tituloVentana}</p></div>
                </div>
            </div>

            <div style={{paddingTop:"50px"}}>

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", background:C_colorPrimaryDark, color:C_uniformeBlanco}}>
                    <img className="IM_imgRedondeada46" src={datosMod.fotoUsuario} alt="" style={{padding:"8px"}}/>
                    <div style={{flex:"1", fontSize:"14px", textAlign:"left", color: C_colorAccent }}>
                        <div className="TX_unaSolaLinea2"><span style={{marginRight:"10px"}}>{datosMod.rolUsuario}</span><span style={{color:C_uniformeBlanco}}>{datosMod.nomUsuario}</span></div>
                        <div className="TX_unaSolaLinea2"><span style={{marginRight:"10px"}}>{"Empresa:"}</span><span style={{color:C_uniformeBlanco}}>{datosMod.nomEmpresa}</span></div>
                        <div className="TX_unaSolaLinea2"><span style={{marginRight:"10px"}}>{"Sede:"}</span><span style={{color:C_uniformeBlanco}}>{datosMod.nomSede}</span></div>
                    </div> 
                </div>                  
            
            </div>
            
                          

            {!loading && esError===0 && (
                <div style={{margin: "10px", paddingBottom:"20px"}}>  
                    <div className="CT_contenedorTitulo" style={{marginBottom:"20px", cursor:"pointer"}} onClick={salir}>
                        <img className='IM_imgIcono28' src={ic_flecha_expandir} alt='' />   
                        <img className='IM_imgRedondeada36' src={datosMod.escudoTorneo} alt='' style={{marginInline:"10px"}}/>   
                        <div style={{fontSize:"18px"}}>{datosMod.nomTorneo}</div>             
                    </div>

                    <div style={{display:"flex", flexWrap:"wrap", gap:"20px", justifyContent:"center"}}>

                        <div style={{flex:"1", display:"flex", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"center"}}>
                            
                            <div style={{position:"relative", cursor:"pointer"}} onClick={() => clickGenerico("CLICK_MODIFCAR_ESCUDO") }>
                                <img className="IM_imgRedondeada80" src={arrCampos[11].valor} alt="" style={{padding:"0px", zIndex:"1"}}/>
                                <div style={{position:"absolute", bottom:"0px", left:"0px"}}>
                                    <img className="IM_imgIcono24" src={ic_tomarfoto_dark} alt="" style={{padding:"0px", zIndex:"2"}}/>
                                </div>
                            </div>
                            
                            
                            <div style={{flex:"1", display:"block", justifyContent:"left", textAlign:"left"}}>
                                <div style={{fontSize:"16px", color:"black"}}>Nombre Completo:</div>
                                <div style={{display:"flex", alignItems:"center"}}>                                    
                                    {puedeModificarEq ? (
                                        <input type="text" value={arrCampos[0].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px"}} placeholder="Nombre Completo" onChange={(e) => validarCampo(e, "NOMBRE_LARGO", 40)} onBlur={(e) => clickGenerico("SALE_NOMBRE_LARGO")} />                                        
                                    ) : (
                                        <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px", paddingLeft:"10px", cursor:"pointer", display:"flex", alignItems:"center"}}  onClick={() => clickGenerico("CLICK_MODIFCAR_EQUIPO")}><span>{arrCampos[0].valor}</span></div>
                                    )}
                                </div>
                                {puedeModificarEq && arrCampos[0].error!==""  && (
                                    <div>
                                        <span className="TX_error">{arrCampos[0].error}</span>
                                    </div>
                                )}

                                <div style={{fontSize:"16px", color:"black", marginTop:"10px"}}>{"Nombre Corto: (14 letras)"}</div>
                                <div style={{display:"flex"}}>                                    
                                    {puedeModificarEq ? (
                                        <input type="text" value={arrCampos[1].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px"}} placeholder="Nombre Corto" onChange={(e) => validarCampo(e, "NOMBRE_CORTO", 14)} onBlur={(e) => clickGenerico("SALE_NOMBRE_CORTO")}/>                                        
                                    ) : (
                                        <div className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px", paddingLeft:"10px", cursor:"pointer", display:"flex", alignItems:"center"}}  onClick={() => clickGenerico("CLICK_MODIFCAR_EQUIPO")}><span>{arrCampos[1].valor}</span></div>
                                    )}
                                </div>
                                {puedeModificarEq && arrCampos[1].error!==""  && (
                                    <div>
                                        <span className="TX_error">{arrCampos[1].error}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div style={{flex:"1", display:"flex", flexWrap:"nowrap", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"center", justifyContent:"space-around"}}>
                            <div style={{display:"block"}}>
                                <div style={{fontSize:"16px", color:"black"}}>U. Principal</div>
                                <div style={{cursor:"pointer"}} onClick={() => clickGenerico("CLICK_ABRIR_UNIFORME", "Principal")}><img className="IM_camiseta65" src={arrCampos[2].valor} alt="" /></div>
                            </div>
                            <div style={{display:"block"}}>
                                <div style={{fontSize:"16px", color:"black"}}>U. Alterno</div>
                                <div style={{cursor:"pointer"}} onClick={() => clickGenerico("CLICK_ABRIR_UNIFORME", "Alterno")}><img className="IM_camiseta65" src={arrCampos[3].valor} alt="" /></div>
                            </div>
                        </div>

                    </div>

                    <div style={{display:"flex", flexWrap:"wrap", gap:"20px", justifyContent:"center", alignItems:"start", marginTop:"20px"}}>

                        <div style={{flex:"1", display:"flex", flexWrap:"wrap", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"center"}}>
                            
                            <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"500px", flexGrow:"1", flexShrink:"1" }}>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer"}} onClick={() => clickGenerico("CLICK_PARAMETROS")}> 
                                    <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_configura_blanco} alt='' /> 
                                    <p className='TX_tituloCaja' style={{flex:"1"}}>{"Parametros Definidos Torneo"}</p>
                                    <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_contraer_vertical_blanco} alt='' /> 
                                </div>
                                
                                <div className='BF_cajaBlanca'>   
                                    {showParametros && (<>
                                        {(parametros.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"params_Info_Equipos"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                                    </>)} 
                                    {!showParametros && (
                                    <div style={{padding:"8px"}}>
                                        <span className="TX_sinInfo">{parametros.length + " parametros configurados"}</span>
                                    </div>
                                    )}                        
                                         
                                </div>
                            </div> 

                            {incluir_opciones && (
                            <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"500px", marginTop:"20px", flexGrow:"1", flexShrink:"1", }}>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer"}} onClick={() => clickGenerico("CLICK_OPCIONES")}> 
                                    <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_registro_manual} alt='' /> 
                                    <p className='TX_tituloCaja' style={{flex:"1"}}>{"Opciones Adicionales"}</p>
                                    <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_contraer_vertical_blanco} alt='' /> 
                                </div>
                                <div className='BF_cajaBlanca'>   

                                    {showOpciones && (
                                    <div style={{textAlign:"left", margin:"8px"}}>
                                        <div style={{fontSize:"14px", color:C_uniformeNegro}}>Texto a Resaltar en Tabla de Clasificación</div>
                                        <div style={{display:"flex"}}>
                                        <input type="text" value={arrCampos[4].valor} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, minWidth:"100px", maxWidth: "500px"}} placeholder="Texto a incluir sobre el Equipo" onChange={(e) => validarCampo(e, "TEXTO_RESALTAR", 40)}/>
                                        </div>
                                        {arrCampos[4].valor!=="" &&(
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems:"center", fontSize:"14px"}}>
                                            <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"center"}}>
                                                <input type="radio" value="Verde" name="tipoLista" style={{marginInline: "10px"}} checked={tipoLista==="Verde"} onChange={cambiarTipoLista}/>
                                                <span style={{color:C_colorPrimary}}>Verde</span>
                                            </label>
                                            <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"center"}}>
                                                <input type="radio" value="Azul" name="tipoLista" style={{marginInline: "10px"}} checked={tipoLista==="Azul"} onChange={cambiarTipoLista}/>
                                                <span style={{color:C_tarjetaAzul}}>Azul</span>
                                            </label>
                                            <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"center"}}>
                                                <input type="radio" value="Rojo" name="tipoLista" style={{marginInline: "10px"}} checked={tipoLista==="Rojo"} onChange={cambiarTipoLista}/>
                                                <span style={{color:C_uniformeRojo}}>Rojo</span>
                                            </label>
                                            <label style={{display:"flex", alignItems:"center", flex:"1", justifyContent:"center"}}>
                                                <input type="radio" value="Sin Borde" name="tipoLista" style={{marginInline: "10px"}} checked={tipoLista==="Sin Borde"} onChange={cambiarTipoLista}/>
                                                <span style={{color:C_uniformeGrisOscuro}}>Sin Borde</span>
                                            </label>
                                        </div>
                                        )}
                                        
                                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"20px"}}>
                                            <div style={{flex:"1", fontSize:"14px", color:C_uniformeNegro}}><span>{"Asignar Puntaje a Barra del Equipo"}</span></div>
                                            <div><input type="number" value={arrCampos[5].valor} className='IP_entradas' style={{width:"100px"}} placeholder="Puntaje" onChange={(e) => validarCampo(e, "TEXTO_PUNTOS_BARRA", 2)}/></div>
                                        </div>
                                    </div>
                                    )}
                                    {!showOpciones && (
                                    <div style={{padding:"10px"}}>
                                        <span className="TX_sinInfo">{"2 Opciones adicionales"}</span>
                                    </div>
                                    )}            
                                </div>
                            </div> 
                            )}
                            
                        </div>

                        <div style={{flex:"1", display:"flex", flexWrap:"nowrap", flexGrow:"1", flexShrink:"1", minWidth:"250px", maxWidth:"500px", alignItems:"start", justifyContent:"space-around"}}>
                            <div className='BF_cajaAccent' style={{minWidth:"250px", maxWidth:"500px", flexGrow:"1", flexShrink:"1", }}>
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}> 
                                    <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_jugador_registro} alt='' /> 
                                    <p className='TX_tituloCaja' style={{flex:"1"}}>{"Jugadores"}</p> 
                                </div>
                                <div className='BF_cajaBlanca'>  
                                    {puedeModificarJug && !showNewJug && (
                                        <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center", padding:"8px", cursor:"pointer"}} onClick={() => clickGenerico("HABILITAR_INCLUIR_JUGADORES")}>
                                            <img className='IM_imgIcono28' style={{padding:"4px"}} src={ic_jugador_registro_primary} alt='' /> 
                                            <span style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>Incluir nuevos jugadores</span>
                                        </div>
                                    )}

                                    {hayNombresNoEditUS && (
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", margin:"10px"}}> 
                                        <div className='IP_entradas BF_fondoClaro' style={{fontSize:"14px", width:"110px", height:"40px", display:"flex", alignItems:"center"}}><span>Nombres NO Modificables</span></div>
                                        <div className="TX_letraPeq" style={{flex:"1",marginInline:"4px"}}>Estos Jugadores se encuentran registrados en MarcaGol y su información es administrada directamente por el usuario.</div>
                                    </div>
                                    )}                                    

                                    {puedeModificarJug && showNewJug && (
                                        <div style={{marginBottom:"0px"}}>
                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center"}}>
                                                <img className='IM_imgIcono16' src={ic_transparente} alt='' style={{paddingInline:"6px", marginTop:"4px"}}/> 
                                                <div className="TX_letraPeq" style={{flex:"2"}}>Teléfono*</div>
                                                <div className="TX_letraPeq" style={{flex:"3"}}>Nombre*</div>
                                            </div>
                                            {(nuevosJugadores.map((reg, index) => (
                                                <div key={index} >
                                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", paddingBlock:"4px"}}>
                                                        <img className='IM_imgIcono16' src={ic_cancelar_gris} alt='' style={{paddingInline:"6px", cursor:"pointer"}}  onClick={() => clickGenerico("LIMPIAR_CASILLA_NUEVOS_JUGADORES", index)}/> 
                                                        <div className='IP_entradas' style={{flex:"2", paddingLeft:"10px", cursor:"pointer", display:"flex", alignItems:"center", fontSize:"14px"}} onClick={() => clickGenerico("ABRIR_BUSQUEDA_INSCRIBIR_JUGADORES", index)} >
                                                            <span>{reg.telefono}</span>
                                                        </div>
                                                        <div style={{flex:"3", display:"flex", alignItems:"center"}}>
                                                            <input disabled={!reg.habilitado} type="text" value={reg.nombre} className={"IP_entradas " + (reg.habilitado===true ? "" : "BF_fondoClaro")} style={{marginInline:"4px", width:"100%", fontSize:"14px"}} placeholder="" onChange={(e) => clickGenerico("VALIDAR_NOMBRE_NUEVO_JUGADOR", [e,index,40])}  />  
                                                        </div>
                                                    </div>
                                                    {reg.error!=="" && (
                                                        <div style={{marginTop:"-4px", marginBottom:"4px", marginLeft:"28px", textAlign:"left"}}>
                                                            <span className="TX_error" style={{fontSize:"12px", padding:"0px", margin:"0px"}}>{reg.error}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            ))  )} 
                                            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginTop:"4px", marginBottom:"15px"}}>
                                                <div style={{flex:"2"}}></div>
                                                <img className='IM_imgIcono28' src={ic_agregar} alt='' style={{cursor:"pointer"}} onClick={() => clickGenerico("HABILITAR_CASILLAS_NUEVOS_JUGADORES")}/> 
                                                <div style={{flex:"3"}}></div>
                                                <div className="BF_botonPrimaryFull" style={{padding:"3px", margin:"0"}} onClick={() => clickGenerico("CLICK_INSCRIBIR_NUEVOS_JUGADORES")}>Inscribir</div>
                                                <div style={{flex:"1"}}></div>
                                            </div>
                                        </div>
                                    )}


                                    {datosJug.length>0 && (<> 
                                        {(datosJug.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"jugadores_Info_Equipos"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                                    </>)} 
                                    
                                    {datosTecnicos.length>0 && (<>
                                        <div style={{fontSize:"16px", fontWeight:"bold", color:C_uniformeGrisOscuro, textAlign:"left", marginBlock:"10px", marginInline:"20px"}}>CUERPO TÉCNICO</div>  
                                        {(datosTecnicos.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"jugadores_Info_Equipos"} datos={reg} definirModulo={clickGenerico} incluirLinea={false} />))  )}     
                                    </>)}    

                                    {datosJug.length===0 && datosTecnicos.length===0 && (
                                    <div style={{padding:"8px"}}>
                                        <span className="TX_sinInfo">{"No hay Jugadores inscritos"}</span>
                                    </div>
                                    )}        
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            )}
                
           

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}   
        </>
    );

};

export default Info_Equipo;