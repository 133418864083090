import { useEffect, useState } from 'react';

import {formatearDecimal, valorNumero, presentarError, recuperarDatoLocal, reportarError} from '../helpers/funciones';
import DinamicoRankingJugadoresDet from './DinamicoRankingJugadoresDet';

import ic_detalle_verde from '../recGraficos/ic_detalle_verde.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_escudo_base2 from '../recGraficos/ic_escudo_base2_w.png';
import ic_flecha_expandir from '../recGraficos/ic_flecha_expandir.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import { C_colorAccent, C_uniformeBlanco, C_uniformeGrisOscuro } from '../constantes/generales';

const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="DinamicoRankingJugadores_WB";

const DinamicoRankingJugadores = ({ indicador, todos, titulo, subtitulo, imagen, bgnd, ascendente, esporcentaje, idxCant, infoOrdenadaEquipos, infoClasificacion, clickVerTodos, modOrigen, paramOrigen, definirModulo, colPartidosJugados, idTorneo="" }) => {

    const nomFuncion="DinamicoRankingJugadores";

    const [datosFinales, setDatosFinales] = useState([]);
    const [hayMasdatos, setHayMasdatos] = useState(false);

    //let titulo=["#","Equipo","Cant","PJ","Prom"];
    


    useEffect(() => {
        let nomFuncion="useEffect";

        let matrizDatos=[];
        try{        

            if(infoOrdenadaEquipos.length>0 && infoClasificacion.length>0){            

                let numRegistros=5;
                let registros=numRegistros;
                let hayMas=false;
        
                let i=0;
                //let vInicial=0, vFinal=0;
                let hayDatos=false;
            
                try{
                    if(ascendente){
                        if(infoOrdenadaEquipos[indicador].length<(registros+1) || todos) registros=infoOrdenadaEquipos[indicador].length-1;
                        //vInicial=0;
                        //vFinal=registros+1;
        
                        if(registros>99) registros=99;
                
                        for(i=0;i<registros+1;i++) {
                            let datos=["","","","","","","",""];
            
                            if (i === numRegistros && !todos) {
                                if (valorNumero(infoClasificacion[infoOrdenadaEquipos[indicador][i]][idxCant]) > 0)
                                    hayMas = true;
                            } else {
                                let cantidad = valorNumero(infoClasificacion[infoOrdenadaEquipos[indicador][i]][idxCant]);
                                let partidos = valorNumero(infoClasificacion[infoOrdenadaEquipos[indicador][i]][colPartidosJugados]);
                
                                if (partidos > 0 && cantidad>0) {
                                    if(!hayDatos) hayDatos=true;
                
                                    datos[0] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][0]; //idJugador
                
                                    datos[1] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][1]; //Nombre Jugador
                                    datos[2] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][2]; //Nombre equipo
                                    datos[3] = "" + cantidad; //Cantidad
                
                                    datos[4] = "" + partidos; //Partidos Jugados
                                    let promedio = 0;
                                    if (partidos > 0) promedio = cantidad / partidos;
                                    if(esporcentaje) datos[5] ="";
                                    else datos[5] = formatearDecimal(promedio);
                                    //let imagen = ic_escudo_base2;
                                    //if (i % 2 == 0) imagen = R.drawable.escudo_base2;
                
                                    datos[6]=infoClasificacion[infoOrdenadaEquipos[indicador][i]][17]; //Escudo equipo
                                    if(datos[6]==="") datos[6]=ic_jugadorsinimagen;
                                    else datos[6] = ruta + datos[6];
                                    datos[7]=i+1;  

                                    datos[8] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][45]; //idEquipo
                                            
                                    matrizDatos.push(datos);
                
                                }
                
                            }
                        }
                
                    }
                    else{
                        if(infoOrdenadaEquipos[indicador].length<(registros+1) || todos) registros=infoOrdenadaEquipos[indicador].length;
                        //vInicial=infoOrdenadaEquipos[indicador].length-1;
                        //vFinal=infoOrdenadaEquipos[indicador].length-registros;
                
                        //i=vInicial;
                        let j=0;
                        for(i=infoOrdenadaEquipos[indicador].length-1;i>=0;i--) {
                            let datos=["","","","","","","",""];
                
                            let cantidad = valorNumero(infoClasificacion[infoOrdenadaEquipos[indicador][i]][idxCant]);
                            let partidos = valorNumero(infoClasificacion[infoOrdenadaEquipos[indicador][i]][colPartidosJugados]);
                
                                if (partidos > 0 && j<registros) {
                                    if(!hayDatos){
                                        //vistaDetalle=objV.agregarVistaRankingEquiposDetalle(this,1,0,0,titulo);
                                        //llPadreDetalleRanking.addView(vistaDetalle);
                                        hayDatos=true;
                                    }
                                    j++;
                
                                    datos[0] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][0];
                
                                    datos[1] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][1]; //Nombre equipo
                                    datos[2] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][2]; //Nombre equipo
                                    datos[3] = "" + cantidad; //Cantidad
                
                                    datos[4] = "" + partidos; //Partidos Jugados
                                    let promedio = 0;
                                    if (partidos > 0) promedio = cantidad / partidos;
                                    if(esporcentaje) datos[5] ="";
                                    else datos[5] = formatearDecimal(promedio);
                                    //let imagen = ic_escudo_base2;
                                    //if (i % 2 == 0) imagen = R.drawable.escudo_base2;
                
                                    datos[6] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][17]; //escudo
                                    if(datos[6]==="") datos[6]=ic_jugadorsinimagen;
                                    else datos[6] = ruta + datos[6];
                                    datos[7]=j;  

                                    datos[8] = infoClasificacion[infoOrdenadaEquipos[indicador][i]][45]; //idEquipo
                
                                    matrizDatos.push(datos);
                
                                }
                                else if(partidos>0) j++;
                
                            if(j>registros){
                                hayMas=true;
                                i=-1;
                            }
                        }
                    } 
                }catch(err){
                    presentarError("",nomFuncion,"",err);
                }
                
                if(hayDatos && hayMas && !todos) setHayMasdatos(true);
                else setHayMasdatos(false);
                
            }
           
        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }

        setDatosFinales(matrizDatos);

    }, [indicador]);



    const incluirRegistro = (registro, indice) => {

        return(
            <DinamicoRankingJugadoresDet key={indice} idTorneo={idTorneo} registro={registro}  tipoRanking={"jugadores"} modOrigen={modOrigen} paramOrigen={paramOrigen} definirModulo={definirModulo} />            
        );
 
     }; 

     const noHacerNada = () => {}

   
    return(
        <div className='CT_regSancion BF_bordeTotal' style={{padding: "6px",background:C_uniformeBlanco, minWidth:"280px", maxWidth:"500px"}}>
                <div className="CT_contenedorTitulo" style={{paddingLeft:"10px", paddingTop:"4px", paddingBottom:"4px", cursor:"pointer"}} onClick={todos ? (clickVerTodos) : (noHacerNada)}>
                    {todos && (<img className='IM_imgIcono24' src={ic_flecha_expandir} alt='' style={{paddingRight:"10px", paddingLeft:"10px"}} /> )}
                    <img className='IM_imgIcono24' src={imagen} alt='' />   
                    <span style={{flex:"1", marginLeft: "10px", marginRight:"10px", textAlign:"left", fontSize:"14px", fontWeight:"bold"}}>{titulo}</span>     
                    {hayMasdatos && (<div style={{display:"flex", alignItems:"center", cursor:"pointer"}} onClick={clickVerTodos}>
                    <span style={{fontSize:"14px", color: C_colorAccent }}>Ver Todo</span>  
                    <img className='IM_imgIcono24' src={ic_detalle_verde} alt='' />
                    <img className='IM_imgIcono24' src={ic_transparente} alt='' />
                    </div>)}      
                </div>


                <div className='BF_cajaGris' style={{marginLeft:"2px", marginRight:"2px", marginBottom:"4px"}}>
                        <p className='TX_tituloCaja'>{subtitulo}</p>
                        <div className='BF_cajaBlanca'>
                            <table width={"100%"} border="0" style={{textAlign:"center"}}><tbody>                                
                            
                            {datosFinales.length>0 && (
                            <tr  style={{alignItems:"center", fontSize:"12px", color:C_uniformeGrisOscuro, padding:"2px"}}>
                                <td style={{fontWeight:"bold"}}>#</td>
                                <td width="36px"></td>
                                <td>Equipo</td>
                                <td>Cant</td>
                                <td>PJ</td>
                                <td>Prom</td>                                
                            </tr>                        
                            )}

                            {datosFinales.length>0 && (
                                <tr><td colSpan={6} align="center"><div className='BF_lineaInferior' ></div></td></tr>
                            )}


                            {datosFinales.length>0 ? 
                            (datosFinales.map((el, index) => (incluirRegistro(el, index) ))) 
                            : 
                            (<tr><td><p className='TX_sinInfo'>No hay información de este indicador</p></td></tr>)}
                                
                            </tbody></table>
                        </div>
                </div>    
                
                
                
            </div>

    );

};


export default DinamicoRankingJugadores;