import "../estilos/MD_modales.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";

import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png'
import ic_salir_w from '../recGraficos/ic_salir_w.png'
import { C_colorPrimary, C_colorPrimaryDark, C_fondoPaginas, C_uniformeBlanco, C_uniformeGrisClaro, C_uniformeGrisSuperClaro } from "../constantes/generales";

const ModalFull = ({ children, isOpen, closeModal,  clickCerrar=null, clickCancelar=null, clickAceptar, isCancelable=true, tipoVentana="FULL"}) => {
  const handleModalContainerClick = (e) => e.stopPropagation();

  

  let estiloChildren = {
    marginTop:"0px", 
    marginBottom: "0px", 
    overflow: "auto", 
    heigth:"100vh",
    
};

  const cerrar = () => {
    if(clickCerrar===null) closeModal();
    else clickCerrar();    
  } 
  
  const cancelar = () => {
    if(clickCancelar===null) cerrar();
    else clickCancelar();    
  } 

  const nada = () => {} 

  return (
    <>
    {tipoVentana==="FULL" && (
      <article className={`MD_Modal ${isOpen && "isOpen"}`} onClick={isCancelable ? (cerrar) : (nada)} style={{padding:"0px", margin:"0px"}}>
        <div className="MD_ContenedorFull" onClick={handleModalContainerClick} style={{margin:"20px", backgroundColor: C_colorPrimaryDark}} >
        

          <div style={estiloChildren}>
              {children}
          </div>

          
        </div>
      </article>
    )}

    {tipoVentana==="SMALL" && (
      <article className={`MD_Modal ${isOpen && "isOpen"}`} onClick={isCancelable ? (cerrar) : (nada)} style={{padding:"0px", margin:"0px"}}>
        <div onClick={handleModalContainerClick}>
        

          <div>
              {children}
          </div>

          
        </div>
      </article>
    )}
      
    </>
    
  );
};

export default ModalFull;