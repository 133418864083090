import { useEffect, useState } from 'react';

import {formatearDecimal, formatearFecha, getReferenciaPago, recuperarDatoLocal, reportarError, valorLong, valorNumero} from '../helpers/funciones';

import "../estilos/TX_Textos.css";
import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";

import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_escudo_base from '../recGraficos/ic_escudo_base.png';
import ic_cobros_gestionar_blanco from '../recGraficos/ic_cobros_gestionar_blanco.png';
import ic_flecha_contraer from '../recGraficos/ic_flecha_contraer.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_manual_primary from '../recGraficos/ic_manual_primary.png';
import ic_cancelar from '../recGraficos/ic_cancelar.png';
import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png';

import { C_fondoAccentSuave, C_fondolistasparrecuadro, C_fondoPrimarySuave, C_transparente, C_uniformeAzulClaro, C_uniformeBlanco, C_uniformeGrisClaro, C_uniformeGrisOscuro, C_uniformeGrisSuperClaro, C_uniformeNegro, C_uniformeRojo, C_uniformeRosa, C_uniformeVerdeClaro } from '../constantes/generales';

const ruta=recuperarDatoLocal("rutaRecursos");


const nomClase="DinamicoCobrosDetalle_WB";

const DinamicoCobrosDetalle = ({datos, tipoLista, eventoClick=null}) => {
    const [display, setDisplay] = useState("none");
    const [soloMostrar, setSoloMostrar] = useState(true);

    useEffect(() => {
        const nomFuncion="useEffect";

        try{    
            if(datos!==null){
                if(datos.menu) setDisplay("flex");
                else setDisplay("none");
            } 

            if(eventoClick!==null) setSoloMostrar(false);
            else setSoloMostrar(true);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [datos]);


    const enviarClick = (e) => {
        e.stopPropagation();
        setDisplay("none");
    }

    const clickElemento = () => {
        if(eventoClick!==null){
            if(display==="none") eventoClick("CLICK_DETCOBRO", datos.indice);    
        }            
    }
    

    const nomFuncion="DinamicoCobrosDetalle";
    try{
        
        if(!datos) return null;

        
        
        let txtDescripcion="";
        let txtNomJugador="";
        let fotoJugador=ic_jugadorsinimagen;
        let escudoRival=ic_escudo_base;
        let txtNomRival="";
        let txtFechaPartido="";

        let valorT="";
        let valorP="";
        let valorD="";
        let referenciaP="";

        let esCobroDistribuido=false;

        let presentarDescripcion=false;
        let presentarJugador=false;
        let presentarPartido=false;


        let showReg=false;
        let estaPendiente=false;

        let tempVT=valorNumero(datos.datosCobro[6]);
        let tempVP=valorNumero(datos.datosCobro[7]);
        let tempVD=tempVT - tempVP;   
        if(tempVD<0) tempVD=0; 

        if(datos.datosCobro.length>=22){
            if(datos.datosCobro[21]===("2")) esCobroDistribuido=true;

            tempVT=valorNumero(datos.datosCobro[19]);
            tempVP=valorNumero(datos.datosCobro[20]);
            tempVD=tempVT - tempVP;   
            if(tempVD<0) tempVD=0; 
        }

        if(tempVD>0){
            estaPendiente=true;
            if(datos.datosCobro.length>=22) referenciaP="Ref. " + getReferenciaPago(datos.datosCobro[18]) ;
            else referenciaP="Ref. " + getReferenciaPago(datos.datosCobro[0]) + "0";
        } 

        valorT="$ " + formatearDecimal(tempVT,2);
        valorP="$ " + formatearDecimal(tempVP,2);
        valorD="$ " + formatearDecimal(tempVD,2);

        if(tipoLista==="pendiente" && estaPendiente) showReg=true;
        if(tipoLista==="todos") showReg=true;               


        if(datos.datosConfig[5]===("1")){
            presentarDescripcion=true;
            txtDescripcion=datos.datosConfig[3];
            if(datos.datosCobro[4]!==("0")) presentarJugador=true;

        }
        else if(datos.datosConfig[5]===("2")){
            if(datos.datosCobro[3]!==("0")) presentarPartido=true;
            if(datos.datosCobro[4]!==("0")) presentarJugador=true;  
        }
        else if(datos.datosConfig[5]===("3")){
            let fechaE="";
            if(datos.datosConfig[6]===("4")){
                presentarDescripcion=true;
                txtDescripcion=formatearFecha(datos.datosCobro[8],13) + " - " + datos.datosCobro[13];
            }
            else{
                if(datos.datosCobro[3]!==("0")) presentarPartido=true;
            }
            if(datos.datosCobro[4]!==("0")) presentarJugador=true;
        }

        if(presentarJugador){
            txtNomJugador=(datos.datosCobro[11]);
            if(datos.datosCobro[12]!==("")) fotoJugador = ruta + datos.datosCobro[12];
        }

        if(presentarPartido){
            if(datos.escudo!=="") escudoRival=ruta + datos.escudo;
            txtNomRival= datos.nomContrincante;
            txtFechaPartido=formatearFecha(datos.datosCobro[10],2);
        }
        
        
        if(showReg){
            return (
                <>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", alignContent:"center"}} onClick={clickElemento}>
                        
                        {tempVP>0 && (
                            <div className='BF_botonPrimaryFull' style={{display: display, flexWrap:"nowrap", alignItems:"center", position:"absolute", margin:"0px"}}>
                                <div style={{paddingInline:"10px"}} onClick={() => {eventoClick("CLICK_DET_VERPAGOS",datos.indice)}}>
                                    <img className="IM_imgIcono20" src={ic_cobros_gestionar_blanco} alt="" />
                                    <div style={{fontSize:"12px", color:C_uniformeBlanco, fontWeight:"bold"}}>Ver Pagos</div>
                                </div>
                                <img className="IM_imgIcono28" src={ic_flecha_contraer} alt="" style={{paddingInline:"10px"}} onClick={enviarClick}/>
                            </div>
                        )}
                        

                        <div style={{flex:"1", display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:(tempVP>0 ? "pointer":""), marginBlock:"4px", textAlign:"left"}} >
                        
                            {!soloMostrar && (<div style={{width:"25px"}}>{datos.contador + "."}</div>)}                             

                            <div style={{flex:"1"}}>
                                {presentarDescripcion && (<div style={{fontSize:"14px", color:C_uniformeGrisOscuro}}>{txtDescripcion}</div>)}

                                {presentarJugador && (
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", gap:"4px"}}>
                                        <img className="IM_imgRedondeada28" src={fotoJugador} alt="" />
                                        <div style={{flex:"1", fontSize:"14px", color:C_uniformeNegro}}>{txtNomJugador}</div>
                                    </div>
                                )}

                                {presentarPartido && (
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", gap:"4px"}}>
                                        <div style={{marginInline:"6px", fontSize:"14px", color:C_uniformeGrisOscuro}}>vs</div>
                                        <img className="IM_imgRedondeada28" src={escudoRival} alt="" />
                                        <div>
                                            <div style={{fontSize:"14px", color:C_uniformeNegro}}>{txtNomRival}</div>
                                            <div style={{fontSize:"12px", color:C_uniformeGrisOscuro}}>{txtFechaPartido}</div>
                                        </div>
                                    </div>
                                )}    

                                {esCobroDistribuido && (
                                    <div className="TX_sinInfo" style={{fontSize:"12px", textAlign:"left", margin:"0px", padding:"0px"}}>Cobro Distribuido</div>  
                                )}                        
                                
                            </div>
                            
                            <div style={{width:"70px", display:"block", textAlign:"right"}}>
                                <div style={{fontSize:"12px", color:C_uniformeNegro, fontWeight:"bold"}}>{valorT}</div>
                                <div style={{fontSize:"12px", color:C_uniformeVerdeClaro}}>{valorP}</div>
                                <div style={{fontSize:"12px", color:C_uniformeRojo}}>{valorD}</div>
                                {referenciaP!=="" && (<div style={{fontSize:"10px", color:C_uniformeGrisOscuro}}>{referenciaP}</div>)}
                                
                            </div>

                            {!soloMostrar && (<img className="IM_imgIcono24" src={tempVP>0 ? ic_entrar_gris:ic_transparente } alt="" />)} 
                            


                            
                            
                        </div> 

                    </div>
                     {!soloMostrar && (<div className="BF_lineaInferior"></div>)} 
                    


            </>
            );
        }
        else return(<></>);
        
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    
};


export default DinamicoCobrosDetalle;