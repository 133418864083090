import { reportarError, valorNumero } from "../helpers/funciones";

import ic_ganador from '../recGraficos/ic_ganador.png';
import ic_perdedor from '../recGraficos/ic_perdedor.png';
import ic_empate from '../recGraficos/ic_empate.png';
import { C_uniformeRojo, C_uniformeGrisClaro, C_uniformeGrisOscuro, C_uniformeVerdeClaro } from "../constantes/generales";

const DinamicoResumenGanador = ({texto1, texto2, puntos1, puntos2}) => {
    const nomClase="DinamicoResumenGanador_WB";
    const nomFuncion="DinamicoResumenGanador";

    try{

        if(!texto1 || !texto2) return null;

        let cssPuntos1={
            fontSize:"12px", 
            color:C_uniformeVerdeClaro, 
            margin:"0", 
            fontWeight:"bold"};
        
        let cssPuntos2={
            fontSize:"12px", 
            color:C_uniformeVerdeClaro, 
            margin:"0", 
            fontWeight:"bold"};

            let sec1="", sec2="";
            let txtFinal1="", txtFinal2="";
            let temp="";

            let pts1=0, pts2=0;
            let img1=ic_ganador, img2=ic_perdedor;


            temp=texto1.toUpperCase().replace("GANADO","");
            temp=temp.replace("PERDIDO","");
            temp=temp.replace("EMPATADO","");
            temp=temp.trim();
            if(temp!=="") sec1="(" + temp + ")";

            temp=texto2.toUpperCase().replace("GANADO","");
            temp=temp.replace("PERDIDO","");
            temp=temp.replace("EMPATADO","");
            temp=temp.trim();
            if(temp!=="") sec2="(" + temp + ")";

            pts1=valorNumero(puntos1);
            pts2=valorNumero(puntos2);

            if(pts1>pts2) {
                txtFinal1="Ganador";
                txtFinal2="Perdedor";
                img1=ic_ganador;
                img2=ic_perdedor;

                cssPuntos1.color=C_uniformeVerdeClaro;
                cssPuntos2.color=C_uniformeRojo;
            }
            else if(pts1<pts2) {
                txtFinal1="Perdedor";
                txtFinal2="Ganador";
                img1=ic_perdedor;
                img2=ic_ganador;

                cssPuntos1.color=C_uniformeRojo;
                cssPuntos2.color=C_uniformeVerdeClaro;
            }
            else if(pts1===pts2 && pts1>1){
                txtFinal1="Ganador";
                txtFinal2="Ganador";
                img1=ic_ganador;
                img2=ic_ganador;

                cssPuntos1.color=C_uniformeVerdeClaro;
                cssPuntos2.color=C_uniformeVerdeClaro;
            }
            else if(pts1===pts2 && pts1===0){
                txtFinal1="Perdedor";
                txtFinal2="Perdedor";
                img1=ic_perdedor;
                img2=ic_perdedor;

                cssPuntos1.color=C_uniformeRojo;
                cssPuntos2.color=C_uniformeRojo;
            }
            else {
                txtFinal1="Empate";
                txtFinal2="Empate";
                img1=ic_empate;
                img2=ic_empate;

                cssPuntos1.color=C_uniformeVerdeClaro;
                cssPuntos2.color=C_uniformeVerdeClaro;
            }


        return(
            <div style={{display:"flex", textAlign:"center", justifyContent:"space-around", alignItems:"center", marginBottom:"10px"}}>
                <div style={{flex:"1", display:"block", textAlign:"center"}}>
                        <p style={{fontSize:"24px", color:C_uniformeGrisOscuro, margin:"0"}}>{txtFinal1}</p>
                        {sec1!=="" && <p style={{fontSize:"12px", color:C_uniformeGrisClaro, margin:"0"}}>{sec1}</p>}
                        <p style={cssPuntos1}>{pts1===1 ? (pts1 + " Punto") : (pts1 + " Puntos")}</p>
                </div>
                <div><img src={img1} alt=""  className='IM_imagenConTexto' /></div>
                <div><img src={img2} alt="" className='IM_imagenConTexto' /></div>
                <div style={{flex:"1", display:"block", textAlign:"center"}}>
                        <p style={{fontSize:"24px", color:C_uniformeGrisOscuro, margin:"0"}}>{txtFinal2}</p>
                        {sec2!=="" && <p style={{fontSize:"12px", color:C_uniformeGrisClaro, margin:"0"}}>{sec2}</p>}
                        <p style={cssPuntos2}>{pts2===1 ? (pts2 + " Punto") : (pts2 + " Puntos")}</p>
                </div>
            </div>
        );

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

};


export default DinamicoResumenGanador;