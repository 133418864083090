import "../estilos/MD_modales.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/BF_BordesFondos.css";

import ic_cancelar_gris from '../recGraficos/ic_cancelar_gris.png';


const MenuLateral = ({ children, isOpen, closeModal, clickCerrar=null, clickCancelar=null, clickAceptar, isCancelable=true}) => {
  
  const handleModalContainerClick = (e) => e.stopPropagation();

  const cerrar = () => {
    if(clickCerrar===null) closeModal();
    else clickCerrar();    
  } 
  
  const cancelar = () => {
    if(clickCancelar===null) cerrar();
    else clickCancelar();    
  } 

  const nada = () => {} 

  return (
    <>
    
    <div className={`MD_MenuLateral ${isOpen && "isOpen"}`} onClick={cerrar}>
        <div className={`MD_ContenedorLateral ${isOpen && "isOpen"}`} onClick={handleModalContainerClick} style={{margin:"0px"}}>  
          <div className="MD_Cerrar"><img className="IM_imgIcono24" src={ic_cancelar_gris} style={{marginLeft:"10px"}} onClick={cerrar}/></div>   
            <div style={{overflow:"auto"}}>
                {children}
            </div>    
        </div>
    </div>
      
    </>
    
  );
};

export default MenuLateral;