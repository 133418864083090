import { C_uniformeGrisOscuro, C_uniformeNegro } from "../constantes/generales";
import { formatearDecimal, valorFloat } from "../helpers/funciones";

const DinamicoResumenBarra = ({ imagen, titulo, cantidad1, cantidad2 }) => {

        let cant1=valorFloat(cantidad1);
        let cant2=valorFloat(cantidad2);

        let barraVacia=false;
        let avance=0;
        let cssBarras={width:"0%"}

        if(cant1<= 0 && cant2<=0){
            barraVacia=true;        }
        else{
            let total=cant1+cant2;            
            if(total>0) avance=(cant1/total)*100;

            cssBarras.width=avance + "%";
        }


    return(
        <div style={{margin:"15px"}}>
            <div style={{display:"flex", alignItems:"center", margin:"5px"}}>
                <span style={{fontSize:"16px", color:C_uniformeNegro}}>{formatearDecimal(cantidad1)}</span>
                <div style={{flex: "1", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {imagen!=="" && <img className="IM_imgIcono24" src={imagen} alt="" />}
                    <span style={{fontSize:"16px", color:C_uniformeGrisOscuro, marginLeft:"10px", marginRight:"10px"}}>{titulo}</span>
                </div>
                <span style={{fontSize:"16px", color:C_uniformeNegro}}>{formatearDecimal(cantidad2)}</span>
            </div>

            {barraVacia ? (<div className="progressVacio"></div>) : 
            (
                <div className="progress">
                    <div className="progress-bar" style={cssBarras}>
                    </div>
                </div>
            )}
            
        </div>

    );

};


export default DinamicoResumenBarra;