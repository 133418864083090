import CryptoJS from 'crypto-js';
import { getDatos } from '../gestionDatos/getDatos';

import dateFormat  from "dateformat";
import { i18n } from "dateformat";
import { secretKey } from '../constantes/secretKey';
import Mensaje from '../recVisual/Mensaje';
import { APP_VERSION_APP, C_uniformeBlanco, C_uniformeGrisMedio, C_uniformeGrisOscuro, C_uniformeNegro, C_uniformeVerdeClaro, LISTA_MODULOS, NUM_LETRAS, REFERENCIA_PAGOS, REGISTRAR_CONSOLE, SEP_1, SEPARADOR_PARAMETROS_BD, TIEMPO_ACTUALIZACION_INFO } from '../constantes/generales';
import Confirmar from '../recVisual/Confirmar';

import ic_escudo_base_gris from '../recGraficos/ic_escudo_base_gris.png'
import ic_transparente from '../recGraficos/ic_transparente.png'

const nomClase="funciones_WB";
const SEP_PARAMS=SEPARADOR_PARAMETROS_BD;
const NUM_MIN_REGISTROS_LOG=process.env.REACT_APP_NUM_MIN_REGISTROS_LOG;
const VERSION_APP=APP_VERSION_APP;


export const crearTabla = (datos, numero) => {
    const nomFuncion="crearTabla";

    let respuesta = [];
    let sep_cols = "$";
    let encontro=false;
    let i;

    try{
        let result = [];
        for(i=0;i<datos.length;i++){
            if(datos[i][0]===numero){
                encontro=true;
                let fila=datos[i][1] + sep_cols + "0";
    
                var columnas=fila.split(sep_cols);
                if(columnas.length>0){
                    result.push(columnas);
                }    
            }    
            else{
                if(encontro) i=datos.length;
            }                                
        }

        respuesta=result;
    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }    

    return respuesta;
}


export const reconstruirDatosOrigen = (total, datos, numero) => {
    const nomFuncion="reconstruirDatosOrigen";

    let respuesta = [];
    let sep_cols = "$";
    //let encontro=false;
    let i, j;

    try{
        let result = total;
        for(i=0;i<datos.length;i++){
            let linea="";
           
            for(j=0;j<datos[i].length;j++){
                if(linea==="") linea=datos[i][j];
                else linea=linea + sep_cols + datos[i][j];
            }

            result.push([numero,linea]);
                                         
        }

        respuesta=result;
    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }    

    return respuesta;
}


export const convertirTextoHorario = (texto) => {
    let nomFuncion="convertirTextoHorario";
    let resultado="";

    try{

        let textoTemp="";
        let semana=["Lunes","Martes","Miercoles","Jueves","Viernes","Sabado","Domingo"];

        let i,j;

        if(texto!==""){

            let dias=texto.split("|");
            for(i=0;i<dias.length;i++){
                let dia=dias[i].substring(0,2).toUpperCase();
                dias[i]=dias[i].substring(3);
                for(j=0;j<semana.length;j++){
                    if(dia===semana[j].substring(0,2).toUpperCase()){
                        dia=semana[j];
                        j=semana.length;
                    }
                }
                let horas=dias[i].split("-");
                let hora1="", hora2="";
                if(horas.length===2){
                    let minutos1=horas[0].split(":");
                    let minutos2=horas[1].split(":");

                    if(minutos1.length===1){
                        hora1=minutos1[0]+":00";
                    }
                    else if(minutos1.length===2){
                        hora1=horas[0];
                    }

                    if(minutos2.length===1){
                        hora2=minutos2[0]+":00";
                    }
                    else if(minutos2.length===2){
                        hora2=horas[1];
                    }
                }

                if(textoTemp!=="") textoTemp=textoTemp+"\n";
                textoTemp=textoTemp+ dia+" "+ hora1 + " a " + hora2;
            }

            resultado=textoTemp;

        }

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return resultado;
}

export const formatearFecha = (fecha, formato) => {
    let nomFuncion="formatearFecha";

    let resultado="";
    

    try{

        

        i18n.dayNames = [
            "Dom",  
            "Lun",
            "Mar",
            "Mie",
            "Jue",
            "Vie",
            "Sab",  
            "Domingo",                    
            "Lunes",
            "Martes",
            "Miercoles",
            "Jueves",
            "Viernes",
            "Sabado",
            
          ];
          
          i18n.monthNames = [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ];
          
          i18n.timeNames = ["a", "p", "am", "pm", "A", "P", "AM", "PM"];

        if(fecha==null || fecha==="") return "";
        fecha=fecha.trim();

        let txtFecha="", txtHora="";
        let hora=0, minuto=0, segundo=0;

        if(fecha.length>10){
            let arrFecha=fecha.split(" ");

            if(arrFecha.length>=1) txtFecha=arrFecha[0];

            if(arrFecha.length>=2){
                txtHora=arrFecha[1];
            } 
        }
        else txtFecha=fecha;

        let fechaEntrada = new Date(txtFecha+"T00:00:00");

        if(txtHora.length>=5){
            let arrHora=txtHora.split(":");

            if(arrHora.length===2){ 
                hora=valorNumero(arrHora[0]);
                minuto=valorNumero(arrHora[1]);
            }
            else if(arrHora.length===3){
                hora=valorNumero(arrHora[0]);
                minuto=valorNumero(arrHora[1]);
                segundo=valorNumero(arrHora[2]);
            }
        }

        if(txtHora.length>=5) fechaEntrada.setHours(hora,minuto,segundo,0);

        let salida="";

        switch (formato){
            case 1: //corto
                salida = dateFormat(fechaEntrada, "dd/mm/yy HH:MM"); 
                //salida = new SimpleDateFormat("dd/MM/yy HH:mm", Locale.getDefault());
                break;
            case 2: //medio
                salida = dateFormat(fechaEntrada, "dd mmm yyyy HH:MM"); 
                //salida = new SimpleDateFormat("dd MMM yyyy HH:mm", Locale.getDefault());
                break;
            case 3: //largo
                salida = dateFormat(fechaEntrada, "ddd, d mmm yyyy HH:MM"); 
                //salida = new SimpleDateFormat("EEE, d MMM yyyy HH:mm", Locale.getDefault());
                break;
            case 4: //full
                salida = dateFormat(fechaEntrada, "dddd, d mmmm yyyy HH:MM"); 
                //salida = new SimpleDateFormat("EEEE, d MMMM yyyy HH:mm", Locale.getDefault());
                break;
            case 5: //corto
                salida = dateFormat(fechaEntrada, "mmm dd"); 
                //salida = new SimpleDateFormat("MMM dd", Locale.getDefault());
                break;
            case 6: //medio
                salida = dateFormat(fechaEntrada, "dd mmm yyyy"); 
                //salida = new SimpleDateFormat("dd MMM yyyy", Locale.getDefault());
                break;
            case 7: //largo
                salida = dateFormat(fechaEntrada, "ddd, d mmm yyyy"); 
                //salida = new SimpleDateFormat("EEE, d MMM yyyy", Locale.getDefault());
                break;
            case 8: //full
                salida = dateFormat(fechaEntrada, "dddd, d mmmm yyyy"); 
                //salida = new SimpleDateFormat("EEEE, d MMMM yyyy", Locale.getDefault());
                break;
            case 9: //full
                salida = dateFormat(fechaEntrada, "dddd d, mmmm"); 
                //salida = new SimpleDateFormat("EEEE d, MMMM", Locale.getDefault());
                break;
            case 10: //full
                salida = dateFormat(fechaEntrada, "yyyy-mm-dd");
                //salida = new SimpleDateFormat("yyyy-MM-dd", Locale.getDefault());
                break;
            case 11: //corto
                salida = dateFormat(fechaEntrada, "mmm dd HH:MM");
                //salida = new SimpleDateFormat("MMM dd HH:mm", Locale.getDefault());
                break;
            case 12: //full
                salida = dateFormat(fechaEntrada, "dddd, d mmmm HH:MM");
                //salida = new SimpleDateFormat("EEEE, d MMMM HH:mm", Locale.getDefault());
                break;
            case 13: //full
                salida = dateFormat(fechaEntrada, "ddd d, mmmm");
                //salida = new SimpleDateFormat("EEE d, MMM", Locale.getDefault());
                break;
            case 14: //full
                salida = dateFormat(fechaEntrada, "ddd, d mmm HH:MM");
                //salida = new SimpleDateFormat("EEE, d MMM HH:mm", Locale.getDefault());
                break;
            case 15: //full
                salida = dateFormat(fechaEntrada, "yyyy-mm-dd");
                //salida = new SimpleDateFormat("yyyy-MM-dd", Locale.getDefault());
                break;
            case 16: //full
                salida = dateFormat(fechaEntrada, "dd 'de' mmmm");
                //salida = new SimpleDateFormat("dd 'de' MMMM", Locale.getDefault());
                break;
            case 17: //full
                salida = dateFormat(fechaEntrada, "dddd d, mmm");
                //salida = new SimpleDateFormat("EEEE d, MMM", Locale.getDefault());
                break;
            case 18: //full
                salida = dateFormat(fechaEntrada, "ddd d, mmm");
                //salida = new SimpleDateFormat("EEE d, MMM", Locale.getDefault());
                break;
            default:
                salida = dateFormat(fechaEntrada, "dd mmm yyyy HH:MM");
                //salida = new SimpleDateFormat("dd MMM yyyy HH:mm", Locale.getDefault());
        }


        resultado=salida; 

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }
    return resultado;
}

export const formatearDecimal = (texto) => {
    let nomFuncion="formatearDecimal";

    let resultado="0";
    let temp=["",""];
    let idx;

    //formato==2: se formatea con hasta 2 decimales

    try{
        texto=" " + texto;
        texto=texto.trim();
    
        if(texto!==""){
            idx=texto.indexOf(".");

            if (idx > 0) {
                let tempInt=0;
                if((idx+2)>texto.length) tempInt=texto.length;
                else tempInt=idx+2;

                temp[0] = texto.substring(0, idx);
                temp[1] = texto.substring(idx + 1, tempInt);
                if (valorNumero(temp[1]) > 0) resultado = temp[0] + "." + temp[1];
                else resultado = temp[0];
            } else {
                if (valorNumero(texto) > 0) resultado = texto;
            }
        }



    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }
    return resultado;
}

export const formatearPorcentaje = (texto) => {
    let nomFuncion="formatearPorcentaje";

    let resultado="";
    let temp=["",""];
    let idx;


    try{
        texto="" + texto;
        texto=texto.trim();

        if(texto.equals!==""){
            idx=texto.indexOf(".");

            if(idx>0){
                temp[0]=texto.substring(0,idx);
                temp[1]=texto.substring(idx+1,idx+2);
                if(valorNumero(temp[1])>0) resultado=temp[0] + "." + temp[1] + "%";
                else resultado=temp[0] + "%";
            }
            else{
                if(valorNumero(texto)>0 || texto==="0") resultado=texto + "%";
            }
        }


    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }
    return resultado;
}

export const formatearTexto = (texto, formato) => {
    let nomFuncion="formatearTexto";

    let resultado="";
    let temp="";

    try{
        if(texto==null) texto="";

        temp=texto.trim();

        if(temp!==""){

            switch (formato){
                case 1:
                    //Primera letra de la cadena a mayuscula y el resto minuscula
                    temp=temp.toLowerCase();
                    temp=temp.substring(0,1).toUpperCase() + temp.substring(1);
                    break;
                case 2:
                    //Primera letra de cada palabra a mayuscula
                    temp=temp.toLowerCase();
                    let palabras=temp.split(" ");

                    let i;
                    let txtFinal="";
                    for(i=0;i<palabras.length;i++){
                        palabras[i]=palabras[i].trim();
                        if(palabras[i]!==""){
                            palabras[i]=palabras[i].substring(0,1).toUpperCase() + palabras[i].substring(1);
                            txtFinal=txtFinal + " " + palabras[i];
                        }
                    }
                    temp=txtFinal.trim();

                    break;
                default:
                    temp=temp.trim();
                    break;

            }

        }

        resultado=temp;


    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }
    return resultado;
}

export const quitarCharEspeciales = (texto) => {
    let nomFuncion="quitarCharEspeciales";
    let dato=texto;

    try{
        if(dato!==""){
            let charEspeciales=["|","&","$","#","'",";","%"];
            let i;
            for(i=0;i<charEspeciales.length;i++){
                dato=dato.replaceAll(charEspeciales[i] ,"");
            }
        }


    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return dato;
}


export const valorNumero = (dato) => {
    let nomFuncion="valorNumero";

    let result=0;

    try{
        if(dato!=="") result=parseInt(dato);

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return result;
}

export const valorFloat = (dato) => {
    let nomFuncion="valorFloat";

    let result=0;

    try{
        if(dato!=="") result=parseFloat(dato);

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return result;
}

export const valorLong = (dato) => {
    let nomFuncion="valorNumero";

    let result=0;

    try{
        if(dato!=="") result=Number(dato);

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return result;
}


export const esformatoHoraDia = (texto) => {
    let nomFuncion="esformatoHoraDia";

    let tiempo=[];
    let horaFinal="";
    let txtHora, txtMin;
    let hora=0, min=0;
    let result=true;

    try{
        if(texto===null || texto==="") return "";

        tiempo=texto.split(":");
        if(tiempo.length===1){
            hora=valorNumero(tiempo[0]);
            min=0;
        }
        else if(tiempo.length===3 || tiempo.length===2){
            hora=valorNumero(tiempo[0]);
            min=valorNumero(tiempo[1]);
        }
        else{
            result=false;
        }

        if(result){
            if(hora<0 || hora>24 || min<0 || min>59){
                if(hora>24){
                    horaFinal="24:00";
                }
                result=false;
            }
            else{
                txtHora="00" + hora;
                txtHora=txtHora.substring(txtHora.length-2)+":";

                txtMin="00" + min;
                txtMin=txtMin.substring(txtMin.length-2);

                horaFinal=txtHora+txtMin;
            }
        }

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return horaFinal;
}

export const esformatoEmail = (texto) => {
    let nomFuncion="esformatoEmail";
    let respuesta=false;
    const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    try{
        texto=texto.trim();
        if (texto !== '') {
            if (isValidEmail.test(texto))  respuesta=true;
        }
    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }
    
    return respuesta;
}

export const esformatoNumero = (texto) => {
    let nomFuncion="esformatoNumero";
    let respuesta=false;
    const isValid = /^([0-9])*$/;
    
    try{
        texto=("" + texto).trim();
        if (texto !== '') {
            if (isValid.test(texto))  respuesta=true;
        }
    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }
    
    return respuesta;
}

export const operacionFechas = (tipo, txtfecha, params) => {
    let nomFuncion="operacionFechas";
    /*
    tipo
    1. sumar o restar n dias a una fecha
        params[0]: dias a sumar
    2. sumar n dias a una fecha
    3. comparar 2 fechas
    4. saber los dias de diferencia entre fecha1 y fecha2
     */

    let resultado="";
    let date1, date2;
    let dias;
    let fecha;

    try{

        if(txtfecha===null) txtfecha="";

        if(txtfecha!==""){
            fecha= new Date(txtfecha+"T00:00:00");
            //fecha.setHours(0,0,0,0);


            switch (tipo){
                case 1:
                    dias=valorNumero(params);
                    fecha.setDate(fecha.getDate() + dias);

                    let txtMes="00" + (fecha.getMonth()+1);
                    txtMes=txtMes.substring(txtMes.length-2);

                    let txtDia="00" + (fecha.getDate());
                    txtDia=txtDia.substring(txtDia.length-2);

                    resultado = fecha.getFullYear() + "-" + txtMes + "-" + txtDia

                    break;
                case 2:

                    break;
                case 3:
                    date1=fecha;
                    date2=new Date(params+"T00:00:00");
                    //date2.setHours(0,0,0,0);

                    if(date1<date2) resultado="1";
                    else if(date1>date2) resultado="2";
                    else resultado="3";

                    break;
                case 4:
                    date1=fecha;
                    date2=new Date(params+"T00:00:00");
                    //date2.setHours(0,0,0,0);

                    let diasdiferencia=parseInt((date1.getTime()-date2.getTime())/86400000);

                    if(diasdiferencia<0) diasdiferencia=-diasdiferencia;

                    resultado="" + diasdiferencia;

                    break;

                default:
                    resultado="";

            }
        }


    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return resultado;
}

export const sumarMinToHoras = (hora, minSumar) => {
    let nomFuncion="sumarMinToHoras";

    let resultado;
    let txtHora1=esformatoHoraDia(hora);
    let txtHora, txtMin;
    let dato1=[];
    let hora1, min1;
    let minutos;

    resultado=txtHora1;

    try{
        minutos=valorNumero(minSumar);

        dato1=txtHora1.split(":");

        hora1=parseInt(dato1[0]);
        min1=parseInt(dato1[1]);

        if(minutos>=0){
            min1=min1+minutos;

            while(min1>=60){
                min1=min1-60;
                hora1++;
            }

            if(hora1<26){
                txtHora="00" + hora1;
                txtHora=txtHora.substring(txtHora.length-2)+":";

                txtMin="00" + min1;
                txtMin=txtMin.substring(txtMin.length-2);

                resultado=txtHora+txtMin;
            }
        }
        else{
            min1=min1+minutos;

            while(min1<0){
                min1=min1+60;
                hora1--;
            }

            if(hora1>=0){
                txtHora="00" + hora1;
                txtHora=txtHora.substring(txtHora.length-2)+":";

                txtMin="00" + min1;
                txtMin=txtMin.substring(txtMin.length-2);

                resultado=txtHora+txtMin;
            }
        }



    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return resultado;
}

export const operacionHorasDia = (tipo, txtHora1, txtHora2) => {
    let nomFuncion="operacionHorasDia";
    /*
    tipo
    1. regresar hora menor
    2. regresar hora mayor
    3. regrear suma
    4. regresar resta
    5. comparar las 2 horas
    6. regresar % tiempo de avance de hora1 sobre hora2
     */

    let resultado="";
    let dato1=[];
    let dato2=[];
    let hora1, hora2, min1, min2;
    let horar, minr;
    let seg1, seg2, tiempo;
    let txtHorar, txtMinr;

    try{
        txtHora1=txtHora1.substring(0,5);
        txtHora2=txtHora2.substring(0,5);

        dato1=txtHora1.split(":");
        dato2=txtHora2.split(":");

        hora1=parseInt(dato1[0]);
        min1=parseInt(dato1[1]);
        hora2=parseInt(dato2[0]);
        min2=parseInt(dato2[1]);

        switch (tipo){
            case 1:
                if(hora1<hora2) resultado=txtHora1;
                else if(hora2<hora1) resultado=txtHora2;
                else{
                    if(min1<=min2) resultado=txtHora1;
                    else resultado=txtHora2;
                }

                break;
            case 2:
                if(hora1>hora2) resultado=txtHora1;
                else if(hora2>hora1) resultado=txtHora2;
                else{
                    if(min1>=min2) resultado=txtHora1;
                    else resultado=txtHora2;
                }
                break;
            case 3:
                horar=hora1+hora2;
                minr=min1+min2;

                if(minr>59){
                    horar=horar+1;
                    minr=minr-60;

                    if(horar>23){
                        horar=24;
                        minr=0;
                    }
                }

                txtHorar="00" + horar;
                txtHorar=txtHorar.substring(txtHorar.length-2)+":";

                txtMinr="00" + minr;
                txtMinr=txtMinr.substring(txtMinr.length-2);

                resultado=txtHorar+txtMinr;

                break;
            case 4:
                if(hora1>hora2){
                    horar=hora1-hora2;
                    minr=min1-min2;
                }
                else if(hora2>hora1){
                    horar=hora2-hora1;
                    minr=min2-min1;
                }
                else{
                    horar=0;
                    if(min1>=min2) minr=min1-min2;
                    else minr=min2-min1;
                }

                if(minr<0){
                    horar=horar-1;
                    minr=minr+60;
                }

                txtHorar="00" + horar;
                txtHorar=txtHorar.substring(txtHorar.length-2)+":";

                txtMinr="00" + minr;
                txtMinr=txtMinr.substring(txtMinr.length-2);

                resultado=txtHorar+txtMinr;
                break;
            case 5:
                let comparar=0;
                if(hora1<hora2) comparar=1;
                else if(hora2<hora1) comparar=2;
                else{
                    if(min1<min2) comparar=1;
                    else if(min2<min1) comparar=2;
                    else comparar=3;
                }

                resultado="" + comparar;

                break;
            case 6:
                seg1=(hora1*60)+min1;
                seg2=(hora2*60)+min2;

                tiempo=parseInt((seg1/seg2)*100);

                resultado="" + tiempo;

                break;
            
            default:
                resultado="";
        }


    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return resultado;
}

export const difHoras = (hora1, hora2 ) => {
    let nomFuncion="difHoras";

    let tiempo=[];
    let tiempo2=[];
    let min1, min2, seg1, seg2;
    let min, seg;
    let textotiempo="00:00";
    let temp="";

    try{
        tiempo=hora1.split(":");

        if(tiempo.length===3){
            min1=parseInt(tiempo[1]);
            seg1=parseInt(tiempo[2]);
        }
        else if(tiempo.length===2){
            min1=parseInt(tiempo[0]);
            seg1=parseInt(tiempo[1]);
        }
        else{
            min1=0;
            seg1=0;
        }

        tiempo2=hora2.split(":");
        if(tiempo2.length===3){
            min2=parseInt(tiempo2[1]);
            seg2=parseInt(tiempo2[2]);
        }
        else if(tiempo2.length===2){
            min2=parseInt(tiempo2[0]);
            seg2=parseInt(tiempo2[1]);
        }
        else{
            min2=0;
            seg2=0;
        }

        min=min1-min2;
        seg=seg1-seg2;

        if(min<0){
            min=0;
            seg=0;
        }
        else{
            if(seg<0){
                min=min-1;
                seg=60+seg;

                if(min<0){
                    min=0;
                    seg=0;
                }
            }
        }

        temp="00" + min;
        temp=temp.substring(temp.length-2,temp.length);

        textotiempo=temp +":";

        temp="00" + seg;
        temp=temp.substring(temp.length-2,temp.length);

        textotiempo=textotiempo + temp;

    }catch(err){
        textotiempo="00:00";
        presentarError(nomClase,nomFuncion,"",err);
    }

    return textotiempo;
}

export const difFechas = (fecha1, fecha2) => {
    let nomFuncion="difFechas";

    let textotiempo="";
    //let ano1, mes1, dia1, hora1, minuto1, segundo1;
    //let ano2, mes2, dia2, hora2, minuto2, segundo2;
    let resultado=0;
    let min, seg;
    let min2, seg2;
    let txtmin, txtseg;
    let fechaA, fechaB;
    //Calendar fechaA=new GregorianCalendar();
    //Calendar fechaB=new GregorianCalendar();

    try{
        if(fecha1.length>=19 && fecha2.length>=19){

            /*
            ano1=parseInt(fecha1.substring(0,4));
            mes1=parseInt(fecha1.substring(5,7));
            dia1=parseInt(fecha1.substring(8,10));
            hora1=parseInt(fecha1.substring(11,13));
            minuto1=parseInt(fecha1.substring(14,16));
            segundo1=parseInt(fecha1.substring(17,19));

            ano2=parseInt(fecha2.substring(0,4));
            mes2=parseInt(fecha2.substring(5,7));
            dia2=parseInt(fecha2.substring(8,10));
            hora2=parseInt(fecha2.substring(11,13));
            minuto2=parseInt(fecha2.substring(14,16));
            segundo2=parseInt(fecha2.substring(17,19));

            */

            fechaA=new Date(fecha1);
            fechaB=new Date(fecha2);

            //fechaA.set(ano1,mes1,dia1,hora1,minuto1,segundo1);
            //fechaB.set(ano2,mes2,dia2,hora2,minuto2,segundo2);

            resultado=fechaA.getTime() - fechaB.getTime();


            if(resultado>=3600000){
                textotiempo="59:59";
            }
            else if(resultado<0){
                textotiempo="00:00";
            }
            else{
                seg=((resultado)/1000);

                min=seg/60;
                min2=parseInt(min);
                //if(min>min2) min2=min2+1;

                seg2=seg - (min2*60);

                txtmin="00" + min2;
                txtmin=txtmin.substring(txtmin.length-2)+":";

                txtseg="00" + seg2;
                txtseg=txtseg.substring(txtseg.length-2);

                textotiempo=txtmin+txtseg;
            }

        }

    }catch(err){
        textotiempo="00:00";
        presentarError(nomClase,nomFuncion,"",err);
    }

    return textotiempo;
}

export const reportarError = (txtClase, txtFuncion, txtMensaje, txtCausa) => {
    const nomFuncion="reportarError";

    try{
        let idUser=recuperarDatoLocal("idUser") || "-1";

        if(isNaN(idUser)) idUser="-1";

        presentarError(txtClase,txtFuncion,txtMensaje,txtCausa);

        let parametros = "";
        parametros = valorNumero(idUser) + SEP_PARAMS;
        parametros = parametros + txtClase + SEP_PARAMS;
        parametros = parametros + txtFuncion + SEP_PARAMS;
        parametros = parametros + txtMensaje.replaceAll(SEP_PARAMS, "") + SEP_PARAMS;
        parametros = parametros + txtCausa.toString().replaceAll(SEP_PARAMS, "");

        parametros=parametros.replaceAll("'","");

        getDatos()
            .get("ejecutarSQL","registrosAPP","REPORTAR_ERRORES",parametros,"0")
            .then((res) => {
                let respuesta=res;
                if (!respuesta.exito && respuesta.conectado) {       
                    presentarError(nomClase, nomFuncion, respuesta.mensaje, ""); 
                }   
            });

    }catch(err){
        presentarError(nomClase, nomFuncion, "", err);
    }  
}

export const presentarError = (txtClase, txtFuncion, txtMensaje, error) => {
    const nomFuncion="presentarError";

    try{
        presentarLog("Clase: " + txtClase + " - Funcion: " + txtFuncion);
        presentarLog("Mensaje: " + txtMensaje);
        presentarLog(error);

    }catch(err){
        presentarLog(nomFuncion, err);
    }  
}


export const registrarLogSeguimiento = (params) => {
    const nomFuncion="registrarLogSeguimiento";

    let iduser="0";
    try{
        let sepDato="$";
        let sepRegistro="&";

        let tipouser="0";
        let idsede="0";
        let idmodulo="0";
        let idaccion="0";
        let identificador1="0";
        let identificador2="0";

        let fecha=new Date();

        let ano, mes, dia, hora, minuto, segundo;

        ano=fecha.getFullYear();
        mes=fecha.getMonth() + 1;
        dia=fecha.getDate();
        hora=fecha.getHours();
        minuto=fecha.getMinutes();
        segundo=fecha.getSeconds();


        let fechalocal=ano +"-"+mes+"-"+dia+" "+ hora+":"+minuto+":"+segundo;


        if(params!==null){
            let i;
            for(i=0;i<params.length;i++){
                if(params[i]===null) params[i]="";

                if(i===0) iduser="" + valorNumero(params[i]);
                else if(i===1){
                    if(params[i]==="superadmin") tipouser="1";
                    else if(params[i]==="administrador") tipouser="2";
                    else if(params[i]==="arbitro") tipouser="3";
                    else if(params[i]==="jugador") tipouser="4";
                    else if(params[i]==="invitado") tipouser="5";
                }
                else if(i===2) idsede="" + valorNumero(params[i]);
                else if(i===3) idmodulo="" + valorNumero(params[i]);
                else if(i===4) idaccion="" + valorNumero(params[i]);
                else if(i===5) identificador1="" + params[i];
                else if(i===6) identificador2="" + params[i];
                else i=params.length;
            }

            let texto=iduser + sepDato +
                    tipouser + sepDato +
                    idsede + sepDato +
                    idmodulo + sepDato +
                    idaccion + sepDato +
                    identificador1 + sepDato +
                    identificador2 + sepDato +
                    fechalocal;

            let logs = JSON.parse(localStorage.getItem('logs'));

        
            if(logs!=="") logs = logs + sepRegistro + texto;
            else logs = texto;

            localStorage.setItem('logs', JSON.stringify(logs));

            if(logs.length>500){                            
                enviarLog(iduser,tipouser,"");
            }

        }
    }catch (err){
        reportarError( nomClase, nomFuncion, "", err);
    }

}

export const enviarLog = (iduser, tipouser, token) => {
    const nomFuncion="enviarLog";

    try{
        
        //let sepDato="$";
        let sepRegistro="&";

        let logs = JSON.parse(localStorage.getItem('logs'));

        if(logs!==""){
            let registros=logs.split(sepRegistro);

            if(registros.length>=valorNumero(NUM_MIN_REGISTROS_LOG)){
                let params="" + VERSION_APP;
                let tipouser_final=tipouser;

                if(tipouser==="superadmin") tipouser_final="1";
                else if(tipouser==="administrador") tipouser_final="2";
                else if(tipouser==="arbitro") tipouser_final="3";
                else if(tipouser==="jugador") tipouser_final="4";
                else if(tipouser==="invitado") tipouser_final="5";

                let parametros = "";
                parametros = valorNumero(iduser) + SEP_PARAMS;
                parametros = parametros + tipouser_final + SEP_PARAMS;
                parametros = parametros + token + SEP_PARAMS;
                parametros = parametros + params + SEP_PARAMS;
                parametros = parametros + logs;

                parametros=parametros.replaceAll("'","");

                getDatos()
                    .get("ejecutarSQL","registrosAPP","REPORTAR_LOGS",parametros,"0")
                    .then((res) => {
                        let respuesta=res;
                        if(respuesta.exito){
                            localStorage.setItem('logs', JSON.stringify(""));
                        }
                        else if (respuesta.conectado) {  
                            localStorage.setItem('logs', JSON.stringify(""));     
                            reportarError(iduser, nomClase, nomFuncion, respuesta.mensaje, "");
                        }   
                    });

            }

        }

    } catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

}

export const registrarLogEventos = (idModulo,idaccion,identificador1,identificador2) => {
    const nomFuncion="registrarLogEventos";

    try{
        let params= [];

        let idUser=recuperarDatoLocal("idUser") || "-1";
        let tipoUser=recuperarDatoLocal("tipoUser") || "invitado";
        let idSede=recuperarDatoLocal("idSede") || "-1";

        params.push(idUser);
        params.push(tipoUser);
        params.push(idSede);
        params.push(idModulo);
        params.push(idaccion);
        params.push(identificador1);
        params.push(identificador2);

        registrarLogSeguimiento(params);

    }catch(err){
        presentarLog(nomFuncion, err);
    }  
}

export const dividirLista = (texto, separador, items) => {
    const nomFuncion="dividirLista";

    let lista=[];

    try{
        let datos=texto.split(separador);
        let i;

        for(i=0;i<items;i++){
            if(datos.length>i) lista.push(datos[i]);
            else lista.push("");
        }

    } catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

    return lista;

}

export const dividirListaNueva = (texto, separador) => {
    const nomFuncion="dividirListaNueva";

    let lista=[];

    try{
        let datos=texto.split(separador);
        let i;

        for(i=0;i<datos.length;i++){
            lista.push(datos[i]);
        }

    } catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

    return lista;

}

export const dinamico_fixture_vacio = () => {

    return (
    <div></div>
    );

}

export const dinamico_fixture_base = (incluir) => {

    return (
    <div style={{display:"flex", flexDirection:"column", flexGrow:"unset", flexShrink:"unset", alignItems:"center", justifyContent:"left"}}>
        {incluir}
    </div>
    );

}

export const dinamico_fixture_ganador = (equipo, escudo, linea=0) => {

    let clase="BF_Fix_BordeGanador";

    if(equipo==="" && escudo==="") clase="BF_Fix_BordePerdedor";
    if(escudo==="") escudo=ic_transparente;

    

    return (
    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginLeft:"10px"}}>
        <div style={{width:"15px", height:"100%"}}>
                    <div style={{height:"50%", fontSize:"12px", fontWeight:"bold", alignContent:"baseline", textAlign:"center"}}></div>
                    <div className='BF_Fix_Linea_Superior' style={{height:"50%"}}></div>
                </div>

        <div className={clase} style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"2px", marginBottom:"2px", marginLeft:"0px", marginRight:"0px", width:"136px"}}>
            <img className='IM_imgRedondeada28' src={escudo} alt="" style={{margin:"2px"}}/>
            <div className='TX_unaSolaLinea2' style={{width:"100px", fontSize:"12px", color:C_uniformeGrisOscuro, marginRight:"2px"}}>{formatearTexto(equipo,2)}</div>
        </div>

        {linea===0 && (<div style={{width:"25px", height:"100%"}}></div>)}

        {linea===1 && (
                    <div style={{width:"25px"}}>
                        <div style={{flex:"1", fontSize:"12px", fontWeight:"bold", alignContent:"center", textAlign:"center", minHeight:"18px"}}></div>
                        <div className='BF_Fix_Esquina_SupDer' style={{flex:"1",  alignContent:"center", textAlign:"center", fontSize:"10px", fontWeight:"bold", minHeight:"18px"}}></div>
                    </div>                
        )}

        {linea===2 && (
                    <div style={{width:"25px"}}>
                        <div className='BF_Fix_Esquina_InfDer' style={{flex:"1", fontSize:"12px", fontWeight:"bold", alignContent:"center", textAlign:"center", minHeight:"18px"}}></div>
                        <div  style={{flex:"1",  alignContent:"center", textAlign:"center", fontSize:"10px", fontWeight:"bold", minHeight:"18px"}}></div>
                    </div>                
        )}

    </div>
    );

}

export const dinamico_fixture_grupo = (padreEquiposGrupo, ganador) => {

    return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"nowrap"}}>        
        <div>{padreEquiposGrupo}</div>
        <div>{ganador}</div>
    </div>
    );

}

export const dinamico_fixture_grupo_contenedor = (grupo, ganador) => {

    return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"left", flexWrap:"nowrap", border:"0px solid red"}}>
        <div>
            {grupo}
        </div>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            {ganador}
        </div>
    </div>
    );

}

export const dinamico_fixture_grupo_equipo = (incluir, datos, nombreGrupo) => {

    let ruta=recuperarDatoLocal("rutaRecursos");
    let escudo=ic_escudo_base_gris;

    if(datos.escudo!=="") escudo = ruta + datos.escudo;


    return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"left", flexWrap:"nowrap", border:"0px solid red"}}>
        <div>
            {incluir}
        </div>
        <div style={{width:"172px", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", marginInline:"5px", border:"0px solid blue"}}> 
            {nombreGrupo!=="" && (<div className='BF_Fix_Fondo_Accent' style={{width:"158px", fontSize:"12px", fontWeight:"bold", color:C_uniformeNegro, marginTop:"10px"}}>{nombreGrupo}</div>)}
            <div className='BF_Fix_Borde_Gris' style={{width:"158px", display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"nowrap", marginTop:"3px", marginBottom:"3px"}}>
                <div style={{width:"10px", fontSize:"12px", color:C_uniformeGrisOscuro, textAlign:"center", fontWeight:"bold"}}>{datos.posicion}</div>
                <img className='IM_imgRedondeada28' src={escudo} alt="" style={{margin:"1px"}}/>
                <div className='TX_unaSolaLinea2' style={{width:"70px", fontSize:"10px", textAlign:"left", color:C_uniformeGrisOscuro, marginRight:"2px"}}>{ formatearTexto(datos.nombre,2)}</div>
                <div style={{flex:"1"}}>
                    <table width="100%" align='center'>
                        <tbody>
                            <tr style={{fontSize:"8px", color:C_uniformeNegro, fontWeight:"bold"}}>
                                <td>PJ</td><td>GD</td><td>Pts</td>
                            </tr>
                            <tr style={{fontSize:"10px", color:C_uniformeGrisOscuro}}>
                                <td>{datos.partidosJugados}</td><td>{datos.golDiferencia}</td><td>{datos.puntos}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    );

}

export const dinamico_fixture_grupo_vacio = (incluir, nombreGrupo) => {
    let clase = "BF_Fix_Borde_Accent";

    if(nombreGrupo==="") clase="BF_Fix_Borde_Transparent";

    return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"nowrap", marginInline:"5px"}}>
        <div>
            {incluir}
        </div>
        <div className={clase} style={{fontSize:"12px", fontWeight:"bold", textAlign:"center", width:"152px", height:"24px", display:"flex", justifyContent:"center", alignItems:"center", marginInline:"8px"}}>
            {nombreGrupo}
        </div>
    </div>
    );

}


export const dinamico_fixture_partidos = (incluirEq1, incluirEq2, datosEq1, datosEq2, ganador, vacio) => {

    let claseEq1="BF_Fix_BordePerdedor";
    let claseEq2="BF_Fix_BordePerdedor";

    let colorEq1=C_uniformeGrisMedio;
    let colorEq2=C_uniformeGrisMedio;

    let tGolesEq1=0;
    let tGolesEq2=0;
    let pEq1=0;
    let pEq2=0;

    if(datosEq1!==null){
        let p=datosEq1.tvPenaltis;
        p=p.replaceAll("(","");
        p=p.replaceAll(")","");
        tGolesEq1=valorNumero(datosEq1.tvGolesP1) + valorNumero(datosEq1.tvGoles);
        pEq1=valorNumero(p);
    }

    if(datosEq2!==null){
        let p=datosEq2.tvPenaltis;
        p=p.replaceAll("(","");
        p=p.replaceAll(")","");
        tGolesEq2=valorNumero(datosEq2.tvGolesP1) + valorNumero(datosEq2.tvGoles);
        pEq2=valorNumero(p);
    }   
    
    
    if(tGolesEq1>tGolesEq2) {
        claseEq1="BF_Fix_BordeGanador";
        claseEq2="BF_Fix_BordePerdedor";
        colorEq1=C_uniformeVerdeClaro;
        colorEq2=C_uniformeGrisMedio;
    }
    else if(tGolesEq1<tGolesEq2) {
        claseEq1="BF_Fix_BordePerdedor";
        claseEq2="BF_Fix_BordeGanador";
        colorEq1=C_uniformeGrisMedio;
        colorEq2=C_uniformeVerdeClaro;
    }
    else{
        if(pEq1>pEq2){
            claseEq1="BF_Fix_BordeGanador";
            claseEq2="BF_Fix_BordePerdedor";
            colorEq1=C_uniformeVerdeClaro;
            colorEq2=C_uniformeGrisMedio;
        }
        else if(pEq1<pEq2){
            claseEq1="BF_Fix_BordePerdedor";
            claseEq2="BF_Fix_BordeGanador";
            colorEq1=C_uniformeGrisMedio;
            colorEq2=C_uniformeVerdeClaro;
        }
    }



    return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"left", flexWrap:"nowrap", marginBlock:"4px", border:"0px solid red"}}>
        <div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"nowrap", marginRight:"0px"}}>
                <div>
                    <div>{incluirEq1}</div>
                </div>

                {vacio===true ? (<div style={{width:"15px", height:"100%"}}></div>) : 
                (
                <div style={{width:"15px", height:"100%", border:"0px solid blue"}}>
                    <div style={{height:"50%", fontSize:"12px", fontWeight:"bold", color:colorEq1, alignContent:"center", textAlign:"center", minHeight:"18px"}}>{datosEq1.tvGolesP1}</div>
                    <div className='BF_Fix_Linea_Superior' style={{height:"50%", minHeight:"18px"}}></div>
                </div>
                )}

                {vacio===true ? (<div style={{marginTop:"6px", marginBottom:"3px", width:"136px"}}></div>) : 
                (
                <div className={claseEq1} style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"0px", marginBottom:"0px", width:"132px"}}>
                    <img className='IM_imgRedondeada28' src={datosEq1.escudo} alt="" style={{margin:"2px"}}/>
                    <div className='TX_unaSolaLinea2' style={{width:"98px", fontSize:"12px", color:C_uniformeGrisOscuro, marginRight:"2px", textAlign:"left"}}>{formatearTexto(datosEq1.tvEquipo,2)}</div>
                </div>
                )}
                
                {vacio===true ? (<div style={{width:"25px", height:"100%"}}></div>) : 
                (
                    <div style={{width:"25px"}}>
                        <div style={{flex:"1", fontSize:"12px", fontWeight:"bold", color:colorEq1, alignContent:"center", textAlign:"center", minHeight:"18px"}}>{datosEq1.tvGoles}</div>
                        <div className='BF_Fix_Esquina_SupDer' style={{flex:"1",  alignContent:"center", textAlign:"center", fontSize:"10px", fontWeight:"bold", color:colorEq1, minHeight:"18px"}}>{datosEq1.tvPenaltis}</div>
                    </div>
                
                )}
                

            </div>
            
            {vacio===false && (
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"nowrap", marginRight:"0px"}}>
                    <div>
                        <div>{incluirEq2}</div>
                    </div>
                    <div style={{width:"15px", height:"100%"}}>                    
                        <div className='BF_Fix_Linea_Inferior' style={{height:"50%", minHeight:"18px"}}></div>
                        <div style={{height:"50%", fontSize:"12px", fontWeight:"bold", color:colorEq2, alignContent:"center", textAlign:"center", minHeight:"18px"}}>{datosEq2.tvGolesP1}</div>
                    </div>
                    <div className={claseEq2} style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"0px", marginBottom:"0px", width:"132px"}}>
                        <img className='IM_imgRedondeada28' src={datosEq2.escudo} alt="" style={{margin:"2px"}}/>
                        <div className='TX_unaSolaLinea2' style={{width:"98px", fontSize:"12px", color:C_uniformeGrisOscuro, marginRight:"2px", textAlign:"left"}}>{formatearTexto(datosEq2.tvEquipo,2)}</div>
                    </div>
                    <div style={{width:"25px", height:"100%"}}>
                    <div className='BF_Fix_Esquina_InfDer' style={{height:"50%", alignContent:"center", textAlign:"center", fontSize:"10px", fontWeight:"bold", color:colorEq2, minHeight:"18px"}}>{datosEq2.tvPenaltis}</div>
                        <div style={{height:"50%", fontSize:"12px", fontWeight:"bold", color:colorEq2, alignContent:"center", textAlign:"center", minHeight:"18px"}}>{datosEq2.tvGoles}</div>                    
                    </div>

                </div>
            )}
            
        </div>        

        <div>
            {ganador}
        </div>
    </div>
    );

}

export const encriptar = (valor) => {
    let nomFuncion="encriptar";

    let result="";

    try{
        result = CryptoJS.AES.encrypt(JSON.stringify(valor),secretKey).toString();

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return result;
}

export const desEncriptar = (valor) => {
    let nomFuncion="desEncriptar";

    let result="";

    try{        
        const bytes = CryptoJS.AES.decrypt(valor,secretKey);
        result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return result;
}

export const recuperarDatoLocal = (clave) => {
    let nomFuncion="recuperarDatoLocal";

    let result="";

    try{
        let dato=localStorage.getItem(clave);
        if(dato!==null && dato!=="") result= desEncriptar(dato);

    }catch(err){
        presentarError(nomClase,nomFuncion,"",err);
    }

    return result;
}

export const guardarDatoLocal = (clave, valor) => {

    
    localStorage.setItem(clave,encriptar(valor));
}

export const showMensajePantalla = (titulo="", mensaje="", funcionAbrir, funcionCerrar=null, tipo="NORMAL", duracion="MEDIA") => {
    let nomFuncion="showMensajePantalla"; 

    try{
        funcionAbrir(true);


        return (
            <Mensaje titulo={titulo} mensaje={mensaje} cerrar={() => {funcionCerrar===null ? (funcionAbrir(false)) : (funcionCerrar())}} duracion={duracion} tipo={tipo} />
        );


    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }         
}

export const getConfirmacion = (titulo="", mensaje="", funcionAbrir, funcionCerrar=null, funcionAceptar=null, txtFnAceptar="", txtCancelar="CANCELAR", txtAceptar="ACEPTAR") => {
    let nomFuncion="getConfirmacion"; 

    try{
        funcionAbrir(true);

        const fnAceptarFinal = () => {
            if(funcionAceptar===null) funcionAbrir(false);
            else if(txtFnAceptar!=="") funcionAceptar(txtFnAceptar);    
            else funcionAbrir(false);
        } 
        


        return (
            <Confirmar titulo={titulo} mensaje={mensaje}  
            fnCerrar={() => {funcionCerrar===null ? (funcionAbrir(false)) : (funcionCerrar())}} 
            fnAceptar={() => {fnAceptarFinal()}}
            txtCancelar={txtCancelar} txtAceptar={txtAceptar} />
        );


    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }         
}

export const superaTiempoActualizacion = (idModulo) => {
    let nomFuncion="superaTiempoActualizacion"; 

    let resultado=true;
    try{   
        let timestamp=Date.now();
        let ultAcceso="";
        ultAcceso=recuperarDatoLocal("ultAcceso" + idModulo);
        
        if(ultAcceso!==""){
            let num=valorLong(ultAcceso);
    
            let diferencia=timestamp-num;
            if(diferencia < TIEMPO_ACTUALIZACION_INFO) resultado=false;
        }
    
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }  

    return resultado;
}


export const infoGuardadaEsMismoDia = (idModulo, info) => {
    let nomFuncion="infoGuardadaEsMismoDia"; 

    let resultado=false;
    try{   
        let actual=new Date();
        actual.setTime(Date.now());
        let ultAcceso="";
        ultAcceso=recuperarDatoLocal("ultAcceso" + idModulo + info);
        
        if(ultAcceso!==""){
            let num=valorLong(ultAcceso);

            let keep=new Date();
            keep.setTime(num);

            if(actual.getFullYear()===keep.getFullYear()){
                if(actual.getMonth()===keep.getMonth()){
                    if(actual.getDay()===keep.getDay()){
                        resultado=true;
                    }
                }
            }
    
        }

    
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }  

    return resultado;
}




export const isFlagUpdate = () => {
    let nomFuncion="hayFlagUpdate"; 

    let resultado=false;
    try{   
        let r=sessionStorage.getItem("flagUpdate");

        if(r==="1"){
            resultado=true;
            sessionStorage.setItem("flagUpdate","0");

            let i;
            for(i=0;i<LISTA_MODULOS.length;i++){
                guardarDatoLocal("ultAcceso" + LISTA_MODULOS[i],"");
            }
        }
    
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }  

    return resultado;
}

export const setFlagUpdate = (valor) => {
    let nomFuncion="setFlagUpdate"; 

    try{  
        if(valor===true || valor==="1")  sessionStorage.setItem("flagUpdate","1");
        else sessionStorage.setItem("flagUpdate","0");
    
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }  

}

export const setParametros = (cantidad, parametros) => {
    let nomFuncion="setParametros"; 

    let respuesta="";
    try{  
        let SEP="#";

        let i=0;
        let linea="";
        for(i=0;i<cantidad;i++){
            if(parametros.length>i){
                if(linea!=="") linea=linea + SEP + parametros[i];
                else linea=parametros[i];
            }
        }

        respuesta=linea;
        
    
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }  
    return respuesta;

}

export const quitarTildes = (texto) => {
    let nomFuncion="quitarTildes"; 

    let respuesta="";
    try{  
        let txtTemp="";
        txtTemp=texto.normalize('NFD')
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
            .normalize();
        
        respuesta=txtTemp;
    
    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }  
    return respuesta;
}

export const generarDiasSemana = (horario) => {
    let nomFuncion="generarDiasSemana";

    let i;
    let datos=[];
    let linea;
    let horas;
    let dia, hora1, hora2, horaM;


    try{
        for(i=0;i<7;i++){
            datos.push(["" + (i+1),"00:00","00:00"]);
        }

        if(horario.length>=8){
            horario=horario.toUpperCase();
            linea=horario.split(SEP_1);

            for(i=0;i<linea.length;i++){
                if(linea[i].length>=8){
                    dia="";
                    dia=linea[i].substring(0,2);
                    horas=linea[i].substring(3).split("-");
                    if(horas.length===2){
                        hora1=esformatoHoraDia(horas[0]);
                        hora2=esformatoHoraDia(horas[1]);

                        if(hora1!=="" && hora2!==""){
                            horaM=operacionHorasDia(2,hora1,hora2);
                            if(horaM===hora2){
                                switch (dia){
                                    case "LU":
                                        datos[0][1]=hora1;
                                        datos[0][2]=hora2;
                                        break;
                                    case "MA":
                                        datos[1][1]=hora1;
                                        datos[1][2]=hora2;
                                        break;
                                    case "MI":
                                        datos[2][1]=hora1;
                                        datos[2][2]=hora2;
                                        break;
                                    case "JU":
                                        datos[3][1]=hora1;
                                        datos[3][2]=hora2;
                                        break;
                                    case "VI":
                                        datos[4][1]=hora1;
                                        datos[4][2]=hora2;
                                        break;
                                    case "SA":
                                        datos[5][1]=hora1;
                                        datos[5][2]=hora2;
                                        break;
                                    case "DO":
                                        datos[6][1]=hora1;
                                        datos[6][2]=hora2;
                                        break;
                                    default:

                                    break;                                    
                                }
                            }
                        }

                    }

                }

            }
        }


    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    } 

    return datos;
}

export const convertirHora = (hora, txtTipo) => {
    let nomFuncion="convertirHora";

    let resultado="";
    try{        
        if(hora!=="") {
            let horas = hora.split("-");

            if(txtTipo==="NORMAL"){
                let temp = horas[0].split(":");
                let temp2 = valorNumero(temp[0]);

                if(temp2>12){
                    temp2=temp2-12;
                    resultado=temp2 + " PM";
                }
                else if(temp2===12) resultado="12 M";
                else resultado=temp2 + " AM";
            }
            else if(txtTipo==="MILITAR"){
                let temp = hora.split(" ");
                if(temp.length>=2) {
                    let temp2 = valorNumero(temp[0]);
                    let tmpHora="" + temp2;

                    if(temp2<12){
                        tmpHora="0" + tmpHora;
                        tmpHora=tmpHora.substring(tmpHora.length-2);
                    }

                    if(temp[1]==="AM")  resultado = "" + tmpHora;
                    else if(temp[1]==="M")  resultado = "12";
                    else resultado = "" + (temp2 + 12);
                }
            }


        }

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    return resultado;

}

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
}

export const esAndroidSO = () => {

    let respuesta=false;
    try{
        var userAgent = navigator.userAgent.toUpperCase();
        if(userAgent.includes("ANDROID")) respuesta=true;

    }catch(err){
        
    }
    
    return respuesta;
}

export const presentarLog = (objeto) => {

    try{
        
        if(REGISTRAR_CONSOLE===true) console.log(objeto);

    }catch(err){
        
    }
}



export const getReferenciaPago = (numero) => {
    let nomFuncion="getReferenciaPago";
    let respuesta="";

    try{
        let cantOpciones=REFERENCIA_PAGOS[0].length;
        let cantBase=cantOpciones-1;

        let numMaxNivel0=cantBase;
        let numMaxNivel1=Math.pow(cantOpciones,2)+cantBase;
        let numMaxNivel2=Math.pow(cantOpciones,3)+cantBase;
        let numMaxNivel3=Math.pow(cantOpciones,4)+cantBase;
        let numMaxNivel4=Math.pow(cantOpciones,5)+cantBase;
        let numMaxNivel5=Math.pow(cantOpciones,6)+cantBase;


        let valor=valorNumero(numero);
        let divisor=1;
        let res=0;
        let diferencia=0;
        let letras="";

        if(valor>=0){
            if(valor<=numMaxNivel0){
                //console.log("Nivel 0");
                respuesta=REFERENCIA_PAGOS[0][valor];
            }
            else if(valor>numMaxNivel0 && valor <= numMaxNivel1){
                divisor=cantOpciones;

                res=Math.floor(valor/divisor);
                diferencia=valor-(res*divisor);

                /*console.log("Nivel 1");
                console.log(divisor);
                console.log(res);
                console.log(diferencia);*/

                letras=getReferenciaPago(diferencia);
                if(letras!==("")) respuesta=REFERENCIA_PAGOS[1][res-1] + letras;

            }
            else if(valor>numMaxNivel1 && valor <=numMaxNivel2){
                divisor= Math.floor(Math.pow(cantOpciones,2));

                res=Math.floor(valor/divisor);
                diferencia=valor-(res*divisor);

                /*console.log("Nivel 2");
                console.log(divisor);
                console.log(res);
                console.log(diferencia);*/

                letras=getReferenciaPago(diferencia);
                if(letras!==("")) respuesta=REFERENCIA_PAGOS[2][res-1] + letras;

            }
            else if(valor>numMaxNivel2 && valor <=numMaxNivel3){
                divisor=Math.floor(Math.pow(cantOpciones,3));

                res=Math.floor(valor/divisor);
                diferencia=valor-(res*divisor);

                /*console.log("Nivel 3");
                console.log(divisor);
                console.log(res);
                console.log(diferencia);*/

                letras=getReferenciaPago(diferencia);
                if(letras!==("")) respuesta=REFERENCIA_PAGOS[3][res-1] + letras;

            }
            else if(valor>numMaxNivel3 && valor <=numMaxNivel4){
                divisor=Math.floor(Math.pow(cantOpciones,4));

                res=Math.floor(valor/divisor);
                diferencia=valor-(res*divisor);

                /*console.log("Nivel 4");
                console.log(divisor);
                console.log(res);
                console.log(diferencia);*/

                letras=getReferenciaPago(diferencia);
                if(letras!==("")) respuesta=REFERENCIA_PAGOS[4][res-1] + letras;

            }
            else if(valor>numMaxNivel4 && valor <=numMaxNivel5){
                divisor=Math.floor(Math.pow(cantOpciones,5));

                res=Math.floor(valor/divisor);
                diferencia=valor-(res*divisor);

                /*console.log("Nivel 5");
                console.log(divisor);
                console.log(res);
                console.log(diferencia);*/

                letras=getReferenciaPago(diferencia);
                if(letras!==("")) respuesta=REFERENCIA_PAGOS[5][res-1] + letras;

            }

        }

        //console.log(respuesta);

    }catch (err){}

    return respuesta;
}


export const getParamsLink = (dato) => {

    let respuesta=["","0"];

    try{

        let datoF=("" + dato).toUpperCase();
        let max=datoF.length;
        if(max>NUM_LETRAS.length) max=NUM_LETRAS.length;

        let numTemp="";
        let correcto=true;

        respuesta[0] = datoF[0];
        
        for(let i=1;i<max;i++){
            let num=-1;
                for(let j=0;j<NUM_LETRAS[i-1].length;j++){
                    if(NUM_LETRAS[i-1][j]===datoF[i]){
                        num=j;
                        j=NUM_LETRAS[i-1].length;
                    }
                }
                if(num>=0) numTemp=numTemp + num;
                else {
                    correcto=false;
                    i=max;  
                } 
        }

        if(correcto){
            let valNum=valorLong(numTemp);

            if(valNum>0) respuesta[1]=numTemp;
        }       


    }catch(err){
        presentarLog(err);
    }

    return respuesta;
}

export const getStringLink = (tipo, id) => {

    let respuesta="";

    try{
        
        let txtNum=("" + id).trim();
        let txtTemp="";


        if(tipo.length===1 && esformatoNumero(txtNum) && valorLong(txtNum)>0){   
            

            for(let i=0;i<txtNum.length;i++){
                let valorLetra=valorNumero(txtNum[i]);
                let letra=NUM_LETRAS[i][valorLetra];

                txtTemp=txtTemp + letra;
            }

            txtTemp=tipo + txtTemp;
            txtTemp=txtTemp.toUpperCase();

            respuesta=txtTemp;

        }        

    }catch(err){
        presentarLog(err);
    }

    return respuesta;
}