import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png';
import ic_uniforme_oscuro from '../recGraficos/ic_uniforme_oscuro.png';
import ic_aceptar from '../recGraficos/ic_aceptar.png';
import ic_cancelar from '../recGraficos/ic_cancelar.png';
import ic_transparente from '../recGraficos/ic_transparente.png';
import { C_uniformeGrisOscuro, C_uniformeNegro } from '../constantes/generales';



const Template_lista_jugadorespartido = ({ datos, handleClick }) => {

    if(!datos) return null;

    let id, imagen, nombre, numCamiseta, confirmo, fechaConfirmo, llego, fechaLlego;
    let img_confirmo=ic_transparente;
    let img_llego=ic_transparente;
    let entrar=false;

    id=datos[0];
    imagen=datos[1];
    nombre=datos[2];
    numCamiseta=datos[3];
    confirmo=datos[4];
    fechaConfirmo=datos[5];
    llego=datos[6];
    fechaLlego=datos[7];
    entrar=datos[8];

    if(confirmo==="1") img_confirmo=ic_aceptar;
    if(confirmo==="2") img_confirmo=ic_cancelar;

    if(llego==="1") img_llego=ic_aceptar;
    if(llego==="2") img_llego=ic_cancelar;

 
    let stDato1={
        textTransform: "capitalize",
        verticalAlign: "center",
        align: "left",
        fontSize: "12px",
        color: C_uniformeNegro,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "pre",
        width: "50%",
        minWidth: "50px",
        maxWidth: "150px"
    }

    const clickRegistro = () => {
        handleClick(id);
    }

    const noHacerNada = () => {}

    return (
      <>
        <tr align="center" style={{cursor: entrar ? "pointer" : "", verticalAlign:"middle"}} onClick={entrar ? clickRegistro : noHacerNada}>
            <td><img className="IM_imgRedondeada36" src={imagen} alt=""/></td>
            <td align="left" style={stDato1} className="TX_unaSolaLinea2">{nombre}</td>
            <td>
                <div className='IM_imagenConTexto'>
                    <img className="IM_camiseta" src={ic_uniforme_oscuro} alt=""  />
                    <span className='IM_numeroCamiseta'>{numCamiseta}</span>
                </div>
            </td>
            <td>
                <div style={{minWidth:"40px"}}>
                    <img className="IM_imgIcono16" src={img_confirmo} alt=""/>
                    <p style={{fontSize:"10px",  color:C_uniformeGrisOscuro, lineHeight:"normal", margin:"0"}} >{fechaConfirmo}</p>
                </div>
            </td>
            <td>
                <div style={{minWidth:"40px"}}>
                    <img className="IM_imgIcono16" src={img_llego} alt=""/>
                    <p style={{fontSize:"10px",  color:C_uniformeGrisOscuro, lineHeight:"normal", margin:"0"}}>{fechaLlego}</p>
                </div>
            </td>
            <td><img className="IM_imgIcono24" src={entrar ? ic_entrar_gris : ic_transparente} alt=""/></td>
        </tr>
        <tr><td colSpan={6} align="center"><div className="BF_lineaInferior"></div></td></tr>
      </>
    );
  };
  
  export default Template_lista_jugadorespartido;