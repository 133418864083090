import { useEffect, useState } from "react";
import ShowError from "../recVisual/ShowError";
import Loader from "../recVisual/Loader";
import { getDatos } from "../gestionDatos/getDatos";
import { C_uniformeAzulClaro, C_uniformeGrisClaro, C_uniformeGrisOscuro, C_uniformeNegro, C_uniformeRojo, C_uniformeRosa, C_uniformeVerdeClaro, ID_MODULO_INFO_COBROS, ID_MODULO_PRINCIPAL_JUGADOR, SEP_4, SEPARADOR_PARAMETROS_BD } from "../constantes/generales";
import { crearTabla, dividirLista, formatearDecimal, formatearFecha, formatearTexto, getConfirmacion, guardarDatoLocal, presentarError, presentarLog, recuperarDatoLocal, registrarLogEventos, reportarError, showMensajePantalla, valorLong, valorNumero } from "../helpers/funciones";


import Select from "react-select";

import ic_salir from '../recGraficos/ic_salir_w.png';
import ic_cobros_pagar_blanco from '../recGraficos/ic_cobros_pagar_blanco.png';
import ic_escudo_base2_w from '../recGraficos/ic_escudo_base2_w.png';
import ic_entrar_gris from '../recGraficos/ic_entrar_gris.png';
import ic_cobros_configurar from '../recGraficos/ic_cobros_configurar.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_tarjeta_azul from '../recGraficos/ic_tarjeta_azul.png';
import ic_cancelar from '../recGraficos/ic_cancelar.png';
import ic_manual_primary from '../recGraficos/ic_manual_primary.png';
import ic_torneo from '../recGraficos/ic_torneo.png';
import ic_partido from '../recGraficos/ic_partido_verde.png';
import ic_evento from '../recGraficos/ic_puntos.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';


import { estilosSelect } from "../estilos/estilosSelect";
import { listaIconoDosItems } from "../recVisual/listaDesplegable";
import Template_lista_conimagen from "../gestionVisual/Template_lista_conimagen";
import DinamicoCobrosPorTipo from "../gestionVisual/DinamicoCobrosPorTipo";
import DinamicoCobrosDetalle from "../gestionVisual/DinamicoCobrosDetalle";
import { useModal } from "../helpers/useModal";
import Modal from "../recVisual/Modal";
import DinamicoCobrosPagosRealizados from "../gestionVisual/DinamicoCobrosPagosRealizados.js";


const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Info_Cobros_WB";
const idModulo=ID_MODULO_INFO_COBROS;


let idTorneo="";
let idEquipo="";
let idEquipoSel="";
let esAdminEquipo=false;
let tipoPermisoCobros="";
let regTorneo=0;

let infoTorneos=[];

let ventanaMensaje;
let titulo="";
let mensaje="";

let tipoLista="pendiente";
let nivelPermisoSel="1";



let infoConfigCobrosO = [];
let infoConfigCobros = [];
function crearInfoConfigCobros(datos){    
    const nomFuncion="crearInfoConfigCobros";

    try{        
        let configCobros = {
            idtorneo:"",
            idconfig:"",
            numconfig:"",
            nombreConfig:"",
            descripcionConfig:"",
            tipocobro:"",
            tipoevento:"",
            nivelcobro:"",
            fecha_inicio:"",
            fecha_fin:"",
            fecha_limite_pago:"",
            porc_antes_partido:"",
            dias_despues_evento:"",
            genera_bloqueo:"",
            actualDate:"",
            activo:"",
            valor:""
        }
    
        infoConfigCobrosO = [];
        infoConfigCobros = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , configCobros);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idconfig=datos[i][j];
                if(j===2) registro.numconfig=datos[i][j];
                if(j===3) registro.nombreConfig=datos[i][j];
                if(j===4) registro.descripcionConfig=datos[i][j];
                if(j===5) registro.tipocobro=datos[i][j];
                if(j===6) registro.tipoevento=datos[i][j];
                if(j===7) registro.nivelcobro=datos[i][j];
                if(j===8) registro.fecha_inicio=datos[i][j];
                if(j===9) registro.fecha_fin=datos[i][j];
                if(j===10) registro.fecha_limite_pago=datos[i][j];
                if(j===11) registro.porc_antes_partido=datos[i][j];
                if(j===12) registro.dias_despues_evento=datos[i][j];
                if(j===13) registro.genera_bloqueo=datos[i][j];
                if(j===14) registro.actualDate=datos[i][j];
                if(j===15) registro.activo=datos[i][j];
                if(j===16) registro.valor=datos[i][j];
            }

            infoConfigCobrosO.push(registro);
            infoConfigCobros.push(datos[i]);            

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoDetalleCobrosO = [];
let infoDetalleCobros = [];
let infoCobrosO = [];
let infoCobros = [];
function crearInfoDetalleCobros(datos){    
    const nomFuncion="crearInfoDetalleCobros";

    try{        
        let detalleCobros = {
            idcobro:"",
            idconfig:"",
            idequipo:"",
            idpartido:"",
            idjugador:"",
            idevento:"",
            valor_total:"",
            valor_pagado:"",
            fecha_registro:"",
            idequipoRival:"",
            fechaPartido:"",
            nombreJugador:"",
            foto:"",
            observacion:"",
            adicional1:"",
            adicional2:"",
            adicional3:""
        }

        let cobro = {
            idTorneo:"",
            idEquipo:"",
            idConfig:"",
            valorTotal:"",
            valorPagado:"",
            valorDebe:""
        }
    
        infoDetalleCobrosO = [];
        infoDetalleCobros = [];
        infoCobrosO = [];
        infoCobros = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , detalleCobros);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idcobro=datos[i][j];
                if(j===1) registro.idconfig=datos[i][j];
                if(j===2) registro.idequipo=datos[i][j];
                if(j===3) registro.idpartido=datos[i][j];
                if(j===4) registro.idjugador=datos[i][j];
                if(j===5) registro.idevento=datos[i][j];
                if(j===6) registro.valor_total=datos[i][j];
                if(j===7) registro.valor_pagado=datos[i][j];
                if(j===8) registro.fecha_registro=datos[i][j];
                if(j===9) registro.idequipoRival=datos[i][j];
                if(j===10) registro.fechaPartido=datos[i][j];
                if(j===11) registro.nombreJugador=datos[i][j];
                if(j===12) registro.foto=datos[i][j];
                if(j===13) registro.observacion=datos[i][j];
                if(j===14) registro.adicional1=datos[i][j];
                if(j===15) registro.adicional2=datos[i][j];
                if(j===16) registro.adicional3=datos[i][j];
            }
            
            infoDetalleCobrosO.push(registro);
            infoDetalleCobros.push(datos[i]);            

        }


        if(infoDetalleCobros.length>=0){
            let tempLinea= dividirLista("","|",5);
            let tempIdConfig="";
            let valorT=0;
            let valorP=0;
            for(i=0;i<infoDetalleCobros.length;i++){
                if(infoDetalleCobros[i][1]!==tempIdConfig){

                    if(tempIdConfig!==""){
                        tempLinea=dividirLista("","|",6);
                        tempLinea[0]=idTorneo;
                        tempLinea[1]=idEquipo;
                        tempLinea[2]=tempIdConfig;
                        tempLinea[3]=formatearDecimal("" + valorT,2);
                        tempLinea[4]=formatearDecimal("" + valorP,2);
                        tempLinea[5]=formatearDecimal("" + (valorT-valorP),2);

                        infoCobros.push(tempLinea);

                        let registro=Object.assign({} , cobro);
                        registro.idTorneo=idTorneo;
                        registro.idEquipo=idEquipo;
                        registro.idConfig=tempIdConfig;
                        registro.valorTotal=tempLinea[3];
                        registro.valorPagado=tempLinea[4];
                        registro.valorDebe=tempLinea[5];

                        infoCobrosO.push(registro);

                    }

                    tempIdConfig=infoDetalleCobros[i][1];
                    valorT=0;
                    valorP=0;
                }

                let tempVT=0;
                let tempVP=0;
                try{
                    tempVT= valorLong(infoDetalleCobros[i][6]);
                    tempVP= valorLong(infoDetalleCobros[i][7]);
                }
                catch (err1){}

                valorT=valorT + tempVT;
                valorP=valorP + tempVP;
            }

            if(tempIdConfig!=="") {
                tempLinea=dividirLista("","|",5);
                tempLinea[0] = idTorneo;
                tempLinea[1] = idEquipo;
                tempLinea[2] = tempIdConfig;
                tempLinea[3] = formatearDecimal("" + valorT,2);
                tempLinea[4] = formatearDecimal("" + valorP,2);
                tempLinea[5]=formatearDecimal("" + (valorT-valorP),2);

                infoCobros.push(tempLinea);

                let registro=Object.assign({} , cobro);
                registro.idTorneo=idTorneo;
                registro.idEquipo=idEquipo;
                registro.idConfig=tempIdConfig;
                registro.valorTotal=tempLinea[3];
                registro.valorPagado=tempLinea[4];
                registro.valorDebe=tempLinea[5];

                infoCobrosO.push(registro);
            }

        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoEquiposO = [];
let infoEquipos = [];
function crearInfoEquipos(datos){    
    const nomFuncion="crearInfoEquipos";

    try{        
        let equipo = {
            idtorneo:"",
            idequipo:"",
            nombrelargo:"",
            escudo:"",
        }
    
        infoEquiposO = [];
        infoEquipos = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , equipo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idequipo=datos[i][j];
                if(j===2) registro.nombrelargo=datos[i][j];
                if(j===3) registro.escudo=datos[i][j];

            }
            infoEquiposO.push(registro);
            infoEquipos.push(datos[i]);            

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}



let infoJugadoresEqO = [];
let infoJugadoresEq = [];
function crearInfoJugadoresEq(datos){    
    const nomFuncion="crearInfoJugadoresEq";

    try{        
        let jugador = {
            idjugador:"",
            foto:"",
            nombre:"",
            sinInfo:"",
            activo:""
        }
    
        infoJugadoresEqO = [];
        infoJugadoresEq = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            //registro.indice=i;
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.foto=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.sinInfo=datos[i][j];
                if(j===4) registro.activo=datos[i][j];
            }
            infoJugadoresEqO.push(registro);
            infoJugadoresEq.push(datos[i]);

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


let infoDetallePagosO = [];
let infoDetallePagos = [];
function crearInfoDetallePagos(datos){    
    const nomFuncion="crearInfoDetallePagos";

    try{        
        let pago = {
            idpago:"",
            fecha:"",
            valor:"",
            idequipo:"",
            concepto:"",
            idJugador:"",
            nomJugador:"",
            foto:""
        }
    
        infoDetallePagosO = [];
        infoDetallePagos = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , pago);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idpago=datos[i][j]; 
                if(j===1) registro.fecha=datos[i][j]; 
                if(j===2) registro.valor=datos[i][j]; 
                if(j===3) registro.idequipo=datos[i][j];
                if(j===4) registro.concepto=datos[i][j];
                if(j===5) registro.idJugador=datos[i][j];
                if(j===6) registro.nomJugador=datos[i][j];
                if(j===7) registro.foto=datos[i][j];
            }

            infoDetallePagosO.push(registro);
            infoDetallePagos.push(datos[i]);            

        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}


const Info_Cobros = ({params="", definirModulo, modOrigen=0, paramOrigen=""}) => {
    const [esError, setEsError] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);

    const [selTorneos, setSelTorneos] = useState([]);
    const [numTorneoSel, setNumTorneoSel] = useState(0);
    const [esAdminEquipoUS, setEsAdminEquipoUS] = useState(false);

    const [infoEquipoUS, setInfoEquipoUS] = useState({txtPrincipal:"", txtSecundario:"", imagen:""});
    const [infoCobrosTorneoUS, setInfoCobrosTorneoUS] = useState([]);
    const [infoCobrosPartidoUS, setInfoCobrosPartidoUS] = useState([]);
    const [infoCobrosEventoUS, setInfoCobrosEventoUS] = useState([]);
    const [detCobrosTorneoUS, setDetCobrosTorneoUS] = useState([]);
    const [detPagosUS, setDetPagosUS] = useState([]);

    const [tipoListaUS, setTipoListaUS] = useState("pendiente");
    const [idConfigSel, setIdConfigSel] = useState("");

    const [isOpenDetPagos, openDetPagos, closeDetPagos] = useModal(false);
    const [isOpenConfigPermiso, openConfigPermiso, closeConfigPermiso] = useModal(false);

    const [imagenTCVerPagosUS, setImagenTCVerPagosUS] = useState("");
    const [imagenTEVerPagosUS, setImagenTEVerPagosUS] = useState("");
    const [nomCobroVerPagos, setNomCobroVerPagos] = useState("");
    const [regDetCobroSel, setRegDetCobroSel] = useState(0);
    
    const [nivelPermisoUS, setNivelPermisoUS] = useState("1");

    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{          

            //let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);

            if(params!==""){
                let actualizar=true;
                let info=dividirLista(params,SEP_4,5);
                
                idTorneo=info[0];
                idEquipo=info[1];
                esAdminEquipo=info[2];
                tipoPermisoCobros=info[3];

                let tmpInfoTorneos=recuperarDatoLocal("infoTorneos");

                if(tmpInfoTorneos!==""){
                    infoTorneos=JSON.parse(tmpInfoTorneos);

                    let tmpDatos=[];
                    let dato={ value:"", label:"", texto2:"", imagen:""};
                    if(infoTorneos.length>0){
                        let cont=0;
                        let regEquipo=0;

                        for (let i = 0; i < infoTorneos.length; i++) {
                            if(infoTorneos[i][4]==="1" || (infoTorneos[i][4]==="0" && infoTorneos[i][8]!=="1")) {
                                let registro=Object.assign({} , dato);

                                if (idTorneo!=="") {
                                    if (infoTorneos[i][0]===idTorneo) {
                                        regEquipo=i;
                                        regTorneo=i;
                                        setNumTorneoSel(cont);
                                        setNivelPermisoUS(infoTorneos[i][8]);
                                        tipoPermisoCobros=infoTorneos[i][8];
                                        if(infoTorneos[i][4]==="1") esAdminEquipo=true;
                                    }
                                }

                                registro.value= "" + i;
                                registro.label=infoTorneos[i][1];
                                registro.texto2="Sede: " + infoTorneos[i][10];
                                if(infoTorneos[i][7]!=="") registro.imagen=ruta + infoTorneos[i][7];

                                tmpDatos.push(registro);
    
                                cont++;
                            }
                        }                

                        idEquipoSel=idEquipo;
                        setEsAdminEquipoUS(esAdminEquipo);
                        setSelTorneos(tmpDatos);
                    }

                    if(actualizar){
                        BD_solicitarDatos("INFO_INICIAL",[]);    
                    }
                    else{
                        //cargarDatosGuardadosModulo();
                    }
                }
                else{
                    ventanaMensaje=showMensajePantalla("SIN INFORMACIÓN","No se recupero la información de sus torneos, por favor intente nuevamente o contacte la línea de soporte",setShowMensaje,salir,"ERROR","");
                }
                
                

                /*paramsAjustado=idTorneo + SEP_4 + idEquipo + SEP_4 + esAdminEquipo + SEP_4 + tipoPermisoCobros;
                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                }*/
                
                //guardarDatoLocal("ultParametros" + idModulo, idTorneo + SEP_4 + idEquipo);

                
                
                
            }
    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [params]);


    if(params==="") return null;


    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            
            if(actualizar){                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getListaCobros";
                    consulta="INFO_DETALLE_INFO_COBROS";
                    
                    paramQuery= idTorneo + SEP + idEquipo;
                } else if(tipoDatos==="INFO_DETALLE_PAGOS"){
                    tipoConsulta="consultarTabla";
                    funcion="getListaCobros";
                    consulta="INFO_DETALLE_PAGOS";

                    let idCobro=params;
                    
                    paramQuery= idCobro;
                } else if(tipoDatos==="ASIGNAR_NIVEL_PERMISO_COBROS"){
                    tipoConsulta="ejecutarSQL";
                    funcion="actualizarCobrosPagos";
                    consulta="ASIGNAR_NIVEL_PERMISO_COBROS";
                    mostrarProcesando=false;

                    let nivelPermiso=nivelPermisoUS;
                    
                    paramQuery= idTorneo + SEP + idEquipo + SEP + nivelPermiso;
                } 
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
    
                                guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                //guardarDatoLocal("datos" + idModulo, respuesta.datos);
    
                                crearInfoConfigCobros(crearTabla(respuesta.datos,"2"));
                                crearInfoDetalleCobros(crearTabla(respuesta.datos,"3"));
                                crearInfoEquipos(crearTabla(respuesta.datos,"4")); 
                                crearInfoJugadoresEq(crearTabla(respuesta.datos,"5")); 
    
        
                                presentarCobrosEquipoSel();

                            
                                registrarLogEventos(idModulo, "1", idTorneo,idEquipo);
                            } else if(tipoDatos==="INFO_DETALLE_PAGOS"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
        
                                crearInfoDetallePagos(crearTabla(respuesta.datos,"1"));

                                presentarListaPagos();    
                            
                                registrarLogEventos(idModulo, "4", idEquipo,params);
                            } else if(tipoDatos==="ASIGNAR_NIVEL_PERMISO_COBROS"){
                                tipoPermisoCobros=nivelPermisoUS;

                                titulo="";
                                mensaje="Información actualizada con éxito";
                                ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,null,"EXITO","BAJA");

                                registrarLogEventos(idModulo, "6",idEquipo,tipoPermisoCobros);
                            }                              
            
                        } else {
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                if(mostrarError) setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            }                       
                        }

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 


    const clickGenerico = (donde, params=null) => {
        let nomFuncion="clickGenerico"; 

        try{
            
            let tempArreglo=null;
            
            switch(donde) {
                  
                case "CLICK_TIPOCOBRO":
                    

                    if(esAdminEquipo || valorNumero(tipoPermisoCobros)>2){
                        setIdConfigSel(params[0]);
                        presentarDetalleTipoCobro(params[0], params[1]);
    
                        let regTipoCobroSel=params[1];
                        if(regTipoCobroSel>=0){
                            setImagenTEVerPagosUS("");
                            setNomCobroVerPagos(infoConfigCobrosO[regTipoCobroSel].nombreConfig);
                            if(infoConfigCobrosO[regTipoCobroSel].tipocobro==="1") setImagenTCVerPagosUS(ic_torneo);
                            if(infoConfigCobrosO[regTipoCobroSel].tipocobro==="2") setImagenTCVerPagosUS(ic_partido);
                            if(infoConfigCobrosO[regTipoCobroSel].tipocobro==="3"){
                                setImagenTCVerPagosUS(ic_evento);
                                if(infoConfigCobrosO[regTipoCobroSel].tipoevento==="1") setImagenTEVerPagosUS(ic_amarilla);
                                if(infoConfigCobrosO[regTipoCobroSel].tipoevento==="2") setImagenTEVerPagosUS(ic_tarjeta_roja);
                                if(infoConfigCobrosO[regTipoCobroSel].tipoevento==="3") setImagenTEVerPagosUS(ic_cancelar);
                                if(infoConfigCobrosO[regTipoCobroSel].tipoevento==="4") setImagenTEVerPagosUS(ic_manual_primary);
                                if(infoConfigCobrosO[regTipoCobroSel].tipoevento==="5") setImagenTEVerPagosUS(ic_tarjeta_azul);
                            }
                        }
                    }
                    else{
                        ventanaMensaje=showMensajePantalla("NO AUTORIZADO","No tiene permisos suficientes para ver información detallada. Solicite acceso a información detallada al delegado del equipo",setShowMensaje,null,"INFO","MEDIA");
                    }
                    
                    
                    break; 
                case "CLICK_DETCOBRO":
                    let tmpDet=JSON.parse(JSON.stringify(detCobrosTorneoUS));                    

                        if(tmpDet.length>0){
                            for(let i=0;i<tmpDet.length;i++){
                                if(tmpDet[i].indice===params){
                                    tmpDet[i].menu=true;
                                }
                                else tmpDet[i].menu=false;
                            }

                            setDetCobrosTorneoUS(tmpDet);
                        }
                
                        
                    break;  
                case "CLICK_DET_VERPAGOS":
                    if(esAdminEquipo || valorNumero(tipoPermisoCobros)>3){
                        setRegDetCobroSel(params); 

                        let tmpDet2=JSON.parse(JSON.stringify(detCobrosTorneoUS));
                        tmpDet2[params].menu=false;
                        setDetCobrosTorneoUS(tmpDet2);


                        BD_solicitarDatos("INFO_DETALLE_PAGOS",tmpDet2[params].datosCobro[0]);  

                        openDetPagos();
                    }
                    else{
                        ventanaMensaje=showMensajePantalla("NO AUTORIZADO","No tiene permisos suficientes para ver información detallada. Solicite acceso a información detallada al delegado del equipo",setShowMensaje,null,"INFO","MEDIA");
                    }
                
                    
                    break;    
                case"CONFIRMAR_NIVEL_PERMISO_COBROS":
                    closeConfigPermiso();
                    if(tipoPermisoCobros!==nivelPermisoUS){
                        
                        titulo="CONFIRMAR CAMBIO";
                        mensaje="Esta Acción Modificará el detalle de la información de Cobros/Pagos que podrán ver los Jugadores del Equipo.\n\nEsta de Acuerdo?";
                        ventanaMensaje=getConfirmacion(titulo,mensaje,setShowMensaje,null,clickGenerico,"ASIGNAR_NIVEL_PERMISO_COBROS");
                    }                    
                    
                    break;
                case "ASIGNAR_NIVEL_PERMISO_COBROS":
                    setShowMensaje(false);
                    closeConfigPermiso();

                    BD_solicitarDatos("ASIGNAR_NIVEL_PERMISO_COBROS");  
                
                    break;
                default:
                    break;
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 


    const cambiarTorneo = (selectedOption) => {
        let nomFuncion="cambiarTorneo"; 

        try{

            if(selectedOption!==null) {
                //let position=selectedOption.index;
                let position= valorNumero(selectedOption.value);                

                if(infoTorneos[position][0]!==idTorneo){
                    setIdConfigSel("");
                    setNumTorneoSel(position);
                    setNivelPermisoUS(infoTorneos[position][8]);

                    tipoPermisoCobros=infoTorneos[position][8];
                    idTorneo=infoTorneos[position][0];
                    idEquipo=infoTorneos[position][2];
                    idEquipoSel=idEquipo;
                    if(infoTorneos[position][4]==="1") esAdminEquipo=true;
                    else esAdminEquipo=false;
                    regTorneo=position;

                    setEsAdminEquipoUS(esAdminEquipo);

                    BD_solicitarDatos("INFO_INICIAL",[]);    
                }
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const presentarCobrosEquipoSel = () => {
        let nomFuncion="presentarCobrosEquipoSel"; 

        try{

            let tempInfoEquipo={
                txtPrincipal: infoTorneos[regTorneo][3],
                txtSecundario: infoTorneos[regTorneo][1],
                imagen: infoTorneos[regTorneo][16] !=="" ? (ruta + infoTorneos[regTorneo][16]) : ic_escudo_base2_w 
            };

            setInfoEquipoUS(tempInfoEquipo);
            
            //Crea arreglos con el resumen por tipo de cobro
            if(true){

                let tmpCobrosTorneo=[];
                let tmpCobrosPartido=[];
                let tmpCobrosEvento=[]; 

                let contTorneo=0;
                let contPartido=0;
                let contEvento=0;

                if(infoCobros.length>0){

                    let i;
                    for(i=0;i<infoCobros.length;i++){
    
                        if(infoCobros[i][0]===idTorneo && infoCobros[i][1]===idEquipoSel){
                            //final int reg=i;
    
                            let datos=dividirLista("","|",8);
    
                            let idConf=buscarRegistroConfig(infoCobros[i][2]);
                            if(idConf>=0){
                                datos[0]=infoConfigCobros[idConf][3];
                                datos[1]="$ " + infoCobros[i][3];
                                datos[2]="$ " + infoCobros[i][4];
                                datos[3]="$ " + infoCobros[i][5];
                                datos[5]= false;

                                let imagen="";
                                if(infoConfigCobros[idConf][5]==="3"){
                                    if(infoConfigCobros[idConf][6]===("1")) imagen=ic_amarilla;
                                    if(infoConfigCobros[idConf][6]===("2")) imagen=ic_tarjeta_roja;
                                    if(infoConfigCobros[idConf][6]===("3")) imagen=ic_cancelar;
                                    if(infoConfigCobros[idConf][6]===("4")) imagen=ic_manual_primary;
                                    if(infoConfigCobros[idConf][6]===("5")) imagen=ic_tarjeta_azul;
                                }
                                datos[4]=imagen;

                                datos[6]=infoCobros[i][2]; //idConfigSel
                                datos[7]=idConf; //regConfig
    
                                let incluirLinea=false;
                                if(infoConfigCobros[idConf][5]==="1"){
                                    if(contTorneo>0) datos[5]=true;
                                    contTorneo++;

                                    tmpCobrosTorneo.push(datos);
                                } 
                                if(infoConfigCobros[idConf][5]==="2"){
                                    if(contPartido>0) datos[5]=true;
                                    contPartido++;

                                    tmpCobrosPartido.push(datos);
                                } 
                                if(infoConfigCobros[idConf][5]==="3"){
                                    if(contEvento>0) datos[5]=true;
                                    contEvento++;

                                    tmpCobrosEvento.push(datos);
                                }     
                                    
    
                            }
    
                        }
    
                    }
    
    
    
                }

                setInfoCobrosTorneoUS(tmpCobrosTorneo);
                setInfoCobrosPartidoUS(tmpCobrosPartido);
                setInfoCobrosEventoUS(tmpCobrosEvento);
            }
            
            
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const presentarDetalleTipoCobro = (idConfigSel, regConfig) => {
        let nomFuncion="presentarDetalleTipoCobro"; 

        try{

            let tmpDetCobros=[];
            
            //Crea arreglos con el resumen por tipo de cobro
            if(true){

                let contador=1;
                let mostrar=1;
                let indice=0;

                if(infoDetalleCobros.length>0){
    
                    let i;
                    for(i=0;i<infoDetalleCobros.length;i++){
    
                        if(infoDetalleCobros[i][1]===idConfigSel && infoDetalleCobros[i][2]===idEquipoSel){
                            //final int reg=i;
                            //int mostrar=1;
                            let incluir=true;
    
                            let puedeVerPagos=false;
                            let puedeVerDistribucion=false;
    
                            //if(esAdminEquipo || tipoPermiso>3) puedeVerPagos=true;
                            //if(esAdminEquipo || tipoPermiso>4) puedeVerDistribucion=true;

                            let tempVT=valorNumero(infoDetalleCobros[i][6]);
                            let tempVP=valorNumero(infoDetalleCobros[i][7]);

                            //if(tempVT<=tempVP && tipoLista==="pendiente") incluir=false;

                            if(incluir){
                                if(tipoLista==="pendiente") mostrar=1;
                                else if(tipoLista==="todos") mostrar=2;
                                else mostrar=3;
        
                                let escudo="";
                                let nomContrincante="";
        
                                if(infoDetalleCobros[i][9]!==("")){
                                    let tempEq=buscarDatosEquipo(infoDetalleCobros[i][9]);
                                    escudo=tempEq[0];
                                    nomContrincante=tempEq[1];
                                }
    
                                let tmpObj={
                                    indice: indice,
                                    mostrar:mostrar,
                                    contador:contador,
                                    escudo:escudo,
                                    nomContrincante:nomContrincante,
                                    datosCobro:infoDetalleCobros[i],
                                    datosConfig: infoConfigCobros[regConfig],
                                    menu:false
                                }
    
                                tmpDetCobros.push(tmpObj);
                                indice++;
                                    
        
                                contador++;
                            }
    
                            
                        }
    
                    }
    
    
    
                }
            }
            
            setDetCobrosTorneoUS(tmpDetCobros);
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }

    const presentarListaPagos = () => {
        let nomFuncion="presentarListaPagos"; 

        try{

            let tmpDetPagos=[];
            
            if(infoDetallePagos.length>0){

                let numPagos=1;
                let incluirLinea=false;
                let i;
                for(i=0;i<infoDetallePagos.length;i++){

                    let datos = {
                        tieneLinea:false,
                        contador:numPagos,
                        fecha:"",
                        valor:"",
                        escudo:"",
                        quienPago:"Equipo"
                    }

                    if(i>0) datos.tieneLinea=true;
                    datos.fecha = formatearFecha(infoDetallePagos[i][1],3);
                    datos.valor = "$ " + formatearDecimal(infoDetallePagos[i][2],2);

                    let tempQuienPaga="";
                    if(valorNumero(infoDetallePagos[i][3])>0){
                        tempQuienPaga="Equipo";
                        if(infoDetallePagos[i][4]!==("")) tempQuienPaga=tempQuienPaga + " - " + formatearTexto(infoDetallePagos[i][4],2);

                        datos.escudo=infoEquipoUS.imagen;                        
                    }
                    else{
                        tempQuienPaga=formatearTexto(infoDetallePagos[i][6],2);

                        datos.escudo=ic_jugadorsinimagen;
                        if(infoDetallePagos[i][7]!==("")) datos.escudo = ruta + infoDetallePagos[i][7];  
                    }

                    datos.quienPago=tempQuienPaga;

                    tmpDetPagos.push(datos);

                    numPagos++;
                }

            }
            
            
            setDetPagosUS(tmpDetPagos);
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
    }



    const buscarRegistroConfig = (idConf) => {
        let nomFuncion="buscarRegistroConfig";

        let resultado=-1;
        try{
            if(infoConfigCobros.length>0){
                let i;
                for(i=0;i<infoConfigCobros.length;i++){
                    if(infoConfigCobros[i][1]===idConf){
                        resultado=i;
                        i=infoConfigCobros.length;
                    }
                }

            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }

        return resultado;
    }

    const buscarDatosEquipo = (idEq) => {
        let nomFuncion="buscarDatosEquipo";

        let resultado=dividirLista("","|", 2);
        try{
            if(infoEquipos.length>0){
                let i;
                for(i=0;i<infoEquipos.length;i++){
                    if(infoEquipos[i][1]===idEq){
                        resultado[0]=infoEquipos[i][3];
                        resultado[1]=infoEquipos[i][2];
                        i=infoEquipos.length;
                    }
                }

            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }

        return resultado;
    }


    const salir = () => {       
        definirModulo(0,"","",ID_MODULO_PRINCIPAL_JUGADOR,recuperarDatoLocal("idUser"));   
    }


    return(<>
    
        {showMensaje && (ventanaMensaje)}


        {isOpenDetPagos && (
                <Modal isOpen={isOpenDetPagos} closeModal={closeDetPagos}  titulo={"Ver Detalle Pagos"} txtAceptar='' txtCancelar='' isCancelable={false}>
                    <div style={{marginBlock:"20px", marginInline:"10px", minWidth:"300px"}}>

                    
                        {Template_lista_conimagen(infoEquipoUS.txtPrincipal, infoEquipoUS.txtSecundario,infoEquipoUS.imagen)}
                     

                        <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"900px", marginBlock:"10px", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}}>
                                <img className="IM_imgIcono24" src={imagenTCVerPagosUS} style={{marginInline:"4px"}} alt=""/> 
                                <div className='BF_cajaBlanca ' style={{flex:"1", alignItems:"center", alignContent:"center", minHeight:"30px"}}>       
                                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", marginBlock:"2px"}}  >
                                    {imagenTEVerPagosUS!=="" && (<img className="IM_imgIcono24" src={imagenTEVerPagosUS} alt="" />)}
                                    <div style={{flex:"1", textAlign:"left", fontSize:"14px", color:C_uniformeGrisOscuro}}>{nomCobroVerPagos}</div>                                    
                                </div>        
                                </div>
                            </div>

                        
                        <DinamicoCobrosDetalle datos={detCobrosTorneoUS[regDetCobroSel]} tipoLista={tipoListaUS}  />
                       
                        <div className="BF_cajaAccent" style={{marginBlock:"10px", marginInline:"0px"}}>
                            <div className='BF_cajaBlanca ' style={{flex:"1", height:"250px", overflow:"auto", paddingBlock:"10px", paddingInline:"4px"}}>

                                {detPagosUS.length>0 && (
                                    <>{(detPagosUS.map((reg, index) => (<DinamicoCobrosPagosRealizados key={index} datos={reg}  />))  )} </>
                                )}  
                                {detPagosUS.length===0 && (
                                    <p className="TX_sinInfo" style={{textAlign:"center"}}>No Hay Pagos Registrados</p>  
                                )} 

                            </div>
                        </div>
                       

                    </div>  
                </Modal>
        )}

        {isOpenConfigPermiso && (
                <Modal isOpen={isOpenConfigPermiso} closeModal={closeConfigPermiso} imgModal={ic_cobros_configurar}  titulo={"Asignar Nivel de Acceso"} clickAceptar={() => clickGenerico("CONFIRMAR_NIVEL_PERMISO_COBROS")}>
                    <div style={{marginBlock:"20px", marginInline:"10px", minWidth:"300px"}}>

                    
                        <div style={{fontSize:"14px", color: C_uniformeGrisOscuro, marginBottom:"20px"}}>
                            {"Como Administrador del Equipo puede definir el Nivel de acceso a la Información de Cobros/Pagos que tendrán los Jugadores del Equipo."}
                        </div>
                       
                        <div  style={{fontSize:"14px", color: C_uniformeNegro, textAlign:"left"}}>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer", marginBottom:"10px"}}  onClick={() => {setNivelPermisoUS("1")}}>
                                        <input type="radio" value="1" name="nivelPermiso" style={{marginRight: "10px"}} checked={nivelPermisoUS==="1"} onChange={() => {setNivelPermisoUS("1")}}/>
                                        <div>{"NIVEL 1. Jugador SOLO puede ver la información de sus propios cobros y pagos."}</div>
                                    </div>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer", marginBottom:"10px"}}  onClick={() => {setNivelPermisoUS("2")}}>
                                        <input type="radio" value="2" name="nivelPermiso" style={{marginRight: "10px"}} checked={nivelPermisoUS==="2"} onChange={() => {setNivelPermisoUS("2")}}/>
                                        <div>{"NIVEL 2. Ver Total Cobrado y Pagado en el Torneo por TIPO de Cobro."}</div>
                                    </div>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer", marginBottom:"10px"}}  onClick={() => {setNivelPermisoUS("3")}}>
                                        <input type="radio" value="3" name="nivelPermiso" style={{marginRight: "10px"}} checked={nivelPermisoUS==="3"} onChange={() => {setNivelPermisoUS("3")}}/>
                                        <div>{"NIVEL 3. Ver Total Cobrado y Pagado por cada Cobro realizado en el Torneo."}</div>
                                    </div>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer", marginBottom:"10px"}}  onClick={() => {setNivelPermisoUS("4")}}>
                                        <input type="radio" value="4" name="nivelPermiso" style={{marginRight: "10px"}} checked={nivelPermisoUS==="4"} onChange={() => {setNivelPermisoUS("4")}}/>
                                        <div>{"NIVEL 4. Ver Detalle de Pagos realizados para cada Cobro."}</div>
                                    </div>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer", marginBottom:"10px"}}  onClick={() => {setNivelPermisoUS("5")}}>
                                        <input type="radio" value="5" name="nivelPermiso" style={{marginRight: "10px"}} checked={nivelPermisoUS==="5"} onChange={() => {setNivelPermisoUS("5")}}/>
                                        <div>{"NIVEL 5. Ver Todo el detalle de los cobros/Pagos (Distribución y pago por cada jugador)."}</div>
                                    </div>
                        </div>

                    </div>  
                </Modal>
        )}



        <div className="CT_zonaFija">
                <div className="CT_encabezado">
                    <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px", cursor:"pointer"}} />  </div>
                    <div className="CT_elementoEncabezado"><img className="IM_imgIcono28" height="36px" width="36px" src={ic_cobros_pagar_blanco} alt="" /></div>
                    <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{"Gestión de Cobros - Pagos"}</p></div>
                </div>
            </div>


        {!loading && esError===0 && (
                <div style={{marginInline:"10px", paddingTop:"70px", paddingBottom:"20px"}}>  

                    {selTorneos.length>0 &&  (   
                        <div className="BF_botonBordePrimary" style={{backgroundColor:"white", maxWidth:"100vw"}}>           
                        <Select     
                            styles={estilosSelect}
                            defaultValue={selTorneos[numTorneoSel]}
                            formatOptionLabel={listaIconoDosItems}
                            options={selTorneos}
                            isSearchable={false}   
                            onChange={(e,index) => {cambiarTorneo(e,index)}}            
                        />   
                        </div>                 
                    )}  

                    <div style={{marginTop:"20px"}}>
                    {Template_lista_conimagen(infoEquipoUS.txtPrincipal, infoEquipoUS.txtSecundario,infoEquipoUS.imagen)}
                    </div>

                    {esAdminEquipoUS && (
                        <div style={{display:"flex", alignItems:"center", padding:"2px", marginTop:"10px", cursor:"pointer", marginInline:"15px"}} onClick={() => {setNivelPermisoUS(tipoPermisoCobros); openConfigPermiso()}}>
                            <img className="IM_imgRedondeada28" src={ic_cobros_configurar} alt=""/> 
                            <div style={{fontSize:"14px", color: C_uniformeGrisOscuro, marginInline:"6px"}}>{"Configurar Acceso a Cobros/Pagos"}</div>   
                            <img className="IM_imgIcono24" src={ic_entrar_gris} alt=""/>
                        </div>
                    )}


                    <div style={{display:"flex", flexWrap:"wrap", gap:"20px", justifyContent:"center", alignItems:"start"}}>

                        <div style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"300px", maxWidth:"900px", alignItems:"center"}}>
                            <div style={{display:"flex", flexWrap:"nowrap", marginTop:"20px", gap:"15px", marginRight:"20px", marginBottom:"4px"}}>
                                <div style={{flex:"1"}}></div>
                                <div style={{fontSize:"12px", color:C_uniformeNegro, fontWeight:"bold"}}>{"Total"}</div>
                                <div style={{fontSize:"12px", color:C_uniformeVerdeClaro}}>{"Pagado"}</div>
                                <div style={{fontSize:"12px", color:C_uniformeRojo}}>{"Debe"}</div>
                            </div>

                            <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"900px", marginInline:"4px", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}}>
                                <img className="IM_imgIcono36" src={ic_torneo} style={{marginInline:"4px"}} alt=""/> 
                                <div className='BF_cajaBlanca ' style={{flex:"1", minHeight:"40px", alignItems:"center", alignContent:"center"}}>       
                                    {infoCobrosTorneoUS.length>0 && (
                                        <>{(infoCobrosTorneoUS.map((reg, index) => (<DinamicoCobrosPorTipo key={index} datos={reg} seleccionado={reg[6]===idConfigSel ? true:false} eventoClick={clickGenerico} />))  )} </>
                                    )}  
                                    {infoCobrosTorneoUS.length===0 && (
                                        <p className="TX_sinInfo" style={{textAlign:"left"}}>No hay Cobros a Nivel Torneo</p>  
                                    )}        
                                </div>
                            </div> 

                            <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"900px", marginTop:"10px", marginInline:"4px", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}}>
                                <img className="IM_imgIcono36" src={ic_partido} style={{marginInline:"4px"}} alt=""/> 
                                <div className='BF_cajaBlanca ' style={{flex:"1", minHeight:"40px", alignItems:"center", alignContent:"center"}}>       
                                    {infoCobrosPartidoUS.length>0 && (
                                        <>{(infoCobrosPartidoUS.map((reg, index) => (<DinamicoCobrosPorTipo key={index} datos={reg} seleccionado={reg[6]===idConfigSel ? true:false} eventoClick={clickGenerico} />))  )} </>
                                    )}  
                                    {infoCobrosPartidoUS.length===0 && (
                                        <p className="TX_sinInfo" style={{textAlign:"left"}}>No hay Cobros a Nivel Partido</p>  
                                    )}        
                                </div>
                            </div> 
                            
                            <div className='BF_cajaGris' style={{minWidth:"250px", maxWidth:"900px", marginTop:"10px", marginInline:"4px", display:"flex", flexWrap:"nowrap", alignItems:"center", justifyContent:"center"}}>
                                <img className="IM_imgIcono36" src={ic_evento} style={{marginInline:"4px"}} alt=""/> 
                                <div className='BF_cajaBlanca ' style={{flex:"1", minHeight:"40px", alignItems:"center", alignContent:"center"}}>       
                                    {infoCobrosEventoUS.length>0 && (
                                        <>{(infoCobrosEventoUS.map((reg, index) => (<DinamicoCobrosPorTipo key={index} datos={reg} seleccionado={reg[6]===idConfigSel ? true:false} eventoClick={clickGenerico} />))  )} </>
                                    )}  
                                    {infoCobrosEventoUS.length===0 && (
                                        <p className="TX_sinInfo" style={{textAlign:"left"}}>No hay Cobros a Nivel Evento</p>  
                                    )}        
                                </div>
                            </div>
                        </div>


                        {idConfigSel!=="" && (
                            <div style={{flex:"1", flexGrow:"1", flexShrink:"1", minWidth:"300px", alignItems:"center"}}>
                                {idConfigSel!=="" && (
                                <div style={{ display: "flex", justifyContent: "space-around", alignItems:"center", alignContent:"center", marginRight: "20px"}}>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer"}}  onClick={() => {setTipoListaUS("pendiente"); tipoLista="pendiente";}}>
                                        <input type="radio" value="pendiente" name="tipoLista" style={{margin: "20px"}} checked={tipoListaUS==="pendiente"} onChange={() => {setTipoListaUS("pendiente"); tipoLista="pendiente";}}/>
                                        <div>Pendiente</div>
                                    </div>
                                    <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", cursor:"pointer"}}  onClick={() => {setTipoListaUS("todos"); tipoLista="todos";}}>
                                        <input type="radio" value="todos" name="tipoLista" style={{margin: "20px"}} checked={tipoListaUS==="todos"} onChange={() => {setTipoListaUS("todos"); tipoLista="todos";}}/>
                                        <div>Todos</div>
                                    </div>
                                </div>
                                )}

                                {detCobrosTorneoUS.length>0 && (
                                    <>{(detCobrosTorneoUS.map((reg, index) => (<DinamicoCobrosDetalle key={index} datos={reg} tipoLista={tipoListaUS} eventoClick={clickGenerico} />))  )} </>
                                )}
                            </div>
                        )}
                        
                    
                    </div>


                    
                     


                    

                    


                </div>
        )}    


        {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

        {loading && <Loader tipo={"TOTAL"}/>}   

    </>);

};

export default Info_Cobros;